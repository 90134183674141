import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { translate } from 'react-i18next';
import { connect, Dispatch } from 'react-redux';
import { EntitlementActions } from '../../../../models';
import { IGrantRequest } from '../../../../models/ELM/IGrantRequest';
import { EntityType } from '../../../../models/EntityType';
import { IRootEntitlementsState } from '../../../../models/IEntitlementState';
import { PackageDetails } from './PackageDetails';
import {
  IConnectedPackageDetailsProps,
  IPackageDetailsActions,
  IPackageDetailsMappedProps,
} from './PackageDetails.types';

/**
 * Maps the external (i.e. own) props and the state to the properties of the PackageDetails control.
 * @param state The current redux state to map to props
 * @param ownProps The external properties to map to props
 * @returns The mapped properties that along with the actions
 * becomes the props (IPackageDetailsProps) for the PackageDetails control
 */
const mapStateToProps = (
  state: IRootEntitlementsState,
  ownProps: IConnectedPackageDetailsProps
): IPackageDetailsMappedProps => {
  const mappedGrantRequest =
    state.app.pendingApprovalGrantRequests.entitiesById.get(
      ownProps.grantRequest!.id
    ) || ownProps.grantRequest;

  return {
    ...ownProps,
    mappedGrantRequest,
    isLoading: state.app.isEntityDetailLoading,
  };
};

/**
 * Maps the dispatch actions to the props for the PackageDetails control.
 * @param dispatch Dispatches actions to the store
 * @returns The mapped actions that along with the mapped properties
 * becomes the props (IPackageDetailsProps) for the PackageDetails control
 */
const mapDispatchToProps = (
  dispatch: Dispatch<IRootEntitlementsState>
): IPackageDetailsActions => {
  const getEntity = createAction<{
    entityId: string;
    entityType: string;
  }>(EntitlementActions.getEntity, dispatch);
  return {
    getPendingApprovalGrantRequest: (id: string) =>
      getEntity({
        entityId: id,
        entityType: EntityType.pendingApprovalGrantRequests,
      }),
    cancelGrantRequest: createAction<Partial<IGrantRequest>>(
      EntitlementActions.updateGrantRequest,
      dispatch
    ),
  };
};

/**
 * A redux connected PackageDetails control.
 * @description Supports translation
 */
export const ConnectedPackageDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('MyAccess')(PackageDetails));
