import { css, DefaultButton, PrimaryButton } from '@fluentui/react';
import React from 'react';
import { v4 } from 'uuid';

import { useFeatures, useTelemetryWithMetadata, useTranslation } from '../../../hooks';
import { CycleActivationStatus, IActivationStatusPayload } from '../../../models/RAM/IActivationStatusPayload';
import { IErrorDialog } from '../../../models/RAM/IErrorDialog';
import { IPimActivationRequestParameters } from '../../../models/RAM/IPimActivationRequestParameters';
import { LocaleKeys } from '../../../shared';
import { isEligibilityScopedAtResource } from '../VmList/VmList.columns';
import { QuickActivationDialogRole } from './QuickActivationDialog';

interface FooterProps {
  role: QuickActivationDialogRole;
  isJustificationRequired?: boolean;
  isTicketingRequired?: boolean;
  activateRamAccess: (parameters: IPimActivationRequestParameters) => void;
  setActivationStatus: (payload: IActivationStatusPayload) => void;
  onDismiss: VoidFunction;
  setRamErrorDialog: (errorDialogContent?: IErrorDialog) => void;
  customizeSettings: boolean;
  setCustomizeSettings: (customizeSettings: boolean) => void;
}

const ramQuickActivationStyles = require('./ram.quickActivation.scss');

export const FooterButtons: React.FunctionComponent<FooterProps> = (props: FooterProps) => {
  const t = useTranslation();
  const { reportCustomEventWithMetadata } = useTelemetryWithMetadata();
  const features = useFeatures();

  const footer = React.useMemo(() => {
    // TODO: adjust button behavior when downstream is ready
    const parameters: IPimActivationRequestParameters = {
      scope: props.role.roleActivationScope,
      roleAssignmentScheduleRequestName: v4(),
      properties: props.role.roleProperties
    };

    const isTicketingRequirementUnmet =
      props.isTicketingRequired &&
      (props.role.roleProperties.ticketInfo === undefined ||
        props.role.roleProperties.ticketInfo.ticketNumber === undefined ||
        props.role.roleProperties.ticketInfo.ticketNumber === '');
    const isJustificationRequirementUnmet =
      props.isJustificationRequired &&
      (props.role.roleProperties.justification === '' || props.role.roleProperties.justification === undefined);
    const isRoleEligibilityAtResourceScope = isEligibilityScopedAtResource(props.role.roleScope);
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    const disableContinueButton =
      isJustificationRequirementUnmet || isTicketingRequirementUnmet || isRoleEligibilityAtResourceScope;

    return (
      <>
        <PrimaryButton
          disabled={disableContinueButton}
          onClick={() => {
            try {
              props.setActivationStatus({ status: CycleActivationStatus.Requesting, roleEndTime: '' });
              reportCustomEventWithMetadata('ram/quickActivate');
              props.activateRamAccess(parameters);
              props.onDismiss();
            } catch (error) {
              reportCustomEventWithMetadata('ram/access/activate/failed/initiate', { error });
              props.setRamErrorDialog({
                message: LocaleKeys.ramErrorTryAgain,
                image: '/imgs/error_Warning.svg'
              });
            }
          }}
          text={
            props.customizeSettings
              ? t(LocaleKeys.ramCustomActivationActivate)
              : t(LocaleKeys.ramQuickActivationDialogFooterContinueButtonText)
          }
        />
        {features.enableRamFullActivationFlow && !props.customizeSettings && (
          <DefaultButton
            className={css(ramQuickActivationStyles.footerButton)}
            text={t(LocaleKeys.ramCustomActivationChangeSettings)}
            onClick={() => {
              props.setCustomizeSettings(true);
            }}
          />
        )}
      </>
    );
  }, [props, reportCustomEventWithMetadata, t, features.enableRamFullActivationFlow]);

  return <>{footer}</>;
};
