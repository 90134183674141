import { AnswerString } from '../models/ELM/AnswerString';
import { IGrantPolicy } from '../models/ELM/IGrantPolicy';
import { IncompatibleDetails } from '../models/ELM/IncompatibleDetails';
import {
  IValidationError,
  ValidationErrorCode,
} from '../models/ELM/IValidationError';
import { QuestionAnswer } from '../models/ELM/QuestionAnswer';
import { QuestionType } from '../models/ELM/QuestionType';
import { isEmptyOrUndefined } from './isEmptyOrUndefined';
import { findGuid } from './isGuid';
import { LocaleKeys } from './LocaleKeys';
export const MAX_DATE: Date = new Date(9999, 12);

export const topLevelValidationErrors = [
  ValidationErrorCode.ExistingOpenRequest,
  ValidationErrorCode.InvalidRequestExistingGrant,
  ValidationErrorCode.EntitlementNotFound,
  ValidationErrorCode.InvalidExtendRequestMoreThanCanExtendBeforeInDays,
  ValidationErrorCode.InvalidExtendRequestPolicyDisabled,
  ValidationErrorCode.InvalidTargetPartner,
  ValidationErrorCode.PolicyExpired,
  ValidationErrorCode.PolicyRequirementNotMet,
  ValidationErrorCode.ValidPolicyNotFound,
  ValidationErrorCode.PartnerNotFound,
  ValidationErrorCode.InvalidRequestSODCheck,
  ValidationErrorCode.NoLicense,
  ValidationErrorCode.NoGovernanceSku
];

export function userAlreadyHasAccess(validationErrors: IValidationError[]): boolean {
  const invalidRequestExistingGrant = getErrorExists(validationErrors, ValidationErrorCode.InvalidRequestExistingGrant);
  const existingOpenRequest = getErrorExists(validationErrors, ValidationErrorCode.ExistingOpenRequest);
  return invalidRequestExistingGrant || existingOpenRequest;
}

export const isValidationErrorTopLevel = (
  validationErrors: IValidationError[]
): boolean => {
  if (!validationErrors || validationErrors.length === 0) {
    return false;
  }

  let isTopLevel = false;
  validationErrors.forEach((error: IValidationError) => {
    if (
      topLevelValidationErrors.includes(ValidationErrorCode[error.Code])
    ) {
      isTopLevel = true;
    }
  });

  return isTopLevel;
};

export const getTopLevelValidationErrorMessage = (
  validationErrors: IValidationError[]
): string => {
  let errorMessage: string = '';
  validationErrors.forEach((error: IValidationError) => {
    if (!isEmptyOrUndefined(error.Code)) {
      switch (ValidationErrorCode[error.Code]) {
        case ValidationErrorCode.EntitlementNotFound:
        case ValidationErrorCode.InvalidTargetPartner:
        case ValidationErrorCode.PolicyRequirementNotMet:
        case ValidationErrorCode.ValidPolicyNotFound:
        case ValidationErrorCode.NoLicense:
        case ValidationErrorCode.NoGovernanceSku:
          errorMessage += `validationErrorMessage.${error.Code}`;
          break;
        default:
          errorMessage += LocaleKeys.generalErrorMessage;
          break;
      }
    } else {
      errorMessage += LocaleKeys.generalErrorMessage;
    }
  });
  return errorMessage;
};

export const getErrorExists = (
  validationErrors: IValidationError[],
  errorToCheck: ValidationErrorCode
): boolean => {
  if (!validationErrors || validationErrors.length === 0) {
    return false;
  }

  let exists = false;
  validationErrors.forEach((error: IValidationError) => {
    if (errorToCheck === ValidationErrorCode[error.Code]) {
      exists = true;
    }
  });

  return exists;
};

export const getMaxDateFromPolicy = (
  validationErrors: IValidationError[]
): Date | null => {
  if (!validationErrors || validationErrors.length === 0) {
    return null;
  }

  let date: Date | null = null;
  validationErrors.forEach((error: IValidationError) => {
    if (
      ValidationErrorCode.InvalidExpiredDateTime ===
      ValidationErrorCode[error.Code]
    ) {
      date = new Date(error.Detail);
    }
  });

  return date;
};

export const getGrantPolicies = (
  validationErrors: IValidationError[]
): IGrantPolicy[] | null => {
  if (!validationErrors || validationErrors.length === 0) {
    return null;
  }

  let grantPolicies: IGrantPolicy[] = [];
  validationErrors.forEach((error: IValidationError) => {
    if (
      ValidationErrorCode.MultiplePolicyFound ===
      ValidationErrorCode[error.Code] ||
      ValidationErrorCode.ValidationOnly === ValidationErrorCode[error.Code]
    ) {
      grantPolicies = JSON.parse(error.Detail) as IGrantPolicy[];
    }
  });
  return grantPolicies;
};

export const getIncompatiblesDetails = (
  validationErrors: IValidationError[]
): IncompatibleDetails => {
  let incompatibleDetails: IncompatibleDetails = {};
  if (
    getErrorExists(validationErrors, ValidationErrorCode.InvalidRequestSODCheck)
  ) {
    validationErrors.forEach((error: IValidationError) => {
      if (
        ValidationErrorCode.InvalidRequestSODCheck ===
        ValidationErrorCode[error.Code]
      ) {
        try {
          incompatibleDetails = JSON.parse(error.Detail) as IncompatibleDetails;
        } catch (err) {
          incompatibleDetails = {};
        }
      }
    });
  }
  return incompatibleDetails;
};

export const getQuestionAnswers = (
  validationErrors: IValidationError[],
  selectedPolicy: IGrantPolicy | undefined
): QuestionAnswer[] => {
  let questionAnswers: QuestionAnswer[] = selectedPolicy?.questions ?? [];
  if (
    getErrorExists(validationErrors, ValidationErrorCode.MultiplePolicyFound)
  ) {
    if (selectedPolicy && selectedPolicy.questions) {
      questionAnswers = selectedPolicy.questions;
    }
  } else {
    validationErrors.forEach((error: IValidationError) => {
      if (
        ValidationErrorCode.MissingQuestionAnswers ===
        ValidationErrorCode[error.Code]
      ) {
        questionAnswers = JSON.parse(error.Detail) as QuestionAnswer[];
      }
    });
  }

  // Old grantRequest isValidationOnly API to get existing answers from validation error details
  if (
    getErrorExists(
      validationErrors,
      ValidationErrorCode.ExistingAttributesQuestionAnswers
    )
  ) {
    const questionAnswerMapping = {};
    let answerStrings: AnswerString[] = [];
    validationErrors.forEach((error: IValidationError) => {
      if (
        ValidationErrorCode.ExistingAttributesQuestionAnswers ===
        ValidationErrorCode[error.Code] &&
        !isEmptyOrUndefined(error.Detail) &&
        error.Detail !== 'null'
      ) {
        answerStrings = JSON.parse(error.Detail) as AnswerString[];

        // Build existing question id and answer value mapping
        for (const answerString of answerStrings) {
          const questionId = answerString.answeredQuestion.id;
          const answerValue = answerString.displayValue;
          questionAnswerMapping[questionId] = answerValue;
        }

        // Assign the answer value to the corresponding question
        for (const questionAnswer of questionAnswers) {
          if (questionAnswer.id in questionAnswerMapping) {
            switch (questionAnswer.$type) {
              case QuestionType.MultipleChoiceQuestion:
                questionAnswer.answerValue = pickOptionInMultipleChoicesWithExistingAnswer(
                  questionAnswer,
                  questionAnswerMapping[questionAnswer.id]
                );
                break;
              case QuestionType.TextInputQuestion:
              default:
                questionAnswer.answerValue =
                  questionAnswerMapping[questionAnswer.id];
                break;
            }
          } else {
            questionAnswer.answerValue = '';
          }
        }
      }
    });
  }

  // New API getApplicablePolicyRequirement get existing Answers from property existingAnswers
  if (selectedPolicy?.existingAnswers && selectedPolicy?.existingAnswers.length > 0) {
    const questionAnswerMapping = {};
    const answerStrings = selectedPolicy?.existingAnswers;

    // Build existing question id and answer value mapping
    for (const answerString of answerStrings) {
      const questionId = answerString.answeredQuestion.id;
      const answerValue = answerString.displayValue;
      questionAnswerMapping[questionId] = answerValue;
    }

    // Assign the answer value to the corresponding question
    for (const questionAnswer of questionAnswers) {
      if (questionAnswer.id in questionAnswerMapping) {
        switch (questionAnswer.$type) {
          case QuestionType.MultipleChoiceQuestion:
            questionAnswer.answerValue = pickOptionInMultipleChoicesWithExistingAnswer(
              questionAnswer,
              questionAnswerMapping[questionAnswer.id]
            );
            break;
          case QuestionType.TextInputQuestion:
          default:
            questionAnswer.answerValue =
              questionAnswerMapping[questionAnswer.id];
            break;
        }
      } else {
        questionAnswer.answerValue = '';
      }
    }
  }

  return questionAnswers
    ? questionAnswers.sort(
      (q1: QuestionAnswer, q2: QuestionAnswer) => q1.sequence - q2.sequence
    )
    : [];
};

export const pickOptionInMultipleChoicesWithExistingAnswer = (
  questionAnswer: QuestionAnswer,
  answer: string
): string => {
  for (const choice of questionAnswer.choices) {
    if (choice.actualValue.toLowerCase() === answer.toLowerCase()) {
      return choice.displayValue.defaultText;
    }
  }

  return '';
};

export const getExistingGrantId = (
  validationErrors: IValidationError[]
): string | null => {
  if (!validationErrors || validationErrors.length === 0) {
    return null;
  }

  let guid = '';
  validationErrors.forEach((error: IValidationError) => {
    if (
      ValidationErrorCode.InvalidRequestExistingGrant ===
      ValidationErrorCode[error.Code]
    ) {
      guid = findGuid(error.Detail);
    }
  });

  return guid;
};

export const getSelectedGrantPolicy = (
  grantPolicies: IGrantPolicy[],
  selectedKey: string | number
): IGrantPolicy | undefined => {
  return grantPolicies.find((gp: IGrantPolicy) => gp.id === selectedKey);
};

export const getPolicyExpirationDateFromValidationErrors = (
  validationErrors: IValidationError[],
  selectedPolicy: IGrantPolicy | undefined
): Date => {
  if (
    getErrorExists(validationErrors, ValidationErrorCode.MultiplePolicyFound)
  ) {
    if (selectedPolicy) {
      return !isEmptyOrUndefined(selectedPolicy.expirationDate)
        ? new Date(selectedPolicy.expirationDate)
        : MAX_DATE;
    } else {
      return MAX_DATE;
    }
  }

  const maxStopDateFromPolicy = getMaxDateFromPolicy(validationErrors);
  const maxStopDate =
    maxStopDateFromPolicy === null ? MAX_DATE : maxStopDateFromPolicy;

  return maxStopDate;
};

export const getPolicyIsCustomAssignmentScheduleAllowed = (
  grantPolicies: IGrantPolicy[],
  selectedKey: string | number
): boolean => {
  if (grantPolicies &&
    grantPolicies.length > 1
  ) {
    const selectedPolicy = getSelectedGrantPolicy(grantPolicies, selectedKey);
    return selectedPolicy
      ? selectedPolicy.isCustomAssignmentScheduleAllowed
      : true;
  }

  if (
    grantPolicies &&
    grantPolicies.length > 0
  ) {
    return grantPolicies[0].isCustomAssignmentScheduleAllowed;
  }

  return true;
};
