export interface IOverviewData {
  pendingActionEntityCount?: number;
  isLoading: boolean;
  isSucceeded: boolean;
  error?: IOverviewError[];
  errorEventName?: string;
}

export interface IOverviewError {
  code: string,
  message: string
}

export enum OverviewType {
  expiringGrants = 'expiringGrants',
  pendingApprovals = 'pendingApprovals',
  pendingAccessReviews = 'pendingAccessReviews'
}


export enum OverviewErrorEventName {
  expiringGrantsError = 'overviewExpiringGrants/get/failed-detail',
  pendingApprovalError = 'overviewPendingApproval/get/failed-detail',
  groupReviewError = 'overviewGroupAccessReview/get/failed-detail',
  accessPackageReviewError = 'overviewAccessPackageAccessReview/get/failed-detail',
  accessReviewError = 'overviewAccessReview/get/failed-detail'
}