// Derived from https://msazure.visualstudio.com/One/_git/AD-IGA-Store?path=/src/Dev/EC/FrontEnd/Ext/Client/DataModels/EC/UserSets.ts&version=GBmaster&_a=contents

interface IUserSet {
  isBackup: boolean;
}

export enum ManagerType {
  directManager = 1,
  secondLevelManager = 2
}

export enum UserSetType {
  targetManager = '#Microsoft.IGAELM.EC.FrontEnd.ExternalModel.targetManager'
}

export interface IOBORequestor extends IUserSet {
  managerLevel?: ManagerType;
  ['@odata.type']: UserSetType;
}
