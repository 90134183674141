
export enum PolicyRequestVerifiableCredentialStage {
  init = 'Init',
  callback = 'Callback'
}

export enum IVerifiableCredentialStatusType {
  required = '#Microsoft.IGAELM.EC.FrontEnd.ExternalModel.VerifiableCredentials.VerifiableCredentialRequired',
  retrieved = '#Microsoft.IGAELM.EC.FrontEnd.ExternalModel.VerifiableCredentials.VerifiableCredentialRetrieved',
  verified = '#Microsoft.IGAELM.EC.FrontEnd.ExternalModel.VerifiableCredentials.VerifiableCredentialVerified'
}

export interface IVerifiableCredentialRequirementStatus {
  '@odata.type': IVerifiableCredentialStatusType;
  expiryDateTime: string;
}

export interface IVerifiableCredentialRequired extends IVerifiableCredentialRequirementStatus {
  url: string;
}

export interface IVerifiableCredentialVerified extends IVerifiableCredentialRequirementStatus { }

export interface IVerifiableCredentialRetrieved extends IVerifiableCredentialRequirementStatus { }
