import { IAjaxRequest } from '@microsoft/portal-app/lib/auth/withAuth';
import { LoadingState } from '@microsoft/portal-app/lib/models/ILoading';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';
import { flatMap } from 'rxjs/operators';

import {
  IGetAccessPackageRecommendationsAction,
  IGetAccessPackageRecommendationsActionPayload
} from '../../../models/ELM/IAccessPackageRecommendations';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState, IRootEntitlementsState } from '../../../models/IEntitlementState';
import { getAudience, getPeopleRecommendationsAPIUrl } from '../../../shared';
import { registry } from '../myAccessRegistry';

export const getRecommendationsEpic: Epic<IGetAccessPackageRecommendationsAction, IRootEntitlementsState> = (
  action$: ActionsObservable<IGetAccessPackageRecommendationsAction>,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IGetAccessPackageRecommendationsAction> => {
  return action$.ofType(EntitlementActions.getRecommendations).pipe(
    flatMap((_action) => {
      const request: IAjaxRequest = {
        method: 'GET',
        url: getPeopleRecommendationsAPIUrl(),
        audience: getAudience()
      };

      return ajax(request)
        .map((payload) => {
          if (!payload?.response?.value) {
            return {
              type: EntitlementActions.getRecommendationsFailed,
              payload: {
                recommendations: []
              }
            };
          }
          return {
            type: EntitlementActions.getRecommendationsSuccess,
            payload: {
              recommendations: payload.response.value
            }
          };
        })
        .catch((error: any) =>
          Observable.of({
            type: EntitlementActions.getRecommendationsFailed,
            payload: {
              recommendations: [],
              loadingError: error
            }
          })
        );
    })
  );
};
registry.addEpic('getRecommendationsEpic', getRecommendationsEpic);

export const getRecommendations = (state: IEntitlementState, _action: IEntitlementAction): IEntitlementState => {
  return {
    ...state,
    recommendations: {
      ...state.recommendations,
      isLoading: true,
      loadingState: LoadingState.loading
    }
  };
};
registry.add(EntitlementActions.getRecommendations, getRecommendations);

export const getRecommendationsSuccess = (
  state: IEntitlementState,
  action: IEntitlementAction<IGetAccessPackageRecommendationsActionPayload>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined || action.payload?.recommendations === undefined) {
    return {
      ...state,
      recommendations: {
        ...state.recommendations,
        isLoading: false,
        loadingState: LoadingState.error,
        isUserError: true
      }
    };
  }

  return {
    ...state,
    recommendations: {
      ...state.recommendations,
      isLoading: false,
      loadingState: LoadingState.loaded,
      value: action.payload?.recommendations
    }
  };
};
registry.add(EntitlementActions.getRecommendationsSuccess, getRecommendationsSuccess);

export const getRecommendationsFailed = (
  state: IEntitlementState,
  action: IEntitlementAction<IGetAccessPackageRecommendationsActionPayload>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  return {
    ...state,
    recommendations: {
      ...state.recommendations,
      isLoading: false,
      loadingState: LoadingState.error,
      loadingError: action.payload.loadingError
    }
  };
};
registry.add(EntitlementActions.getRecommendationsFailed, getRecommendationsFailed);
