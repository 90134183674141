export enum RecommendationType {
  Approve = 'Approve',
  Deny = 'Deny',
  NotAvailable = 'NotAvailable'
}

export enum RecommendationFilterType {
  Approve = 'approve',
  Deny = 'deny',
  NotAvailable = 'notAvailable'
}