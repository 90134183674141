import {
  ColorClassNames,
  css,
  FontClassNames,
  FontWeights,
  HoverCard,
  HoverCardType,
  IPlainCardProps,
  Link,
  MessageBar,
  MessageBarType,
} from '@fluentui/react';
import { ILocalizableMessage } from '@microsoft/portal-app/lib/localization/ILocalizableMessage';
import { TranslationFunction } from 'i18next';
import React from 'react';

import { IncompatibleDetails } from '../../../models/ELM/IncompatibleDetails';
import { IValidationError, ValidationErrorCode } from '../../../models/ELM/IValidationError';
import { MultipleChoiceQuestion } from '../../../models/ELM/MultipleChoiceQuestion';
import { QuestionAnswer } from '../../../models/ELM/QuestionAnswer';
import { QuestionType } from '../../../models/ELM/QuestionType';
import { TextInputQuestion } from '../../../models/ELM/TextInputQuestion';
import { asLocalizedText } from '../../../shared/asLocalizedText';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import {
  getErrorExists,
  getExistingGrantId,
  getIncompatiblesDetails,
  getTopLevelValidationErrorMessage,
  isValidationErrorTopLevel,
} from '../../../shared/validationErrorHelper';
const myAccessStyles = require('../../../css/myAccess.scoped.scss');

export const onRenderValidationErrors = (
  entitlementName: string,
  validationErrors: IValidationError[],
  onExistingGrant: (guid: string) => void,
  t: TranslationFunction,
  usingNewRequestForm = false
): JSX.Element => {

  if (
    getErrorExists(
      validationErrors,
      ValidationErrorCode.InvalidExtendRequestPolicyDisabled
    )
  ) {
    return getMessageBar(
      {
        key: LocaleKeys.extendDisabledMessage,
        options: {
          entitlementName,
        },
      },
      t,
      MessageBarType.blocked
    );
  }

  if (getErrorExists(validationErrors, ValidationErrorCode.PolicyExpired)) {
    return getMessageBar(
      {
        key: LocaleKeys.policyExpiredMessage,
        options: {
          entitlementName,
        },
      },
      t,
      MessageBarType.blocked
    );
  }

  if (getErrorExists(validationErrors, ValidationErrorCode.PartnerNotFound)) {
    return getMessageBar(
      {
        key: LocaleKeys.partnerNotFoundMessage,
      },
      t,
      MessageBarType.blocked
    );
  }

  if (
    getErrorExists(
      validationErrors,
      ValidationErrorCode.InvalidRequestExistingGrant
    )
  ) {
    return getMessageBar(
      {
        key: LocaleKeys.grantExistsMessage,
        options: {
          entitlementName,
        },
      },
      t,
      usingNewRequestForm ? MessageBarType.info : MessageBarType.warning,
      true,
      LocaleKeys.viewAccessPackage,
      false,
      undefined,
      () =>
        onExistingGrant(getExistingGrantId(validationErrors)!)
    );
  }

  if (
    getErrorExists(validationErrors, ValidationErrorCode.ExistingOpenRequest)
  ) {
    return getMessageBar(
      {
        key: LocaleKeys.requestExistsMessage,
        options: {
          entitlementName,
        },
      },
      t
    );
  }

  if (
    getErrorExists(validationErrors, ValidationErrorCode.AccessDurationPeriodOutsidePolicyRange)
  ) {
    return getMessageBar(
      {
        key: LocaleKeys.requestDurationOutsidePolicyRange
      },
      t,
      MessageBarType.error
    );
  }
  if (
    getErrorExists(
      validationErrors,
      ValidationErrorCode.InvalidRequestSODCheck
    )
  ) {
    const incompatibleDetails = getIncompatiblesDetails(validationErrors);
    const plainCardProps: IPlainCardProps = {
      onRenderPlainCard: () =>
        onRenderIncompatibleDetailsHoverCard(incompatibleDetails, t),
    };

    return getMessageBar(
      {
        key: LocaleKeys.invalidSODCheck,
      },
      t,
      MessageBarType.blocked,
      false,
      LocaleKeys.seeDetails,
      true,
      plainCardProps
    );
  }

  if (
    getErrorExists(
      validationErrors,
      ValidationErrorCode.InvalidRequestExistingGrant
    )
  ) {
    return getMessageBar(
      {
        key: LocaleKeys.grantExistsMessage,
        options: {
          entitlementName,
        },
      },
      t
    );
  }

  if (isValidationErrorTopLevel(validationErrors)) {
    return getMessageBar(
      {
        key: getTopLevelValidationErrorMessage(validationErrors),
      },
      t,
      MessageBarType.error
    );
  }

  return (<div />);
};

export const MessageBarHoverCard = ({ plainCardProps, linkText, t }: { plainCardProps?: IPlainCardProps, linkText?: string, t: TranslationFunction }): JSX.Element => (
  <div className={css(myAccessStyles.marginBottomXSmall)}>
    <HoverCard
      trapFocus={true}
      setInitialFocus={true}
      instantOpenOnClick={true}
      type={HoverCardType.plain}
      plainCardProps={plainCardProps}
    >
      <Link
        data-is-focusable={false}
        className={css(
          FontClassNames.medium,
          'ms-pii',
          myAccessStyles.marginBottomSmall
        )}
      >
        {linkText ? t(linkText) : null}
      </Link>
    </HoverCard>
  </div>
)

export const getMessageBar = (
  message: ILocalizableMessage | string,
  t: TranslationFunction,
  messageBarType?: MessageBarType,
  showLink?: boolean,
  linkText?: string,
  showHoverCard?: boolean,
  plainCardProps?: IPlainCardProps,
  linkCallBack?: () => void
): JSX.Element => {
  return (
    <MessageBar
      className={css(myAccessStyles.marginTopSmall)}
      messageBarType={messageBarType !== undefined && messageBarType in MessageBarType ? messageBarType : MessageBarType.warning}
    >
      <span className={css('ms-pii')}>{asLocalizedText(message, t)} </span>
      {showLink ? (
        // tslint:disable-next-line:jsx-no-lambda
        <Link onClick={linkCallBack}>{t(linkText!)}</Link>
      ) : null}
      {showHoverCard && <MessageBarHoverCard plainCardProps={plainCardProps} t={t} linkText={linkText} />}
    </MessageBar>
  );
};

export const getQuestionObject = (
  questionAnswer: QuestionAnswer
): QuestionAnswer => {
  switch (questionAnswer.$type) {
    case QuestionType.MultipleChoiceQuestion:
      return new MultipleChoiceQuestion(
        questionAnswer.id,
        questionAnswer.attributeName,
        questionAnswer.isAttribute,
        questionAnswer.text,
        questionAnswer.isRequired,
        questionAnswer.sequence,
        questionAnswer.choices,
        questionAnswer.showAnswerToApprover
      );
    case QuestionType.TextInputQuestion:
      return new TextInputQuestion(
        questionAnswer.id,
        questionAnswer.attributeName,
        questionAnswer.isAttribute,
        questionAnswer.text,
        questionAnswer.isRequired,
        questionAnswer.sequence,
        questionAnswer.choices,
        questionAnswer.showAnswerToApprover,
        questionAnswer.isSingleLineQuestion,
        questionAnswer.regexPattern
      );
  }
};

export const onRenderIncompatibleDetailsHoverCard = (
  incompatiblesDetails: IncompatibleDetails,
  t: TranslationFunction
): JSX.Element => {
  const incompatiblesKeys = Object.keys(incompatiblesDetails);
  const view: JSX.Element[] = incompatiblesKeys.map((key: string) => {
    return (
      <div
        key={key}
        className={css(
          FontClassNames.small,
          myAccessStyles.marginBottomSmall
        )}
      >
        <div
          className={css(
            FontWeights.semilight,
            ColorClassNames.neutralSecondary
          )}
        >
          {t(key)}
        </div>
        <span
          className={css(
            myAccessStyles.semiBoldText,
            ColorClassNames.neutralPrimary,
            'ms-pii'
          )}
        >
          {incompatiblesDetails[key].join(', ')}
        </span>
      </div>
    );
  });
  return (
    <div
      className={css(
        myAccessStyles.paddingSmallPlus,
        myAccessStyles.cardDefaultWidth,
        myAccessStyles.wordBreak,
        myAccessStyles.incompatibleDetailsContainer
      )}
    >
      {view}
    </div>
  );
};