import { Dialog, ResponsiveMode } from '@fluentui/react';
import { useResponsiveMode } from '@uifabric/portal-ux/lib/styling/useResponsiveMode';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from '../../../hooks';
import { CycleActivationStatus } from '../../../models/RAM/IActivationStatusPayload';
import { getSpinner, LocaleKeys } from '../../../shared';

const defaultCycleTime = 15 * 1000;

export interface ActivationStatusDialogProps {
  onDismiss: VoidFunction;
  activationStatus: CycleActivationStatus;
}

// TODO: Augusto is working on a new way to import scss files, ignore for now
// eslint-disable-next-line @typescript-eslint/no-var-requires
const ramActivationStatusDialogStyles = require('./ram.activationStatusDialog.scss');

const activationStatusDialogStyles = {
  [ResponsiveMode.small]: ramActivationStatusDialogStyles.activationStatusDialogSmall,
  [ResponsiveMode.medium]: ramActivationStatusDialogStyles.activationStatusDialogMedium,
  [ResponsiveMode.large]: ramActivationStatusDialogStyles.activationStatusDialogLarge,
  [ResponsiveMode.xLarge]: ramActivationStatusDialogStyles.activationStatusDialogXLarge,
  [ResponsiveMode.xxLarge]: ramActivationStatusDialogStyles.activationStatusDialogXXLarge,
  [ResponsiveMode.xxxLarge]: ramActivationStatusDialogStyles.activationStatusDialogXXXLarge
};

const spinnerMessageKeys = [
  LocaleKeys.ramActivationStatusProcessingYourRequest,
  LocaleKeys.ramActivationStatusActivatingYourRole,
  LocaleKeys.ramActivationStatusValidating,
  LocaleKeys.ramActivationStatusActivationSuccessful
];

const dialogContentProps = {
  showCloseButton: false
};

export const ActivationStatusDialog: React.FunctionComponent<ActivationStatusDialogProps> = (
  props: ActivationStatusDialogProps
) => {
  const t = useTranslation();
  const responsiveMode = useResponsiveMode();
  const [spinnerMessageKey, setSpinnerMessageKey] = useState<LocaleKeys>(spinnerMessageKeys[0]);
  const [containerClassName, setContainerClassName] = useState<string>(activationStatusDialogStyles[responsiveMode]);
  const isMounted = useRef(false);

  const modalProps = useMemo(
    () => ({
      isBlocking: true,
      containerClassName
    }),
    [containerClassName]
  );

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    // If the activation status is Provisioned, we will show all messages in the spinnerMessageKeys array
    // If the activation status is PendingApprovalProvisioning, we will show only the first message
    const finalSpinnerMessageKey =
      props.activationStatus === CycleActivationStatus.PendingApprovalProvisioning
        ? spinnerMessageKeys[0]
        : spinnerMessageKeys[spinnerMessageKeys.length - 1];

    const intervalId = setInterval(() => {
      if (spinnerMessageKey === finalSpinnerMessageKey) {
        props.onDismiss();
      }
      if (isMounted.current) {
        setSpinnerMessageKey((prev) => {
          const index = spinnerMessageKeys.indexOf(prev);
          return spinnerMessageKeys[(index + 1) % spinnerMessageKeys.length];
        });
      }
    }, defaultCycleTime);

    return () => clearInterval(intervalId);
  }, [props, spinnerMessageKey]);

  useEffect(() => {
    setContainerClassName(activationStatusDialogStyles[responsiveMode]);
  }, [responsiveMode]);

  return (
    <Dialog hidden={false} onDismiss={props.onDismiss} dialogContentProps={dialogContentProps} modalProps={modalProps}>
      <div className={ramActivationStatusDialogStyles.spinnerContainer}>{getSpinner(t(spinnerMessageKey))}</div>
    </Dialog>
  );
};
