import { translate } from 'react-i18next';
import { connect, Dispatch } from 'react-redux';

import { createAction } from '@microsoft/portal-app/lib/redux/createAction';

import { EntitlementActions } from '../../../models';
import { IGrantRequest } from '../../../models/ELM/IGrantRequest';
import { EntityType } from '../../../models/EntityType';
import { IRootEntitlementsState } from '../../../models/IEntitlementState';
import { IListColumn } from '../../../models/IListColumn';
import { LoadMode } from '../../../models/IPageData';
import { ApprovalSearchFilter } from '../../../models/RequestApprovals/ApprovalSearchFilter';
import { createTelemetryAction } from '../../../redux';
import { GetEntityList } from '../../../shared/GetEntityList';
import { getPageMetaDataFromEntities } from '../../../shared/getPageMetaDataFromEntities';
import { PendingApprovalGrantRequestList } from './PendingApprovalGrantRequestList';
import {
  IConnectedPendingApprovalGrantRequestListProps,
  IPendingApprovalGrantRequestListActions,
  IPendingApprovalGrantRequestListMappedProps,
} from './PendingApprovalGrantRequestList.types';

/**
 * Maps the external (i.e. own) props and the state to the properties of the GrantRequestList control.
 * @param state The current redux state to map to props
 * @param ownProps The external properties to map to props
 * @returns The mapped properties that along with the actions
 * becomes the props (IGrantRequestListProps) for the GrantRequestList control
 */
const mapStateToProps = (
  state: IRootEntitlementsState,
  _ownProps: IConnectedPendingApprovalGrantRequestListProps
): IPendingApprovalGrantRequestListMappedProps => {
  const {
    pendingApprovalGrantRequests,
    errorHasOccurred,
    errorCode,
    showingPendingApprovalGrantRequestDetails,
    searchTerm,
  } = state.app;

  const isSearching: boolean = searchTerm !== null && searchTerm !== '';
  const entitiesList = GetEntityList(pendingApprovalGrantRequests, isSearching);
  const pendingApprovalGrantRequestList: IGrantRequest[] = entitiesList.filteredEntities.reduce(
    (list: IGrantRequest[], key: string) => {
      const item = pendingApprovalGrantRequests.entitiesById.get(key);
      if (item !== undefined) {
        list.push(item);
      }
      return list;
    },
    []
  );

  const pageMetaData = getPageMetaDataFromEntities(
    pendingApprovalGrantRequests,
    errorHasOccurred
  );

  const loadMode = pendingApprovalGrantRequests.loadMode!;
  const showingBulkActionDialog = state.app.showingBulkActionDialog!;

  const selectedFilterKey =
    state.search.selectedFilterKey || ApprovalSearchFilter.All;

  return {
    isLoading: pendingApprovalGrantRequests.isLoading,
    isLoadingMore: loadMode === LoadMode.LoadMore,
    isRefreshing: loadMode === LoadMode.Refresh,
    pendingApprovalGrantRequestList: pendingApprovalGrantRequestList,
    pageMetaData,
    errorHasOccurred,
    errorCode,
    showingPendingApprovalGrantRequestDetails: showingPendingApprovalGrantRequestDetails!,
    entitiesById: pendingApprovalGrantRequests.entitiesById,
    showingBulkActionDialog,
    searchTerm,
    selectedFilterKey: selectedFilterKey,
    isSearching,
    features: state.app.features.isEnabled,
  };
};

/**
 * Maps the dispatch actions to the props for the GrantRequestList control.
 * @param dispatch Dispatches actions to the List
 * @returns The mapped actions that along with the mapped properties
 * becomes the props (IGrantRequestListProps) for the GrantRequestList control
 */
const mapDispatchToProps = (
  dispatch: Dispatch<IRootEntitlementsState>
): IPendingApprovalGrantRequestListActions => {
  const showPendingApprovalGrantRequestDetailsAction = createAction<boolean>(
    EntitlementActions.showPendingApprovalGrantRequestDetails,
    dispatch
  );
  const getEntitiesAction = createAction<{ entityType: string }>(
    EntitlementActions.getEntities,
    dispatch
  );
  const refreshEntitiesAction = createAction<{ entityType: string }>(
    EntitlementActions.refreshEntities,
    dispatch
  );

  const setSortedByColumnAction = createAction<{
    column: IListColumn<IGrantRequest>;
    entityType: string;
  }>(EntitlementActions.setSortedByColumn, dispatch);

  const sortEntities = createAction<{
    entityType: string;
    orderby: string;
    isAscending: boolean;
  }>(EntitlementActions.sortEntities, dispatch);

  const showBulkActionDialogAction = createAction<boolean>(
    EntitlementActions.showBulkActionDialog,
    dispatch
  );

  const searchForMoreAction = createAction<{ entityType: string }>(
    EntitlementActions.searchForMore,
    dispatch
  );

  const sortFilteredEntitiesAction = createAction<{
    entityType: string;
    orderby: string;
    isAscending: boolean;
    searchTerm: string;
  }>(EntitlementActions.sortFilteredEntities, dispatch);

  return {
    showPendingApprovalGrantRequestDetails: () =>
      showPendingApprovalGrantRequestDetailsAction(true),
    dismissPendingApprovalGrantRequestDetails: () =>
      showPendingApprovalGrantRequestDetailsAction(false),
    getEntities: () =>
      getEntitiesAction({
        entityType: EntityType.pendingApprovalGrantRequests,
      }),
    refreshEntities: () =>
      refreshEntitiesAction({
        entityType: EntityType.pendingApprovalGrantRequests,
      }),
    setSearchContext: createAction<string | null>(
      EntitlementActions.setSearchContext,
      dispatch
    ),
    setSortedByColumn: (column: IListColumn<IGrantRequest>) =>
      setSortedByColumnAction({
        column: column,
        entityType: EntityType.pendingApprovalGrantRequests,
      }),
    sortPendingApprovalGrantRequests: (orderby: string, isAscending: boolean) =>
      sortEntities({
        entityType: EntityType.pendingApprovalGrantRequests,
        orderby: orderby,
        isAscending: isAscending,
      }),
    showBulkActionDialog: () => showBulkActionDialogAction(true),
    dismissBulkActionDialog: () => showBulkActionDialogAction(false),
    searchForMore: () =>
      searchForMoreAction({
        entityType: EntityType.pendingApprovalGrantRequests,
      }),
    sortFilteredEntities: (
      orderby: string,
      isAscending: boolean,
      searchTerm: string
    ) =>
      sortFilteredEntitiesAction({
        entityType: EntityType.pendingApprovalGrantRequests,
        orderby: orderby,
        isAscending: isAscending,
        searchTerm: searchTerm,
      }),
    recordBusinessEvent: (eventName) =>
      dispatch(createTelemetryAction(eventName)),
  };
};

/**
 * A redux connected GrantRequestList control.
 * @description Supports translation
 */
export const ConnectedPendingApprovalGrantRequestList = connect(
  mapStateToProps,
  mapDispatchToProps
  // tslint:disable-next-line:no-any
)(translate('MyAccess')(PendingApprovalGrantRequestList) as any);
