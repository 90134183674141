import { IAjaxRequest } from '@microsoft/portal-app/lib/auth/withAuth';
import { ILoading } from '@microsoft/portal-app/lib/models/ILoading';
import { notificationsMerge } from '@microsoft/portal-app/lib/Notifications/helpers/notificationsMerge';
import {
  INotification,
  NotificationSeverity,
  NotificationType
} from '@microsoft/portal-app/lib/Notifications/models/INotification';
import { getResponseValue, IODataValueResponse } from '@microsoft/portal-app/lib/odata-utils';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { TranslationOptions } from 'i18next';
import * as moment from 'moment';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';
import { isNullOrUndefined } from 'util';
import { v4 } from 'uuid';
import { IEntity } from '../../../models/ELM/IEntity';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { EntityType } from '../../../models/EntityType';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState, IRootEntitlementsState } from '../../../models/IEntitlementState';
import { IEntityResult } from '../../../models/IEntityResult';
import { LoadMode } from '../../../models/IPageData';
import { getRequestWithAudience } from '../../../shared/AttachAudience';
import { getEntitiesApiUrl } from '../../../shared/getApiUrl';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { registry } from '../myAccessRegistry';
import { IAccessPackageRecommendations } from 'src/models/ELM/IAccessPackageRecommendations';

export const getEntitiesEpic: Epic<IEntitlementAction<AnyPayload>, IRootEntitlementsState> = (
  action$: ActionsObservable<
    IEntitlementAction<{
      entityType: EntityType;
      id?: string;
    }>
  >,
  store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
  return action$
    .ofType(EntitlementActions.getEntities)
    .switchMap((action: IEntitlementAction<{ entityType: EntityType; id?: string }>) => {
      let ajaxRequest: IAjaxRequest;

      const state: IEntitlementState = store.getState().app;
      const entityType = action.payload!.entityType;
      const id = action.payload!.id;

      const nextLink = state[entityType].unfiltered.nextLink;
      ajaxRequest = getRequestWithAudience(nextLink ? nextLink : getEntitiesApiUrl(entityType, id), entityType);

      return (
        ajax(ajaxRequest)
          .map((payload: IODataValueResponse<ReadonlyArray<IEntity>>) => {
            return {
              type: EntitlementActions.getEntitiesSucceeded,
              payload: {
                entityType: action.payload!.entityType,
                entities: getResponseValue(payload),
                count: payload.response!['@odata.count'],
                nextLink: payload.response!['@odata.nextLink'],
                secondaryId: id
              }
            } as IEntitlementAction<IEntityResult<IEntity>>;
          })
          // tslint:disable-next-line:no-any
          .catch((error: any) =>
            Observable.of({
              type: EntitlementActions.getEntitiesFailed,
              payload: {
                entityType: action.payload!.entityType,
                errorCode: error && error.status
              }
            })
          )
      );
    });
};
registry.addEpic('getEntitiesEpic', getEntitiesEpic);

export const getEntities = (
  state: IEntitlementState,
  action: IEntitlementAction<{ entityType: string }>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }
  let entityType = action.payload.entityType;
  const loadMode = state[entityType].unfiltered.nextLink ? LoadMode.LoadMore : LoadMode.Refresh;
  return {
    ...state,
    [entityType]: {
      ...state[entityType],
      isLoading: true,
      loadMode: loadMode
    }
  };
};
registry.add(EntitlementActions.getEntities, getEntities);

export const getEntitiesSucceeded = (
  state: IEntitlementState,
  action: IEntitlementAction<IEntityResult<IEntity>>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined || !action.payload.entities) {
    return state;
  }

  const entityType = action.payload.entityType;
  let usePrincipal = false;

  let mappedEntities = action.payload.entities.map((item: IEntity) => {
    if (isNullOrUndefined(item.id)) {
      usePrincipal = true;
    }
    if (entityType === EntityType.suggestions){
      return [(item as IAccessPackageRecommendations).accessPackageId, item]
    } else {
      return [item.id, item];
    }
  }) as [string, IEntity & ILoading][];

  if (usePrincipal) {
    let idList: string[] = [];
    let tempEnt = action.payload.entities as any[];
    if (tempEnt.length > 0 && tempEnt[0].principal) {
      mappedEntities = [];
      mappedEntities = tempEnt.map((item: any) => {
        item.id = item.principal.id;
        idList.push(item.id);
        return [item.principal.id, item];
      }) as [string, IEntity & ILoading][];
    }

    for (let index of Object.keys(mappedEntities)) {
      let principal = mappedEntities[index][1] as any;
      if (principal.principal) {
        mappedEntities[index][0] = principal.principal.id;
        mappedEntities[index][1].id = principal.principal.id;
        idList.push(mappedEntities[index][0]);
      }
    }
  }

  const newEntitiesById = new Map([
    // tslint:disable-next-line:no-any
    ...(Array.from(state[entityType].entitiesById) as any),
    ...mappedEntities
  ]);

  let newState = {
    ...state,
    [entityType]: {
      ...state[entityType],
      entitiesById: newEntitiesById,
      unfiltered: {
        ...state[entityType].unfiltered,
        entities: state[entityType].unfiltered.entities.concat(
          // idList
          action.payload.entities.map((item: IEntity) => entityType === EntityType.suggestions? (item as IAccessPackageRecommendations).accessPackageId : item.id)
        ),
        count: action.payload.count,
        nextLink: action.payload.nextLink
      },
      isLoading: false,
      loadMode: undefined
    },
    errorHasOccurred: false
  };

  if (entityType === EntityType.pendingRaeRequests || entityType === EntityType.pendingApprovalGrantRequests) {
    newState = {
      ...newState,
      pageCounts: {
        ...newState.pageCounts,
        approvals: action.payload.count
      }
    };
  }

  if (
    entityType === EntityType.validGrants ||
    entityType === EntityType.expiredGrants ||
    entityType === EntityType.entitlements
  ) {
    newState = {
      ...newState,
      pageCounts: {
        ...newState.pageCounts,
        [entityType]: action.payload.count
      }
    };
  }

  return newState;
};
registry.add(EntitlementActions.getEntitiesSucceeded, getEntitiesSucceeded);

export const getEntitiesFailed = (
  state: IEntitlementState,
  // tslint:disable-next-line:no-any
  action: IEntitlementAction<Readonly<any>>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  const entityType = action.payload.entityType;
  const errorCode = action.payload.errorCode;

  let toastKey = LocaleKeys.generalErrorMessage;
  let toastOptions: TranslationOptions = {};

  let notifications: INotification[] = [
    {
      id: v4(),
      createdDateTime: moment(),
      localizableMessage: {
        key: toastKey,
        options: toastOptions
      },
      severity: NotificationSeverity.error,
      type: NotificationType.card
    }
  ];

  return {
    ...state,
    notifications: notificationsMerge(notifications, state.notifications, state.notificationsLimit),
    [entityType]: {
      ...state[entityType],
      isLoading: false,
      loadMode: undefined
    },
    errorHasOccurred: true,
    errorCode: errorCode,
    isTenantWhitelisted: errorCode !== 403
  };
};
registry.add(EntitlementActions.getEntitiesFailed, getEntitiesFailed);
