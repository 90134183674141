import {
  IAuth,
  IAuthUser,
} from '@iamexperiences/react-auth/lib-commonjs/types';
import { auth } from '../auth';
import { isEmptyOrUndefined } from './isEmptyOrUndefined';

const getAuth = (): IAuth => {
  return auth;
};

const getUserFromAuth = (): IAuthUser | null => {
  return getAuth().user.value;
};

const getObjectId = (): string | null => {
  const user = getUserFromAuth();
  return user?.objectId || (user?.rawClaims['home_oid'] as string);
};

const isInternalUser = (): boolean => {
  const user = getUserFromAuth();
  return user ? !isEmptyOrUndefined(user.objectId) : false;
};

export { getAuth, getUserFromAuth, isInternalUser, getObjectId };
