// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../dev/node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../dev/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2Sb2p-ASddRkhPmNXrvZD4{padding:20px;display:flex;flex-direction:column}._3E-pIc7iQbQ5JbhRYuhxXl{display:flex;flex-direction:column;width:60%}._1rOLPJbDMXEunFdl11NakT{width:60%}.MXG0e4r62kL6_G3Ayk6Wq{width:18%;align-self:flex-end}\n", "",{"version":3,"sources":["webpack://./src/components/RequestApprovals/ApprovalSubstitution/ApprovalSubstitution.scoped.scss"],"names":[],"mappings":"AAAA,yBACE,YAAa,CACb,YAAa,CACb,qBAAsB,CACvB,yBAGC,YAAa,CACb,qBAAsB,CACtB,SAAU,CACX,yBAGC,SAAU,CACX,uBAGC,SAAU,CACV,mBAAoB","sourcesContent":[".modalContent {\n  padding: 20px;\n  display: flex;\n  flex-direction: column;\n}\n\n.dateTimePicker {\n  display: flex;\n  flex-direction: column;\n  width: 60%;\n}\n\n.objectIdTextField {\n  width: 60%;\n}\n\n.submitButton {\n  width: 18%;\n  align-self: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContent": "_2Sb2p-ASddRkhPmNXrvZD4",
	"dateTimePicker": "_3E-pIc7iQbQ5JbhRYuhxXl",
	"objectIdTextField": "_1rOLPJbDMXEunFdl11NakT",
	"submitButton": "MXG0e4r62kL6_G3Ayk6Wq"
};
module.exports = ___CSS_LOADER_EXPORT___;
