import { IAnswerChoice } from './AnswerChoice';
import { IEntitlementManagementLocalizedContent } from './IEntitlementManagementLocalizedContent';
import { IQuestion } from './IQuestion';
import { QuestionType } from './QuestionType';

export class QuestionAnswer implements IQuestion {
  public '$type': QuestionType;
  public id: string;
  public attributeName: string;
  public isAttribute: boolean;
  public isAnswerEditable?: boolean;
  /**
   * @deprecated use `isAnswerEditable` instead. Kept for now for backwards compatibility.
   */
  public isNonAnswerable?: boolean;
  public text: IEntitlementManagementLocalizedContent;
  public questionType: string;
  public isRequired: boolean;
  public showAnswerToApprover: boolean;
  public sequence: number;
  public choices: IAnswerChoice[];
  public maximumCharacters: number;
  public minimumValue: number;
  public maximumValue: number;
  public maximumRating: number;
  public step: number;
  public dateFormat: string;
  public numberOfLines: number;
  public regexPattern: string;
  public isValid?: boolean;
  public answerValue: string;
  public answerDisplayValue: string;
  public isSingleLineQuestion: boolean;
  constructor(
    id: string,
    attributeName: string,
    isAttribute: boolean,
    text: IEntitlementManagementLocalizedContent,
    isRequired: boolean,
    sequence: number,
    choices: IAnswerChoice[],
    showAnswerToApprover: boolean
  ) {
    this.id = id;
    this.attributeName = attributeName;
    this.isAttribute = isAttribute;
    this.text = text;
    this.isRequired = isRequired;
    this.sequence = sequence;
    this.choices = choices;
    this.showAnswerToApprover = showAnswerToApprover;
  }
}
