import {
  ContextualMenu,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  getGlobalClassNames,
  getTheme,
  Link,
  Pivot,
  PivotItem,
  ResponsiveMode
} from '@fluentui/react';
import { useResponsiveMode } from '@uifabric/portal-ux/lib/styling/useResponsiveMode';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { useTelemetryWithMetadata, useTranslation } from '../../../hooks';
import { IPimAssignmentScheduleInstance } from '../../../models/RAM/IPimActivationRequestParameters';
import { OsTypeEnum } from '../../../models/RAM/OsTypeEnum';
import { ResourceTypeEnum } from '../../../models/RAM/ResourceTypeEnum';
import { LocaleKeys } from '../../../shared';
import { ConnectionDialogProps, ConnectionTypeEnum } from './Connection.types';
import {
  formatConnectionDateTime,
  generalTroubleshootUrl,
  getSelectedConnectionPivot,
  rdpTroubleshootUrl
} from './Connection.utils';
import { generateConnectionMessageBars } from './ConnectionMessageBarListGenerator';
import { PivotItemListGenerator } from './PivotItemListGenerator';

const classNames = getGlobalClassNames(
  {
    inner: 'ms-Dialog-inner'
  },
  getTheme()
);
const ramDialogStyles = require('../Styles/ram.dialog.scss');
const ramConnectionStyles = require('./ram.connection.scss');

const connectionDialogStyles = {
  [ResponsiveMode.small]: ramDialogStyles.dialogSmall,
  [ResponsiveMode.medium]: ramDialogStyles.dialogMedium,
  [ResponsiveMode.large]: ramDialogStyles.dialogLarge,
  [ResponsiveMode.xLarge]: ramDialogStyles.dialogXLarge,
  [ResponsiveMode.xxLarge]: ramDialogStyles.dialogXXLarge,
  [ResponsiveMode.xxxLarge]: ramDialogStyles.dialogXXXLarge
};

export const ConnectionDialog: FunctionComponent<ConnectionDialogProps> = (props: ConnectionDialogProps) => {
  const t = useTranslation();
  const responsiveMode = useResponsiveMode();
  const [containerClassName, setContainerClassName] = useState<string>(connectionDialogStyles[responsiveMode]);
  const [selectedPivot, setSelectedPivot] = useState<string | undefined>(undefined);
  const [error, setError] = useState(false);
  const [pivotItems, setPivotItems] = useState<JSX.Element[]>([]);
  const { reportCustomEventWithMetadata } = useTelemetryWithMetadata();

  const dialogContentProps = {
    type: DialogType.close,
    title: props.virtualMachineName,
    styles: {
      inner: [
        classNames.inner,
        {
          padding: '0px 0px 0px'
        }
      ]
    }
  };

  useEffect(() => {
    props.getVirtualMachineDetails(props.virtualMachine);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.getVirtualMachineDetails, props.virtualMachine]);

  useEffect(() => {
    setContainerClassName(connectionDialogStyles[responsiveMode]);
  }, [responsiveMode]);

  useEffect(() => {
    if (error) {
      props.setRamErrorDialog({
        message: LocaleKeys.ramErrorTryAgain,
        image: '/imgs/error_Warning.svg'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (
      props.virtualMachine.resourceType === ResourceTypeEnum.VirtualMachine &&
      props.virtualMachine.osType === undefined
    ) {
      setError(true);
      reportCustomEventWithMetadata('ram/connectionDialog/initialize/fail', { details: 'undefined OS type' });
    } else {
      setSelectedPivot(getSelectedConnectionPivot(props.virtualMachineOsType as OsTypeEnum));
    }
  }, [
    props.virtualMachine.osType,
    props.virtualMachine.resourceType,
    props.virtualMachineOsType,
    reportCustomEventWithMetadata
  ]);

  const handlePivotClick = useCallback(
    (item: PivotItem | undefined): void => {
      if (!item?.props) {
        return;
      }
      setSelectedPivot(item.props.itemKey as ConnectionTypeEnum);
    },
    [setSelectedPivot]
  );

  const rdpTroubleshootLink = useCallback((): JSX.Element => {
    return (
      <Link href={rdpTroubleshootUrl} target={'_blank'}>
        {t(LocaleKeys.ramConnectionDialogRemoteDesktopLink)}
      </Link>
    );
  }, [t]);

  const generalTroubleshootLink = useCallback((): JSX.Element => {
    return (
      <Link href={generalTroubleshootUrl(props.virtualMachineId)} target={'_blank'}>
        {t(LocaleKeys.ramConnectionDialogTroubleshootLink)}
      </Link>
    );
  }, [t, props.virtualMachineId]);

  const troubleshootList = (): JSX.Element => {
    const links = [generalTroubleshootLink()];
    if (selectedPivot === ConnectionTypeEnum.Rdp) links.push(rdpTroubleshootLink());
    return (
      <>
        <hr className={ramConnectionStyles.hr}></hr>
        <h3 className={ramConnectionStyles.connectionHeading}>{t(LocaleKeys.ramConnectionDialogCantConnect)}</h3>
        <div>{createUnorderedList(links)}</div>
      </>
    );
  };

  useEffect(() => {
    try {
      setPivotItems(PivotItemListGenerator(props.virtualMachine));
    } catch (e) {
      setError(true);
      reportCustomEventWithMetadata('ram/connectionDialog/initialize/fail', { error: e });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.virtualMachine, selectedPivot]);

  return (
    <Dialog
      hidden={false}
      onDismiss={props.onDismiss}
      dialogContentProps={dialogContentProps}
      modalProps={{
        isBlocking: true,
        dragOptions: {
          moveMenuItemText: t(LocaleKeys.ramConnectionDialogMove),
          closeMenuItemText: t(LocaleKeys.ramConnectionDialogClose),
          menu: ContextualMenu
        },
        containerClassName
      }}
    >
      {generateConnectionMessageBars(
        t,
        props.virtualMachine,
        props.quickActivationRole,
        props.selectedVirtualMachineDetails,
        props.roleEndTime || getAccessEndTime([...props.virtualMachine.activeRoleAssignmentList])
      )}
      <div className={ramConnectionStyles.dialogContent}>
        <Pivot selectedKey={selectedPivot} onLinkClick={handlePivotClick}>
          {pivotItems}
        </Pivot>
        {troubleshootList()}
        <DialogFooter>
          <DefaultButton text={t(LocaleKeys.ramConnectionDialogClose)} onClick={props.onDismiss} />
        </DialogFooter>
      </div>
    </Dialog>
  );
};

const createUnorderedList = (items: JSX.Element[]): JSX.Element => {
  return (
    <ul>
      {items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  );
};

const getAccessEndTime = (activeAssignments: IPimAssignmentScheduleInstance[]): string | null => {
  const assignmentsWithEndDate = activeAssignments.filter((assignment) => assignment.endDateTime !== null);

  if (assignmentsWithEndDate.length === 0) {
    return null;
  }
  const now = new Date();

  const farthestAssignment = assignmentsWithEndDate.reduce((farthest, current) => {
    const farthestTime = new Date(farthest.endDateTime).getTime();
    const currentTime = new Date(current.endDateTime).getTime();
    return Math.abs(now.getTime() - currentTime) > Math.abs(now.getTime() - farthestTime) ? current : farthest;
  });

  if (farthestAssignment) {
    const date = new Date(farthestAssignment.endDateTime);
    return formatConnectionDateTime(date);
  }

  return null;
};
