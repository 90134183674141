export enum TelemetryEvent {
  Object_Id_Not_Found = 'Object_Id_Not_Found',
  High_Retry_Count = 'High_Retry_Count',
  Unhandled_Get_Policies_Error = 'Unhandled_Get_Policies_Error',
  Unhandled_Get_EligblePackage_Error = 'Unhandled_Get_EligiblePackage_Error',
  Recommendation_Feedback = 'Recommendation_Feedback',
  Recommendation_Clicked = 'Recommendation_Clicked',
  Recommendation_View_All_Available_Action_Button = 'View_All_Available_Action_Button_Clicked', //Event of switching to view all available page from action button
  Recommendation_View_All_Available_Bottom_Link = 'View_All_Available_Bottom_Link_Clicked', //Event of switching to view all available page from list bottom link
  Recommendation_List_Load_More = 'Recommendation_List_Load_More', //Event of clicking load more button in recommendation page
  Recommendation_List_Request = 'Recommendation_List_Request', //Event of opening request panel from recommendation page
  Recommendation_List_Request_Submit = 'Recommendation_List_Request_Submit', //Event of submission request panel from recommendation page
  Entitlement_List_Request = 'Entitlement_List_Request', //Event of opening request panel from available page
  Entitlement_List_Load_More = 'Entitlement_List_Load_More', //Event of clicking load more button in available page
  Entitlement_List_Request_Submit = 'Entitlement_List_Request_Submit', //Event of submission request panel from available page
}
