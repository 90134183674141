import { IAjaxRequest } from '@microsoft/portal-app/lib/auth/withAuth';
import { notificationsMerge } from '@microsoft/portal-app/lib/Notifications/helpers/notificationsMerge';
import {
  INotification,
  NotificationSeverity,
  NotificationType
} from '@microsoft/portal-app/lib/Notifications/models/INotification';
import {
  getResponseValue,
  IODataValueResponse
} from '@microsoft/portal-app/lib/odata-utils';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { TranslationOptions } from 'i18next';
import * as moment from 'moment';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';
import { v4 } from 'uuid';
import { IEntity } from '../../../models/ELM/IEntity';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { EntityType } from '../../../models/EntityType';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import {
  IEntitlementState,
  IRootEntitlementsState
} from '../../../models/IEntitlementState';
import { IEntityFilterable } from '../../../models/IEntityFilterable';
import { IEntityResult } from '../../../models/IEntityResult';
import { IFilter } from '../../../models/IFilter';
import { LoadMode } from '../../../models/IPageData';
import { getRequestWithAudience } from '../../../shared/AttachAudience';
import { filterEntitiesApiUrl } from '../../../shared/getApiUrl';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { registry } from '../myAccessRegistry';
import { authUserMatchesSubject } from '../../../shared/getUserStrings';
import { getUserFromAuth } from '../../../shared';

export const filterEntities = (
  state: IEntitlementState,
  action: IEntitlementAction<{
    entityType: string;
    filter: IFilter | undefined;
  }>
): IEntitlementState => {
  const { entityType, filter } = action.payload!;
  let filteredEntities: string[];

  if (filter === undefined) {
    filteredEntities = state[entityType].unfiltered.entities as string[];
  } else if (filter === null) {
    filteredEntities = [];
  } else {
    const entitiesById = state[entityType].entitiesById;
    filteredEntities = state[entityType].unfiltered.entities.filter(
      (id: string) => {
        const entity = entitiesById.get(id);
        switch (entityType) {
          case EntityType.validGrants:
          case EntityType.expiredGrants:
            return (
              entity &&
              filter.selectedCatalogs!.indexOf(
                entity.accessPackage.accessPackageCatalog.id
              ) > -1
            );
          case EntityType.grantRequests:
            const daysSinceSubmitted = moment()
              .startOf('day')
              .diff(moment(entity.createdDateTime).startOf('day'), 'days');
            return (
              entity &&
              (filter.selectedStates!.length === 0 ||
                filter.selectedStates!.indexOf(entity.requestState!) > -1) &&
              (daysSinceSubmitted <= filter.daysSinceSubmitted! ||
                filter.daysSinceSubmitted === -1) &&
                isRequestedForMatch(entity, EntityType.grantRequests, filter)
            );
          case EntityType.accessReviewDecisions:
            return (
              entity &&
              (filter.selectedRecommendations!.length === 0 ||
                filter.selectedRecommendations!.indexOf(entity.accessRecommendation!) > -1) &&
              (filter.selectedDecisions!.length === 0 ||
                filter.selectedDecisions!.indexOf(entity.reviewResult!) > -1)
            );
          default:
            return '';
        }
      }
    );
  }

  return {
    ...state,
    searchTerm: '',
    [entityType]: {
      ...state[entityType],
      filtered: {
        ...state[entityType].filtered,
        entities: filteredEntities
      },
      filterContext: filter
    }
  };
};
registry.add(EntitlementActions.filterEntities, filterEntities);

export const filterEntitiesOnServerEpic: Epic<
  IEntitlementAction<AnyPayload>,
  IRootEntitlementsState
> = (
  action$: ActionsObservable<
    IEntitlementAction<{
      entityType: string;
      filterContext: IFilter | undefined;
    }>
  >,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
    return action$
      .ofType(EntitlementActions.filterEntitiesOnServer)
      .switchMap((action: IEntitlementAction<IEntityFilterable>) => {
        let ajaxRequest: IAjaxRequest;

        const { entityType, filterContext } = action.payload!;
        const nextLink = _store.getState().app[entityType!].filtered.nextLink!;
        ajaxRequest = getRequestWithAudience(
          nextLink ? nextLink : filterEntitiesApiUrl(entityType!, filterContext!),
          entityType as EntityType
        );

        return ajax(ajaxRequest)
          .map((payload: IODataValueResponse<ReadonlyArray<IEntity>>) => {
            return {
              type: EntitlementActions.filterEntitiesOnServerSucceeded,
              payload: {
                entityType: action.payload!.entityType,
                entities: getResponseValue(payload),
                filterContext: filterContext,
                count: payload.response!['@odata.count'],
                nextLink: payload.response!['@odata.nextLink']
              }
            } as IEntitlementAction<IEntityResult<IEntity>>;
          })
          .catch(() =>
            Observable.of({
              type: EntitlementActions.filterEntitiesOnServerFailed,
              payload: entityType
            })
          );
      });
  };
registry.addEpic('filterEntitiesOnServerEpic', filterEntitiesOnServerEpic);

export const filterEntitiesOnServer = (
  state: IEntitlementState,
  action: IEntitlementAction<{ entityType: string; filterContext: IFilter }>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }
  const { entityType, filterContext } = action.payload!;
  if (
    filterContext === state[entityType].filterContext &&
    state[entityType].filtered.entities.length !==
    state[entityType].filtered.count
  ) {
    return {
      ...state,
      searchTerm: '',
      [entityType]: {
        ...state[entityType],
        isLoading: true,
        loadMode: LoadMode.LoadMore,
        filterContext: filterContext
      }
    };
  } else if (isFilterResultsFullyCached(state, entityType, filterContext!)) {
    return {
      ...state,
      searchTerm: '',
      [entityType]: {
        ...state[entityType],
        filterContext: filterContext
      }
    };
  } else {
    return {
      ...state,
      searchTerm: '',
      [entityType]: {
        ...state[entityType],
        filtered: {
          ...state[entityType].filtered,
          entities: [],
          count: undefined,
          nextLink: undefined
        },
        isLoading: true,
        loadMode: LoadMode.LoadMore,
        filterContext: filterContext
      }
    };
  }
};
registry.add(EntitlementActions.filterEntitiesOnServer, filterEntitiesOnServer);

const isFilterResultsFullyCached = (
  state: IEntitlementState,
  entityType: string,
  filter: IFilter
): boolean => {
  const entities = state[entityType];
  return (
    filter === state[entityType].filterContext &&
    entities.filtered.count === entities.filtered.entities.length
  );
};

const isRequestedForMatch = (entity: any, entityType: string, filter:IFilter): boolean =>{
  const curUser = getUserFromAuth();
  if (entityType === EntityType.grantRequests){
    if (filter.selectedRequestedFor?.length === 1) {
      // Check if current filter selection equals RequestedFor key
      if (filter.selectedRequestedFor[0] === 'Target'){
        return authUserMatchesSubject(curUser, entity.accessPackageAssignment.target);
      } else if (filter.selectedRequestedFor[0] === 'Others'){
        return authUserMatchesSubject(curUser, entity.requestor) && !authUserMatchesSubject(curUser, entity.accessPackageAssignment.target);
      }
    }
  }
  return true;
}

const filterEntitiesOnServerSucceeded = (
  state: IEntitlementState,
  action: IEntitlementAction<IEntityResult<IEntity>>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined || !action.payload.entities) {
    return state;
  }
  const { entityType, filterContext } = action.payload!;
  if (isFilterResultsFullyCached(state, entityType, filterContext!)) {
    return state;
  }

  const mappedEntities = action.payload.entities.map((item: IEntity) => {
    return [item.id, item];
  }) as [string, IEntity][];

  const newEntitiesById = new Map([
    // tslint:disable-next-line:no-any
    ...(Array.from(state[entityType].entitiesById) as any),
    ...mappedEntities
  ]);

  return {
    ...state,
    [entityType]: {
      ...state[entityType],
      entitiesById: newEntitiesById,
      filtered: {
        ...state[entityType].filtered,
        entities: state[entityType].filtered.entities.concat(
          action.payload.entities.map((item: IEntity) => item.id)
        ),
        count: action.payload.count,
        nextLink: action.payload.nextLink
      },
      filterContext: filterContext,
      isLoading: false,
      loadMode: undefined
    }
  };
};
registry.add(
  EntitlementActions.filterEntitiesOnServerSucceeded,
  filterEntitiesOnServerSucceeded
);

export const filterEntitiesOnServerFailed = (
  state: IEntitlementState,
  action: IEntitlementAction<Readonly<string>>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  const entityType = action.payload;

  const toastKey = LocaleKeys.generalErrorMessage;
  const toastOptions: TranslationOptions = {};

  const notifications: INotification[] = [
    {
      id: v4(),
      localizableMessage: {
        key: toastKey,
        options: toastOptions
      },
      createdDateTime: moment(),
      severity: NotificationSeverity.error,
      type: NotificationType.card
    }
  ];

  return {
    ...state,
    notifications: notificationsMerge(
      notifications,
      state.notifications,
      state.notificationsLimit
    ),
    [entityType]: {
      ...state[entityType],
      isLoading: false,
      loadMode: undefined
    }
  };
};
registry.add(
  EntitlementActions.filterEntitiesOnServerFailed,
  filterEntitiesOnServerFailed
);
