import { getUserFromAuth, isInternalUser } from './authHelper';
import { settingsManager } from './settings';

export const getUserProfileUrl = (): string => {
  if (isInternalUser()) {
    const tid = getUserFromAuth()!.tenantId;
    const baseUrl =  settingsManager.get('userProfileUrl')!;
    return `${baseUrl}?tenantId=${tid}#/profile`;
  }
  // if external tenant, return empty string.
  return '';
};
