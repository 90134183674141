import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { translate } from 'react-i18next';
import { connect, Dispatch } from 'react-redux';
import { EntitlementActions } from '../../../models';
import { EntityType } from '../../../models/EntityType';
import { IRootEntitlementsState } from '../../../models/IEntitlementState';
import { IFilter } from '../../../models/IFilter';
import { DecisionDetails } from './DecisionDetails';
import {
  IConnectedDecisionDetailsProps,
  IDecisionDetailsActions,
  IDecisionDetailsMappedProps
} from './DecisionDetails.types';

/**
 * Maps the external (i.e. own) props and the state to the properties of the DecisionDetailsFilter control.
 * @param state The current redux state to map to props
 * @param ownProps The external properties to map to props
 * @returns The mapped properties that along with the actions
 * becomes the props (IDecisionDetailsFilterProps) for the DecisionDetailsFilter control
 */
const mapStateToProps = (
  state: IRootEntitlementsState,
  ownProps: IConnectedDecisionDetailsProps
): IDecisionDetailsMappedProps => {
  const hidden = !state.app.showingDecisionDetails;

  return {
    ...ownProps,
    hidden
  };
};

/**
 * Maps the dispatch actions to the props for the DecisionDetailsFilter control.
 * @param dispatch Dispatches actions to the store
 * @returns The mapped actions that along with the mapped properties
 * becomes the props (IDecisionDetailsFilterProps) for the DecisionDetailsFilter control
 */
const mapDispatchToProps = (
  dispatch: Dispatch<IRootEntitlementsState>
): IDecisionDetailsActions => {
  const filterEntitiesAction = createAction<{
    entityType: string;
    filter: IFilter | undefined;
  }>(EntitlementActions.filterEntities, dispatch);
  const filterEntitiesOnServerAction = createAction<{
    entityType: string;
    filterContext: IFilter | undefined;
  }>(EntitlementActions.filterEntitiesOnServer, dispatch);
  return {
    filterEntities: (filter: IFilter | undefined) =>
      filterEntitiesAction({
        entityType: EntityType.grantRequests,
        filter: filter
      }),
    filterEntitiesOnServer: (filter: IFilter | undefined) =>
      filterEntitiesOnServerAction({
        entityType: EntityType.grantRequests,
        filterContext: filter
      })
  };
};

/**
 * A redux connected DecisionDetailsFilter control.
 * @description Supports translation
 */
export const ConnectedDecisionDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('MyAccess')(DecisionDetails));
