import {
  classNamesFunction,
  CommandButton,
  css,
  DefaultButton,
  FocusZone,
  FontClassNames,
  List,
  Persona,
  PersonaSize,
  ScrollablePane,
  Spinner,
  SpinnerSize,
} from '@fluentui/react';
import { withResponsiveMode } from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import * as React from 'react';

import { ErrorBanner } from '@microsoft/portal-app/lib/Banners/ErrorBanner';
import { IListPageStyles, styles } from '@microsoft/portal-app/lib/styling';

import { EntitlementActions, ICatalog } from '../../../models';
import { LocaleKeys, Routes } from '../../../shared';
import { ICatalogsListProps, ICatalogsListState } from './CatalogsList.types';

const cardStyles = require('@microsoft/portal-app/lib/styling/patterns/Card.scoped.scss');
const catalogListStyles = require('./CatalogsList.scoped.scss');
const myAccessStyles = require('../../../css/myAccess.scoped.scss');

const getClassNames = classNamesFunction<{}, IListPageStyles>();
const listPageStyles = getClassNames(styles);

@withResponsiveMode
export class CatalogsList extends React.Component<
  ICatalogsListProps,
  ICatalogsListState
> {
  constructor(nextProps: ICatalogsListProps) {
    super(nextProps);
  }

  public componentDidMount(): void {
    this.refreshCatalogs();
    this.props.setSearchContext(EntitlementActions.searchCatalogsOnServer);
    this.props.showFiltersIcon(false);
  }

  public render(): JSX.Element {
    const {
      t,
      errorHasOccurred,
      isTenantWhitelisted,
      catalogs,
      isLoading,
    } = this.props;

    // Render error message if applicable
    if (errorHasOccurred) {
      if (!isTenantWhitelisted) {
        return <ErrorBanner text={t(LocaleKeys.tenantNotWhitelistedMessage)} />;
      }

      return (
        <ErrorBanner
          text={t(LocaleKeys.errorMessage)}
          onAction={this.refreshCatalogs}
          actionText={t(LocaleKeys.retry)}
        />
      );
    }

    return (
      <ScrollablePane
        className={catalogListStyles.catalogsListContainer}
        onScroll={this.onScroll}
      >
        <FocusZone>
          <List<ICatalog>
            aria-labelledby="pageTitle"
            className={listPageStyles.list}
            items={catalogs}
            onRenderCell={this.renderCard}
            getItemCountForPage={() => 20}
            getKey={(catalog) => catalog.id}
          />
        </FocusZone>

        {isLoading ? (
          <Spinner
            size={SpinnerSize.large}
            label={t(LocaleKeys.loading)}
            className={css(FontClassNames.medium)}
          />
        ) : null}

        {this.shouldLoadCatalogs() ? (
          <div className={css(myAccessStyles.alignCenter)}>
            <DefaultButton
              onClick={this.loadMoreCatalogs}
              text={t(LocaleKeys.loadMore)}
            />
          </div>
        ) : null}
      </ScrollablePane>
    );
  }

  /**
   * Event listener to load more catalogs when scrolled to the bottom.
   */
  private onScroll = (e: React.UIEvent<HTMLElement>) => {
    const element = e.target as HTMLElement;

    if (
      element.offsetHeight + element.scrollTop + 25 >= element.scrollHeight &&
      this.shouldLoadCatalogs()
    ) {
      this.loadMoreCatalogs();
    }
  };

  private navigateTo = (catalog: ICatalog) => () => {
    this.props.history.push(`${Routes.catalogs}/${catalog.id}`);
  };

  private renderCard = (catalog: ICatalog) => (
    <CommandButton
      ariaLabel={this.props.t(LocaleKeys.catalogNameAriaLabel, {
        name: catalog.displayName,
      })}
      onClick={this.navigateTo(catalog)}
      className={css(
        'ms-card',
        'ms-actionCard',
        catalogListStyles.card,
        cardStyles.actionCard,
        cardStyles.cardPrimaryContent,
        cardStyles.cardListItem
      )}
    >
      <div
        className={css(
          catalogListStyles.cardHeader,
          cardStyles.cardContentIconAndText
        )}
      >
        <Persona
          hidePersonaDetails
          text={catalog.displayName}
          size={PersonaSize.size48}
        />
        <div
          title={catalog.displayName}
          className={css(cardStyles.cardTitle, catalogListStyles.cardTitle)}
        >
          <span className="ms-pii">{catalog.displayName}</span>
        </div>
      </div>
      <div
        title={catalog.description}
        className={css('ms-TileTwoLineBlock', cardStyles.cardDescription)}
      >
        <span className="ms-pii">{catalog.description}</span>
      </div>
    </CommandButton>
  );

  private refreshCatalogs = () => {
    if (this.props.catalogs.length === 0) {
      this.props.refreshCatalogs();
    }
  };

  private shouldLoadCatalogs = (): boolean => {
    const { pageMetaData, isLoading, isSearching, catalogs } = this.props;

    if (isSearching) {
      return (
        !pageMetaData.isFilteredEntitiesFullyCached &&
        !isLoading &&
        catalogs.length < pageMetaData.filteredEntityCount
      );
    }

    return (
      !pageMetaData.isAllEntitiesFullyCached &&
      !isLoading &&
      (catalogs.length < pageMetaData.allEntityCount || catalogs.length === 0)
    );
  };

  private loadMoreCatalogs = () => {
    const { isSearching, getMoreCatalogs, searchMoreCatalogs } = this.props;

    if (isSearching) {
      searchMoreCatalogs();
    } else {
      getMoreCatalogs();
    }
  };
}
