import { Dialog, DialogFooter, DialogType, ResponsiveMode } from '@fluentui/react';
import { useResponsiveMode } from '@uifabric/portal-ux/lib/styling/useResponsiveMode';
import React from 'react';
import { v4 } from 'uuid';

import { useFeatures, useTelemetryWithMetadata, useTranslation } from '../../../hooks';
import { IActivationStatusPayload } from '../../../models/RAM/IActivationStatusPayload';
import { IErrorDialog } from '../../../models/RAM/IErrorDialog';
import {
  IPimActivationRequestParameters,
  IPimActivationRequestProperties
} from '../../../models/RAM/IPimActivationRequestParameters';
import { IPimRoleManagementPolicyListRequestParameters } from '../../../models/RAM/IPimRoleManagementPolicyGetRequestParameter';
import { IVirtualMachine } from '../../../models/RAM/IVirtualMachine';
import { getSpinner, LocaleKeys } from '../../../shared';
import { ActivationMessageBarContent } from './ActivationMessageBarContent';
import { ActivationRequirementsContent } from './ActivationRequirementsContent';
import { Content } from './Content';
import { CustomizableContent } from './CustomizableContent';
import { FooterButtons } from './FooterButtons';
import { iso8601ToHours } from './QuickActivation.utils';

// TODO: Augusto is working on a new way to import scss files, ignore for now
// eslint-disable-next-line @typescript-eslint/no-var-requires
const ramDialogStyles = require('../Styles/ram.dialog.scss');

const quickActivationDialogStyles = {
  [ResponsiveMode.small]: ramDialogStyles.dialogSmall,
  [ResponsiveMode.medium]: ramDialogStyles.dialogMedium,
  [ResponsiveMode.large]: ramDialogStyles.dialogLarge,
  [ResponsiveMode.xLarge]: ramDialogStyles.dialogXLarge,
  [ResponsiveMode.xxLarge]: ramDialogStyles.dialogXXLarge,
  [ResponsiveMode.xxxLarge]: ramDialogStyles.dialogXXXLarge
};

export interface QuickActivationDialogProps {
  activateRamAccess: (parameters: IPimActivationRequestParameters) => void;
  setActivationStatus: (payload: IActivationStatusPayload) => void;
  onDismiss: VoidFunction;
  editRoleJustification: (newValue: string) => void;
  validateRoleActivation: (parameters: IPimActivationRequestParameters) => void;
  editRoleTicketing: (newTicketSystem?: string, newTicketNumber?: string) => void;
  editRoleDuration: (newValue: string) => void;
  roleInfo: QuickActivationDialogRole;
  isJustificationRequired?: boolean;
  isMfaRequirementMet?: boolean;
  isTicketingRequired?: boolean;
  setRamErrorDialog: (errorDialogContent?: IErrorDialog) => void;
  virtualMachine: IVirtualMachine;
  listRoleManagementPolicy: (parameters: IPimRoleManagementPolicyListRequestParameters) => void;
  setQuickActivationRole: (role: QuickActivationDialogRole) => void;
  maximumDuration?: string;
  clearQuickActivationDialogValue: () => void;
}

export interface QuickActivationDialogRole {
  roleName: string;
  roleStartTimeDisplayText: string;
  roleDurationInIso8601Format: string;
  rolePorts?: string;
  roleIpAddresses?: string;
  roleScope: string;
  roleProperties: IPimActivationRequestProperties;
  roleId: string;
  roleActivationScope: string;
}

export const QuickActivationDialog: React.FunctionComponent<QuickActivationDialogProps> = (
  props: QuickActivationDialogProps
) => {
  const t = useTranslation();
  const { reportCustomEventWithMetadata } = useTelemetryWithMetadata();
  const responsiveMode = useResponsiveMode();
  const [customizeSettings, setCustomizeSettings] = React.useState<boolean>(false);
  const [startTime, setStartTime] = React.useState<string>(props.roleInfo.roleProperties.scheduleInfo.startDateTime);
  const [roleDuration, setRoleDuration] = React.useState<string>(
    iso8601ToHours(props.roleInfo.roleDurationInIso8601Format, reportCustomEventWithMetadata).toString()
  );
  const [containerClassName, setContainerClassName] = React.useState<string>(
    quickActivationDialogStyles[responsiveMode]
  );
  const features = useFeatures();

  React.useEffect(() => {
    setContainerClassName(quickActivationDialogStyles[responsiveMode]);
  }, [responsiveMode]);

  React.useEffect(() => {
    props.validateRoleActivation({
      scope: props.roleInfo.roleScope,
      properties: props.roleInfo.roleProperties,
      roleAssignmentScheduleRequestName: v4()
    });
    reportCustomEventWithMetadata('ram/journey/2/quickActivate');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.roleInfo.roleId]);

  const dialogContentProps = {
    type: DialogType.close,
    title: customizeSettings
      ? `${t(LocaleKeys.ramCustomActivationTitle)} ${props.virtualMachine.displayName}`
      : t(LocaleKeys.ramQuickActivationDialogTitle)
  };

  return (
    <Dialog
      hidden={false}
      onDismiss={props.onDismiss}
      dialogContentProps={dialogContentProps}
      modalProps={{
        isBlocking: true,
        containerClassName
      }}
    >
      {!props.roleInfo.roleDurationInIso8601Format || props.isMfaRequirementMet === undefined ? (
        <div className={ramDialogStyles.spinnerContainer}>{getSpinner('')}</div>
      ) : (
        <>
          <ActivationMessageBarContent
            role={props.roleInfo}
            isMfaRequirementMet={props.isMfaRequirementMet}
            virtualMachine={props.virtualMachine}
          />
          {!features.enableRamFullActivationFlow || !customizeSettings ? (
            <Content role={props.roleInfo} />
          ) : (
            <CustomizableContent
              role={props.roleInfo}
              virtualMachine={props.virtualMachine}
              startTime={startTime}
              onStartTimeChange={setStartTime}
              roleDuration={roleDuration}
              onRoleDurationChange={setRoleDuration}
              customizeSettings={customizeSettings}
              listRoleManagementPolicy={props.listRoleManagementPolicy}
              setQuickActivationRole={props.setQuickActivationRole}
              maximumDuration={props.maximumDuration}
              clearQuickActivationDialogValue={props.clearQuickActivationDialogValue}
            />
          )}
          <ActivationRequirementsContent
            role={props.roleInfo}
            isJustificationRequired={props.isJustificationRequired}
            isTicketingRequired={props.isTicketingRequired}
            editJustification={props.editRoleJustification}
            editTicketing={props.editRoleTicketing}
            isMfaRequirementMet={props.isMfaRequirementMet}
          />
          <DialogFooter>
            <FooterButtons
              role={props.roleInfo}
              isJustificationRequired={props.isJustificationRequired}
              isTicketingRequired={props.isTicketingRequired}
              activateRamAccess={props.activateRamAccess}
              setActivationStatus={props.setActivationStatus}
              onDismiss={props.onDismiss}
              setRamErrorDialog={props.setRamErrorDialog}
              setCustomizeSettings={(customizeSettings: boolean) => setCustomizeSettings(customizeSettings)}
              customizeSettings={customizeSettings}
            />
          </DialogFooter>
        </>
      )}
    </Dialog>
  );
};
