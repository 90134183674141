import { LeftNav as TwoUILeftNav, LeftNavLinkProps } from '@amx/ux-twoui';
import { useAuth } from '@iamexperiences/react-auth/lib-commonjs/react';
import { getIAMUXAppsUrl, getIAMUXGroupsUrl } from '@microsoft/portal-app/lib/environment';
import { useLocalization } from '@microsoft/portal-app/lib/shared/localization';
import React from 'react';

import { useAppContext } from '../../Application/useAppContext';
import { buildMyStarUrl } from '../../components/Shared/Header/enabledApps.util';
import { useFeatures, useGetUserImageUrl } from '../../hooks';
import { canShowUserInitiatedIPF } from '../../InProductFeedback';
import { history } from '../../redux/configureStore';
import { isInternalUser, LocaleKeys } from '../../shared';
import { NAVIGATION_LINKS } from '../../shared/constants';
import { settingsManager } from '../../shared/settings';

export interface ILeftNavProps {
  selectedNavLinkKey: string;
  setLeftNavSelectedItem: (itemKey: string) => void;
}

export const LeftNav: React.FC<ILeftNavProps> = ({ selectedNavLinkKey, setLeftNavSelectedItem }) => {
  const { onShowIPF } = useAppContext().InProductFeedbackOption;
  const { userImageUrl } = useGetUserImageUrl();

  const [t] = useLocalization(['MyAccess']);
  const features = useFeatures();

  const user = useAuth().user();

  const leftNavItemsOnClick = (link: string, leftNavItemKey: string) => () => {
    setLeftNavSelectedItem(leftNavItemKey);
    history.push(link);
  };

  const allLeftNavItems: Array<{ item: LeftNavLinkProps; show?: boolean }> = [
    {
      item: {
        text: t(LocaleKeys.overviewUpper),
        'aria-label': t(LocaleKeys.overviewUpper),
        'data-bi-id': '',
        icon: 'Home',
        itemKey: NAVIGATION_LINKS.overview.key,
        linkInteraction: {
          interactionType: 'withOnClick',
          onClick: leftNavItemsOnClick(`${NAVIGATION_LINKS.overview.link}`, NAVIGATION_LINKS.overview.key)
        },
        linkType: 'directlink'
      },
      show: true
    },
    {
      item: {
        text: t(LocaleKeys.catalogUpper, { context: 'plural' }),
        'aria-label': t(LocaleKeys.catalogUpper, { context: 'plural' }),
        'data-bi-id': '',
        icon: 'Folder',
        itemKey: NAVIGATION_LINKS.catalogs.key,
        linkInteraction: {
          interactionType: 'withOnClick',
          onClick: leftNavItemsOnClick(NAVIGATION_LINKS.catalogs.link, NAVIGATION_LINKS.catalogs.key)
        },
        linkType: 'directlink'
      },
      show: features.enableCatalogView
    },
    {
      item: {
        text: t(LocaleKeys.accessPackage, { context: 'plural' }),
        'aria-label': t(LocaleKeys.accessPackage, { context: 'plural' }),
        'data-bi-id': '',
        icon: 'Package',
        itemKey: NAVIGATION_LINKS.accessPackages.key,
        linkInteraction: {
          interactionType: 'withOnClick',
          onClick: leftNavItemsOnClick(`${NAVIGATION_LINKS.accessPackages.link}`, NAVIGATION_LINKS.accessPackages.key)
        },
        linkType: 'directlink'
      },
      show: true
    },
    {
      item: {
        text: t(LocaleKeys.requestHistory),
        'aria-label': t(LocaleKeys.requestHistory),
        'data-bi-id': '',
        icon: 'History',
        itemKey: NAVIGATION_LINKS.requestHistory.key,
        linkInteraction: {
          interactionType: 'withOnClick',
          onClick: leftNavItemsOnClick(`${NAVIGATION_LINKS.requestHistory.link}`, NAVIGATION_LINKS.requestHistory.key)
        },
        linkType: 'directlink'
      },
      show: true
    },
    {
      item: {
        text: t(LocaleKeys.approvalUpper, { context: 'plural' }),
        'aria-label': t(LocaleKeys.approvalUpper, { context: 'plural' }),
        'data-bi-id': '',
        icon: 'AddFriend',
        itemKey: NAVIGATION_LINKS.requestApprovals.key,
        linkInteraction: {
          interactionType: 'withOnClick',
          onClick: leftNavItemsOnClick(
            `${NAVIGATION_LINKS.requestApprovals.link}`,
            NAVIGATION_LINKS.requestApprovals.key
          )
        },
        linkType: 'directlink'
      },
      show: isInternalUser()
    },
    {
      item: {
        text: t(LocaleKeys.accessReviews),
        'aria-label': t(LocaleKeys.accessReviews),
        'data-bi-id': '',
        icon: 'MultiSelect',
        itemKey: NAVIGATION_LINKS.accessReviews.key,
        linkInteraction: {
          interactionType: 'withOnClick',
          onClick: leftNavItemsOnClick(NAVIGATION_LINKS.accessReviews.link, NAVIGATION_LINKS.accessReviews.key)
        },
        linkType: 'directlink'
      },
      show: isInternalUser()
    },
    {
      item: {
        text: t(LocaleKeys.cloudInfrastructure),
        'aria-label': t(LocaleKeys.cloudInfrastructure),
        'data-bi-id': '',
        icon: 'Cloud',
        itemKey: NAVIGATION_LINKS.ram.key,
        linkInteraction: {
          interactionType: 'withOnClick',
          onClick: leftNavItemsOnClick(NAVIGATION_LINKS.ram.link, NAVIGATION_LINKS.ram.key)
        },
        linkType: 'directlink'
      },
      show: features.enableRam && isInternalUser()
    }
  ];

  const myStarApps: LeftNavLinkProps[] = [
    {
      text: t('myAccount'),
      'aria-label': t('myAccount'),
      'data-bi-id': '',
      icon: 'Home',
      itemKey: 'myAccount',
      linkInteraction: {
        interactionType: 'withHref',
        href: buildMyStarUrl(settingsManager.get('myAccountUrl'), user?.tenantId, user?.username),
        target: '_blank'
      },
      linkType: 'directlink'
    },
    {
      text: t('myApps'),
      'aria-label': t('myApps'),
      'data-bi-id': '',
      icon: 'Apps',
      itemKey: 'myApps',
      linkInteraction: {
        interactionType: 'withHref',
        href: buildMyStarUrl(settingsManager.get('myAppsUrl'), user?.tenantId, user?.username),
        target: '_blank'
      },
      linkType: 'directlink'
    },
    {
      text: t('myGroups'),
      'aria-label': t('myGroups'),
      'data-bi-id': '',
      icon: 'Group',
      itemKey: 'myGroups',
      linkInteraction: {
        interactionType: 'withHref',
        href: buildMyStarUrl(settingsManager.get('myGroupsUrl'), user?.tenantId, user?.username),
        target: '_blank'
      },
      linkType: 'directlink'
    }
  ];

  const ipfItem: LeftNavLinkProps = {
    'aria-label': t('inProductFeedback'),
    text: t('inProductFeedback'),
    'data-bi-id': '',
    icon: 'Feedback',
    itemKey: 'feedback',
    linkInteraction: {
      interactionType: 'withOnClick',
      onClick: () => {
        onShowIPF();
      }
    },
    linkType: 'directlink'
  };

  return (
    <TwoUILeftNav
      bypassBlocksButton={{
        text: t(LocaleKeys.skipToMainContent),
        'aria-label': t(LocaleKeys.skipToMainContent),
        'data-bi-id': 'leftnav.bypass-blocks-button',
        targetId: 'main-content',
      }}
      expandCollapseNavButton={{
        'aria-label': '',
        'data-bi-id': ''
      }}
      navHeader={{
        headerType: 'profileHeader',
        persona: {
          fallback: 'initials',
          imageUrl: userImageUrl ?? ''
        },
        primaryInfo: {
          kind: 'text',
          text: user?.name ?? ''
        },
        secondaryInfo: {
          text: user?.email ?? ''
        }
      }}
      selectedNavLink={{ mainKey: selectedNavLinkKey }}
      navLinkGroups={[
        { key: 'account-links', navLinks: allLeftNavItems.filter(({ show }) => show).map(({ item }) => item) },
        {
          key: 'others-apps',
          navLinks: myStarApps
        },
        ...(isInternalUser() && canShowUserInitiatedIPF() ? [{ key: 'others', navLinks: [ipfItem] }] : [])
      ]}
    />
  );
};
