import * as React from 'react';
import { IConsentFormState, IConsentFormProps } from './ConsentForm.types';
import { ConsentToggle } from '../../../ELM/ConsentToggle/ConsentToggle';

export class ConsentForm extends React.Component<
  IConsentFormProps,
  IConsentFormState
> {
  public render() {
    return (
      <ConsentToggle
        t={this.props.t}
        tenantDisplayName={this.props.tenantDisplayName}
        privacyUrl={this.props.privacyUrl}
        onChange={this.props.onToggleConsent}
      />
    );
  }
}