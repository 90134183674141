import { ActionButton, css, DefaultButton, Icon, Label, Link, PrimaryButton, Text } from '@fluentui/react';
import { toDataURL } from 'qrcode';
import React from 'react';

import {
  IVerifiableCredentialRequired,
  IVerifiableCredentialStatusType
} from '../../../../models/ELM/IVerifiableCredentialStatus';
import { getSpinner, LocaleKeys } from '../../../../shared';
import { logOnDevelopment } from '../../../../shared/consoleLogger';
import {
  IVerifyCredentialPresentationProps,
  IVerifyCredentialPresentationState,
  STATUS_POLL_CUTOFF_MS,
  STATUS_POLL_INTERVAL
} from './VerifyCredentialPresentation.types';

const styles = require('./VerifyCredentialPresentation.scoped.scss');

function userAgentIsMobile(): boolean {
  return navigator.userAgent.toLowerCase().includes('mobi')
}

export class VerifyCredentialPresentation extends React.Component<
  IVerifyCredentialPresentationProps,
  IVerifyCredentialPresentationState
> {
  constructor(nextProps: IVerifyCredentialPresentationProps) {
    super(nextProps);
    let url;
    let expiry;
    let estimatedExpiry = 0;
    if (nextProps.status && nextProps.status['@odata.type'] === IVerifiableCredentialStatusType.required) {
      const required = nextProps.status as IVerifiableCredentialRequired;
      url = required.url;
      expiry = new Date(required.expiryDateTime);
      estimatedExpiry = this.calculateEstimatedExpiry(expiry);
    }
    this.state = {
      loadingQrCode: true,
      url,
      expiry,
      estimatedExpiry,
      showingCantScanMessage: false,
      scanned: false,
      pollIntervalHandle: setInterval(() => {
        this.checkStatus();
      }, STATUS_POLL_INTERVAL)
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IVerifyCredentialPresentationProps): void {
    if (nextProps.status && nextProps.status['@odata.type'] !== IVerifiableCredentialStatusType.verified) {
      const expiry = new Date(nextProps.status.expiryDateTime);
      if (this.state.pollIntervalHandle === undefined && expiry.valueOf() > Date.now()) {
        // polling is unset on expiry or completition. Status can be reset on new requests.
        this.createNewRequest();
      }
    }
  }

  public UNSAFE_componentWillMount(): void {
    if (this.state.url) {
      this.generateQrCode(this.state.url);
    }
  }

  public componentWillUnmount(): void {
    clearInterval(this.state.pollIntervalHandle!);
  }

  public render(): JSX.Element {
    const { t, usingModal } = this.props;

    if (usingModal) {
      return (
        <div className={css(styles.content, styles.usingModal)}>
          {userAgentIsMobile() && <Text className={css(styles.subtitle)}>{t(LocaleKeys.tapVCButton)}</Text>}
          {this.renderAction()}
          {this.renderFooter()}
        </div>
      );
    }
    return (
      <div className={css(styles.content)}>
        <h3>{t(LocaleKeys.verifiableCredentialPrompt)}</h3>
        <p>{t(LocaleKeys.verifiableCredentialExplainer)}</p>

        <div className={styles.flexBreak} />
        {this.renderAction()}
      </div>
    );
  }

  private generateQrCode(url: string): void {
    this.setState({
      loadingQrCode: true
    });
    toDataURL(url, {}, (err: Error | null | undefined, dataUrl: string) => {
      if (!err) {
        this.setState({
          qrCode: dataUrl,
          loadingQrCode: false
        });
      }
    });
  }

  private createNewRequest(): void {
    if (this.state.pollIntervalHandle) {
      clearInterval(this.state.pollIntervalHandle);
    }
    this.props.pollStatus(this.props.grantRequest);
    this.setState({
      scanned: false,
      loadingQrCode: true,
      qrCode: undefined,
      url: undefined,
      expiry: undefined,
      pollIntervalHandle: setInterval(() => {
        this.checkStatus();
      }, STATUS_POLL_INTERVAL)
    });
  }

  private deleteExistingRequest(): void {
    if (this.state.pollIntervalHandle) {
      clearInterval(this.state.pollIntervalHandle);
    }
    const assignmentPolicyId = this.props.grantRequest.accessPackageAssignment?.assignmentPolicyId;
    if (assignmentPolicyId) {
      this.props.deleteVerifiableCredentialPresentation(assignmentPolicyId);
    }
    this.setState({
      scanned: false,
      loadingQrCode: true,
      qrCode: undefined,
      url: undefined,
      expiry: undefined,
      pollIntervalHandle: undefined
    });
  }

  private checkStatus(): void {
    const { status, pollingForStatus } = this.props;
    if (status && !pollingForStatus) {
      if (this.state.expiry && Date.now() + STATUS_POLL_CUTOFF_MS > this.state.expiry.valueOf()) {
        // automatic refresh before it has been opened/scanned
        if (status['@odata.type'] === IVerifiableCredentialStatusType.required) {
          this.deleteExistingRequest();
        } else {
          clearInterval(this.state.pollIntervalHandle!);
          this.setState({
            pollIntervalHandle: undefined
          });
          setTimeout(() => {
            this.setState({});
          }, STATUS_POLL_CUTOFF_MS);
        }
        return;
      }
      switch (status['@odata.type']) {
        case IVerifiableCredentialStatusType.required: {
          const requiredStatus = status as IVerifiableCredentialRequired;
          const newExpiry = new Date(requiredStatus.expiryDateTime);
          if (this.state.url !== requiredStatus.url || this.state.expiry?.valueOf() !== newExpiry.valueOf()) {
            const estimatedExpiry = this.calculateEstimatedExpiry(newExpiry);
            this.setState({
              ...this.state,
              url: requiredStatus.url,
              expiry: newExpiry,
              estimatedExpiry,
              scanned: false
            });
            this.generateQrCode(requiredStatus.url);
          }
          this.props.pollStatus(this.props.grantRequest);
          break;
        }
        case IVerifiableCredentialStatusType.retrieved: {
          const retrievedStatus = status as IVerifiableCredentialRequired;
          const newExpiry = new Date(retrievedStatus.expiryDateTime);
          if (!this.state.scanned || this.state.expiry?.valueOf() !== newExpiry.valueOf()) {
            const estimatedExpiry = this.calculateEstimatedExpiry(newExpiry);
            this.setState({
              ...this.state,
              expiry: newExpiry,
              estimatedExpiry,
              scanned: true
            });
          }
          this.props.pollStatus(this.props.grantRequest);
          break;
        }
        case IVerifiableCredentialStatusType.verified: {
          clearInterval(this.state.pollIntervalHandle!);
          this.setState({
            pollIntervalHandle: undefined
          });
          // next
          break;
        }
      }
    }
  }

  private renderAction(): JSX.Element {
    const { t, pollingForStatus, usingModal } = this.props;
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#mobile_tablet_or_desktop
    let actionItem: JSX.Element;
    if (this.state.expiry && this.state.expiry < new Date()) {
      actionItem = (
        <div className={css(styles.actions)}>
          <ActionButton
            className={css(styles.refresh, styles.staticHeight)}
            iconProps={{ iconName: 'Refresh' }}
            ariaLabel={t(LocaleKeys.refresh)}
            text={t(LocaleKeys.refresh)}
            onClick={() => {
              this.deleteExistingRequest();
            }}
            disabled={pollingForStatus}
          />
        </div>
      );
    } else if (this.state.scanned) {
      actionItem = (
        <div>
          <div className={css(styles.actions)}>{getSpinner(t(LocaleKeys.waitingForAuthenticator))}</div>
          {this.renderTimer()}
        </div>
      );
    } else if (this.state.loadingQrCode) {
      actionItem = <div className={css(styles.actions)}>{getSpinner(t(LocaleKeys.loading))}</div>;
    } else {
      if (userAgentIsMobile()) {
        actionItem = (
          <div>
            <div className={css(styles.actions, styles.mobileActions)}>
              <ActionButton
                className={css(styles.deeplink)}
                iconProps={{ iconName: 'Certificate' }}
                ariaLabel={t(LocaleKeys.verifiableCredentialRequest)}
                href={this.state.url}
                target="_blank"
                text={t(LocaleKeys.verifiableCredentialRequest)}
              />
            </div>
            {this.renderTimer()}
          </div>
        );
      } else {
        // callback for Cant Scan button:
        const cantScanCallback = (): void => {
          this.setState({
            showingCantScanMessage: true
          });
        };

        actionItem = (
          <div className={css(styles.actionsWrapper)}>
            <img alt={LocaleKeys.verifiableCredentialRequest} className={css(styles.scanImage, styles.actions)} src={this.state.qrCode} />
            {this.renderTimer()}

            {!usingModal && (
              <ActionButton
                className={css(styles.alternativeScan)}
                ariaLabel={t(LocaleKeys.cantScanImage)}
                onClick={() => {
                  cantScanCallback();
                }}
                text={t(LocaleKeys.cantScanImage)}
              />
            )}

            {this.renderCantScan()}
          </div>
        );
      }
    }

    if (usingModal) {
      return <div className={styles.actionItem}>{actionItem}</div>;
    }

    return (
      <div>
        {actionItem}

        <ActionButton
          className={css(styles.download)}
          iconProps={{ iconName: 'OpenInNewTabIcon' }}
          ariaLabel={t(LocaleKeys.downloadAuthenticator)}
          href="https://www.microsoft.com/account/authenticator"
          target="_blank"
          text={t(LocaleKeys.downloadAuthenticator)}
        />
      </div>
    );
  }

  private renderCantScan(): JSX.Element {
    if (this.state.showingCantScanMessage) {
      const t = this.props.t;
      const url = this.state.url;

      const copyToClipboard = (): void => {
        navigator.clipboard
          .writeText(this.state.url ?? '')
          .catch((error: Error) => {
            logOnDevelopment(`copy failed due to: ${error.message}`);
          });
      };

      return (
        <div>
          <Text>{t(LocaleKeys.cantScanInstruction)}</Text>
          <Label className={css(styles.cantScan)} onClick={copyToClipboard}>
            <Text className={css(styles.cantScanPrefix)}>{t(LocaleKeys.url)}</Text>
            <Text>{url}</Text>
            <Icon iconName="copy" />
          </Label>
        </div>
      );
    }
    return <div />;
  }

  private renderTimer(): React.ReactNode {
    const { t, usingModal } = this.props;
    const estimatedExpiry = this.state.estimatedExpiry;
    if (usingModal) {
      if (userAgentIsMobile()) {
        return;
      }
      return <span className={styles.timer}>{t(LocaleKeys.verifiedCredentialTimer)}</span>;
    }
    return (
      <span className={styles.timer}>
        {t(LocaleKeys.completeWithinTimeLimit, { numericalDuration: estimatedExpiry })}&nbsp;
        <Link
          className={css(styles.refresh)}
          ariaLabel={t(LocaleKeys.manualRefresh)}
          onClick={() => {
            this.deleteExistingRequest();
          }}
        >
          {t(LocaleKeys.manualRefresh)}
        </Link>
      </span>
    );
  }

  private calculateEstimatedExpiry(expiry: Date): number {
    return Math.round((expiry.valueOf() - Date.now()) / 60000);
  }

  private renderFooter(): JSX.Element {
    const { t } = this.props;
    if (userAgentIsMobile()) {
      return (
        <Link className={css(styles.externalDownload)} href="https://www.microsoft.com/account/authenticator" ariaLabel={t(LocaleKeys.downloadMicrosoftAuthenticator)} target="_blank">
          {t(LocaleKeys.downloadMicrosoftAuthenticator)}
          <Icon
            id="openInNewTab"
            iconName="OpenInNewTab"
            className={css(styles.externalDownloadIcon)}
          />
        </Link>
      )
    }

    const cantScanCallback = (): void => {
      this.setState({
        showingCantScanMessage: true
      });
    };
    const generateNewCodeText = t(LocaleKeys.generateNewCode)
    const cantScanQRCodeText = t(LocaleKeys.cantScanQRCode)
    return (
      <div className={css(styles.footer)}>
        <div className={css(styles.footerDownloadMessage)}>
          <Text>
            {`${t(LocaleKeys.verifiedCredentialAuthenticator) as string} `}
            <Link href="https://www.microsoft.com/account/authenticator" target="_blank">
              {t(LocaleKeys.downloadMicrosoftAuthenticator)}
            </Link>
          </Text>
        </div>
        <div className={css(styles.footerButtons)}>
          <PrimaryButton
            ariaLabel={generateNewCodeText}
            text={generateNewCodeText}
            onClick={() => {
              this.deleteExistingRequest();
            }}
          />
          <DefaultButton
            ariaLabel={cantScanQRCodeText}
            text={cantScanQRCodeText}
            onClick={() => {
              cantScanCallback();
            }}
          />
        </div>
      </div>
    )
  }

}