import { ILocalizableMessage } from '@microsoft/portal-app/lib/localization/ILocalizableMessage';
import { TranslationFunction } from 'i18next';

export const asLocalizedText = (
  textOrLocalizedMessage: string | ILocalizableMessage | undefined,
  t: TranslationFunction
): string => {
  if (textOrLocalizedMessage === undefined) {
    return '';
  }
  if (typeof textOrLocalizedMessage === 'string') {
    return t(textOrLocalizedMessage);
  }

  return t(textOrLocalizedMessage.key, textOrLocalizedMessage.options);
};
