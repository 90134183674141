import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { useDispatch } from 'react-redux';

import { EntitlementActions } from '../../models';
import { IGetPhotoActionPayload } from '../../redux';
import { useSelector } from '../index';

interface IUseGetPhoto {
  getPhoto: (payload: IGetPhotoActionPayload) => void;
  usersPhoto: Map<string, string | null>;
}

export const useGetPhoto = (): IUseGetPhoto => {
  const dispatch = useDispatch();
  const { usersPhoto } = useSelector((state) => ({
    usersPhoto: state?.app?.usersPhoto
  }));

  return {
    getPhoto: createAction(EntitlementActions.getPhoto, dispatch),
    usersPhoto
  };
};
