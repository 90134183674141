import { EntitlementActions } from '../../../models/EntitlementActions';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState } from '../../../models/IEntitlementState';
import { registry } from '../myAccessRegistry';

export const showBulkDecisionDialog = (
  state: IEntitlementState,
  action: IEntitlementAction<{ isShowing: boolean, decisionType: string }>
): IEntitlementState => {
  let nextState = {
    ...state,
    showingBulkDecisionDialog: action.payload!.isShowing,
    bulkDecisionType: action.payload!.decisionType
  };

  return nextState;
};
registry.add(EntitlementActions.showBulkDecisionDialog, showBulkDecisionDialog);
