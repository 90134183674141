import { AccessReviewsPivot } from '../../../components/AccessReviews/AccessReviewsList';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState } from '../../../models/IEntitlementState';
import { registry } from '../myAccessRegistry';

export const setReviewListPivot = (
  state: IEntitlementState,
  action: IEntitlementAction<{ pivot: AccessReviewsPivot }>
): IEntitlementState => {
  let nextState = {
    ...state,
    reviewListPivot: action.payload!.pivot
  };

  return nextState;
};
registry.add(EntitlementActions.setReviewListPivot, setReviewListPivot);
