import { AuthAutoLoginResult } from '@iamexperiences/react-auth/lib-commonjs/core';
import { getDomainNameFromPathName } from '../shared/getDomainNameFromPathName';
import { deleteQueryParams, getNormalUsername, getQueryParam } from './utils';

const USERNAME_QUERY_PARAM_NAMES = ['upn', 'login_hint'];
const TENANTID_QUERY_PARAM_NAMES = ['tenant', 'tenantId'];
const DOMAINHINT_QUERY_PARAM_NAMES = ['whr', 'domain_hint'];

export const autoLoginHandler = (href: string): AuthAutoLoginResult => {
  const url = new URL(href, window.location.origin);
  const tenantDomain = getDomainNameFromPathName();
  const username = getNormalUsername(getQueryParam(url, USERNAME_QUERY_PARAM_NAMES));
  const tenantId = tenantDomain || getQueryParam(url, TENANTID_QUERY_PARAM_NAMES);
  const domainHint = tenantDomain || getQueryParam(url, DOMAINHINT_QUERY_PARAM_NAMES);
  const newHref = deleteQueryParams(url, [
    ...USERNAME_QUERY_PARAM_NAMES,
    ...TENANTID_QUERY_PARAM_NAMES,
    ...DOMAINHINT_QUERY_PARAM_NAMES
  ]).href;

  if (username !== undefined || tenantId !== undefined || !!domainHint) {
    let extraQueryParameters: Record<string, string> | undefined;

    if (domainHint) {
      extraQueryParameters = {
        domain_hint: domainHint
      };
    }

    return {
      hints: {
        username,
        tenantId,
        extraQueryParameters
      },
      url: newHref
    };
  }

  return null;
};
