import { AppPanel } from '@uifabric/portal-ux/lib/AppPanel';

import {
  ChoiceGroup,
  css,
  DefaultButton,
  IButtonStyles,
  IChoiceGroupOption,
  PanelType,
  Stack,
  TextField,
} from '@fluentui/react';
import { withResponsiveMode } from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import * as React from 'react';
import { DecisionType } from '../../../models/RequestApprovals/DecisionType';
import { DetailsType } from '../../../models/RequestApprovals/DetailsType';
import * as ApprovalHelper from '../../../shared/approvalPersonaHelper';
import { getDecisionFromGrantRequest } from '../../../shared/getDecisionFromRequest';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { getSpinner } from '../../../shared/spinner';
import { PanelOption } from '../../Shared/PanelOption/PanelOption';
import { PanelSubHeading } from '../../Shared/PanelSubHeading/PanelSubHeading';
import { GrantRequestDueDateInfo } from '../GrantRequestDueDateInfo/GrantRequestDueDateInfo';
import {
  IPendingApprovalGrantRequestDetailsProps,
  IPendingApprovalGrantRequestDetailsState,
} from './PendingApprovalGrantRequestDetails.types';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');

@withResponsiveMode
export class PendingApprovalGrantRequestDetails extends React.Component<
  IPendingApprovalGrantRequestDetailsProps,
  IPendingApprovalGrantRequestDetailsState
> {
  constructor(nextProps: IPendingApprovalGrantRequestDetailsProps) {
    super(nextProps);
    this.state = {
      decision: getDecisionFromGrantRequest(
        nextProps.pendingApprovalGrantRequest!,
        nextProps.decisionType
      ),
      textFieldClean: true,
    };
  }

  public render(): JSX.Element {
    const { t } = this.props;

    return (
      <AppPanel
        styles={{ headerText: { fontWeight: '400' } }}
        isBlocking={true}
        isOpen={true}
        onDismiss={this.props.onDismiss}
        type={PanelType.smallFixedFar}
        headerText={t(LocaleKeys.accessRequest)}
        onRenderFooterContent={this._onRenderFooterContent}
        isLightDismiss={true}
        hasCloseButton={false}
        isFooterAtBottom={true}
      >
        {this._onRenderBody()}
      </AppPanel>
    );
  }

  private _onRenderBody = (): JSX.Element => {
    const { pendingApprovalGrantRequest, t, submitting, isLoading } = this.props;

    if (isLoading) {
      return (
        <div className={css(myAccessStyles.marginTopXLarge)}>
          {getSpinner(t(LocaleKeys.loading))}
        </div>
      );
    }

    if (!pendingApprovalGrantRequest) {
      return <div />;
    }

    if (submitting) {
      return (
        <div className={css(myAccessStyles.marginTopXLarge)}>
          {getSpinner(t(LocaleKeys.submitting))}
        </div>
      );
    }

    const panelOptionIcon = 'chevronRight';
    const createdBy = pendingApprovalGrantRequest.requestor;
    const requestorName = createdBy.displayName || createdBy.email || '';
    const target = pendingApprovalGrantRequest.accessPackageAssignment.target!;
    const targetName = target.displayName || target.email || '';
    const entitlementName = pendingApprovalGrantRequest.accessPackageAssignment!.accessPackage!.displayName || '';

    const isApproverJustificationRequiredForCurrentStage =
      ApprovalHelper.isApproverJustificationRequiredForCurrentStage(pendingApprovalGrantRequest);

    const options: IChoiceGroupOption[] = [
      { key: 'Approve', text: t(LocaleKeys.approve, { context: 'capitalize' }) },
      { key: 'Deny', text: t(LocaleKeys.deny, { context: 'capitalize' }) }
    ];

    return (
      <div>
        <div className={css(myAccessStyles.appPanelSubHeading)}>
          <PanelSubHeading
            t={t}
            title={LocaleKeys.accessTo}
            primaryValue={entitlementName}
          />
          <PanelSubHeading
            t={t}
            title={LocaleKeys.requestedBy}
            primaryValue={requestorName}
            secondaryValue={createdBy.principalName}
          />
          <PanelSubHeading
            t={t}
            title={LocaleKeys.requestedFor}
            primaryValue={targetName}
            secondaryValue={target.principalName}
          />

          <div className={css(myAccessStyles.marginTopXSmall)}>
            <GrantRequestDueDateInfo t={this.props.t} grantRequest={pendingApprovalGrantRequest} />
          </div>
        </div>

        <div>
          <PanelOption
            t={t}
            title={LocaleKeys.requestDetails}
            iconName={panelOptionIcon}
            // tslint:disable-next-line:jsx-no-lambda
            onClick={() => this.props.showDetails(DetailsType.Request)}
          />
          <PanelOption
            t={t}
            title={LocaleKeys.packageDetails}
            iconName={panelOptionIcon}
            showingPackageDetails={this.props.showingPackageDetails}
            // tslint:disable-next-line:jsx-no-lambda
            onClick={() => this.props.showDetails(DetailsType.Package)}
          />
          <PanelOption
            t={t}
            title={LocaleKeys.approvalHistory}
            iconName={panelOptionIcon}
            // tslint:disable-next-line:jsx-no-lambda
            onClick={() => this.props.showDetails(DetailsType.Approval)}
          />
        </div>
        <div
          className={css(
            myAccessStyles.marginTopXSmall,
            myAccessStyles.marginBottomSmall
          )}
        >
          <ChoiceGroup
            options={options}
            defaultSelectedKey={DecisionType[this.state.decision?.reviewResult!]}
            onChange={this._onDecisionChange}
            label={t(LocaleKeys.decision)}
            required={true}
          />
          <TextField
            label={t(LocaleKeys.provideReason)}
            className={'ms-pii'}
            value={this.state.decision && this.state.decision.justification}
            onChange={this._onReasonChanged}
            required={
              isApproverJustificationRequiredForCurrentStage
            }
            onGetErrorMessage={this._getErrorMessage}
            placeholder={
              isApproverJustificationRequiredForCurrentStage
                ? t(LocaleKeys.required)
                : null
            }
            multiline
          />
        </div>
      </div>
    );
  };

  private _onReasonChanged = (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    this.setState({
      ...this.state,
      decision: {
        ...this.state.decision,
        justification: newValue,
      },
      textFieldClean: false,
    });
  };

  private _getErrorMessage = (value: string): string => {
    const { pendingApprovalGrantRequest, t } = this.props;

    if (
      pendingApprovalGrantRequest &&
      !ApprovalHelper.isApproverJustificationRequiredForCurrentStage(pendingApprovalGrantRequest)
    ) {
      return '';
    }

    return value.length !== 0 || this.state.textFieldClean
      ? t(LocaleKeys.empty)
      : t(LocaleKeys.reasonNotEmpty);
  };

  private _onRenderFooterContent = (): JSX.Element => {
    const { t, submitting, pendingApprovalGrantRequest } = this.props;

    if (!pendingApprovalGrantRequest || submitting) {
      return <div />;
    }

    const buttonStyles: IButtonStyles = {
      root: {
        borderColor: 'rgb(138, 136, 134)',
        borderRadius: '2px',
        backgroundColor: 'transparent',
      },
      rootDisabled: {
        borderColor: 'transparent',
      },
    };

    const isApproverJustificationRequiredForCurrentStage =
      ApprovalHelper.isApproverJustificationRequiredForCurrentStage(pendingApprovalGrantRequest);
    const reason = this.state.decision && this.state.decision.justification;

    const isApproverReasonNotMet = (
      isApproverJustificationRequiredForCurrentStage &&
      reason!.length === 0) || (
        this.state.decision?.reviewResult !== DecisionType.Approve &&
        this.state.decision?.reviewResult !== DecisionType.Deny);

    return (
      <div>
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <DefaultButton
            // tslint:disable-next-line:jsx-no-lambda
            onClick={() => this._onSubmitDecision()}
            disabled={isApproverReasonNotMet}
            text={t(LocaleKeys.submit)}
            styles={buttonStyles}
          />
          <DefaultButton
            // tslint:disable-next-line:jsx-no-lambda
            onClick={this.props.onDismiss}
            disabled={false}
            text={t(LocaleKeys.cancel)}
            styles={buttonStyles}
          />
        </Stack>
      </div>
    );
  };

  private _onDecisionChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption): void => {
    this.setState({
      ...this.state,
      decision: {
        ...this.state.decision,
        reviewResult: DecisionType[option!.key]
      }
    });
  };

  private _onSubmitDecision = (): void => {
    if (this.props.isAnswerEditable) {
      this.state.decision!.answers = this.props.answers;
    }
    if (this.props.enableELMRecordDecision || this.props.isAnswerEditable) {
      this.props.patchDecision(this.state.decision!);
    } else {
      this.props.postDecision(this.state.decision!);
    }
  };
}
