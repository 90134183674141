import { feedbackItems,IPFFeatureArea } from './constants';
import {
  getIPFOverrideSetting, isInProductFeedbackEnabled,
} from './util';

export function canShowUserInitiatedIPF(): boolean {
  return isInProductFeedbackEnabled();
}
export function canShowSystemInitiatedIPF(
  localStorage: Storage,
  feedbackId: string,
  tenantId: string,
  userId: string,
  featureArea: IPFFeatureArea,
  surveyName?: string,
): boolean {
  const ipfOverrideSetting = getIPFOverrideSetting('SystemInitiatedIPF');
  if (ipfOverrideSetting.isOverriden) return ipfOverrideSetting.shouldShow;
  // if no override setting found, proceed with normal check

  return (
    !!feedbackItems[feedbackId] &&
    isInProductFeedbackEnabled() &&
    passIPFSamplingAndLastSurveyRules(localStorage, tenantId, userId, feedbackId, featureArea, surveyName)
  );
}

export function getInProductFeedbackLocalStorageKey(
  tenantId: string,
  userId: string,
  featureArea: string,
  surveyName?: string,
): string {
  return tenantId + '_' + userId + '_' + featureArea + (surveyName ? '_' + surveyName : '');
}

/**
 * Rules: sampling percentage and lastSurveyTimeInSeconds check
 */
function passIPFSamplingAndLastSurveyRules(
  localStorage: Storage,
  tenantId: string,
  userId: string,
  feedbackId: string,
  featureArea: IPFFeatureArea,
  surveyName?: string,
): boolean {
  try {
    let pass = false;
    const now: Date = new Date();
    const key = getInProductFeedbackLocalStorageKey(tenantId, userId, featureArea, surveyName);
    const feedbackItem = feedbackItems[feedbackId];
    const item = localStorage.getItem(key);
    if (item) {
      const lastDate = new Date(item);
      const diff = (now.valueOf() - lastDate.valueOf()) / 1000;
      if (
        diff > feedbackItem.lastSurveyTimeInSeconds &&
        Math.random() * 100 < feedbackItem.samplingPercentageThreshold
      ) {
        localStorage.setItem(key, now.toString());
        pass = true;
      }
    } else {
      localStorage.setItem(key, now.toString());
      pass = true;
    }
    return pass;
  } catch (e) {
    return false;
  }
}
