import { ILocalizableMessage } from '@microsoft/portal-app/lib/localization/ILocalizableMessage';
import '@microsoft/portal-app/lib/styling/patterns/Dialog.scss';
import { TranslationFunction } from 'i18next';
import {
  css,
  DefaultButton,
  Dialog,
  DialogFooter,
  PrimaryButton
} from '@fluentui/react';
import { ResponsiveMode } from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import * as React from 'react';
import { IAccessReviewDecision } from '../../../models/AccessReviews/IAccessReviewDecision';
import { DecisionType } from '../../../models/RequestApprovals/DecisionType';
import { RecommendationType } from '../../../models/RequestApprovals/RecommendationType';
import { asLocalizedText } from '../../../shared/asLocalizedText';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { getSpinner } from '../../../shared/spinner';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');

export interface IBulkDecisionDialogProps {
  userList: IAccessReviewDecision[];
  isSubmitting: boolean;
  submittingText?: string | ILocalizableMessage;
  t: TranslationFunction;
  responsiveMode?: ResponsiveMode;
  onSubmit(acceptAll: boolean): void;
  onDismiss(): void;
}

export class AcceptRecommendations extends React.Component<IBulkDecisionDialogProps, {}> {
  public render(): JSX.Element {
    const {
      isSubmitting,
      submittingText,
      onDismiss,
      userList,
      responsiveMode,
      t
    } = this.props;

    const acceptAll = !(userList && userList.length > 0);

    let updatedUserCount = 0;
    userList.forEach((decision: IAccessReviewDecision) => {
      if (
        decision.reviewResult === DecisionType.NotReviewed
        && decision.accessRecommendation !== RecommendationType.NotAvailable
      ) {
        updatedUserCount++;
      }
    });

    let acceptMessage = '';
    if (!isSubmitting) {
      if (acceptAll) {
        acceptMessage = t(LocaleKeys.acceptAllRecommendations);
      } else if (updatedUserCount === 0) {
        acceptMessage = t(LocaleKeys.acceptRecommendationsUnavailable);
      } else {
        acceptMessage = asLocalizedText(
          {
            key: LocaleKeys.acceptRecommendationsMessage,
            options: {
              recommendationCount: updatedUserCount,
              recommendation: t(LocaleKeys.recommendation, { count: updatedUserCount }),
              count: updatedUserCount
            }
          },
          t
        );
      }
    }

    return (
      <Dialog
        hidden={false}
        isOpen={true}
        onDismiss={onDismiss}
        title={t(LocaleKeys.acceptRecommendations)}
        modalProps={{
          containerClassName:
            responsiveMode! < ResponsiveMode.large
              ? myAccessStyles.smallDialog
              : ''
        }}
      >
        <div className={css(myAccessStyles.marginBottomSmall)}>
          {t(LocaleKeys.accessReviewDecisionWarning)}
        </div>
        <div className={css(myAccessStyles.marginBottomMedium)}>
          {t(LocaleKeys.accessReviewRecommendationWarning)}
        </div>
        {!isSubmitting ?
          <div>
            {acceptMessage}
          </div> : null}
        <DialogFooter>
          {isSubmitting
            ? getSpinner(
              asLocalizedText(submittingText || LocaleKeys.loading, t)
            )
            : <div>
              <PrimaryButton
                // tslint:disable-next-line:jsx-no-lambda
                onClick={() => this.props.onSubmit(acceptAll)}
                text={t(LocaleKeys.submit)}
                className={css(myAccessStyles.marginRightSmall)}
                disabled={updatedUserCount === 0 && !acceptAll}
              />
              <DefaultButton
                onClick={this.props.onDismiss}
                text={t(LocaleKeys.cancel)}
              />
            </div>}
        </DialogFooter>
      </Dialog>
    );
  }
}
