import { IPageData } from '../models/IPageData';
import { IPageMetaData } from '../models/IPageMetaData';

export const getPageMetaDataFromEntities = (
  // tslint:disable-next-line
  pageData: IPageData<any>,
  hasErrorOccurred?: boolean
): IPageMetaData => {
  const allEntityCount = pageData.unfiltered.count!;
  const isEmpty = pageData.entitiesById.size === 0;
  const isAllEntitiesFullyCached = hasErrorOccurred
    ? hasErrorOccurred
    : allEntityCount === pageData.unfiltered.entities.length;
  const filteredEntityCount = isAllEntitiesFullyCached
    ? pageData.filtered.entities.length
    : pageData.filtered.count
    ? pageData.filtered.count
    : 0;
  const isFilteredEntitiesFullyCached =
    pageData.filtered.entities.length === pageData.filtered.count;
  return {
    isEmpty,
    isAllEntitiesFullyCached,
    allEntityCount,
    isFilteredEntitiesFullyCached,
    filteredEntityCount
  };
};
