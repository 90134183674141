import { IODataValueResponse } from '@microsoft/portal-app/lib/odata-utils';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { errorHandler } from '@microsoft/portal-app/lib/redux/observableErrorHandler';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import 'rxjs/add/observable/empty';
import { Observable } from 'rxjs/Observable';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';
import { ITenant } from '../../../models/ELM/ITenant';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import {
  IEntitlementState,
  IRootEntitlementsState
} from '../../../models/IEntitlementState';
import { getRequestWithAudience } from '../../../shared/AttachAudience';
import {
  getTenantApiUrl
} from '../../../shared/getApiUrl';
import { registry } from '../myAccessRegistry';

export const getTenantEpic: Epic<
  IEntitlementAction<AnyPayload>,
  IRootEntitlementsState
> = (
  action$: ActionsObservable<IEntitlementAction<ITenant>>,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
    return action$
      .ofType(EntitlementActions.getTenant)
      .switchMap((_action: IEntitlementAction<ITenant>) => {
        if (!_store.getState().user.auth!.isAuthenticated) {
          return Observable.empty<never>();
        }

        const url = getTenantApiUrl();
        const ajaxRequest = getRequestWithAudience(url);
        return ajax(ajaxRequest)
          .map((payload: IODataValueResponse<ITenant>) => {
            return {
              type: EntitlementActions.getTenantSucceeded,
              payload: payload.response
            } as IEntitlementAction<ITenant>;
          })
          .catch(
            errorHandler<IEntitlementAction>(EntitlementActions.getTenantFailed)
          );
      });
  };
registry.addEpic('getTenantEpic', getTenantEpic);

export const getTenantSucceeded = (
  state: IEntitlementState,
  action: IEntitlementAction<ITenant>
): Readonly<IEntitlementState> => {
  const payload = action.payload!;
  if (payload === undefined) {
    return state;
  }

  const tenant = payload;
  if (tenant && tenant.initialDomainName) {
    const newQuery = `@${tenant.initialDomainName}`;
    history.replaceState('', '', newQuery + location.search + location.hash);
  }
  return {
    ...state,
    tenantContext: {
      ...state.tenantContext!,
      tenantId: tenant.id,
      domainName: tenant.initialDomainName,
      tenant: tenant
    }
  };
};
registry.add(EntitlementActions.getTenantSucceeded, getTenantSucceeded);

export const getTenantFailed = (
  state: IEntitlementState,
  // tslint:disable-next-line:no-any
  action: IEntitlementAction<Readonly<any>>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  const payload = action.payload;
  const errorCode = payload && payload.status;
  return {
    ...state,
    tenantContext: {
      ...state.tenantContext!,
      isChecking: false,
      isRegistered: false
    },
    errorHasOccurred: true,
    isTenantWhitelisted: errorCode !== 403
  };
};
registry.add(EntitlementActions.getTenantFailed, getTenantFailed);
