import { EntitlementActions } from '../../../models/EntitlementActions';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState } from '../../../models/IEntitlementState';
import { registry } from '../../modules/myAccessRegistry';

export const setVirtualMachinesSearchTerm = (
  state: IEntitlementState,
  action: IEntitlementAction<string>
): IEntitlementState => {
  return {
    ...state,
    virtualMachines: { ...state.virtualMachines, searchTerm: action.payload }
  };
};
registry.add(EntitlementActions.setVirtualMachinesSearchTerm, setVirtualMachinesSearchTerm);
