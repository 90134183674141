import { EntitlementActions } from '../../../models/EntitlementActions';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState } from '../../../models/IEntitlementState';
import { IListColumn } from '../../../models/IListColumn';
import { registry } from '../myAccessRegistry';

export const setSortedByColumn = (
  state: IEntitlementState,
  action: IEntitlementAction<{
    // tslint:disable-next-line:no-any
    column: IListColumn<any> | undefined;
    entityType: string;
  }>
): IEntitlementState => {
  if (action.payload === undefined) {
    return state;
  }
  const sortedColumn = action.payload.column;
  const entityType = action.payload.entityType;
  return {
    ...state,
    [entityType]: {
      ...state[entityType],
      sortedColumn: sortedColumn
    }
  };
};
registry.add(EntitlementActions.setSortedByColumn, setSortedByColumn);
