import * as React from 'react';
import { DocumentCard, DocumentCardTitle, Link, DocumentCardDetails } from '@fluentui/react';
import { Icon } from '@fluentui/react/lib/Icon';
import { LocaleKeys } from '../../../shared';
import { useTranslation } from '../../../hooks';
const overviewCardStyle = require('../../../css/overview.scoped.scss');

export interface IOverviewErrorCard {
  title: string;
  refreshCardAction: () => void;
}

export function OverviewErrorCard(props: IOverviewErrorCard) {
  const t = useTranslation();

  return (
    <DocumentCard className={overviewCardStyle.cardNormal}>
      <DocumentCardTitle title={props.title} className={overviewCardStyle.cardTitle} />
      <DocumentCardDetails className={overviewCardStyle.emptyStatus}>
        <Icon iconName="Refresh" className={overviewCardStyle.refreshIcon}/>
        <div>
          <span>{t(LocaleKeys.somethingWentWrong)}</span>
          <Link onClick={props.refreshCardAction}
          >
            {t(LocaleKeys.overviewTryAgain)}
          </Link>
        </div>
      </DocumentCardDetails>
    </DocumentCard>
  );
}
