import { ILocalizableMessage } from '@microsoft/portal-app/lib/localization/ILocalizableMessage';
import { TranslationFunction } from 'i18next';
import {
  css,
  DefaultButton,
  Dialog,
  DialogFooter,
  PrimaryButton,
  TextField
} from '@fluentui/react';
import { ResponsiveMode } from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import * as React from 'react';
import { IAccessReviewDecision } from '../../../models/AccessReviews/IAccessReviewDecision';
import { DecisionType } from '../../../models/RequestApprovals/DecisionType';
import { asLocalizedText } from '../../../shared/asLocalizedText';
import { emptyGuid } from '../../../shared/constants';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { getSpinner } from '../../../shared/spinner';

import '@microsoft/portal-app/lib/styling/patterns/Dialog.scss';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');

export interface IBulkDecisionDialogProps {
  decisionType: string;
  userList: IAccessReviewDecision[];
  isSubmitting: boolean;
  submittingText?: string | ILocalizableMessage;
  justificationRequired: boolean | undefined;
  t: TranslationFunction;
  responsiveMode?: ResponsiveMode;
  onSubmit(justification: string): void;
  onDismiss(): void;
  selectAll?: boolean;
  totalCount?: number;
}

export interface IBulkDecisionDialogState {
  justification: string;
}

export class BulkDecisionDialog extends React.Component<
  IBulkDecisionDialogProps,
  IBulkDecisionDialogState
> {
  constructor(nextProps: IBulkDecisionDialogProps) {
    super(nextProps);
    this.state = {
      justification: ''
    };
  }
  public render(): JSX.Element {
    const {
      decisionType,
      userList,
      isSubmitting,
      submittingText,
      justificationRequired,
      responsiveMode,
      t
    } = this.props;

    let overriddenDecisionCount = this._overriddenDecisionCount();
    const useTotalDecisionsCount = this.props.selectAll && this.props.totalCount && this.props.totalCount > 0;
    if (useTotalDecisionsCount) {
      overriddenDecisionCount = 0;
    }

    let title = LocaleKeys.approveAccess;
    let labelType = LocaleKeys.accessReviewApproval;
    let reasonRequired = justificationRequired;
    let disableSubmit = false;

    if (decisionType === DecisionType.Deny) {
      title = LocaleKeys.denyAccess;
      labelType = LocaleKeys.accessReviewDenial;
      reasonRequired = false;
    }

    if (decisionType === DecisionType.DontKnow) {
      title = LocaleKeys.dontKnow;
      labelType = LocaleKeys.accessReviewDontKnows;
      reasonRequired = false;
    }

    if (reasonRequired && this.state.justification === '') {
      disableSubmit = true;
    }

    return (
      <Dialog
        hidden={false}
        isOpen={true}
        onDismiss={this.props.onDismiss}
        title={t(title)}
        modalProps={{
          containerClassName:
            responsiveMode! < ResponsiveMode.large
              ? myAccessStyles.smallDialog
              : ''
        }}
      >
        <div className={css(myAccessStyles.marginBottomSmall)}>
          {t(LocaleKeys.accessReviewDecisionWarning)}
        </div>
        {
          !isSubmitting ? (
            <div>
              {
                overriddenDecisionCount > 0 ?
                  (
                    <div className={css(myAccessStyles.marginBottomMedium)}>
                      {t(LocaleKeys.previousDecisionsChanged, { count: overriddenDecisionCount })}
                    </div>
                  ) : null
              }
              <div className={css(myAccessStyles.marginBottomMedium)}>
                {
                  asLocalizedText(
                    {
                      key: LocaleKeys.entityNameWithCount,
                      options: {
                        count: (useTotalDecisionsCount) ? this.props.totalCount : userList.length,
                        entityName: t(labelType, { count: userList!.length })
                      }
                    },
                    t
                  )
                }
              </div>
              <div
                className={css(
                  myAccessStyles.marginTopSmall,
                  myAccessStyles.marginBottomMedium
                )}
              >
                <TextField
                  label={t(LocaleKeys.reason)}
                  value={this.state.justification}
                  className={'ms-pii'}
                  required={reasonRequired}
                  onChange={this._onJustificationChanged}
                  disabled={isSubmitting}
                  multiline
                />
              </div>
            </div>
          ) : null
        }
        <DialogFooter>
          {isSubmitting ? (
            getSpinner(asLocalizedText(submittingText || LocaleKeys.loading, t))
          ) : (
            <div>
              <PrimaryButton
                // tslint:disable-next-line:jsx-no-lambda
                onClick={() => this.props.onSubmit(this.state.justification)}
                text={t(LocaleKeys.submit)}
                className={css(myAccessStyles.marginRightSmall)}
                disabled={disableSubmit}
              />
              <DefaultButton
                onClick={this.props.onDismiss}
                text={t(LocaleKeys.cancel)}
              />
            </div>
          )}
        </DialogFooter>
      </Dialog>
    );
  }

  private _onJustificationChanged = (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    this.setState({
      ...this.state,
      justification: newValue!
    });
  }

  private _overriddenDecisionCount(): number {
    let decisionCount = 0;
    this.props.userList.forEach((decision: IAccessReviewDecision) => {
      if (decision
        && decision.reviewedBy
        && decision.reviewedBy.id
        && decision.reviewedBy!.id !== emptyGuid) {
        decisionCount++;
      }
    });
    return decisionCount;
  }
}
