import { IAjaxRequest } from '@microsoft/portal-app/lib/auth/withAuth';
import { notificationsMerge } from '@microsoft/portal-app/lib/Notifications/helpers/notificationsMerge';
import {
  INotification,
  NotificationSeverity,
  NotificationType
} from '@microsoft/portal-app/lib/Notifications/models/INotification';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { errorHandler } from '@microsoft/portal-app/lib/redux/observableErrorHandler';
import { TranslationOptions } from 'i18next';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';
import { catchError, flatMap } from 'rxjs/operators';
import { v4 as guid } from 'uuid';
import { ISubmitDecision } from '../../../models/AccessReviews/ISubmitDecision';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { EntityType } from '../../../models/EntityType';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState, IRootEntitlementsState } from '../../../models/IEntitlementState';
import { DecisionType } from '../../../models/RequestApprovals/DecisionType';
import { getAudience } from '../../../shared/AttachAudience';
import { acceptAllRecommendationsApiUrl, submitReviewAllDecisionApiUrl } from '../../../shared/getAccessReviewsApiUrl';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { registry } from '../myAccessRegistry';
import { AccessReviewsPivot } from '../../../components/AccessReviews/AccessReviewsList';

import * as moment from 'moment';

import 'rxjs/add/observable/concat';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

export const submitAllDecisionsEpic: Epic<IEntitlementAction<AnyPayload>, IRootEntitlementsState> = (
  action$: ActionsObservable<IEntitlementAction<Partial<ISubmitDecision>>>,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
  return action$
    .ofType(EntitlementActions.submitAllDecisions)
    .switchMap((_action: IEntitlementAction<Partial<ISubmitDecision>>) => {
      const decision = _action.payload!;
      const reviewId = _action.payload!.reviewId;
      const filter = _action.payload!.filterContext!;
      const correlationId = guid();

      let url = submitReviewAllDecisionApiUrl(filter);
      const pivotType = _store.getState().app.reviewListPivot;
      if (pivotType === AccessReviewsPivot.UserAccess) {
        url = submitReviewAllDecisionApiUrl(filter, true);
      }

      let ajaxRequest: IAjaxRequest = {
        method: 'POST',
        url: url,
        body: {
          justification: decision.justification,
          reviewResult: decision.decisionType
        },
        audience: getAudience(EntityType.accessReviewDecisions),
        headers: {
          'x-ms-client-request-id': correlationId
        }
      };

      if (decision.decisionType === DecisionType.AcceptRecommendation) {
        ajaxRequest = {
          method: 'POST',
          url: acceptAllRecommendationsApiUrl(filter),
          body: {
            justification: decision.justification,
            reviewResult: decision.decisionType
          },
          audience: getAudience(EntityType.accessReviewDecisions),
          headers: {
            'x-ms-client-request-id': correlationId
          }
        };
      }

      const updateSucceededAction = {
        type: EntitlementActions.submitAllDecisionsSucceeded,
        payload: {
          id: 'all'
        }
      };

      const refreshEntitiesAction = {
        type: EntitlementActions.refreshEntities,
        payload: {
          entityType: EntityType.accessReviewDecisions
        }
      };

      const getDecisionSummaryAction = {
        type: EntitlementActions.getDecisionsSummary,
        payload: {
          entityId: reviewId
        }
      };

      if (location.href.includes('user-access-reviews')) {
        // Perform your logic here
        return ajax(ajaxRequest).pipe(
          flatMap(() => {
            return Observable.concat(Observable.of(updateSucceededAction));
          }),
          catchError(errorHandler<IEntitlementAction>(EntitlementActions.submitAllDecisionsFailed))
        );
      }

      return ajax(ajaxRequest).pipe(
        flatMap(() => {
          return Observable.concat(
            Observable.of(updateSucceededAction),
            Observable.of(refreshEntitiesAction),
            Observable.of(getDecisionSummaryAction)
          );
        }),
        catchError(errorHandler<IEntitlementAction>(EntitlementActions.submitAllDecisionsFailed))
      );
    });
};
registry.addEpic('submitAllDecisionsEpic', submitAllDecisionsEpic);

export const submitAllDecisions = (state: IEntitlementState): Readonly<IEntitlementState> => {
  return {
    ...state,
    submitting: true
  };
};
registry.add(EntitlementActions.submitAllDecisions, submitAllDecisions);

export const submitAllDecisionsSucceeded = (
  state: IEntitlementState,
  action: IEntitlementAction<Readonly<string>>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  return {
    ...state,
    submitting: false,
    showingConfirmDialog: false,
    showingBulkDecisionDialog: false,
    showingReviewDecisionsDialog: false,
    showingAcceptRecommendations: false,
    showingResetDecisions: false
  };
};
registry.add(EntitlementActions.submitAllDecisionsSucceeded, submitAllDecisionsSucceeded);

export const submitAllDecisionsFailed = (
  state: IEntitlementState,
  action: IEntitlementAction<Readonly<AnyPayload>>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  const payload = action.payload;

  const error = payload.response && payload.response.error && payload.response.error.message;

  const correlationId = payload.request && payload.request.headers && payload.request.headers['x-ms-client-request-id'];

  let toastOptions: TranslationOptions = {
    error: error,
    correlationId: correlationId
  };

  const notification = {
    localizableTitle: {
      key: LocaleKeys.accessReviewSubmitDecisionFailed,
      options: toastOptions
    },
    localizableMessage: {
      key: LocaleKeys.errorTemplate,
      options: toastOptions
    },
    createdDateTime: moment(),
    severity: NotificationSeverity.error
  };

  let notifications: INotification[] = [
    {
      ...notification,
      id: guid(),
      type: NotificationType.card
    },
    {
      ...notification,
      id: guid(),
      type: NotificationType.toast
    }
  ];

  return {
    ...state,
    notifications: notificationsMerge(notifications, state.notifications, state.notificationsLimit),
    submitting: false,
    showingConfirmDialog: false,
    showingBulkDecisionDialog: false,
    showingReviewDecisionsDialog: false,
    showingAcceptRecommendations: false,
    showingResetDecisions: false
  };
};
registry.add(EntitlementActions.submitAllDecisionsFailed, submitAllDecisionsFailed);
