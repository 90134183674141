import 'rxjs/add/observable/concat';

import { TranslationOptions } from 'i18next';
import * as moment from 'moment';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';
import { catchError, flatMap } from 'rxjs/operators';
import { v4 as guid } from 'uuid';

import { IAjaxRequest } from '@microsoft/portal-app/lib/auth/withAuth';
import { notificationsMerge } from '@microsoft/portal-app/lib/Notifications/helpers/notificationsMerge';
import {
  INotification,
  NotificationSeverity,
  NotificationType,
} from '@microsoft/portal-app/lib/Notifications/models/INotification';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { errorHandler } from '@microsoft/portal-app/lib/redux/observableErrorHandler';

import { IGrantRequest } from '../../../models/ELM/IGrantRequest';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { EntityType } from '../../../models/EntityType';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import {
  IEntitlementState,
  IRootEntitlementsState,
} from '../../../models/IEntitlementState';
import { IDecision } from '../../../models/RequestApprovals/IDecision';
import { getAudience } from '../../../shared/AttachAudience';
import { getPatchDecisionApiUrl } from '../../../shared/getApiUrl';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { registry } from '../myAccessRegistry';

export const patchDecisionEpic: Epic<
  IEntitlementAction<AnyPayload>,
  IRootEntitlementsState
> = (
  action$: ActionsObservable<IEntitlementAction<IDecision>>,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
    return action$.ofType(EntitlementActions.patchDecision).pipe(
      flatMap((action: IEntitlementAction<IDecision>) => {
        const decision = action.payload!;

        const approvalPayload = {
          justification: decision.justification,
          reviewResult: decision.reviewResult,
          answers: decision.answers,
        };

        const correlationId = guid();
        const request: IAjaxRequest = {
          method: 'PATCH',
          url: getPatchDecisionApiUrl(decision.grantRequestId!, decision.requestId),
          body: approvalPayload,
          audience: getAudience(EntityType.pendingApprovalGrantRequests),
          headers: {
            'x-ms-client-request-id': correlationId,
          },
        };

        const addSucceededAction = {
          type: EntitlementActions.patchDecisionSucceeded,
          payload: decision,
        };

        return ajax(request).pipe(
          flatMap(() => {
            return Observable.of(addSucceededAction);
          }),
          catchError(
            errorHandler<IEntitlementAction>(
              EntitlementActions.patchDecisionFailed
            )
          )
        );
      })
    );
  };
registry.addEpic('patchDecisionEpic', patchDecisionEpic);

export const patchDecision = (
  state: IEntitlementState,
  action: IEntitlementAction<IDecision>
): IEntitlementState => {
  if (action.payload === undefined) {
    return state;
  }

  return {
    ...state,
    submitting: true,
  };
};
registry.add(EntitlementActions.patchDecision, patchDecision);

export const patchDecisionSucceeded = (
  state: IEntitlementState,
  action: IEntitlementAction<IDecision>
): IEntitlementState => {
  if (action.payload === undefined) {
    return state;
  }
  const decision = action.payload;

  const decisionStatus = decision.reviewResult;
  const toastKey = `${decisionStatus.toLowerCase()}Succeeded`;
  const toastOptions = {
    decisionStatus: decision.reviewResult,
    targetDisplayName: decision.targetDisplayName,
  };

  const notification = {
    localizableTitle: {
      key: toastKey,
      options: toastOptions,
    },
    localizableMessage: {
      key: toastKey,
      options: toastOptions,
    },
    createdDateTime: moment(),
    severity: NotificationSeverity.success,
  };

  let notifications: INotification[] = [
    {
      ...notification,
      id: guid(),
      type: NotificationType.card,
    },
    {
      ...notification,
      id: guid(),
      type: NotificationType.toast,
    },
  ];

  let newRequest: IGrantRequest;
  let requestId: string;
  if (
    decision.grantRequestId &&
    state.pendingApprovalGrantRequests.entitiesById.has(
      decision.grantRequestId
    )
  ) {
    newRequest = state.pendingApprovalGrantRequests.entitiesById.get(
      decision.grantRequestId
    )!;
    requestId = decision.grantRequestId;
  } else {
    newRequest = state.pendingApprovalGrantRequests.entitiesById.get(
      decision.requestId
    )!;
    requestId = decision.requestId;
  }

  newRequest.requestStatus = decision.reviewResult;

  let entities = state.pendingApprovalGrantRequests.entitiesById as Map<
    string,
    IGrantRequest
  >;
  entities.set(requestId, newRequest);
  return {
    ...state,
    pendingApprovalGrantRequests: {
      ...state.pendingApprovalGrantRequests,
      entitiesById: entities as ReadonlyMap<string, IGrantRequest>,
    },
    notifications: notificationsMerge(
      notifications,
      state.notifications,
      state.notificationsLimit
    ),
    showingPendingApprovalGrantRequestDetails: false,
    showingMoreGrantRequestDetails: false,
    showingBulkActionDialog: false,
    submitting: false,
    pageCounts: {
      // A hacky way to deal with the issue that request won't disappear
      // right after decision is posted, have to use client side counter--
      approvals: Math.max(state.pageCounts.approvals! - 1, 0),
    },
  };
};
registry.add(EntitlementActions.patchDecisionSucceeded, patchDecisionSucceeded);

export const patchDecisionFailed = (
  state: IEntitlementState,
  action: IEntitlementAction<Readonly<AnyPayload>>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  const payload = action.payload;
  let error =
    payload.response &&
    payload.response.error &&
    payload.response.error.message;

  let correlationId =
    payload.request &&
    payload.request.headers &&
    payload.request.headers['x-ms-client-request-id'];

  let toastOptions: TranslationOptions = {
    error: error,
    correlationId: correlationId,
  };

  const notification = {
    localizableTitle: {
      key: LocaleKeys.generalErrorMessage,
      options: toastOptions,
    },
    localizableMessage: {
      key: LocaleKeys.errorTemplate,
      options: toastOptions,
    },
    createdDateTime: moment(),
    severity: NotificationSeverity.error,
  };

  let notifications: INotification[] = [
    {
      ...notification,
      id: guid(),
      type: NotificationType.card,
    },
    {
      ...notification,
      id: guid(),
      type: NotificationType.toast,
    },
  ];

  return {
    ...state,
    notifications: notificationsMerge(
      notifications,
      state.notifications,
      state.notificationsLimit
    ),
    showingPendingApprovalGrantRequestDetails: false,
    showingMoreGrantRequestDetails: false,
    showingBulkActionDialog: false,
    submitting: false,
  };
};
registry.add(EntitlementActions.patchDecisionFailed, patchDecisionFailed);