import { css, Icon } from '@fluentui/react';
import * as React from 'react';

import { useTranslation } from '../../../hooks';
import { OriginSystem } from '../../../models';
import { LocaleKeys } from '../../../shared';
import { IResourceListItemProps } from './ResourceView.types';
const styles = require('./ResourceView.scoped.scss');


export function ResourceListItem({ item, index }: IResourceListItemProps): JSX.Element {
  const t = useTranslation();
  const [isExpanded, setIsExpanded] = React.useState(false);
  const description = item?.description;
  const isApplication = item?.originSystem === OriginSystem.AadApplication;
  const hasDescription = description?.length > 0 && !isApplication;
  const listItemStyles = [styles.listItem];
  if (hasDescription) {
    listItemStyles.push(styles.hasDescription);
  }
  const onItemClicked = (): void => {
    if (isApplication) {
      return;
    }
    setIsExpanded(!isExpanded);
  }
  return (
    // Fluent already handles keypresses for us
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      role="button"
      className={css(...listItemStyles)}
      data-is-focusable={true}
      tabIndex={index}
      onClick={onItemClicked}
    >
      <div className={css(styles.itemInfo)}>
        <div>
          <div className={css(styles.itemTitle)}>{item?.name}</div>
          <div className={css(styles.itemSubtitle)}>{`${t(item?.originSystemLabel, { context: "capitalize" })} - ${t(LocaleKeys.role)}: ${item?.role}`}</div>
        </div>
        <div>
          <Icon
            iconName={isExpanded ? 'ChevronUp' : 'ChevronDown'}
            className={css(styles.expandArrow, 'ms-ExpandArrowIcon')}
          />
        </div>
      </div>
      {isExpanded && <span className={css(styles.itemDescription)}>{description}</span>}
    </div>
  );
}
