import { ColorClassNames, css, FontClassNames } from '@fluentui/react';
import * as React from 'react';
import { InjectedTranslateProps } from 'react-i18next';

const cardStyles = require('../../../css/card.scoped.scss');
const myAccessStyles = require('../../../css/myAccess.scoped.scss');

export interface IDetailSectionProps extends InjectedTranslateProps {
  heading: string;
  // body: JSX.Element;
  headingCSS?: string;
  sectionCSS?: string;
  detailSectionContentProps: IDetailSectionContentProps[];
}

export class DetailSection extends React.Component<IDetailSectionProps, {}> {
  constructor(nextProps: IDetailSectionProps) {
    super(nextProps);
  }

  public render(): JSX.Element {
    const {
      t,
      heading,
      headingCSS,
      sectionCSS,
      detailSectionContentProps,
    } = this.props;
    const contents: JSX.Element[] = [];
    detailSectionContentProps.forEach((props: IDetailSectionContentProps) => {
      contents.push(
        <DetailSectionContent
          primaryContent={t(props.primaryContent)}
          secondaryContent={
            props.secondaryContent ? t(props.secondaryContent) : undefined
          }
          primaryCSS={props.primaryCSS}
          secondaryCSS={props.secondaryCSS}
          verifiedCredential={props.verifiedCredential}
        />
      );
    });

    return (
      <div className={sectionCSS || css(cardStyles.labelCard)}>
        <span
          className={
            headingCSS ||
            css(
              FontClassNames.medium,
              ColorClassNames.neutralSecondary,
              myAccessStyles.semiLightText
            )
          }
        >
          {t(heading)}
        </span>
        <div>{contents}</div>
      </div>
    );
  }
}

export interface IDetailSectionContentProps {
  primaryContent: string;
  secondaryContent?: string;
  primaryCSS?: string;
  secondaryCSS?: string;
  containerCSS?: string;
  verifiedCredential?: string;
}

class DetailSectionContent extends React.Component<
  IDetailSectionContentProps,
  {}
> {
  constructor(nextProps: IDetailSectionContentProps) {
    super(nextProps);
  }

  public render(): JSX.Element {
    const {
      primaryContent,
      secondaryContent,
      primaryCSS,
      secondaryCSS,
      containerCSS,
      verifiedCredential
    } = this.props;
    return (
      <div className={containerCSS || css(myAccessStyles.marginTopXSmallPlus)}>
        <div className={primaryCSS || css(FontClassNames.medium)}>
          <span>{primaryContent}</span>
        </div>
        {secondaryContent ? (
          <div className={secondaryCSS || css(FontClassNames.smallPlus)}>
            <span>{secondaryContent}</span>
          </div>
        ) : null}
        {verifiedCredential ? (
          <div className={secondaryCSS || css(FontClassNames.smallPlus)}>
            <span className={css(myAccessStyles.vcStyle)}>{verifiedCredential}</span>
          </div>
        ) : null}
      </div>
    );
  }
}
