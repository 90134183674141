import { EntitlementActions } from '../../../models/EntitlementActions';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState } from '../../../models/IEntitlementState';
import { registry } from '../myAccessRegistry';

export const showGrantFilter = (
  state: IEntitlementState,
  action: IEntitlementAction<boolean>
): IEntitlementState => {
  let nextState = {
    ...state,
    showingGrantFilter: action.payload
  };

  return nextState;
};
registry.add(EntitlementActions.showGrantFilter, showGrantFilter);
