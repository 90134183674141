import { ILocalizableMessage } from '@microsoft/portal-app/lib/localization/ILocalizableMessage';
import { AppPanel } from '@uifabric/portal-ux/lib/AppPanel';
import { css } from '@fluentui/react';
import { TranslationFunction } from 'i18next';
import {
  ChoiceGroup,
  ColorClassNames,
  DatePicker,
  DefaultButton,
  FontClassNames,
  FontWeights,
  HoverCard,
  HoverCardType,
  IChoiceGroupOption,
  Icon,
  IPlainCardProps,
  Label,
  Link,
  MessageBar,
  MessageBarType,
  PanelType,
  PrimaryButton,
  TextField,
  Toggle,
  TooltipHost
} from '@fluentui/react';
import { withResponsiveMode } from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import * as React from 'react';
import { isNullOrUndefined } from 'util';
import { AnswerString } from '../../../models/ELM/AnswerString';
import { IAnswer } from '../../../models/ELM/IAnswer';
import { IGrantPolicy } from '../../../models/ELM/IGrantPolicy';
import { IGrantRequest } from '../../../models/ELM/IGrantRequest';
import { IncompatibleDetails } from '../../../models/ELM/IncompatibleDetails';
import { ValidationErrorCode } from '../../../models/ELM/IValidationError';
import { MultipleChoiceQuestion } from '../../../models/ELM/MultipleChoiceQuestion';
import { QuestionAnswer } from '../../../models/ELM/QuestionAnswer';
import { QuestionType } from '../../../models/ELM/QuestionType';
import { RequestType } from '../../../models/ELM/RequestType';
import { TextInputQuestion } from '../../../models/ELM/TextInputQuestion';
import { asLocalizedText } from '../../../shared/asLocalizedText';
import { FormatDate } from '../../../shared/FormatDateTime';
import { getDatePickerStrings } from '../../../shared/getDatePickerStrings';
import { isEmptyOrUndefined } from '../../../shared/isEmptyOrUndefined';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { Routes } from '../../../shared/Routes';
import { getSpinner } from '../../../shared/spinner';
import {
  getErrorExists,
  getExistingGrantId,
  getGrantPolicies,
  getIncompatiblesDetails,
  getPolicyExpirationDateFromValidationErrors,
  getPolicyIsCustomAssignmentScheduleAllowed,
  getQuestionAnswers,
  getSelectedGrantPolicy,
  getTopLevelValidationErrorMessage,
  isValidationErrorTopLevel,
  MAX_DATE
} from '../../../shared/validationErrorHelper';
import { ConsentToggle } from '../ConsentToggle/ConsentToggle';
import { QuestionAnswer as QuestionAnswerControl } from '../QuestionAnswer/QuestionAnswer';
import {
  IAddGrantRequestProps,
  IAddGrantRequestState
} from './AddGrantRequest.types';
const myAccessStyles = require('../../../css/myAccess.scoped.scss');

@withResponsiveMode
export class AddGrantRequest extends React.Component<
  IAddGrantRequestProps,
  IAddGrantRequestState
> {
  private _minStartDate: Date = new Date(Date.now());
  private _maxStartDate: Date = MAX_DATE;
  private _minStopDate: Date = new Date(Date.now());
  private _maxStopDate: Date = MAX_DATE;
  private _grantPolicyOptions: IChoiceGroupOption[] = [];
  private _grantPolicies: IGrantPolicy[] = [];

  constructor(nextProps: IAddGrantRequestProps) {
    super(nextProps);
    this.state = {
      newGrantRequest: nextProps.grantRequest!,
      datesEnabled: false,
      consented: false,
      textFieldClean: true,
      selectedKey: '',
      questionAnswers: []
    };
  }

  public componentWillReceiveProps(nextProps: IAddGrantRequestProps): void {
    const nextErrors = nextProps.validationErrors;
    const currentErrors = this.props.validationErrors;
    if (
      nextErrors &&
      nextErrors.length > 0 &&
      (!currentErrors || currentErrors.length === 0)
    ) {
      if (
        getErrorExists(
          nextProps.validationErrors,
          ValidationErrorCode.InvalidExpiredDateTime
        )
      ) {
        const expirationDate = getPolicyExpirationDateFromValidationErrors(
          nextErrors,
          getSelectedGrantPolicy(this._grantPolicies, this.state.selectedKey)
        );
        this._maxStopDate = expirationDate;
        this._maxStartDate = expirationDate;
      }

      if (
        getErrorExists(
          nextProps.validationErrors,
          ValidationErrorCode.MissingQuestionAnswers
        )
      ) {
        const questionAnswers = getQuestionAnswers(
          nextErrors,
          getSelectedGrantPolicy(this._grantPolicies, this.state.selectedKey)
        );
        this.setState({
          ...this.state,
          questionAnswers
        });
      }
    }
  }

  public componentWillMount = (): void => {
    const { grantRequest } = this.props;

    if (!grantRequest) {
      return;
    }

    let stopDateTime = new Date();
    stopDateTime.setFullYear(stopDateTime.getFullYear() + 100);

    // Trigger pre-validation
    const grantRequestForValidation: Partial<IGrantRequest> = {
      ...grantRequest,
      accessPackageAssignment: {
        ...grantRequest.accessPackageAssignment,
        schedule: {
          startDateTime: null,
          stopDateTime: stopDateTime
        }
      },
      isValidationOnly: true
    };

    this.props.postGrantRequest({
      newGrantRequest: grantRequestForValidation,
      entitlementName: this.props.entitlementName!
    });
  }

  public componentWillUnmount = (): void => {
    this.props.clearValidationErrors();
  }

  public render(): JSX.Element {
    const {
      grantRequest,
      t,
      validationErrors,
      submitting,
      validating
    } = this.props;
    if (!grantRequest || !grantRequest.accessPackageAssignment) {
      return <div />;
    }

    const title = t(this.props.title || LocaleKeys.requestAccess, {
      context: 'capitalize'
    });
    const hideValidationErrors =
      !validationErrors ||
      validationErrors.length === 0 ||
      submitting ||
      validating;

    return (
      <AppPanel
        isBlocking={true}
        isOpen={true}
        onDismiss={this.props.onDismiss}
        type={PanelType.smallFixedFar}
        onRenderFooterContent={this._onRenderFooterContent}
        isLightDismiss={true}
        headerText={title}
        closeButtonAriaLabel={t(LocaleKeys.cancel)}
        hasCloseButton={true}
      >
        {this._onRenderBodyTop()}
        {hideValidationErrors ? null : this._onRenderValidationErrors()}
        {this._onRenderBodyBottom()}
      </AppPanel>
    );
  }

  private _onExistingGrantLinkClicked = (guid: string): void => {
    this.props.navigateTo(`${Routes.accessIHaveTab}/${guid}`);
    this.props.onDismiss();
  }

  private _onRenderValidationErrors = (): JSX.Element => {
    const { t, validationErrors, submitting, validating } = this.props;

    if (
      getErrorExists(
        validationErrors,
        ValidationErrorCode.InvalidExtendRequestPolicyDisabled
      )
    ) {
      return this._getMessageBar(
        {
          key: LocaleKeys.extendDisabledMessage,
          options: {
            entitlementName: this.props.entitlementName
          }
        },
        t,
        MessageBarType.blocked
      );
    }

    if (getErrorExists(validationErrors, ValidationErrorCode.PolicyExpired)) {
      return this._getMessageBar(
        {
          key: LocaleKeys.policyExpiredMessage,
          options: {
            entitlementName: this.props.entitlementName
          }
        },
        t,
        MessageBarType.blocked
      );
    }

    if (getErrorExists(validationErrors, ValidationErrorCode.PartnerNotFound)) {
      return this._getMessageBar(
        {
          key: LocaleKeys.partnerNotFoundMessage
        },
        t,
        MessageBarType.blocked
      );
    }

    if (
      getErrorExists(
        validationErrors,
        ValidationErrorCode.InvalidRequestExistingGrant
      )
    ) {
      return this._getMessageBar(
        {
          key: LocaleKeys.grantExistsMessage,
          options: {
            entitlementName: this.props.entitlementName
          }
        },
        t,
        MessageBarType.warning,
        true,
        LocaleKeys.viewAccessPackage,
        false,
        undefined,
        () =>
          this._onExistingGrantLinkClicked(
            getExistingGrantId(validationErrors)!
          )
      );
    }

    if (
      getErrorExists(validationErrors, ValidationErrorCode.ExistingOpenRequest)
    ) {
      return this._getMessageBar(
        {
          key: LocaleKeys.requestExistsMessage,
          options: {
            entitlementName: this.props.entitlementName
          }
        },
        t
      );
    }

    if (getErrorExists(validationErrors, ValidationErrorCode.InvalidRequestSODCheck)) {
      const incompatibleDetails = getIncompatiblesDetails(validationErrors);
      const plainCardProps: IPlainCardProps = {
        onRenderPlainCard: () => this._OnRenderIncompatibleDetailsHoverCard(incompatibleDetails),
      };

      return this._getMessageBar(
        {
          key: LocaleKeys.invalidSODCheck,
        },
        t,
        MessageBarType.blocked,
        false,
        LocaleKeys.seeDetails,
        true,
        plainCardProps
      );
    }

    if (isValidationErrorTopLevel(validationErrors)) {
      return this._getMessageBar(
        {
          key: getTopLevelValidationErrorMessage(validationErrors)
        },
        t,
        MessageBarType.error
      );
    }

    this._grantPolicies = getGrantPolicies(validationErrors)!;
    this._grantPolicyOptions = [];
    if (this._grantPolicies && this._grantPolicies.length > 1) {
      this._grantPolicies.forEach((grantPolicy: IGrantPolicy) => {
        this._grantPolicyOptions.push({
          key: grantPolicy.id,
          text: grantPolicy.displayName,
          // tslint:disable-next-line:no-any
          onRenderField: (props: IChoiceGroupOption, render: any) => {
            return (
              <div>
                <div>{render!(props)}</div>
                <div
                  className={css(
                    myAccessStyles.marginLeftSmaller,
                    ColorClassNames.neutralSecondary
                  )}
                >
                  {grantPolicy.description}
                </div>
              </div>
            );
          }
        });
      });
    }

    const questionAnswersView: JSX.Element[] = [];
    if (this.state.questionAnswers && this.state.questionAnswers.length > 0) {
      this.state.questionAnswers.forEach((questionAnswer: QuestionAnswer) => {
        questionAnswersView.push(
          <QuestionAnswerControl
            key={questionAnswer.id}
            t={t}
            submitting={submitting}
            validating={validating}
            questionAnswer={questionAnswer}
            onChange={this._onAnswerChanged}
          />
        );
      });
    }

    return (
      <div>
        {this._grantPolicyOptions.length > 0 ? (
          <div className={css(myAccessStyles.marginTopMedium)}>
            <Label required={true}>
              {t(LocaleKeys.selectAPolicy)}{' '}
              <TooltipHost content={t(LocaleKeys.policyTooltip)}>
                <Icon iconName="info" />
              </TooltipHost>
            </Label>
            <ChoiceGroup
              options={this._grantPolicyOptions}
              onChange={this._onPolicySelectionChanged}
              selectedKey={this.state.selectedKey!}
            />
          </div>
        ) : null}
        {this.state.questionAnswers && this.state.questionAnswers.length > 0
          ? questionAnswersView
          : null}
      </div>
    );
  }

  private _getMessageBar = (
    message: ILocalizableMessage | string,
    t: TranslationFunction,
    messageBarType?: MessageBarType,
    showLink?: boolean,
    linkText?: string,
    showHoverCard?: boolean,
    plainCardProps?: IPlainCardProps,
    linkCallBack?: () => void,
  ): JSX.Element => {
    return (
      <MessageBar
        className={css(myAccessStyles.marginTopSmall)}
        messageBarType={messageBarType || MessageBarType.warning}
      >
        <span className={css('ms-pii')}>{asLocalizedText(message, t)} </span>
        {showLink ? (
          // tslint:disable-next-line:jsx-no-lambda
          <Link onClick={linkCallBack}>{t(linkText!)}</Link>
        ) : null}
        {showHoverCard ? (
          // tslint:disable-next-line:jsx-no-lambda
          <div className={css(myAccessStyles.marginBottomXSmall)}>
            <HoverCard
              trapFocus={true}
              setInitialFocus={true}
              instantOpenOnClick={true}
              type={HoverCardType.plain}
              plainCardProps={plainCardProps}
            >
              <Link
                data-is-focusable={false}
                className={css(
                  FontClassNames.medium,
                  'ms-pii',
                  myAccessStyles.marginBottomSmall
                )}
              >{t(linkText!)}
              </Link>
            </HoverCard>
          </div>
        ) : null}
      </MessageBar>
    );
  }

  private _onToggleConsent = (
    _ev?: React.FormEvent<HTMLElement>,
    isChecked?: boolean
  ): void => {
    this.setState({
      consented: isChecked!
    });
  }

  private _getIsJustificationRequired = (): boolean => {
    const missingJustificationErrorExists = getErrorExists(
      this.props.validationErrors,
      ValidationErrorCode.MissingRequiredRequestorJustification
    );

    const multiplePolicyErrorExists = getErrorExists(
      this.props.validationErrors,
      ValidationErrorCode.MultiplePolicyFound
    );

    if (multiplePolicyErrorExists) {
      let selectedPolicy = getSelectedGrantPolicy(
        this._grantPolicies,
        this.state.selectedKey
      );
      if (!isNullOrUndefined(selectedPolicy)) {
        return selectedPolicy.isRequestorJustificationRequired;
      } else {
        return true;
      }
    }

    return missingJustificationErrorExists;
  }

  private _onRenderBodyBottom = (): JSX.Element => {
    const {
      grantRequest,
      t,
      submitting,
      validating,
      validationErrors
    } = this.props;

    if (validating) {
      return (
        <div className={css(myAccessStyles.marginTopXLarge)}>
          {getSpinner(t(LocaleKeys.loading))}
        </div>
      );
    }

    if (submitting) {
      return (
        <div className={css(myAccessStyles.marginTopXLarge)}>
          {getSpinner(t(LocaleKeys.submittingYourAccessRequest))}
        </div>
      );
    }

    if (isValidationErrorTopLevel(validationErrors)) {
      return <div />;
    }

    const startDateValue =
      this.state.newGrantRequest &&
      this.state.newGrantRequest!.accessPackageAssignment!.schedule!
        .startDateTime!;

    const stopDateValue =
      this.state.newGrantRequest &&
      this.state.newGrantRequest!.accessPackageAssignment!.schedule!
        .stopDateTime!;

    const policyAllowCustomSchedule = getPolicyIsCustomAssignmentScheduleAllowed(
      this._grantPolicies, this.state.selectedKey);

    return (
      <div>
        <div className={css(myAccessStyles.marginTopSmall)}>
          <TextField
            label={t(LocaleKeys.businessJustification)}
            multiline
            rows={4}
            placeholder={
              this._getIsJustificationRequired() ? t(LocaleKeys.required) : null
            }
            value={
              this.state.newGrantRequest &&
              this.state.newGrantRequest!.justification
            }
            onChange={this._onJustificationChanged}
            required={this._getIsJustificationRequired()}
            onGetErrorMessage={this._getErrorMessage}
            disabled={submitting || validating}
          />
        </div>

        <div className={css(myAccessStyles.marginTopSmall)}>
          {policyAllowCustomSchedule ?
            <Toggle
              label={t(LocaleKeys.requestForSpecificPeriod)}
              defaultChecked={false}
              onChanged={this._onDatesToggled}
              onText={t(LocaleKeys.yes)}
              offText={t(LocaleKeys.no)}
              checked={this.state.datesEnabled}
            /> : null}
          {this.state.datesEnabled && policyAllowCustomSchedule ? (
            <div>
              {grantRequest!.requestType === RequestType.UserExtend ? null : (
                <div>
                  <Label>{t(LocaleKeys.startsOn)}</Label>
                  <DatePicker
                    onSelectDate={this._onStartDateSelected}
                    value={startDateValue as Date}
                    formatDate={FormatDate}
                    minDate={this._minStartDate}
                    maxDate={this._maxStartDate}
                    disabled={!this.state.datesEnabled}
                    ariaLabel={'Pick the start date.'}
                    strings={getDatePickerStrings(t)}
                  />
                </div>
              )}
              <div>
                <Label>{t(LocaleKeys.expiresOn)}</Label>
                <DatePicker
                  onSelectDate={this._onStopDateSelected}
                  value={stopDateValue as Date}
                  formatDate={FormatDate}
                  minDate={this._minStopDate}
                  maxDate={this._maxStopDate}
                  disabled={!this.state.datesEnabled}
                  ariaLabel={'Pick the expiration date.'}
                  strings={getDatePickerStrings(t)}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  private _onRenderBodyTop = (): JSX.Element => {
    const { entitlementName, entitlementDescription } = this.props;

    return (
      <div className={css(myAccessStyles.marginTopSmall)}>
        <div
          className={css(
            FontClassNames.large,
            myAccessStyles.marginBottomSmall,
            'ms-pii'
          )}
        >
          {entitlementName}
        </div>
        <div className={css(FontClassNames.medium, 'ms-pii')}>
          {entitlementDescription}
        </div>
      </div>
    );
  }

  private _onRenderFooterContent = (): JSX.Element => {
    const {
      t,
      needsConsent,
      tenantDisplayName,
      privacyUrl,
      submitting,
      validating,
      validationErrors
    } = this.props;
    if (
      submitting ||
      validating ||
      isValidationErrorTopLevel(validationErrors)
    ) {
      return <div />;
    }

    return (
      <div>
        {needsConsent ? (
          <ConsentToggle
            t={t}
            tenantDisplayName={tenantDisplayName}
            privacyUrl={privacyUrl}
            onChange={this._onToggleConsent}
          />
        ) : null}
        <PrimaryButton
          onClick={this._save}
          className={css(myAccessStyles.marginRightSmall)}
          disabled={this._isSubmitButtonDisabled()}
          text={t(LocaleKeys.submit)}
        />
        <DefaultButton
          onClick={this._closePanel}
          text={t(LocaleKeys.cancel)}
          disabled={submitting || validating}
        />
      </div>
    );
  }

  private _isSubmitButtonDisabled = (): boolean => {
    const { needsConsent, submitting, validating } = this.props;
    const policyAllowCustomSchedule = getPolicyIsCustomAssignmentScheduleAllowed(
      this._grantPolicies, this.state.selectedKey);
    const isJustificationBlocking =
      this._getIsJustificationRequired() &&
      this.state.newGrantRequest.justification!.length === 0;
    const isConsentBlocking = needsConsent && !this.state.consented;
    const isStatusBlocking = submitting || validating;
    const isGrantPolicyBlocking =
      this._grantPolicyOptions.length > 0 && this.state.selectedKey === '';
    const isDateBlocking = policyAllowCustomSchedule &&
      (this.state.datesEnabled! &&
        this.state.newGrantRequest &&
        this.state.newGrantRequest.accessPackageAssignment &&
        this.state.newGrantRequest.accessPackageAssignment.schedule &&
        this.state.newGrantRequest.accessPackageAssignment.schedule
          .startDateTime === null &&
        this.state.newGrantRequest.accessPackageAssignment.schedule
          .stopDateTime === null);
    const isQuestionAnswersBlocking =
      this.state.questionAnswers &&
      this.state.questionAnswers.some(
        (qa: QuestionAnswer) =>
          qa.isRequired && isEmptyOrUndefined(qa.answerValue)
      );
    const isSODCheckBlocking = getErrorExists(
      this.props.validationErrors,
      ValidationErrorCode.InvalidRequestSODCheck
    );
    return (
      isJustificationBlocking ||
      isConsentBlocking ||
      isStatusBlocking ||
      isGrantPolicyBlocking ||
      isDateBlocking! ||
      isQuestionAnswersBlocking ||
      isSODCheckBlocking
    );
  }

  private _getErrorMessage = (value: string): string => {
    if (!this._getIsJustificationRequired()) {
      return LocaleKeys.empty;
    }

    return value.length !== 0 || this.state.textFieldClean
      ? LocaleKeys.empty
      : this.props.t(LocaleKeys.justificationNotEmpty);
  }

  private _save = (): void => {
    if (this.props.postGrantRequest !== undefined) {
      if (this.state.questionAnswers && this.state.questionAnswers.length > 0) {
        let answers: IAnswer[] = this.state.questionAnswers
          .filter(
            (qa: QuestionAnswer) =>
              qa.isRequired || !isEmptyOrUndefined(qa.answerValue)
          )
          .map((qa: QuestionAnswer) => {
            return new AnswerString(
              qa.answerValue,
              this._getQuestionObject(qa),
              qa.answerDisplayValue,
            );
          });

        this.state.newGrantRequest.answers = answers;
      }

      this.props.postGrantRequest({
        newGrantRequest: this.state.newGrantRequest,
        entitlementName: this.props.entitlementName!
      });
    }
  }

  private _getQuestionObject(questionAnswer: QuestionAnswer): QuestionAnswer {
    switch (questionAnswer.$type) {
      case QuestionType.MultipleChoiceQuestion:
        return new MultipleChoiceQuestion(
          questionAnswer.id,
          questionAnswer.attributeName,
          questionAnswer.isAttribute,
          questionAnswer.text,
          questionAnswer.isRequired,
          questionAnswer.sequence,
          questionAnswer.choices,
          questionAnswer.showAnswerToApprover
        );
      case QuestionType.TextInputQuestion:
        return new TextInputQuestion(
          questionAnswer.id,
          questionAnswer.attributeName,
          questionAnswer.isAttribute,
          questionAnswer.text,
          questionAnswer.isRequired,
          questionAnswer.sequence,
          questionAnswer.choices,
          questionAnswer.showAnswerToApprover,
          questionAnswer.isSingleLineQuestion,
          questionAnswer.regexPattern
        );
    }
  }

  private _closePanel = (): void => {
    if (this.props.onDismiss) {
      this.props.onDismiss();
    }
  }

  private _onPolicySelectionChanged = (_event?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption): void => {
    this.setState({
      ...this.state,
      newGrantRequest: {
        ...this.state.newGrantRequest,
        accessPackageAssignment: {
          ...this.state.newGrantRequest.accessPackageAssignment,
          assignmentPolicyId: String(option!.key),
          schedule: {
            ...this.state.newGrantRequest.accessPackageAssignment!.schedule,
            stopDateTime: null,
            startDateTime: null
          }
        }
      },
      selectedKey: option!.key,
      questionAnswers: getQuestionAnswers(
        this.props.validationErrors,
        getSelectedGrantPolicy(this._grantPolicies, option!.key)
      )
    });

    const expirationDate = getPolicyExpirationDateFromValidationErrors(
      this.props.validationErrors,
      getSelectedGrantPolicy(this._grantPolicies, option!.key)
    );
    this._maxStopDate = expirationDate;
    this._maxStartDate = expirationDate;
  }

  private _onJustificationChanged = (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    this.setState({
      ...this.state,
      newGrantRequest: {
        ...this.state.newGrantRequest,
        justification: newValue
      },
      textFieldClean: false
    });
  }

  private _onStartDateSelected = (date: Date | undefined | null): void => {
    if (!date) {
      return;
    }
    let newDateTime = date;
    if (date.getDate() === new Date().getDate()) {
      newDateTime = new Date();
    }
    this.setState({
      ...this.state,
      newGrantRequest: {
        ...this.state.newGrantRequest,
        accessPackageAssignment: {
          ...this.state.newGrantRequest.accessPackageAssignment,
          schedule: {
            ...this.state.newGrantRequest.accessPackageAssignment!.schedule,
            startDateTime: newDateTime
          }
        }
      }
    });
    this._minStopDate = newDateTime;
  }

  private _onStopDateSelected = (date: Date | undefined | null): void => {
    if (!date) {
      return;
    }
    date.setHours(23, 59, 59);
    this.setState({
      ...this.state,
      newGrantRequest: {
        ...this.state.newGrantRequest,
        accessPackageAssignment: {
          ...this.state.newGrantRequest.accessPackageAssignment,
          schedule: {
            ...this.state.newGrantRequest.accessPackageAssignment!.schedule,
            stopDateTime: date
          }
        }
      }
    });
    this._maxStartDate = date;
  }

  private _onDatesToggled = (): void => {
    if (this.state.datesEnabled) {
      this._minStopDate = new Date(Date.now());
      this._maxStartDate = new Date(9999, 12);
    }
    this.setState({
      ...this.state,
      newGrantRequest: {
        ...this.state.newGrantRequest,
        accessPackageAssignment: {
          ...this.state.newGrantRequest.accessPackageAssignment!,
          schedule: {
            ...this.state.newGrantRequest.accessPackageAssignment!.schedule,
            stopDateTime: null,
            startDateTime: null
          }
        }
      },
      datesEnabled: !this.state.datesEnabled
    });
  }

  private _onAnswerChanged(
    questionId: string,
    answerValue: string,
    answerDisplayValue: string,
    isValid: boolean = true
  ): void {
    let updatedQuestionAnswers = this.state.questionAnswers.map(
      (qa: QuestionAnswer) => {
        if (qa.id === questionId) {
          qa.answerValue = answerValue;
          qa.answerDisplayValue = answerDisplayValue;
          qa.isValid = isValid;
          return qa;
        }
        return qa;
      }
    );

    this.setState({
      ...this.state,
      questionAnswers: updatedQuestionAnswers
    });
  }

  private _OnRenderIncompatibleDetailsHoverCard = (incompatiblesDetails: IncompatibleDetails): JSX.Element => {
    const incompatiblesKeys = Object.keys(incompatiblesDetails) as string[];
    const view: JSX.Element[] = incompatiblesKeys.map((key: string) => {
      return (
        <div key={key} className={css(FontClassNames.small, myAccessStyles.marginBottomSmall)}>
          <div
            className={css(
              FontWeights.semilight,
              ColorClassNames.neutralSecondary
            )}
          >
            {this.props.t(key)}
          </div>
          <span
            className={css(
              myAccessStyles.semiBoldText,
              ColorClassNames.neutralPrimary,
              'ms-pii'
            )}
          >
            {incompatiblesDetails[key].join(', ')}
          </span>
        </div>
      );
    });
    return (
      <div
        className={css(
          myAccessStyles.paddingSmallPlus,
          myAccessStyles.cardDefaultWidth,
          myAccessStyles.wordBreak,
          myAccessStyles.incompatibleDetailsContainer
        )}
      >{view}
      </div>
    );
  }
}
