import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { translate } from 'react-i18next';
import { connect, Dispatch } from 'react-redux';
import { EntitlementActions } from '../../../models';
import { EntityType } from '../../../models/EntityType';
import { IRootEntitlementsState } from '../../../models/IEntitlementState';
import { IFilter } from '../../../models/IFilter';
import { getPageMetaDataFromEntities } from '../../../shared/getPageMetaDataFromEntities';
import { DecisionsFilter } from './DecisionsFilter';
import {
  IConnectedDecisionsFilterProps,
  IDecisionsFilterActions,
  IDecisionsFilterMappedProps
} from './DecisionsFilter.types';

/**
 * Maps the external (i.e. own) props and the state to the properties of the DecisionsFilter control.
 * @param state The current redux state to map to props
 * @param ownProps The external properties to map to props
 * @returns The mapped properties that along with the actions
 * becomes the props (IDecisionsFilterProps) for the DecisionsFilter control
 */
const mapStateToProps = (
  state: IRootEntitlementsState,
  ownProps: IConnectedDecisionsFilterProps
): IDecisionsFilterMappedProps => {
  const hidden = !state.app.showingReviewDecisionsFilter;
  const { accessReviewDecisions } = state.app;
  const filter = accessReviewDecisions.filterContext;

  const selectedRecommendations = filter && filter.selectedRecommendations;
  const selectedDecisions = filter && filter.selectedDecisions;

  const pageMetaData = getPageMetaDataFromEntities(accessReviewDecisions);

  return {
    ...ownProps,
    hidden,
    selectedRecommendations,
    selectedDecisions,
    pageMetaData
  };
};

/**
 * Maps the dispatch actions to the props for the DecisionsFilter control.
 * @param dispatch Dispatches actions to the store
 * @returns The mapped actions that along with the mapped properties
 * becomes the props (IDecisionsFilterProps) for the DecisionsFilter control
 */
const mapDispatchToProps = (
  dispatch: Dispatch<IRootEntitlementsState>
): IDecisionsFilterActions => {
  const filterEntitiesAction = createAction<{
    entityType: string;
    filter: IFilter | undefined;
  }>(EntitlementActions.filterEntities, dispatch);
  const filterEntitiesOnServerAction = createAction<{
    entityType: string;
    filterContext: IFilter | undefined;
  }>(EntitlementActions.filterEntitiesOnServer, dispatch);
  return {
    filterEntities: (filter: IFilter | undefined) =>
      filterEntitiesAction({
        entityType: EntityType.accessReviewDecisions,
        filter: filter
      }),
    filterEntitiesOnServer: (filter: IFilter | undefined) =>
      filterEntitiesOnServerAction({
        entityType: EntityType.accessReviewDecisions,
        filterContext: filter
      })
  };
};

/**
 * A redux connected DecisionsFilter control.
 * @description Supports translation
 */
export const ConnectedDecisionsFilter = connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('MyAccess')(DecisionsFilter));
