import { AnswerString } from '../models/ELM/AnswerString';
import { IAnswer } from '../models/ELM/IAnswer';
import { IEntitlement } from '../models/ELM/IEntitlement';
import { IGrant } from '../models/ELM/IGrant';
import { IGrantRequest } from '../models/ELM/IGrantRequest';
import { IRoleScope } from '../models/ELM/IRoleScope';
import { ISchedule } from '../models/ELM/ISchedule';
import { OriginSystem, originSystemMap } from '../models/ELM/OriginSystem';
import { ICustomData } from '../models/RequestApprovals/ICustomData';
import { IRaeRequest } from '../models/RequestApprovals/IRaeRequest';

export const getGrantRequestFromRaeRequest = (
  raeRequest: IRaeRequest
): IGrantRequest => {
  const createdBy = raeRequest.createdBy;
  const requestorName = createdBy.displayName || createdBy.mail;
  const requestorEmail = createdBy.mail || createdBy.userPrincipalName;
  const customData = JSON.parse(raeRequest.customData, (key, value) => {
    if (key === 'Answers') {
      return JSON.parse(value, (key1, value1) => {
        if (key1 === 'Answers') {
          return value1 as IAnswer[];
        } else {
          return value1;
        }
      });
    } else if (key === 'ResourceRoles') {
      return JSON.parse(value) as IRoleScope[];
    } else {
      return value;
    }
  }) as ICustomData;
  return {
    accessPackageAssignment: {
      accessPackage: {
        displayName: raeRequest.resourceDisplayName,
        accessPackageCatalog: {
          displayName: raeRequest.catalogName,
        },
        accessPackageResourceRoleScopes: getResourceRoleScopesFromCustomData(
          customData
        ),
      } as IEntitlement,
      schedule: {
        startDateTime: raeRequest.entitlementDurationStartDate,
        stopDateTime: raeRequest.entitlementDurationEndDate,
      } as ISchedule,
    } as Partial<IGrant>,
    justification: raeRequest.description,
    requestor: {
      displayName: requestorName,
      principalName: requestorEmail,
    },
    createdDateTime: raeRequest.createdDateTime,
    answers: getAnswersFromCustomData(customData),
  } as IGrantRequest;
};

const getAnswersFromCustomData = (customData: ICustomData): IAnswer[] => {
  let answers: AnswerString[] = [];
  if (customData && customData.Answers && customData.Answers.answers) {
    answers = customData.Answers.answers.map(
      // tslint:disable-next-line:no-any
      (a: any) =>
        <AnswerString>{
          answeredQuestion: a.answeredQuestion,
          displayValue: a.displayValue,
          value: a.value,
        }
    );
  }
  return answers;
};

const getResourceRoleScopesFromCustomData = (
  customData: ICustomData
): IRoleScope[] => {
  let roleScopes: IRoleScope[] = [];
  if (customData && customData.ResourceRoles) {
    roleScopes = customData.ResourceRoles.map(
      // tslint:disable-next-line:no-any
      (a: any) =>
        <IRoleScope>{
          accessPackageResourceRole: {
            id: a.Role.Id,
            description: a.Role.Description,
            displayName: a.Role.DisplayName,
            originSystem: a.Role.OriginSystem
              ? originSystemMap.get(a.Role.OriginSystem)
              : OriginSystem.AadGroup,
          },
          accessPackageResourceScope: {
            id: a.Scope.Id,
            description: a.Scope.Description,
            displayName: a.Scope.DisplayName,
            originSystem: a.Scope.OriginSystem
              ? originSystemMap.get(a.Scope.OriginSystem)
              : OriginSystem.AadGroup,
            accessPackageResource: {
              description: a.Scope.Resource.Description,
              displayName: a.Scope.Resource.DisplayName,
              id: a.Scope.Resource.Id,
              url: a.Scope.Resource.Url,
              environment: {
                displayName: a.Scope.Resource.Environment.DisplayName,
                description: a.Scope.Resource.Environment.Description,
                connectionInfoStr:
                  a.Scope.Resource.Environment.ConnectionInfoStr,
              },
            },
          },
        }
    );
  }
  return roleScopes;
};
