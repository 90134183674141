import { EntitlementActions } from '../../../models/EntitlementActions';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState } from '../../../models/IEntitlementState';
import { DecisionType } from '../../../models/RequestApprovals/DecisionType';
import { registry } from '../myAccessRegistry';

export const showResetDecisions = (
  state: IEntitlementState,
  action: IEntitlementAction<boolean>
): IEntitlementState => {
  return {
    ...state,
    showingResetDecisions: action.payload,
    bulkDecisionType: DecisionType.NotReviewed
  };
};
registry.add(EntitlementActions.showResetDecisions, showResetDecisions);
