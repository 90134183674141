import { IAjaxRequest } from '@microsoft/portal-app/lib/auth/withAuth';
import { notificationsMerge } from '@microsoft/portal-app/lib/Notifications/helpers/notificationsMerge';
import {
  INotification,
  NotificationSeverity,
  NotificationType
} from '@microsoft/portal-app/lib/Notifications/models/INotification';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { errorHandler } from '@microsoft/portal-app/lib/redux/observableErrorHandler';
import { TranslationOptions } from 'i18next';
import * as moment from 'moment';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import 'rxjs/add/observable/concat';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import { Observable } from 'rxjs/Observable';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';
import { catchError, flatMap } from 'rxjs/operators';
import { v4 as guid } from 'uuid';
import { IGrantRequest } from '../../../models/ELM/IGrantRequest';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { EntityType } from '../../../models/EntityType';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import {
  IEntitlementState,
  IRootEntitlementsState
} from '../../../models/IEntitlementState';
import { getAudience } from '../../../shared/AttachAudience';
import { cancelGrantRequestApiUrl } from '../../../shared/getApiUrl';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { registry } from '../myAccessRegistry';

export const updateGrantRequestsEpic: Epic<
  IEntitlementAction<AnyPayload>,
  IRootEntitlementsState
> = (
  action$: ActionsObservable<IEntitlementAction<Partial<IGrantRequest>>>,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
    return action$
      .ofType(EntitlementActions.updateGrantRequest)
      .switchMap((_action: IEntitlementAction<Partial<IGrantRequest>>) => {
        const grantRequest = _action.payload!;
        const correlationId = guid();
        const ajaxRequest: IAjaxRequest = {
          method: 'POST',
          url: cancelGrantRequestApiUrl(grantRequest.id!),
          body: grantRequest,
          audience: getAudience(),
          headers: {
            'x-ms-client-request-id': correlationId
          }
        };

        const updateSucceededAction = {
          type: EntitlementActions.updateGrantRequestSucceeded,
          payload: {
            id: grantRequest.id
          }
        };

        const refreshEntitiesAction = {
          type: EntitlementActions.refreshEntities,
          payload: {
            entityType: EntityType.grantRequests
          }
        };
        return ajax(ajaxRequest).pipe(
          flatMap(() => {
            return Observable.concat(
              Observable.of(updateSucceededAction),
              Observable.of(refreshEntitiesAction)
            );
          }),
          catchError(
            errorHandler<IEntitlementAction>(
              EntitlementActions.updateGrantRequestFailed
            )
          )
        );
      });
  };
registry.addEpic('updateGrantRequestsEpic', updateGrantRequestsEpic);

export const updateGrantRequest = (
  state: IEntitlementState
): Readonly<IEntitlementState> => {
  return {
    ...state,
    submitting: true
  };
};
registry.add(EntitlementActions.updateGrantRequest, updateGrantRequest);

export const updateGrantRequestSucceeded = (
  state: IEntitlementState,
  action: IEntitlementAction<Readonly<string>>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  const toastOptions: TranslationOptions = {};

  const notification = {
    localizableTitle: {
      key: LocaleKeys.cancelRequestSucceeded,
      options: toastOptions
    },
    localizableMessage: {
      key: LocaleKeys.cancelRequestSucceededMessage,
      options: toastOptions
    },
    createdDateTime: moment(),
    severity: NotificationSeverity.success
  };

  let notifications: INotification[] = [
    {
      ...notification,
      id: guid(),
      type: NotificationType.card
    },
    {
      ...notification,
      id: guid(),
      type: NotificationType.toast
    }
  ];

  return {
    ...state,
    notifications: notificationsMerge(
      notifications,
      state.notifications,
      state.notificationsLimit
    ),
    submitting: false,
    showingConfirmDialog: false,
    showingGrantRequestDetails: false
  };
};
registry.add(
  EntitlementActions.updateGrantRequestSucceeded,
  updateGrantRequestSucceeded
);

export const updateGrantRequestFailed = (
  state: IEntitlementState,
  action: IEntitlementAction<Readonly<AnyPayload>>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  const payload = action.payload;

  const error =
    payload.response &&
    payload.response.error &&
    payload.response.error.message;

  const correlationId =
    payload.request &&
    payload.request.headers &&
    payload.request.headers['x-ms-client-request-id'];

  let toastOptions: TranslationOptions = {
    error: error,
    correlationId: correlationId
  };

  const notification = {
    localizableTitle: {
      key: LocaleKeys.cancelRequestFailed,
      options: toastOptions
    },
    localizableMessage: {
      key: LocaleKeys.errorTemplate,
      options: toastOptions
    },
    createdDateTime: moment(),
    severity: NotificationSeverity.error
  };

  let notifications: INotification[] = [
    {
      ...notification,
      id: guid(),
      type: NotificationType.card
    },
    {
      ...notification,
      id: guid(),
      type: NotificationType.toast
    }
  ];

  return {
    ...state,
    notifications: notificationsMerge(
      notifications,
      state.notifications,
      state.notificationsLimit
    ),
    submitting: false,
    showingConfirmDialog: false,
    showingGrantRequestDetails: false
  };
};
registry.add(
  EntitlementActions.updateGrantRequestFailed,
  updateGrantRequestFailed
);
