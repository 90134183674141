import '@microsoft/portal-app/lib/styling/patterns/Dialog.scss';
import { TranslationFunction } from 'i18next';
import { Checkbox, css, Link } from '@fluentui/react';
import * as React from 'react';
import { asLocalizedText } from '../../../shared/asLocalizedText';
import { LocaleKeys } from '../../../shared/LocaleKeys';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');

export interface IConsentToggleProps {
  t: TranslationFunction;
  tenantDisplayName?: string;
  privacyUrl?: string;
  onChange(): void;
}

export class ConsentToggle extends React.Component<IConsentToggleProps, {}> {
  constructor(nextProps: IConsentToggleProps) {
    super(nextProps);
  }

  public render(): JSX.Element {
    const { privacyUrl, onChange, t, tenantDisplayName } = this.props;
    const consentStatement = asLocalizedText(
      {
        key: LocaleKeys.consentSentence,
        options: {
          tenantDisplayName: tenantDisplayName
        }
      },
      t
    );

    return (
      <div
        className={css(
          myAccessStyles.marginBottomMedium,
          myAccessStyles.semiBoldText
        )}
      >
        <Checkbox label={consentStatement} onChange={onChange} />
        {privacyUrl ? (
          <Link
            href={privacyUrl}
            className={css(myAccessStyles.marginLeftMedium)}
          >
            {t(LocaleKeys.privacyStatement)}
          </Link>
        ) : (
          <div />
        )}
      </div>
    );
  }
}
