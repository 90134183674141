import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';

export const FormatDateTime = (
  dateTime: Date | string | null | undefined
): string => {
  if (!dateTime) {
    return '';
  }
  const tempDate = new Date(dateTime);
  if (!isValidDate(tempDate)) {
    return '' + dateTime;
  }
  const timeZone = momentTimezone.tz.guess();
  const abbrTimeZone = momentTimezone.tz(timeZone).zoneAbbr();
  return `${moment(dateTime!).format('lll')} ${abbrTimeZone}`;
};

export const FormatDate = (
  dateTime: Date | string | null | undefined
): string => {
  if (!dateTime) {
    return '';
  }
  const tempDate = new Date(dateTime);
  if (!isValidDate(tempDate)) {
    return '' + dateTime;
  }
  return moment(tempDate!).format('ll');
};

// tslint:disable-next-line:no-any
export const isValidDate = (d: any): boolean => {
  return d instanceof Date && !isNaN(d.getTime());
};
