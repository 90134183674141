import { IContextualMenuItem } from '@fluentui/react';
import { TranslationFunction } from 'i18next';

import { Routes } from '../shared/Routes';

export const getFilterMenu = (
  t: TranslationFunction,
  hideResourceSearchFilterOption: boolean
): IContextualMenuItem[] => {
  const locationHash = location.hash;

  if(locationHash.includes(Routes.requestApprovals) || locationHash.includes(Routes.approvals)) {
    return getApprovalsFilterMenu(t);
  } else {
    return getAccessPackageFilterMenu(t, hideResourceSearchFilterOption);
  }
};

export const getAccessPackageFilterMenu = (
  t: TranslationFunction,
  hideResourceSearchFilterOption: boolean
): IContextualMenuItem[] => {
  const filterItems: IContextualMenuItem[] = [
    {
      key: 'accessPackage',
      name: t('searchFilters.Name')
    }
  ];
  if (!hideResourceSearchFilterOption) {
    filterItems.push({
      key: 'resource',
      name: t('searchFilters.Resource')
    });
  }
  return filterItems;
};

export const getApprovalsFilterMenu = (
  t: TranslationFunction
): IContextualMenuItem[] => {
  return [
    {
      key: 'name',
      name: t('searchFilters.Name')
    },
    {
      key: 'accessPackage',
      name: t('searchFilters.Package')
    },
    {
      key: 'all',
      name: t('searchFilters.All')
     }
  ];
};
