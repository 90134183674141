import {
  IWithResponsiveModeState,
  withResponsiveMode,
} from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import * as React from 'react';
import { InjectedTranslateProps, TranslationFunction } from 'react-i18next';
import { IGrantRequest } from '../../../models';
import { LocaleKeys } from '../../../shared';
import * as ApprovalHelper from '../../../shared/approvalPersonaHelper';
import { DecisionActivityCard } from '../../Shared/DecisionActivityCard/DecisionActivityCard';
import { GrantRequestEscalationApprovalHistory } from '../GrantRequestEscalationApprovalHistory/GrantRequestEscalationApprovalHistory';

export interface IGrantRequestMultiStageApprovalHistoryProps
  extends IWithResponsiveModeState,
    InjectedTranslateProps {
  grantRequest: IGrantRequest | undefined;
  t: TranslationFunction;
}

@withResponsiveMode
export class GrantRequestMultiStageApprovalHistory extends React.Component<
  IGrantRequestMultiStageApprovalHistoryProps
> {
  constructor(nextProps: IGrantRequestMultiStageApprovalHistoryProps) {
    super(nextProps);
  }

  public render(): JSX.Element {
    const { grantRequest, t } = this.props;
    if (!grantRequest) {
      return <div />;
    }
    const totalStages = ApprovalHelper.getTotalStagesOfGrantRequest(grantRequest);
    if (totalStages === 1) {
      return <div />;
    }

    const decisions = grantRequest && grantRequest.approval.steps!;

    if (!decisions) {
      return <div />;
    }

    let firstApproverDescription =
     t(LocaleKeys.firstApproverWithStageCount, {
      totalCount: totalStages,
    });

    let secondApproverDescription =
     t(LocaleKeys.secondApproverWithStageCount, {
      totalCount: totalStages,
    });

    let thirdApproverDescription = t(
      LocaleKeys.thirdApproverWithStageCount, {
        totalCount: totalStages,
      }
    );

    let expirationDate = ApprovalHelper.getDueDate(grantRequest);
    // if this is the 1st stage and there's only 1 decision
    if (grantRequest.approval.requestApprovalSettings.approvalStages.length === 1 && decisions.length === 1) {
      if (ApprovalHelper.canCurrentStageBeEscalated(grantRequest)) {
        return (
          <GrantRequestEscalationApprovalHistory
            cardAdditionalDescription={firstApproverDescription}
            grantRequest={grantRequest}
            t={this.props.t}
          />
        );
      } else {
        return (
          <DecisionActivityCard
            t={t}
            decision={decisions[0]}
            cardDescription={firstApproverDescription}
            requestExpirationDate={expirationDate}
          />
        );
      }
    } else {
      const firstDecision = decisions && decisions[0];
      const secondDecision = decisions && decisions[1];
      const thirdDecision = decisions && decisions[2];

      return (
        <div>
          <DecisionActivityCard
            t={t}
            decision={firstDecision}
            cardDescription={firstApproverDescription}
            requestExpirationDate={expirationDate}
          />
          <DecisionActivityCard
            t={t}
            decision={secondDecision}
            cardDescription={secondApproverDescription}
            requestExpirationDate={expirationDate}
          />
          <DecisionActivityCard
            t={t}
            decision={thirdDecision}
            cardDescription={thirdApproverDescription}
            requestExpirationDate={expirationDate}
          />
        </div>
      );
    }
  }
}
