import { ErrorBanner } from '@microsoft/portal-app/lib/Banners/ErrorBanner';
import * as moment from 'moment';

import {
  ColumnActionsMode,
  CommandBar,
  css,
  FontClassNames,
  Link,
  SelectionMode,
  Announced
} from '@fluentui/react';
import { IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import {
  ResponsiveMode,
  withResponsiveMode
} from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';

import * as React from 'react';
import { TranslationFunction } from 'react-i18next';
import { EntitlementActions } from '../../../models';
import { IGrantRequest } from '../../../models/ELM/IGrantRequest';
import { EntityType } from '../../../models/EntityType';
import { IListColumn } from '../../../models/IListColumn';
import { ApprovalSearchFilter } from '../../../models/RequestApprovals/ApprovalSearchFilter';
import { DecisionType } from '../../../models/RequestApprovals/DecisionType';
import { ApprovalStatus } from '../../../models/RequestApprovals/IApproval';
import { FormatDate } from '../../../shared/FormatDateTime';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { getNewColumnsOnColumnClicked } from '../../../shared/sortingHelper';
import { getSpinner } from '../../../shared/spinner';
import { ConnectedBulkActionDialog } from '../../ELM/BulkActionDialog';
import { ColumnValue } from '../../Shared/ColumnValue/ColumnValue';
import { ConnectedGrantRequestMoreDetails } from '../../Shared/GrantRequestMoreDetails';
import { InfinityList } from '../../Shared/InfinityList/InfinityList';
import { CompletedGrantRequestDetails } from '../CompletedGrantRequestDetails/CompletedGrantRequestDetails';
import { telemetry } from '../../../shared/telemetry';
import {
  ICompletedGrantRequestListProps,
  ICompletedGrantRequestListState
} from './CompletedGrantRequestList.types';
import { QosProvider } from '@iamexperiences/ecos-telemetry';
import { RequestStatus } from '../../../models/ELM/RequestStatus';
import { SimpleRequestType } from '../../..//models/ELM/RequestType';
import { getSimpleRequestTypeFromRequestType } from '../../../models/ELM/RequestType';
import { RequestType } from '../../../models/ELM/RequestType';

const myAccessListStyles = require('../../../css/myAccessList.scoped.scss');
const myAccessStyles = require('../../../css/myAccess.scoped.scss');

@withResponsiveMode
export class CompletedGrantRequestList extends React.Component<
  ICompletedGrantRequestListProps,
  ICompletedGrantRequestListState
> {
  private _selectedItems: IGrantRequest[] | null = null;

  constructor(nextProps: ICompletedGrantRequestListProps) {
    super(nextProps);

    let columns = this._getCompletedGrantRequestListColumns(
      this._getResponsiveMode(),
      this.props.t!,
      this.props
    );

    this.state = {
      columns: columns,
      commands: this._getCompletedGrantRequestListCommands(),
      decision: DecisionType.DontKnow,
      oldApprovalsTelemetryEmitted: false
    };
  }

  public componentDidMount(): void {
    this.props.setSearchContext(
      EntitlementActions.searchCompletedGrantRequestsOnServer
    );
    this.props.refreshEntities();
  }

  public componentDidUpdate(prevProps: ICompletedGrantRequestListProps): void {
    const requests = this.props.completedGrantRequestList as IGrantRequest[];
    const oldRequestsPresent = requests.some(r => moment(r.createdDateTime!).isBefore(moment().subtract(90, 'days')));
    if (oldRequestsPresent && !this.state.oldApprovalsTelemetryEmitted) {
      telemetry.reportCustomEvent('approvalhistory/oldrequests')
      this.setState({ oldApprovalsTelemetryEmitted: true });
    }
  }

  public render(): JSX.Element {
    const {
      isLoading,
      isRefreshing,
      isLoadingMore,
      errorHasOccurred,
      errorCode,
      pageMetaData,
      completedGrantRequestList,
      showingDetails,
      showingBulkActionDialog,
      t,
      isSearching,
      searchTerm
    } = this.props;

    if (isRefreshing) {
      return getSpinner(
        t(LocaleKeys.loadingPage, {
          pageName: t(LocaleKeys.approval, { context: 'plural' })
        })
      );
    }

    if (errorHasOccurred) {
      if (errorCode !== 403) {
        return (
          <ErrorBanner
            text={t(LocaleKeys.errorMessage)}
            onAction={this.props.getEntities}
            actionText={t(LocaleKeys.retry)}
          />
        );
      }
    }
    const requests = completedGrantRequestList as IGrantRequest[];

    const showNoEntities =
      errorHasOccurred && errorCode === 403
        ? true
        : pageMetaData.allEntityCount === 0 && !isSearching;

    const showLoadMore =
      errorHasOccurred && errorCode === 403
        ? false
        : !pageMetaData.isAllEntitiesFullyCached &&
        !isLoading &&
        !isLoadingMore &&
        !pageMetaData.isFilteredEntitiesFullyCached;

    const filteredCount = pageMetaData.filteredEntityCount
      ? pageMetaData.filteredEntityCount
      : 0;

    const showNoFilteredResults =
      !isLoading && isSearching && filteredCount === 0;

    const completedGrantRequest =
      this._selectedItems && this._selectedItems.length > 0
        ? this._selectedItems[0]
        : undefined;

    const decisions = completedGrantRequest && completedGrantRequest.approval.steps!;

    return (
      <QosProvider name='CompletedApprovalList' >
        <div
          className={css(myAccessListStyles.listPage, myAccessListStyles.padding)}
        >
          {!this.props.isLoading && isSearching && <Announced message={`${filteredCount} items found`} />}
          {this.props.enableApproverRevoke &&
            <CommandBar
              className={css(myAccessListStyles.commandBar)}
              items={this.state.commands!}
              farItems={[]}
            />}
          <InfinityList
            t={t}
            entityList={requests}
            entityType={EntityType.completedGrantRequests}
            ariaLabel={'List of completed approvals.'}
            columns={this.state.columns}
            showLoadMore={showLoadMore}
            showSpinner={isLoadingMore}
            spinnerLabel={t(LocaleKeys.loadingPage, {
              pageName: t(LocaleKeys.approvalUpper, { context: 'plural' })
            })}
            showNoEntities={showNoEntities}
            noEntitiesProps={{
              iconName: 'Unlock',
              noRowMessage: LocaleKeys.noCompletedApprovalsMessage,
              showButton: false
            }}
            showNoFilteredResults={showNoFilteredResults}
            onLoadMore={this._loadMore}
            onItemSelected={this._onItemSelected}
            selectionMode={SelectionMode.single}
            isExpanded={false}
            searchTerm={searchTerm}
          />

          {showingDetails ? (
            <CompletedGrantRequestDetails
              grantRequest={completedGrantRequest}
              onDismiss={this.props.dismissDetails}
              decisions={decisions!}
              showMoreGrantRequestDetails={this._showMoreGrantRequestDetails}
              t={t}
              enableApproverRevoke={this.props.enableApproverRevoke}
            />
          ) : null}

          {this.state.showingMoreGrantRequestDetails ? (
            <ConnectedGrantRequestMoreDetails
              fetchEntityType={EntityType.completedGrantRequests}
              grantRequest={this.state.grantRequest!}
              onDismiss={this._hideMoreGrantRequestDetails}
            />
          ) : null}


          {showingBulkActionDialog ? (
            <ConnectedBulkActionDialog
              selectedGrantRequests={this._selectedItems!}
              decisionType={DecisionType.Revoke}
              onDismiss={this.props.dismissBulkActionDialog}
              isApproverReasonRequired={false}
              responsiveMode={this.props.responsiveMode}
            />
          ) : null}
        </div>
      </QosProvider>
    );
  }

  private _showMoreGrantRequestDetails = (): void => {
    this.setState({ showingMoreGrantRequestDetails: true });
  }

  private _hideMoreGrantRequestDetails = (): void => {
    this.setState({ showingMoreGrantRequestDetails: false });
    this.props.showDetails();
  }

  private _onItemSelected = (selectedItems: IGrantRequest[]): void => {
    this._selectedItems = selectedItems;
    this.setState({
      commands: this._getCompletedGrantRequestListCommands(),
    });
  }

  private _loadMore = (): void => {
    if (!this.props.isLoading) {
      if (!this.props.isSearching) {
        this._getEntities();
      } else {
        this._searchEntitiesOnServer();
      }
    }
  }

  private _searchEntitiesOnServer = (): void => {
    if (
      this.props.pageMetaData.isAllEntitiesFullyCached! ||
      this.props.pageMetaData.isFilteredEntitiesFullyCached
    ) {
      return;
    }
    this.props.searchForMore();
  }

  private _getEntities = (): void => {
    if (this.props.pageMetaData.isAllEntitiesFullyCached) {
      return;
    }
    this.props.getEntities();
  }

  private _getCompletedGrantRequestListColumns(
    responsiveMode: ResponsiveMode,
    t: TranslationFunction,
    _props: ICompletedGrantRequestListProps
  ): IListColumn<IGrantRequest>[] {
    const column: IListColumn<IGrantRequest> = {
      key: 'createdBy',
      name: t(LocaleKeys.name),
      fieldName: 'createdBy',
      minWidth: 100,
      maxWidth: 200,
      className: 'ms-pii',
      headerClassName: FontClassNames.smallPlus,
      columnActionsMode: ColumnActionsMode.disabled,
      isResizable: true,
      onRender: (item: IGrantRequest) => (
        <div>
          <div>
            <ColumnValue
              searchTerm={
                this.props.selectedFilterKey === ApprovalSearchFilter.Name ||
                  this.props.selectedFilterKey === ApprovalSearchFilter.All
                  ? this.props.searchTerm
                  : ''
              }
              columnValue={item.requestor.displayName || ''}
              isHighlightRequired={false}
              isSearching={false}
            />
          </div>
          <div>
            <ColumnValue
              searchTerm={
                this.props.selectedFilterKey === ApprovalSearchFilter.Name ||
                  this.props.selectedFilterKey === ApprovalSearchFilter.All
                  ? this.props.searchTerm
                  : ''
              }
              columnValue={item.requestor.email || ''}
              isHighlightRequired={false}
              isSearching={false}
              isSecondary={true}
            />
          </div>
        </div>
      )
    } as IListColumn<IGrantRequest>;

    let columns: IListColumn<IGrantRequest>[] = [];
    columns.push(column);

    if (responsiveMode > ResponsiveMode.medium) {
      columns.push({
        key: 'entitlementName',
        name: t(LocaleKeys.accessPackage),
        fieldName: 'resourceDisplayName',
        columnActionsMode: ColumnActionsMode.disabled,
        headerClassName: FontClassNames.smallPlus,
        minWidth: 60,
        maxWidth: 158,
        isResizable: true,
        onRender: (item: IGrantRequest) => (
          <ColumnValue
            searchTerm={
              this.props.selectedFilterKey ===
                ApprovalSearchFilter.AccessPackage ||
                this.props.selectedFilterKey === ApprovalSearchFilter.All
                ? this.props.searchTerm
                : ''
            }
            columnValue={item.accessPackageAssignment.accessPackage!.displayName || ''}
            isHighlightRequired={true}
            isSearching={this.props.isSearching}
          />
        )
      });
    }

    if (responsiveMode > ResponsiveMode.large) {
      columns.push({
        key: 'approvalsStatus',
        name: t(LocaleKeys.decision),
        fieldName: 'approvals/status',
        headerClassName: FontClassNames.smallPlus,
        columnActionsMode: ColumnActionsMode.disabled,
        minWidth: 40,
        maxWidth: 80,
        isResizable: true,
        onRender: (item: IGrantRequest) => {
          const approval =
            item.approval.steps && item.approval.steps.length > 0
              ? item.approval.steps[item.approval.steps.length - 1]
              : null;
          const decisionType = approval
            ? t(ApprovalStatus[approval.reviewResult])
            : '';
          return (
            <ColumnValue
              searchTerm={''}
              columnValue={decisionType}
              isHighlightRequired={false}
              isSearching={false}
            />
          );
        }
      });
    }

    if (responsiveMode > ResponsiveMode.xLarge) {
      columns.push({
        key: 'decisionBy',
        name: t(LocaleKeys.decisionBy),
        fieldName: 'decisionBy',
        columnActionsMode: ColumnActionsMode.disabled,
        headerClassName: FontClassNames.smallPlus,
        minWidth: 60,
        maxWidth: 200,
        isResizable: true,
        onRender: (item: IGrantRequest) => {
          const approval =
            item.approval.steps && item.approval.steps.length > 0
              ? item.approval.steps[item.approval.steps.length - 1]
              : null;
          const approver = approval ? approval.reviewedBy : null;
          const approverDisplayName = approver ? approver.displayName : '';
          const approverMail = approver ? approver.email : '';

          return (
            <div>
              <div>
                <ColumnValue
                  searchTerm={''}
                  columnValue={approverDisplayName || ''}
                  isHighlightRequired={false}
                  isSearching={false}
                />
              </div>
              <div>
                <ColumnValue
                  searchTerm={''}
                  columnValue={approverMail || ''}
                  isHighlightRequired={false}
                  isSearching={false}
                />
              </div>
            </div>
          );
        }
      });
    }


    if (this.props.enableApproverRevoke && responsiveMode > ResponsiveMode.xLarge) {
      columns.push({
        key: 'status',
        name: t(LocaleKeys.requestState),
        fieldName: 'history',
        headerClassName: FontClassNames.smallPlus,
        columnActionsMode: ColumnActionsMode.disabled,
        minWidth: 40,
        maxWidth: 80,
        isResizable: true,
        onRender: (item: IGrantRequest) => {
          let status = '';
          const fulfilledActivity =
            item.history.filter(a => RequestStatus[a.action] === RequestStatus.Fulfilled)
          if (fulfilledActivity[0]) {
            status = t(LocaleKeys.active)
          }
          const pendingRemovalActivity =
            item.history.filter(a => RequestStatus[a.action] === RequestStatus.PendingRemoval)
          if (pendingRemovalActivity[0]) {
            status = t(LocaleKeys.pendingRemoval)
          }
          const accessRemovedActivity =
            item.history.filter(a => getSimpleRequestTypeFromRequestType(RequestType[a.action]) === SimpleRequestType.Remove)
          if (accessRemovedActivity[0]) {
            status = t(LocaleKeys.removed)
          }
          return (
            <ColumnValue
              searchTerm={''}
              columnValue={status}
              isHighlightRequired={false}
              isSearching={false}
            />
          );
        }
      });
    }

    if (responsiveMode > ResponsiveMode.medium) {
      columns.push({
        key: 'createdDateTime',
        name: t(LocaleKeys.dateRequested),
        fieldName: 'createdDateTime',
        isSorted: true,
        isSortedDescending: true,
        onColumnClick: this._onColumnClick,
        headerClassName: FontClassNames.smallPlus,
        minWidth: 60,
        maxWidth: 120,
        isResizable: true,
        onRender: (item: IGrantRequest) => {
          return (
            <span className={css('ms-pii', FontClassNames.medium)}>
              {FormatDate(item.createdDateTime)}
            </span>
          );
        }
      });
    }

    if (responsiveMode > ResponsiveMode.medium) {
      columns.push({
        key: 'target',
        name: t(LocaleKeys.requestedFor),
        fieldName: 'target',
        minWidth: 100,
        maxWidth: 200,
        className: 'ms-pii',
        headerClassName: FontClassNames.smallPlus,
        columnActionsMode: ColumnActionsMode.disabled,
        isResizable: true,
        onRender: (item: IGrantRequest) => (
          <div>
            <div>
              <ColumnValue
                searchTerm={
                  this.props.selectedFilterKey === ApprovalSearchFilter.Name ||
                    this.props.selectedFilterKey === ApprovalSearchFilter.All
                    ? this.props.searchTerm
                    : ''
                }
                columnValue={item.accessPackageAssignment.target!.displayName || ''}
                isHighlightRequired={true}
                isSearching={this.props.isSearching}
              />
            </div>
            <div>
              <ColumnValue
                searchTerm={
                  this.props.selectedFilterKey === ApprovalSearchFilter.Name ||
                    this.props.selectedFilterKey === ApprovalSearchFilter.All
                    ? this.props.searchTerm
                    : ''
                }
                columnValue={item.accessPackageAssignment.target!.email || ''}
                isHighlightRequired={true}
                isSearching={this.props.isSearching}
                isSecondary={true}
              />
            </div>
          </div>
        )
      });
    }

    columns.push({
      key: 'viewDetails',
      name: '',
      fieldName: 'id',
      minWidth: 10,
      maxWidth: 30,
      headerClassName: FontClassNames.smallPlus,
      columnActionsMode: ColumnActionsMode.disabled,
      isResizable: true,
      onRender: (item: IGrantRequest) => {
        if (item.requestStatus === 'Approve') {
          return (
            <span className={css(FontClassNames.medium)}>
              {this.props.t(LocaleKeys.approved)}
            </span>
          );
        } else if (item.requestStatus === 'Deny') {
          return (
            <span className={css(FontClassNames.medium)}>
              {this.props.t(LocaleKeys.denied)}
            </span>
          );
        }

        return (
          <Link
            // tslint:disable-next-line:jsx-no-lambda
            onClick={() => this._onViewDetailsClicked(item)}
            className={css(FontClassNames.medium, myAccessStyles.themeDarkFont)}
          >
            {t(LocaleKeys.view)}
          </Link>
        );
      }
    });

    return columns;
  }

  private _onViewDetailsClicked = (completedGrantRequest: IGrantRequest): void => {
    this.setState({
      grantRequest: completedGrantRequest
    });
    this.props.showDetails();
  };

  private _getCompletedGrantRequestListCommands = (): IContextualMenuItem[] => {
    const { t } = this.props;
    const itemNotSelected =
      !this._selectedItems! || this._selectedItems!.length === 0;


    const selectedItems = this._getSelectedItems();

    let anySelectedDeniedOrTimedoutOrRemovedOrNotFulfilled: boolean = false;

    if (selectedItems) {
      for (let selectedItem of selectedItems!) {
        if (this._isNotApprovedRequestStatus(selectedItem) || this._isAccessRemovedOrNotFulfilled(selectedItem)) {
          anySelectedDeniedOrTimedoutOrRemovedOrNotFulfilled = true;
          break;
        }
      }
    }

    const revokeCommand: IContextualMenuItem = {
      key: 'remove',
      name: t(LocaleKeys.removeAccess),
      iconProps: { iconName: 'Cancel' },
      disabled: itemNotSelected || anySelectedDeniedOrTimedoutOrRemovedOrNotFulfilled,
      onClick: () => this._openBulkActionDialog(DecisionType.Revoke)
    };

    return [revokeCommand];
  }

  private _getSelectedItems = (): IGrantRequest[] | undefined => {
    return this._selectedItems && this._selectedItems.length > 0
      ? this._selectedItems
      : undefined;
  }

  private _isNotApprovedRequestStatus = (
    grantRequest: IGrantRequest
  ): boolean => {
    if (!grantRequest) {
      return false;
    }
    const approvalStatus = this._getApprovalStatus(grantRequest);
    return (
      approvalStatus !== 'Approve'
    );
  };


  private _isAccessRemovedOrNotFulfilled = (
    grantRequest: IGrantRequest
  ): boolean => {
    if (!grantRequest) {
      return false;
    }

    const fulfilledActivity =
      grantRequest.history.filter(a => RequestStatus[a.action] === RequestStatus.Fulfilled)

    const accessRemovalActivity =
      grantRequest.history.filter(a => RequestStatus[a.action] == RequestStatus.PendingRemoval || getSimpleRequestTypeFromRequestType(RequestType[a.action]) === SimpleRequestType.Remove)
    if (!fulfilledActivity[0] || accessRemovalActivity[0]) {
      return true;
    }

    return false;
  };

  private _getApprovalStatus = (grantRequest: IGrantRequest): string => {
    const approval =
      grantRequest.approval.steps && grantRequest.approval.steps.length > 0
        ? grantRequest.approval.steps[grantRequest.approval.steps.length - 1]
        : null;
    return approval
      ? approval.reviewResult
      : '';
  }

  private _openBulkActionDialog = (decision: DecisionType): void => {
    this.setState({
      ...this.state,
      decision: decision,
    });
    this.props.showBulkActionDialog();
  }

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IListColumn<IGrantRequest>
  ): void => {
    ev.preventDefault();
    column.isSorted = true;
    column.isSortedDescending = !column.isSortedDescending;

    this.setState({
      columns: getNewColumnsOnColumnClicked(this.state.columns, column)
    });
    this.props.setSortedByColumn(column);

    if (!this.props.isSearching) {
      this.props.sortCompletedGrantRequests(
        column.fieldName,
        !column.isSortedDescending!
      );
    } else {
      this.props.sortFilteredEntities(
        column.key,
        !column.isSortedDescending!,
        this.props.searchTerm!
      );
    }
  };

  private _getResponsiveMode(): ResponsiveMode {
    let { responsiveMode } = this.props;
    if (responsiveMode === undefined) {
      responsiveMode = ResponsiveMode.large;
    }
    return responsiveMode;
  }
}
