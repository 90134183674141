export const DaysSinceSubmitted = [
  {
    days: -1,
    title: 'submittedFromAllTime'
  },
  {
    days: 365,
    title: 'submittedInLast1Year'
  },
  {
    days: 90,
    title: 'submittedInLast3Months'
  },
  {
    days: 30,
    title: 'submittedInLast30Days'
  },
  {
    days: 7,
    title: 'submittedInLast7Days'
  },
  {
    days: 1,
    title: 'submittedSinceYesterday'
  },
  {
    days: 0,
    title: 'submittedToday'
  }
];
