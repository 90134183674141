import { ChoiceGroup, ColorClassNames, css, IChoiceGroupOption, Icon, IconButton, Label, Text, TooltipHost } from '@fluentui/react';
import * as React from 'react';
import { LocaleKeys } from '../../../../shared/LocaleKeys';
import { IGetPolicySelectionProps, IGetPolicySelectionState } from './GetPolicySelection.types';
import { registerIcons } from '@fluentui/react/lib/Styling';

const styles = require('./GetPolicySelection.scoped.scss');
const myAccessStyles = require('../../../../css/myAccess.scoped.scss');
import { IGrantPolicy } from '../../../../models/ELM/IGrantPolicy';
import { getSpinner } from '../../../../shared/spinner';
import { IGrantRequest } from '../../../../models/ELM/IGrantRequest';

registerIcons({
  icons: {
    'verified-svg': (
      <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          // tslint:disable-next-line:max-line-length
          d="M4.58824 3.29984C4.58824 3.90732 4.07468 4.39979 3.44118 4.39979C2.80767 4.39979 2.29412 3.90732 2.29412 3.29984C2.29412 2.69236 2.80767 2.19989 3.44118 2.19989C4.07468 2.19989 4.58824 2.69236 4.58824 3.29984ZM1.52941 5.6455C1.52941 5.26126 1.85425 4.94976 2.25496 4.94976H4.6274C5.0281 4.94976 5.35294 5.26126 5.35294 5.6455C5.35294 6.26678 4.912 6.80833 4.28346 6.95902L4.23833 6.96983C3.71495 7.09531 3.1674 7.0953 2.64402 6.96983L2.5989 6.95902C1.97035 6.80833 1.52941 6.26678 1.52941 5.6455ZM7.26471 2.93319C7.05354 2.93319 6.88235 3.09735 6.88235 3.29984C6.88235 3.50233 7.05354 3.66649 7.26471 3.66649H9.55882C9.76999 3.66649 9.94118 3.50233 9.94118 3.29984C9.94118 3.09735 9.76999 2.93319 9.55882 2.93319H7.26471ZM7.26471 5.13308C7.05354 5.13308 6.88235 5.29724 6.88235 5.49973C6.88235 5.70223 7.05354 5.86638 7.26471 5.86638H7.74343C7.81325 5.60623 7.91936 5.36004 8.05628 5.13308H7.26471ZM1.33824 8.06628H8.05628C8.15839 8.23556 8.27765 8.39415 8.41177 8.53986V8.79957H1.33824C0.599148 8.79957 0 8.22503 0 7.5163V1.28327C0 0.57454 0.599148 0 1.33824 0H10.8971C11.6361 0 12.2353 0.57454 12.2353 1.28327V4.0589C11.9986 3.92761 11.7419 3.82586 11.4706 3.7589V1.28327C11.4706 0.979529 11.2138 0.733298 10.8971 0.733298H1.33824C1.02148 0.733298 0.764706 0.979529 0.764706 1.28327V7.5163C0.764706 7.82004 1.02148 8.06628 1.33824 8.06628ZM13 6.59968C13 7.81465 11.9729 8.79957 10.7059 8.79957C9.43888 8.79957 8.41177 7.81465 8.41177 6.59968C8.41177 5.38471 9.43888 4.39979 10.7059 4.39979C11.9729 4.39979 13 5.38471 13 6.59968ZM12.2353 9.14046C11.7854 9.39003 11.263 9.53287 10.7059 9.53287C10.1487 9.53287 9.62638 9.39003 9.17647 9.14046V10.8164C9.17647 10.9586 9.33797 11.0466 9.4654 10.9739L10.7059 10.2662L11.9464 10.9739C12.0738 11.0466 12.2353 10.9586 12.2353 10.8164V9.14046Z"
          fill="#0078D4"
        />
      </svg>
    )
  }
});

export class GetPolicySelection extends React.Component<IGetPolicySelectionProps, IGetPolicySelectionState> {
  private _policyOptions: IChoiceGroupOption[] | undefined;
  constructor(nextProps: IGetPolicySelectionProps) {
    super(nextProps);
    let selectedPolicy = '';
    if (
      !nextProps.policiesDefined &&
      !nextProps.policiesLoading &&
      !nextProps.hasValidationErrors &&
      nextProps.grantRequest?.accessPackageAssignment?.accessPackageId &&
      !nextProps.usingModal
    ) {
      nextProps.getRequestPolicies(nextProps.grantRequest!);
    } else if (nextProps.policies.length === 1) {
      selectedPolicy = nextProps.policies[0].id;
      const newGrantRequest: Partial<IGrantRequest> = {
        ...nextProps.grantRequest,
        accessPackageAssignment: {
          ...nextProps.grantRequest?.accessPackageAssignment,
          assignmentPolicyId: selectedPolicy
        }
      };
      nextProps.setPartialGrantRequest(newGrantRequest);
    }
    this.state = {
      selectedPolicy
    };
  }

  public componentDidUpdate(_previousProps: IGetPolicySelectionProps): void {
    if (this.props.policies.length === 1 && this.state.selectedPolicy === '') {
      const selectedPolicy = this.props.policies[0].id;
      const newGrantRequest: Partial<IGrantRequest> = {
        ...this.props.grantRequest,
        accessPackageAssignment: {
          ...this.props.grantRequest?.accessPackageAssignment,
          assignmentPolicyId: selectedPolicy
        }
      };
      this.props.setPartialGrantRequest(newGrantRequest);
      this.setState({
        ...this.state,
        selectedPolicy: selectedPolicy
      });
    }
  }

  public render(): JSX.Element {
    if (this.props.policiesLoading) {
      return getSpinner(this.props.t(LocaleKeys.loading));
    } else {
      return this.renderContent();
    }
  }

  private onPolicySelectionChanged = (_event?: React.FormEvent, option?: IChoiceGroupOption): void => {
    const selectedPolicy = this.getPolicy(option!.key);
    if (!selectedPolicy) {
      return;
    }
    const newGrantRequest: Partial<IGrantRequest> = {
      ...this.props.grantRequest,
      accessPackageAssignment: {
        ...this.props.grantRequest?.accessPackageAssignment,
        assignmentPolicyId: selectedPolicy.id
      }
    };
    this.setState({
      ...this.state,
      selectedPolicy: selectedPolicy.id
    });
    this.props.setPartialGrantRequest(newGrantRequest);
  };

  private getPolicy(policyId: string | number): IGrantPolicy | undefined {
    const matchingPolicies = this.props.policies.filter((policy) => policy.id === policyId);
    return matchingPolicies.length > 0 ? matchingPolicies[0] : undefined;
  }

  private getPolicySubtitle(policy: IGrantPolicy): JSX.Element {
    const { t } = this.props;
    return (
      <div>
        <div className={css(styles.policyDescription)}>
          <Text variant="small">{policy.description}</Text>
        </div>
        {policy.verifiableCredentialRequirementStatus && (
          <div className={css(styles.verifiedDisclosure)}>
            <Text className={css(styles.blueColor)} variant="small">
              <Icon iconName="verified-svg" />
              {t(LocaleKeys.verifiableCredentialPolicyRequest)}
            </Text>
          </div>
        )}
      </div>
    );
  }

  private get policyOptions(): IChoiceGroupOption[] {
    const { t, usingModal } = this.props;
    // empty if we're not ready to render policy options
    if (this._policyOptions === undefined && this.props.policies.length === 0) {
      return [];
    } else if (this._policyOptions === undefined) {
      this._policyOptions = this.props.policies.map((policy) => {
        let policyDisclosure = policy.verifiableCredentialRequirementStatus ? (
          <Text className={css(styles.disclosure)}>{t(LocaleKeys.verifiableCredentialPolicyDisclosure)}</Text>
        ) : null;
        return {
          key: policy.id,
          text: policy.displayName,
          onRenderField: (props: IChoiceGroupOption, render: any) => {
            return (
              <div>
                <div>{render!(props)}</div>
                {usingModal ? (
                  this.getPolicySubtitle(policy)
                ) : (
                  <div className={css(myAccessStyles.marginLeftSmaller, ColorClassNames.neutralSecondary)}>
                    {policyDisclosure}
                  </div>
                )}
              </div>
            );
          }
        } as IChoiceGroupOption;
      });
    }
    return this._policyOptions;
  }

  private renderContent = (): JSX.Element => {
    const { t, usingModal } = this.props;

    return (
      <div>
        <div className={css(styles.content, styles.modal)}>
          {!this.props.policiesLoading && this.props.policiesDefined ? (
            <div>
              <Label htmlFor="policySelection" required={true}>
                <h3 className={css(styles.subTitle)}>
                  {t(usingModal ? LocaleKeys.chooseAPolicy : LocaleKeys.selectAPolicy)}
                </h3>
              </Label>
              {!usingModal && (
              <TooltipHost content={t(LocaleKeys.policyTooltip)} id='policyInfo'>
                <IconButton iconProps={{iconName: 'info'}} aria-label={t(LocaleKeys.policyTooltip)}/>
              </TooltipHost>
              )}
              <ChoiceGroup
                id="policySelection"
                options={this.policyOptions}
                onChange={this.onPolicySelectionChanged}
                selectedKey={this.state.selectedPolicy!}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  };
}
