import * as React from 'react';
import { DocumentCard } from '@fluentui/react';
import { Shimmer, ShimmerElementType } from '@fluentui/react';
const overviewCardStyle = require('../../../css/overview.scoped.scss');

const shimmerButton = [{ type: ShimmerElementType.line, height: 8, width: '50%' }];
const shimmerTitle = [{ type: ShimmerElementType.line, height: 8 }];
const shimmerContent = [{ type: ShimmerElementType.line, height: 8, width: '50%' }];
const shimmerImage = [{ type: ShimmerElementType.line, height: 60 }];

export function OverviewLoadingCard() {
  return (
    // Disabling for inline styles
    // tslint:disable
    <DocumentCard className={overviewCardStyle.cardNormal} style={{ alignItems: 'normal' }}>
      <Shimmer className={overviewCardStyle.loadingTitle} shimmerElements={shimmerTitle} />
      <div className={overviewCardStyle.loadingContentWrapper}>
        <div className={overviewCardStyle.loadingContent}>
          <Shimmer shimmerElements={shimmerContent} />
          <Shimmer shimmerElements={shimmerContent} />
          <Shimmer shimmerElements={shimmerContent} width="35%" />
        </div>
        <div className={overviewCardStyle.loadingImage}>
          <Shimmer shimmerElements={shimmerImage} />
        </div>
      </div>
      <div>
        <div className={overviewCardStyle.cardBottom}>
          <div className={overviewCardStyle.loadingCardBottom}>
            <Shimmer shimmerElements={shimmerButton} width="40%" />
          </div>
        </div>
      </div>
    </DocumentCard>
  );
}
