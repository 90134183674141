import { IRoleScope } from './IRoleScope';

export interface IRoleAssignment extends IRoleScope {
  readonly status: RoleAssignmentStatus;
}

export enum RoleAssignmentStatus {
  PendingFulfillment = 'raStatus.processing',
  Fulfilled = 'raStatus.fulfilled',
  PendingRemoval = 'raStatus.pendingRemoval'
}
