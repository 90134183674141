import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { translate } from 'react-i18next';
import { connect, Dispatch } from 'react-redux';
import { EntitlementActions } from '../../../models';
import { IGrantRequest } from '../../../models/ELM/IGrantRequest';
import { IRootEntitlementsState } from '../../../models/IEntitlementState';
import { history } from '../../../redux/configureStore';
import { isInternalUser } from '../../../shared/authHelper';
import { AddGrantRequest } from './AddGrantRequest';
import {
  IAddGrantRequestActions,
  IAddGrantRequestMappedProps,
  IConnectedAddGrantRequestProps
} from './AddGrantRequest.types';

/**
 * Maps the external (i.e. own) props and the state to the properties of the AddGrantRequest control.
 * @param state The current redux state to map to props
 * @param ownProps The external properties to map to props
 * @returns The mapped properties that along with the actions
 * becomes the props (IAddGrantRequestProps) for the AddGrantRequest control
 */
const mapStateToProps = (
  state: IRootEntitlementsState,
  ownProps: IConnectedAddGrantRequestProps
): IAddGrantRequestMappedProps => {
  const needsConsent = !isInternalUser();
  const tenantContext = state.app.tenantContext!;
  const privacyUrl =
    tenantContext && tenantContext.tenant && tenantContext.tenant.privacyUrl!;
  const tenantDisplayName =
    tenantContext && tenantContext.tenant && tenantContext.tenant.displayName!;
  const { submitting, validating, validationErrors } = state.app;

  return {
    ...ownProps,
    submitting,
    validating,
    needsConsent,
    tenantDisplayName,
    privacyUrl,
    validationErrors,
    navigateTo(url: string): void {
      history.push(url);
    }
  };
};

/**
 * Maps the dispatch actions to the props for the AddGrantRequest control.
 * @param dispatch Dispatches actions to the store
 * @returns The mapped actions that along with the mapped properties
 * becomes the props (IAddGrantRequestProps) for the AddGrantRequest control
 */
const mapDispatchToProps = (
  dispatch: Dispatch<IRootEntitlementsState>
): IAddGrantRequestActions => {
  return {
    postGrantRequest: createAction<{
      newGrantRequest: Partial<IGrantRequest>;
      entitlementName: string;
    }>(EntitlementActions.addGrantRequest, dispatch),
    clearValidationErrors: createAction(
      EntitlementActions.clearValidationErrors,
      dispatch
    )
  };
};

/**
 * A redux connected AddGrantRequest control.
 * @description Supports translation
 */
export const ConnectedAddGrantRequest = connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('MyAccess')(AddGrantRequest));
