import { removeLeadingSlash } from '../../src/redux/modules/ram/utils';
import { IFilter } from '../models/IFilter';
import { isEmptyOrUndefined } from '../shared/isEmptyOrUndefined';
import { settingsManager } from './settings';

export const getRamBaseUrlBasedOnEnv = (): string => {
  return settingsManager.get('ramApiUrl')!;
};

export const getVirtualMachinesApiUrl = (
  searchTerm?: string,
  filterContext?: IFilter,
  orderby?: string,
  isAscending?: boolean
): string => {
  const url =
    'https://api.azrbac.mspim.azure.com/api/v3/identityGovernance/privilegedAccess/eligibleResources?$expand=EligibilitySchedules,AssignmentSchedules';
  if (!isEmptyOrUndefined(searchTerm)) {
    return `${url}&$filter=contains(tolower(displayName), '${searchTerm.toLowerCase()}')`;
  } else if (filterContext?.selectedSubscription) {
    return `${url}&$filter=contains(tolower(properties/microsoft.identity.governance.common.data.externalmodels.v1.azurevmeligibleresourceproperties/subscriptionname), '${filterContext.selectedSubscription.toLowerCase()}')`;
  } else if (!isEmptyOrUndefined(orderby) && orderby === 'name') {
    return `${url}&$orderby=displayName ${isAscending ? 'asc' : 'desc'}`;
  } else if (!isEmptyOrUndefined(orderby) && orderby === 'subscription') {
    return `${url}&$orderby=properties/microsoft.identity.governance.common.data.externalmodels.v1.azurevmeligibleresourceproperties/subscriptionname ${
      isAscending ? 'asc' : 'desc'
    }`;
  } else if (!isEmptyOrUndefined(orderby) && orderby === 'resourceGroup') {
    return `${url}&$orderby=properties/microsoft.identity.governance.common.data.externalmodels.v1.azurevmeligibleresourceproperties/resourcegroupname ${
      isAscending ? 'asc' : 'desc'
    }`;
  }
  return `${url}&$orderby=displayName asc`;
};
export const generateRoleAssignmentDeactivateUrl = (
  scope: string,
  roleAssignmentScheduleRequestName: string
): string => {
  const baseUrl = getRamBaseUrlBasedOnEnv();
  scope = removeLeadingSlash(scope);

  return `${baseUrl}${scope}/providers/Microsoft.Authorization/roleAssignmentScheduleRequests/${roleAssignmentScheduleRequestName}?api-version=2020-10-01`;
};
