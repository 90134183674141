import { IAjaxRequest } from '@microsoft/portal-app/lib/auth/withAuth';
import { ILoading, LoadingState } from '@microsoft/portal-app/lib/models/ILoading';
import { notificationsMerge } from '@microsoft/portal-app/lib/Notifications/helpers/notificationsMerge';
import {
  INotification,
  NotificationSeverity,
  NotificationType
} from '@microsoft/portal-app/lib/Notifications/models/INotification';
import { IODataValueResponse } from '@microsoft/portal-app/lib/odata-utils';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { errorHandler } from '@microsoft/portal-app/lib/redux/observableErrorHandler';
import { TranslationOptions } from 'i18next';
import moment from 'moment';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { AjaxCreationMethod, AjaxResponse } from 'rxjs/observable/dom/AjaxObservable';
import { catchError, map } from 'rxjs/operators';
import { v4 } from 'uuid';

import { IGrantPolicy, IPolicyRequirements } from '../../../models/ELM/IGrantPolicy';
import { IValidationError, ValidationErrorCode } from '../../../models/ELM/IValidationError';
import { QuestionType } from '../../../models/ELM/QuestionType';
import { TelemetryEvent, IEligibleAccessPackageDetail, IEligibleAccessPackageRequestBody } from '../../../models';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { EntityType } from '../../../models/EntityType';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState, IRootEntitlementsState } from '../../../models/IEntitlementState';
import { getPolicyTargetKey, isEmptyOrUndefined, policiesContainsOBODirectReport } from '../../../shared';
import { getAudience } from '../../../shared/AttachAudience';
import { MultipleChoiceQuestionType, TextInputQuestionType } from '../../../shared/constants';
import { getRequestableAccessPackageDetailApiUrl } from '../../../shared/getApiUrl';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { telemetry } from '../../../shared/telemetry';
import { topLevelValidationErrors, userAlreadyHasAccess } from '../../../shared/validationErrorHelper';
import { registry } from '../myAccessRegistry';

export interface GetEligibleAccessPackageActionPayload {
  entityId: string;
  isPollingForStatus?: boolean;
  parameters: IEligibleAccessPackageRequestBody;
}

/**
 * internal interface used to transfer accessPackageId with policy results to success action
 */
interface IEligibleAccessPackageResult {
  accessPackageId: string;
  eligibleAccessPackage?: IEligibleAccessPackageDetail;
  subjectObjectId?: string;
}

export type IGetEligibleAccessPackageResponse = IEligibleAccessPackageDetail;
export type IGetEligibleAccessPackageAction = IEntitlementAction<GetEligibleAccessPackageActionPayload>;
export type IGetEligibleAccessPackageSuccessAction = IEntitlementAction<IGetEligibleAccessPackageSucceededActionPayload>;
export type IGetEligibleAccessPackageFailAction = IEntitlementAction<Readonly<AnyPayload>, MetaEligibleAccessPackageFailed>;

interface IGetEligibleAccessPackageSucceededActionPayload {
  result: IEligibleAccessPackageResult;
  payload: IEligibleAccessPackageDetail;
  isOnBehalfRequest?: boolean;
}

// a new epic is required because this is a POST request
export const getEligibleAccessPackageEpic: Epic<IEntitlementAction<AnyPayload>, IRootEntitlementsState> = (
  action$: ActionsObservable<IGetEligibleAccessPackageAction>,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
  return action$.ofType(EntitlementActions.getEligibleAccessPackageDetail).mergeMap((action: IGetEligibleAccessPackageAction) => {
    if (!action?.payload?.entityId || !action?.payload?.parameters) {
      throw new Error('getPoliciesEpic: entityId and parameters are required');
    }
    const { entityId, parameters } = action.payload;
    const ajaxRequest: IAjaxRequest = {
      url: getRequestableAccessPackageDetailApiUrl(entityId),
      audience: getAudience(EntityType.entitlements),
      method: 'POST',
      body: parameters
    };
    const subjectObjectId = parameters?.subject?.objectId;
    return ajax(ajaxRequest).pipe(
      map((payload: AjaxResponse): IGetEligibleAccessPackageSuccessAction => {
        return {
          type: EntitlementActions.getEligibleAccessPackageDetailSucceeded,
          payload: {
            result: {
              accessPackageId: entityId,
              eligibleAccessPackage: payload.response!,
              subjectObjectId
            },
            payload,
            isOnBehalfRequest: parameters?.isOnBehalfRequest
          }
        };
      }),
      catchError(errorHandler<IEntitlementAction>(EntitlementActions.getEligibleAccessPackageDetailFailed, action))
    );
  });
};
registry.addEpic('getEligibleAccessPackageDetail', getEligibleAccessPackageEpic);

export const getEligibleAccessPackageDetail = (state: IEntitlementState, action: IGetEligibleAccessPackageAction): Readonly<IEntitlementState> => {
  if (action.payload === undefined || !action.payload.entityId) {
    return state;
  }

  const newState = {
    ...state,
    policies: {
      ...state.policies,
      isLoading: true
    }
  };

  if (action.payload.parameters?.isOnBehalfRequest || action.payload.isPollingForStatus) {
    return newState;
  }

  const accessPackageId = action.payload.entityId;
  const targetObjectId = action.payload.parameters?.subject?.objectId;

  const policyTargetKey = getPolicyTargetKey(accessPackageId, targetObjectId);
  const retryCount = state.policyTargetLoadingMap.get(policyTargetKey)?.retryCount ?? 0;
  if (retryCount >= 10) {
    telemetry.warn(TelemetryEvent.High_Retry_Count, {
      message: `Retry count(${retryCount}) exceeded for ${policyTargetKey}`
    });
  }
  updatePolicyTargetLoadingMap(state, accessPackageId, targetObjectId, {
    loadingError: undefined,
    isUserError: undefined,
    loadingState: LoadingState.loading,
    isLoading: true,
    retryCount: retryCount + 1
  });

  return newState;
};
registry.add(EntitlementActions.getPolicies, getEligibleAccessPackageDetail);

export const getEligibleAccessPackageSucceeded = (
  state: IEntitlementState,
  action: IGetEligibleAccessPackageSuccessAction
): Readonly<IEntitlementState> => {
  if (action.payload === undefined || action.payload.result === null) {
    return state;
  }
  const { result, isOnBehalfRequest } = action.payload;

  const accessPackageId = result.accessPackageId;
  const policies = result.eligibleAccessPackage?.grantRequestRequirements ?? [];
  const targetObjectId = result?.subjectObjectId;

  // Set OBO status if not defined yet
  if (!state.oboEnabledPackages.get(accessPackageId)) {
    state.oboEnabledPackages.set(accessPackageId, policiesContainsOBODirectReport(policies));
  }

  if (isOnBehalfRequest) {
    return {
      ...state,
      eligibleAccessPackageDetail: result.eligibleAccessPackage?? {} as IEligibleAccessPackageDetail,
      policies: {
        ...state.policies,
        isLoading: false
      }
    };
  }

  const vcEnabled = state.features.isEnabled.enableVerifiableCredential;

  const asGrantPolicy = policies
    .map((policyRequirements: IPolicyRequirements) => {
      if (!vcEnabled && !!policyRequirements.verifiableCredentialRequirementStatus) {
        return null;
      }
      // map question types to the correct types.
      const questions = policyRequirements.questions.map((question) => {
        switch (question['@odata.type']) {
          case MultipleChoiceQuestionType:
            question.$type = QuestionType.MultipleChoiceQuestion;
            break;
          case TextInputQuestionType:
            question.$type = QuestionType.TextInputQuestion;
            break;
        }
        return question;
      });

      return {
        id: policyRequirements.policyId,
        displayName: policyRequirements.policyDisplayName,
        description: policyRequirements.policyDescription,
        expirationDate: policyRequirements.schedule.expiration?.endDateTime ?? '',
        isCustomAssignmentScheduleAllowed: policyRequirements.isCustomAssignmentScheduleAllowed,
        isRequestorJustificationRequired: policyRequirements.isRequestorJustificationRequired,
        questions,
        verifiableCredentialRequirementStatus: policyRequirements.verifiableCredentialRequirementStatus,
        existingAnswers: policyRequirements.existingAnswers
      };
    })
    .filter((grant) => {
      return !!grant;
    }) as IGrantPolicy[];

  const mappedPolicies = asGrantPolicy.map((policy: IGrantPolicy) => {
    return [policy.id, policy];
  });

  const assignmentSet = asGrantPolicy.map((policy: IGrantPolicy) => {
    return policy.id;
  });

  const policyAssignmentKey = getPolicyTargetKey(accessPackageId, result.subjectObjectId);

  const newAssignmentMap = new Map<string, string[]>([
    ...Array.from(state.policyAssignments),
    [policyAssignmentKey, assignmentSet]
  ]);

  const newEntitiesById = new Map<string, IGrantPolicy>([
    ...(Array.from(state.policies.entitiesById) as any),
    ...mappedPolicies
  ]);

  updatePolicyTargetLoadingMap(state, accessPackageId, targetObjectId, {
    loadingState: LoadingState.loaded,
    isLoading: false
  });

  return {
    ...state,
    eligibleAccessPackageDetail: result.eligibleAccessPackage?? {} as IEligibleAccessPackageDetail,
    policies: {
      ...state.policies,
      isLoading: false,
      entitiesById: newEntitiesById
    },
    policyAssignments: newAssignmentMap,
    errorHasOccurred: false
  };
};
registry.add(EntitlementActions.getEligibleAccessPackageDetailSucceeded, getEligibleAccessPackageSucceeded);

export type MetaEligibleAccessPackageFailed = { parent?: IGetEligibleAccessPackageAction } | undefined;
export const getEligibleAccessPackageFailed = (
  state: IEntitlementState,
  action: IGetEligibleAccessPackageFailAction
): Readonly<IEntitlementState> => {
  if (action.payload === undefined || action.meta?.parent?.payload?.entityId === undefined) {
    return state;
  }
  const payload = action.payload;
  let notifications: INotification[] = [];
  let validationErrors: IValidationError[] = [];

  const accessPackageId = action.meta?.parent?.payload?.entityId;
  const targetObjectId = action.meta?.parent?.payload?.parameters?.subject?.objectId;

  if (action.meta?.parent?.payload?.parameters?.isOnBehalfRequest === true) {
    const { oboEnabledPackages } = state;
    oboEnabledPackages.set(action.meta.parent.payload.entityId, false);
    return {
      ...state,
      eligibleAccessPackageDetail:{} as IEligibleAccessPackageDetail,
      policies: {
        ...state.policies,
        isLoading: false
      }
    };
  }

  const error = payload?.response?.error;
  const loadingState: ILoading = {
    isLoading: false,
    loadingError: error,
    loadingState: LoadingState.error
  };
  if (error) {
    try {
      validationErrors = JSON.parse(error.message) as IValidationError[];
      // Set isUserError if the user already has access to the package or is in process of getting access
      loadingState.isUserError = userAlreadyHasAccess(validationErrors);
    } catch (e) {
      validationErrors.push({
        Code: error.code || error.Code,
        Detail: error.message || error.Message
      });
    }
    notifications = getNotifications(validationErrors, payload);
  } else {
    const toastKey = LocaleKeys.generalErrorMessage;
    const toastOptions: TranslationOptions = {};
    notifications = [
      {
        id: v4(),
        createdDateTime: moment(),
        localizableMessage: {
          key: toastKey,
          options: toastOptions
        },
        severity: NotificationSeverity.error,
        type: NotificationType.card
      },
      {
        id: v4(),
        createdDateTime: moment(),
        localizableMessage: {
          key: toastKey,
          options: toastOptions
        },
        severity: NotificationSeverity.error,
        type: NotificationType.toast
      }
    ];
  }

  const isUnhandledError = validationErrors.length === 0;
  if (isUnhandledError) {
    telemetry.error(TelemetryEvent.Unhandled_Get_EligblePackage_Error, { accessPackageId, targetObjectId });
  }

  updatePolicyTargetLoadingMap(state, accessPackageId, targetObjectId, loadingState);
  return {
    ...state,
    notifications: notificationsMerge(notifications, state.notifications, state.notificationsLimit),
    eligibleAccessPackageDetail:{} as IEligibleAccessPackageDetail,
    policies: {
      ...state.policies,
      isLoading: false
    },
    validationErrors,
    // set generalerror if unhandled error
    errorHasOccurred: isUnhandledError
  };
};
registry.add(EntitlementActions.getEligibleAccessPackageDetailFailed, getEligibleAccessPackageFailed);

function getNotifications(validationErrors: IValidationError[], payload: Readonly<any>): INotification[] {
  const notifiedError = validationErrors?.filter(
    (error) => !topLevelValidationErrors.includes(ValidationErrorCode[error.Code])
  );

  if (isEmptyOrUndefined(notifiedError) || notifiedError.length === 0) {
    return [];
  }
  const errorMessage =
    notifiedError && notifiedError.length > 0 ? notifiedError[0].Detail : LocaleKeys.generalErrorMessage;

  const correlationId = payload?.request?.headers['x-ms-client-request-id'];

  const toastOptions: TranslationOptions = {
    error: errorMessage,
    correlationId
  };

  const notification = {
    localizableTitle: {
      key: LocaleKeys.somethingWentWrong,
      options: toastOptions
    },
    localizableMessage: {
      key: LocaleKeys.errorTemplate,
      options: toastOptions
    },
    createdDateTime: moment(),
    severity: NotificationSeverity.error
  };

  return [
    {
      ...notification,
      id: v4(),
      type: NotificationType.card
    },
    {
      ...notification,
      id: v4(),
      type: NotificationType.toast
    }
  ];
}

function updatePolicyTargetLoadingMap(
  state: IEntitlementState,
  accessPackageId: string,
  targetObjectId: string | undefined,
  value: ILoading
): void {
  const policyTargetKey = getPolicyTargetKey(accessPackageId, targetObjectId);
  const currentValue = state.policyTargetLoadingMap.get(policyTargetKey);
  state.policyTargetLoadingMap.set(policyTargetKey, {
    ...currentValue,
    ...value
  });
}
