import { TranslationFunction } from 'i18next';
import * as React from 'react';
import { IPageMetaData } from '../../../models/IPageMetaData';
import { asLocalizedText } from '../../../shared/asLocalizedText';
import { findGuid } from '../../../shared/isGuid';
import { LocaleKeys } from '../../../shared/LocaleKeys';

export interface ISubtitleProps {
  isSearching: boolean;
  isFiltering: boolean;
  pageMetaData: IPageMetaData;
  searchTerm: string;
  entityName: string;
  t: TranslationFunction;
}

export class Subtitle extends React.Component<ISubtitleProps, {}> {
  public render(): JSX.Element {
    const {
      isSearching,
      isFiltering,
      pageMetaData,
      searchTerm,
      entityName,
      t
    } = this.props;
    const filteredCount = pageMetaData.filteredEntityCount
      ? pageMetaData.filteredEntityCount
      : 0;
    const allCount = pageMetaData.allEntityCount
      ? pageMetaData.allEntityCount
      : 0;

    if (findGuid(location.hash) !== '') {
      return <div>&#8203;</div>;
    } else if (isSearching) {
      const localizedMessage = {
        key: LocaleKeys.searchingSubtitle,
        options: {
          allCount: allCount,
          filteredCount: filteredCount,
          searchTerm: searchTerm,
          entityName: t(entityName, { count: allCount }),
          count: filteredCount
        }
      };

      return <span>{asLocalizedText(localizedMessage, t)}</span>;
    } else if (isFiltering) {
      const localizedMessage = {
        key: LocaleKeys.filteringSubtitle,
        options: {
          allCount: allCount,
          entityName: t(entityName, { count: allCount }),
          filteredCount: filteredCount,
          count: filteredCount
        }
      };

      return <span>{asLocalizedText(localizedMessage, t)}</span>;
    } else {
      const localizedMessage = {
        key: LocaleKeys.entityNameWithCount,
        options: {
          count: allCount,
          entityName: t(entityName, { count: allCount })
        }
      };

      return <span>{asLocalizedText(localizedMessage, t)}</span>;
    }
  }
}
