import React from 'react';

import { TranslationFunctionKeys } from '../../../models/ITranslation';
import { LocaleKeys } from '../../../shared';

export const createOrderedList = (steps: JSX.Element[]): JSX.Element => {
  return (
    <ol>
      {steps.map((step, index) => (
        <li key={index}>{step}</li>
      ))}
    </ol>
  );
};

export const installationStep = (t: TranslationFunctionKeys): JSX.Element => {
  return <span>{t(LocaleKeys.ramConnectionSshInstallationStep)}</span>;
};

export const textFieldStyles = {
  fieldGroup: [{ height: 40 }],
  field: { textOverflow: 'ellipsis' }
};
