import { IAjaxRequest } from '@microsoft/portal-app/lib/auth/withAuth';
import {
  getResponseValue,
  IODataValueResponse
} from '@microsoft/portal-app/lib/odata-utils';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';

import { AccessReviewsPivot } from '../../../components/AccessReviews/AccessReviewsList';
import { AccessReviewPartnerGuid } from '../../../models/AccessReviews/AccessReviewType';
import { IAccessReviewDecision } from '../../../models/AccessReviews/IAccessReviewDecision';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { EntityType } from '../../../models/EntityType';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import {
  IEntitlementState,
  IRootEntitlementsState
} from '../../../models/IEntitlementState';
import { IEntityResult } from '../../../models/IEntityResult';
import { LoadMode } from '../../../models/IPageData';
import { getRequestWithAudience } from '../../../shared/AttachAudience';
import { getAccessReviewsApiUrl } from '../../../shared/getAccessReviewsApiUrl';
import { registry } from '../myAccessRegistry';

export const searchAccessReviewsOnServerEpic: Epic<
  IEntitlementAction<AnyPayload>,
  IRootEntitlementsState
> = (
  action$: ActionsObservable<IEntitlementAction<string>>,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
    return action$
      .ofType(EntitlementActions.searchAccessReviewsOnServer)
      .switchMap((action: IEntitlementAction<string>) => {
        let ajaxRequest: IAjaxRequest;
        const searchTerm = action.payload && action.payload.toLowerCase();

        let accessReviews = _store.getState().app.accessReviews;
        let partnerId = AccessReviewPartnerGuid.Governance;
        let entity = EntityType.accessReviews;
        let isByod = false;
        const pivotType = _store.getState().app.reviewListPivot;

        // By default, search context should be on GroupsApps reviews
        // If the review pivot has changed to other types, update here
        if (pivotType) {
          switch (pivotType) {
            case AccessReviewsPivot.AccessPackages:
              accessReviews = _store.getState().app.accessPackageReviews;
              partnerId = AccessReviewPartnerGuid.AccessPackage;
              entity = EntityType.accessPackageReviews;
              isByod = false;
              break;
            case AccessReviewsPivot.AadRoles:
              accessReviews = _store.getState().app.aadRolesReviews;
              partnerId = AccessReviewPartnerGuid.AadRoles;
              entity = EntityType.aadRoleReviews;
              isByod = false;
              break;
            case AccessReviewsPivot.Rbac:
              accessReviews = _store.getState().app.rbacReviews;
              partnerId = AccessReviewPartnerGuid.Rbac;
              entity = EntityType.rbacReviews;
              isByod = false;
              break;
            case AccessReviewsPivot.Byod:
              accessReviews = _store.getState().app.accessReviews;
              partnerId = AccessReviewPartnerGuid.Governance;
              entity = EntityType.accessReviews;
              isByod = true;
              break;
            case AccessReviewsPivot.UserAccess:
              accessReviews = _store.getState().app.userAccessReviews;
              partnerId = AccessReviewPartnerGuid.UserAccess;
              entity = EntityType.userAccessReviews;
              isByod = false;
              break;
            default:
              break;
          }
        }

        const nextLink = accessReviews.filtered.nextLink!;
        const orderby =
          accessReviews.sortedColumn && accessReviews.sortedColumn.fieldName;
        const isAscending =
          accessReviews.sortedColumn &&
          !accessReviews.sortedColumn.isSortedDescending;

        ajaxRequest = getRequestWithAudience(
          nextLink || getAccessReviewsApiUrl(partnerId, isByod, searchTerm, orderby, isAscending),
          entity
        );

        return ajax(ajaxRequest)
          .map((payload: IODataValueResponse<readonly IAccessReviewDecision[]>) => {
            return {
              type: EntitlementActions.searchEntitiesOnServerSucceeded,
              payload: {
                entityType: entity,
                entities: getResponseValue(payload),
                searchTerm,
                count: payload.response!['@odata.count'],
                nextLink: payload.response!['@odata.nextLink']
              }
            } as IEntitlementAction<IEntityResult<IAccessReviewDecision>>;
          })
          .catch(() =>
            Observable.of({
              type: EntitlementActions.searchEntitiesOnServerFailed,
              payload: entity
            })
          );
      });
  };
registry.addEpic(
  'searchAccessReviewsOnServerEpic',
  searchAccessReviewsOnServerEpic
);

export const searchAccessReviewsOnServer = (
  state: IEntitlementState,
  action: IEntitlementAction<string>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }
  const searchTerm = action.payload;
  const pivotType = state.reviewListPivot;

  switch (pivotType) {

    case AccessReviewsPivot.AccessPackages:
      return {
        ...state,
        searchTerm,
        accessPackageReviews: {
          ...state.accessPackageReviews,
          filtered: {
            ...state.accessPackageReviews.filtered,
            entities: [],
            count: undefined,
            nextLink: undefined
          },
          filterContext: undefined,
          isLoading: true,
          loadMode: LoadMode.LoadMore
        }
      };
    case AccessReviewsPivot.AadRoles:
      return {
        ...state,
        searchTerm,
        aadRolesReviews: {
          ...state.aadRolesReviews,
          filtered: {
            ...state.aadRolesReviews.filtered,
            entities: [],
            count: undefined,
            nextLink: undefined
          },
          filterContext: undefined,
          isLoading: true,
          loadMode: LoadMode.LoadMore
        }
      };
    case AccessReviewsPivot.Rbac:
      return {
        ...state,
        searchTerm,
        rbacReviews: {
          ...state.rbacReviews,
          filtered: {
            ...state.rbacReviews.filtered,
            entities: [],
            count: undefined,
            nextLink: undefined
          },
          filterContext: undefined,
          isLoading: true,
          loadMode: LoadMode.LoadMore
        }
      };
    case AccessReviewsPivot.Byod:
      return {
        ...state,
        searchTerm,
        byodReviews: {
          ...state.byodReviews,
          filtered: {
            ...state.byodReviews.filtered,
            entities: [],
            count: undefined,
            nextLink: undefined
          },
          filterContext: undefined,
          isLoading: true,
          loadMode: LoadMode.LoadMore
        }
      };
    case AccessReviewsPivot.UserAccess:
        return {
          ...state,
          searchTerm,
          userAccessReviews: {
            ...state.userAccessReviews,
            filtered: {
              ...state.userAccessReviews.filtered,
              entities: [],
              count: undefined,
              nextLink: undefined
            },
            filterContext: undefined,
            isLoading: true,
            loadMode: LoadMode.LoadMore
          }
        };
    default: // GroupsApps
      return {
        ...state,
        searchTerm,
        accessReviews: {
          ...state.accessReviews,
          filtered: {
            ...state.accessReviews.filtered,
            entities: [],
            count: undefined,
            nextLink: undefined
          },
          filterContext: undefined,
          isLoading: true,
          loadMode: LoadMode.LoadMore
        }
      };

  }

};
registry.add(
  EntitlementActions.searchAccessReviewsOnServer,
  searchAccessReviewsOnServer
);
