import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';

import { notificationsMerge } from '@microsoft/portal-app/lib/Notifications/helpers/notificationsMerge';
import {
  INotification,
  NotificationSeverity,
  NotificationType
} from '@microsoft/portal-app/lib/Notifications/models/INotification';
import { IODataValueResponse } from '@microsoft/portal-app/lib/odata-utils';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { TranslationOptions } from 'i18next';
import * as moment from 'moment';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';
import { v4 } from 'uuid';

import { AccessReviewsPivot } from '../../../components/AccessReviews/AccessReviewsList';
import { IAccessReview } from '../../../models/AccessReviews/IAccessReview';
import { IDecisionsSummary } from '../../../models/AccessReviews/IDecisionsSummary';
import { IEntity } from '../../../models/ELM/IEntity';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { EntityType } from '../../../models/EntityType';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import {
  IEntitlementState,
  IRootEntitlementsState
} from '../../../models/IEntitlementState';
import { IEntityFilterable } from '../../../models/IEntityFilterable';
import { getRequestWithAudience } from '../../../shared/AttachAudience';
import { getEntityApiUrl } from '../../../shared/getApiUrl';
import { getResponseValue } from '../../../shared/getResponseValue';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { registry } from '../myAccessRegistry';

export const getDecisionsSummaryEpic: Epic<
  IEntitlementAction<AnyPayload>,
  IRootEntitlementsState
> = (
  action$: ActionsObservable<
    IEntitlementAction<{
      entityId: string;
      entityType: EntityType;
    }>
  >,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
    return action$
      .ofType(EntitlementActions.getDecisionsSummary)
      .mergeMap((action: IEntitlementAction<IEntityFilterable>) => {
        const { entityId } = action.payload!;
        const ajaxRequest = getRequestWithAudience(
          getEntityApiUrl(EntityType.decisionsSummary, entityId!),
          EntityType.decisionsSummary as EntityType
        );
        return (
          ajax(ajaxRequest)
            .map((payload: IODataValueResponse<readonly IEntity[]>) => {
              return {
                type: EntitlementActions.getDecisionsSummarySucceeded,
                payload: {
                  entities: getResponseValue(payload),
                  reviewId: entityId!,
                  payload
                }
              };
            })
            // tslint:disable-next-line:no-any
            .catch((error: any) =>
              Observable.of({
                type: EntitlementActions.getDecisionsSummaryFailed,
                payload: {
                  entityType: action.payload!.entityType,
                  errorCode: error && error.status
                }
              })
            )
        );
      });
  };
registry.addEpic('getDecisionsSummaryEpic', getDecisionsSummaryEpic);

export const getDecisionsSummarySucceeded = (
  state: IEntitlementState,
  action: IEntitlementAction<{
    entities: readonly IDecisionsSummary[];
    reviewId: string;
  }>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  const { entities, reviewId } = action.payload;
  // pivotType should be null when on the Decisions List page
  // Compare URL with reviewId to confirm if decisions list is active
  // If not, use state.reviewListPivot
  const pivotType = reviewId === location.href.split('/').pop() ? null : state.reviewListPivot;

  if (!entities.length) {
    return {
      ...state,
      isEntityDetailLoading: false
    };
  }

  const decisionsSummary = entities[0];
  if (!decisionsSummary) {
    return state;
  }

  let review;
  // By default, use the GroupsApps entities.
  // However, if pivotType is set, use appropriate type
  switch (pivotType) {
    case AccessReviewsPivot.AccessPackages:
      review = state.accessPackageReviews.entitiesById.get(reviewId);
      break;
    case AccessReviewsPivot.AadRoles:
      review = state.aadRolesReviews.entitiesById.get(reviewId);
      break;
    case AccessReviewsPivot.Rbac:
      review = state.rbacReviews.entitiesById.get(reviewId);
      break;
    case AccessReviewsPivot.Byod:
      review = state.byodReviews.entitiesById.get(reviewId);
      break;
    case AccessReviewsPivot.UserAccess:
      review = state.userAccessReviews.entitiesById.get(reviewId);
      break;
    default:
      review = state.accessReviews.entitiesById.get(reviewId);
      break;
  }

  // If the review was not found, return
  if (!review) {
    return {
      ...state
    };
  }

  review.decisionsSummary = decisionsSummary;

  let allReviews;
  switch (pivotType) {
    case AccessReviewsPivot.AccessPackages:
      allReviews = state.accessPackageReviews.entitiesById as Map<
        string,
        IAccessReview
      >;
      break;
    case AccessReviewsPivot.AadRoles:
      allReviews = state.aadRolesReviews.entitiesById as Map<
        string,
        IAccessReview
      >;
      break;
    case AccessReviewsPivot.Rbac:
      allReviews = state.rbacReviews.entitiesById as Map<
        string,
        IAccessReview
      >;
      break;
    case AccessReviewsPivot.Byod:
      allReviews = state.byodReviews.entitiesById as Map<
        string,
        IAccessReview
      >;
      break;
    case AccessReviewsPivot.UserAccess:
      allReviews = state.userAccessReviews.entitiesById as Map<
        string,
        IAccessReview
      >;
      break;
    default:
      allReviews = state.accessReviews.entitiesById as Map<
        string,
        IAccessReview
      >;
      break;
  }

  allReviews.set(reviewId, review);

  switch (pivotType) {
    case AccessReviewsPivot.AccessPackages:
      return {
        ...state,
        accessPackageReviews: {
          ...state.accessPackageReviews,
          entitiesById: allReviews as ReadonlyMap<string, IAccessReview>
        },
      };
    case AccessReviewsPivot.AadRoles:
      return {
        ...state,
        aadRolesReviews: {
          ...state.aadRolesReviews,
          entitiesById: allReviews as ReadonlyMap<string, IAccessReview>
        },
      };
    case AccessReviewsPivot.Rbac:
      return {
        ...state,
        rbacReviews: {
          ...state.rbacReviews,
          entitiesById: allReviews as ReadonlyMap<string, IAccessReview>
        },
      };
    case AccessReviewsPivot.Byod:
      return {
        ...state,
        byodReviews: {
          ...state.byodReviews,
          entitiesById: allReviews as ReadonlyMap<string, IAccessReview>
        },
      };
    case AccessReviewsPivot.UserAccess:
      return {
        ...state,
        userAccessReviews: {
          ...state.userAccessReviews,
          entitiesById: allReviews as ReadonlyMap<string, IAccessReview>
        },
      };
    default:
      return {
        ...state,
        accessReviews: {
          ...state.accessReviews,
          entitiesById: allReviews as ReadonlyMap<string, IAccessReview>
        },
      };
  }
};
registry.add(EntitlementActions.getDecisionsSummarySucceeded, getDecisionsSummarySucceeded);

export const getDecisionsSummaryFailed = (
  state: IEntitlementState,
  // tslint:disable-next-line:no-any
  action: IEntitlementAction<Readonly<any>>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  const errorCode = action.payload.errorCode;

  const toastKey = LocaleKeys.generalErrorMessage;
  const toastOptions: TranslationOptions = {};

  const notifications: INotification[] = [
    {
      id: v4(),
      localizableMessage: {
        key: toastKey,
        options: toastOptions
      },
      createdDateTime: moment(),
      severity: NotificationSeverity.error,
      type: NotificationType.card
    }
  ];

  return {
    ...state,
    notifications: notificationsMerge(
      notifications,
      state.notifications,
      state.notificationsLimit
    ),
    isEntityDetailLoading: false,
    errorHasOccurred: true,
    isTenantWhitelisted: errorCode !== 403
  };
};
registry.add(EntitlementActions.getDecisionsSummaryFailed, getDecisionsSummaryFailed);
