import { ApprovalSearchFilter } from '../models/RequestApprovals/ApprovalSearchFilter';
import { isEmptyOrUndefined } from './isEmptyOrUndefined';
import { settingsManager } from './settings';
import { getTenantInfo } from './urlHelper';

export const getRaeBaseUrl = (): string => {
  return settingsManager.get('accessReviewsApiUrl')!;
};

export const getPostDecisionApiUrl = (requestId: string): string => {
  const baseUrl = getRaeBaseUrl();
  const partnerId = settingsManager.get('raeElmPartnerId')!;
  const tenantOption = getTenantInfo(true);
  return `${baseUrl}approvalWorkflowProviders/${partnerId}/requests/${requestId}/recordDecisions${tenantOption}`;
};

export const getPendingRaeRequestsCountApiUrl = (): string => {
  const baseUrl = getRaeBaseUrl();
  const partnerId = settingsManager.get('raeElmPartnerId')!;
  const tenantOption = getTenantInfo(false);
  const options = `?$count=true&$select=id${tenantOption}`;
  return `${baseUrl}approvalWorkflowProviders/${partnerId}/requestsAwaitingMyDecision${options}`;
};

export const getRaeRequestsApiUrl = (
  status: string,
  orderBy?: string,
  isAscending?: boolean,
  searchTerm?: string,
  selectedFilterKey?: string
): string => {
  const baseUrl = getRaeBaseUrl();
  const partnerId = settingsManager.get('raeElmPartnerId')!;
  const tenantOption = getTenantInfo(false);

  let filter = '';
  let expand = '';
  let select =
    'id,createdBy,status,description,createdDateTime,endDateTime,policy,' +
    'catalogName,resourceDisplayName,homeOrganization,parentReviewId,stage,' +
    'entitlementDurationEndDate,entitlementDurationStartDate,customData';
  if (isEmptyOrUndefined(orderBy)) {
    if (status === 'InProgress') {
      orderBy = 'endDateTime';
      isAscending = true;
    } else if (status === 'Completed') {
      orderBy = 'createdDateTime';
      isAscending = false;
    }
  }

  if (status === 'Completed') {
    // Add expand property for completed approvals
    filter = '(status eq %27Completed%27)';
    expand = '$expand=decisions';
  } else {
    // only for in progress requests, do select on settings
    select += ',settings';
  }

  if (!isEmptyOrUndefined(searchTerm)) {
    if (!isEmptyOrUndefined(filter)) {
      filter += ' and ';
    }

    switch (selectedFilterKey) {
      case ApprovalSearchFilter.AccessPackage:
        filter += ` contains(resourceDisplayName, '${searchTerm}')`;
        break;
      case ApprovalSearchFilter.All:
        filter +=
          ` contains(ownerDisplayName, '${searchTerm}')` +
          ` or contains(ownerUserPrincipalName, '${searchTerm}')` +
          ` or contains(resourceDisplayName, '${searchTerm}')`;
        break;
      case ApprovalSearchFilter.Name:
      default:
        filter +=
          ` contains(ownerDisplayName, '${searchTerm}')` +
          ` or contains(ownerUserPrincipalName, '${searchTerm}')`;
        break;
    }
  }

  let options = `?$count=true${tenantOption}&$top=20&$skip=0&$select=${select}&$orderby=${orderBy} ${
    isAscending ? 'asc' : 'desc'
  }`;

  if (filter !== '') {
    options = options + `&$filter=${filter}`;
  }

  if (!isEmptyOrUndefined(expand)) {
    options = options + `&${expand}`;
  }

  return `${baseUrl}approvalWorkflowProviders/${partnerId}/requestsAwaitingMyDecision${options}`;
};

export const getPendingRaeRequestApiUrl = (id: string): string => {
  const baseUrl = getRaeBaseUrl();
  const partnerId = settingsManager.get('raeElmPartnerId')!;
  const tenantOption = getTenantInfo(false);

  const expand = '$expand=decisions';
  const select =
    '$select=id,createdBy,status,description,createdDateTime,endDateTime,policy,' +
    'catalogName,resourceDisplayName,homeOrganization,parentReviewId,stage,settings,' +
    'entitlementDurationEndDate,entitlementDurationStartDate,customData';
  const filter = `$filter=id eq '${id}'`;

  const options = `?${filter}&${select}&${expand}${tenantOption}`;

  return `${baseUrl}approvalWorkflowProviders/${partnerId}/requestsAwaitingMyDecision${options}`;
};
