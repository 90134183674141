import { IEntitlementAction } from './';

export interface ITelemetryEnvironment {
  endpoint: string;
  name: string;
}

export enum TelemetryEnvironment {
  WW = 'WW',
  EU = 'EU'
}

export interface ITelemetryPayload {
  eventName: string;
  metadata: {};
}

export type ITelemetryAction = IEntitlementAction<ITelemetryPayload>;
