import { LocaleKeys } from '../../shared/LocaleKeys';

export interface IErrorDialog {
  title?: LocaleKeys;
  message?: LocaleKeys;
  image?: string;
  link?: string;
  onContinue?: VoidFunction;
  code?: string;
}

export enum PimRoleActivationErrorCode {
  RoleAssignmentExists = 'RoleAssignmentExists',
  RoleAssignmentRequestPolicyValidationFailed = 'RoleAssignmentRequestPolicyValidationFailed',
  RoleAssignmentDoesNotExist = 'RoleAssignmentDoesNotExist',
  ActiveDurationTooShort = 'ActiveDurationTooShort'
}
