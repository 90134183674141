import { IContextualMenuItem } from '@fluentui/react';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState } from '../../../models/IEntitlementState';
import { registry } from '../myAccessRegistry';

export const updateFilterItems = (
  state: IEntitlementState,
  action: IEntitlementAction<IContextualMenuItem[]>
): IEntitlementState => {
  if (action.payload === undefined) {
    return state;
  }
  return {
    ...state,
    filterItems: action.payload
  };
};
registry.add(EntitlementActions.updateFilterItems, updateFilterItems);
