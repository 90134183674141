import { CheckboxVisibility, css, SelectionMode, Announced } from '@fluentui/react';
import { withResponsiveMode } from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import * as React from 'react';

import { ErrorBanner } from '@microsoft/portal-app/lib/Banners/ErrorBanner';

import { ConnectedAddGrantRequest, EntitlementDetailsPanel } from '../';
import { EntitlementActions } from '../../../models';
import { IGrant } from '../../../models/ELM/IGrant';
import { RequestType } from '../../../models/ELM/RequestType';
import { IListColumn } from '../../../models/IListColumn';
import { history } from '../../../redux/configureStore';
import { getEntitlementUrl } from '../../../shared/getEntitlementUrl';
import {
  GetGrantFromEntitlement,
  GetGrantRequestFromGrant,
} from '../../../shared/GetGrantRequestFromEntity';
import { findGuid } from '../../../shared/isGuid';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { Routes } from '../../../shared/Routes';
import { getNewColumnsOnColumnClicked } from '../../../shared/sortingHelper';
import { getSpinner } from '../../../shared/spinner';
import { ConnectedGrantFilter } from '../../ELM/GrantFilter';
import { ConfirmDialog } from '../../Shared/ConfirmDialog/ConfirmDialog';
import { CopyLink } from '../../Shared/CopyLink/CopyLink';
import { InfinityList } from '../../Shared/InfinityList/InfinityList';
import { getActiveGrantListColumns } from './ActiveGrantList.columns';
import {
  IActiveGrantListProps,
  IActiveGrantListState,
} from './ActiveGrantList.types';
import { QosProvider } from '@iamexperiences/ecos-telemetry';

const myAccessListStyles = require('../../../css/myAccessList.scoped.scss');

@withResponsiveMode
export class ActiveGrantList extends React.Component<
  IActiveGrantListProps,
  IActiveGrantListState
> {
  constructor(nextProps: IActiveGrantListProps) {
    super(nextProps);

    this.state = {
      columns: getActiveGrantListColumns(
        nextProps.responsiveMode!,
        nextProps,
        this.onCtaButtonClicked,
        this.onColumnClick
      ),
      showEntitlementPanel: false,
    };
  }

  public componentDidMount(): void {
    this.loadInitial();
  }

  public componentDidUpdate(prevProps: IActiveGrantListProps): void {
    if (this.props.isSingleEntity !== prevProps.isSingleEntity) {
      this.loadInitial();
    }

    if (
      prevProps.isSearching !== this.props.isSearching ||
      prevProps.responsiveMode !== this.props.responsiveMode ||
      prevProps.searchTerm !== this.props.searchTerm
    ) {
      this.setState({
        ...this.state,
        columns: getActiveGrantListColumns(
          this.props.responsiveMode!,
          this.props,
          this.onCtaButtonClicked,
          this.onColumnClick
        ),
      });
    }
  }

  public componentWillUnmount(): void {
    this.props.dismissGrantFilter();
    this.props.dismissConfirmDialog();
    this.props.dismissCopyLink();
    this.props.dismissAddGrantRequest();
  }
  public render(): JSX.Element {
    const {
      isLoading,
      isRefreshing,
      isLoadingMore,
      isSearching,
      isFiltering,
      pageMetaData,
      errorHasOccurred,
      isTenantWhitelisted,
      showingAddGrantRequest,
      showingConfirmDialog,
      showingCopyLink,
      isSubmitting,
      t,
      responsiveMode,
    } = this.props;

    const spinnerLabel = t(LocaleKeys.loadingPage, {
      pageName: t(LocaleKeys.package, { context: 'plural' }),
    });

    if (isRefreshing) {
      return getSpinner(spinnerLabel);
    }

    if (errorHasOccurred) {
      if (!isTenantWhitelisted) {
        return <ErrorBanner text={t(LocaleKeys.tenantNotWhitelistedMessage)} />;
      }

      return (
        <ErrorBanner
          text={t(LocaleKeys.errorMessage)}
          onAction={this.props.getEntities}
          actionText={t(LocaleKeys.retry)}
        />
      );
    }

    const filteredCount = pageMetaData.filteredEntityCount
      ? pageMetaData.filteredEntityCount
      : 0;

    const showNoEntities =
      pageMetaData.allEntityCount === 0 && !isFiltering && !isSearching;

    const showNoFilteredResults =
      !isLoading && (isFiltering || isSearching) && filteredCount === 0;

    const showLoadMore =
      !pageMetaData.isAllEntitiesFullyCached &&
      !isLoading &&
      !isLoadingMore &&
      !pageMetaData.isFilteredEntitiesFullyCached;

    const { selectedGrant } = this.state;

    const selectedEntitlement =
      selectedGrant && selectedGrant.accessPackage
        ? {
            ...selectedGrant.accessPackage,
            accessPackageResourceRoleScopes:
              selectedGrant.accessPackageAssignmentResourceRoles || [],
          }
        : undefined;
    const entitlementUrl = getEntitlementUrl(
      (selectedEntitlement && selectedEntitlement.id!) || ''
    );
    const newGrant = GetGrantFromEntitlement(
      selectedEntitlement!,
      selectedGrant && selectedGrant.id,
      selectedGrant && selectedGrant.assignmentPolicyId
    );
    const grantRequest = GetGrantRequestFromGrant(
      newGrant!,
      RequestType.UserExtend
    );

    return (
      <QosProvider name='ActiveGrantsList'>
        <div
          className={css(
            myAccessListStyles.listPage,
            myAccessListStyles.paddingSmallTop,
            myAccessListStyles.listWithHoverRow
          )}
        >
          {!this.props.isLoading && <Announced message={`${filteredCount} items found`}/>}
          <InfinityList
            t={t}
            entityList={this.props.grantList as IGrant[]}
            ariaLabel={'List of access packages that I have.'}
            columns={this.state.columns}
            showLoadMore={showLoadMore}
            showSpinner={isLoadingMore}
            spinnerLabel={spinnerLabel}
            showNoEntities={showNoEntities}
            noEntitiesProps={{
              iconName: 'Unlock',
              noRowMessage: LocaleKeys.noValidGrantsMessage,
              showButton: true,
              buttonText: LocaleKeys.requestAccess,
              onButtonClick: () => history.push(Routes.accessPackages),
            }}
            showNoFilteredResults={showNoFilteredResults}
            onLoadMore={this.loadMore}
            onRowClicked={this.onRowClicked}
            selectionMode={SelectionMode.none}
            isExpanded={false}
            searchTerm={this.props.searchTerm}
            selectedFilterKey={this.props.selectedFilterKey}
            checkboxVisibility={CheckboxVisibility.hidden}
          />

          <ConnectedGrantFilter onDismiss={this.props.dismissGrantFilter} />

          {showingConfirmDialog ? (
            <ConfirmDialog
              t={t}
              title={LocaleKeys.removeAccess}
              subText={{
                key: LocaleKeys.removeAccessConfirmation,
                options: {
                  entitlementName:
                    selectedEntitlement && selectedEntitlement.displayName!,
                },
              }}
              // tslint:disable-next-line:jsx-no-lambda
              onYes={() => this.postGrantRequest(selectedGrant!)}
              onDismiss={this.props.dismissConfirmDialog}
              yesText={LocaleKeys.remove}
              noText={LocaleKeys.cancel}
              isSubmitting={isSubmitting}
              submittingText={{
                key: LocaleKeys.removingAccessMessage,
                options: {
                  entitlementName:
                    selectedEntitlement && selectedEntitlement.displayName!,
                },
              }}
              responsiveMode={responsiveMode}
            />
          ) : null}

          {showingCopyLink ? (
            <CopyLink
              onDismiss={this.props.dismissCopyLink}
              url={entitlementUrl}
              entityName={
                (selectedEntitlement && selectedEntitlement.displayName!) || ''
              }
              t={t}
              responsiveMode={responsiveMode}
            />
          ) : null}

          {showingAddGrantRequest && selectedEntitlement ? (
            <ConnectedAddGrantRequest
              title={LocaleKeys.extendAccess}
              grantRequest={grantRequest}
              entitlementName={selectedEntitlement.displayName}
              entitlementDescription={selectedEntitlement.description}
              onDismiss={() => {
                this.props.dismissAddGrantRequest();
                this.dismissEntitlementDetailsPanel();
              }}
            />
          ) : null}

          <EntitlementDetailsPanel
            isVisible={this.state.showEntitlementPanel && !showingAddGrantRequest}
            entitlement={selectedEntitlement}
            onDismiss={this.dismissEntitlementDetailsPanel}
            hideResourcesWithMessage={
              selectedGrant && selectedGrant.assignmentState === 'Delivering'
                ? t(LocaleKeys.deliveringPackages)
                : undefined
            }
            messageImageSrc="/imgs/resources_delivering.png"
            buttons={{
              leftText: t(LocaleKeys.share),
              rightText: t(LocaleKeys.removeAccess),
              leftIsPrimary: false,
              onLeftClick: this.props.showCopyLink,
              onRightClick: this.props.showConfirmDialog,
            }}
          />
        </div>
      </QosProvider>
    );
  }
  private dismissEntitlementDetailsPanel = (): void => {
    this.setState({
      ...this.state,
      showEntitlementPanel: false,
    });
  };

  private loadInitial = (): void => {
    const guid = findGuid(location.hash);
    if (guid !== '') {
      this.props.filterEntitiesOnServer({ id: guid });
      this.props.setSearchContext(null);
    } else {
      this.props.setSearchContext(EntitlementActions.searchValidGrantsOnServer);
      this.props.refreshEntities();
    }
  };

  private postGrantRequest = (grant: IGrant): void => {
    if (!grant) {
      return;
    }
    const grantRequest = GetGrantRequestFromGrant(
      GetGrantFromEntitlement(grant!.accessPackage, grant.id)!,
      RequestType.UserRemove
    );
    this.props.postGrantRequest({
      newGrantRequest: grantRequest!,
      entitlementName: grant.accessPackage.displayName!,
    });
  };

  private onRowClicked = (grant: IGrant): void => {
    this.setState({
      ...this.state,
      showEntitlementPanel: true,
      selectedGrant: grant,
    });
  };

  private onCtaButtonClicked = (grant: IGrant): void => {
    this.setState({
      ...this.state,
      showEntitlementPanel: false,
      selectedGrant: grant,
    });
  };

  private loadMore = (): void => {
    if (!this.props.isLoading) {
      if (this.props.isSearching) {
        this.searchEntitiesOnServer();
      } else if (this.props.isFiltering) {
        this.filterEntitiesOnServer();
      } else {
        this.getEntities();
      }
    }
  };

  private getEntities = (): void => {
    if (this.props.pageMetaData.isAllEntitiesFullyCached) {
      return;
    }
    this.props.getEntities();
  };

  private searchEntitiesOnServer = (): void => {
    if (
      this.props.pageMetaData.isAllEntitiesFullyCached! ||
      this.props.pageMetaData.isFilteredEntitiesFullyCached
    ) {
      return;
    }
    this.props.searchForMore();
  };

  private filterEntitiesOnServer = (): void => {
    if (
      this.props.pageMetaData.isAllEntitiesFullyCached! ||
      this.props.pageMetaData.isFilteredEntitiesFullyCached
    ) {
      return;
    }
    this.props.filterEntitiesOnServer(this.props.filter);
  };

  private onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IListColumn<IGrant>
  ): void => {
    ev.preventDefault();
    column.isSorted = true;
    column.isSortedDescending = !column.isSortedDescending;

    this.setState({
      columns: getNewColumnsOnColumnClicked(this.state.columns, column),
    });
    this.props.setSortedByColumn(column);
    if (!this.props.isSearching && !this.props.isFiltering) {
      this.props.sortEntities(column.key, !column.isSortedDescending!);
    } else {
      this.props.sortFilteredEntities(
        column.key,
        !column.isSortedDescending!,
        this.props.searchTerm!,
        this.props.filter
      );
    }
  };
}
