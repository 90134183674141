import { useSelector, useTranslation } from '../index';
import { EntitlementActions } from '../../models';
import { useDispatch } from 'react-redux';
import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { IOverviewData, OverviewType } from '../../models/IOverviewData';
import { LocaleKeys, Routes } from '../../shared';
import { useHistory } from 'react-router';
import { IOverviewActionCardProps } from '../../components/Shared/OverviewCard';
import { isNullOrUndefined } from 'util';

export const useOverviewPendingApprovalsManager = () => {
  const { pendingApprovalData } = useSelector((state) => ({
    pendingApprovalData: state.app.overviewPendingApprovalData
  }));

  const dispatch = useDispatch();
  const t = useTranslation();

  const refreshPendingApprovalOverview = createAction<boolean>(
    EntitlementActions.getOverviewApproval,
    dispatch
  );

  const history = useHistory();
  const approveOrDenyRequests = () => {
    let path = Routes.requestApprovals;
    history.push(path);
  };

  const buildOverviewPendingApprovalCardData = (pendingApprovalsOverviewData: IOverviewData): IOverviewActionCardProps => {
    let overviewPendingApprovalCardData: IOverviewActionCardProps = {
      id: OverviewType.pendingApprovals,
      title:t(LocaleKeys.packageRequest),
      cardDescription: t(LocaleKeys.awaitingRequest),
      cardImageUrl: '/imgs/overview_PackageRequest.png',
      primaryButtonLabel: t(LocaleKeys.approveOrDenyNow),
      isLoading: true,
      isSucceeded: false,
      primaryButtonAction: approveOrDenyRequests,
      refreshAction: refreshPendingApprovalOverview
    };

    if (!isNullOrUndefined(pendingApprovalsOverviewData)) {
      overviewPendingApprovalCardData.isLoading = pendingApprovalsOverviewData.isLoading;
      overviewPendingApprovalCardData.isSucceeded = pendingApprovalsOverviewData.isSucceeded;
      overviewPendingApprovalCardData.pendingActionEntityCount = pendingApprovalsOverviewData.pendingActionEntityCount;
      overviewPendingApprovalCardData.errorEventName = pendingApprovalsOverviewData.errorEventName;
      overviewPendingApprovalCardData.error = pendingApprovalsOverviewData.error;
      if (pendingApprovalsOverviewData.pendingActionEntityCount && pendingApprovalsOverviewData.pendingActionEntityCount > 1) {
        overviewPendingApprovalCardData.cardDescription = t(LocaleKeys.awaitingRequest, {context: 'plural'});
      }
    }

    return overviewPendingApprovalCardData;
  };

  const overviewPendingApprovalData: IOverviewActionCardProps = buildOverviewPendingApprovalCardData(pendingApprovalData);

  return { overviewPendingApprovalData, refreshPendingApprovalOverview, approveOrDenyRequests };
};