import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Routes } from '../../../shared';
import { ConnectedVirtualMachines } from '../VirtualMachines/ConnectedVirtualMachines';

export function CloudInfrastructurePage(): JSX.Element {
  return (
    <Switch>
      <Route exact path={Routes.ram} render={(routeProps) => <ConnectedVirtualMachines {...routeProps} />} />
      <Route
        exact
        path={`${Routes.ram}/:selectedVmId/:selectedRoleId`}
        render={(routeProps) => {
          return <ConnectedVirtualMachines {...routeProps} />;
        }}
      />
      <Redirect path={Routes.root} to={Routes.ram} />
    </Switch>
  );
}
