import { Announced, ColumnActionsMode, CommandBar, css, FontClassNames, Link, SelectionMode } from '@fluentui/react';
import { IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import { ResponsiveMode, withResponsiveMode } from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import { QosProvider } from '@iamexperiences/ecos-telemetry';
import { ErrorBanner } from '@microsoft/portal-app/lib/Banners/ErrorBanner';
import * as React from 'react';
import { TranslationFunction } from 'react-i18next';

import {
  ApprovalSearchFilter,
  DecisionType,
  DetailsType,
  EntitlementActions,
  EntityType,
  IGrantRequest,
  IListColumn
} from '../../../models';
import { IAnswer } from '../../../models/ELM/IAnswer';
import { ApprovalStatus } from '../../../models/RequestApprovals/IApproval';
import {
  FormatDateTime,
  getCurrentStageIndex,
  getDueDate,
  getSpinner,
  getStageCreationDate,
  isApproveOrDenyRequestStatus,
  isEmptyOrUndefined,
  LocaleKeys
} from '../../../shared';
import { ConnectedBulkActionDialog } from '../../ELM/BulkActionDialog';
import { ColumnValue } from '../../Shared/ColumnValue/ColumnValue';
import { InfinityList } from '../../Shared/InfinityList/InfinityList';
import { ApprovalDetails } from '../../Shared/PanelViews/ApprovalDetails';
import { ConnectedPackageDetails } from '../../Shared/PanelViews/PackageDetails/ConnectedPackageDetails';
import { RequestDetails } from '../../Shared/PanelViews/RequestDetails';
import { ConnectedPendingApprovalGrantRequestDetails } from '../PendingApprovalGrantRequestDetails';
import { IGrantRequestListState, IPendingApprovalGrantRequestListProps } from './PendingApprovalGrantRequestList.types';

const myAccessListStyles = require('../../../css/myAccessList.scoped.scss');
const myAccessStyles = require('../../../css/myAccess.scoped.scss');

@withResponsiveMode
export class PendingApprovalGrantRequestList extends React.Component<
  IPendingApprovalGrantRequestListProps,
  IGrantRequestListState
> {
  private _selectedItems: IGrantRequest[] | null = null;
  private _isApproverReasonRequired: boolean = false;

  constructor(nextProps: IPendingApprovalGrantRequestListProps) {
    super(nextProps);

    const columns = this._getPendingApprovalGrantRequestListColumns(
      this._getResponsiveMode(),
      this.props.t,
      this.props
    );

    this.state = {
      columns,
      commands: this._getPendingApprovalGrantRequestListCommands(),
      decision: DecisionType.DontKnow,
      showingRequestDetails: false,
      showingPackageDetails: false,
      showingApprovalHistory: false,
      currentAnswers: []
    };
  }

  public componentWillReceiveProps(nextProps: IPendingApprovalGrantRequestListProps): void {
    const selectedItems = this._getSelectedItems();
    if (selectedItems !== undefined) {
      const curItem = nextProps.entitiesById.get(selectedItems[0].id)!;
      if (isApproveOrDenyRequestStatus(curItem)) {
        this.setState({
          commands: this._getPendingApprovalGrantRequestListCommands()
        });
      }
    }
  }

  public componentDidMount(): void {
    this.props.setSearchContext(EntitlementActions.searchPendingApprovalGrantRequestsOnServer);
    this.props.refreshEntities();
  }

  public render(): JSX.Element {
    const {
      isLoading,
      isRefreshing,
      isLoadingMore,
      errorHasOccurred,
      errorCode,
      pageMetaData,
      pendingApprovalGrantRequestList,
      showingPendingApprovalGrantRequestDetails,
      showingBulkActionDialog,
      isSearching,
      searchTerm,
      t
    } = this.props;

    if (isRefreshing) {
      return getSpinner(
        t(LocaleKeys.loadingPage, {
          pageName: t(LocaleKeys.approval, { context: 'plural' })
        })
      );
    }

    if (errorHasOccurred) {
      if (errorCode !== 403) {
        return (
          <ErrorBanner
            text={t(LocaleKeys.errorMessage)}
            onAction={this.props.getEntities}
            actionText={t(LocaleKeys.retry)}
          />
        );
      }
    }

    const requests = pendingApprovalGrantRequestList as IGrantRequest[];

    const showNoEntities =
      errorHasOccurred && errorCode === 403 ? true : pageMetaData.allEntityCount === 0 && !isSearching;

    const showLoadMore =
      errorHasOccurred && errorCode === 403
        ? false
        : !pageMetaData.isAllEntitiesFullyCached &&
          !isLoading &&
          !isLoadingMore &&
          !pageMetaData.isFilteredEntitiesFullyCached;

    const filteredCount = pageMetaData.filteredEntityCount ? pageMetaData.filteredEntityCount : 0;

    const showNoFilteredResults = !isLoading && isSearching && filteredCount === 0;

    return (
      <QosProvider name="PendingApprovalList">
        <div
          className={css(
            myAccessListStyles.listPage,
            myAccessListStyles.paddingSmallTop,
            myAccessListStyles.listWithHoverRow
          )}
        >
          {!this.props.isLoading && isSearching && <Announced message={`${filteredCount} items found`} />}
          <CommandBar className={css(myAccessListStyles.commandBar)} items={this.state.commands} farItems={[]} />
          <InfinityList
            t={t}
            entityList={requests}
            entityType={EntityType.pendingApprovalGrantRequests}
            ariaLabel={'List of pending approvals.'}
            columns={this.state.columns}
            showLoadMore={showLoadMore}
            showSpinner={isLoadingMore}
            spinnerLabel={t(LocaleKeys.loadingPage, {
              pageName: t(LocaleKeys.approval, { context: 'plural' })
            })}
            showNoEntities={showNoEntities}
            noEntitiesProps={{
              iconName: 'Unlock',
              noRowMessage: LocaleKeys.noPendingApprovalsMessage,
              showButton: false
            }}
            showNoFilteredResults={showNoFilteredResults}
            onLoadMore={this._loadMore}
            onItemSelected={this._onItemSelected}
            selectionMode={SelectionMode.multiple}
            isExpanded={false}
            searchTerm={searchTerm}
          />
          {showingPendingApprovalGrantRequestDetails ? (
            <ConnectedPendingApprovalGrantRequestDetails
              pendingApprovalGrantRequestId={
                this._selectedItems && this._selectedItems.length > 0 ? this._selectedItems[0].id : undefined
              }
              answers={this.state.currentAnswers}
              decisionType={this.state.decision}
              showingPackageDetails={this.state.showingPackageDetails}
              onDismiss={this.props.dismissPendingApprovalGrantRequestDetails}
              showDetails={this._showDetails}
              isAnswerEditable={this._isAnswerEditable(this._getSelection())}
            />
          ) : null}

          {this.state.showingRequestDetails ? (
            <RequestDetails
              grantRequest={this._getSelection()}
              // tslint:disable-next-line:jsx-no-lambda
              onPrevious={this._setAnswers}
              onDismiss={this._dismissPendingApprovalGrantRequestDetails}
              isAnswerEditable={this._isAnswerEditable(this._getSelection())}
              answers={this.state.currentAnswers}
              t={t}
            />
          ) : null}

          {this.state.showingPackageDetails ? (
            <ConnectedPackageDetails
              grantRequest={this._getSelection()}
              // tslint:disable-next-line:jsx-no-lambda
              onPrevious={() => this._onPreviousDetails(DetailsType.Package)}
              onDismiss={this._dismissPendingApprovalGrantRequestDetails}
            />
          ) : null}

          {this.state.showingApprovalHistory ? (
            <ApprovalDetails
              grantRequest={this._getSelection()}
              // tslint:disable-next-line:jsx-no-lambda
              onPrevious={() => this._onPreviousDetails(DetailsType.Approval)}
              onDismiss={this._dismissPendingApprovalGrantRequestDetails}
              t={t}
              features={this.props.features}
            />
          ) : null}

          {showingBulkActionDialog ? (
            <ConnectedBulkActionDialog
              selectedGrantRequests={this._selectedItems!}
              decisionType={this.state.decision}
              onDismiss={this.props.dismissBulkActionDialog}
              isApproverReasonRequired={this._isApproverReasonRequired}
              responsiveMode={this.props.responsiveMode}
            />
          ) : null}
        </div>
      </QosProvider>
    );
  }

  private readonly _setAnswers = (answers?: IAnswer[]): void => {
    this.state.currentAnswers = answers;
    this._onPreviousDetails(DetailsType.Request);
  };

  private readonly _getSelection = (): IGrantRequest | undefined => {
    return this._selectedItems && this._selectedItems.length > 0
      ? this.props.entitiesById.get(this._selectedItems[0].id)
      : undefined;
  };

  private readonly _dismissPendingApprovalGrantRequestDetails = (): void => {
    this.setState({
      showingRequestDetails: false,
      showingPackageDetails: false,
      showingApprovalHistory: false
    });
    this.props.dismissPendingApprovalGrantRequestDetails;
  };

  private readonly _showDetails = (detailsType: DetailsType): void => {
    const telemetryPrefix = 'request-approval/show-details';

    switch (detailsType) {
      case DetailsType.Request:
        this.props.recordBusinessEvent(`${telemetryPrefix}/request`);
        this.setState({ showingRequestDetails: true });
        break;
      case DetailsType.Package:
        this.props.recordBusinessEvent(`${telemetryPrefix}/package`);
        this.setState({ showingPackageDetails: true });
        break;
      case DetailsType.Approval:
        this.props.recordBusinessEvent(`${telemetryPrefix}/approval`);
        this.setState({ showingApprovalHistory: true });
        break;
      default:
    }
  };

  private readonly _onPreviousDetails = (detailsType: DetailsType): void => {
    this._onShowDetailsPanel(DecisionType.DontKnow);
    switch (detailsType) {
      case DetailsType.Request:
        this.setState({ showingRequestDetails: false });
        break;
      case DetailsType.Package:
        this.setState({ showingPackageDetails: false });
        break;
      case DetailsType.Approval:
        this.setState({ showingApprovalHistory: false });
        break;
      default:
    }
  };

  private readonly _onItemSelected = (selectedItems: IGrantRequest[]): void => {
    this._selectedItems = selectedItems;
    this._isApproverReasonRequired = this._selectedItems.some((request: IGrantRequest) => {
      const currentStageIndex = getCurrentStageIndex(request);
      return currentStageIndex < 0
        ? false
        : request.approval.requestApprovalSettings.approvalStages[currentStageIndex].isApproverJustificationRequired;
    });

    this.setState({
      commands: this._getPendingApprovalGrantRequestListCommands()
    });
  };

  private readonly _loadMore = (): void => {
    if (!this.props.isLoading) {
      if (!this.props.isSearching) {
        this._getEntities();
      } else {
        this._searchEntitiesOnServer();
      }
    }
  };

  private readonly _searchEntitiesOnServer = (): void => {
    if (this.props.pageMetaData.isAllEntitiesFullyCached || this.props.pageMetaData.isFilteredEntitiesFullyCached) {
      return;
    }
    this.props.searchForMore();
  };

  private readonly _getEntities = (): void => {
    if (this.props.pageMetaData.isAllEntitiesFullyCached) {
      return;
    }
    this.props.getEntities();
  };

  private _getPendingApprovalGrantRequestListColumns(
    responsiveMode: ResponsiveMode,
    t: TranslationFunction,
    _props: IPendingApprovalGrantRequestListProps
  ): Array<IListColumn<IGrantRequest>> {
    const column: IListColumn<IGrantRequest> = {
      key: 'createdBy',
      name: t(LocaleKeys.name),
      fieldName: 'createdBy',
      minWidth: 100,
      maxWidth: 200,
      headerClassName: FontClassNames.smallPlus,
      columnActionsMode: ColumnActionsMode.disabled,
      isResizable: true,
      onRender: (item: IGrantRequest) => (
        <div>
          <div>
            <ColumnValue
              searchTerm={
                this.props.selectedFilterKey === ApprovalSearchFilter.Name ||
                this.props.selectedFilterKey === ApprovalSearchFilter.All
                  ? this.props.searchTerm
                  : ''
              }
              columnValue={item.requestor?.displayName ?? item.requestor?.email ?? ''}
              isHighlightRequired={false}
              isSearching={false}
            />
          </div>
          <div>
            <ColumnValue
              searchTerm={
                this.props.selectedFilterKey === ApprovalSearchFilter.AccessPackage ||
                this.props.selectedFilterKey === ApprovalSearchFilter.All
                  ? this.props.searchTerm
                  : ''
              }
              columnValue={item.requestor?.email ?? item.requestor?.principalName ?? ''}
              isHighlightRequired={false}
              isSearching={false}
              isSecondary={true}
            />
            {item.verifiedCredentialsData && item.verifiedCredentialsData.length > 0 ? (
              <span className={css(myAccessStyles.vcStyle)}>{LocaleKeys.verified}</span>
            ) : null}
          </div>
        </div>
      )
    } as IListColumn<IGrantRequest>;

    const columns: Array<IListColumn<IGrantRequest>> = [];
    columns.push(column);

    if (responsiveMode > ResponsiveMode.medium) {
      columns.push({
        key: 'entitlementName',
        name: t(LocaleKeys.requestedPackage),
        fieldName: 'resourceDisplayName',
        headerClassName: FontClassNames.smallPlus,
        columnActionsMode: ColumnActionsMode.disabled,
        minWidth: 60,
        maxWidth: 158,
        isResizable: true,
        onRender: (item: IGrantRequest) => {
          return (
            <div>
              <div>
                <ColumnValue
                  searchTerm={
                    this.props.selectedFilterKey === ApprovalSearchFilter.AccessPackage ||
                    this.props.selectedFilterKey === ApprovalSearchFilter.All
                      ? this.props.searchTerm
                      : ''
                  }
                  columnValue={
                    item.accessPackageAssignment.accessPackage
                      ? item.accessPackageAssignment.accessPackage.displayName!
                      : ''
                  }
                  isHighlightRequired={true}
                  isSearching={this.props.isSearching}
                />
              </div>
            </div>
          );
        }
      });
    }

    if (responsiveMode > ResponsiveMode.large) {
      columns.push({
        key: 'requestedDateTime',
        name: t(LocaleKeys.requestedOn),
        fieldName: 'requestedDateTime',
        headerClassName: FontClassNames.smallPlus,
        columnActionsMode: ColumnActionsMode.disabled,
        minWidth: 60,
        maxWidth: 160,
        isResizable: true,
        onRender: (item: IGrantRequest) => {
          return (
            <span className={css('ms-pii', FontClassNames.medium)}>{FormatDateTime(getStageCreationDate(item))}</span>
          );
        }
      });
    }

    if (responsiveMode > ResponsiveMode.large) {
      // temp disable sorting on due date as it's now a calculated date and sorting is not correct.
      columns.push({
        key: 'endDateTime',
        name: t(LocaleKeys.dueBy),
        fieldName: 'endDateTime',
        headerClassName: FontClassNames.smallPlus,
        columnActionsMode: ColumnActionsMode.disabled,
        minWidth: 60,
        maxWidth: 160,
        isResizable: true,
        onRender: (item: IGrantRequest) => {
          return <span className={css('ms-pii', FontClassNames.medium)}>{FormatDateTime(getDueDate(item))}</span>;
        }
      });
    }

    if (responsiveMode > ResponsiveMode.medium) {
      columns.push({
        key: 'target',
        name: t(LocaleKeys.requestedFor),
        fieldName: 'target',
        minWidth: 100,
        maxWidth: 200,
        headerClassName: FontClassNames.smallPlus,
        columnActionsMode: ColumnActionsMode.disabled,
        isResizable: true,
        onRender: (item: IGrantRequest) => {
          return (
            <div>
              <div>
                <ColumnValue
                  searchTerm={
                    this.props.selectedFilterKey === ApprovalSearchFilter.Name ||
                    this.props.selectedFilterKey === ApprovalSearchFilter.All
                      ? this.props.searchTerm
                      : ''
                  }
                  columnValue={
                    item.accessPackageAssignment.target?.displayName ?? item.accessPackageAssignment.target?.email ?? ''
                  }
                  isHighlightRequired={true}
                  isSearching={this.props.isSearching}
                />
              </div>
              <div>
                <ColumnValue
                  searchTerm={
                    this.props.selectedFilterKey === ApprovalSearchFilter.AccessPackage ||
                    this.props.selectedFilterKey === ApprovalSearchFilter.All
                      ? this.props.searchTerm
                      : ''
                  }
                  columnValue={
                    item.accessPackageAssignment.target?.email ??
                    item.accessPackageAssignment.target?.principalName ??
                    ''
                  }
                  isHighlightRequired={true}
                  isSearching={this.props.isSearching}
                  isSecondary={true}
                />
              </div>
            </div>
          );
        }
      });
    }

    columns.push({
      key: 'viewDetails',
      name: '',
      fieldName: 'id',
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      headerClassName: FontClassNames.smallPlus,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: IGrantRequest) => {
        if (item.requestStatus === 'Approve' || item.requestStatus === 'Deny') {
          return <span className={css(FontClassNames.medium)}>{this.props.t(ApprovalStatus[item.requestStatus])}</span>;
        }

        const linkText = t(LocaleKeys.reviewOnly);
        return (
          <Link
            // tslint:disable-next-line:jsx-no-lambda
            onClick={() => {
              this._setSelectedGrantRequest(item);
              this._onShowDetailsPanel(DecisionType.DontKnow);
            }}
            className={css(FontClassNames.medium, myAccessStyles.themeDarkFont)}
          >
            {linkText}
          </Link>
        );
      }
    });

    return columns;
  }

  private readonly _getSelectedItems = (): IGrantRequest[] | undefined => {
    return this._selectedItems && this._selectedItems.length > 0 ? this._selectedItems : undefined;
  };

  private readonly _getPendingApprovalGrantRequestListCommands = (): IContextualMenuItem[] => {
    const { t } = this.props;
    const itemNotSelected = !this._selectedItems! || this._selectedItems.length === 0;

    const selectedItems = this._getSelectedItems();

    let anySelectedReviewed: boolean = false;

    if (selectedItems) {
      for (const selectedItem of selectedItems) {
        if (isApproveOrDenyRequestStatus(selectedItem)) {
          anySelectedReviewed = true;
          break;
        }
      }
    }

    const approveCommand: IContextualMenuItem = {
      key: 'approve',
      name: t(LocaleKeys.approve),
      iconProps: { iconName: 'Accept' },
      disabled: itemNotSelected || anySelectedReviewed,
      onClick:
        this._selectedItems! && this._selectedItems.length > 1
          ? () => this._openBulkActionDialog(DecisionType.Approve)
          : () => {
              this._setSelectedGrantRequest(this._selectedItems![0]);
              this._onShowDetailsPanel(DecisionType.Approve);
            }
    };

    const denyCommand: IContextualMenuItem = {
      key: 'deny',
      name: t(LocaleKeys.deny),
      iconProps: { iconName: 'clear' },
      disabled: itemNotSelected || anySelectedReviewed,
      onClick:
        this._selectedItems! && this._selectedItems.length > 1
          ? () => this._openBulkActionDialog(DecisionType.Deny)
          : () => {
              this._setSelectedGrantRequest(this._selectedItems![0]);
              this._onShowDetailsPanel(DecisionType.Deny);
            }
    };

    return [approveCommand, denyCommand];
  };

  private readonly _setSelectedGrantRequest = (grantRequest: IGrantRequest): void => {
    this.state.currentAnswers = grantRequest.answers;

    this.setState({
      ...this.state,
      selectedGrantRequest: grantRequest
    });
  };

  private readonly _onShowDetailsPanel = (decision: DecisionType): void => {
    this.setState({
      ...this.state,
      decision
    });
    this.props.showPendingApprovalGrantRequestDetails();
  };

  private readonly _openBulkActionDialog = (decision: DecisionType): void => {
    this.setState({
      ...this.state,
      decision
    });
    this.props.showBulkActionDialog();
  };

  private _getResponsiveMode(): ResponsiveMode {
    let { responsiveMode } = this.props;
    if (responsiveMode === undefined) {
      responsiveMode = ResponsiveMode.large;
    }
    return responsiveMode;
  }

  private readonly _isAnswerEditable = (grantRequest: IGrantRequest | undefined): boolean => {
    // Values should be the same across all answers, so only check any of them
    if (isEmptyOrUndefined(grantRequest) || isEmptyOrUndefined(grantRequest.answers)) {
      return false;
    }

    return grantRequest.answers.some((ans) => ans.answeredQuestion.isAnswerEditable === true);
  };
}
