import { css } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';

import {
  useFeatures,
  useOverviewAccessReviewManager,
  useOverviewExpiringGrantsManager,
  useOverviewPackageLibraryManager,
  useOverviewPendingApprovalsManager,
  useSearchBox,
  useTelemetryWithMetadata,
  useTranslation
} from '../../../hooks';
import { isInternalUser, LocaleKeys } from '../../../shared';
import { IOverviewActionCardProps, OverviewActionCard, OverviewLearningCard } from '../../Shared/OverviewCard';
import { RecommendationCarousel } from '../../Shared/RecommendationCarousel';
import { OverviewEmptyAction } from './OverviewEmptyAction';

const overviewStyles = require('../../../css/overview.scoped.scss');
const globalStyles = require('../../../css/global.scss');
const myaccessStyles = require('../../../css/myAccess.scoped.scss');

export function OverviewPage() {
  const { overviewExpiringGrantData, refreshExpiringGrantOverview } = useOverviewExpiringGrantsManager();
  const { overviewPendingApprovalData, refreshPendingApprovalOverview } = useOverviewPendingApprovalsManager();
  const { viewLibrary } = useOverviewPackageLibraryManager();
  const { accessReviewData, getAccessPackageReviewCount, getGroupReviewCount } = useOverviewAccessReviewManager();
  const { reportCustomEventWithMetadata } = useTelemetryWithMetadata();
  const { showSearchBox } = useSearchBox();
  const [pendingActions, setPendingActions] = useState<IOverviewActionCardProps[]>([]);

  const features = useFeatures();

  const isTenantUser = isInternalUser();

  const t = useTranslation();

  useEffect(() => {
    if (isTenantUser) {
      refreshExpiringGrantOverview();
      refreshPendingApprovalOverview();
      getAccessPackageReviewCount();
      getGroupReviewCount();
    }
  }, []);

  useEffect(() => {
    showSearchBox(false);
    return () => {
      showSearchBox(true);
    };
  }, []);

  useEffect(() => {
    [overviewExpiringGrantData, overviewPendingApprovalData, accessReviewData].forEach((item) => {
      if (
        (item.pendingActionEntityCount !== 0 && item.isSucceeded) ||
        (!item.isSucceeded &&
          (!item.error ||
            item.error.length === 0 ||
            !item.error.some((err) => err.code == 'BlockedByConditionalAccess'))) ||
        item.isLoading
      ) {
        // Show cards only when loading, entity count is not 0, and error doesn't contain CA errors.
        setPendingActions((currentItems) => [...currentItems, item]);
      }
      if (!item.isSucceeded && item.errorEventName) {
        reportCustomEventWithMetadata(item.errorEventName, item.error);
      }
    });
    return () => {
      setPendingActions([]);
    };
  }, [overviewExpiringGrantData.isLoading, overviewPendingApprovalData.isLoading, accessReviewData.isLoading]);

  return (
    <main data-automation-id="OverviewPage" className={css(globalStyles.detailsPage)}>
      <div className={css(overviewStyles.overviewPageContainer)}>
        <h1 id="pageTitle" className={overviewStyles.pageTitle}>
          {t(LocaleKeys.myAccessOverview)}
        </h1>
        <div>
          <h2 className={overviewStyles.sectionTitle}>{t(LocaleKeys.overviewActionTitle)}</h2>
          {isTenantUser && pendingActions.length > 0 ? (
            // Disabling for inline styles
            // tslint:disable
            <div className={overviewStyles.overviewActionCardContainer}>
              {pendingActions.map((item) => (
                <OverviewActionCard
                  id={item.id}
                  key={item.id}
                  title={item.title}
                  pendingActionEntityCount={item.pendingActionEntityCount}
                  cardDescription={item.cardDescription}
                  cardImageUrl={item.cardImageUrl}
                  primaryButtonAction={() => {
                    reportCustomEventWithMetadata(`overview/action/click-${item.id}`);
                    item.primaryButtonAction();
                  }}
                  primaryButtonLabel={item.primaryButtonLabel}
                  refreshAction={() => {
                    reportCustomEventWithMetadata(`overview/retry/${item.id}`);
                    item.refreshAction();
                  }}
                  isLoading={item.isLoading}
                  isSucceeded={item.isSucceeded}
                />
              ))}
            </div>
          ) : (
            <OverviewEmptyAction />
          )}
        </div>
        {/* {features.enableAPRecommendations && <RecommendationCarousel />} */}
        <div>
          <h2 className={css(overviewStyles.sectionTitle, myaccessStyles.marginBottomMedium)}>
            {t(LocaleKeys.overviewLearningTitle)}
          </h2>
          <div className={overviewStyles.overviewLearningCardContainer}>
            <OverviewLearningCard
              title={t(LocaleKeys.learningCardRequestPackageTitle)}
              cardDescription={t(LocaleKeys.learningCardRequestPackageDescription)}
              cardImageUrl={'/imgs/feature_RequestPackage.png'}
              defaultButtonLabel={t(LocaleKeys.browseLibrary)}
              linkLabel={t(LocaleKeys.learnMore)}
              linkUrl={
                'https://learn.microsoft.com/azure/active-directory/governance/entitlement-management-request-access'
              }
              defaultButtonAction={() => {
                reportCustomEventWithMetadata('overview/click-browse-library');
                viewLibrary();
              }}
              handleLinkClick={() => {
                reportCustomEventWithMetadata('overview/learn-more/request-access');
              }}
            />
            {isTenantUser && (
              <OverviewLearningCard
                title={t(LocaleKeys.learningCardApprovalRequestTitle)}
                cardDescription={t(LocaleKeys.learningCardApprovalRequestDescription)}
                cardImageUrl={'/imgs/feature_RequestApproval.png'}
                linkLabel={t(LocaleKeys.learnMore)}
                linkUrl={
                  'https://learn.microsoft.com/azure/active-directory/governance/entitlement-management-request-approve'
                }
                handleLinkClick={() => {
                  reportCustomEventWithMetadata('overview/learn-more/request-approve');
                }}
              />
            )}
            {isTenantUser && (
              <OverviewLearningCard
                title={t(LocaleKeys.learningCardAccessReviewTitle)}
                cardDescription={t(LocaleKeys.learningCardAccessReviewDescription)}
                cardImageUrl={'/imgs/feature_AccessReview.png'}
                linkLabel={t(LocaleKeys.learnMore)}
                linkUrl={'https://learn.microsoft.com/azure/active-directory/governance/self-access-review'}
                handleLinkClick={() => {
                  reportCustomEventWithMetadata('overview/learn-more/access-review');
                }}
              />
            )}
          </div>
        </div>
      </div>
      <Redirect path="/" to="/overview" />
    </main>
  );
}
