// this file is NOT used anymore, kept for reference only. will clean up after releasing LeftNav integration

export enum Routes {
  root = '/',
  requests = '/request-history',
  requestApprovals = '/request-approval',
  requestPendingTab = '/request-approval/pending',
  requestCompletedTab = '/request-approval/completed',
  accessPackages = '/access-packages',
  requestAccessTab = '/access-packages',
  accessIHaveTab = '/access-packages/active',
  previousAccessTab = '/access-packages/expired',
  overview = '/overview',
  catalogs = '/catalogs',
  catalogId = '/:catalogId',
  accessReviews = '/access-reviews',
  supervisorCentricReviews = '/user-access-reviews',
  reviewId = '/:reviewId',
  approvals = '/approvals',
  pendingTab = '/approvals/pending',
  completedTab = '/approvals/completed',
  me = '/me',
  ram = '/ram',
  allVirtualMachinesTab = '/ram/virtualMachines/all',
  favoritesVirtualMachinesTab = '/ram/virtualMachines/favorites'
}
