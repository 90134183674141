import { IAppSuiteEnabledApps } from '@microsoft/portal-app/lib/AppSuite';
import { AppKeys } from '@microsoft/portal-app/lib/environment';
import { IAppItem } from '@iamexperiences/suite-header';
import { auth } from '../../../auth';
import { IAppInfo } from '../../../models/Header/interfaces';

export const getMenuItems = (appsMap: ReadonlyMap<AppKeys, IAppInfo>, showMyAccess: boolean): IAppItem[] => {
  const appItems: IAppItem[] = [];
  const tenantId = auth.user.value?.tenantId;
  const upn = auth.user.value && auth.user.value.username;

  for (const [key, value] of appsMap) {
    if (key === AppKeys.access && !showMyAccess) {
      continue;
    }

    const app = value;
    if (key === AppKeys.access){
      appItems.push({
        key,
        text: app.appName,
        selected: true,
        href: app.defaultUrl,
        target: '_self'
      });
    } else {
      const baseUrl = new URL(app.defaultUrl);
      baseUrl.searchParams.set('tenantId', tenantId || '');
      baseUrl.searchParams.set('upn', upn || '');
      appItems.push({
        key,
        text: app.appName,
        href: baseUrl.toString(),
        target: '_blank',
      });
   }
  }

  return appItems;
};

export const isAppEnabled = (key: string, enabledApps?: IAppSuiteEnabledApps): boolean => {
  if (!enabledApps) {
    return false;
  }
  return enabledApps[key];
};

export const buildMyStarUrl = (href: string, tenantId?: string, upn?: string) => {
  const url = new URL(href);
  url.searchParams.set('tenantId', tenantId || '');
  url.searchParams.set('upn', upn || '');

  return url.toString();
};
