import { IAjaxRequest } from '@microsoft/portal-app/lib/auth/withAuth';
import { LoadingState } from '@microsoft/portal-app/lib/models/ILoading';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';
import { flatMap } from 'rxjs/operators';

import { EntitlementActions } from '../../../models/EntitlementActions';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState, IRootEntitlementsState } from '../../../models/IEntitlementState';
import { IApproverSubstitution, ISetApproverSubstitutionPayload } from '../../../models/RequestApprovals/IApproverSubstitution';
import { getAudience, setApproverSubstitutionApiUrl } from '../../../shared';
import { registry } from '../myAccessRegistry';

interface ISetApproverSubstitutionSuccessActionPayload {
  approverSubstitution?: IApproverSubstitution;
}

interface ISetApproverSubstitutionFailActionPayload {
  error?: Error;
}

type ISetApproverSubstitutionTrigger = IEntitlementAction<ISetApproverSubstitutionPayload>;

type ISetApproverSubstitutionSuccess = IEntitlementAction<ISetApproverSubstitutionSuccessActionPayload>;
type ISetApproverSubstitutionFail = IEntitlementAction<ISetApproverSubstitutionFailActionPayload>;

type ISetApproverSubstitutionAction = ISetApproverSubstitutionTrigger | ISetApproverSubstitutionSuccess | ISetApproverSubstitutionFail;

export const setApproverSubstitutionEpic: Epic<ISetApproverSubstitutionAction, IRootEntitlementsState> = (
  action$: ActionsObservable<ISetApproverSubstitutionAction>,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<ISetApproverSubstitutionAction> => {
  return action$.ofType(EntitlementActions.setApproverSubstitution).pipe(
    flatMap((_action) => {
      const { approverSubstitution } = _action.payload! as ISetApproverSubstitutionPayload;
      const request: IAjaxRequest = {
        method: 'PUT',
        url: setApproverSubstitutionApiUrl(),
        audience: getAudience(),
        body: approverSubstitution
      };

      return ajax(request)
        .map((payload) => {
          if (!payload?.response?.value) {
            return {
              type: EntitlementActions.setApproverSubstitutionSuccess,
            };
          }
          return {
            type: EntitlementActions.setApproverSubstitutionSuccess,
            payload: {
              approverSubstitution: payload.response.value
            }
          };
        })
        .catch((error: any) =>
          Observable.of<ISetApproverSubstitutionFail>({
            type: EntitlementActions.setApproverSubstitutionFailed,
            payload: {
              error
            }
          })
        );
    })
  );
};
registry.addEpic('setApproverSubstitutionEpic', setApproverSubstitutionEpic);

export const setApproverSubstitution = (state: IEntitlementState, _action: IEntitlementAction): IEntitlementState => {
  return {
    ...state,
    approverSubstitution: {
      ...state.approverSubstitution,
      isLoading: true,
      loadingState: LoadingState.loading
    }
  };
};
registry.add(EntitlementActions.setApproverSubstitution, setApproverSubstitution);

export const setApproverSubstitutionSuccess = (
  state: IEntitlementState,
  action: IEntitlementAction<ISetApproverSubstitutionSuccessActionPayload>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined || action.payload?.approverSubstitution === undefined) {
    return {
      ...state,
      approverSubstitution: {
        ...state.approverSubstitution,
        isLoading: false,
        loadingState: LoadingState.loaded,
        value: null
      }
    };
  }

  return {
    ...state,
    approverSubstitution: {
      ...state.approverSubstitution,
      isLoading: false,
      loadingState: LoadingState.loaded,
      value: action.payload?.approverSubstitution
    }
  };
};
registry.add(EntitlementActions.setApproverSubstitutionSuccess, setApproverSubstitutionSuccess);

export const setApproverSubstitutionFailed = (
  state: IEntitlementState,
  action: IEntitlementAction<ISetApproverSubstitutionFailActionPayload>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  return {
    ...state,
    approverSubstitution: {
      ...state.approverSubstitution,
      isLoading: false,
      loadingState: LoadingState.error,
      loadingError: action.payload.error
    }
  };
};
registry.add(EntitlementActions.setApproverSubstitutionFailed, setApproverSubstitutionFailed);
