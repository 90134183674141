import { translate } from 'react-i18next';
import { connect, Dispatch } from 'react-redux';

import { createAction } from '@microsoft/portal-app/lib/redux/createAction';

import { EntitlementActions } from '../../../models';
import { EntitlementSearchFilter } from '../../../models/ELM/EntitlementSearchFilter';
import { IGrant } from '../../../models/ELM/IGrant';
import { IGrantRequest } from '../../../models/ELM/IGrantRequest';
import { EntityType } from '../../../models/EntityType';
import { IRootEntitlementsState } from '../../../models/IEntitlementState';
import { IFilter } from '../../../models/IFilter';
import { IListColumn } from '../../../models/IListColumn';
import { LoadMode } from '../../../models/IPageData';
import { GetEntityList } from '../../../shared/GetEntityList';
import { getPageMetaDataFromEntities } from '../../../shared/getPageMetaDataFromEntities';
import { isEmptyOrUndefined } from '../../../shared/isEmptyOrUndefined';
import { findGuid } from '../../../shared/isGuid';
import { isInternalUser } from '../../../shared';

import {
  ActiveGrantList,
  IActiveGrantListActions,
  IActiveGrantListMappedProps,
  IConnectedActiveGrantListProps,
} from './';

/**
 * Maps the external (i.e. own) props and the state to the properties of the ValidGrantList control.
 * @param state The current redux state to map to props
 * @param ownProps The external properties to map to props
 * @returns The mapped properties that along with the actions
 * becomes the props (IValidGrantListProps) for the ValidGrantList control
 */
const mapStateToProps = (
  state: IRootEntitlementsState,
  _ownProps: IConnectedActiveGrantListProps
): IActiveGrantListMappedProps => {
  const {
    showingGrantFilter,
    validGrants,
    searchTerm,
    errorHasOccurred,
    isTenantWhitelisted,
    submitting,
  } = state.app;
  const showingAddGrantRequest = state.app.showingAddGrantRequest!;
  const showingConfirmDialog = state.app.showingConfirmDialog!;
  const showingCopyLink = state.app.showingCopyLink!;
  const filter = validGrants.filterContext!;

  const isSearching: boolean = !isEmptyOrUndefined(searchTerm);
  const isFiltering: boolean = !isEmptyOrUndefined(filter);
  const selectedFilterKey =
    state.search.selectedFilterKey || EntitlementSearchFilter.All;

  const entitiesList = GetEntityList(validGrants, isFiltering || isSearching);

  const grantList: IGrant[] = entitiesList.filteredEntities.reduce(
    (grantsList: IGrant[], key: string) => {
      const item = validGrants.entitiesById.get(key);
      if (item !== undefined) {
        grantsList.push(item);
      }
      return grantsList;
    },
    []
  );

  const pageMetaData = getPageMetaDataFromEntities(validGrants);
  const loadMode = validGrants.loadMode!;

  const isSingleEntity = findGuid(location.hash) !== '';

  const isGuestUser = !isInternalUser();

  return {
    isSubmitting: submitting,
    isFiltering,
    isSearching,
    errorHasOccurred,
    filter,
    isLoading: validGrants.isLoading,
    isRefreshing: loadMode === LoadMode.Refresh,
    isLoadingMore: loadMode === LoadMode.LoadMore,
    grantList,
    pageMetaData,
    showingGrantFilter,
    searchTerm,
    selectedFilterKey: selectedFilterKey,
    isTenantWhitelisted,
    showingAddGrantRequest,
    showingConfirmDialog,
    showingCopyLink,
    isSingleEntity,
    features: state.app.features.isEnabled,
    isGuestUser,
  };
};

/**
 * Maps the dispatch actions to the props for the ValidGrantList control.
 * @param dispatch Dispatches actions to the List
 * @returns The mapped actions that along with the mapped properties
 * becomes the props (IValidGrantListProps) for the ValidGrantList control
 */
const mapDispatchToProps = (
  dispatch: Dispatch<IRootEntitlementsState>
): IActiveGrantListActions => {
  const showGrantFilter = createAction<boolean>(
    EntitlementActions.showGrantFilter,
    dispatch
  );
  const setSortedByColumnAction = createAction<{
    column: IListColumn<IGrant>;
    entityType: string;
  }>(EntitlementActions.setSortedByColumn, dispatch);
  const getEntitiesAction = createAction<{ entityType: string }>(
    EntitlementActions.getEntities,
    dispatch
  );
  const sortEntitiesAction = createAction<{
    entityType: string;
    orderby: string;
    isAscending: boolean;
  }>(EntitlementActions.sortEntities, dispatch);
  const sortFilteredEntitiesAction = createAction<{
    entityType: string;
    orderby: string;
    isAscending: boolean;
    searchTerm: string;
    filterContext: IFilter;
  }>(EntitlementActions.sortFilteredEntities, dispatch);
  const filterEntitiesOnServerAction = createAction<{
    entityType: string;
    filterContext: IFilter | undefined;
  }>(EntitlementActions.filterEntitiesOnServer, dispatch);
  const refreshEntitiesAction = createAction<{ entityType: string }>(
    EntitlementActions.refreshEntities,
    dispatch
  );
  const showAddGrantRequest = createAction<boolean>(
    EntitlementActions.showAddGrantRequest,
    dispatch
  );
  const showCopyLink = createAction<boolean>(
    EntitlementActions.showCopyLink,
    dispatch
  );
  const showConfirmDialog = createAction<boolean>(
    EntitlementActions.showConfirmDialog,
    dispatch
  );
  const searchForMoreAction = createAction<{ entityType: string }>(
    EntitlementActions.searchForMore,
    dispatch
  );

  return {
    searchForMore: () =>
      searchForMoreAction({ entityType: EntityType.validGrants }),
    setSortedByColumn: (column: IListColumn<IGrant>) =>
      setSortedByColumnAction({
        column: column,
        entityType: EntityType.validGrants,
      }),
    getEntities: () =>
      getEntitiesAction({
        entityType: EntityType.validGrants,
      }),
    showGrantFilter: () => showGrantFilter(true),
    dismissGrantFilter: () => showGrantFilter(false),
    setSearchContext: createAction<string | null>(
      EntitlementActions.setSearchContext,
      dispatch
    ),
    filterEntitiesOnServer: (filter: IFilter | undefined) =>
      filterEntitiesOnServerAction({
        entityType: EntityType.validGrants,
        filterContext: filter,
      }),
    sortEntities: (orderby: string, isAscending: boolean) =>
      sortEntitiesAction({
        entityType: EntityType.validGrants,
        orderby: orderby,
        isAscending: isAscending,
      }),
    sortFilteredEntities: (
      orderby: string,
      isAscending: boolean,
      searchTerm: string,
      filterContext: IFilter
    ) =>
      sortFilteredEntitiesAction({
        entityType: EntityType.validGrants,
        orderby: orderby,
        isAscending: isAscending,
        searchTerm: searchTerm,
        filterContext: filterContext,
      }),
    refreshEntities: () =>
      refreshEntitiesAction({ entityType: EntityType.validGrants }),
    dismissCopyLink: () => showCopyLink(false),
    dismissConfirmDialog: () => showConfirmDialog(false),
    dismissAddGrantRequest: () => showAddGrantRequest(false),
    showCopyLink: () => showCopyLink(true),
    showConfirmDialog: () => showConfirmDialog(true),
    showAddGrantRequest: () => showAddGrantRequest(true),
    postGrantRequest: createAction<{
      newGrantRequest: Partial<IGrantRequest>;
      entitlementName: string;
    }>(EntitlementActions.addGrantRequest, dispatch),
  };
};

/**
 * A redux connected ValidGrantList control.
 * @description Supports translation
 */
export const ConnectedActiveGrantList = connect(
  mapStateToProps,
  mapDispatchToProps
  // tslint:disable-next-line:no-any
)(translate('MyAccess')(ActiveGrantList) as any);
