import { IAjaxRequest } from '@microsoft/portal-app/lib/auth/withAuth';
import { getResponseValue, IODataValueResponse } from '@microsoft/portal-app/lib/odata-utils';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';

import { EntitlementActions } from '../../../models/EntitlementActions';
import { EntityType } from '../../../models/EntityType';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState, IRootEntitlementsState } from '../../../models/IEntitlementState';
import { IEntityResult } from '../../../models/IEntityResult';
import { LoadMode } from '../../../models/IPageData';
import { IVirtualMachine } from '../../../models/RAM/IVirtualMachine';
import { getRequestWithAudience } from '../../../shared/AttachAudience';
import { getVirtualMachinesApiUrl } from '../../../shared/getRamApiUrl';
import { registry } from '../myAccessRegistry';

export const searchVirtualMachinesOnServerEpic: Epic<IEntitlementAction<AnyPayload>, IRootEntitlementsState> = (
  action$: ActionsObservable<IEntitlementAction<string>>,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
  return action$
    .ofType(EntitlementActions.searchVirtualMachinesOnServer)
    .switchMap((action: IEntitlementAction<string>) => {
      const searchTerm = action.payload?.toLowerCase();
      const virtualMachines = _store.getState().app.virtualMachines;
      const nextLink = virtualMachines.filtered.nextLink!;

      const ajaxRequest: IAjaxRequest = getRequestWithAudience(
        nextLink || getVirtualMachinesApiUrl(searchTerm),
        EntityType.virtualMachines
      );

      return ajax(ajaxRequest)
        .map((payload: IODataValueResponse<readonly IVirtualMachine[]>) => {
          return {
            type: EntitlementActions.searchEntitiesOnServerSucceeded,
            payload: {
              entityType: EntityType.virtualMachines,
              entities: getResponseValue(payload),
              searchTerm,
              count: payload.response!['@odata.count'],
              nextLink: payload.response!['@odata.nextLink']
            }
          } as IEntitlementAction<IEntityResult<IVirtualMachine>>;
        })
        .catch(() =>
          Observable.of({
            type: EntitlementActions.searchEntitiesOnServerFailed,
            payload: EntityType.virtualMachines
          })
        );
    });
};
registry.addEpic('searchVirtualMachinesOnServerEpic', searchVirtualMachinesOnServerEpic);

export const searchVirtualMachinesOnServer = (
  state: IEntitlementState,
  action: IEntitlementAction<string>
): Readonly<IEntitlementState> => {
  const searchTerm = action.payload;
  return {
    ...state,
    virtualMachines: {
      ...state.virtualMachines,
      filtered: {
        ...state.virtualMachines.filtered,
        entities: [],
        count: undefined,
        nextLink: undefined
      },
      filterContext: undefined,
      isLoading: true,
      loadMode: LoadMode.LoadMore,
      searchTerm
    }
  };
};
registry.add(EntitlementActions.searchVirtualMachinesOnServer, searchVirtualMachinesOnServer);
