import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { translate } from 'react-i18next';
import { connect, Dispatch } from 'react-redux';
import { EntitlementActions } from '../../../models';
import { IGrantRequest } from '../../../models/ELM/IGrantRequest';
import { EntityType } from '../../../models/EntityType';
import { IRootEntitlementsState } from '../../../models/IEntitlementState';
import { IListColumn } from '../../../models/IListColumn';
import { LoadMode } from '../../../models/IPageData';
import { ApprovalSearchFilter } from '../../../models/RequestApprovals/ApprovalSearchFilter';
import { GetEntityList } from '../../../shared/GetEntityList';
import { getPageMetaDataFromEntities } from '../../../shared/getPageMetaDataFromEntities';
import { CompletedGrantRequestList } from './CompletedGrantRequestList';
import {
  ICompletedGrantRequestListActions,
  ICompletedGrantRequestListMappedProps,
  IConnectedCompletedGrantRequestListProps
} from './CompletedGrantRequestList.types';

/**
 * Maps the external (i.e. own) props and the state to the properties of the CompletedGrantRequestList control.
 * @param state The current redux state to map to props
 * @param ownProps The external properties to map to props
 * @returns The mapped properties that along with the actions
 * becomes the props (ICompletedGrantRequestListProps) for the CompletedGrantRequestList control
 */
const mapStateToProps = (
  state: IRootEntitlementsState,
  _ownProps: IConnectedCompletedGrantRequestListProps
): ICompletedGrantRequestListMappedProps => {
  const {
    completedGrantRequests,
    errorHasOccurred,
    errorCode,
    showingCompletedGrantRequestDetails,
    searchTerm
  } = state.app;

  const isSearching: boolean = searchTerm !== null && searchTerm !== '';
  const entitiesList = GetEntityList(completedGrantRequests, isSearching);

  const showingBulkActionDialog = state.app.showingBulkActionDialog!;
  const completedGrantRequestList: IGrantRequest[] = entitiesList.filteredEntities.reduce(
    (list: IGrantRequest[], key: string) => {
      const item = completedGrantRequests.entitiesById.get(key);
      if (item !== undefined) {
        list.push(item);
      }
      return list;
    },
    []
  );

  const pageMetaData = getPageMetaDataFromEntities(
    completedGrantRequests,
    errorHasOccurred
  );
  const loadMode = completedGrantRequests.loadMode!;

  const selectedFilterKey =
    state.search.selectedFilterKey || ApprovalSearchFilter.All;

  return {
    isLoading: completedGrantRequests.isLoading,
    isLoadingMore: loadMode === LoadMode.LoadMore,
    isRefreshing: loadMode === LoadMode.Refresh,
    completedGrantRequestList,
    pageMetaData,
    errorHasOccurred,
    errorCode,
    showingDetails: showingCompletedGrantRequestDetails!,
    entitiesById: completedGrantRequests.entitiesById,
    searchTerm,
    showingBulkActionDialog,
    selectedFilterKey: selectedFilterKey,
    isSearching,
    enableApproverRevoke: state.app.features.isEnabled.enableApproverRevoke,
  };
};

/**
 * Maps the dispatch actions to the props for the CompletedGrantRequestList control.
 * @param dispatch Dispatches actions to the List
 * @returns The mapped actions that along with the mapped properties
 * becomes the props (ICompletedGrantRequestListProps) for the CompletedGrantRequestList control
 */
const mapDispatchToProps = (
  dispatch: Dispatch<IRootEntitlementsState>
): ICompletedGrantRequestListActions => {
  const showDetailsAction = createAction<boolean>(
    EntitlementActions.showCompletedGrantRequestDetails,
    dispatch
  );
  const getEntitiesAction = createAction<{ entityType: string }>(
    EntitlementActions.getEntities,
    dispatch
  );
  const refreshEntitiesAction = createAction<{ entityType: string }>(
    EntitlementActions.refreshEntities,
    dispatch
  );

  const setSortedByColumnAction = createAction<{
    column: IListColumn<IGrantRequest>;
    entityType: string;
  }>(EntitlementActions.setSortedByColumn, dispatch);

  const sortEntities = createAction<{
    entityType: string;
    orderby: string;
    isAscending: boolean;
  }>(EntitlementActions.sortEntities, dispatch);

  const showBulkActionDialogAction = createAction<boolean>(
    EntitlementActions.showBulkActionDialog,
    dispatch
  );

  const searchForMoreAction = createAction<{ entityType: string }>(
    EntitlementActions.searchForMore,
    dispatch
  );

  const sortFilteredEntitiesAction = createAction<{
    entityType: string;
    orderby: string;
    isAscending: boolean;
    searchTerm: string;
  }>(EntitlementActions.sortFilteredEntities, dispatch);

  return {
    showDetails: () => showDetailsAction(true),
    dismissDetails: () => showDetailsAction(false),
    getEntities: () =>
      getEntitiesAction({
        entityType: EntityType.completedGrantRequests
      }),
    refreshEntities: () =>
      refreshEntitiesAction({ entityType: EntityType.completedGrantRequests }),
    setSearchContext: createAction<string | null>(
      EntitlementActions.setSearchContext,
      dispatch
    ),
    setSortedByColumn: (column: IListColumn<IGrantRequest>) =>
      setSortedByColumnAction({
        column: column,
        entityType: EntityType.completedGrantRequests
      }),
    sortCompletedGrantRequests: (orderby: string, isAscending: boolean) =>
      sortEntities({
        entityType: EntityType.completedGrantRequests,
        orderby: orderby,
        isAscending: isAscending
      }),
    showBulkActionDialog: () => showBulkActionDialogAction(true),
    dismissBulkActionDialog: () => showBulkActionDialogAction(false),
    searchForMore: () =>
      searchForMoreAction({ entityType: EntityType.completedGrantRequests }),
    sortFilteredEntities: (
      orderby: string,
      isAscending: boolean,
      searchTerm: string
    ) =>
      sortFilteredEntitiesAction({
        entityType: EntityType.completedGrantRequests,
        orderby: orderby,
        isAscending: isAscending,
        searchTerm: searchTerm
      })
  };
};

/**
 * A redux connected CompletedGrantRequestList control.
 * @description Supports translation
 */
export const ConnectedCompletedGrantRequestList = connect(
  mapStateToProps,
  mapDispatchToProps
  // tslint:disable-next-line:no-any
)(translate('MyAccess')(CompletedGrantRequestList) as any);
