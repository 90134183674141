
import {
  CommandBar,
  css,
  SelectionMode,
} from '@fluentui/react';
import {
  ResponsiveMode,
  withResponsiveMode,
} from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import * as React from 'react';

import { ErrorBanner } from '@microsoft/portal-app/lib/Banners/ErrorBanner';

import { EntitlementActions } from '../../../models';
import { IEntitlement } from '../../../models/ELM/IEntitlement';
import { RequestType } from '../../../models/ELM/RequestType';
import { EntityType } from '../../../models/EntityType';
import { IListColumn } from '../../../models/IListColumn';
import { history } from '../../../redux/configureStore';
import {
  GetGrantFromEntitlement,
  GetGrantRequestFromGrant,
} from '../../../shared/GetGrantRequestFromEntity';
import { isTouchScreen } from '../../../shared/isTouchScreen';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { getNewColumnsOnColumnClicked } from '../../../shared/sortingHelper';
import { getSpinner } from '../../../shared/spinner';
import { getOneLayerUp } from '../../../shared/urlHelper';
import { InfinityList } from '../../Shared/InfinityList/InfinityList';
import { ConnectedAddGrantRequest } from '../AddGrantRequest/ConnectedAddGrantRequest';
import { getEntitlementListColumns } from './EntitlementList.columns';
import { getEntitlementListCommands } from './EntitlementList.commands';
import {
  IEntitlementListProps,
  IEntitlementListState,
} from './EntitlementList.types';

const myAccessListStyles = require('../../../css/myAccessList.scoped.scss');

@withResponsiveMode
export class EntitlementList extends React.Component<
  IEntitlementListProps,
  IEntitlementListState
> {
  private _selectedItems: IEntitlement[] | null = null;
  constructor(nextProps: IEntitlementListProps) {
    super(nextProps);

    this.state = {
      columns: getEntitlementListColumns(
        this._getResponsiveMode(),
        this.props.t!,
        this.props.selectedFilterKey,
        this.props.isSearching,
        this.props.searchTerm,
        this._getSelectedKey(),
        null,
        false,
        this.props.showAddGrantRequest,
        this._onColumnClick
      ),
      commands: getEntitlementListCommands(
        this.props.t,
        this.props,
        !this._selectedItems! || this._selectedItems!.length === 0
      ),
      isSortedDecending: false,
    };
  }

  public componentDidMount(): void {
    this.loadInitial();
  }

  public componentDidUpdate(prevProps: IEntitlementListProps): void {
    if (this.props.isSingleEntity !== prevProps.isSingleEntity) {
      this.loadInitial();
    }

    if (
      !this.props.catalogId &&
      prevProps.isSearching !== this.props.isSearching
    ) {
      this.setState({
        ...this.state,
        columns: getEntitlementListColumns(
          this._getResponsiveMode(),
          this.props.t!,
          this.props.selectedFilterKey,
          this.props.isSearching,
          this.props.searchTerm,
          this._getSelectedKey(),
          null,
          this.state.isSortedDecending,
          this.props.showAddGrantRequest,
          this._onColumnClick
        ),
      });
    }

    // For the single access package view, show the "Request access" side panel
    // automatically when the entitlement is loaded from server.
    if (
      this.props.isSingleEntity &&
      this.props.accessPackageId &&
      prevProps.entitlementList.length === 0 &&
      this.props.entitlementList.length > 0
    ) {
      const [entitlement] = this.props.entitlementList;
      this._selectedItems = [entitlement];
      this.props.showAddGrantRequest();
    }
  }

  public render(): JSX.Element {
    const {
      isLoading,
      isRefreshing,
      isLoadingMore,
      isFiltering,
      errorHasOccurred,
      isSingleEntity,
      t,
      isTenantWhitelisted,
    } = this.props;
    const spinnerLabel = t(LocaleKeys.loadingPage, {
      pageName: t(LocaleKeys.package, { cotnext: 'plural' }),
    });

    if (isRefreshing) {
      return getSpinner(spinnerLabel);
    }

    if (errorHasOccurred) {
      if (!isTenantWhitelisted) {
        return <ErrorBanner text={t(LocaleKeys.tenantNotWhitelistedMessage)} />;
      }

      return (
        <ErrorBanner
          text={t(LocaleKeys.errorMessage)}
          onAction={this.props.getEntitlements}
          actionText={t(LocaleKeys.retry)}
        />
      );
    }

    const entitlements = this.props.entitlementList as IEntitlement[];

    const { isSearching, pageMetaData, showingAddGrantRequest } = this.props;

    const filteredCount = pageMetaData.filteredEntityCount
      ? pageMetaData.filteredEntityCount
      : 0;

    const entitlement =
      this._selectedItems && this._selectedItems.length > 0
        ? this._selectedItems[0]
        : null;

    const grant = GetGrantFromEntitlement(entitlement);
    const grantRequest = GetGrantRequestFromGrant(grant!, RequestType.UserAdd);

    const showNoEntities =
      (pageMetaData.allEntityCount === 0 &&
        !this.props.isRefreshing &&
        !isSearching &&
        !isFiltering) ||
      (entitlements.length === 0 && isSingleEntity && !isLoading);

    const showNoFilteredResults =
      !isLoading && isSearching && filteredCount === 0;

    const showLoadMore =
      !pageMetaData.isAllEntitiesFullyCached &&
      !isLoading &&
      !isLoadingMore &&
      !pageMetaData.isFilteredEntitiesFullyCached;

    const noEntitiesProp = this.props.isSingleEntity
      ? {
          t: t,
          iconName: 'Lock',
          noRowMessage: LocaleKeys.noPermissionToViewAccessPackage,
          showButton: true,
          buttonText: LocaleKeys.viewOtherAvailablePackages,
          onButtonClick: () => history.push(getOneLayerUp()),
        }
      : {
          t: t,
          iconName: 'Unlock',
          noRowMessage: LocaleKeys.noEntitlementsMessage,
          showButton: false,
        };

    return (
      <div
        className={css(myAccessListStyles.listPage, myAccessListStyles.padding)}
      >
        <CommandBar
          className={css(myAccessListStyles.commandBar)}
          items={this.state.commands!}
        />
        <InfinityList
          t={t}
          entityList={entitlements}
          ariaLabel={'List of access packages to request'}
          columns={this.state.columns}
          showLoadMore={showLoadMore}
          showSpinner={isLoadingMore}
          spinnerLabel={spinnerLabel}
          showNoEntities={showNoEntities}
          entityType={EntityType.entitlements}
          noEntitiesProps={noEntitiesProp}
          showNoFilteredResults={showNoFilteredResults}
          onLoadMore={this._loadMore}
          onItemSelected={this._onItemSelected}
          selectionMode={SelectionMode.single}
          isExpanded={isSingleEntity}
          selectedFilterKey={this.props.selectedFilterKey}
          searchTerm={this.props.searchTerm}
          onSetHoveredKey={isTouchScreen() ? undefined : this._setHoveredKey}
        />
        {showingAddGrantRequest ? (
          <ConnectedAddGrantRequest
            grantRequest={grantRequest}
            entitlementName={entitlement && entitlement.displayName}
            entitlementDescription={entitlement && entitlement.description}
            onDismiss={this.props.dismissAddGrantRequest}
          />
        ) : null}
      </div>
    );
  }

  private _setHoveredKey = (key: string): void => {
    this.setState({
      ...this.state,
      columns: getEntitlementListColumns(
        this._getResponsiveMode(),
        this.props.t!,
        this.props.selectedFilterKey,
        this.props.isSearching,
        this.props.searchTerm,
        this._getSelectedKey(),
        key,
        this.state.isSortedDecending,
        this.props.showAddGrantRequest,
        this._onColumnClick
      ),
    });
  }

  private loadInitial = (): void => {
    const { accessPackageId, catalogId } = this.props;

    if (accessPackageId) {
      // Single access package view
      this.props.filterEntitiesOnServer({ id: accessPackageId });
      this.props.setSearchContext(null);
    } else if (catalogId) {
      // View for access packages for a specific catalogs
      this.props.filterEntitiesOnServer({ selectedCatalogs: [catalogId] });
      this.props.setSearchContext(
        EntitlementActions.searchEntitlementsOnServer
      );
      this.props.showFiltersIcon(true);
    } else {
      // Unfiltered entitlement view
      this.props.refreshEntities();
      this.props.setSearchContext(
        EntitlementActions.searchEntitlementsOnServer
      );

      const urlParams = new URLSearchParams(location.search);
      if (urlParams.has('name')) {
        let searchTerm = urlParams.get('name');
        if (searchTerm) {
          this.props.onSearch(searchTerm);
        }
      }
    }
  };

  private _onItemSelected = (selectedItems: IEntitlement[]): void => {
    this._selectedItems = selectedItems;
    this.setState({
      ...this.state,
      commands: getEntitlementListCommands(
        this.props.t,
        this.props,
        !this._selectedItems! || this._selectedItems!.length === 0
      ),
      columns: getEntitlementListColumns(
        this._getResponsiveMode(),
        this.props.t!,
        this.props.selectedFilterKey,
        this.props.isSearching,
        this.props.searchTerm,
        this._getSelectedKey(),
        null,
        this.state.isSortedDecending,
        this.props.showAddGrantRequest,
        this._onColumnClick
      ),
    });
  }

  private _getSelectedKey(): string | null {
    if (!this._selectedItems || this._selectedItems.length === 0) {
      return null;
    }

    return this._selectedItems[0].id;
  }

  private _loadMore = (): void => {
    const { catalogId } = this.props;

    if (!this.props.isLoading) {
      if (catalogId) {
        this.props.filterEntitiesOnServer(this.props.filter);
      } else if (this.props.isSearching) {
        this._searchEntitlements();
      } else {
        this._getEntitlements();
      }
    }
  }

  private _getResponsiveMode(): ResponsiveMode {
    let { responsiveMode } = this.props;
    if (responsiveMode === undefined) {
      responsiveMode = ResponsiveMode.large;
    }
    return responsiveMode;
  }

  private _getEntitlements = (): void => {
    if (this.props.pageMetaData.isAllEntitiesFullyCached) {
      return;
    }
    const nextProps = this.props;
    nextProps.getEntitlements();
  }

  private _searchEntitlements = (): void => {
    if (
      this.props.pageMetaData.isAllEntitiesFullyCached! ||
      this.props.pageMetaData.isFilteredEntitiesFullyCached
    ) {
      return;
    }

    this.props.searchForMore();
  }

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IListColumn<IEntitlement>
  ): void => {
    ev.preventDefault();
    column.isSorted = true;
    column.isSortedDescending = !column.isSortedDescending;
    this.setState({
      ...this.state,
      isSortedDecending: !this.state.isSortedDecending,
    });

    this.setState({
      columns: getNewColumnsOnColumnClicked(this.state.columns, column),
    });
    this.props.setSortedByColumn(column);
    if (!this.props.isSearching && !this.props.isFiltering) {
      this.props.sortEntitlements(
        column.fieldName,
        !column.isSortedDescending!
      );
    } else {
      this.props.sortFilteredEntitlements(
        column.fieldName,
        !column.isSortedDescending!,
        this.props.searchTerm!,
        this.props.filter
      );
    }
  };
}
