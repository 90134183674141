import { IAjaxCreationMethod } from '@microsoft/portal-app/lib/auth';
import { IAjaxRequest } from '@microsoft/portal-app/lib/auth';
import { IAction } from '@microsoft/portal-app/lib/redux';
import { addAjaxEpic, getSuccessActionType } from '@microsoft/portal-app/lib/redux/createAjaxEpic';
import { EntitlementActions, IEntitlementState } from '../../../models';
import { registry } from '../myAccessRegistry';
import { getAudience, getELMBaseUrlBasedOnEnv } from '../../../../src/shared';

const getTenantBranding = (): IAjaxRequest => {
  return {
    url: `${getELMBaseUrlBasedOnEnv()}tenants?$select=bannerLogoUrl`,
    audience: getAudience()
  };
};

export const getTenantBrandingAjax = (ajax: IAjaxCreationMethod) => {
  return ajax.getJSON(getTenantBranding());
};

export const getTenantBrandingResponse = (
  state: IEntitlementState,
  action: IAction<{ bannerLogoUrl: string }>
): IEntitlementState => {
  if (!action.payload) {
    return state;
  }
  // tslint:disable-next-line:no-any
  const result = action.payload;
  let tenantBrandingUrl = '';
  if (result.bannerLogoUrl && result.bannerLogoUrl !== '') {
    tenantBrandingUrl = result.bannerLogoUrl!;
  }

  return {
    ...state,
    tenantBrandingUrl
  };
};

addAjaxEpic(registry, EntitlementActions.getTenantBranding, getTenantBrandingAjax, {
  showErrorAsToast: false
});

registry.add(getSuccessActionType(EntitlementActions.getTenantBranding), getTenantBrandingResponse);
