import { IConfiguration, IOrganization as IHeaderOrganization, IUserData } from '@iamexperiences/suite-header';
import { useGetResourceTenants } from '@iamexperiences/suite-header/lib-commonjs/SuiteHeader/hooks/apis/useGetResourceTenants';

import { useCurrentOrganization } from '../index';

interface IQueryResponse {
  readonly organizations: IHeaderOrganization[];
  readonly isLoading: boolean;
  readonly error: unknown;
}

export const useHeaderOrganizations = (configuration: IConfiguration, userData: IUserData): IQueryResponse => {

  const { data, error, isLoading } = useGetResourceTenants(configuration, userData);
  const { currentOrganization, isLoading: isCurrentOrgLoading, error: getCurrentOrgError } = useCurrentOrganization();

  const organizations = data ?? [];
  return {
    organizations: organizations.some((org: IHeaderOrganization) => org.tenantId === currentOrganization.tenantId)
      ? organizations
      : [
          ...organizations,
          {
            displayName: currentOrganization.orgName,
            tenantId: currentOrganization.tenantId,
            isCurrent: currentOrganization.isCurrent,
            isHome: currentOrganization.isHome,
          },
        ],
    isLoading: isLoading || isCurrentOrgLoading,
    error: error || getCurrentOrgError
  };
};
