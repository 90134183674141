import { AnswerStringType } from '../../shared/constants';
import { IAnswer } from './IAnswer';
import { IQuestion } from './IQuestion';

export class AnswerString implements IAnswer {
  public '@odata.type': string = AnswerStringType;
  public value: string;
  public answeredQuestion: IQuestion;
  public displayValue: string;

  constructor(
    value: string,
    answeredQuestion: IQuestion,
    displayValue: string
  ) {
    this.value = value;
    this.answeredQuestion = answeredQuestion;
    this.displayValue = displayValue;
  }
}
