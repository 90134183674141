import { PivotItem } from '@fluentui/react';
import React from 'react';

import { IVirtualMachine } from '../../../models/RAM/IVirtualMachine';
import { OsTypeEnum } from '../../../models/RAM/OsTypeEnum';
import { ResourceTypeEnum } from '../../../models/RAM/ResourceTypeEnum';
import { ArcSshContent } from './ArcSshContent';
import { ConnectionTypeEnum } from './Connection.types';
import { RdpContent } from './RdpContent';
import { SshContent } from './SshContent';

const ramConnectionStyles = require('./ram.connection.scss');
export const PivotItemListGenerator = (virtualMachine: IVirtualMachine): JSX.Element[] => {
  if (virtualMachine.resourceType === ResourceTypeEnum.VirtualMachine) {
    return generateForVirtualMachine(virtualMachine);
  } else if (virtualMachine.resourceType === ResourceTypeEnum.ArcMachine) {
    return generateForArcMachine(virtualMachine);
  }

  return [];
};

const generateForVirtualMachine = (virtualMachine: IVirtualMachine): JSX.Element[] => {
  const pivotItemList: JSX.Element[] = [];

  if (virtualMachine.osType === OsTypeEnum.Windows) {
    pivotItemList.push(
      <PivotItem
        key={ConnectionTypeEnum.Rdp}
        headerText={ConnectionTypeEnum.Rdp.toLocaleUpperCase()}
        itemKey={ConnectionTypeEnum.Rdp}
      >
        <div className={ramConnectionStyles.connectionPivotContent}>
          <RdpContent
            publicIpAddress={virtualMachine.publicIpAddress}
            privateIpAddress={virtualMachine.publicIpAddress}
            virtualMachineName={virtualMachine.displayName}
            userName={virtualMachine.username}
          />
        </div>
      </PivotItem>
    );
  }
  if (virtualMachine.osType !== undefined) {
    pivotItemList.push(
      <PivotItem
        key={ConnectionTypeEnum.Ssh}
        headerText={ConnectionTypeEnum.Ssh.toLocaleUpperCase()}
        itemKey={ConnectionTypeEnum.Ssh}
      >
        <div className={ramConnectionStyles.connectionPivotContent}>
          <SshContent
            publicIpAddress={virtualMachine.publicIpAddress}
            osType={virtualMachine.osType}
            virtualMachineName={virtualMachine.displayName}
            resourceGroupName={virtualMachine.resourceGroupName}
            username={virtualMachine.username}
          />
        </div>
      </PivotItem>
    );
  }
  return pivotItemList;
};

const generateForArcMachine = (virtualMachine: IVirtualMachine): JSX.Element[] => {
  const pivotItemList: JSX.Element[] = [];

  pivotItemList.push(
    <PivotItem
      key={ConnectionTypeEnum.Ssh}
      headerText={ConnectionTypeEnum.Ssh.toLocaleUpperCase()}
      itemKey={ConnectionTypeEnum.Ssh}
    >
      <div className={ramConnectionStyles.connectionPivotContent}>
        <ArcSshContent
          subscription={virtualMachine.subscriptionId}
          resourceGroup={virtualMachine.resourceGroupName}
          machineName={virtualMachine.displayName}
          osType={virtualMachine.osType}
        />
      </div>
    </PivotItem>
  );

  return pivotItemList;
};
