import { IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import { TranslationFunction } from 'react-i18next';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { IEntitlementListProps } from './EntitlementList.types';

export const getEntitlementListCommands = (
  t: TranslationFunction,
  props: IEntitlementListProps,
  itemNotSelected?: boolean
): IContextualMenuItem[] => {
  return [
    {
      key: 'Request',
      name: t(LocaleKeys.requestAccess, { context: 'capitalize' }),
      iconProps: {
        iconName: 'add',
      },
      ariaLabel: 'Request access button',
      onClick: () => {
        props.showAddGrantRequest();
        props.recordBusinessEvent('entitlements/click-request-access-button');
      },
      disabled: itemNotSelected,
    },
  ];
};
