
import { css, FontClassNames } from '@fluentui/react';
import * as React from 'react';
import { IRoleScope } from '../../../../models/ELM/IRoleScope';
import { OriginSystem } from '../../../../models/ELM/OriginSystem';
import { getSpinner, LocaleKeys } from '../../../../shared';

import {
  DetailSection,
  IDetailSectionContentProps,
} from '../../DetailSection/DetailSection';
import { PanelOptionDetails } from '../../PanelOptionDetails/PanelOptionDetails';
import { IPackageDetailsProps, IPackageDetailsState } from './PackageDetails.types';

const myAccessStyles = require('../../../../css/myAccess.scoped.scss');

export class PackageDetails extends React.Component<
IPackageDetailsProps,
IPackageDetailsState
> {
  constructor(nextProps: IPackageDetailsProps) {
    super(nextProps);
  }
  public componentDidMount(): void {
    const grantRequest = this.props.mappedGrantRequest!;
    const grant = grantRequest!.accessPackageAssignment;
    const entitlement = grant!.accessPackage!;
    const roleScopes = entitlement && entitlement.accessPackageResourceRoleScopes;
    if (!roleScopes) {
      this.props.getPendingApprovalGrantRequest(grantRequest.id);
    }
  }
  public render(): JSX.Element {
    const { t, onPrevious, onDismiss } = this.props;
    return (
      <PanelOptionDetails
        t={t}
        headerText={LocaleKeys.packageDetails}
        previousLinkText={LocaleKeys.reviewRequest}
        onPrevious={onPrevious}
        onDismiss={onDismiss}
        onRenderBody={this._onRenderBodyPackageDetails}
      />
    );
  }

  private _onRenderBodyPackageDetails = (): JSX.Element => {
    const { t } = this.props;
    let grantRequest = this.props.mappedGrantRequest;
    if (grantRequest) {
      const entitlement = grantRequest!.accessPackage!;
      const roleScopes =
        entitlement && entitlement.accessPackageResourceRoleScopes;

      const originSysMap: Map<string, IDetailSectionContentProps[]> = new Map<
        string,
        IDetailSectionContentProps[]
      >();

      if(this.props.isLoading) {
       return getSpinner(t(LocaleKeys.loading));
      }

      if (roleScopes) {
        roleScopes.forEach((roleScope: IRoleScope) => {
          let role = roleScope.accessPackageResourceRole;
          let resourceType: string = role ? OriginSystem[role.originSystem] || role.originSystem : '';
          let scope = roleScope!.accessPackageResourceScope!;
          let accessPackageResource = scope && scope.accessPackageResource;
          let resourceName: string =
            accessPackageResource !== null ||
            accessPackageResource !== undefined
              ? accessPackageResource.displayName
              : '';
          let resourceDescription: string =
            accessPackageResource!.description || '';
          let roleAssignment: string = role ? role.displayName : '';

          if (!originSysMap.has(resourceType)) {
            originSysMap.set(resourceType, []);
          }

          originSysMap.get(resourceType)!.push(
            {
              primaryContent: resourceName,
              primaryCSS: css(FontClassNames.mediumPlus),
            },
            {
              primaryContent: resourceDescription,
              secondaryContent: `${t(
                LocaleKeys.roleAssigned
              )}: ${roleAssignment}`,
              primaryCSS: css(
                myAccessStyles.semiLightText,
                FontClassNames.small
              ),
              secondaryCSS: css(
                myAccessStyles.marginTopXSmall,
                myAccessStyles.semiLightText,
                FontClassNames.small
              ),
            }
          );
        });
      }

      if (originSysMap.size === 0) {
        return (
          <div
            className={css(
              FontClassNames.smallPlus,
              myAccessStyles.marginTopSmall
            )}
          >
            {t(LocaleKeys.noPermissionsInAccessPackage)}
          </div>
        );
      }

      const detailSectionElements: JSX.Element[] = [];
      originSysMap.forEach(
        (props: IDetailSectionContentProps[], resType: string) => {
          detailSectionElements.push(
            <DetailSection
              t={t}
              heading={`${resType}_plural`}
              headingCSS={css(
                myAccessStyles.semiBoldText,
                FontClassNames.large
              )}
              detailSectionContentProps={props}
            />
          );
        }
      );

      return <div>{detailSectionElements}</div>;
    }

    return (
      <div
        className={css(FontClassNames.smallPlus, myAccessStyles.marginTopSmall)}
      >
        {t(LocaleKeys.noPermissionsInAccessPackage)}
      </div>
    );
  }
}
