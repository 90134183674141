import { ColorClassNames, FontClassNames, ImageFit, Link } from '@fluentui/react';
import { css, Icon, PrimaryButton, Image } from '@fluentui/react';
import * as React from 'react';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');

export interface INoEntitiesProps {
  iconName?: string;
  noRowMessage: string;
  showButton: boolean;
  buttonText?: string;
  onButtonClick?(): void;
  imageSource?: string;
  showLink?: boolean;
  linkText?: string;
  noRowSubMessage?: string;
}

export class NoEntities extends React.Component<INoEntitiesProps, {}> {
  public render(): JSX.Element {
    const { showButton, iconName, noRowMessage, onButtonClick, imageSource, showLink, linkText, noRowSubMessage } = this.props;

    return (
      <div
        className={css(
          myAccessStyles.alignCenter,
          myAccessStyles.marginTopXLarge
        )}
      >
        <Icon
          iconName={iconName}
          className={css(
            myAccessStyles.largeIcon,
            ColorClassNames.neutralPrimary
          )}
        />
          {imageSource && (
          <Image src={imageSource} imageFit={ImageFit.center} alt="Search for more image" height={192}/>)}
        <div>
          <div
            className={css(
              FontClassNames.xLarge,
              myAccessStyles.marginTopMedium,
              myAccessStyles.marginBottomSmall,
              ColorClassNames.neutralPrimary
            )}
          >
            {noRowMessage}
          </div>
          <div
            className={css(
              FontClassNames.large,
              myAccessStyles.marginBottomSmall,
              ColorClassNames.neutralPrimary
            )}
          >
            {noRowSubMessage}
          </div>
          {showButton ? (
            <div
              className={css(
                FontClassNames.medium,
                ColorClassNames.neutralPrimary
              )}
            >
              <PrimaryButton
                text={this.props.buttonText!}
                // tslint:disable-next-line:jsx-no-lambda
                onClick={() => onButtonClick!()}
              />
            </div>
          ) : null}
          {showLink && (
            <Link role='button' onClick={onButtonClick}>{linkText}</Link>
          )}
        </div>
      </div>
    );
  }
}
