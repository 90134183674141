import { IAjaxRequest } from '@microsoft/portal-app/lib/auth/withAuth';
import { IODataValueResponse } from '@microsoft/portal-app/lib/odata-utils';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import 'rxjs/add/observable/concat';
import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs/Observable';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';
import { flatMap } from 'rxjs/operators';
import { v4 } from 'uuid';
import { IGetReviewerDisplayNames } from '../../../models/AccessReviews/IGetReviewerDisplayNames';
import { IReviewerGraphObject } from '../../../models/AccessReviews/IReviewerGraphObject';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { EntityType } from '../../../models/EntityType';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import {
  IEntitlementState,
  IRootEntitlementsState
} from '../../../models/IEntitlementState';
import { getAudience } from '../../../shared/AttachAudience';
import { getReviewerDisplayNamesUrl } from '../../../shared/getAccessReviewsApiUrl';
import { getResponseValue } from '../../../shared/getResponseValue';
import { registry } from '../myAccessRegistry';

export const getReviewerDisplayNamesEpic: Epic<
  IEntitlementAction<AnyPayload>,
  IRootEntitlementsState
> = (
  action$: ActionsObservable<IEntitlementAction<string[]>>,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
    return action$.ofType(EntitlementActions.getReviewerDisplayNames).pipe(
      flatMap((action: IEntitlementAction<string[]>) => {
        const reviewers = action.payload!;

        const getReviewerPayload = {
          types: ['user', 'group'],
          ids: reviewers
        };

        const correlationId = v4();
        const request: IAjaxRequest = {
          method: 'POST',
          url: getReviewerDisplayNamesUrl(),
          body: getReviewerPayload,
          audience: getAudience(EntityType.graphObject),
          headers: {
            'x-ms-client-request-id': correlationId
          }
        };

        return (
          ajax(request)
            .map((payload: IODataValueResponse<ReadonlyArray<IReviewerGraphObject>>) => {
              return {
                type: EntitlementActions.getReviewerDisplayNamesSucceeded,
                payload: {
                  entities: getResponseValue(payload),
                  payload: payload
                }
              };
            })
            // tslint:disable-next-line:no-any
            .catch((error: any) =>
              Observable.of({
                type: EntitlementActions.getReviewerDisplayNamesFailed,
                payload: {
                  correlationId: correlationId,
                  errorCode: error && error.status
                }
              })
            )
        );
      })
    );
  };
registry.addEpic('getReviewerDisplayNamesEpic', getReviewerDisplayNamesEpic);

export const getReviewerDisplayNames = (
  state: IEntitlementState,
  action: IEntitlementAction<IGetReviewerDisplayNames>
): IEntitlementState => {
  if (action.payload === undefined) {
    return state;
  }
  return {
    ...state
  };
};
registry.add(EntitlementActions.getReviewerDisplayNames, getReviewerDisplayNames);

export const getReviewerDisplayNamesSucceeded = (
  state: IEntitlementState,
  action: IEntitlementAction<{
    entities: ReadonlyArray<IReviewerGraphObject>;
  }>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  const { entities } = action.payload!;

  if (!entities.length || entities.length <= 0) {
    return {
      ...state,
    };
  }

  let reviewerDisplayNames: string[] = [];
  entities.forEach((user) => {
    reviewerDisplayNames.push(user.displayName!);
  });

  return {
    ...state,
    reviewerDisplayNames: reviewerDisplayNames
  };
};
registry.add(EntitlementActions.getReviewerDisplayNamesSucceeded, getReviewerDisplayNamesSucceeded);

export const getReviewerDisplayNamesFailed = (
  state: IEntitlementState,
  // tslint:disable-next-line:no-any
  action: IEntitlementAction<Readonly<any>>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  return {
    ...state,
    submitting: false,
    showingConfirmDialog: false,
    showingBulkDecisionDialog: false,
    showingReviewDecisionsDialog: false,
    showingAcceptRecommendations: false,
    showingResetDecisions: false
  };
};
registry.add(EntitlementActions.getReviewerDisplayNamesFailed, getReviewerDisplayNamesFailed);
