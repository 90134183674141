import { Announced, getTheme } from '@fluentui/react';
import { IButton, IconButton } from '@fluentui/react/lib/Button';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { useId } from '@fluentui/react-hooks';
import * as React from 'react';
import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';

import { useTranslation } from '../../../hooks';
import { LocaleKeys } from '../../../shared';

/**
 * Props for CopyButton Component
 */
export interface CopyButtonProps {
  /**
   * Value to be copied
   */
  value: string;
  /**
   * Called when the value is copied
   */
  readonly onCopy?: (copied: boolean) => void;
}

/**
 * CopyableTextField component.
 *
 */
export const CopyButton: FunctionComponent<CopyButtonProps> = ({ value, onCopy }: CopyButtonProps) => {
  const t = useTranslation();
  const [copyIconToolTipText, setCopyIconTooltipText] = useState<string>(t(LocaleKeys.copyToClipboardCopyButton));
  const [announcedMessage, setAnnouncedMessage] = useState<string | undefined>(undefined);
  const buttonRef = useRef<IButton>(null);
  const isMounted = useRef(false);
  const buttonId = useId('copy-button');
  const announcedId = useId('announced');

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const updateCopyStatusMessage = useCallback(
    (successStatus: boolean) => {
      onCopy?.(successStatus);

      // update tooltip and announced message based on copy to clipboard success status
      const statusMessage = successStatus ? t(LocaleKeys.copiedCopyButton) : t(LocaleKeys.failedToCopyCopyButton);
      setCopyIconTooltipText(statusMessage);
      setAnnouncedMessage(statusMessage);

      // reset tooltip and announced message
      setTimeout(() => {
        if (isMounted.current) {
          setCopyIconTooltipText(t(LocaleKeys.copyToClipboardCopyButton));
          setAnnouncedMessage(undefined);
        }
      }, timeout);

      buttonRef.current?.focus();
    },
    [onCopy, t]
  );

  const copyToClipboard = useCallback(() => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        updateCopyStatusMessage(true);
      })
      .catch(() => {
        updateCopyStatusMessage(false);
      });
  }, [updateCopyStatusMessage, value]);

  return (
    <TooltipHost content={copyIconToolTipText}>
      <IconButton
        id={buttonId}
        componentRef={buttonRef}
        ariaLabel={t(LocaleKeys.copyToClipboardCopyButton)}
        iconProps={{ iconName: 'Copy' }}
        styles={iconButtonStyles}
        style={{
          position: 'sticky',
          color: theme.palette.neutralDark,
          backgroundColor: 'transparent'
        }}
        onClick={copyToClipboard}
      />
      {announcedMessage !== undefined ? <Announced id={announcedId} message={announcedMessage} /> : null}
    </TooltipHost>
  );
};

const theme = getTheme();
const iconButtonStyles = {
  root: {
    selectors: {
      ':hover': {
        border: `2px solid ${theme.palette.blue}`
      }
    }
  }
};

const timeout = 1 * 1000;
