
import { css, Icon, Link } from '@fluentui/react';
import * as React from 'react';
import { InjectedTranslateProps } from 'react-i18next';
import { LocaleKeys } from '../../../shared/LocaleKeys';

const ChevronButtonStyles = require('./ChevronButton.scoped.scss');

export interface IChevronButtonProps extends InjectedTranslateProps {
  onClick(): void;
  noRotate?: boolean;
}

export interface IChevronButtonState {
  isExpanded: boolean;
}

export class ChevronButton extends React.Component<
  IChevronButtonProps,
  IChevronButtonState
> {
  constructor(nextProps: IChevronButtonProps) {
    super(nextProps);
    this.state = {
      isExpanded: false
    };
  }

  public render(): JSX.Element {
    const { t } = this.props;
    return (
      <Link onClick={this._onClick}>
        <Icon
          ariaLabel={
            this.state.isExpanded
              ? t(LocaleKeys.collapse)
              : t(LocaleKeys.expand)
          }
          iconName={this.state.isExpanded ? 'ChevronUp' : 'ChevronDown'}
          className={css(ChevronButtonStyles.expandArrow, 'ms-ExpandArrowIcon')}
        />
      </Link>
    );
  }

  private _onClick = (): void => {
    this.props.onClick();

    if (this.props.noRotate === undefined || !this.props.noRotate) {
      this.setState({
        isExpanded: !this.state.isExpanded
      });
    }
  }
}
