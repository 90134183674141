import { IPageData } from '../../../models/IPageData';
import {
  IPimAssignmentScheduleInstance,
  IPimEligibilityScheduleInstance
} from '../../../models/RAM/IPimActivationRequestParameters';
import { IPimPendingApprovalRequest } from '../../../models/RAM/IPimPendingApprovalRequest';
import {
  IAssignmentSchedule,
  IEligibilitySchedule,
  IVirtualMachine,
  IVirtualMachineInResponse
} from '../../../models/RAM/IVirtualMachine';
import { OsTypeEnum } from '../../../models/RAM/OsTypeEnum';
import { PimRoleAssignmentStatusEnum } from '../../../models/RAM/PimRoleAssignmentStatusEnum';
import { azureVMTypeStr, ResourceTypeEnum } from '../../../models/RAM/ResourceTypeEnum';
import { GetEntityList } from '../../../shared/GetEntityList';

export const convertToTitleCase = (text: string): string => {
  return text.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
};

export const replaceBackslashes = (str: string): string => {
  return str.replace(/\\/g, '/');
};

export interface IGetVirtualMachineListContext {
  pendingApprovalList: readonly IPimPendingApprovalRequest[];
  isFiltering: boolean;
}

export const getVirtualMachineList = (
  virtualMachines: IPageData<IVirtualMachineInResponse>,
  context: IGetVirtualMachineListContext
): IVirtualMachine[] => {
  const entitiesList = GetEntityList(virtualMachines, context.isFiltering);
  return entitiesList.filteredEntities
    .map((key: string) => {
      const entity = virtualMachines.entitiesById.get(key);
      return entity === undefined ? undefined : convertToVm(entity, context.pendingApprovalList);
    })
    .filter((vm) => vm !== undefined) as IVirtualMachine[];
};

const convertToVm = (
  entity: IVirtualMachineInResponse,
  pendingApprovalList: readonly IPimPendingApprovalRequest[]
): IVirtualMachine => {
  return {
    id: entity.id,
    displayName: entity.displayName,
    resourceType: getResourceType(entity.properties.vmType),
    subscriptionId: getSubscriptionId(entity.properties.externalId),
    subscriptionName: entity.properties.subscriptionName,
    resourceGroupName: entity.properties.resourceGroupName,
    // TODO: For now we only support Azure environment, do we need to show Azure and Azure Arc?
    environment: 'Azure',
    osType: getOsType(entity.properties.osType),
    assignmentStatus: getAssignmentStatus(entity, pendingApprovalList),
    favorite: false, // TODO: Need to update this when favorite API is ready
    username: entity.properties.adminUsername,
    publicIpAddress: entity.properties.ipAddress,
    eligibleRoleAssignmentList: getEligibleRoleAssignmentList(entity.eligibilitySchedules),
    activeRoleAssignmentList: getActiveRoleAssignmentList(entity.assignmentSchedules)
  };
};

// TODO: Will add more type checks
const getResourceType = (resourceType: string): ResourceTypeEnum => {
  if (resourceType.toLowerCase() === azureVMTypeStr) {
    return ResourceTypeEnum.VirtualMachine;
  }
  return ResourceTypeEnum.ArcMachine;
};

const getSubscriptionId = (externalId: string): string => {
  return externalId.split('/')[2];
};

const getOsType = (osType: string): OsTypeEnum | undefined => {
  if (osType.toLowerCase() === 'windows') {
    return OsTypeEnum.Windows;
  } else if (osType.toLowerCase() === 'linux') {
    return OsTypeEnum.Linux;
  }
  return undefined;
};

const getAssignmentStatus = (
  entity: IVirtualMachineInResponse,
  pendingApprovalList: readonly IPimPendingApprovalRequest[]
): PimRoleAssignmentStatusEnum => {
  if (hasActiveRoleAssignment(entity.assignmentSchedules)) {
    return PimRoleAssignmentStatusEnum.Active;
  }

  if (hasPendingApproval(entity, pendingApprovalList)) {
    return PimRoleAssignmentStatusEnum.PendingApproval;
  }

  return PimRoleAssignmentStatusEnum.Eligible;
};

const hasActiveRoleAssignment = (assignmentSchedules: readonly IAssignmentSchedule[]): boolean => {
  return assignmentSchedules.length > 0;
};

const hasPendingApproval = (
  entity: IVirtualMachineInResponse,
  pendingApprovalList: readonly IPimPendingApprovalRequest[]
): boolean => {
  const pendingLinkedEligibleScheduleIdsSet = new Set(
    pendingApprovalList.map((item) => item.linkedRoleEligibilityScheduleId.split('/').pop())
  );
  const eligibilityScheduleList = entity.eligibilitySchedules;
  const hasPendingApproval = eligibilityScheduleList.some((eligibilitySchedule) =>
    pendingLinkedEligibleScheduleIdsSet.has(eligibilitySchedule.roleEligibilityScheduleId)
  );

  return hasPendingApproval;
};

const getEligibleRoleAssignmentList = (
  eligibilitySchedules: readonly IEligibilitySchedule[]
): readonly IPimEligibilityScheduleInstance[] => {
  return eligibilitySchedules.map((eligibilitySchedule) => {
    return {
      id: eligibilitySchedule.id,
      directoryScopeId: eligibilitySchedule.scope.id,
      roleDefinitionId: eligibilitySchedule.actionInfo.roles[0].id,
      principalId: eligibilitySchedule.identityInfo.externalId,
      roleDefinition: {
        id: eligibilitySchedule.actionInfo.roles[0].id,
        displayName: eligibilitySchedule.actionInfo.roles[0].displayName
      },
      roleEligibilityScheduleId: eligibilitySchedule.roleEligibilityScheduleId
    };
  });
};

const getActiveRoleAssignmentList = (
  assignmentSchedules: readonly IAssignmentSchedule[]
): readonly IPimAssignmentScheduleInstance[] => {
  return assignmentSchedules.map((assignmentSchedule) => {
    return {
      id: assignmentSchedule.id,
      linkedRoleEligibilityScheduleId: assignmentSchedule.linkedRoleEligibilityScheduleId,
      linkedRoleEligibilityScheduleInstanceId: assignmentSchedule.linkedRoleEligibilityScheduleInstanceId,
      createdOn: assignmentSchedule.createdOn,
      status: assignmentSchedule.status,
      memberType: assignmentSchedule.memberType,
      startDateTime: assignmentSchedule.startDateTime,
      endDateTime: assignmentSchedule.endDateTime,
      RoleAssignmentScheduleId: assignmentSchedule.RoleAssignmentScheduleId,
      RoleAssignmentOriginId: assignmentSchedule.RoleAssignmentOriginId,
      AssignmentType: assignmentSchedule.AssignmentType
    };
  });
};
