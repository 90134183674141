import { TextField } from '@fluentui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { useTranslation } from '../../../hooks';
import { LocaleKeys } from '../../../shared';
import { isEligibilityScopedAtResource } from '../VmList/VmList.columns';
import { QuickActivationDialogRole } from './QuickActivationDialog';

interface ActivationRequirementsContentProps {
  role: QuickActivationDialogRole;
  isJustificationRequired?: boolean;
  editJustification: (newValue: string) => void;
  isMfaRequirementMet?: boolean;
  isTicketingRequired?: boolean;
  editTicketing: (newTicketSystem?: string, newTicketNumber?: string) => void;
}

export const ActivationRequirementsContent: React.FunctionComponent<ActivationRequirementsContentProps> = (
  props: ActivationRequirementsContentProps
) => {
  const t = useTranslation();
  const [isJustificationValid, setIsJustificationValid] = useState<boolean>(false);
  const [isTicketingValid, setIsTicketingValid] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>(t(LocaleKeys.ramQuickActivationDialogErrorMessage));
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const isRoleScopedAtResource = isEligibilityScopedAtResource(props.role.roleScope);

  useEffect(() => {
    if (!isJustificationValid && isDirty) {
      setErrorMsg(t(LocaleKeys.ramQuickActivationDialogErrorMessage));
    } else {
      setErrorMsg('');
    }
  }, [isJustificationValid, isDirty, t]);

  // TODO: Adjust justification section by checking if it is required, will be done in a follow up PR
  //  https://learn.microsoft.com/en-us/rest/api/authorization/privileged-role-policy-rest-sample
  const justification = props.isJustificationRequired && (
    <>
      <TextField
        label={t(LocaleKeys.ramQuickActivationDialogJustificationSectionTitle)}
        ariaLabel={t(LocaleKeys.ramQuickActivationDialogJustificationSectionTip)}
        aria-required="true"
        required
        multiline
        autoAdjustHeight
        maxLength={500}
        onBlur={() => {
          setIsDirty(true);
        }}
        onChange={(_ev, newValue) => {
          setIsJustificationValid(newValue !== undefined && newValue.length > 0);
          props.editJustification(newValue ?? '');
        }}
        errorMessage={errorMsg}
        disabled={!props.isMfaRequirementMet || isRoleScopedAtResource}
      />
      <div>{t(LocaleKeys.ramQuickActivationDialogJustificationSectionTip)}</div>
    </>
  );

  const ticketing = props.isTicketingRequired && (
    <>
      <TextField
        label={t(LocaleKeys.ramQuickActivationDialogTicketingSectionSystemTitle)}
        ariaLabel={t(LocaleKeys.ramQuickActivationDialogTicketingSectionSystemAria)}
        aria-required="true"
        onChange={(_ev, newValue) => {
          props.editTicketing(newValue?.trim() ?? '');
        }}
        disabled={!props.isMfaRequirementMet || isRoleScopedAtResource}
      />
      <TextField
        label={t(LocaleKeys.ramQuickActivationDialogTicketingSectionNumberTitle)}
        ariaLabel={t(LocaleKeys.ramQuickActivationDialogTicketingSectionNumberAria)}
        aria-required="true"
        required
        onChange={(_ev, newValue) => {
          setIsTicketingValid(newValue !== undefined && newValue.trim() !== '');
          props.editTicketing(undefined, newValue?.trim() ?? '');
        }}
        errorMessage={!isTicketingValid ? t(LocaleKeys.ramQuickActivationDialogTicketingSectionNumberError) : undefined}
        disabled={!props.isMfaRequirementMet || isRoleScopedAtResource}
      />
    </>
  );

  return (
    <>
      {ticketing}
      {justification}
    </>
  );
};
