import { EntitlementActions } from '../../../models/EntitlementActions';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState } from '../../../models/IEntitlementState';
import { registry } from '../myAccessRegistry';

export const showBulkActionDialog = (
  state: IEntitlementState,
  action: IEntitlementAction<boolean>
): IEntitlementState => {
  return {
    ...state,
    showingBulkActionDialog: action.payload
  };
};
registry.add(EntitlementActions.showBulkActionDialog, showBulkActionDialog);
