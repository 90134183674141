import { FontClassNames } from '@fluentui/react';
import { css } from '@fluentui/react';
import {
  IWithResponsiveModeState,
  withResponsiveMode,
} from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import * as React from 'react';
import { InjectedTranslateProps, TranslationFunction } from 'react-i18next';
import { DecisionType, IGrantRequest } from '../../../models';
import { ApprovalStatus } from '../../../models/RequestApprovals/IApproval';
import * as ApprovalHelper from '../../../shared/approvalPersonaHelper';
import { FormatDateTime } from '../../../shared/FormatDateTime';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { DecisionActivityCard } from '../../Shared/DecisionActivityCard/DecisionActivityCard';
import { SimpleHoverCard } from '../../Shared/SimpleHoverCard/SimpleHoverCard';
import {
  GrantRequestApprovalHistoryActivityItem
} from '../GrantRequestApprovalHistoryActivityItem/GrantRequestApprovalHistoryActivityItem';
import {
  GrantRequestEscalationApprovalHistory
} from '../GrantRequestEscalationApprovalHistory/GrantRequestEscalationApprovalHistory';
import { GrantRequestMultiStageApprovalHistory } from '../GrantRequestMultiStageApprovalHistory/GrantRequestMultiStageApprovalHistory';
import { ApprovalHistoryActivityItem } from '../../../components/RequestApprovals/ApprovalHistoryActivityItem/ApprovalHistoryActivityItem';
import { DecisionHoverCard } from '../../../components/Shared/DecisionHoverCard/DecisionHoverCard';
import { RequestType, SimpleRequestType, getSimpleRequestTypeFromRequestType } from '../../../models/ELM/RequestType';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');

export interface IGrantRequestApprovalHistoryProps
  extends IWithResponsiveModeState,
  InjectedTranslateProps {
  grantRequest: IGrantRequest | undefined;
  displayHeading?: boolean;
  t: TranslationFunction;
  enableApproverRevoke?: boolean;
}

@withResponsiveMode
export class GrantRequestApprovalHistory extends React.Component<IGrantRequestApprovalHistoryProps> {
  constructor(nextProps: IGrantRequestApprovalHistoryProps) {
    super(nextProps);
  }

  public render(): JSX.Element {
    const { t, grantRequest, displayHeading } = this.props;
    if (!grantRequest) {
      return <div />;
    }

    return (
      <div>
        {displayHeading ? (
          <div
            className={css(
              FontClassNames.xLarge,
              myAccessStyles.marginTopMedium
            )}
          >
            {t(LocaleKeys.requestActivity)}
          </div>
        ) : null}
        <div
          className={css(
            myAccessStyles.marginTopSmall,
            myAccessStyles.marginBottomMedium
          )}
        >
          {this._getApprovalHistory(grantRequest)}
          {this.props.enableApproverRevoke && this._getAccessRemovalHistoryItem(grantRequest)}
        </div>
      </div>
    );
  }

  private _getApprovalHistory(grantRequest: IGrantRequest): JSX.Element {
    if (!grantRequest) {
      return <div />;
    }

    const { t } = this.props;

    if (ApprovalHelper.getTotalStagesOfGrantRequest(grantRequest) > 1) {
      return (
        <GrantRequestMultiStageApprovalHistory grantRequest={grantRequest} t={this.props.t} />
      );
    }

    if (ApprovalHelper.isGrantRequestEscalated(grantRequest)) {
      return (
        <GrantRequestEscalationApprovalHistory grantRequest={grantRequest} t={this.props.t} />
      );
    }

    if (ApprovalHelper.isSingleStageNormalGrantRequest(grantRequest)) {
      // if decision is already made
      if (grantRequest.approval!.steps && grantRequest.approval!.steps.length > 0) {
        // for single stage there should only be one stage
        const approvalStep = grantRequest.approval!.steps[0];
        if (ApprovalStatus[approvalStep.reviewResult] === ApprovalStatus.NotReviewed) {
          // reviewResult not Reviewed is pending approval.
          let grantRequestExpirationDate = ApprovalHelper.getDueDate(grantRequest);
          return (
            <GrantRequestApprovalHistoryActivityItem
              t={this.props.t}
              hoverCardDom={
                <SimpleHoverCard
                  localizedDescription={t(LocaleKeys.normalPendingApprovalMessage, {
                    expirationDateTime: FormatDateTime(grantRequestExpirationDate),
                  })}
                  localizedTitle={t(LocaleKeys.pendingApproval, {
                    context: 'capitalize',
                  })}
                />
              }
              dateTime={`${this.props.t(LocaleKeys.due)} ${FormatDateTime(grantRequestExpirationDate)}`}
              title={t(LocaleKeys.pendingApproval, {
                context: 'capitalize',
              })}
              iconName={'Sync'}
            />
          );
        } else {
          return (
            <DecisionActivityCard
              t={t}
              decision={approvalStep}
              requestExpirationDate={grantRequest && ApprovalHelper.getGrantRequestCompletionDate(grantRequest)}
              cardDescription={''}
            />
          );
        }
      }
    }
    return <div />;
  }

  private _getAccessRemovalHistoryItem(grantRequest: IGrantRequest): JSX.Element {
    const { t } = this.props;

    const accessRemovedActivity =
      grantRequest.history.filter(a => getSimpleRequestTypeFromRequestType(RequestType[a.action]) === SimpleRequestType.Remove)
    if (!accessRemovedActivity || !accessRemovedActivity[0]) {
      return <div />;
    }
    return (
      <ApprovalHistoryActivityItem
        t={t}
        title={RequestType[accessRemovedActivity[0].action] !== RequestType.SystemRemove ? t('requestActivity.accessRemoved', {
          actorDisplayName: accessRemovedActivity[0].actorDisplayName,
        }) : t(LocaleKeys.accessRemoved)}
        cardAdditionalDescription={''}
        hoverCardDom={
          RequestType[accessRemovedActivity[0].action] !== RequestType.SystemRemove ?
            <DecisionHoverCard
              t={t}
              decisionType={DecisionType.AccessRemoved}
              actorDisplayName={accessRemovedActivity[0].actorDisplayName}
              actorEmail={accessRemovedActivity[0].actorPrincipalName}
              detail={accessRemovedActivity[0].detail}
            /> : undefined
        }
        dateTime={FormatDateTime(accessRemovedActivity[0].actionDateTime)}
        iconName={'Cancel'}
      />);
  }
}
