// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../dev/node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../dev/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div._121T2gkvX3JwVQuOdvosri{display:grid;place-items:center}\n", "",{"version":3,"sources":["webpack://./src/components/RAM/RamErrorModals/ram.errorDialog.scss"],"names":[],"mappings":"AAAA,4BACE,YAAa,CACb,kBAAmB","sourcesContent":["div.center {\n  display: grid;\n  place-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"center": "_121T2gkvX3JwVQuOdvosri"
};
module.exports = ___CSS_LOADER_EXPORT___;
