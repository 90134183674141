import {
  ColorClassNames,
  ColumnActionsMode,
  CommandBar,
  css,
  FontClassNames,
  Icon,
  IconButton,
  KeyCodes,
  Link,
  MessageBar,
  MessageBarType,
  SelectionMode,
  Callout,
  DirectionalHint,
} from '@fluentui/react';
import { ResponsiveMode, withResponsiveMode } from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import { ErrorBanner } from '@microsoft/portal-app/lib/Banners/ErrorBanner';
import * as React from 'react';
import { TranslationFunction } from 'react-i18next';
import { isNullOrUndefined } from 'util';

import { EntitlementActions } from '../../../models';
import {
  AccessReviewPartnerGuid,
  AccessReviewProviderType,
  AccessReviewType
} from '../../../models/AccessReviews/AccessReviewType';
import { IAccessReviewDecision } from '../../../models/AccessReviews/IAccessReviewDecision';
import {
  AccessReviewInsight,
  IAccessReviewDecisionInsights,
  IAccessReviewInsight
} from '../../../models/AccessReviews/IAccessReviewInsight';
import { ISubmitDecision } from '../../../models/AccessReviews/ISubmitDecision';
import { IEntity } from '../../../models/ELM/IEntity';
import { EntityType } from '../../../models/EntityType';
import { IListColumn } from '../../../models/IListColumn';
import {
  DecisionResourceType,
  DecisionType,
  ReviewDecisionSubjectType,
  ReviewScopeResourceType
} from '../../../models/RequestApprovals/DecisionType';
import { RecommendationType } from '../../../models/RequestApprovals/RecommendationType';
import { checkFeatureAccess } from '../../../shared';
import { asLocalizedText } from '../../../shared/asLocalizedText';
import { getUserFromAuth } from '../../../shared/authHelper';
import { allRolesReviewTypeId, armAllRolesReviewTypeId, selfReviewTypeId } from '../../../shared/constants';
import { FormatDate } from '../../../shared/FormatDateTime';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { getNewColumnsOnColumnClicked } from '../../../shared/sortingHelper';
import { getInlineSpinner, getSpinner } from '../../../shared/spinner';
import { getOneLayerUp } from '../../../shared/urlHelper';
import { ColumnValue } from '../../Shared/ColumnValue/ColumnValue';
import { InfinityList } from '../../Shared/InfinityList/InfinityList';
import { AcceptRecommendations } from '../AcceptRecommendations/AcceptRecommendations';
import { ConnectedAccessReviewDetails } from '../AccessReviewDetails';
import { BulkDecisionDialog } from '../BulkDecisionDialog/BulkDecisionDialog';
import { ReviewDecisionsDialog } from '../BulkDecisionDialog/ReviewDecisionsDialog';
import { ConnectedDecisionDetails } from '../DecisionDetails';
import { ConnectedDecisionsFilter } from '../DecisionsFilter';
import { ResetDecisions } from '../ResetDecisions/ResetDecisions';
import { SelfReview } from '../SelfReview/SelfReview';
import { ConnectedUserCentricPanel } from '../UserCentricPanel';
import {
  getAccessReviewDecisionsListCommands,
  getAccessReviewDecisionsListFarCommands
} from './AccessReviewDecisionsList.commands';
import { IAccessReviewDecisionsListProps, IAccessReviewDecisionsListState } from './AccessReviewDecisionsList.types';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');
const myAccessListStyles = require('../../../css/myAccessList.scoped.scss');
const globalStyles = require('../../../css/global.scss');

/* Represent the list of decisions for a review. */
@withResponsiveMode
export class AccessReviewDecisionsList extends React.Component<
  IAccessReviewDecisionsListProps,
  IAccessReviewDecisionsListState
> {
  constructor(nextProps: IAccessReviewDecisionsListProps) {
    super(nextProps);
    nextProps.showFiltersIcon(false);
    this.state = {
      entitlement: undefined,
      currentDecision: undefined,
      decisionIndex: 0,
      isLoadingDetails: false,
      commands: getAccessReviewDecisionsListCommands(this.props.t, this.props, undefined),
      farCommands: getAccessReviewDecisionsListFarCommands(this.props.t, this.props),
      columns: this._getAccessReviewDecisionsListColumns(false, this._getResponsiveMode(), this.props.t),
      byodColumns: this._getAccessReviewDecisionsListColumns(true, this._getResponsiveMode(), this.props.t),
      clearSelection: false,
      isUserCentric: false,
      resourceId: '',
      isRecommendationsTooltipVisible: false,
      isRecommendationsTooltipButtonFocused: false
    };
  }

  public componentWillReceiveProps(nextProps: IAccessReviewDecisionsListProps): void {
    if (
      (nextProps.currentAccessReview &&
        this.props.currentAccessReview &&
        nextProps.currentAccessReview.id !== this.props.currentAccessReview.id) ||
      (nextProps.accessReviewDecisionList &&
        this.props.accessReviewDecisionList &&
        nextProps.accessReviewDecisionList.length !== this.props.accessReviewDecisionList.length) ||
      (nextProps.decisionsCriteria &&
        this.props.decisionsCriteria &&
        nextProps.decisionsCriteria.typeId !== this.props.decisionsCriteria?.typeId)
    ) {
      let newResourceId = this.state.resourceId;
      if (
        nextProps.decisionsCriteria &&
        nextProps.decisionsCriteria.resourceId &&
        nextProps.decisionsCriteria.resourceId.length > 0
      ) {
        newResourceId = nextProps.decisionsCriteria.resourceId;
      }
      this.setState({
        commands: getAccessReviewDecisionsListCommands(nextProps.t, nextProps, []),
        farCommands: getAccessReviewDecisionsListFarCommands(this.props.t, this.props),
        accessReviewSelectedUsers: [],
        resourceId: newResourceId
      });
    }
  }

  public componentDidMount(): void {
    this.props.setSearchContext(EntitlementActions.searchReviewDecisionsOnServer);
    this.props.getCurrentReview();
    this.props.getDecisionsCriteria();

    this.setState({
      isLoadingDetails: true
    });
  }

  public componentWillUnmount(): void {
    if (this.props.showingReviewDecisionsFilter) {
      this.props.dismissReviewDecisionsFilter();
    }

    if (this.props.showingReviewDecisionsDialog) {
      this.props.dismissReviewDecisionsDialog();
    }

    if (this.props.showingReviewDetails) {
      this.props.dismissReviewDetails();
    }

    if (this.props.showingDecisionDetails) {
      this.props.dismissDecisionDetails();
    }

    if (this.props.showingBulkDecisionDialog) {
      this.props.dismissBulkDecisionDialog();
    }

    if (this.props.showingResetDecisions) {
      this.props.dismissResetDecisions();
    }

    if (this.props.showingAcceptRecommendations) {
      this.props.dismissAcceptRecommendations();
    }
  }

  public componentDidUpdate(prevProps: IAccessReviewDecisionsListProps): void {
    if (
      prevProps.pageMetaData.filteredEntityCount !== this.props.pageMetaData.filteredEntityCount &&
      (prevProps.pageMetaData.isFilteredEntitiesFullyCached || prevProps.pageMetaData.isAllEntitiesFullyCached)
    ) {
      this.setState({
        clearSelection: true
      });
    } else if (this.state.clearSelection) {
      this.setState({
        clearSelection: false
      });
    }

    // Get the decision history for every decision, assuming it is not a first-stage review
    if (
      (prevProps.accessReviewDecisionList.length !== this.props.accessReviewDecisionList.length ||
        prevProps.currentAccessReview !== this.props.currentAccessReview) &&
      this.props.currentAccessReview &&
      this.props.currentAccessReview.stage! > 0 &&
      this.props.currentAccessReview.settings!.decisionHistoriesForDecisionMakersEnabled
    ) {
      this.props.accessReviewDecisionList.forEach((decision: IAccessReviewDecision) => {
        this.props.getDecisionHistory(this.props.reviewId, decision.id);
      });
    }

    if (
      this.state.decisionIndex &&
      prevProps.accessReviewDecisionList[this.state.decisionIndex] !==
      this.props.accessReviewDecisionList[this.state.decisionIndex]
    ) {
      this.setState({
        currentDecision: this.props.accessReviewDecisionList[this.state.decisionIndex]
      });
    }

    if (prevProps.responsiveMode !== this.props.responsiveMode) {
      this._resetColumns(this.state.isUserCentric);
    }

    // If user-centric, get secondary decisions for each grouped decision
    if (prevProps.accessReviewDecisionList.length !== this.props.accessReviewDecisionList.length) {
      if (this.state.isUserCentric) {
        this.props.accessReviewDecisionList.forEach((decision: IAccessReviewDecision) => {
          if (isNullOrUndefined(decision.secondaryDecisions) || decision.secondaryDecisions.length < 1) {
            this.props.getSecondaryDecisions(decision.id);
          }
        });
      }
    }

    if (prevProps.showingBulkDecisionDialog && !this.props.showingBulkDecisionDialog) {
      if (this.state.isUserCentric && this.state.currentDecision) {
        this.props.getSecondaryDecisions(this.state.currentDecision.id);
      }
    }

    if (this.state.isLoadingDetails) {
      if (
        !this.props.entityLoadingList[EntityType.accessReviews] &&
        !this.props.entityLoadingList[EntityType.decisionsCriteria]
      ) {
        let isUserCentricReview: boolean | undefined =
          this.props.decisionsCriteria &&
          (this.props.decisionsCriteria.typeId === allRolesReviewTypeId ||
            this.props.decisionsCriteria.typeId === armAllRolesReviewTypeId);
        if (isUserCentricReview === undefined) {
          isUserCentricReview = false;
        }

        this.setState(
          {
            isLoadingDetails: false,
            isUserCentric: isUserCentricReview
          },
          () => {
            this.props.refreshReviewDecisions(EntityType.accessReviews, isUserCentricReview ? 'user-centric' : '');
            this._resetColumns(isUserCentricReview);
          }
        );
      }
    }
  }

  public render(): JSX.Element {
    const {
      isLoading,
      isRefreshing,
      errorHasOccurred,
      errorCode,
      isLoadingMore,
      showingBulkDecisionDialog,
      showingReviewDecisionsDialog,
      showingResetDecisions,
      showingAcceptRecommendations,
      t,
      isTenantWhitelisted,
      isSubmitting,
      responsiveMode,
      bulkDecisionType,
      currentAccessReview,
      decisionsCriteria
    } = this.props;

    if (isRefreshing || this.state.isLoadingDetails) {
      return getSpinner(
        t(LocaleKeys.loadingPage, {
          pageName: t(LocaleKeys.review)
        })
      );
    }

    if (
      currentAccessReview === undefined ||
      decisionsCriteria === undefined ||
      this.props.accessReviewDecisionList.length < 1
    ) {
      return getSpinner(
        t(LocaleKeys.loadingPage, {
          pageName: t(LocaleKeys.review)
        })
      );
    }

    if (errorHasOccurred) {
      if (!isTenantWhitelisted) {
        return <ErrorBanner text={t(LocaleKeys.tenantNotWhitelistedMessage)} />;
      }

      if (errorCode === 0 || errorCode === 429) {
        return (
          <main data-automation-id="AccessReviewsListPage" className={css(globalStyles.detailsPage)}>
            <ErrorBanner text={t(LocaleKeys.throttleError)} />
          </main>
        );
      }

      return (
        <ErrorBanner
          text={t(LocaleKeys.errorMessage)}
          onAction={this.props.getEntities}
          actionText={t(LocaleKeys.retry)}
        />
      );
    }

    const accessReviewDecisionList = this.props.accessReviewDecisionList as IAccessReviewDecision[];
    const { isSearching, isFiltering, pageMetaData, searchTerm } = this.props;

    const isSelfReview: boolean | undefined =
      currentAccessReview &&
      currentAccessReview.policy &&
      currentAccessReview.policy.decisionMakerCriteria.length > 0 &&
      currentAccessReview.policy.decisionMakerCriteria[0].typeId.toLowerCase() === selfReviewTypeId &&
      accessReviewDecisionList.length === 1 &&
      this.state.isUserCentric !== true;

    const selectionMode = this.state.isUserCentric ? SelectionMode.none : SelectionMode.multiple;

    const isPim: boolean | undefined =
      currentAccessReview &&
      (currentAccessReview.partnerId === AccessReviewPartnerGuid.AadRoles.toLowerCase() ||
        currentAccessReview.partnerId === AccessReviewPartnerGuid.Rbac.toLowerCase());

    const isByod: boolean | undefined =
      currentAccessReview && !isNullOrUndefined(currentAccessReview.customDataProvider);

    const filteredCount = pageMetaData.filteredEntityCount ? pageMetaData.filteredEntityCount : 0;

    const showNoEntities =
      pageMetaData.allEntityCount === 0 && !this.props.isRefreshing && !isFiltering && !isSearching;

    const showNoFilteredResults = !isLoading && (isFiltering || isSearching) && filteredCount === 0;

    const showLoadMore =
      !pageMetaData.isAllEntitiesFullyCached &&
      !isSelfReview &&
      !isLoading &&
      !isLoadingMore &&
      !pageMetaData.isFilteredEntitiesFullyCached;

    // Display entity as whichever property is populated in decisionsCriteria
    let reviewedEntity = decisionsCriteria.groupDisplayName;
    let entityType = AccessReviewType.Group;
    if (decisionsCriteria.appDisplayName) {
      reviewedEntity = decisionsCriteria.appDisplayName;
      entityType = AccessReviewType.App;
    } else if (decisionsCriteria.accessPackageDisplayName) {
      reviewedEntity = decisionsCriteria.accessPackageDisplayName;
      entityType = AccessReviewType.AccessPackage;
    } else if (decisionsCriteria.roleDisplayName) {
      reviewedEntity = decisionsCriteria.roleDisplayName;
      if (decisionsCriteria.providerType === AccessReviewProviderType.AadRole) {
        entityType = AccessReviewType.AadRole;
      } else {
        entityType = AccessReviewType.Rbac;
      }
    }

    let decisionCriteriaSubjectType = t(LocaleKeys.user, { context: 'plural' });

    if (currentAccessReview) {
      if (decisionsCriteria.subjectType === ReviewDecisionSubjectType.ServicePrincipal.toString()) {
        decisionCriteriaSubjectType = t(LocaleKeys.servicePrincipal, { context: 'plural' });
      } else if (decisionsCriteria.subjectType === ReviewDecisionSubjectType.UserAndGroup.toString()) {
        decisionCriteriaSubjectType = t(LocaleKeys.userAndGroup, { context: 'plural' });
      }
    }

    let scopeResourceType = t(LocaleKeys.resource);
    let scopeResourceName = t(LocaleKeys.resource);

    if (currentAccessReview) {
      if (decisionsCriteria.subjectType === ReviewDecisionSubjectType.ServicePrincipal.toString()) {
        decisionCriteriaSubjectType = t(LocaleKeys.servicePrincipal, { context: 'plural' });
      } else if (
        decisionsCriteria.subjectType === ReviewDecisionSubjectType.UserAndGroup.toString() ||
        decisionsCriteria.includeSharedChannelMemberships
      ) {
        // Shared Channel reviews may include team (group) and user while subjectType set as user
        decisionCriteriaSubjectType = t(LocaleKeys.userAndGroup, { context: 'plural' });
      }

      scopeResourceName = decisionsCriteria.resourceDisplayName;
      switch (decisionsCriteria.resourceType) {
        case ReviewScopeResourceType.Subscription: {
          scopeResourceType = t(LocaleKeys.subscription);
          break;
        }
        case ReviewScopeResourceType.ManagementGroup: {
          scopeResourceType = t(LocaleKeys.managementGroup);
          break;
        }
        case ReviewScopeResourceType.ResourceGroup: {
          scopeResourceType = t(LocaleKeys.resourceGroup);
          break;
        }
        default: {
          scopeResourceType = t(LocaleKeys.resource);
          break;
        }
      }
    }

    if (
      currentAccessReview &&
      currentAccessReview.reviewedEntity &&
      currentAccessReview.reviewedEntity.displayName &&
      currentAccessReview.reviewedEntity.displayName.length > 0
    ) {
      reviewedEntity = currentAccessReview.reviewedEntity.displayName;
    }

    const showReviewMessage = !isNullOrUndefined(reviewedEntity);

    const user = getUserFromAuth();
    const tid = user.tenantId;
    const typeId = decisionsCriteria?.typeId;
    // Preview only available for prod tenants
    const previewLink =
      'https://portal.azure.com/' +
      tid +
      '/#blade/Microsoft_AAD_ERM/ReviewAccessApprovalsBlade/AccessReviewEntityTypeId/' +
      typeId +
      '/AccessReviewId/' +
      this.props.reviewId;

    return (
      <div className={css(myAccessListStyles.listPage, myAccessListStyles.padding)}>
        {isPim ? (
          <MessageBar
            messageBarType={MessageBarType.warning}
            isMultiline={false}
            dismissButtonAriaLabel="Close"
            className={css(myAccessStyles.previewMessageBar)}
          >
            {t(LocaleKeys.pimArBannerMessage)}
            <Link href={previewLink} target="_blank" underline>
              {t(LocaleKeys.clickHere)}
            </Link>
          </MessageBar>
        ) : null}
        <div>
          <Link
            onClick={
              // tslint:disable-next-line:jsx-no-lambda
              () => this.props.history.push(getOneLayerUp())
            }
            className={css(ColorClassNames.black, myAccessStyles.marginBottomSmall)}
          >
            <Icon iconName={'Back'} />
            <span className={css(FontClassNames.mediumPlus, myAccessStyles.marginLeftXSmall)}>
              {t(LocaleKeys.accessReviews)}
            </span>
          </Link>
        </div>
        {!isNullOrUndefined(currentAccessReview) ? (
          <div>
            <div className={css(myAccessListStyles.pageTitle)}>{currentAccessReview.displayName}</div>
            <div className={css(myAccessStyles.marginTopXSmall, myAccessListStyles.pageSubtitle)}>
              <span>
                {showReviewMessage ? (
                  <span>
                    {isByod ? (
                      <span>{t(LocaleKeys.pleaseReviewMultiEntities)}</span>
                    ) : (
                      <span>
                        {asLocalizedText(
                          {
                            key: LocaleKeys.pleaseReview,
                            options: {
                              entityName: reviewedEntity
                            }
                          },
                          t
                        )}
                      </span>
                    )}
                  </span>
                ) : this.state.isUserCentric ? (
                  <span>
                    {asLocalizedText(
                      {
                        key: LocaleKeys.pleaseReviewPrincipleAzureRole,
                        options: {
                          resourceDisplayName: scopeResourceName,
                          resourceType: scopeResourceType
                        }
                      },
                      t
                    )}
                  </span>
                ) : (
                  <span>{t(LocaleKeys.pleaseReviewNoEntity)}</span>
                )}
                <Link
                  onClick={this.props.showReviewDetails}
                  className={css(FontClassNames.medium, myAccessStyles.themeDarkFont, myAccessStyles.marginLeftSmall)}
                >
                  {t(LocaleKeys.seeDetails)}
                </Link>
              </span>
              <div className={css(myAccessStyles.marginBottomSmall)}>
                {showReviewMessage && checkFeatureAccess('reviewerExperience') && !this.state.isUserCentric ? (
                  <span className={css(FontClassNames.mediumPlus, myAccessStyles.bold)}>
                    {isByod
                      ? t(LocaleKeys.continueAccessByod)
                      : asLocalizedText(
                        {
                          key: LocaleKeys.continueAccess,
                          options: {
                            decisionSubjectType: decisionCriteriaSubjectType,
                            resourceName: reviewedEntity
                          }
                        },
                        t
                      )}
                  </span>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}

        {isSelfReview ? (
          <SelfReview
            t={t}
            isSubmitting={isSubmitting}
            resourceName={reviewedEntity!}
            resourceType={entityType}
            reviewDecision={accessReviewDecisionList[0]}
            justificationRequired={
              currentAccessReview ? currentAccessReview.settings!.justificationRequiredOnApproval : true
            }
            // tslint:disable-next-line:jsx-no-lambda
            onSubmit={(decisionType, justification) => this._patchDecision(decisionType, justification, false, true)}
            responsiveMode={responsiveMode}
          />
        ) : !this.state.isUserCentric ? (
          <div className={css(myAccessStyles.marginTopSmall)}>
            <CommandBar
              className={css(myAccessListStyles.commandBar)}
              items={this.state.commands!}
              farItems={this.state.farCommands}
            />
          </div>
        ) : null}

        {!isNullOrUndefined(isSelfReview) && !isSelfReview ? (
          <InfinityList
            t={t}
            entityList={accessReviewDecisionList}
            entityType={EntityType.accessReviewDecisions}
            ariaLabel={'List of users to review.'}
            clearSelection={isSubmitting || this.state.clearSelection}
            columns={
              isByod
                ? (this.state.byodColumns as Array<IListColumn<IEntity>>)
                : (this.state.columns as Array<IListColumn<IEntity>>)
            }
            showLoadMore={showLoadMore}
            showSpinner={isLoadingMore}
            spinnerLabel={t(LocaleKeys.loadingPage, {
              pageName: t(LocaleKeys.user, {
                context: 'plural'
              })
            })}
            showNoEntities={showNoEntities}
            noEntitiesProps={{
              iconName: 'UserFollowed',
              noRowMessage: LocaleKeys.completedReview,
              showButton: false
            }}
            showNoFilteredResults={showNoFilteredResults}
            onLoadMore={this._loadMore}
            onItemSelected={this._onItemSelected}
            selectionMode={selectionMode}
            isExpanded={false}
          />
        ) : null}
        {showingBulkDecisionDialog && !this.state.isUserCentric ? (
          <BulkDecisionDialog
            t={t}
            decisionType={bulkDecisionType}
            isSubmitting={isSubmitting}
            userList={this.state.accessReviewSelectedUsers!}
            // tslint:disable-next-line:jsx-no-lambda
            onSubmit={(justification) => this._patchDecision(bulkDecisionType, justification)}
            onDismiss={this.props.dismissBulkDecisionDialog}
            justificationRequired={
              currentAccessReview ? currentAccessReview.settings!.justificationRequiredOnApproval : true
            }
            responsiveMode={responsiveMode}
          />
        ) : null}
        {showingReviewDecisionsDialog ? (
          <ReviewDecisionsDialog
            t={t}
            decisionSummary={this.props.currentAccessReview?.decisionsSummary}
            decisionSubjectType={
              checkFeatureAccess('reviewerExperience')
                ? decisionCriteriaSubjectType
                : t(LocaleKeys.user, { context: 'plural' })
            }
            currFilter={this.props.isFiltering ? this.props.filter : null}
            filteredEntityCount={this.props.isFiltering ? this.props.pageMetaData.filteredEntityCount : null}
            reviewId={this.props.reviewId}
            resourceName={reviewedEntity}
            resourceType={entityType}
            isSubmitting={isSubmitting}
            submitAllDecisions={this.props.submitAllDecisions}
            onDismiss={this.props.dismissReviewDecisionsDialog}
            responsiveMode={responsiveMode}
          />
        ) : null}
        {showingResetDecisions ? (
          <ResetDecisions
            t={t}
            isSubmitting={isSubmitting}
            userList={this.state.accessReviewSelectedUsers!}
            // tslint:disable-next-line:jsx-no-lambda
            onSubmit={(resetAll) => this._patchDecision(DecisionType.NotReviewed, '', resetAll)}
            onDismiss={this.props.dismissResetDecisions}
            responsiveMode={responsiveMode}
          />
        ) : null}
        {showingAcceptRecommendations ? (
          <AcceptRecommendations
            t={t}
            userList={this.state.accessReviewSelectedUsers!}
            isSubmitting={isSubmitting}
            // tslint:disable-next-line:jsx-no-lambda
            onSubmit={(acceptAll) => this._patchDecision(DecisionType.AcceptRecommendation, '', acceptAll)}
            onDismiss={this.props.dismissAcceptRecommendations}
            responsiveMode={responsiveMode}
          />
        ) : null}

        <ConnectedAccessReviewDetails
          onDismiss={this.props.dismissReviewDetails}
          currentReview={currentAccessReview}
          decisionsCriteria={this.props.decisionsCriteria}
          resourceId={this.state.resourceId}
        />
        <ConnectedDecisionDetails
          isSubmitting={isSubmitting}
          onDismiss={this.props.dismissDecisionDetails}
          decision={this.state.currentDecision}
          justificationRequired={
            currentAccessReview ? currentAccessReview.settings!.justificationRequiredOnApproval : true
          }
          currentReview={currentAccessReview}
          insights={this._getDecisionInsights(this.state.currentDecision!)}
          lookbackDuration={this._getLookbackDuration()}
          // tslint:disable-next-line:jsx-no-lambda
          onSubmit={(type, justification) => this._patchDecision(type, justification, false, false, true)}
        />
        <ConnectedUserCentricPanel
          onDismiss={this.props.dismissUserCentricPanel}
          decision={this.props.accessReviewDecisionList[this.state.decisionIndex!]}
          justificationRequired={
            currentAccessReview ? currentAccessReview.settings!.justificationRequiredOnApproval : true
          }
          currentReview={currentAccessReview}
          lookbackDuration={this._getLookbackDuration()}
          reviewId={this.props.reviewId}
          // tslint:disable-next-line:jsx-no-lambda
          onSubmit={(type, justification) => this._patchDecision(type, justification, false, false, false)}
        />
        <ConnectedDecisionsFilter
          onDismiss={this.props.dismissReviewDecisionsFilter}
          filterUpdated={this._filterUpdated}
        />
      </div>
    );
  }

  private readonly _patchDecision = (
    type: string,
    justification: string,
    decideAll?: boolean,
    selfReview?: boolean,
    singleDecision?: boolean
  ): void => {
    if (decideAll) {
      this.props.submitAllDecisions({
        decisionType: type,
        justification
      } as ISubmitDecision);
      // decideAll = true only for accept recommendations
    } else if (selfReview) {
      const selfDecision: ISubmitDecision = {
        decisionId: this.props.accessReviewDecisionList[0].id,
        decisionType: type,
        justification,
        selfReview: true
      };
      this.props.submitDecision([selfDecision]);
    } else {
      const submitDecisionList: ISubmitDecision[] = [];
      this.state.accessReviewSelectedUsers!.forEach((decision: IAccessReviewDecision) => {
        // Do nothing if one of the selected reviews is unreviewed and trying to be reset
        if (decision.reviewResult === DecisionType.NotReviewed && type === DecisionType.NotReviewed) {
          return;
        }
        // There may be multiple users selected, even when using the single-decision panel
        // Only add decisions to the submitDecisionList if the id matches to the single decision
        if (!singleDecision || decision.id === this.state.currentDecision!.id) {
          let submitDecisionType = type;
          let acceptRec = false;
          if (
            type === DecisionType.AcceptRecommendation &&
            decision.accessRecommendation !== RecommendationType.NotAvailable &&
            decision.reviewResult === DecisionType.NotReviewed
          ) {
            submitDecisionType = decision.accessRecommendation!;
            acceptRec = true;
          }

          const submitDecision: ISubmitDecision = {
            decisionId: decision.id,
            decisionType: submitDecisionType,
            justification,
            acceptRecommendation: acceptRec
          };

          if (submitDecision.decisionType !== DecisionType.AcceptRecommendation) {
            submitDecisionList.push(submitDecision);
          }
        }
      });

      this.props.submitDecision(submitDecisionList);
    }

    this._afterCancelSubmitDecision();
  };

  private readonly _onItemSelected = (selectedItems: IAccessReviewDecision[]): void => {
    if (!this.props.isSubmitting) {
      this.props.dismissDecisionDetails();
    }

    // Check for Select All and pop review dialog
    if (
      this.props.features.reviewerExperience &&
      this.state.accessReviewSelectedUsers &&
      selectedItems.length - this.state.accessReviewSelectedUsers?.length > 1
    ) {
      this.props.getDecisionsSummary(this.props.reviewId);
      this.props.showReviewDecisionsDialog();
    }

    this.setState({
      commands: getAccessReviewDecisionsListCommands(this.props.t, this.props, selectedItems),
      accessReviewSelectedUsers: selectedItems
    });
  };

  private readonly _onRowClicked = (item: IAccessReviewDecision): void => {
    const isByod: boolean = this.props.currentAccessReview
      ? !isNullOrUndefined(this.props.currentAccessReview.customDataProvider)
      : false;
    this._viewDecisionDetails(item, isByod);
  };

  private readonly _loadMore = (): void => {
    if (!this.props.isLoading) {
      if (this.props.isSearching) {
        this._searchEntitiesOnServer();
      }
      if (this.props.isFiltering) {
        this._filterEntitiesOnServer();
      } else {
        this._getEntities(EntityType.accessReviewDecisions, this.state.isUserCentric ? 'user-centric' : '');
      }
    }
  };

  private readonly _getEntities = (type: string, id?: string): void => {
    if (this.props.pageMetaData.isAllEntitiesFullyCached || this.props.isLoading) {
      return;
    }
    this.props.getEntities(type, id);
  };

  private readonly _searchEntitiesOnServer = (): void => {
    if (this.props.pageMetaData.isAllEntitiesFullyCached || this.props.pageMetaData.isFilteredEntitiesFullyCached) {
      return;
    }
    this.props.searchForMore();
  };

  private readonly _filterEntitiesOnServer = (): void => {
    if (this.props.pageMetaData.isAllEntitiesFullyCached || this.props.pageMetaData.isFilteredEntitiesFullyCached) {
      return;
    }
    this.props.filterEntitiesOnServer(this.props.filter);
  };

  private readonly _afterCancelSubmitDecision = (): void => {
    this.setState({
      commands: getAccessReviewDecisionsListCommands(this.props.t, this.props, this.state.accessReviewSelectedUsers!)
    });
  };

  private _getResponsiveMode(): ResponsiveMode {
    let { responsiveMode } = this.props;
    if (responsiveMode === undefined) {
      responsiveMode = ResponsiveMode.large;
    }
    return responsiveMode;
  }

  private readonly _getAccessReviewDecisionsListColumns = (
    isByod: boolean,
    responsiveMode: ResponsiveMode,
    t: TranslationFunction
  ): Array<IListColumn<IAccessReviewDecision>> => {
    const isMobile = responsiveMode <= ResponsiveMode.medium;
    const primaryColumnName = isMobile
      ? t(LocaleKeys.user, { context: 'capitalize' })
      : isByod
        ? t(LocaleKeys.identity)
        : t(LocaleKeys.name);

    let singleType = true;
    if (
      this.props.decisionsCriteria !== undefined &&
      this.props.decisionsCriteria.subjectType === ReviewDecisionSubjectType.UserAndGroup.toString()
    ) {
      singleType = false;
    }

    const primaryColumnWidth = !isMobile ? 320 : 999;
    const userColumn: IListColumn<IAccessReviewDecision> = {
      key: isByod ? 'principal/displayName' : 'userDisplayName',
      name: primaryColumnName,
      fieldName: 'userName',
      minWidth: 80,
      maxWidth: primaryColumnWidth,
      className: 'ms-pii',
      onColumnClick: this._onColumnClick,
      headerClassName: FontClassNames.smallPlus,
      isSorted: true,
      isSortedDescending: false,
      isResizable: true,
      onRender: (item: IAccessReviewDecision) =>
        isByod ? this._renderByodColumn(true, isMobile, item, t) : this._renderPrimaryColumn(isMobile, item, t)
    } as IListColumn<IAccessReviewDecision>;

    const columns: Array<IListColumn<IAccessReviewDecision>> = [];
    columns.push(userColumn);

    const docLink =
      'https://docs.microsoft.com/azure/active-directory/governance/review-recommendations-access-reviews';

    if (this.state && !this.state.isUserCentric) {
      if (!isMobile) {
        columns.push({
          key: 'accessRecommendation',
          name: t(LocaleKeys.recommendation, { context: 'capitalize' }),
          fieldName: 'accessRecommendation',
          minWidth: 80,
          maxWidth: 425,
          headerClassName: FontClassNames.smallPlus,
          ariaLabel: t(LocaleKeys.recommendation),
          onColumnClick: this._onColumnClick,
          isResizable: true,
          onRenderHeader: () => (
            <div aria-label={t(LocaleKeys.recommendation)}>
              {t(LocaleKeys.recommendation, { context: 'capitalize' })}

              <IconButton
                id='recommendationTooltipInfoButton'
                iconProps={{ iconName: 'Info' }}
                onKeyDown={this._onRecommendationToolTipKeyboard}
                ariaLabel={t(LocaleKeys.recommendationsInfo)}
                className={css(myAccessStyles.marginLeftXSmall)}
                onMouseOver={() => this._toggleRecommendationsTooltip(true)}
                onKeyPress={() => this._onRecommendationToolTipKeyboard}
                onFocus={() => this._toggleRecommendationsTooltipFocus(true)}
                onBlur={() =>
                  this._toggleRecommendationsTooltipFocus(false)
                }
              />
              {this.state.isRecommendationsTooltipVisible && (
                <Callout
                  target={'#recommendationTooltipInfoButton'}
                  calloutMaxWidth={375}
                  role='dialog'
                  gapSpace={0}
                  directionalHint={DirectionalHint.topAutoEdge}
                  ariaLabel={t(LocaleKeys.recommendationsInfoExpanded)}
                  onDismiss={() => {
                    this._toggleRecommendationsTooltip(false);
                  }}
                  setInitialFocus={true}
                >
                  <div className={css(myAccessListStyles.recommendationColumnTooltip)} tabIndex={0}>
                    {this.props.t(LocaleKeys.recommendationTooltip)}
                    <Link href={docLink} target="_blank"> {this.props.t(LocaleKeys.learnMore)}</Link>
                  </div>
                </Callout>
              )}
            </div>
          ),
          onRender: (item: IAccessReviewDecision) => this._renderRecommendation(isByod, isMobile, item, t)
        });
      }
      if (
        !isMobile &&
        this.props.currentAccessReview &&
        this.props.currentAccessReview.stage! > 0 &&
        this.props.currentAccessReview.settings!.decisionHistoriesForDecisionMakersEnabled
      ) {
        columns.push({
          key: 'previousStage',
          name: t(LocaleKeys.previousStageDecision),
          fieldName: 'previousStage',
          minWidth: 100,
          maxWidth: 178,
          columnActionsMode: ColumnActionsMode.disabled,
          headerClassName: FontClassNames.smallPlus,
          isResizable: true,
          onRender: (item: IAccessReviewDecision) => {
            if (item.histories && item.histories.length > 0) {
              return this._renderPreviousDecision(item, t);
            } else {
              return getInlineSpinner();
            }
          }
        });
      }
    } else if (this.state && this.state.isUserCentric) {
      if (!isMobile) {
        columns.push({
          key: 'roleCount',
          name: t(LocaleKeys.roles),
          fieldName: 'roleCount',
          minWidth: 80,
          maxWidth: 200,
          headerClassName: FontClassNames.smallPlus,
          isResizable: true,
          onRender: (item: IAccessReviewDecision) => this._renderUserCentricColumn(isMobile, item, t, isByod)
        });
      }
    }

    // Add type column
    if (checkFeatureAccess('reviewerExperience') && !singleType && !isMobile && !isByod) {
      columns.push({
        key: 'schemaId',
        name: t(LocaleKeys.accessReviewsType, { context: 'capitalize' }),
        fieldName: 'schemaId',
        minWidth: 80,
        maxWidth: 150,
        columnActionsMode: ColumnActionsMode.disabled,
        headerClassName: FontClassNames.smallPlus,
        onColumnClick: this._onColumnClick,
        isResizable: true,
        onRender: (item: IAccessReviewDecision) => {
          let result: DecisionResourceType | string = item.schemaId!;
          if (this.state.isUserCentric && item.secondaryDecisions) {
            result = item.secondaryDecisions[0].schemaId!;
          }
          switch (result) {
            case DecisionResourceType.Group:
              result = t(LocaleKeys.group, { context: 'capitalize' });
              break;
            case DecisionResourceType.ServicePrincipal:
              result = t(LocaleKeys.servicePrincipal, { context: 'capitalize' });
              break;
            case DecisionResourceType.User:
              result = t(LocaleKeys.user, { context: 'capitalize' });
              break;
            default:
              result = '';
              break;
          }
          return (
            <ColumnValue
              searchTerm={this.props.searchTerm}
              columnValue={result}
              isHighlightRequired={false}
              isSearching={false}
            />
          );
        }
      });
    }

    if (isByod) {
      columns.push({
        key: 'resource/displayName',
        name: t(LocaleKeys.resource),
        fieldName: 'resourceName',
        minWidth: 80,
        maxWidth: 425,
        className: 'ms-pii',
        onColumnClick: this._onColumnClick,
        headerClassName: FontClassNames.smallPlus,
        isResizable: true,
        onRender: (item: IAccessReviewDecision) => this._renderByodColumn(false, isMobile, item, t)
      });
    }

    if (!isMobile && this.state && !this.state.isUserCentric) {
      columns.push({
        key: 'reviewResult',
        name: t(LocaleKeys.decision),
        fieldName: 'reviewResult',
        minWidth: 80,
        maxWidth: 120,
        onColumnClick: this._onColumnClick,
        headerClassName: FontClassNames.smallPlus,
        isResizable: true,
        onRender: (item: IAccessReviewDecision) => {
          let result: DecisionType | string = item.reviewResult!;
          switch (result) {
            case DecisionType.Approve:
              result = t(LocaleKeys.approved);
              break;
            case DecisionType.Deny:
              result = t(LocaleKeys.denied);
              break;
            case DecisionType.DontKnow:
              result = t(LocaleKeys.dontKnow);
              break;
            default:
              result = '';
              break;
          }

          return (
            <ColumnValue
              searchTerm={this.props.searchTerm}
              columnValue={result}
              isHighlightRequired={false}
              isSearching={false}
            />
          );
        }
      });
    }
    if (!isMobile) {
      columns.push({
        key: 'reviewedBy',
        name: t(LocaleKeys.reviewedBy),
        fieldName: 'reviewedBy',
        minWidth: 150,
        maxWidth: 220,
        columnActionsMode: ColumnActionsMode.disabled,
        headerClassName: FontClassNames.smallPlus,
        isResizable: true,
        onRender: (item: IAccessReviewDecision) => {
          return (
            <ColumnValue
              searchTerm={this.props.searchTerm}
              columnValue={item.reviewedBy ? item.reviewedBy.displayName : ''}
              isHighlightRequired={false}
              isSearching={false}
            />
          );
        }
      });
    }
    if (
      !isMobile &&
      this.props.currentAccessReview &&
      this.props.currentAccessReview.stage! > 0 &&
      this.props.currentAccessReview.settings!.decisionHistoriesForDecisionMakersEnabled
    ) {
      columns.push({
        key: 'previousStage',
        name: t(LocaleKeys.previousStageDecision),
        fieldName: 'previousStage',
        minWidth: 100,
        maxWidth: 178,
        columnActionsMode: ColumnActionsMode.disabled,
        headerClassName: FontClassNames.smallPlus,
        isResizable: true,
        onRender: (item: IAccessReviewDecision) => {
          if (item.histories && item.histories.length > 0) {
            return this._renderPreviousDecision(item, t);
          } else {
            return getInlineSpinner();
          }
        }
      });
    }
    if (this.state && !this.state.isUserCentric) {
      columns.push({
        key: 'viewDetails',
        name: '',
        fieldName: 'id',
        minWidth: 50,
        maxWidth: 50,
        headerClassName: FontClassNames.smallPlus,
        columnActionsMode: ColumnActionsMode.disabled,
        onRender: (item: IAccessReviewDecision) => (
          <Link
            // tslint:disable-next-line:jsx-no-lambda
            onClick={() => this._viewDecisionDetails(item, isByod)}
            className={css(FontClassNames.medium, myAccessStyles.themeDarkFont)}
          >
            {t(LocaleKeys.details)}
          </Link>
        )
      });
    }
    return columns;
  };

  private readonly _viewDecisionDetails = (decision: IAccessReviewDecision, isByod: boolean): void => {
    decision.isByod = isByod;
    let decInd = 0;
    for (let i = 0; i < this.props.accessReviewDecisionList.length; i++) {
      if (this.props.accessReviewDecisionList[i].id === decision.id) {
        decInd = i;
        break;
      }
    }
    this.setState({
      currentDecision: decision,
      decisionIndex: decInd
    });
    if (this.state.isUserCentric) {
      this.props.showUserCentricPanel();
    } else {
      this.props.showDecisionDetails(true, decision);
    }
  };

  private readonly _renderPrimaryColumn = (
    isMobile: boolean,
    item: IAccessReviewDecision,
    t: TranslationFunction
  ): JSX.Element => {
    let displayName = '';
    let principalName = '';
    if (item.principal) {
      displayName = item.principal.displayName;
    }
    if (item.userPrincipalName) {
      principalName = item.userPrincipalName;
    }

    if (!isNullOrUndefined(item.servicePrincipalDisplayName)) {
      displayName = item.servicePrincipalDisplayName;
    } else if (!isNullOrUndefined(item.groupDisplayName)) {
      displayName = item.groupDisplayName;
    }

    if (!isNullOrUndefined(item.servicePrincipalId)) {
      principalName = item.servicePrincipalId;
    }

    if (item.secondaryDecisions && item.secondaryDecisions.length > 0) {
      if (
        item.principal &&
        item.principal.schemaId &&
        item.principal.schemaId === DecisionResourceType.ServicePrincipal
      ) {
        principalName = item.secondaryDecisions[0].servicePrincipalId!;
      } else {
        principalName = item.secondaryDecisions[0].userPrincipalName;
      }
    }

    if (!isMobile) {
      return (
        <div>
          <div className={css('ms-pii', FontClassNames.medium, myAccessStyles.primaryText)}>{displayName}</div>
          <div className={css('ms-pii', FontClassNames.medium, myAccessStyles.secondaryText)}>{principalName}</div>
        </div>
      );
    } else {
      return (
        <div>
          <div className={css('ms-pii', FontClassNames.small)}>
            {displayName} ({principalName})
          </div>
          {this._renderRecommendation(false, isMobile, item, t)}
        </div>
      );
    }
  };

  private readonly _renderByodColumn = (
    isPrincipal: boolean,
    isMobile: boolean,
    item: IAccessReviewDecision,
    t: TranslationFunction
  ): JSX.Element => {
    let principalDisplayName: string | undefined;
    let principalType: string | undefined;
    let resourceDisplayName: string | undefined;
    let resourceType: string | undefined;

    if (!isNullOrUndefined(item.principal)) {
      principalDisplayName = item.principal.displayName;
      principalType = item.principal.principalType;
    }

    if (!isNullOrUndefined(item.resource)) {
      resourceDisplayName = item.resource.displayName;
      resourceType = item.resource.type;
    }

    if (!isMobile) {
      return (
        <div>
          <div className={css('ms-pii', FontClassNames.medium, myAccessStyles.primaryText)}>
            {isPrincipal ? principalDisplayName : resourceDisplayName}
          </div>
          <div className={css('ms-pii', FontClassNames.medium, myAccessStyles.secondaryText)}>
            ({isPrincipal ? principalType : resourceType})
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className={css('ms-pii', FontClassNames.small)}>
            {principalDisplayName} ({principalType}), {resourceDisplayName} ({resourceType})
          </div>
          {this._renderRecommendation(true, isMobile, item, t)}
        </div>
      );
    }
  };

  private readonly _renderRecommendation = (
    isByod: boolean,
    isMobile: boolean,
    item: IAccessReviewDecision,
    t: TranslationFunction
  ): JSX.Element => {
    let recommendation: string;
    let description = '';
    const type = this._getItemResourceType(item);

    const insightStatus = this._getDecisionInsights(item);
    const lookbackDuration = this._getLookbackDuration();

    const recommendationsEnabled: boolean =
      this.props.currentAccessReview && this.props.currentAccessReview.settings
        ? this.props.currentAccessReview.settings.accessRecommendationsEnabled
        : false;
    if (!recommendationsEnabled) {
      return (
        <div className={css('ms-pii', FontClassNames.medium, myAccessStyles.primaryText)}>
          {t(LocaleKeys.notAvailable)}
        </div>
      );
    }

    switch (item.accessRecommendation) {
      case RecommendationType.Approve: {
        recommendation = t(LocaleKeys.approve);
        description = t(LocaleKeys.beforeReviewLess, {
          lookback: lookbackDuration
        });
        break;
      }
      case RecommendationType.Deny: {
        recommendation = t(LocaleKeys.deny);
        description = t(LocaleKeys.beforeReviewMore, {
          lookback: lookbackDuration
        });
        break;
      }
      default: {
        recommendation = t(LocaleKeys.notAvailable);
        break;
      }
    }

    if (!isMobile) {
      return (
        <div>
          <div className={css('ms-pii', FontClassNames.medium, myAccessStyles.primaryText)}>{recommendation}</div>
          {type === DecisionResourceType.User && item.accessRecommendation !== RecommendationType.NotAvailable ? (
            <div className={css(myAccessListStyles.recommendationRow)}>
              {insightStatus.outlier ? this._renderInsight(AccessReviewInsight.Outlier) : null}
              {insightStatus.inactive ? this._renderInsight(AccessReviewInsight.Inactive) : null}
            </div>
          ) : null}
        </div>
      );
    } else {
      return (
        <div>
          {item.lastUserSignInDateTime ? (
            <div className={css('ms-pii', FontClassNames.medium)}>
              {t(LocaleKeys.lastSignedIn, {
                signInDate: '(' + FormatDate(item.lastUserSignInDateTime) + ')',
                reason: description
              })}
            </div>
          ) : !isByod ? (
            <div className={css('ms-pii', FontClassNames.medium)}>{t(LocaleKeys.lastSignInUnknown)}</div>
          ) : null}
          {item.reviewResult === undefined || item.reviewResult === DecisionType.NotReviewed ? (
            <span className={css('ms-pii', FontClassNames.small)}>
              {asLocalizedText(
                {
                  key: LocaleKeys.recommendationTag,
                  options: {
                    decisionType: recommendation
                  }
                },
                t
              )}
            </span>
          ) : (
            this._renderDecisionInfo(item, t)
          )}
        </div>
      );
    }
  };

  private readonly _renderUserCentricColumn = (
    isMobile: boolean,
    item: IAccessReviewDecision,
    t: TranslationFunction,
    isByod: boolean
  ): JSX.Element => {
    if (item.secondaryDecisions && item.secondaryDecisions.length > 0) {
      const total = item.secondaryDecisions.length;
      let reviewed = 0;
      for (const dec of item.secondaryDecisions) {
        if (dec.reviewResult !== DecisionType.NotReviewed) {
          reviewed++;
        }
      }
      return (
        <Link
          // tslint:disable-next-line:jsx-no-lambda
          onClick={() => this._viewDecisionDetails(item, isByod)}
          className={css(FontClassNames.medium, myAccessStyles.themeDarkFont)}
        >
          {t(LocaleKeys.rolesReviewed, {
            num: reviewed,
            den: total
          })}
        </Link>
      );
    } else {
      return getInlineSpinner();
    }
  };

  private readonly _renderDecisionInfo = (item: IAccessReviewDecision, t: TranslationFunction): JSX.Element => {
    let decisionString = '';

    switch (item.reviewResult) {
      case DecisionType.Approve: {
        decisionString = LocaleKeys.decisionApproved;
        break;
      }
      case DecisionType.Deny: {
        decisionString = LocaleKeys.decisionDenied;
        break;
      }
      case DecisionType.DontKnow: {
        decisionString = LocaleKeys.decisionDontKnow;
        break;
      }
      default: {
        decisionString = LocaleKeys.decision;
        break;
      }
    }

    return (
      <span className={css('ms-pii', FontClassNames.small)}>
        {asLocalizedText(
          {
            key: decisionString,
            options: {
              reviewerName: item.reviewedBy ? item.reviewedBy.displayName : ''
            }
          },
          t
        )}
      </span>
    );
  };

  private readonly _renderPreviousDecision = (item: IAccessReviewDecision, t: TranslationFunction): JSX.Element => {
    let decisionString = '';
    let reviewerName = '';
    let previousStage = 0;

    if (this.props.currentAccessReview!.stage) {
      previousStage = this.props.currentAccessReview!.stage - 1;

      decisionString = item.histories![previousStage].reviewResult!;
      reviewerName = item.histories![previousStage].reviewedBy!.displayName;
    }

    // Set decisionString to the correct localeKey for its decision type
    switch (decisionString) {
      case DecisionType.Approve: {
        decisionString = LocaleKeys.approved;
        break;
      }
      case DecisionType.Deny: {
        decisionString = LocaleKeys.denied;
        break;
      }
      case DecisionType.DontKnow: {
        decisionString = LocaleKeys.dontKnow;
        break;
      }
      default: {
        decisionString = LocaleKeys.notReviewed;
        break;
      }
    }

    return (
      <div>
        <div className={css('ms-pii', FontClassNames.medium)}>{t(decisionString)}</div>
        <div className={css('ms-pii', FontClassNames.medium)}>{reviewerName}</div>
      </div>
    );
  };

  private readonly _getDecisionInsights = (item: IAccessReviewDecision): IAccessReviewDecisionInsights => {
    let isOutlier = false;

    if (item && item.insights) {
      item.insights.forEach((insight: IAccessReviewInsight) => {
        if (insight['@odata.type'] === AccessReviewInsight.Outlier) {
          isOutlier = true;
        }
      });
    }

    // relying on the inactive insight is currently unreliable, use _isUserInactive
    return {
      inactive: this._isUserInactive(item),
      outlier: isOutlier
    } as IAccessReviewDecisionInsights;
  };

  private readonly _renderInsight = (insight: AccessReviewInsight): JSX.Element | void => {
    let iconString = '';
    let message = '';

    switch (insight) {
      case AccessReviewInsight.Outlier: {
        message = this.props.t(LocaleKeys.insightCardOutlier);
        iconString = 'PeopleBlock';
        break;
      }
      case AccessReviewInsight.Inactive: {
        message = this.props.t(LocaleKeys.insightCardInactive);
        iconString = 'Snooze';
        break;
      }
      default: {
        break;
      }
    }

    return (
      <div className={css(myAccessListStyles.recommendationColumn)}>
        <Icon iconName={iconString} className={css(myAccessListStyles.recommendationIcon)} />
        {message}
      </div>
    );
  };

  private _getLookbackDuration(): number {
    let lookback = 30; // 30 is the default lookback duration
    if (this.props.currentAccessReview?.settings?.recommendationLookBackDuration) {
      let lookbackStr = this.props.currentAccessReview?.settings?.recommendationLookBackDuration;
      // Remove first and last characters (original format: P30D)
      lookbackStr = lookbackStr.substring(1, lookbackStr.length - 1);
      lookback = +lookbackStr; // convert string to number
    }
    return lookback;
  }

  private _isUserInactive(user: IAccessReviewDecision): boolean {
    if (this.props.currentAccessReview?.settings?.lastSignInRecommendationEnabled) {
      if (user && user.lastUserSignInDateTime) {
        const lookback = this._getLookbackDuration();
        const startDateTime = this.props.currentAccessReview?.startDateTime!;
        const minDay = new Date(startDateTime);
        minDay?.setDate(minDay.getDate() - lookback);
        return new Date(user.lastUserSignInDateTime) < minDay;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  private readonly _filterUpdated = (): void => {
    this.setState({
      clearSelection: true
    });
  };

  private readonly _getItemResourceType = (item: IAccessReviewDecision): DecisionResourceType => {
    if (item.groupDisplayName) {
      return DecisionResourceType.Group;
    } else if (item.servicePrincipalDisplayName) {
      return DecisionResourceType.ServicePrincipal;
    } else {
      return DecisionResourceType.User;
    }
  };

  private readonly _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IListColumn<IAccessReviewDecision>
  ): void => {
    if (column.key === 'accessRecommendation'
      && this.state.isRecommendationsTooltipButtonFocused) {
      this._toggleRecommendationsTooltip(true);
      return;
    }
    ev.preventDefault();
    column.isSorted = true;
    column.isSortedDescending = !column.isSortedDescending;

    this.setState({
      columns: getNewColumnsOnColumnClicked(this.state.columns, column),
      byodColumns: getNewColumnsOnColumnClicked(this.state.byodColumns, column)
    });
    this.props.setSortedByColumn(column);
    if (!this.props.isSearching && !this.props.isFiltering) {
      this.props.sortEntities(column.key, !column.isSortedDescending);
    } else {
      this.props.sortFilteredEntities(column.key, !column.isSortedDescending, this.props.searchTerm, this.props.filter);
    }
  };

  private readonly _resetColumns = (): void => {
    this.setState({
      ...this.state,
      columns: this._getAccessReviewDecisionsListColumns(false, this._getResponsiveMode(), this.props.t),
      byodColumns: this._getAccessReviewDecisionsListColumns(true, this._getResponsiveMode(), this.props.t)
    });
  };

  private readonly _onRecommendationToolTipKeyboard = (
    ev: React.KeyboardEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement>
  ): void => {
    switch (ev.keyCode) {
      case KeyCodes.enter:
      case KeyCodes.space:
        this._toggleRecommendationsTooltip(true);
        ev.stopPropagation();
        ev.preventDefault();
        break;
      case KeyCodes.tab:
        if (ev.shiftKey) {
          ev.stopPropagation();
          ev.preventDefault();
        }
        break;
    }
  };

  private readonly _toggleRecommendationsTooltip = (toolTipVisible: boolean): void => {
    this.setState({
      isRecommendationsTooltipVisible: toolTipVisible
    });
  };

  private readonly _toggleRecommendationsTooltipFocus = (toolTipButtonFocused: boolean): void => {
    this.setState({
      isRecommendationsTooltipButtonFocused: toolTipButtonFocused
    });
  };
}
