import { IAccessReviewSubject } from '../AccessReviews/IAccessReviewSubject';

export interface IApproval {
  id: string;
  reviewResult: string;
  reviewedDateTime: Date;
  justification: string;
  reviewedBy: IAccessReviewSubject;
}

export enum ApprovalStatus {
  Approve = 'approved',
  Deny = 'denied',
  NotReviewed = 'notReviewed',
  DontKnow = 'unknown',
  NotNotified = 'notNotified'
}
