import { Link, MessageBar, MessageBarType } from '@fluentui/react';
import React from 'react';

import { TranslationFunctionKeys } from '../../../models/ITranslation';
import {
  AadExtensionProvisioningState,
  IVirtualMachine,
  IVirtualMachineExtendedDetails,
  VirtualMachineIdentityType,
  VirtualMachinePowerState
} from '../../../models/RAM/IVirtualMachine';
import { OsTypeEnum } from '../../../models/RAM/OsTypeEnum';
import { ResourceTypeEnum } from '../../../models/RAM/ResourceTypeEnum';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { QuickActivationDialogRole } from '../QuickActivation/QuickActivationDialog';
import { isEligibilityScopedAtResource } from '../VmList/VmList.columns';
import { deallocatedVmLink, linuxEntraLoginLink, windowsEntraLoginLink } from './Connection.utils';

export const generateConnectionMessageBars = (
  t: TranslationFunctionKeys,
  virtualMachine: IVirtualMachine,
  quickActivationRole: QuickActivationDialogRole,
  virtualMachineDetails?: IVirtualMachineExtendedDetails,
  roleEndTime?: string | null
): JSX.Element[] => {
  const messageLink: string | undefined =
    virtualMachine.osType === OsTypeEnum.Windows ? windowsEntraLoginLink : linuxEntraLoginLink;
  const vmIsArcWindows =
    virtualMachine.osType === OsTypeEnum.Windows && virtualMachine.resourceType === ResourceTypeEnum.ArcMachine;
  const messageBarsList: JSX.Element[] = [];

  if (roleEndTime) {
    messageBarsList.push(
      <MessageBar messageBarType={MessageBarType.success} isMultiline={true}>
        {t(LocaleKeys.ramConnectionDialogRoleDuration)} <strong>{roleEndTime}</strong>
      </MessageBar>
    );
  } else if (roleEndTime === null) {
    messageBarsList.push(
      <MessageBar messageBarType={MessageBarType.success} isMultiline={true}>
        {t(LocaleKeys.ramConnectionDialogRoleIndefinteDuration)}
      </MessageBar>
    );
  }
  if (!virtualMachineDetails) return messageBarsList;
  if (
    virtualMachineDetails?.powerState !== VirtualMachinePowerState.Running &&
    virtualMachineDetails?.powerState !== VirtualMachinePowerState.Connected
  ) {
    messageBarsList.push(
      createWarningMessageBar(t(LocaleKeys.ramConnectionAlertVmDeallocated), deallocatedVmLink, t(LocaleKeys.learnMore))
    );
  }
  if (
    virtualMachine.osType !== undefined &&
    !vmIsArcWindows &&
    virtualMachineDetails?.aadExtensionProvisioningState !== AadExtensionProvisioningState.Succeeded
  ) {
    messageBarsList.push(
      createWarningMessageBar(
        t(LocaleKeys.ramConnectionAlertExtensionNotInstalled),
        messageLink,
        t(LocaleKeys.learnMore)
      )
    );
  }
  if (!vmIsArcWindows && virtualMachineDetails?.identityType !== VirtualMachineIdentityType.SystemAssigned) {
    messageBarsList.push(
      createWarningMessageBar(t(LocaleKeys.ramConnectionAlertNotSystemAssigned), messageLink, t(LocaleKeys.learnMore))
    );
  }
  if (isEligibilityScopedAtResource(quickActivationRole.roleActivationScope)) {
    const roleName = quickActivationRole.roleName;
    messageBarsList.push(
      createWarningMessageBar(
        t(LocaleKeys.ramConnectionAlertRoleScopedAtResource, { roleName }),
        messageLink,
        t(LocaleKeys.learnMore)
      )
    );
  }

  return messageBarsList;
};

const createWarningMessageBar = (message: string, messageLink: string, linkText: string): JSX.Element => (
  <MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
    {message}
    <Link href={messageLink} target={'_blank'}>
      {linkText}
    </Link>
  </MessageBar>
);
