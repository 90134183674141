import { Icon, MessageBar, MessageBarType } from '@fluentui/react';
import * as React from 'react';

import { useTranslation } from '../../../hooks';
import { IVirtualMachine } from '../../../models/RAM/IVirtualMachine';
import { LocaleKeys } from '../../../shared';
import { getAuth, getUserFromAuth } from '../../../shared/authHelper';
import { isEligibilityScopedAtResource } from '../VmList/VmList.columns';
import { genRestoreUri } from './QuickActivation.utils';
import { QuickActivationDialogRole } from './QuickActivationDialog';

interface ActivationMessageBarContentProps {
  role: QuickActivationDialogRole;
  isMfaRequirementMet?: boolean;
  virtualMachine: IVirtualMachine;
}

export const ActivationMessageBarContent: React.FunctionComponent<ActivationMessageBarContentProps> = (
  props: ActivationMessageBarContentProps
) => {
  const t = useTranslation();
  const isRoleScopedAtResource = isEligibilityScopedAtResource(props.role.roleScope);
  const roleName = props.role.roleName;

  const mfaMessage = props.isMfaRequirementMet !== undefined && !props.isMfaRequirementMet && (
    <MessageBar
      messageBarType={MessageBarType.warning}
      isMultiline={false}
      onClick={() => {
        const userInfo = getUserFromAuth();
        const uri = genRestoreUri(props.virtualMachine.id, props.role.roleId);

        getAuth().login({
          username: userInfo.username,
          tenantId: userInfo.tenantId,
          restoreUri: uri,
          extraQueryParameters: { acr_values: 'urn:microsoft:policies:mfa' }
        });
      }}
      styles={{
        root: { cursor: 'pointer', display: 'flex', alignItems: 'center', marginBottom: '8px' }
      }}
    >
      {t(LocaleKeys.ramQuickActivationDialogMfaMessageText)}
      <Icon iconName="Forward" style={{ marginLeft: '5px', transform: 'translateY(2px)' }} />
    </MessageBar>
  );

  const roleAtResourceScopeMessage = isRoleScopedAtResource && (
    <MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
      {t(LocaleKeys.ramQuickActivationDialogRoleAtResourceScopeMessageText, { roleName })}
    </MessageBar>
  );

  const roleActivationScopeMessage = !isRoleScopedAtResource && (
    <MessageBar messageBarType={MessageBarType.info} isMultiline={true}>
      {t(LocaleKeys.ramQuickActivationDialogRoleActivationScopeMessageText)}
    </MessageBar>
  );

  return (
    <>
      {roleActivationScopeMessage}
      {roleAtResourceScopeMessage}
      {mfaMessage}
    </>
  );
};
