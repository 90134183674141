import { IAjaxRequest } from '@microsoft/portal-app/lib/auth';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';

import { EntitlementActions } from '../../../models/EntitlementActions';
import { EntityType } from '../../../models/EntityType';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState, IRootEntitlementsState } from '../../../models/IEntitlementState';
import { PimActivationRule } from '../../../models/RAM/IActivationStatusPayload';
import { PimRoleActivationErrorCode } from '../../../models/RAM/IErrorDialog';
import { IPimActivationRequestParameters } from '../../../models/RAM/IPimActivationRequestParameters';
import { getAudience } from '../../../shared/AttachAudience';
import { getRamBaseUrlBasedOnEnv } from '../../../shared/getRamApiUrl';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { registry } from '../myAccessRegistry';
import { removeLeadingSlash } from './utils';

export const validateRoleActivationEpic: Epic<IEntitlementAction<AnyPayload>, IRootEntitlementsState> = (
  action$: ActionsObservable<IEntitlementAction<IPimActivationRequestParameters>>,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
  return action$
    .ofType(EntitlementActions.validateRoleActivation)
    .switchMap((action: IEntitlementAction<IPimActivationRequestParameters>) => {
      const parameters = action.payload!;

      const ajaxRequest: IAjaxRequest = {
        method: 'POST',
        url: genRoleAssignmentValidationUrl(parameters.scope, parameters.roleAssignmentScheduleRequestName),
        audience: getAudience(EntityType.cloudInfrastructure),
        body: { Properties: { ...parameters.properties, justification: 'validation only call' } }
      };

      return ajax(ajaxRequest)
        .map(() => {
          return { type: EntitlementActions.validateRoleActivationSucceeded };
        })
        .catch((error) => {
          return Observable.of({
            type: EntitlementActions.validateRoleActivationFailed,
            payload: error
          });
        });
    });
};

registry.addEpic('validateRoleActivationEpic', validateRoleActivationEpic);

export const validateRoleActivationFailed = (
  state: IEntitlementState,
  // tslint:disable-next-line
  action: IEntitlementAction<Readonly<any>>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  const { payload } = action;

  if (
    payload.response.error &&
    payload.response.error.code === PimRoleActivationErrorCode.RoleAssignmentRequestPolicyValidationFailed
  ) {
    const mfaRequirementMet = !payload.response.error.message.includes(PimActivationRule.MfaRule);
    return { ...state, isMfaRequirementMet: mfaRequirementMet };
  } else {
    const ramErrorDialogContent = {
      message: LocaleKeys.ramErrorTryAgain,
      image: '/imgs/error_Warning.svg'
    };
    return { ...state, ramErrorDialogContent };
  }
};
registry.add(EntitlementActions.validateRoleActivationFailed, validateRoleActivationFailed);

export const validateRoleActivationSucceeded = (state: IEntitlementState): Readonly<IEntitlementState> => {
  return { ...state, isMfaRequirementMet: true };
};
registry.add(EntitlementActions.validateRoleActivationSucceeded, validateRoleActivationSucceeded);

const genRoleAssignmentValidationUrl = (scope: string, roleAssignmentScheduleRequestName: string): string => {
  const baseUrl = getRamBaseUrlBasedOnEnv();
  scope = removeLeadingSlash(scope);
  return `${baseUrl}${scope}/providers/Microsoft.Authorization/roleAssignmentScheduleRequests/${roleAssignmentScheduleRequestName}/validate?api-version=2020-10-01`;
};
