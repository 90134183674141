import { Announced, ColumnActionsMode, CommandBar, css, FontClassNames, Link, SelectionMode } from '@fluentui/react';
import { ResponsiveMode, withResponsiveMode } from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import { QosProvider } from '@iamexperiences/ecos-telemetry';
import { ErrorBanner } from '@microsoft/portal-app/lib/Banners/ErrorBanner';
import * as React from 'react';
import { TranslationFunction } from 'react-i18next';

import { EntitlementActions } from '../../../models';
import { IEntity } from '../../../models/ELM/IEntity';
import { IGrantRequest } from '../../../models/ELM/IGrantRequest';
import { RequestState } from '../../../models/ELM/RequestState';
import { RequestStatus } from '../../../models/ELM/RequestStatus';
import { getSimpleRequestTypeFromRequestType } from '../../../models/ELM/RequestType';
import { EntityType } from '../../../models/EntityType';
import { IListColumn } from '../../../models/IListColumn';
import { history } from '../../../redux/configureStore';
import { FormatDateTime } from '../../../shared/FormatDateTime';
import { getRequestorName, getTargetName } from '../../../shared/getUserStrings';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { Routes } from '../../../shared/Routes';
import { getNewColumnsOnColumnClicked } from '../../../shared/sortingHelper';
import { getSpinner } from '../../../shared/spinner';
import { ConfirmDialog } from '../..//Shared/ConfirmDialog/ConfirmDialog';
import { ColumnValue } from '../../Shared/ColumnValue/ColumnValue';
import { ConnectedGrantRequestApproverInfo } from '../../Shared/GrantRequestApproverInfo';
import { ConnectedGrantRequestMoreDetails } from '../../Shared/GrantRequestMoreDetails';
import { InfinityList } from '../../Shared/InfinityList/InfinityList';
import { Subtitle } from '../../Shared/Subtitle/Subtitle';
import { ConnectedGrantRequestDetails } from '../GrantRequestDetails';
import { ConnectedGrantRequestFilter } from '../GrantRequestFilter';
import { getGrantRequestListCommands, getGrantRequestListFarCommands } from './GrantRequestList.commands';
import { IGrantRequestListProps, IGrantRequestListState } from './GrantRequestList.types';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');
const myAccessListStyles = require('../../../css/myAccessList.scoped.scss');
const globalStyles = require('../../../css/global.scss');

/* Represent the list of request history. */
@withResponsiveMode
export class GrantRequestList extends React.Component<IGrantRequestListProps, IGrantRequestListState> {
  private _selectedItem: IGrantRequest | null = null;

  constructor(nextProps: IGrantRequestListProps) {
    super(nextProps);
    nextProps.showFiltersIcon(false);
    this.state = {
      entitlement: undefined,
      grantRequest: undefined,
      commands: getGrantRequestListCommands(this.props.t, this.props, undefined),
      columns: this._getGrantRequestListColumns(this._getResponsiveMode(), this.props.t),
      showingMoreGrantRequestDetails: false,
      showingApproverInfo: false,
      pendingApprovalIndex: 0
    };
  }

  public componentDidMount(): void {
    this.props.setSearchContext(EntitlementActions.searchGrantRequestsOnServer);
    this.props.refreshGrantRequests();
  }

  public componentWillUnmount(): void {
    if (this.props.showingGrantRequestDetails) {
      this.props.dismissGrantRequestDetails();
    }

    if (this.props.showingGrantRequestFilter) {
      this.props.dismissGrantRequestFilter();
    }
  }

  public render(): JSX.Element {
    const {
      isLoading,
      isRefreshing,
      errorHasOccurred,
      isLoadingMore,
      showingConfirmDialog,
      t,
      isTenantWhitelisted,
      isSubmitting,
      responsiveMode
    } = this.props;
    if (isRefreshing) {
      return getSpinner(
        t(LocaleKeys.loadingPage, {
          pageName: t(LocaleKeys.request, { context: 'plural' })
        })
      );
    }

    if (errorHasOccurred) {
      if (!isTenantWhitelisted) {
        return (
          <main data-automation-id="GrantRequestListPage" className={css(globalStyles.detailsPage)}>
            <ErrorBanner text={t(LocaleKeys.tenantNotWhitelistedMessage)} />{' '}
          </main>
        );
      }

      return (
        <main data-automation-id="GrantRequestListPage" className={css(globalStyles.detailsPage)}>
          <ErrorBanner
            text={t(LocaleKeys.errorMessage)}
            onAction={this.props.getEntities}
            actionText={t(LocaleKeys.retry)}
          />
        </main>
      );
    }

    const grantRequests = this.props.grantRequestList as IGrantRequest[];
    const { isSearching, isFiltering, pageMetaData, searchTerm } = this.props;

    const farCommands = getGrantRequestListFarCommands(this.props.t, this.props);

    const filteredCount = pageMetaData.filteredEntityCount ? pageMetaData.filteredEntityCount : 0;

    const showNoEntities =
      pageMetaData.allEntityCount === 0 && !this.props.isRefreshing && !isFiltering && !isSearching;

    const showNoFilteredResults = !isLoading && (isFiltering || isSearching) && filteredCount === 0;

    const showLoadMore =
      !pageMetaData.isAllEntitiesFullyCached &&
      !isLoading &&
      !isLoadingMore &&
      !pageMetaData.isFilteredEntitiesFullyCached;

    return (
      <QosProvider name="RequestHistory">
        <main data-automation-id="GrantRequestListPage" className={css(globalStyles.detailsPage)}>
          <div className={css(myAccessListStyles.listPage, myAccessListStyles.padding)}>
            <h1 className={css(myAccessListStyles.pageTitle, myAccessListStyles.pageTitleH1Margin)}>
              {t(LocaleKeys.requestHistory)}
            </h1>
            <div
              id="pageSubtitle"
              role="heading"
              aria-labelledby="pageSubtitle"
              className={css(myAccessStyles.marginTopXSmall, myAccessListStyles.pageSubtitle)}
            >
              <Subtitle
                isSearching={isSearching}
                isFiltering={isFiltering}
                pageMetaData={pageMetaData}
                searchTerm={searchTerm}
                entityName={LocaleKeys.request}
                t={this.props.t}
              />
            </div>
            <div className={css(myAccessStyles.marginTopSmall)}>
              <CommandBar
                className={css(myAccessListStyles.commandBar)}
                items={this.state.commands!}
                farItems={farCommands}
              />
            </div>
            {!this.props.isLoading && isSearching && <Announced message={`${filteredCount} items found`} />}
            <InfinityList
              t={t}
              entityList={grantRequests}
              entityType={EntityType.grantRequests}
              ariaLabel={'List of requests.'}
              columns={this.state.columns as Array<IListColumn<IEntity>>}
              showLoadMore={showLoadMore}
              showSpinner={isLoadingMore}
              spinnerLabel={t(LocaleKeys.loadingPage, {
                pageName: t(LocaleKeys.request, { context: 'plural' })
              })}
              showNoEntities={showNoEntities}
              noEntitiesProps={{
                iconName: 'Unlock',
                noRowMessage: LocaleKeys.noRequestsMessage,
                showButton: true,
                buttonText: LocaleKeys.requestAccess,
                onButtonClick: () => history.push(Routes.accessPackages)
              }}
              showNoFilteredResults={showNoFilteredResults}
              onLoadMore={this._loadMore}
              onItemSelected={this._onItemSelected}
              selectionMode={SelectionMode.single}
              isExpanded={false}
            />
            {showingConfirmDialog ? (
              <ConfirmDialog
                t={t}
                title={LocaleKeys.cancelMyRequest}
                isSubmitting={isSubmitting}
                subText={LocaleKeys.cancelMyRequestConfirmMessage}
                onYes={this._patchGrantRequest}
                onDismiss={this.props.dismissConfirmDialog}
                responsiveMode={responsiveMode}
              />
            ) : null}
            {this.props.showingGrantRequestDetails ? (
              <ConnectedGrantRequestDetails
                grantRequestId={this.state.grantRequest! && this.state.grantRequest.id}
                onDismiss={this.props.dismissGrantRequestDetails}
                onCancelClick={this._afterCancelGrantRequest}
                showMoreGrantRequestDetails={this._showMoreGrantRequestDetails}
                showApproverInfo={this._showApproverInfo}
              />
            ) : null}

            {this.state.showingMoreGrantRequestDetails ? (
              <ConnectedGrantRequestMoreDetails
                fetchEntityType={EntityType.grantRequests}
                grantRequest={this.state.grantRequest!}
                onDismiss={this._hideMoreGrantRequestDetails}
              />
            ) : null}

            <ConnectedGrantRequestFilter onDismiss={this.props.dismissGrantRequestFilter} />

            {this.state.showingApproverInfo ? (
              <ConnectedGrantRequestApproverInfo
                grantRequest={this.state.grantRequest!}
                onDismiss={this._hideApproverInfo}
                index={this.state.pendingApprovalIndex!}
              />
            ) : null}

            <ConnectedGrantRequestFilter onDismiss={this.props.dismissGrantRequestFilter} />
          </div>
        </main>
      </QosProvider>
    );
  }

  private readonly _showMoreGrantRequestDetails = (): void => {
    this.setState({ showingMoreGrantRequestDetails: true });
  };

  private readonly _hideMoreGrantRequestDetails = (): void => {
    this.setState({ showingMoreGrantRequestDetails: false });
    this.props.showGrantRequestDetails();
  };

  private readonly _showApproverInfo = (index: number): void => {
    this.setState({
      showingApproverInfo: true,
      pendingApprovalIndex: index
    });
  };

  private readonly _hideApproverInfo = (): void => {
    this.setState({ showingApproverInfo: false });
    this.props.showGrantRequestDetails();
  };

  private readonly _patchGrantRequest = (): void => {
    if (!this._selectedItem) {
      return;
    }
    const grantRequest = this._selectedItem;
    const updatedGrantRequest: Partial<IGrantRequest> = {
      id: grantRequest.id,
      requestStatus: RequestStatus.Canceled
    };
    this.props.cancelGrantRequest(updatedGrantRequest);
    this._afterCancelGrantRequest();
  };

  private readonly _onItemSelected = (selectedItems: IGrantRequest[]): void => {
    if (selectedItems && selectedItems.length > 0) {
      this._selectedItem = selectedItems[0];
    }
    this.setState({
      commands: getGrantRequestListCommands(this.props.t, this.props, selectedItems)
    });
  };

  private readonly _loadMore = (): void => {
    if (!this.props.isLoading) {
      if (this.props.isSearching) {
        this._searchEntitiesOnServer();
      }
      if (this.props.isFiltering) {
        this._filterEntitiesOnServer();
      } else {
        this._getEntities();
      }
    }
  };

  private readonly _getEntities = (): void => {
    if (this.props.pageMetaData.isAllEntitiesFullyCached || this.props.isLoading) {
      return;
    }
    this.props.getEntities();
  };

  private readonly _searchEntitiesOnServer = (): void => {
    if (this.props.pageMetaData.isAllEntitiesFullyCached || this.props.pageMetaData.isFilteredEntitiesFullyCached) {
      return;
    }
    this.props.searchForMore();
  };

  private readonly _filterEntitiesOnServer = (): void => {
    if (this.props.pageMetaData.isAllEntitiesFullyCached || this.props.pageMetaData.isFilteredEntitiesFullyCached) {
      return;
    }
    this.props.filterEntitiesOnServer(this.props.filter);
  };

  private readonly _afterCancelGrantRequest = (): void => {
    this.setState({
      commands: getGrantRequestListCommands(this.props.t, this.props, undefined)
    });
  };

  private readonly _onViewDetailsClicked = (grantRequest: IGrantRequest): void => {
    this.setState({
      grantRequest
    });
    this.props.showGrantRequestDetails();
  };

  private _getResponsiveMode(): ResponsiveMode {
    let { responsiveMode } = this.props;
    if (responsiveMode === undefined) {
      responsiveMode = ResponsiveMode.large;
    }
    return responsiveMode;
  }

  private readonly _getGrantRequestListColumns = (
    responsiveMode: ResponsiveMode,
    t: TranslationFunction
  ): Array<IListColumn<IGrantRequest>> => {
    const entitlementColumn: IListColumn<IGrantRequest> = {
      key: 'accessPackageAssignment/accessPackage/displayName',
      name: t(LocaleKeys.name),
      fieldName: 'accessPackageAssignment',
      minWidth: 100,
      maxWidth: 200,
      className: 'ms-pii',
      headerClassName: FontClassNames.smallPlus,
      columnActionsMode: ColumnActionsMode.disabled,
      isResizable: true,
      isRowHeader: true,
      onRender: (item: IGrantRequest) => {
        const entitlementName = item.accessPackageAssignment.accessPackage!.displayName!;
        return (
          <ColumnValue
            searchTerm={this.props.searchTerm}
            columnValue={entitlementName}
            isHighlightRequired={true}
            isSearching={this.props.isSearching}
          />
        );
      }
    } as IListColumn<IGrantRequest>;

    const columns: Array<IListColumn<IGrantRequest>> = [];
    columns.push(entitlementColumn);

    if (responsiveMode > ResponsiveMode.medium) {
      columns.push({
        key: 'requestState',
        name: t(LocaleKeys.requestState),
        fieldName: 'requestState',
        minWidth: 60,
        maxWidth: 120,
        headerClassName: FontClassNames.smallPlus,
        columnActionsMode: ColumnActionsMode.disabled,
        isResizable: true,
        onRender: (item: IGrantRequest) => {
          let localeKey = RequestState[item.requestState];
          if (
            RequestState[item.requestState] === RequestState.Delivered ||
            RequestState[item.requestState] === RequestState.Delivering
          ) {
            localeKey += `.${getSimpleRequestTypeFromRequestType(item.requestType)}`;
          }

          return (
            <ColumnValue
              searchTerm={this.props.searchTerm}
              columnValue={t(localeKey)}
              isHighlightRequired={false}
              isSearching={false}
            />
          );
        }
      } as IListColumn<IGrantRequest>);
    }

    if (responsiveMode > ResponsiveMode.xLarge) {
      columns.push({
        key: 'requestedBy',
        name: t(LocaleKeys.requestedBy),
        fieldName: 'requestor',
        minWidth: 60,
        maxWidth: 148,
        isResizable: true,
        headerClassName: FontClassNames.smallPlus,
        columnActionsMode: ColumnActionsMode.disabled,
        onRender: (item: IGrantRequest) => {
          return (
            <ColumnValue
              searchTerm={this.props.searchTerm}
              columnValue={getRequestorName(item.requestor, item.requestType, t)}
              isHighlightRequired={false}
              isSearching={false}
            />
          );
        }
      });
    }

    if (responsiveMode > ResponsiveMode.xLarge) {
      columns.push({
        key: 'requestedFor',
        name: t(LocaleKeys.requestedFor),
        fieldName: 'target',
        minWidth: 60,
        maxWidth: 148,
        isResizable: true,
        headerClassName: FontClassNames.smallPlus,
        columnActionsMode: ColumnActionsMode.disabled,
        onRender: (item: IGrantRequest) => {
          return (
            <ColumnValue
              searchTerm={this.props.searchTerm}
              columnValue={getTargetName(t, item.accessPackageAssignment.target)}
              isHighlightRequired={false}
              isSearching={false}
            />
          );
        }
      });
    }

    if (responsiveMode > ResponsiveMode.xLarge) {
      columns.push({
        key: 'accessPackageAssignment/accessPackage/description',
        name: t(LocaleKeys.description),
        fieldName: 'accessPackageAssignment',
        minWidth: 60,
        maxWidth: 261,
        className: 'ms-pii',
        isResizable: true,
        headerClassName: FontClassNames.smallPlus,
        columnActionsMode: ColumnActionsMode.disabled,
        onRender: (item: IGrantRequest) => (
          <ColumnValue
            searchTerm={this.props.searchTerm}
            columnValue={item.accessPackageAssignment.accessPackage!.description!}
            isHighlightRequired={false}
            isSearching={false}
          />
        )
      } as IListColumn<IGrantRequest>);
    }

    if (responsiveMode > ResponsiveMode.small) {
      columns.push({
        key: 'createdDateTime',
        name: t(LocaleKeys.dateRequested),
        fieldName: 'createdDateTime',
        minWidth: 100,
        maxWidth: 178,
        onColumnClick: this._onColumnClick,
        headerClassName: FontClassNames.smallPlus,
        isSorted: true,
        isSortedDescending: true,
        isResizable: true,
        onRender: (item: IGrantRequest) => (
          <span className={css('ms-pii', FontClassNames.medium)}>{FormatDateTime(item.createdDateTime)}</span>
        )
      });
    }
    columns.push({
      key: 'viewDetails',
      name: '',
      fieldName: 'id',
      minWidth: 30,
      maxWidth: 30,
      headerClassName: FontClassNames.smallPlus,
      columnActionsMode: ColumnActionsMode.disabled,
      isResizable: true,
      onRender: (item: IGrantRequest) => (
        <Link
          // tslint:disable-next-line:jsx-no-lambda
          onClick={() => this._onViewDetailsClicked(item)}
          className={css(FontClassNames.medium, myAccessStyles.themeDarkFont)}
        >
          {t(LocaleKeys.view)}
        </Link>
      )
    });
    return columns;
  };

  private readonly _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IListColumn<IGrantRequest>): void => {
    ev.preventDefault();
    column.isSorted = true;
    column.isSortedDescending = !column.isSortedDescending;

    this.setState({
      columns: getNewColumnsOnColumnClicked(this.state.columns, column)
    });
    this.props.setSortedByColumn(column);
    if (!this.props.isSearching && !this.props.isFiltering) {
      this.props.sortEntities(column.key, !column.isSortedDescending);
    } else {
      this.props.sortFilteredEntities(column.key, !column.isSortedDescending, this.props.searchTerm, this.props.filter);
    }
  };
}
