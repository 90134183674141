import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { translate } from 'react-i18next';
import { connect, Dispatch } from 'react-redux';
import { IAccessReviewDecision } from '../../../models/AccessReviews/IAccessReviewDecision';
import { ISubmitDecision } from '../../../models/AccessReviews/ISubmitDecision';
import { EntitlementActions } from '../../../models';
import { EntityType } from '../../../models/EntityType';
import { IRootEntitlementsState } from '../../../models/IEntitlementState';
import { IFilter } from '../../../models/IFilter';
import { LoadMode } from '../../../models/IPageData';
import { GetEntityList } from '../../../shared/GetEntityList';
import { getPageMetaDataFromEntities } from '../../../shared/getPageMetaDataFromEntities';
import { UserCentricPanel } from './UserCentricPanel';
import {
  IConnectedUserCentricPanelProps,
  IUserCentricPanelActions,
  IUserCentricPanelMappedProps
} from './UserCentricPanel.types';

/**
 * Maps the external (i.e. own) props and the state to the properties of the UserCentricPanel control.
 * @param state The current redux state to map to props
 * @param ownProps The external properties to map to props
 * @returns The mapped properties that along with the actions
 * becomes the props (IUserCentricPanelProps) for the UserCentricPanel control
 */
const mapStateToProps = (
  state: IRootEntitlementsState,
  ownProps: IConnectedUserCentricPanelProps
): IUserCentricPanelMappedProps => {
  const {
    secondaryDecisions,
    searchTerm,
    errorHasOccurred,
    errorCode,
    isTenantWhitelisted,
    showingConfirmDialog,
    showingReviewDecisionsFilter,
    showingReviewDetails,
    showingUserCentricPanel,
    showingDecisionDetails,
    showingBulkDecisionDialog,
    showingResetDecisions,
    showingAcceptRecommendations,
    submitting,
    bulkDecisionType
  } = state.app;

  const hidden = !state.app.showingUserCentricPanel;

  const isFiltering = false;
  const loadMode = secondaryDecisions.loadMode!;
  const pageMetaData = getPageMetaDataFromEntities(secondaryDecisions);

  const entitiesList = GetEntityList(
    secondaryDecisions,
    isFiltering // Search can be placed here if design needs it (see DecisionsList)
  );

  const secondaryList: IAccessReviewDecision[] = entitiesList.filteredEntities.reduce(
    (decisionList: IAccessReviewDecision[], key: string) => {
      const item = secondaryDecisions.entitiesById.get(key);
      if (item !== undefined) {
        decisionList.push(item);
      }
      return decisionList;
    },
    []
  );

  return {
    ...ownProps,
    hidden,
    isSubmitting: submitting,
    secondaryDecisionList: secondaryList,
    isLoading: secondaryDecisions.isLoading,
    isRefreshing: loadMode === LoadMode.Refresh,
    isLoadingMore: loadMode === LoadMode.LoadMore,
    errorHasOccurred,
    errorCode,
    pageMetaData,
    showingBulkDecisionDialog: showingBulkDecisionDialog!,
    showingResetDecisions: showingResetDecisions!,
    showingAcceptRecommendations: showingAcceptRecommendations!,
    bulkDecisionType: bulkDecisionType!
  };
};

/**
 * Maps the dispatch actions to the props for the UserCentricPanel control.
 * @param dispatch Dispatches actions to the store
 * @returns The mapped actions that along with the mapped properties
 * becomes the props (IUserCentricPanelProps) for the UserCentricPanelFilter control
 */
const mapDispatchToProps = (
  dispatch: Dispatch<IRootEntitlementsState>
): IUserCentricPanelActions => {
  const getEntitiesAction = createAction<{ entityType: string, id: string }>(
    EntitlementActions.getEntities,
    dispatch
  );
  const filterEntitiesAction = createAction<{
    entityType: string;
    filter: IFilter | undefined;
  }>(EntitlementActions.filterEntities, dispatch);
  const filterEntitiesOnServerAction = createAction<{
    entityType: string;
    filterContext: IFilter | undefined;
  }>(EntitlementActions.filterEntitiesOnServer, dispatch);
  const showBulkDecisionDialog = createAction<{
    isShowing: boolean;
    decisionType?: string;
  }>(EntitlementActions.showBulkDecisionDialog, dispatch);
  const showResetDecisions = createAction<boolean>(
    EntitlementActions.showResetDecisions,
    dispatch
  );
  const showAcceptRecommendations = createAction<boolean>(
    EntitlementActions.showAcceptRecommendations,
    dispatch
  );
  return {
    getEntities: (principalId: string) =>
      getEntitiesAction({
        entityType: EntityType.secondaryDecisions,
        id: principalId
      }),
    filterEntities: (filter: IFilter | undefined) =>
      filterEntitiesAction({
        entityType: EntityType.grantRequests,
        filter: filter
      }),
    filterEntitiesOnServer: (filter: IFilter | undefined) =>
      filterEntitiesOnServerAction({
        entityType: EntityType.grantRequests,
        filterContext: filter
      }),
    submitDecision: createAction<ISubmitDecision[]>(
      EntitlementActions.submitDecision,
      dispatch
    ),
    dismissBulkDecisionDialog: () =>
      showBulkDecisionDialog({ isShowing: false }),
    showBulkDecisionDialog: (isShowing: boolean, decisionType?: string) =>
      showBulkDecisionDialog({
        isShowing: isShowing,
        decisionType: decisionType
      }
      ),
    showResetDecisions: () => showResetDecisions(true),
    dismissResetDecisions: () => showResetDecisions(false),
    showAcceptRecommendations: () => showAcceptRecommendations(true),
    dismissAcceptRecommendations: () => showAcceptRecommendations(false),
  };
};

/**
 * A redux connected UserCentricPanel control.
 * @description Supports translation
 */
export const ConnectedUserCentricPanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('MyAccess')(UserCentricPanel));
