import { IAjaxRequest } from '@microsoft/portal-app/lib/auth/withAuth';
import { getResponseValue, IODataValueResponse } from '@microsoft/portal-app/lib/odata-utils';
import { auth } from '../../../auth';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { Action, MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';
import { IEntity } from '../../../models/ELM/IEntity';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import {
  IEntitlementState,
  IRootEntitlementsState
} from '../../../models/IEntitlementState';
import { getRequestWithAudience } from '../../../shared/AttachAudience';
import { registry } from '../myAccessRegistry';
import { getMyFeaturesApiUrl } from '../../../shared/getApiUrl';
import { getCacheKey, setOrUpdateWithExpiry } from '../../../shared/storage/localStorageUtil';
import { AdminOptInFeatureKey, IAdminOptInFeature } from '../../../shared';

/** This is to refresh the local storage feature value */
export const refreshAdminOptInFeatureEpic: Epic<
  IEntitlementAction<AnyPayload>,
  IRootEntitlementsState
> = (
  action$: ActionsObservable<Action>,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
  return action$
    .ofType(EntitlementActions.refreshAdminOptInFeatures)
    .switchMap((_action: IEntitlementAction) => {
      const ajaxRequest: IAjaxRequest = getRequestWithAudience(getMyFeaturesApiUrl());
      return (
        ajax(ajaxRequest)
          .map((payload: IODataValueResponse<ReadonlyArray<IEntity>>) => {
            return {
              type: EntitlementActions.refreshAdminOptInFeaturesSucceeded,
              payload: {
                features: getResponseValue(payload)
              }
            };
          })
          // tslint:disable-next-line:no-any
          .catch((_error: any) =>
            Observable.of({
              type: EntitlementActions.refreshAdminOptInFeaturesFailed,
              payload: _error
            })
          )
      );
    });
};
registry.addEpic('refreshAdminOptInFeatureEpic', refreshAdminOptInFeatureEpic);

export const refreshAdminOptInFeaturesSucceeded = (
  state: IEntitlementState,
  action: IEntitlementAction<any>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined || action.payload.features === null) {
    return state;
  }

  const adminOptInFeatures: IAdminOptInFeature[] = action.payload?.features;

  // Update local storage
  for (const optInFeature of adminOptInFeatures) {
    const tid = auth.user()?.tenantId;
    const cacheKey = getCacheKey(AdminOptInFeatureKey[optInFeature.id], tid);
    setOrUpdateWithExpiry(cacheKey, optInFeature.isEnabled, 12*60*60); // TTL in seconds
  }

  return {
    ...state
  };
};

registry.add(
  EntitlementActions.refreshAdminOptInFeaturesSucceeded,
  refreshAdminOptInFeaturesSucceeded
);

export const refreshAdminOptInFeaturesFailed = (
  state: IEntitlementState,
  // tslint:disable-next-line:no-any
  action: IEntitlementAction
): Readonly<IEntitlementState> => {
  return state;
};
registry.add(
  EntitlementActions.refreshAdminOptInFeaturesFailed,
  refreshAdminOptInFeaturesFailed
);