import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { translate } from 'react-i18next';
import { connect, Dispatch } from 'react-redux';
import { EntitlementActions } from '../../../models';
import { ICatalog } from '../../../models/ELM/ICatalog';
import { EntityType } from '../../../models/EntityType';
import { IRootEntitlementsState } from '../../../models/IEntitlementState';
import { IFilter } from '../../../models/IFilter';
import { GetEntityList } from '../../../shared/GetEntityList';
import { getPageMetaDataFromEntities } from '../../../shared/getPageMetaDataFromEntities';
import { GrantFilter } from './GrantFilter';
import {
  IConnectedGrantFilterProps,
  IGrantFilterActions,
  IGrantFilterMappedProps
} from './GrantFilter.types';

/**
 * Maps the external (i.e. own) props and the state to the properties of the GrantFilter control.
 * @param state The current redux state to map to props
 * @param ownProps The external properties to map to props
 * @returns The mapped properties that along with the actions
 * becomes the props (IGrantFilterProps) for the GrantFilter control
 */
const mapStateToProps = (
  state: IRootEntitlementsState,
  ownProps: IConnectedGrantFilterProps
): IGrantFilterMappedProps => {
  const { catalogs, validGrants } = state.app;
  const entitiesList = GetEntityList(catalogs, false);
  const catalogList: ICatalog[] = entitiesList.filteredEntities.reduce(
    (catalogsList: ICatalog[], key: string) => {
      const item = catalogs.entitiesById.get(key);
      if (item !== undefined) {
        catalogsList.push(item);
      }
      return catalogsList;
    },
    []
  );
  const hidden = !state.app.showingGrantFilter;
  const isCatalogsLoading = state.app.catalogs.isLoading;

  const selectedCatalogs =
    validGrants.filterContext && validGrants.filterContext.selectedCatalogs;
  const pageMetaData = getPageMetaDataFromEntities(validGrants);
  const catalogsPageMetaData = getPageMetaDataFromEntities(catalogs);
  return {
    ...ownProps,
    isCatalogsLoading,
    catalogList,
    hidden,
    selectedCatalogs,
    pageMetaData,
    catalogsPageMetaData
  };
};

/**
 * Maps the dispatch actions to the props for the GrantFilter control.
 * @param dispatch Dispatches actions to the store
 * @returns The mapped actions that along with the mapped properties
 * becomes the props (IGrantFilterProps) for the GrantFilter control
 */
const mapDispatchToProps = (
  dispatch: Dispatch<IRootEntitlementsState>
): IGrantFilterActions => {
  const getEntities = createAction<{ entityType: string }>(
    EntitlementActions.getEntities,
    dispatch
  );
  const filterEntitiesAction = createAction<{
    entityType: string;
    filter: IFilter | undefined;
  }>(EntitlementActions.filterEntities, dispatch);
  const filterEntitiesOnServerAction = createAction<{
    entityType: string;
    filterContext: IFilter | undefined;
  }>(EntitlementActions.filterEntitiesOnServer, dispatch);
  return {
    getCatalogs: () =>
      getEntities({
        entityType: EntityType.catalogs
      }),
    filterEntities: (filter: IFilter | undefined) =>
      filterEntitiesAction({
        entityType: EntityType.validGrants,
        filter: filter
      }),
    filterEntitiesOnServer: (filter: IFilter | undefined) =>
      filterEntitiesOnServerAction({
        entityType: EntityType.validGrants,
        filterContext: filter
      })
  };
};

/**
 * A redux connected GrantFilter control.
 * @description Supports translation
 */
export const ConnectedGrantFilter = connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('MyAccess')(GrantFilter));
