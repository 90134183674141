import { User } from '@microsoft/microsoft-graph-types';
import { ILoadable } from '@microsoft/portal-app/lib/models/ILoading';
import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { useDispatch } from 'react-redux';

import { EntitlementActions } from '../../models';
import { GetPeopleRequestType } from '../../redux';
import { useSelector } from '../index';

interface IUseGetPeople {
  getDirectReports: () => void;
  getCoworkers: () => void;
  loadableDirectReports: ILoadable<readonly User[]>;
  loadableCoworkers: ILoadable<readonly User[]>;
}

export const useGetPeople = (): IUseGetPeople => {
  const dispatch = useDispatch();
  const { directReports, coworkers } = useSelector((state) => ({
    directReports: state?.app?.directReports,
    coworkers: state?.app?.coworkers
  }));

  const getPeople = createAction(EntitlementActions.getPeople, dispatch);

  return {
    getDirectReports: () => getPeople({ requestType: GetPeopleRequestType.directReports }),
    getCoworkers: () => getPeople({ requestType: GetPeopleRequestType.coworkers }),
    loadableDirectReports: directReports,
    loadableCoworkers: coworkers
  };
};
