import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { useDispatch } from 'react-redux';

import { EntitlementActions, EntityType, IEntitlement } from '../models';
import { IGetEntityActionPayload } from '../redux/modules/get/getEntity';
import { useSelector } from '.';

interface IGetEntity {
  getEntitlement: (id: string) => void;
  entitlementsById: ReadonlyMap<string, IEntitlement>;
  entityLoadingList: { [key: string]: boolean };
  isLoadingEntity: boolean;
  errorHasOccurred: boolean;
}

export const useGetEntity = (): IGetEntity => {
  const { entitlementsById, entityLoadingList, isLoadingEntity, errorHasOccurred } = useSelector((state) => ({
    entitlementsById: state.app.entitlements.entitiesById,
    entityLoadingList: state.app.entityLoadingList,
    isLoadingEntity: state.app.isEntityDetailLoading,
    errorHasOccurred: state.app.errorHasOccurred
  }));
  const dispatch = useDispatch();
  const getEntity = createAction<IGetEntityActionPayload>(EntitlementActions.getEntity, dispatch);
  const getEntitlement = (id: string): void => {
    getEntity({ entityId: id, entityType: EntityType.entitlements });
  };
  return { getEntitlement, entitlementsById, entityLoadingList, isLoadingEntity, errorHasOccurred };
};
