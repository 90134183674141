import { css, ITextProps, Link, TooltipHost } from '@fluentui/react';
import React, { useState } from 'react';

import { CopyButton } from '../../../components/Shared/CopyButton/CopyButton';
const styles = require('./TruncatedText.scoped.scss');

interface Props {
  children: JSX.Element;
}

export function TruncatedText({ children }: Props): JSX.Element {
  const [expanded, setExpanded] = useState(false);

  const StyledChildren = (): JSX.Element =>
    React.Children.map(children, (child: JSX.Element) =>
      React.cloneElement(child, {
        className: css(
          child.props.className,
          styles.truncated,
          expanded && styles.untruncated
        ),
        onClick: () => setExpanded(true),
      })
    ) as {} as JSX.Element;

  return <StyledChildren />;
}

interface TruncatedTextSingleLineProps {
  text: string;
  textComponentProps: ITextProps;
  readMoreLabel?: string;
}

function isOverflowActive(element: HTMLParagraphElement): boolean {
  return element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth;
}

export function TruncatedTextSingleLine({ text, textComponentProps, readMoreLabel }: TruncatedTextSingleLineProps): JSX.Element {
  const [expanded, setExpanded] = useState(false);
  const [overflowActive, setOverflowActive] = useState(false);

  const textRef = React.useRef<HTMLParagraphElement>(null);
  const textProps = {
    ...textComponentProps,
    className: `${textComponentProps.className ?? ''} ${css(styles.truncated, expanded && styles.untruncated)}`
  }
  // default to text onclick if no readMoreLabel is provided
  if (!readMoreLabel) {
    textProps.onClick = () => setExpanded(true);
  }
  const textId = `truncated-text-${text}`

  React.useEffect(() => {
    const textElement = textRef.current;
    if (!readMoreLabel || !textElement) {
      return;
    }
    const isActive = isOverflowActive(textElement);
    setOverflowActive(isActive);
  }, [readMoreLabel, textRef]);

  return (
    <>
      <span ref={textRef} id={textId} {...textProps}>{text}</span>
      {readMoreLabel && !expanded && overflowActive && <Link hidden aria-expanded={false} aria-controls={textId} onClick={() => setExpanded(true)}>{readMoreLabel}</Link>}
    </>
  );
}

interface TruncatedTextWithCopyProps {
  children: JSX.Element;
  toolTipContent: JSX.Element;
  copyValue: string;
}

export function TruncatedTextWithCopy({ children, toolTipContent, copyValue }: TruncatedTextWithCopyProps): JSX.Element {

  return <div style={{ display: 'flex', alignItems: 'center' }}>
    <TooltipHost
      content={toolTipContent}
      // This id is used on the tooltip itself, not the host
      // (so an element with this id only exists when the tooltip is shown)
      id={copyValue}
    >
      <div className={styles.truncated}>{children}</div>
    </TooltipHost>
    <CopyButton value={copyValue} />
  </div>;
}