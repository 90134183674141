import { translate } from 'react-i18next';
import { connect, Dispatch } from 'react-redux';

import { createAction } from '@microsoft/portal-app/lib/redux/createAction';

import {
  EntitiesAction,
  EntitlementActions,
  EntityType,
  IPageMetaData,
  IRootEntitlementsState,
  LoadMode,
} from '../../../models';
import {
  GetEntityList,
  getPageMetaDataFromEntities,
  isEmptyOrUndefined,
} from '../../../shared';
import { CatalogsList } from './CatalogsList';
import {
  ICatalogsListActions,
  ICatalogsListStateProps,
} from './CatalogsList.types';

const mapStateToProps = (
  state: IRootEntitlementsState
): ICatalogsListStateProps => {
  const {
    errorHasOccurred,
    isTenantWhitelisted,
    catalogs: catalogEntities,
    searchTerm,
  } = state.app;

  const isSearching = !isEmptyOrUndefined(searchTerm);

  const entitiesList = GetEntityList(catalogEntities, isSearching);

  const catalogs = entitiesList.filteredEntities.map(
    (key) => catalogEntities.entitiesById.get(key)!
  );

  const pageMetaData: IPageMetaData = getPageMetaDataFromEntities(
    catalogEntities
  );

  const loadMode = catalogEntities.loadMode!;

  return {
    catalogs,
    errorHasOccurred,
    isTenantWhitelisted,
    isSearching,
    pageMetaData,
    isLoading: catalogEntities.isLoading,
    isLoadingInitial: loadMode === LoadMode.Initial,
    isRefreshing: loadMode === LoadMode.Refresh,
    isLoadingMore: loadMode === LoadMode.LoadMore,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<IRootEntitlementsState>
): ICatalogsListActions => {
  // Action creator to get more entities
  const getEntities = createAction<EntitiesAction>(
    EntitlementActions.getEntities,
    dispatch
  );

  // Action creator to search for more entities.
  const searchForMore = createAction<EntitiesAction>(
    EntitlementActions.searchForMore,
    dispatch
  );

  // Action creator to load the first page of entities
  const refreshEntities = createAction<EntitiesAction>(
    EntitlementActions.refreshEntities,
    dispatch
  );

  const catalogAction = {
    entityType: EntityType.catalogs,
  };

  return {
    getMoreCatalogs: () => getEntities(catalogAction),
    searchMoreCatalogs: () => searchForMore(catalogAction),
    refreshCatalogs: () => refreshEntities(catalogAction),
    setSearchContext: createAction<string | null>(
      EntitlementActions.setSearchContext,
      dispatch
    ),
    showFiltersIcon: createAction<boolean>(
      EntitlementActions.showSearchFiltersIcon,
      dispatch
    ),
  };
};

export const ConnectedCatalogsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('MyAccess')(CatalogsList));
