import { IErrorDialog } from '../../../models/RAM/IErrorDialog';
import { IVirtualMachine, IVirtualMachineExtendedDetails } from '../../../models/RAM/IVirtualMachine';
import { OsTypeEnum } from '../../../models/RAM/OsTypeEnum';
import { ResourceTypeEnum } from '../../../models/RAM/ResourceTypeEnum';
import { QuickActivationDialogRole } from '../QuickActivation/QuickActivationDialog';

export interface ConnectionDialogProps {
  onDismiss: VoidFunction;
  virtualMachine: IVirtualMachine;
  publicIpAddress: string;
  privateIpAddress: string;
  virtualMachineName: string;
  virtualMachineId: string;
  roleEndTime: string;
  userName: string;
  virtualMachineType: ResourceTypeEnum;
  virtualMachineOsType?: OsTypeEnum;
  setRamErrorDialog: (errorDialogContent?: IErrorDialog) => void;
  getVirtualMachineDetails: (virtualMachine?: IVirtualMachine) => void;
  selectedVirtualMachineDetails?: IVirtualMachineExtendedDetails;
  quickActivationRole: QuickActivationDialogRole;
}

export enum ConnectionTypeEnum {
  Rdp = 'rdp',
  Ssh = 'ssh'
}

export interface RdpContentProps {
  virtualMachineName: string;
  publicIpAddress: string;
  privateIpAddress: string;
  userName: string;
}

export enum IpAddressTypeEnum {
  Private = 'private',
  Public = 'public'
}

export interface SshContentProps {
  publicIpAddress: string;
  osType: OsTypeEnum;
  virtualMachineName: string;
  resourceGroupName: string;
  username: string;
}
