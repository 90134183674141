import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { translate } from 'react-i18next';
import { connect, Dispatch } from 'react-redux';
import { EntitlementActions } from '../../../models';
import { EntityType } from '../../../models/EntityType';
import { IRootEntitlementsState } from '../../../models/IEntitlementState';
import { IFilter } from '../../../models/IFilter';
import { getPageMetaDataFromEntities } from '../../../shared/getPageMetaDataFromEntities';
import { AccessReviewDetails } from './AccessReviewDetails';
import {
  IAccessReviewDetailsActions,
  IAccessReviewDetailsMappedProps,
  IConnectedAccessReviewDetailsProps
} from './AccessReviewDetails.types';

/**
 * Maps the external (i.e. own) props and the state to the properties of the GrantRequestFilter control.
 * @param state The current redux state to map to props
 * @param ownProps The external properties to map to props
 * @returns The mapped properties that along with the actions
 * becomes the props (IGrantRequestFilterProps) for the GrantRequestFilter control
 */
const mapStateToProps = (
  state: IRootEntitlementsState,
  ownProps: IConnectedAccessReviewDetailsProps
): IAccessReviewDetailsMappedProps => {
  const hidden = !state.app.showingReviewDetails;

  return {
    ...ownProps,
    hidden,
    reviewerDisplayNames: state.app.reviewerDisplayNames
  };
};

/**
 * Maps the dispatch actions to the props for the AccessReviewDetails control.
 * @param dispatch Dispatches actions to the store
 * @returns The mapped actions that along with the mapped properties
 * becomes the props (IAccessReviewDetailsProps) for the AccessReviewDetails control
 */
const mapDispatchToProps = (
  dispatch: Dispatch<IRootEntitlementsState>
): IAccessReviewDetailsActions => {
  return {

    getReviewerDisplayNames: createAction<string[]>(
      EntitlementActions.getReviewerDisplayNames,
      dispatch
    )
  };
};

/**
 * A redux connected AccessReviewDetails control.
 * @description Supports translation
 */
export const ConnectedAccessReviewDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('MyAccess')(AccessReviewDetails));
