import { IAjaxRequest } from '@microsoft/portal-app/lib/auth/withAuth';
import { LoadingState } from '@microsoft/portal-app/lib/models/ILoading';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';
import { flatMap } from 'rxjs/operators';

import { EntitlementActions } from '../../../models/EntitlementActions';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState, IRootEntitlementsState } from '../../../models/IEntitlementState';
import { IApproverSubstitution } from '../../../models/RequestApprovals/IApproverSubstitution';
import { getApproverSubstitutionApiUrl, getAudience } from '../../../shared';
import { registry } from '../myAccessRegistry';

interface IGetApproverSubstitutionSuccessActionPayload {
  approverSubstitution?: IApproverSubstitution;
}

interface IGetApproverSubstitutionFailActionPayload {
  error?: Error;
}

type IGetApproverSubstitutionTrigger = IEntitlementAction<undefined>
type IGetApproverSubstitutionSuccessAction = IEntitlementAction<IGetApproverSubstitutionSuccessActionPayload>;
type IGetApproverSubstitutionFailAction = IEntitlementAction<IGetApproverSubstitutionFailActionPayload>;
type IGetApproverSubstitutionAction = IGetApproverSubstitutionTrigger | IGetApproverSubstitutionSuccessAction | IGetApproverSubstitutionFailAction;


export const getApproverSubstitutionEpic: Epic<IGetApproverSubstitutionAction, IRootEntitlementsState> = (
  action$: ActionsObservable<IGetApproverSubstitutionAction>,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IGetApproverSubstitutionSuccessAction | IGetApproverSubstitutionFailAction> => {
  return action$.ofType(EntitlementActions.getApproverSubstitution).pipe(
    flatMap((_action) => {
      const request: IAjaxRequest = {
        method: 'GET',
        url: getApproverSubstitutionApiUrl(),
        audience: getAudience()
      };

      return ajax(request)
        .map((payload) => {
          if (!payload?.response?.value) {
            return {
              type: EntitlementActions.getApproverSubstitutionSuccess,
            };
          }
          return {
            type: EntitlementActions.getApproverSubstitutionSuccess,
            payload: {
              approverSubstitution: payload.response.value
            }
          };
        })
        .catch((error: any) =>
          Observable.of<IGetApproverSubstitutionFailAction>({
            type: EntitlementActions.getApproverSubstitutionFailed,
            payload: {
              error
            }
          })
        );
    })
  );
};
registry.addEpic('getApproverSubstitutionEpic', getApproverSubstitutionEpic);

export const getApproverSubstitution = (state: IEntitlementState, _action: IEntitlementAction): IEntitlementState => {
  return {
    ...state,
    approverSubstitution: {
      ...state.approverSubstitution,
      isLoading: true,
      loadingState: LoadingState.loading
    }
  };
};
registry.add(EntitlementActions.getApproverSubstitution, getApproverSubstitution);

export const getApproverSubstitutionSuccess = (
  state: IEntitlementState,
  action: IEntitlementAction<IGetApproverSubstitutionSuccessActionPayload>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined || action.payload?.approverSubstitution === undefined) {
    return {
      ...state,
      approverSubstitution: {
        ...state.approverSubstitution,
        isLoading: false,
        loadingState: LoadingState.loaded,
        value: null
      }
    };
  }

  return {
    ...state,
    approverSubstitution: {
      ...state.approverSubstitution,
      isLoading: false,
      loadingState: LoadingState.loaded,
      value: action.payload?.approverSubstitution
    }
  };
};
registry.add(EntitlementActions.getApproverSubstitutionSuccess, getApproverSubstitutionSuccess);

export const getApproverSubstitutionFailed = (
  state: IEntitlementState,
  action: IEntitlementAction<IGetApproverSubstitutionFailActionPayload>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  return {
    ...state,
    approverSubstitution: {
      ...state.approverSubstitution,
      isLoading: false,
      loadingState: LoadingState.error,
      loadingError: action.payload.error
    }
  };
};
registry.add(EntitlementActions.getApproverSubstitutionFailed, getApproverSubstitutionFailed);
