import {
  ColumnActionsMode,
  FontClassNames,
  FontIcon,
  getTheme,
  IconButton,
  IContextualMenuItem,
  IIconProps,
  Link,
  ResponsiveMode,
  Text
} from '@fluentui/react';
import { ContextualMenuItemType } from '@fluentui/react/lib/ContextualMenu';
import * as React from 'react';

import { IListColumn } from '../../../models/IListColumn';
import { IPimEligibilityScheduleInstance } from '../../../models/RAM/IPimActivationRequestParameters';
import { IVirtualMachine, PimVmLoginRoleNames } from '../../../models/RAM/IVirtualMachine';
import { PimRoleAssignmentStatusEnum } from '../../../models/RAM/PimRoleAssignmentStatusEnum';
import { GridSizes, resizeResponsiveGrid } from '../../../shared';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { ColumnValue } from '../../Shared/ColumnValue/ColumnValue';
import { QuickActivationDialogRole } from '../QuickActivation/QuickActivationDialog';
import { generateQuickConnectColumnItems } from '../QuickConnection/QuickConnection.utils';
import { IVmListProps } from './VmList.types';
import { convertToTitleCase } from './VmList.utils';

const activeCircleFillIconStyles = { color: getTheme().palette.green, marginRight: '8px' };
const eligibleCircleIconStyles = { marginRight: '8px' };
const activeAssignmentTextStyles = { color: getTheme().palette.green };
const activeActionStyles = { cursor: 'pointer', border: 'none', backgroundColor: 'transparent' };
const activeLightningIconStyles = { color: getTheme().palette.blue, marginRight: '8px' };
const activeAssignmentColmnStyle = { display: 'block' };
const moreIcon: IIconProps = { iconName: 'MoreVertical' };
const moreMenuStyles = {
  root: {
    selectors: {
      '.ms-ContextualMenu-item': {
        margin: '0px !important'
      },
      '.ms-ContextualMenu-divider': {
        margin: '0px !important'
      },
      '.ms-ContextualMenu-link': {
        padding: '0px 6px'
      }
    }
  },
  header: [
    {
      color: getTheme().palette.neutralPrimary
    }
  ]
};

const columnSizes: GridSizes = {
  'vmList/name': {
    [ResponsiveMode.small]: [150, 316],
    [ResponsiveMode.medium]: [150, 236],
    [ResponsiveMode.large]: [111, 178],
    [ResponsiveMode.xLarge]: [178, 237],
    [ResponsiveMode.xxLarge]: [174, 287],
    [ResponsiveMode.xxxLarge]: [287, 396]
  },
  'vmList/resourceType': {
    [ResponsiveMode.small]: [60, 100],
    [ResponsiveMode.medium]: [60, 118],
    [ResponsiveMode.large]: [60, 80],
    [ResponsiveMode.xLarge]: [60, 80],
    [ResponsiveMode.xxLarge]: [38, 62],
    [ResponsiveMode.xxxLarge]: [62, 86]
  },
  'vmList/subscription': {
    [ResponsiveMode.medium]: [150, 236],
    [ResponsiveMode.large]: [235, 313],
    [ResponsiveMode.xLarge]: [148, 197],
    [ResponsiveMode.xxLarge]: [145, 239],
    [ResponsiveMode.xxxLarge]: [239, 330]
  },
  'vmList/resourceGroup': {
    [ResponsiveMode.large]: [78, 124],
    [ResponsiveMode.xLarge]: [124, 166],
    [ResponsiveMode.xxLarge]: [122, 201],
    [ResponsiveMode.xxxLarge]: [201, 278]
  },
  'vmList/environment': {
    [ResponsiveMode.large]: [58, 93],
    [ResponsiveMode.xLarge]: [93, 124],
    [ResponsiveMode.xxLarge]: [91, 150],
    [ResponsiveMode.xxxLarge]: [150, 207]
  },
  'vmList/distributionType': {
    [ResponsiveMode.large]: [74, 118],
    [ResponsiveMode.xLarge]: [118, 158],
    [ResponsiveMode.xxLarge]: [116, 191],
    [ResponsiveMode.xxxLarge]: [191, 264]
  },
  'vmList/assignment': {
    [ResponsiveMode.large]: [64, 103],
    [ResponsiveMode.xLarge]: [103, 137],
    [ResponsiveMode.xxLarge]: [101, 167],
    [ResponsiveMode.xxxLarge]: [167, 230]
  },
  'vmList/action': {
    [ResponsiveMode.xLarge]: [200, 266],
    [ResponsiveMode.xxLarge]: [213, 351],
    [ResponsiveMode.xxxLarge]: [352, 485]
  }
};

export const getVmListColumns = (
  responsiveMode: ResponsiveMode,
  props: IVmListProps,
  onColumnClick: (ev: React.MouseEvent<HTMLElement>, column: IListColumn<IVirtualMachine>) => void,
  setShowQuickActivationDialog: (show: boolean) => void,
  setHideConnectionDialog: (show: boolean) => void,
  setQuickActivationRole: (role: QuickActivationDialogRole) => void,
  setSelectedVm: (vm: IVirtualMachine) => void,
  setRamErrorMessageBar: (message: string) => void,
  reportCustomEventWithMetadata: (eventName: string, payload?: {} | undefined) => void
): Array<IListColumn<IVirtualMachine>> => {
  const { t } = props;

  const resourceNameColumn: IListColumn<IVirtualMachine> = {
    key: 'vmList/name',
    name: t(LocaleKeys.name),
    fieldName: 'name',
    minWidth: 0,
    headerClassName: FontClassNames.smallPlus,
    columnActionsMode: ColumnActionsMode.clickable,
    onColumnClick,
    isSorted: true,
    isSortedDescending: false,
    isResizable: true,
    onRender: (item: IVirtualMachine) => {
      return (
        <>
          {item.assignmentStatus === PimRoleAssignmentStatusEnum.Active ? (
            <Link
              styles={{ root: { fontSize: 14 } }}
              onClick={() => {
                try {
                  setSelectedVm(item);
                  setHideConnectionDialog(false);
                } catch (error) {
                  setRamErrorMessageBar(t(LocaleKeys.ramErrorTryAgain));
                  reportCustomEventWithMetadata('ram/virtualMachine/connectionDetails/error', { error });
                }
              }}
            >
              {item.displayName}
            </Link>
          ) : (
            <ColumnValue
              columnValue={item.displayName}
              isHighlightRequired={true}
              searchTerm={''}
              isSearching={false}
            />
          )}
        </>
      );
    }
  };

  const resourceTypeColumn: IListColumn<IVirtualMachine> = {
    key: 'vmList/resourceType',
    name: t(LocaleKeys.type),
    fieldName: 'resourceType',
    minWidth: 0,
    headerClassName: FontClassNames.smallPlus,
    columnActionsMode: ColumnActionsMode.disabled,
    isSorted: false,
    isSortedDescending: false,
    isResizable: true,
    onRender: () => {
      return (
        <ColumnValue
          columnValue={t(LocaleKeys.virtualMachine)}
          isHighlightRequired={true}
          searchTerm={''}
          isSearching={false}
        />
      );
    }
  };

  const subscriptionColumn: IListColumn<IVirtualMachine> = {
    key: 'vmList/subscription',
    name: t(LocaleKeys.subscription),
    fieldName: 'subscription',
    minWidth: 0,
    headerClassName: FontClassNames.smallPlus,
    columnActionsMode: ColumnActionsMode.clickable,
    onColumnClick,
    isSorted: false,
    isSortedDescending: false,
    isResizable: true,
    onRender: (item: IVirtualMachine) => {
      return (
        <ColumnValue
          columnValue={item.subscriptionName}
          isHighlightRequired={true}
          searchTerm={''}
          isSearching={false}
        />
      );
    }
  };

  const resourceGroupColumn: IListColumn<IVirtualMachine> = {
    key: 'vmList/resourceGroup',
    name: t(LocaleKeys.ramResourceGroup),
    fieldName: 'resourceGroup',
    minWidth: 0,
    headerClassName: FontClassNames.smallPlus,
    columnActionsMode: ColumnActionsMode.clickable,
    onColumnClick,
    isSorted: false,
    isSortedDescending: false,
    isResizable: true,
    onRender: (item: IVirtualMachine) => {
      return (
        <ColumnValue
          columnValue={item.resourceGroupName}
          isHighlightRequired={true}
          searchTerm={''}
          isSearching={false}
        />
      );
    }
  };

  const environmentColumn: IListColumn<IVirtualMachine> = {
    key: 'vmList/environment',
    name: t(LocaleKeys.environment),
    fieldName: 'environment',
    minWidth: 0,
    headerClassName: FontClassNames.smallPlus,
    columnActionsMode: ColumnActionsMode.disabled,
    isSorted: false,
    isSortedDescending: false,
    isResizable: true,
    onRender: (item: IVirtualMachine) => {
      return (
        <ColumnValue columnValue={item.environment} isHighlightRequired={true} searchTerm={''} isSearching={false} />
      );
    }
  };

  const distributionTypeColumn: IListColumn<IVirtualMachine> = {
    key: 'vmList/distributionType',
    name: t(LocaleKeys.distributionType),
    fieldName: 'distributionType',
    minWidth: 10,
    headerClassName: FontClassNames.smallPlus,
    columnActionsMode: ColumnActionsMode.disabled,
    isSorted: false,
    isSortedDescending: false,
    isResizable: true,
    onRender: (item: IVirtualMachine) => {
      return (
        <ColumnValue
          columnValue={item.osType ? convertToTitleCase(item.osType) : t(LocaleKeys.ramDistributionTypeNotAvailable)}
          isHighlightRequired={true}
          searchTerm={''}
          isSearching={false}
        />
      );
    }
  };

  const assignmentColumn: IListColumn<IVirtualMachine> = {
    key: 'vmList/assignment',
    name: t(LocaleKeys.assignment),
    fieldName: 'assignment',
    minWidth: 0,
    headerClassName: FontClassNames.smallPlus,
    columnActionsMode: ColumnActionsMode.disabled,
    isSorted: false,
    isSortedDescending: false,
    isResizable: true,
    onRender: (item: IVirtualMachine) => {
      if (item.assignmentStatus === PimRoleAssignmentStatusEnum.Eligible) {
        return (
          <div>
            <FontIcon aria-hidden iconName="CircleRing" style={eligibleCircleIconStyles} />{' '}
            <Text>{t(LocaleKeys.eligible)}</Text>
          </div>
        );
      } else if (item.assignmentStatus === PimRoleAssignmentStatusEnum.PendingApproval) {
        return (
          <div>
            <FontIcon aria-hidden iconName="CircleRing" style={eligibleCircleIconStyles} />{' '}
            <Text>{t(LocaleKeys.pending, { context: 'capitalize' })}</Text>
          </div>
        );
      } else {
        const activeRoleNameTextList = getRoleNameText(item);
        return (
          <div style={activeAssignmentColmnStyle}>
            <div>
              <FontIcon aria-hidden iconName="CircleFill" style={activeCircleFillIconStyles} />
              <Text style={activeAssignmentTextStyles}>{t(LocaleKeys.active)}</Text>
            </div>
            <div>{activeRoleNameTextList.map((roleNameText) => t(roleNameText)).join(', ')}</div>
          </div>
        );
      }
    }
  };

  const actionColumnOnClick = (item: IVirtualMachine, roleToActivate?: IPimEligibilityScheduleInstance): void => {
    try {
      const quickRoleAssignment = roleToActivate ?? roleToActivateForEligibleStatusVm(item);
      if (!quickRoleAssignment) return;

      props.listRoleManagementPolicy({
        scope: quickRoleAssignment.directoryScopeId,
        roleDefinitionId: quickRoleAssignment.roleDefinitionId
      });

      setSelectedVm(item);
      setShowQuickActivationDialog(true);
      setQuickActivationRole({
        roleName: quickRoleAssignment.roleDefinition.displayName,
        roleStartTimeDisplayText: t(LocaleKeys.ramQuickActivationDialogRoleSectionImmediatelyDisplayText),
        roleDurationInIso8601Format: props.maximumDuration ?? '',
        roleScope: quickRoleAssignment.directoryScopeId,
        roleProperties: {
          principalId: quickRoleAssignment.principalId,
          roleDefinitionId: `${quickRoleAssignment.directoryScopeId}/providers/Microsoft.Authorization/roleDefinitions/${quickRoleAssignment.roleDefinitionId}`,
          requestType: 'SelfActivate',
          justification: '',
          scheduleInfo: {
            startDateTime: '',
            expiration: {
              type: 'AfterDuration',
              endDateTime: '',
              duration: props.maximumDuration ?? ''
            }
          },
          linkedRoleEligibilityScheduleId: quickRoleAssignment.roleEligibilityScheduleId.split('/').pop() as string
        },
        roleActivationScope: isEligibilityScopedAtResource(quickRoleAssignment.directoryScopeId)
          ? quickRoleAssignment.directoryScopeId
          : item.id.replace(/(\/resourcegroups\/[^/]+).*/i, '$1'),
        roleId: quickRoleAssignment.id
      });
    } catch (error) {
      setRamErrorMessageBar(t(LocaleKeys.ramErrorTryAgain));
      reportCustomEventWithMetadata('ram/access/activate/failed/initiate', { error });
    }
  };

  const getMoreMenuActivationItems = (
    vm: IVirtualMachine,
    userLoginRole?: IPimEligibilityScheduleInstance,
    adminLoginRole?: IPimEligibilityScheduleInstance
  ): IContextualMenuItem[] => {
    const userLoginItem: IContextualMenuItem | undefined = userLoginRole
      ? {
          key: 'activateUserLoginRole',
          text: t(LocaleKeys.ramActivationMenuActivateAsUser),
          onClick: () => {
            try {
              reportCustomEventWithMetadata('ram/journey/2/moreMenu/activate');
              actionColumnOnClick(vm, userLoginRole);
            } catch (error) {
              setRamErrorMessageBar(t(LocaleKeys.ramErrorTryAgain));
              reportCustomEventWithMetadata('ram/access/activate/failed/initiate/moreMenu', { error });
            }
          }
        }
      : undefined;
    const adminLoginItem: IContextualMenuItem | undefined = adminLoginRole
      ? {
          key: 'activateAdminLoginRole',
          text: t(LocaleKeys.ramActivationMenuActivateAsAdmin),
          onClick: () => {
            try {
              reportCustomEventWithMetadata('ram/journey/2/moreMenu/activate');
              actionColumnOnClick(vm, adminLoginRole);
            } catch (error) {
              setRamErrorMessageBar(t(LocaleKeys.ramErrorTryAgain));
              reportCustomEventWithMetadata('ram/access/activate/failed/initiate/moreMenu', { error });
            }
          }
        }
      : undefined;
    const menuItems: IContextualMenuItem[] = [];

    if (userLoginItem) menuItems.push(userLoginItem);
    if (adminLoginItem) menuItems.push(adminLoginItem);

    return menuItems;
  };

  const getMoreMenuActivationSection = (
    vm: IVirtualMachine,
    sectionItems: IContextualMenuItem[],
    title: string
  ): IContextualMenuItem[] => {
    const connectionDetails = {
      key: 'connectionDetails',
      text: t(LocaleKeys.ramActivationMenuConnectionDetailsTitle),
      onClick: () => {
        try {
          reportCustomEventWithMetadata('ram/journey/3/moreMenu/connectionDetails');
          setSelectedVm(vm);
          setHideConnectionDialog(false);
        } catch (e) {
          setRamErrorMessageBar(t(LocaleKeys.ramErrorTryAgain));
          reportCustomEventWithMetadata('ram/moreMenu/connectionDetails/error', { error: e });
        }
      }
    };
    const activationSection = {
      key: 'activationSectionMoreMenu',
      itemType: ContextualMenuItemType.Header,
      text: title
    };
    if (vm.assignmentStatus === PimRoleAssignmentStatusEnum.Active) {
      return sectionItems.length > 0
        ? [
            connectionDetails,
            { key: 'divider_1', itemType: ContextualMenuItemType.Divider },
            activationSection,
            ...sectionItems
          ]
        : [connectionDetails];
    } else {
      return [activationSection, ...sectionItems];
    }
  };
  const eligibleStatusMoreMenuItems = (vm: IVirtualMachine): IContextualMenuItem[] => {
    const userLoginRole = roleToActivateForEligibleStatusVm(vm, PimVmLoginRoleNames.VirtualMachineUserLogin);
    const adminLoginRole = roleToActivateForEligibleStatusVm(vm, PimVmLoginRoleNames.VirtualMachineAdministratorLogin);

    const sectionItems = getMoreMenuActivationItems(vm, userLoginRole, adminLoginRole);

    return getMoreMenuActivationSection(vm, sectionItems, t(LocaleKeys.ramActivationMenuRolesAvailableTitle));
  };

  const activeStatusMoreMenuItems = (vm: IVirtualMachine): IContextualMenuItem[] => {
    const userLoginRole: IPimEligibilityScheduleInstance | undefined = roleToActivateForActiveStatusVm(
      vm,
      PimVmLoginRoleNames.VirtualMachineUserLogin
    );
    const adminLoginRole: IPimEligibilityScheduleInstance | undefined = roleToActivateForActiveStatusVm(
      vm,
      PimVmLoginRoleNames.VirtualMachineAdministratorLogin
    );

    const sectionItems = getMoreMenuActivationItems(vm, userLoginRole, adminLoginRole);

    return getMoreMenuActivationSection(vm, sectionItems, t(LocaleKeys.ramActivationMenuSwitchRoleTitle));
  };

  const moreMenu = (vm: IVirtualMachine, label: string): JSX.Element => {
    const items: IContextualMenuItem[] =
      vm.assignmentStatus === PimRoleAssignmentStatusEnum.Eligible
        ? eligibleStatusMoreMenuItems(vm)
        : activeStatusMoreMenuItems(vm);
    return (
      <IconButton
        menuIconProps={moreIcon}
        menuProps={{ shouldFocusOnMount: true, items, styles: moreMenuStyles }}
        ariaLabel={label}
      />
    );
  };

  const actionColumn: IListColumn<IVirtualMachine> = {
    key: 'vmList/action',
    name: '',
    fieldName: 'action',
    minWidth: 0,
    headerClassName: FontClassNames.smallPlus,
    columnActionsMode: ColumnActionsMode.disabled,
    isSorted: false,
    isSortedDescending: false,
    isResizable: true,
    onRender: (item: IVirtualMachine) => {
      return (
        <>
          {item.assignmentStatus === PimRoleAssignmentStatusEnum.Eligible ? (
            <button style={activeActionStyles} onClick={() => actionColumnOnClick(item)}>
              <FontIcon aria-hidden iconName="LightningBolt" style={activeLightningIconStyles} />
              <Text>{t(LocaleKeys.activate)}</Text>
            </button>
          ) : item.assignmentStatus === PimRoleAssignmentStatusEnum.Active ? (
            <>{generateQuickConnectColumnItems(item, reportCustomEventWithMetadata, t)}</>
          ) : null}
          {moreMenu(item, t(LocaleKeys.ramMoreMenuLabel))}
        </>
      );
    }
  };

  switch (responsiveMode) {
    case ResponsiveMode.small:
      return resizeResponsiveGrid([resourceNameColumn, resourceTypeColumn], columnSizes, responsiveMode);
    case ResponsiveMode.medium:
      return resizeResponsiveGrid(
        [resourceNameColumn, resourceTypeColumn, subscriptionColumn],
        columnSizes,
        responsiveMode
      );
    default:
      return resizeResponsiveGrid(
        [
          resourceNameColumn,
          resourceTypeColumn,
          subscriptionColumn,
          resourceGroupColumn,
          environmentColumn,
          distributionTypeColumn,
          assignmentColumn,
          actionColumn
        ],
        columnSizes,
        responsiveMode
      );
  }
};

const roleNameToTextMap: Map<string, string> = new Map([
  ['Virtual Machine Administrator Login', LocaleKeys.ramAdminRoleTextOnLandingPage],
  ['Virtual Machine User Login', LocaleKeys.ramUserRoleTextOnLandingPage]
]);

const getRoleNameText = (vm: IVirtualMachine): string[] => {
  const eligibleAssignments = vm.eligibleRoleAssignmentList;
  const activeAssignments = vm.activeRoleAssignmentList;

  if (activeAssignments.length === 0) {
    return [];
  }

  const roleNameList = activeAssignments.map((activeAssignment) => {
    const eligibleAssignment = eligibleAssignments.find(
      (eligibleAssignment) => eligibleAssignment.id === activeAssignment.linkedRoleEligibilityScheduleInstanceId
    );

    return eligibleAssignment ? eligibleAssignment.roleDefinition.displayName : '';
  });

  return Array.from(new Set(roleNameList)).map((roleName) => roleNameToTextMap.get(roleName) ?? roleName);
};

export const isEligibilityScopedAtResource = (roleDirectoryScope: string): boolean => {
  return roleDirectoryScope.toLowerCase().includes('machine');
};

const roleToActivateForActiveStatusVm = (
  vm: IVirtualMachine,
  roleName: string
): IPimEligibilityScheduleInstance | undefined => {
  let role: IPimEligibilityScheduleInstance | undefined;

  for (const eligibleRoleAssignment of vm.eligibleRoleAssignmentList) {
    if (eligibleRoleAssignment.roleDefinition.displayName !== roleName) continue;

    const isActive = vm.activeRoleAssignmentList.some(
      (activeRoleAssignment) =>
        activeRoleAssignment.linkedRoleEligibilityScheduleInstanceId === eligibleRoleAssignment.id
    );

    if (isActive) {
      role = undefined;
      break;
    }
    if (role && !isEligibilityScopedAtResource(role.directoryScopeId)) continue;
    if (!role || !isEligibilityScopedAtResource(eligibleRoleAssignment.directoryScopeId)) {
      role = eligibleRoleAssignment;
    }
  }
  return role;
};

const roleToActivateForEligibleStatusVm = (
  vm: IVirtualMachine,
  roleName?: string
): IPimEligibilityScheduleInstance | undefined => {
  let role: IPimEligibilityScheduleInstance | undefined;

  for (const eligibleRoleAssignment of vm.eligibleRoleAssignmentList) {
    if (role && !isEligibilityScopedAtResource(role.directoryScopeId)) return role;
    if (roleName && !(eligibleRoleAssignment.roleDefinition.displayName === roleName)) continue;
    if (role && isEligibilityScopedAtResource(eligibleRoleAssignment.directoryScopeId)) continue;
    role = eligibleRoleAssignment;
  }
  return role;
};

export const roleToActivateForVM = (
  vm: IVirtualMachine,
  roleName?: string
): IPimEligibilityScheduleInstance | undefined => {
  return vm.activeRoleAssignmentList.length === 0
    ? roleToActivateForActiveStatusVm(vm, roleName as string)
    : roleToActivateForEligibleStatusVm(vm, roleName);
};
