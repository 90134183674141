
import {
  css,
  FontClassNames,
  Label,
  Link,
  MessageBar,
  MessageBarType,
} from '@fluentui/react';
import * as React from 'react';
import { InjectedTranslateProps } from 'react-i18next';
import { getLocalizedText } from '../../../models/ELM/IEntitlementManagementLocalizedContent';
import { isNullOrUndefined } from 'util';
import { AnswerString } from '../../../models/ELM/AnswerString';
import { IGrantRequest } from '../../../models/ELM/IGrantRequest';
import { IRaeRequest } from '../../../models/RequestApprovals/IRaeRequest';
import * as ApprovalHelper from '../../../shared/approvalPersonaHelper';
import { FormatDateTime } from '../../../shared/FormatDateTime';
import { getGrantRequestFromRaeRequest } from '../../../shared/getGrantRequestFromRaeRequest';
import { getRequestorName, getSubjectEmailOrUpn, getTargetName } from '../../../shared/getUserStrings';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import {
  DetailSection,
  IDetailSectionContentProps,
} from '../DetailSection/DetailSection';
import { PanelOptionDetails } from '../PanelOptionDetails/PanelOptionDetails';
import { IVerifiedCredentialData } from '../../../models/ELM/IVerfiedCredentialData';
import { QuestionAnswer } from '../../../models/ELM/QuestionAnswer';
import { QuestionAnswer as QuestionAnswerControl } from '../../ELM/QuestionAnswer/QuestionAnswer';
import { QuestionType } from '../../../models/ELM/QuestionType';
import { IAnswer } from '../../../models/ELM/IAnswer';
import { isEmptyOrUndefined } from '../../../shared/isEmptyOrUndefined';
import { convertAnswersToQuestionAnswers, convertQuestionAnswersToAnswers } from '../../../shared/questionAnswerHelper';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');

export interface IRequestDetails extends InjectedTranslateProps {
  raeRequest?: IRaeRequest;
  grantRequest?: IGrantRequest;
  onPrevious: (answers: IAnswer[]) => void;
  onDismiss: () => void;
  submitting?: boolean;
  validating?: boolean;
  isAnswerEditable: boolean;
  answers: IAnswer[]|undefined;
}
export interface IRequestDetailsState {
  questionAnswers: QuestionAnswer[];
  isEditingAnswer: boolean;
  currentAnswers: IAnswer[];
}
export class RequestDetails extends React.Component<IRequestDetails, IRequestDetailsState> {
  constructor(nextProps: IRequestDetails) {
    super(nextProps);

    const answers: QuestionAnswer[] = convertAnswersToQuestionAnswers(nextProps.answers);

    this.state = {
      isEditingAnswer: false,
      questionAnswers: answers,
      currentAnswers: nextProps.answers
    };
  }

  public render(): JSX.Element {
    const { t, onPrevious, onDismiss } = this.props;

    return (
      <PanelOptionDetails
        t={t}
        headerText={LocaleKeys.requestDetails}
        previousLinkText={LocaleKeys.reviewRequest}
        onPrevious={this._onPreviousAction}
        onDismiss={onDismiss}
        onRenderBody={this._onRenderBodyRaeRequestDetails}
      />
    );
  }

  private _onPreviousAction = (): void => {
    this.props.onPrevious(this.state.currentAnswers);
  }

  private _onRenderBodyRaeRequestDetails = (): JSX.Element => {
    const { raeRequest, grantRequest, t, submitting, validating, isAnswerEditable} = this.props;

    if (raeRequest) {
      const request: IGrantRequest = getGrantRequestFromRaeRequest(
        raeRequest
      );
      const grant = request!.accessPackageAssignment;
      const entitlement = grant!.accessPackage!;
      const isEscalation: boolean = ApprovalHelper.isEscalationApproval(raeRequest);
      const escalatedDate = ApprovalHelper.getEscalatedDate(raeRequest);

      const dueDate =
        isEscalation && ApprovalHelper.isOriginalApprover(raeRequest)
          ? escalatedDate
          : raeRequest.endDateTime;

      const detailSectionContentProps: IDetailSectionContentProps[] = [];
      const vcDetailSectionContent: IDetailSectionContentProps[] = [];

      // This is a simple version to render vc details for approval
      if (request!.verifiedCredentialsData && request!.verifiedCredentialsData.length > 0) {
        request!.verifiedCredentialsData.forEach((vcData: IVerifiedCredentialData) => {
          for (const [key, value] of Object.entries(vcData.claims!)) {
            vcDetailSectionContent.push({
              primaryContent: key!.toString(),
              secondaryContent: value.toString(),
              secondaryCSS: css(myAccessStyles.semiBoldText)
            });
          }
        });
      }

      if (request!.answers && request!.answers.length > 0) {
        request!.answers.forEach((answer: AnswerString) => {
          let value = answer.displayValue ? answer.displayValue : answer.value ? answer.value : '';

          detailSectionContentProps.push({
            primaryContent: getLocalizedText(answer.answeredQuestion.text),
            secondaryContent: value,
            secondaryCSS: css(myAccessStyles.semiBoldText),
          });
        });
      }

      return (
        <div>
          <DetailSection
            t={t}
            heading={LocaleKeys.requestedBy}
            detailSectionContentProps={[
              {
                primaryContent: getRequestorName(request.requestor, request!.requestType, t),
                secondaryContent: getSubjectEmailOrUpn(request.requestor),
                verifiedCredential: request.verifiedCredentialsData && request.verifiedCredentialsData.length > 0
                  ? t(LocaleKeys.verified)
                  : undefined
              },
            ]}
          />

          <DetailSection
            t={t}
            heading={LocaleKeys.accessTo}
            detailSectionContentProps={[
              {
                primaryContent: entitlement.displayName || '',
                // secondaryContent:
                //   'Description of package here when new api is complete',
                // secondaryCSS: css(
                //   myAccessStyles.marginTopXSmall,
                //   FontClassNames.smallPlus
                // )
              },
            ]}
          />

          <DetailSection
            t={t}
            heading={LocaleKeys.requestSubmittedOn}
            detailSectionContentProps={[
              {
                primaryContent: FormatDateTime(request.createdDateTime),
              },
            ]}
          />

          {dueDate ? (
            <DetailSection
              t={t}
              heading={LocaleKeys.requestDueBy}
              detailSectionContentProps={[
                {
                  primaryContent: FormatDateTime(dueDate),
                },
              ]}
            />
          ) : null}

          {raeRequest.description ? (
            <DetailSection
              t={t}
              heading={LocaleKeys.businessJustification}
              detailSectionContentProps={[
                {
                  primaryContent: raeRequest.description,
                },
              ]}
            />
          ) : null}

          {vcDetailSectionContent.length > 0 ? (
            <DetailSection
              t={this.props.t}
              heading={LocaleKeys.verifiedCredentialsProvidedByUser}
              detailSectionContentProps={vcDetailSectionContent}
            />
          ) : null}

          {detailSectionContentProps.length > 0 ? (
            <DetailSection
              t={this.props.t}
              heading={LocaleKeys.informationProvidedByUser}
              detailSectionContentProps={detailSectionContentProps}
            />
          ) : null}

          {isEscalation &&
            ApprovalHelper.isOriginalApprover(raeRequest) &&
            ApprovalHelper.pastDue(escalatedDate) ? (
            <MessageBar
              className={css(myAccessStyles.marginTopSmall)}
              messageBarType={MessageBarType.warning}
            >
              {this.props.t(LocaleKeys.escalationMessageAlternateAdded, {
                dueDate: FormatDateTime(escalatedDate),
              })}
            </MessageBar>
          ) : null}

          {isEscalation && ApprovalHelper.isAlternateApprover(raeRequest) ? (
            <MessageBar
              className={css(myAccessStyles.marginTopSmall)}
              messageBarType={MessageBarType.warning}
            >
              {this.props.t(LocaleKeys.escalationMessageForwarded)}
            </MessageBar>
          ) : null}
        </div>
      );
    }
    if (grantRequest) {
      const grant = grantRequest!.accessPackageAssignment;
      const entitlement = grant!.accessPackage!;
      const isGrantRequestEscalated: boolean = ApprovalHelper.isGrantRequestEscalated(grantRequest);

      const dueDate = ApprovalHelper.getDueDate(grantRequest);
      const escalatedDate = ApprovalHelper.getGrantRequestEscalatedDate(grantRequest);
      const detailSectionContentProps: IDetailSectionContentProps[] = [];
      const vcDetailSectionContent: IDetailSectionContentProps[] = [];

      // This is a simple version to render vc details for approval
      if (grantRequest!.verifiedCredentialsData && grantRequest!.verifiedCredentialsData.length > 0) {
        grantRequest!.verifiedCredentialsData.forEach((vcData: IVerifiedCredentialData) => {
          for (const [key, value] of Object.entries(vcData.claims!)) {
            vcDetailSectionContent.push({
              primaryContent: key!.toString(),
              secondaryContent: value.toString(),
              secondaryCSS: css(myAccessStyles.semiBoldText)
            });
          }
        });
      }

      if (this.state.currentAnswers && this.state.currentAnswers.length > 0) {
        this.state.currentAnswers.forEach((answer: AnswerString) => {
          let value = answer.displayValue ? answer.displayValue : answer.value ? answer.value : '';

          detailSectionContentProps.push({
            primaryContent: getLocalizedText(answer.answeredQuestion.text),
            secondaryContent: value,
            secondaryCSS: css(myAccessStyles.semiBoldText),
          });
        });
      }

      const questionAnswersView: JSX.Element[] = [];
      if (this.state.questionAnswers && this.state.questionAnswers.length > 0) {
        this.state.questionAnswers.forEach((questionAnswer: QuestionAnswer) => {
          questionAnswersView.push(
            <QuestionAnswerControl
              key={questionAnswer.id}
              t={t}
              submitting={submitting ? submitting : false}
              validating={validating ? validating : false}
              questionAnswer={questionAnswer}
              onChange={this.onAnswerChanged}
            />
          );
        });
      }

      return (
        <div>
          <DetailSection
            t={t}
            heading={LocaleKeys.requestedBy}
            detailSectionContentProps={[
              {
                primaryContent: getRequestorName(grantRequest.requestor, grantRequest.requestType, t),
                secondaryContent: getSubjectEmailOrUpn(grantRequest.requestor),
                verifiedCredential: grantRequest!.verifiedCredentialsData && grantRequest!.verifiedCredentialsData.length > 0
                  ? t(LocaleKeys.verified)
                  : ''
              },
            ]}
          />

          <DetailSection
            t={t}
            heading={LocaleKeys.requestedFor}
            detailSectionContentProps={[
              {
                primaryContent: getTargetName(t, grantRequest.accessPackageAssignment.target),
                secondaryContent: getSubjectEmailOrUpn(grantRequest.accessPackageAssignment.target)
              },
            ]}
          />

          <DetailSection
            t={t}
            heading={LocaleKeys.accessTo}
            detailSectionContentProps={[
              {
                primaryContent: entitlement.displayName || '',
                secondaryContent:
                  entitlement.description || '',
                secondaryCSS: css(
                  myAccessStyles.marginTopXSmall,
                  FontClassNames.smallPlus
                )
              },
            ]}
          />

          <DetailSection
            t={t}
            heading={LocaleKeys.requestSubmittedOn}
            detailSectionContentProps={[
              {
                primaryContent: FormatDateTime(ApprovalHelper.getStageCreationDate(grantRequest)),
              },
            ]}
          />

          {dueDate ? (
            <DetailSection
              t={t}
              heading={LocaleKeys.requestDueBy}
              detailSectionContentProps={[
                {
                  primaryContent: FormatDateTime(dueDate),
                },
              ]}
            />
          ) : null}

          {
            <DetailSection
              t={t}
              heading={LocaleKeys.businessJustification}
              detailSectionContentProps={[
                {
                  primaryContent: !isNullOrUndefined(grantRequest.justification) ? grantRequest.justification : LocaleKeys.notSpecified,
                },
              ]}
            />
          }

          {vcDetailSectionContent.length > 0 ? (
            <DetailSection
              t={this.props.t}
              heading={LocaleKeys.verifiedCredentialsProvidedByUser}
              detailSectionContentProps={vcDetailSectionContent}
            />
          ) : null}

          {detailSectionContentProps.length > 0 ? (
            <div className={css(myAccessStyles.marginTopMedium)}>
              <Label required={false}>
                {t(LocaleKeys.informationProvidedByUser)}{' '}
              </Label>
            </div>
          ) : null}

          {isAnswerEditable && this.state.isEditingAnswer && detailSectionContentProps.length > 0 ? (
            questionAnswersView
          ) : (
          <DetailSection
            t={this.props.t}
            heading={LocaleKeys.informationProvidedByUser}
            detailSectionContentProps={detailSectionContentProps}
          />
          )}

          {grantRequest.customExtensionCalloutInstances.length > 0 ? (
            <DetailSection
              t={t}
              heading={LocaleKeys.additionalInformation}
              detailSectionContentProps={[
                {
                  primaryContent: !isNullOrUndefined(grantRequest.customExtensionCalloutInstances[0].detail)
                  ? grantRequest.customExtensionCalloutInstances[0].detail
                  : LocaleKeys.notSpecified,
                },
              ]}
            />
          ) : null}

          {isGrantRequestEscalated &&
            ApprovalHelper.isOriginalGrantRequestApprover(grantRequest) &&
            ApprovalHelper.pastDue(escalatedDate) ? (
            <MessageBar
              className={css(myAccessStyles.marginTopSmall)}
              messageBarType={MessageBarType.warning}
            >
              {this.props.t(LocaleKeys.escalationMessageAlternateAdded, {
                dueDate: FormatDateTime(escalatedDate),
              })}
            </MessageBar>
          ) : null}

          {isGrantRequestEscalated &&
            ApprovalHelper.isAlternateGrantRequestApprover(grantRequest) ? (
            <MessageBar
              className={css(myAccessStyles.marginTopSmall)}
              messageBarType={MessageBarType.warning}
            >
              {this.props.t(LocaleKeys.escalationMessageForwarded)}
            </MessageBar>
          ) : null}

          {isAnswerEditable && grant.schedule?.startDateTime != null ? (
            <DetailSection
              t={this.props.t}
              heading={LocaleKeys.requestStartDate}
              detailSectionContentProps={[
                {
                  primaryContent: FormatDateTime(grant.schedule?.startDateTime),
                },
              ]}
            />
          ) : null}

          {grant.schedule?.startDateTime != null ? (
            <DetailSection
              t={this.props.t}
              heading={LocaleKeys.requestEndDate}
              detailSectionContentProps={[
                {
                  primaryContent: FormatDateTime(grant.schedule?.stopDateTime),
                },
              ]}
            />
          ) : null}

          {isAnswerEditable? (
            this.state.isEditingAnswer ?(
            <div>
              <Link className={css(myAccessStyles.marginTopSmall, myAccessStyles.marginRightSmall)} onClick={this._onFinishEditingAnswers}>
                {t(LocaleKeys.finishEditing)}
              </Link>
              <Link className={css(myAccessStyles.marginTopSmall, myAccessStyles.marginRightSmall)} onClick={this._onCancelEditingAnswers}>
                  {t(LocaleKeys.discardChanges)}
              </Link>
            </div>)
            :(
            <Link className={css(myAccessStyles.marginTopSmall, myAccessStyles.marginRightSmall)} onClick={this._onEditAnswers}>
              <span>
                {t(LocaleKeys.editAnswers)}
              </span>
            </Link>))
            : null
          }
        </div>
      );
    }
    return (
      <div
        className={css(FontClassNames.smallPlus, myAccessStyles.marginTopSmall)}
      >
        {this.props.t(LocaleKeys.noRequestSelected)}
      </div>
    );
  }

  private onAnswerChanged = (
    questionId: string,
    answerValue: string,
    answerDisplayValue: string,
    isValid: boolean = true
  ): void => {
    let updatedQuestionAnswers = this.state.questionAnswers.map(
      (qa: QuestionAnswer) => {
        if (qa.id === questionId) {
          qa.answerValue = answerValue;
          qa.answerDisplayValue = answerDisplayValue;
          if (qa.$type === QuestionType.TextInputQuestion &&
            !isEmptyOrUndefined(qa.regexPattern) &&
            !isEmptyOrUndefined(qa.answerValue)) {
            const regex = new RegExp(qa.regexPattern);
            isValid = regex.test(qa.answerValue);
          }
          qa.isValid = isValid;
          return qa;
        }
        return qa;
      }
    );

    this.setState({
      ...this.state,
      questionAnswers: updatedQuestionAnswers,
    });
  };

  private _onEditAnswers = (): void => {
    this.setState({
      ...this.state,
      isEditingAnswer: true
    });
  }

  private _onFinishEditingAnswers = (): void => {
    let answers: IAnswer[] = convertQuestionAnswersToAnswers(this.state.questionAnswers);

    this.setState({
      ...this.state,
      isEditingAnswer: false,
      currentAnswers: answers
    });
  }

  private _onCancelEditingAnswers = (): void => {
    this.setState({
      ...this.state,
      isEditingAnswer: false
    });
  }
}
