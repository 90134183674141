// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../dev/node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../dev/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2i1pI_eA5IYdUUeT_v6C-6{width:100%;max-height:100%}._2Stvpy34zUSfY7dXuikxIi{border-radius:8px;width:480px;max-height:85vh}@media (min-width: 1366px){._2Stvpy34zUSfY7dXuikxIi{width:560px}}._3ueSCaoyAjDD5CaKy4a44A{z-index:9998}\n", "",{"version":3,"sources":["webpack://./src/components/Shared/StyledModal/StyledModal.scoped.scss"],"names":[],"mappings":"AAEA,yBACE,UAAW,CACX,eAAgB,CACjB,yBAGC,iBAAkB,CAElB,WAAY,CACZ,eAAgB,CAEhB,2BANF,yBAOI,WAAY,CAEf,CAED,yBAEE,YAAa","sourcesContent":["@import '~@microsoft/portal-app/lib/styling/mixins.scss';\n\n.modalContent {\n  width: 100%;\n  max-height: 100%; // prevent double scrollbars\n}\n\n.modalMain {\n  border-radius: 8px;\n  // Minimum dimensions for mobile\n  width: 480px;\n  max-height: 85vh;\n  // Minimum dimensions for desktop\n  @media (min-width: $ms-screen-min-xxl) {\n    width: 560px;\n  }\n}\n\n.modalLayer {\n  // Needed so notifications are visible in the modal overlay\n  z-index: 9998;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContent": "_2i1pI_eA5IYdUUeT_v6C-6",
	"modalMain": "_2Stvpy34zUSfY7dXuikxIi",
	"modalLayer": "_3ueSCaoyAjDD5CaKy4a44A"
};
module.exports = ___CSS_LOADER_EXPORT___;
