/* eslint-disable @typescript-eslint/no-var-requires */
import { CommandBar, css, SelectionMode } from '@fluentui/react';
import { ErrorBanner } from '@microsoft/portal-app/lib/Banners/ErrorBanner';
import { useResponsiveMode } from '@uifabric/portal-ux/lib/styling/useResponsiveMode';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useTelemetryWithMetadata, useTranslation } from '../../../hooks';
import { EntityType } from '../../../models/EntityType';
import { IListColumn } from '../../../models/IListColumn';
import { CycleActivationStatus } from '../../../models/RAM/IActivationStatusPayload';
import { IPimEligibilityScheduleInstance } from '../../../models/RAM/IPimActivationRequestParameters';
import { IVirtualMachine } from '../../../models/RAM/IVirtualMachine';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { getNewColumnsOnColumnClicked } from '../../../shared/sortingHelper';
import { InfinityList } from '../../Shared/InfinityList/InfinityList';
import { QuickActivationDialogRole } from '../QuickActivation/QuickActivationDialog';
import { RamDialogs } from '../RamDialogs/RamDialogs';
import { getVmListColumns, isEligibilityScopedAtResource } from './VmList.columns';
import { getVmListCommands } from './VmList.commands';
import { IVmListProps, VmListRouteParams } from './VmList.types';
import { replaceBackslashes } from './VmList.utils';

// TODO: Augusto is working on a new way to import scss files, ignore for now. Will remove diaable rule on top when done
const myAccessListStyles = require('../../../css/myAccessList.scoped.scss');
const commandBarStyles = {
  root: {
    paddingLeft: 0
  }
};

const emptyRoleInfo: QuickActivationDialogRole = {
  roleName: '',
  roleStartTimeDisplayText: '',
  roleDurationInIso8601Format: '',
  roleScope: '',
  roleProperties: {
    principalId: '',
    roleDefinitionId: '',
    requestType: '',
    justification: '',
    scheduleInfo: {
      startDateTime: '',
      expiration: {
        type: '',
        endDateTime: '',
        duration: ''
      }
    },
    linkedRoleEligibilityScheduleId: ''
  },
  roleId: '',
  roleActivationScope: ''
};

export const VmList: React.FunctionComponent<IVmListProps> = (props: IVmListProps) => {
  const { selectedVmId, selectedRoleId } = useParams<VmListRouteParams>();
  const t = useTranslation();
  const { reportCustomEventWithMetadata } = useTelemetryWithMetadata();
  const responsiveMode = useResponsiveMode();
  const [columns, setColumns] = useState<Array<IListColumn<IVirtualMachine>>>([]);
  const [virtualMachines, setVirtualMachines] = useState<IVirtualMachine[]>(props.vmList as IVirtualMachine[]);
  const [showQuickActivationDialog, setShowQuickActivationDialog] = useState<boolean>(false);
  const [quickActivationRole, setQuickActivationRole] = useState<QuickActivationDialogRole>(emptyRoleInfo);
  const [selectedVm, setSelectedVm] = useState<IVirtualMachine>(null!);
  const [hideConnectionDialog, setHideConnectionDialog] = useState<boolean>(true);
  const commands = useMemo(() => {
    return getVmListCommands(props.t, props);
  }, [props]);

  useEffect(() => {
    // Load the VMs on first render
    props.refreshEntities();

    props.getPimPendingApprovalRequestEpic();

    reportCustomEventWithMetadata('ram/journey/1/landingpage');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setVirtualMachines(props.vmList as IVirtualMachine[]);

    // extract the selected VM and role from the URL
    if (!selectedVm && props.vmList.length > 0 && selectedVmId && selectedRoleId) {
      // TODO: once the API is ready, it should be used to get the VM as it may not
      // be present in the initial VM list
      const vmId = replaceBackslashes(selectedVmId);
      const vm = props.vmList.find((vm) => vm.id.includes(vmId));
      if (vm) setSelectedVm(vm);

      const roleId = replaceBackslashes(selectedRoleId);
      const quickRoleAssignment = vm?.eligibleRoleAssignmentList.find((role) =>
        role.id.includes(roleId)
      ) as IPimEligibilityScheduleInstance;

      if (vm && quickRoleAssignment) {
        setQuickActivationRole({
          ...quickActivationRole,
          roleName: quickRoleAssignment.roleDefinition.displayName,
          roleStartTimeDisplayText: t(LocaleKeys.ramQuickActivationDialogRoleSectionImmediatelyDisplayText),
          roleScope: quickRoleAssignment.directoryScopeId,
          roleProperties: {
            ...quickActivationRole.roleProperties,
            principalId: quickRoleAssignment.principalId,
            roleDefinitionId: `${quickRoleAssignment.directoryScopeId}/providers/Microsoft.Authorization/roleDefinitions/${quickRoleAssignment.roleDefinitionId}`,
            requestType: 'SelfActivate',
            scheduleInfo: {
              ...quickActivationRole.roleProperties.scheduleInfo,
              expiration: {
                ...quickActivationRole.roleProperties.scheduleInfo.expiration,
                type: 'AfterDuration'
              }
            },
            linkedRoleEligibilityScheduleId: quickRoleAssignment.roleEligibilityScheduleId.split('/').pop() as string
          },
          roleActivationScope: isEligibilityScopedAtResource(quickRoleAssignment.directoryScopeId)
            ? quickRoleAssignment.directoryScopeId
            : vm.id.replace(/(\/resourcegroups\/[^/]+).*/i, '$1'),
          roleId: selectedRoleId
        });

        if (vm && !props.maximumDuration) {
          props.listRoleManagementPolicy({
            scope: vm.id,
            roleDefinitionId: quickRoleAssignment.roleDefinitionId
          });
        }

        setShowQuickActivationDialog(true);
      }
    }

    // only want this to run when url params change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoleId, selectedVmId, props.vmList]);

  useEffect(() => {
    if (props.maximumDuration) {
      setQuickActivationRole({
        ...quickActivationRole,
        roleDurationInIso8601Format: props.maximumDuration,
        roleProperties: {
          ...quickActivationRole.roleProperties,
          scheduleInfo: {
            ...quickActivationRole.roleProperties.scheduleInfo,
            expiration: {
              ...quickActivationRole.roleProperties.scheduleInfo.expiration,
              duration: props.maximumDuration
            }
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.maximumDuration]);

  useEffect(() => {
    setColumns(
      getVmListColumns(
        responsiveMode,
        props,
        _onColumnClick,
        setShowQuickActivationDialog,
        setHideConnectionDialog,
        setQuickActivationRole,
        setSelectedVm,
        props.setRamErrorMessageBar,
        reportCustomEventWithMetadata
      )
    );
    // TODO: Logic change is needed here, will remove the disable rule on top once implemented
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setColumns, responsiveMode]);

  const _onColumnClick = useCallback(
    (ev: React.MouseEvent<HTMLElement>, column: IListColumn<IVirtualMachine>): void => {
      ev.preventDefault();
      column.isSorted = true;
      column.isSortedDescending = !column.isSortedDescending;

      setColumns((prevColumns) => getNewColumnsOnColumnClicked(prevColumns, column));

      props.setSortedByColumn(column);
      props.sortEntities(column.fieldName, !column.isSortedDescending);
    },
    // TODO: Logic change is needed here, will remove the disable rule on top once implemented
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props]
  );

  const filteredCount = useMemo(
    () => (props.pageMetaData.filteredEntityCount ? props.pageMetaData.filteredEntityCount : 0),
    [props]
  );
  const showLoadMore = useMemo(
    () =>
      !props.pageMetaData.isAllEntitiesFullyCached &&
      !props.isLoading &&
      !props.isLoadingMore &&
      !props.pageMetaData.isFilteredEntitiesFullyCached,
    [props]
  );

  const searchVirtualMachines = useCallback(() => {
    if (props.pageMetaData.isAllEntitiesFullyCached || props.pageMetaData.isFilteredEntitiesFullyCached) {
      return;
    }
    props.searchVirtualMachines();
  }, [props]);

  const getVirtualMachines = useCallback(() => {
    if (props.pageMetaData.isAllEntitiesFullyCached || props.isLoading) {
      return;
    }
    props.getEntities();
  }, [props]);

  const loadMore = useCallback(() => {
    if (!props.isLoading) {
      if (props.isSearching || props.isFiltering) {
        searchVirtualMachines();
      } else {
        getVirtualMachines();
      }
    }
  }, [props]);

  const mainContent = (): JSX.Element => {
    if (props.errorHasOccurred && props.errorCode !== 0) {
      return (
        <ErrorBanner
          text={t(LocaleKeys.ramVmListError)}
          onAction={props.getEntities}
          actionText={t(LocaleKeys.retry)}
        />
      );
    } else {
      return (
        <>
          <div>
            <CommandBar className={css(myAccessListStyles.commandBar)} items={commands} styles={commandBarStyles} />
          </div>
          <InfinityList
            t={props.t}
            entityType={EntityType.virtualMachines}
            entityList={virtualMachines}
            columns={columns}
            onLoadMore={loadMore}
            showLoadMore={showLoadMore}
            selectionMode={SelectionMode.none}
            ariaLabel={t(LocaleKeys.ramVirtualMachinesListAriaLabel)}
            showSpinner={props.isLoadingMore || props.isRefreshing}
            spinnerLabel={t(LocaleKeys.loading)}
            showNoEntities={props.pageMetaData.allEntityCount === 0 && !props.isSearching && !props.isLoading}
            noEntitiesProps={{
              iconName: 'Unlock',
              noRowMessage: t(LocaleKeys.ramVmListNoItems),
              showButton: false
            }}
            showNoFilteredResults={!props.isLoading && (props.isSearching || props.isFiltering) && filteredCount === 0}
            isExpanded={false}
          />
        </>
      );
    }
  };
  // TODO: Pass correct roleInfo when backend API is ready so we can get those data
  return (
    <div className={css(myAccessListStyles.listPage, myAccessListStyles.paddingSmallTop)}>
      {mainContent()}
      <RamDialogs
        onDismissActivationStatusDialog={() =>
          props.setActivationStatus({ ...props.activationStatus, status: CycleActivationStatus.None })
        }
        showQuickActivationDialog={showQuickActivationDialog}
        activateRamAccess={props.activateRamAccess}
        onDismissQuckActivationDialog={() => {
          setShowQuickActivationDialog(false);
          props.clearQuickActivationDialogValue();
          setQuickActivationRole(emptyRoleInfo);
          props.getPimPendingApprovalRequestEpic();
        }}
        setQuickActivationRole={(role: QuickActivationDialogRole) => setQuickActivationRole(role)}
        quickActivationRole={quickActivationRole}
        activationStatus={props.activationStatus}
        setActivationStatus={props.setActivationStatus}
        refreshEntities={() => {
          props.refreshEntities();
          props.getPimPendingApprovalRequestEpic();
        }}
        selectedVm={selectedVm}
        maximumDuration={props.maximumDuration}
        isJustificationRequired={props.isJustificationRequired}
        isTicketingRequired={props.isTicketingRequired}
        validateRoleActivation={props.validateRoleActivation}
        isMfaRequirementMet={props.isMfaRequirementMet}
        hideConnectionDialog={hideConnectionDialog}
        setHideConnectionDialog={(hide: boolean) => {
          setHideConnectionDialog(hide);
          if (hide) props.setSelectedVirtualMachineDetails(undefined);
        }}
        ramErrorDialogContent={props.ramErrorDialogContent}
        setRamErrorDialog={props.setRamErrorDialog}
        getVirtualMachineDetails={props.getVirtualMachineDetails}
        selectedVirtualMachineDetails={props.selectedVirtualMachineDetails}
        listRoleManagementPolicy={props.listRoleManagementPolicy}
        clearQuickActivationDialogValue={props.clearQuickActivationDialogValue}
      />
    </div>
  );
};
