import { notificationsMerge } from '@microsoft/portal-app/lib/Notifications/helpers/notificationsMerge';
import {
  INotification,
  NotificationSeverity,
  NotificationType
} from '@microsoft/portal-app/lib/Notifications/models/INotification';
import { IODataValueResponse } from '@microsoft/portal-app/lib/odata-utils';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { TranslationOptions } from 'i18next';
import * as moment from 'moment';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import { Observable } from 'rxjs/Observable';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';
import { v4 } from 'uuid';
import { IAccessReviewDecision } from '../../../models/AccessReviews/IAccessReviewDecision';
import { IEntity } from '../../../models/ELM/IEntity';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { EntityType } from '../../../models/EntityType';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import {
  IEntitlementState,
  IRootEntitlementsState
} from '../../../models/IEntitlementState';
import { IEntityFilterable } from '../../../models/IEntityFilterable';
import { getRequestWithAudience } from '../../../shared/AttachAudience';
import { getEntityApiUrl } from '../../../shared/getApiUrl';
import { getResponseValue } from '../../../shared/getResponseValue';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { registry } from '../myAccessRegistry';

export const getDecisionHistroyEpic: Epic<
  IEntitlementAction<AnyPayload>,
  IRootEntitlementsState
> = (
  action$: ActionsObservable<
    IEntitlementAction<{
      entityId: string;
      secondaryId: string;
      entityType: EntityType;
    }>
  >,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
    return action$
      .ofType(EntitlementActions.getDecisionHistory)
      .mergeMap((action: IEntitlementAction<IEntityFilterable>) => {
        const { entityId, secondaryId } = action.payload!;
        const ajaxRequest = getRequestWithAudience(
          getEntityApiUrl(EntityType.decisionHistory, entityId!, secondaryId!),
          EntityType.decisionHistory as EntityType
        );
        return (
          ajax(ajaxRequest)
            .map((payload: IODataValueResponse<ReadonlyArray<IEntity>>) => {
              return {
                type: EntitlementActions.getDecisionHistorySucceeded,
                payload: {
                  entities: getResponseValue(payload),
                  reviewId: entityId!,
                  decisionId: secondaryId!,
                  payload: payload
                }
              };
            })
            // tslint:disable-next-line:no-any
            .catch((error: any) =>
              Observable.of({
                type: EntitlementActions.getDecisionHistoryFailed,
                payload: {
                  entityType: action.payload!.entityType,
                  errorCode: error && error.status
                }
              })
            )
        );
      });
  };
registry.addEpic('getDecisionHistroyEpic', getDecisionHistroyEpic);

export const getDecisionHistorySucceeded = (
  state: IEntitlementState,
  action: IEntitlementAction<{
    entities: ReadonlyArray<IAccessReviewDecision>;
    decisionId: string;
  }>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  const { entities, decisionId } = action.payload!;

  if (!entities.length) {
    return {
      ...state,
      isEntityDetailLoading: false
    };
  }

  const decisionWithHistory = entities[0];
  if (!decisionWithHistory) {
    return state;
  }

  let currentDecision = state.accessReviewDecisions.entitiesById.get(decisionId);

  // If the decision was not found, return
  if (!currentDecision) {
    return {
      ...state
    };
  }

  currentDecision!.histories = decisionWithHistory.histories;

  let allDecisions = state.accessReviewDecisions.entitiesById as Map<
    string,
    IAccessReviewDecision
  >;

  allDecisions.set(decisionId, currentDecision!);

  return {
    ...state,
    accessReviewDecisions: {
      ...state.accessReviewDecisions,
      entitiesById: allDecisions as ReadonlyMap<string, IAccessReviewDecision>
    },
  };
};
registry.add(EntitlementActions.getDecisionHistorySucceeded, getDecisionHistorySucceeded);

export const getDecisionHistoryFailed = (
  state: IEntitlementState,
  // tslint:disable-next-line:no-any
  action: IEntitlementAction<Readonly<any>>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  const errorCode = action.payload.errorCode;

  let toastKey = LocaleKeys.generalErrorMessage;
  let toastOptions: TranslationOptions = {};

  let notifications: INotification[] = [
    {
      id: v4(),
      localizableMessage: {
        key: toastKey,
        options: toastOptions
      },
      createdDateTime: moment(),
      severity: NotificationSeverity.error,
      type: NotificationType.card
    }
  ];

  return {
    ...state,
    notifications: notificationsMerge(
      notifications,
      state.notifications,
      state.notificationsLimit
    ),
    isEntityDetailLoading: false,
    errorHasOccurred: true,
    isTenantWhitelisted: errorCode !== 403
  };
};
registry.add(EntitlementActions.getDecisionHistoryFailed, getDecisionHistoryFailed);
