export interface IValidationError {
  readonly Code: string;
  readonly Detail: string;
}

export enum ValidationErrorCode {
  InvalidSchedule,
  MultiplePolicyFound,
  ValidationOnly,
  MissingRequiredRequestorJustification,
  // set max stop date
  InvalidExpiredDateTime,
  // existing grant or request
  InvalidRequestExistingGrant,
  ExistingOpenRequest,
  // top level errors
  ValidPolicyNotFound,
  InvalidTargetPartner,
  PolicyRequirementNotMet,
  PolicyExpired,
  EntitlementNotFound,
  InvalidExtendRequestPolicyDisabled,
  InvalidExtendRequestMoreThanCanExtendBeforeInDays,
  PartnerNotFound,
  MissingQuestionAnswers,
  InvalidRequestSODCheck,
  ExistingAttributesQuestionAnswers,
  AccessDurationPeriodOutsidePolicyRange,
  NoLicense,
  NoGovernanceSku
}
