import * as React from 'react';
import {Image, ImageFit, Link, css } from '@fluentui/react';
import { useTelemetryWithMetadata, useTranslation } from '../../../hooks';
import { TelemetryEvent } from '../../../models';
import { history } from '../../../redux/configureStore';
import { NAVIGATION_LINKS } from '../../../shared/constants';
import { LocaleKeys } from '../../../shared';

const recommendationStyles = require('./InfinityList.scoped.scss');

export function EntitlementRecommendationListFooter(): JSX.Element{
  const telemetry = useTelemetryWithMetadata();
  const t = useTranslation();
  return (
    <div className={css(recommendationStyles.container)}>
      <Image className={css(recommendationStyles.image)} imageFit={ImageFit.centerContain} src='/imgs/Illustration.svg' width={92} height={92} alt='Search for more image'/>
      <div className={css(recommendationStyles.bottomText)}>
        <span>
          {t(LocaleKeys.lookForMore)}
        </span>
      </div>
      <Link onClick={() => {
          telemetry.reportCustomEventWithMetadata(TelemetryEvent.Recommendation_View_All_Available_Bottom_Link);
          history.push(NAVIGATION_LINKS.accessPackages.subRoutePaths!.available.link);
      }}>
        {t(LocaleKeys.viewAndSearchAll)}
      </Link>
    </div>
  );
}