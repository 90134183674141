// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../dev/node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../dev/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OtFcDAoScTnwQt9IZKfW9{-webkit-box-orient:vertical;display:-webkit-box;-webkit-line-clamp:2;overflow:hidden;text-overflow:ellipsis;white-space:normal;word-break:break-word}._2QHniqy6Wq7sGJ1KsuRBE8{-webkit-line-clamp:unset}\n", "",{"version":3,"sources":["webpack://./src/components/Shared/TruncatedText/TruncatedText.scoped.scss"],"names":[],"mappings":"AAAA,uBACE,2BAA4B,CAC5B,mBAAoB,CACpB,oBAAqB,CACrB,eAAgB,CAChB,sBAAuB,CACvB,kBAAmB,CACnB,qBAAsB,CACvB,yBAGC,wBAAyB","sourcesContent":[".truncated {\n  -webkit-box-orient: vertical;\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: normal;\n  word-break: break-word;\n}\n\n.untruncated {\n  -webkit-line-clamp: unset;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"truncated": "OtFcDAoScTnwQt9IZKfW9",
	"untruncated": "_2QHniqy6Wq7sGJ1KsuRBE8"
};
module.exports = ___CSS_LOADER_EXPORT___;
