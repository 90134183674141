
import * as React from 'react';
import {
  IGrantRequestQuestionsProps,
  IGrantRequestQuestionsState
} from './GrantRequestQuestions.types';
import { QuestionAnswer } from '../../../../models/ELM/QuestionAnswer';
import { QuestionAnswer as QuestionAnswerControl } from '../../../ELM/QuestionAnswer/QuestionAnswer';
import { IAnswer } from '../../../../models/ELM/IAnswer';
import { AnswerString } from '../../../../models/ELM/AnswerString';
import { getQuestionObject } from '../../AddGrantRequest/GrantRequestShared';
import { getQuestionAnswers } from '../../../../shared/validationErrorHelper';
import { IGrantPolicy } from '../../../../models/ELM/IGrantPolicy';
import { getDatePickerStrings } from '../../../../shared/getDatePickerStrings';
import { FormatDate } from '../../../../shared/FormatDateTime';
import { MAX_DATE } from '../../../../shared/validationErrorHelper';
import { LocaleKeys } from '../../../../shared/LocaleKeys';
import {
  DatePicker,
  Label,
  TextField,
  Toggle,
} from '@fluentui/react';
import { IGrantRequest } from '../../../../models/ELM/IGrantRequest';
import { isEmptyOrUndefined } from '../../../../shared/isEmptyOrUndefined';
import { QuestionType } from '../../../../models/ELM/QuestionType';

export class GrantRequestQuestions extends React.Component<
  IGrantRequestQuestionsProps,
  IGrantRequestQuestionsState
> {
  private _minStartDate: Date = new Date(Date.now());
  private _maxStartDate: Date = MAX_DATE;
  private _minStopDate: Date = new Date(Date.now());
  private _maxStopDate: Date = MAX_DATE;

  private _updateJustificationTimeout?: number;

  constructor(nextProps: IGrantRequestQuestionsProps) {
    super(nextProps);

    this.state = {
      ...this.getQuestionAnswerState(nextProps.policy),
      datesEnabled: false,
    };
  }

  public componentDidUpdate(prevProps: IGrantRequestQuestionsProps, prevState: IGrantRequestQuestionsState) {
    if (this.props.policy?.id !== prevProps.policy?.id) {
      this.setState(this.getQuestionAnswerState(this.props.policy));
    }
  }

  public render(): JSX.Element {
    // const questions = this.getQuestions();
    const { t } = this.props;

    return (<div>
      {this.getQuestions()}
      {
        this.datesPermitted ? (
          <Toggle
            label={t(LocaleKeys.requestForSpecificPeriod)}
            defaultChecked={false}
            onChanged={this.onDatesToggled}
            onText={t(LocaleKeys.yes)}
            offText={t(LocaleKeys.no)}
            checked={this.state.datesEnabled}
          />
        ) : null
      }
      {
        this.datesPermitted && this.state.datesEnabled ?
          this.renderDates()
          : null
      }

      <TextField
        label={t(LocaleKeys.businessJustification)}
        multiline
        rows={4}
        placeholder={
          this.props.policy?.isRequestorJustificationRequired ?
            t(LocaleKeys.required) : null
        }
        required={this.props.policy?.isRequestorJustificationRequired}
        onChange={this.onJustificationChanged}
      />
    </div>);
  }

  private getQuestionAnswerState(policy: IGrantPolicy | undefined): IGrantRequestQuestionsState {
    const questionAnswers = getQuestionAnswers([], policy);
    const answers = questionAnswers
      .map((qa: QuestionAnswer) => {
        return new AnswerString(
          qa.answerValue,
          getQuestionObject(qa),
          qa.answerDisplayValue
        );
      });

    this.props.setPartialGrantRequest({
      ...this.props.grantRequest,
      answers
    });
    return {
      ...this.state,
      questionAnswers
    };
  }

  private getQuestions = (): JSX.Element => {
    const { t, submitting } = this.props;
    const questionAnswers = this.state.questionAnswers;

    const questionAnswersView: JSX.Element[] = [];
    if (questionAnswers && questionAnswers.length > 0) {
      questionAnswers.forEach((questionAnswer: QuestionAnswer) => {
        questionAnswersView.push(
          <QuestionAnswerControl
            key={questionAnswer.id}
            t={t}
            submitting={submitting}
            validating={false}
            questionAnswer={questionAnswer}
            onChange={this.onAnswerChanged}
          />
        );
      });
    }
    return (<div>{questionAnswersView}</div>);
  };

  private onAnswerChanged = (
    questionId: string,
    answerValue: string,
    answerDisplayValue: string,
  ): void => {
    if (!this.props.grantRequest?.answers) {
      return;
    }

    let isValid = true;
    let updatedState = this.state.questionAnswers.map((questionAnswer) => {
      if (questionAnswer.id === questionId) {
        questionAnswer.answerValue = answerValue;
        questionAnswer.answerDisplayValue = answerDisplayValue;
        if (questionAnswer.$type === QuestionType.TextInputQuestion &&
          !isEmptyOrUndefined(questionAnswer.regexPattern) &&
          !isEmptyOrUndefined(questionAnswer.answerValue)) {
          const regex = new RegExp(questionAnswer.regexPattern);
          isValid = regex.test(questionAnswer.answerValue);
        }
        questionAnswer.isValid = isValid;
        return questionAnswer;
      }
      return questionAnswer;
    });
    this.setState({
      ...this.state,
      questionAnswers: updatedState
    });

    let updatedAnswers = this.props.grantRequest.answers.map(
      (answer: IAnswer) => {
        if (answer.answeredQuestion.id === questionId) {
          (answer as AnswerString).value = answerValue;
          (answer as AnswerString).displayValue = answerDisplayValue;
        }
        return answer;
      }
    );

    this.props.setPartialGrantRequest({
      ...this.props.grantRequest,
      accessPackageAssignment: {
        ...this.props.grantRequest?.accessPackageAssignment,
      },
      answers: updatedAnswers
    });
  };

  private renderDates = (): JSX.Element => {
    const { t } = this.props;
    const isStartDatePickerRequired = this.state.stopDate !== undefined;
    const isStopDatePickerRequired = this.state.startDate !== undefined;
    const startDateAriaLabel = `Pick the start date ${isStartDatePickerRequired? 'required' : ''}`;
    const stopeDateAriaLabel = `Pick the expiration date ${isStopDatePickerRequired? 'required' : ''}`;
    return (
      <div>
        <div>
          <Label>{t(LocaleKeys.startsOn)}</Label>
          <DatePicker
            onSelectDate={this.onStartDateSelected}
            value={this.state.startDate}
            formatDate={FormatDate}
            minDate={this._minStartDate}
            maxDate={this._maxStartDate}
            disabled={!this.state.datesEnabled}
            ariaLabel={startDateAriaLabel}
            strings={getDatePickerStrings(t)}
            isRequired={isStartDatePickerRequired}
          />
        </div>
        <div>
          <Label>{t(LocaleKeys.expiresOn)}</Label>
          <DatePicker
            onSelectDate={this.onStopDateSelected}
            value={this.state.stopDate}
            formatDate={FormatDate}
            minDate={this._minStopDate}
            maxDate={this._maxStopDate}
            disabled={!this.state.datesEnabled}
            ariaLabel={stopeDateAriaLabel}
            strings={getDatePickerStrings(t)}
            isRequired={isStopDatePickerRequired}
          />
        </div>
      </div>
    );
  };

  private onDatesToggled = (): void => {
    let datesEnabled = !this.state.datesEnabled;
    let startDate = this.state.startDate;
    let stopDate = this.state.stopDate;
    let schedule = undefined;

    if (datesEnabled) {
      const selectedPolicy = this.props.policy;
      const expirationDate = selectedPolicy && !isEmptyOrUndefined(selectedPolicy.expirationDate) ? new Date(selectedPolicy.expirationDate) : MAX_DATE;
      this._maxStartDate = expirationDate;
      this._maxStopDate = expirationDate;
      if (stopDate && stopDate > this._maxStopDate) {
        stopDate = expirationDate;
      }
      if (startDate && startDate > this._maxStopDate) {
        startDate = expirationDate;
      }
      schedule = {
        startDateTime: startDate,
        stopDateTime: stopDate,
      };
    }
    const newGrantRequest: Partial<IGrantRequest> = {
      ...this.props.grantRequest,
      accessPackageAssignment: {
        ...this.props.grantRequest?.accessPackageAssignment,
        schedule,
      }
    };
    this.setState({
      ...this.state,
      datesEnabled,
      startDate,
      stopDate,
    });
    this.props.setPartialGrantRequest(newGrantRequest);
  }

  private onStartDateSelected = (date: Date | null | undefined): void => {
    if (!date || !this.state.datesEnabled || !this.datesPermitted) {
      return;
    }
    this._minStopDate = date;

    const schedule = {
      ...this.props.grantRequest?.accessPackageAssignment?.schedule,
      startDateTime: date
    };
    const newGrantRequest: Partial<IGrantRequest> = {
      ...this.props.grantRequest,
      accessPackageAssignment: {
        ...this.props.grantRequest?.accessPackageAssignment,
        schedule,
      }
    };
    this.setState({
      ...this.state,
      startDate: date,
    });
    this.props.setPartialGrantRequest(newGrantRequest);
  }

  private onStopDateSelected = (date: Date | null | undefined): void => {
    if (!date || !this.state.datesEnabled || !this.datesPermitted) {
      return;
    }
    date.setHours(23, 59, 59);
    this._maxStartDate = date;

    const schedule = {
      ...this.props.grantRequest?.accessPackageAssignment?.schedule,
      stopDateTime: date
    };
    const newGrantRequest: Partial<IGrantRequest> = {
      ...this.props.grantRequest,
      accessPackageAssignment: {
        ...this.props.grantRequest?.accessPackageAssignment,
        schedule,
      }
    };
    this.setState({
      ...this.state,
      stopDate: date,
    });
    this.props.setPartialGrantRequest(newGrantRequest);
  }

  private onJustificationChanged = (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    // this event can take a while and hold down the UI thread
    if (this._updateJustificationTimeout) {
      clearTimeout(this._updateJustificationTimeout);
    }
    this._updateJustificationTimeout = setTimeout((justification?: string) => { this.updateJustification(justification); },
      200, newValue);
  }

  private updateJustification = (newValue: string | undefined) => {
    clearTimeout(this._updateJustificationTimeout);
    const newGrantRequest: Partial<IGrantRequest> = {
      ...this.props.grantRequest,
      justification: newValue,
    };
    this.props.setPartialGrantRequest(newGrantRequest);
  }

  private get datesPermitted(): boolean {
    return this.props.policy?.isCustomAssignmentScheduleAllowed ?? false;
  }
}