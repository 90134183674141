import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IGrantPolicy } from '../../../../models/ELM/IGrantPolicy';
import { IGrantRequest } from '../../../../models/ELM/IGrantRequest';
import { EntitlementActions } from '../../../../models/EntitlementActions';
import { IRootEntitlementsState } from '../../../../models/IEntitlementState';
import { history } from '../../../../redux/configureStore';
import { getPolicyTargetKey } from '../../../../shared';
import { GetPolicySelection } from './GetPolicySelection';
import {
  IConnectedGetPolicySelectionProps,
  IGetPolicySelectionActions,
  IGetPolicySelectionMappedProps
} from './GetPolicySelection.types';

const mapStateToProps = (
  state: IRootEntitlementsState,
  ownProps: IConnectedGetPolicySelectionProps
): IGetPolicySelectionMappedProps => {
  let policies: IGrantPolicy[] = [];
  let policiesDefined = false;
  if (state.app.partialGrantRequest?.accessPackageAssignment?.accessPackageId) {
    const accessPackageId = state.app.partialGrantRequest.accessPackageAssignment.accessPackageId;
    const grantTargetObjectId = state.app.partialGrantRequest.accessPackageAssignment.target?.objectId;
    const policyAssignmentKey = getPolicyTargetKey(accessPackageId, grantTargetObjectId);
    policiesDefined = state.app.policyAssignments.has(policyAssignmentKey);
    const policyIds = state.app.policyAssignments.get(policyAssignmentKey);
    if (policyIds) {
      policies = policyIds
        .map((policyId: string) => {
          return state.app.policies.entitiesById.get(policyId);
        })
        .filter((policy) => policy !== undefined) as IGrantPolicy[];
    }
  }

  return {
    ...ownProps,
    grantRequest: state.app.partialGrantRequest,
    policies,
    policiesDefined,
    policiesLoading: state.app.policies.isLoading,
    hasValidationErrors: state.app.validationErrors.length > 0,
    navigateTo(url: string): void {
      history.push(url);
    }
  };
};

const mapDispatchToProps = (dispatch: Dispatch<IRootEntitlementsState>): IGetPolicySelectionActions => {
  return {
    setPartialGrantRequest: createAction<Partial<IGrantRequest>>(EntitlementActions.setPartialGrantRequest, dispatch)
  };
};

/**
 * A redux connected AddGrantRequest control.
 * @description Supports translation
 */
export const ConnectedGetPolicySelection = connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('MyAccess')(GetPolicySelection));
