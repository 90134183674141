import { IAccessReviewDecision } from '../../../models/AccessReviews/IAccessReviewDecision';
import { checkFeatureAccess } from '../../../shared';
import { DecisionType } from '../../../models/RequestApprovals/DecisionType';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { ISupervisorCentricDecisionsListProps } from './SupervisorCentricDecisionsList.types';
import { IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import { TranslationFunction } from 'react-i18next';

export const getSupervisorCentricDecisionsListCommands = (
  t: TranslationFunction,
  props: ISupervisorCentricDecisionsListProps,
  selectedItems?: IAccessReviewDecision[]
): IContextualMenuItem[] => {
  const decisionCommandStatus =
    selectedItems && selectedItems.length > 0 ? false : true;
  const noUsers: boolean = props.accessReviewDecisionList.length === 0;

  const recommendationsEnabled: boolean = props.currentAccessReview && props.currentAccessReview.settings
    ? props.currentAccessReview.settings.accessRecommendationsEnabled : false;

  const approveCommand: IContextualMenuItem = {
    key: 'approveCommand',
    role: 'button',
    ariaLabel: checkFeatureAccess('reviewerExperience') ? t(LocaleKeys.continue) : t(LocaleKeys.approve),
    name: checkFeatureAccess('reviewerExperience') ? t(LocaleKeys.continue) : t(LocaleKeys.approve),
    iconProps: { iconName: 'accept' },
    disabled: true,
    onClick: () => {
      props.showBulkDecisionDialog(true, DecisionType.Approve);
    }
  };
  approveCommand.disabled = decisionCommandStatus;

  const denyCommand: IContextualMenuItem = {
    key: 'denyCommand',
    role: 'button',
    ariaLabel: checkFeatureAccess('reviewerExperience') ? (LocaleKeys.endAccess) : t(LocaleKeys.deny),
    name: checkFeatureAccess('reviewerExperience') ? t(LocaleKeys.endAccess) : t(LocaleKeys.deny),
    iconProps: { iconName: 'clear' },
    disabled: true,
    onClick: () => {
      props.showBulkDecisionDialog(true, DecisionType.Deny);
    }
  };
  denyCommand.disabled = decisionCommandStatus;

  const dontKnowCommand: IContextualMenuItem = {
    key: 'dontKnowCommand',
    role: 'button',
    ariaLabel: t(LocaleKeys.dontKnow),
    name: t(LocaleKeys.dontKnow),
    iconProps: { iconName: 'help' },
    disabled: true,
    onClick: () => {
      props.showBulkDecisionDialog(true, DecisionType.DontKnow);
    }
  };
  dontKnowCommand.disabled = decisionCommandStatus;

  // Reset Decisions will be enabled if no items are selected, or if at least one item
  // has a decision result other than "NotReviewed"
  let resetDisabled = true;
  if (!noUsers && selectedItems) {
    selectedItems!.forEach((decision: IAccessReviewDecision) => {
      if (decision.reviewResult !== DecisionType.NotReviewed) {
        resetDisabled = false;
      }
    });
  }

  const resetDecisionsCommand: IContextualMenuItem = {
    key: 'resetDecisionsCommand',
    role: 'button',
    ariaLabel: t(LocaleKeys.resetDecisions),
    name: t(LocaleKeys.resetDecisions),
    iconProps: { iconName: 'returntosession' },
    disabled: resetDisabled,
    onClick: props.showResetDecisions,
    split: true
  };

  const acceptRecommendationsCommand: IContextualMenuItem = {
    key: 'acceptRecommendationsCommand',
    role: 'button',
    ariaLabel: t(LocaleKeys.acceptRecommendations),
    name: t(LocaleKeys.acceptRecommendations),
    iconProps: { iconName: 'multiselect' },
    // When select all feature enabled, accept all Recommendation is available from review dialog
    disabled: noUsers || (checkFeatureAccess('reviewerExperience') && decisionCommandStatus),
    onClick: props.showAcceptRecommendations
  };

  const commands: IContextualMenuItem[] = [
    approveCommand,
    denyCommand,
    dontKnowCommand,
    resetDecisionsCommand
  ];

  if (recommendationsEnabled) {
    commands.push(acceptRecommendationsCommand);
  }

  return commands;
};

export const getSupervisorCentricDecisionsListFarCommands = (
  t: TranslationFunction,
  props: ISupervisorCentricDecisionsListProps
): IContextualMenuItem[] => {
  const filter: IContextualMenuItem = {
    key: 'filter',
    name: t(LocaleKeys.filter, { context: 'capitalize' }),
    iconProps: { iconName: 'Filter' },
    disabled: false,
    onClick: () => props.showReviewDecisionsFilter()
  };
  return [filter];
};
