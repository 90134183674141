import { EntitlementActions } from '../../../models';
import { ITelemetryAction } from './';

export const createTelemetryAction = (
  eventName: string,
  metadata?: {}
): ITelemetryAction => ({
  type: EntitlementActions.emitTelemetry,
  payload: {
    eventName,
    metadata: metadata || {},
  },
});
