import '../../css/global.scss';

import { BaseLayout } from '@amx/ux-twoui';
import { css } from '@fluentui/react';
import { getResponsiveMode, ResponsiveMode } from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import { logIntoTenant } from '@microsoft/portal-app/lib/auth/auth';
import { LoadingState } from '@microsoft/portal-app/lib/models/ILoading';
import { IRootNotificationsState } from '@microsoft/portal-app/lib/Notifications/models';
import { INotification } from '@microsoft/portal-app/lib/Notifications/models/INotification';
import { notificationRegistry } from '@microsoft/portal-app/lib/Notifications/notificationRegistry';
import { PortalProvider } from '@microsoft/portal-app/lib/PortalProvider';
import * as React from 'react';
import { translate } from 'react-i18next';

import { ConnectedHeader } from '../../components/Shared/Header/ConnectedHeader';
import {
  canShowSystemInitiatedIPF,
  InProductFeedbackDialog,
  IPFFeatureArea,
  KnownIPFSurveyName
} from '../../InProductFeedback';
import { IRootEntitlementsState } from '../../models';
import { history as hashHistory } from '../../redux/configureStore';
import { getObjectId, isInternalUser } from '../../shared';
import { getOfficeTheme } from '../../shared/getTheme';
import { getUserProfileUrl } from '../../shared/getUserProfileUrl';
import { LocaleKeys } from '../../shared/LocaleKeys';
import { AppContext, IAppContext } from '../AppProvider';
import { getFilterMenu } from '../MyAccessSearch';
import { Routes } from '../routes/Routes';
import { ConnectedApp } from './ConnectedApp';
import { shouldLoadPhotoDirectReports } from './loadPhotoDirectReports';
import { IMyAccessMainProps } from './MyAccessMain.types';

const globalStyles = require('../../css/global.scss');
export class MyAccessMainBase extends React.Component<IMyAccessMainProps> {
  static contextType = AppContext;

  private initialized = false;
  public componentDidMount(): void {
    this.props.recordBusinessEvent('init');
    this.props.refreshFeatures();
  }

  public componentDidUpdate(prevProps: IMyAccessMainProps): void {
    const {
      t,
      hideResourceSearchFilterOption,
      isUserInTenant,
      enableNewRequestForm,
      getDirectReports,
      getPhoto,
      loadableDirectReports,
      usersPhoto
    } = this.props;
    if (prevProps.isUserInTenant !== isUserInTenant && isUserInTenant) {
      this.props.getPendingApprovalsCount(true);
    }
    const filterItems = getFilterMenu(t, hideResourceSearchFilterOption);
    if (
      this.props.filterItems.length !== filterItems.length ||
      filterItems.some((item, index) => this.props.filterItems[index].key !== item.key)
    ) {
      this.props.updateFilterItems(filterItems);
    }

    this._initSystemInitiatedFeedback();

    const userObjectId = getObjectId();
    const userObjectIdIsPresent = Boolean(userObjectId && userObjectId !== '');
    const directReportsNotLoaded = loadableDirectReports.loadingState === LoadingState.notLoaded;

    shouldLoadPhotoDirectReports(
      enableNewRequestForm,
      userObjectIdIsPresent,
      directReportsNotLoaded,
      getDirectReports,
      prevProps.loadableDirectReports.loadingState,
      loadableDirectReports,
      usersPhoto,
      getPhoto
    );
  }

  public render(): React.ReactNode {
    const AppContextValue: IAppContext = this.context;
    const { IPFState, onHideIPF } = AppContextValue.InProductFeedbackOption;
    const { isIPFVisible, feedbackItemId } = IPFState;

    const { t, store, showFiltersIcon, hideResourceSearchFilterOption } = this.props;

    const getAppTitle = (): string => t(LocaleKeys.myAccess, { context: 'title' });

    const filterItems = getFilterMenu(t, hideResourceSearchFilterOption);

    const profileUrl = getUserProfileUrl();

    const responsiveMode = this._getResponsiveMode();

    return (
      <PortalProvider store={store}>
        <ConnectedApp
          appStateKey={'app'}
          appBarProps={{
            responsiveMode,
            searchVisible: this.props.searchBoxVisible,
            tenantLogoUrl: '',
            tenantName: '',
            waffleVisible: false,
            tenantPickerVisible: true,
            helpVisible: true,
            meControlVisible: true,
            flagsVisible: false,
            notificationsVisible: false,
            theme: getOfficeTheme(),
            showFiltersIcon
          }}
          tenantPickerProps={{
            options: this.props.tenantPickerOptions,
            // tslint:disable-next-line:typedef
            onChange(option): void {
              history.replaceState('', '', '/#/');
              logIntoTenant(option.key);
            }
          }}
          routes={[]}
          history={hashHistory}
          suiteHeader={this._renderSuiteHeader(true)}
          getAppTitle={getAppTitle}
          accountUrl={profileUrl}
          filterItems={filterItems}
        >
          <div className={css(globalStyles.baseLayoutClass)}>
            <BaseLayout variant="AMC">
              <Routes />
            </BaseLayout>
          </div>
        </ConnectedApp>
        {isIPFVisible && <InProductFeedbackDialog feedbackItemId={feedbackItemId} onDismiss={onHideIPF} />}
      </PortalProvider>
    );
  }

  private readonly _renderSuiteHeader = (enableSuiteHeader: boolean): JSX.Element | undefined => {
    return enableSuiteHeader ? <ConnectedHeader /> : undefined;
  };

  private _initSystemInitiatedFeedback(): void {
    if (this.initialized) return;

    const AppContextvalue = this.context;
    const user = this.props.user;
    if (user?.auth.isAuthenticated && user?.auth.profile && AppContextvalue) {
      this.initialized = true;
      const surveyName = KnownIPFSurveyName.myaccesssysteminitiated;
      if (
        isInternalUser() &&
        canShowSystemInitiatedIPF(
          window.localStorage,
          surveyName,
          user.auth.profile.tid,
          user.auth.profile.oid,
          IPFFeatureArea.MyAccess
        )
      ) {
        AppContextvalue.InProductFeedbackOption.onShowIPF(surveyName);
      }
    }
  }

  private _getResponsiveMode(): ResponsiveMode {
    return getResponsiveMode(window) ?? ResponsiveMode.large;
  }
}

/**
 * Connects the notifications for my access to the notification system
 */
notificationRegistry.addProvider(
  (state: IRootNotificationsState & Partial<IRootEntitlementsState>): INotification[] | undefined => {
    const { app } = state;

    const appNotifications = (app && (app.notifications as INotification[])) || [];

    const notifications = appNotifications;
    if (notifications) {
      notifications.forEach((notification: INotification) => {
        const options = notification.localizableMessage.options!;
        options.ns = options.ns || 'MyAccess';
      });
    }
    return notifications;
  }
);

export const MyAccessMain = translate('MyAccess')(MyAccessMainBase);
