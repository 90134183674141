/**
 * Gets the local storage of features and clear the storage if expired
 * @param key the feature flag key
 */
export function getWithExpiry(key: string) : any {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
}

/**
 * Sets the local storage of features with expiry
 * @param key the feature flag key
 * @param newValue the new value of the feature flag in local storage
 * @param ttl the time to live in second
 */
export function setOrUpdateWithExpiry(key: string, newValue: boolean, ttl: number): void {
  const itemStr = localStorage.getItem(key);

  // if expired or old/new value not equal, update and reset the expiry time
  if (!itemStr || JSON.parse(itemStr).value !== newValue) {
    const now = new Date();

    const item = {
      value: newValue,
      expiry: now.getTime() + ttl * 1000,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }
}

export function getCacheKey(featureKey: string, tenantId?: string) : string {
  return tenantId ? `${featureKey}-${tenantId}` : featureKey;
}