import { css, Label, MessageBar, MessageBarType, PrimaryButton, TextField } from '@fluentui/react';
import * as React from 'react';

import { useApprovalSubstitution } from '../../../hooks';
import { IApproverSubstitution } from '../../../models/RequestApprovals/IApproverSubstitution';
import { DateTimePicker } from '../../Shared/DateTimePicker';
import { StyledModal } from '../../Shared/StyledModal';
const styles = require('./ApprovalSubstitution.scoped.scss');

interface IApprovalSubstitutionProps {
  isOpen: boolean;
  onDismiss: () => void;
}

export function ApprovalSubstitution({ isOpen, onDismiss }: IApprovalSubstitutionProps): JSX.Element {
  const startDateRef = React.useRef<Date>(null);
  const endDateRef = React.useRef<Date>(null);
  const [userObjectId, setUserObjectId] = React.useState<string>();
  const { approverSubstitution, getApproverSubstitution, setApproverSubstitution } = useApprovalSubstitution();

  React.useEffect(() => {
    if (isOpen) {
      getApproverSubstitution();
    }
  }, [isOpen]);

  const submitApprovalSubstitution = (): void => {
    if (!startDateRef.current || !endDateRef.current || !userObjectId) {
      return;
    }
    const approverSubstitution: IApproverSubstitution = {
      schedule: {
        startDateTime: startDateRef.current?.toISOString(),
        stopDateTime: endDateRef.current?.toISOString(),
      },
      delegate: {
        isBackup: false,
        objectId: userObjectId,
        id: userObjectId,
        userId: userObjectId,
        displayName: "POC",
        description: "POC"
      }
    }
    setApproverSubstitution({ approverSubstitution });
  };

  const updateUserObjectId = (_ev: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>, newValue?: string): void => {
    if (!newValue) {
      return;
    }
    setUserObjectId(newValue);
  }
  const hasApproverSubstitution = approverSubstitution?.value;
  return (
    <StyledModal
      isOpen={isOpen}
      onDismiss={onDismiss}
    >
      <div className={css(styles.modalContent)}>
        <h3>Approval delegation</h3>
        {hasApproverSubstitution &&
          <MessageBar isMultiline messageBarType={MessageBarType.info}>
            You have an active delegation. <br />
            From {approverSubstitution?.value?.schedule?.startDateTime} to {approverSubstitution?.value?.schedule?.stopDateTime} <br />
            Delegator: {approverSubstitution?.value?.delegate?.displayName}
          </MessageBar>
        }
        <Label>Start Date</Label>
        <DateTimePicker containerStyles={css(styles.dateTimePicker)} ref={startDateRef} />
        <Label>End Date</Label>
        <DateTimePicker containerStyles={css(styles.dateTimePicker)} ref={endDateRef} />
        <TextField className={css(styles.objectIdTextField)} label='Delegator object id' value={userObjectId} onChange={updateUserObjectId} />
        <PrimaryButton
          onClick={submitApprovalSubstitution}
          text={hasApproverSubstitution ? 'Update' : 'Submit'}
          className={css(styles.submitButton)}
        />
      </div>
    </StyledModal>
  );
}