import { css } from '@fluentui/react';
import * as React from 'react';

import { useTelemetryWithMetadata, useTranslation } from '../../../hooks';
import { LocaleKeys } from '../../../shared';
import { isEligibilityScopedAtResource } from '../VmList/VmList.columns';
import { iso8601ToHours } from './QuickActivation.utils';
import { QuickActivationDialogRole } from './QuickActivationDialog';

// TODO: Augusto is working on a new way to import scss files, ignore for now
// eslint-disable-next-line @typescript-eslint/no-var-requires
const ramQuickActivationStyles = require('./ram.quickActivation.scss');

interface ContentProps {
  role: QuickActivationDialogRole;
}

export const Content: React.FunctionComponent<ContentProps> = (props: ContentProps) => {
  const t = useTranslation();
  const isRoleScopedAtResource = isEligibilityScopedAtResource(props.role.roleScope);
  const { reportCustomEventWithMetadata } = useTelemetryWithMetadata();

  const customSubText = (
    <div className={css(ramQuickActivationStyles.subTextPadding)}>
      {t(LocaleKeys.ramQuickActivationDialogNormalSubText)}{' '}
      <strong>{t(LocaleKeys.ramQuickActivationDialogBoldSubText)}</strong>
    </div>
  );

  const roleInfo = (
    <div className={css(ramQuickActivationStyles.roleSection)}>
      <div>
        <span>{t(LocaleKeys.ramQuickActivationDialogRoleSectionRoleName)}</span>
        <span>{props.role.roleName}</span>
      </div>
      <div>
        <span>{t(LocaleKeys.ramQuickActivationDialogRoleSectionScope)}</span>
        <span>
          {isRoleScopedAtResource
            ? t(LocaleKeys.ramQuickActivationDialogRoleSectionResource)
            : t(LocaleKeys.ramQuickActivationDialogRoleSectionResourceGroup)}
        </span>
      </div>
      <div>
        <span>{t(LocaleKeys.ramQuickActivationDialogRoleSectionStartTime)}</span>
        <span>{props.role.roleStartTimeDisplayText}</span>
      </div>
      <div>
        <span>{t(LocaleKeys.ramQuickActivationDialogRoleSectionDurationTitle)}</span>
        <span>
          {t(LocaleKeys.ramQuickActivationDialogRoleSectionDurationHours, {
            numericalDuration: iso8601ToHours(props.role.roleDurationInIso8601Format, reportCustomEventWithMetadata)
          })}
        </span>
      </div>
      {props.role.rolePorts !== undefined && (
        <div>
          <span>{t(LocaleKeys.ramQuickActivationDialogRoleSectionPorts)}</span>
          <span>{props.role.rolePorts}</span>
        </div>
      )}
      {props.role.roleIpAddresses !== undefined && (
        <div>
          <span>{t(LocaleKeys.ramQuickActivationDialogRoleSectionIpAddresses)}</span>
          <span>{props.role.roleIpAddresses}</span>
        </div>
      )}
    </div>
  );

  return (
    <>
      {customSubText}
      {roleInfo}
    </>
  );
};
