import { IPimAssignmentScheduleInstance, IPimEligibilityScheduleInstance } from './IPimActivationRequestParameters';
import { OsTypeEnum } from './OsTypeEnum';
import { PimRoleAssignmentStatusEnum } from './PimRoleAssignmentStatusEnum';
import { ResourceTypeEnum } from './ResourceTypeEnum';

export interface IVirtualMachine {
  readonly id: string;
  readonly displayName: string;
  readonly resourceType: ResourceTypeEnum;
  readonly subscriptionId: string;
  readonly subscriptionName: string;
  readonly resourceGroupName: string;
  readonly environment: string;
  readonly osType?: OsTypeEnum;
  readonly location?: string;
  readonly publicIpAddress: string;
  readonly assignmentStatus: PimRoleAssignmentStatusEnum;
  readonly favorite: boolean;
  readonly username: string;
  readonly eligibleRoleAssignmentList: readonly IPimEligibilityScheduleInstance[];
  readonly activeRoleAssignmentList: readonly IPimAssignmentScheduleInstance[];
}

export interface IVirtualMachineInResponse {
  readonly id: string;
  readonly displayName: string;
  readonly type: string;
  readonly properties: IProperties;
  readonly eligibilitySchedules: readonly IEligibilitySchedule[];
  readonly assignmentSchedules: readonly IAssignmentSchedule[];
}

export interface IEligibilitySchedule {
  readonly id: string;
  readonly roleEligibilityScheduleId: string;
  readonly createdOn: string;
  readonly status: string;
  readonly memberType: string;
  readonly startDateTime: string;
  readonly endDateTime: string;
  readonly scope: IScopeInEligibilitySchedule;
  readonly authorizationSystemInfo: IAuthorizationSystemInfo;
  readonly identityInfo: IIdentityInfo;
  readonly actionInfo: IActionInfo;
}

export interface IAssignmentSchedule {
  readonly id: string;
  readonly linkedRoleEligibilityScheduleId: string;
  readonly linkedRoleEligibilityScheduleInstanceId: string;
  readonly createdOn: string;
  readonly status: string;
  readonly memberType: string;
  readonly startDateTime: string;
  readonly endDateTime: string;
  readonly RoleAssignmentScheduleId: string;
  readonly RoleAssignmentOriginId: string;
  readonly AssignmentType: string;
}

interface IProperties {
  readonly externalId: string;
  readonly vmType: string;
  readonly osType: string;
  readonly adminUsername: string;
  readonly ipAddress: string;
  readonly subscriptionName: string;
  readonly resourceGroupName: string;
}

interface IScopeInEligibilitySchedule {
  readonly id: string;
  readonly displayName: string;
  readonly type: string;
}

interface IAuthorizationSystemInfo {
  readonly authorizationSystemId: string;
  readonly authorizationSystemType: string;
}

interface IIdentityInfo {
  readonly externalId: string;
  readonly source: ISource;
  readonly identityType: string;
}

interface ISource {
  readonly '@odata.type': string;
}

interface IActionInfo {
  readonly '@odata.type': string;
  readonly roles: readonly IRole[];
}

interface IRole {
  readonly id: string;
  readonly displayName: string;
}

export enum PimVmLoginRoleNames {
  VirtualMachineUserLogin = 'Virtual Machine User Login',
  VirtualMachineAdministratorLogin = 'Virtual Machine Administrator Login'
}

export interface IVirtualMachineExtendedDetails {
  aadExtensionProvisioningState: AadExtensionProvisioningState;
  id: string;
  identityType: VirtualMachineIdentityType;
  powerState: VirtualMachinePowerState;
}

export enum AadExtensionProvisioningState {
  Succeeded = 'Succeeded'
}

export enum VirtualMachineIdentityType {
  SystemAssigned = 'SystemAssigned'
}

export enum VirtualMachinePowerState {
  Running = 'PowerState/running',
  Deallocated = 'PowerState/deallocated',
  Connected = 'Connected',
  Disconnected = 'Disconnected',
  Expired = 'Expired'
}
