import { IAjaxRequest } from '@microsoft/portal-app/lib/auth';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';

import { EntitlementActions } from '../../../models/EntitlementActions';
import { EntityType } from '../../../models/EntityType';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState, IRootEntitlementsState } from '../../../models/IEntitlementState';
import { IVirtualMachine, IVirtualMachineExtendedDetails } from '../../../models/RAM/IVirtualMachine';
import { ResourceTypeEnum } from '../../../models/RAM/ResourceTypeEnum';
import { getAudience } from '../../../shared/AttachAudience';
import { registry } from '../myAccessRegistry';

export const getVirtualMachineDetailsEpic: Epic<IEntitlementAction<AnyPayload>, IRootEntitlementsState> = (
  action$: ActionsObservable<IEntitlementAction<IVirtualMachine>>,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
  return action$
    .ofType(EntitlementActions.getVirtualMachineDetails)
    .switchMap((action: IEntitlementAction<IVirtualMachine>) => {
      const virtualMachine = action.payload!;

      const ajaxRequest: IAjaxRequest = {
        method: 'POST',
        url: getAzureResourceGraphUrl(),
        audience: getAudience(EntityType.cloudInfrastructure),
        body: {
          query: getVirtualMachineDetailsQuery(virtualMachine)
        }
      };
      return ajax(ajaxRequest)
        .map((payload) => {
          return { type: EntitlementActions.setSelectedVirtualMachineDetails, payload: payload.response.data[0] };
        })
        .catch((error) => {
          return Observable.of({ type: EntitlementActions.getVirtualMachineDetailsFailed, payload: error });
        });
    });
};
registry.addEpic('getVirtualMachineDetailsEpic', getVirtualMachineDetailsEpic);

export const setSelectedVirtualMachineDetails = (
  state: IEntitlementState,
  action: IEntitlementAction<IVirtualMachineExtendedDetails>
): IEntitlementState => {
  const selectedVirtualMachineDetails = action.payload;
  const nextState = {
    ...state,
    selectedVirtualMachineDetails
  };

  return nextState;
};
registry.add(EntitlementActions.setSelectedVirtualMachineDetails, setSelectedVirtualMachineDetails);

export const getVirtualMachineDetailsFailed = (
  state: IEntitlementState,
  // tslint:disable-next-line
  _action: IEntitlementAction<Readonly<any>>
): Readonly<IEntitlementState> => {
  return state;
};
registry.add(EntitlementActions.getVirtualMachineDetailsFailed, getVirtualMachineDetailsFailed);
const getAzureResourceGraphUrl = (): string => {
  return 'https://management.azure.com/providers/Microsoft.ResourceGraph/resources?api-version=2022-10-01';
};
const getVirtualMachineDetailsQuery = (virtualMachine: IVirtualMachine): string => {
  const virtualMachineType =
    virtualMachine.resourceType === ResourceTypeEnum.VirtualMachine
      ? 'microsoft.compute/virtualmachines'
      : 'microsoft.hybridcompute/machines';
  const powerState =
    virtualMachine.resourceType === ResourceTypeEnum.VirtualMachine
      ? 'properties.extended.instanceView.powerState.code'
      : 'properties.status';
  const query = ` Resources
| where type =~ '${virtualMachineType}' and name =~ '${virtualMachine.displayName}'
| extend
	joinId = toupper(id),
    powerState = ${powerState},
    identityType = identity.type
| join kind=leftouter(
	Resources
	| where type =~ '${virtualMachineType}/extensions' and (name =~ 'AADSSHLogin' or name =~'AADLoginForWindows' or name =~ 'AADSSHLoginForLinux')
	| extend
		joinId = toupper(substring(id, 0, indexof(id, '/extensions'))),
		ExtensionName = name,
        aadExtensionProvisioningState = properties.provisioningState
) on $left.joinId == $right.joinId
| project id = tolower(joinId), powerState, identityType, aadExtensionProvisioningState`;
  return query;
};
