import { IAjaxRequest } from '@microsoft/portal-app/lib/auth';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';

import { formatConnectionDateTime } from '../../../components/RAM/Connection/Connection.utils';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { EntityType } from '../../../models/EntityType';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState, IRootEntitlementsState } from '../../../models/IEntitlementState';
import {
  CycleActivationStatus,
  IActivationStatusPayload,
  PimActivationApiRequestStatus,
  PimActivationRule
} from '../../../models/RAM/IActivationStatusPayload';
import { PimRoleActivationErrorCode } from '../../../models/RAM/IErrorDialog';
import { IPimActivationRequestParameters } from '../../../models/RAM/IPimActivationRequestParameters';
import { getAudience } from '../../../shared/AttachAudience';
import { getRamBaseUrlBasedOnEnv } from '../../../shared/getRamApiUrl';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { registry } from '../myAccessRegistry';
import { removeLeadingSlash } from './utils';

export const activateRamAccessEpic: Epic<IEntitlementAction<AnyPayload>, IRootEntitlementsState> = (
  action$: ActionsObservable<IEntitlementAction<IPimActivationRequestParameters>>,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
  return action$
    .ofType(EntitlementActions.activateRamAccess)
    .switchMap((action: IEntitlementAction<IPimActivationRequestParameters>) => {
      const parameters = action.payload!;

      const ajaxRequest: IAjaxRequest = {
        method: 'PUT',
        url: generateRoleAssignmentActivateUrl(parameters.scope, parameters.roleAssignmentScheduleRequestName),
        audience: getAudience(EntityType.cloudInfrastructure),
        body: {
          properties: parameters.properties
        }
      };

      return ajax(ajaxRequest)
        .map((payload) => {
          const requestStatus = payload.response.properties.status;
          const roleScheduleInfo = payload.response.properties.scheduleInfo;

          switch (requestStatus) {
            case PimActivationApiRequestStatus.Provisioned:
              return {
                type: EntitlementActions.setActivationStatus,
                payload: {
                  status: CycleActivationStatus.Provisioned,
                  roleEndTime: getEndDate(roleScheduleInfo.expiration.duration, roleScheduleInfo.startDateTime)
                }
              };
            case PimActivationApiRequestStatus.PendingApprovalProvisioning:
              return {
                type: EntitlementActions.setActivationStatus,
                payload: {
                  status: CycleActivationStatus.PendingApprovalProvisioning,
                  // TODO: Set roleEndTime as empty when status is PendingApprovalProvisioning
                  // TODO: Need to update this value when end-user clicks Connect button on landing page
                  roleEndTime: ''
                }
              };
            default:
              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              return {} as IEntitlementAction;
          }
        })
        .catch((error) => {
          return Observable.of({
            type: EntitlementActions.activateRamAccessFailed,
            payload: error
          });
        });
    });
};
registry.addEpic('activateRamAccessEpic', activateRamAccessEpic);

export const setActivationStatus = (
  state: IEntitlementState,
  action: IEntitlementAction<IActivationStatusPayload>
): Readonly<IEntitlementState> => {
  if (action.payload) {
    const activationStatus = action.payload;
    return {
      ...state,
      activationStatus: { status: activationStatus.status, roleEndTime: activationStatus.roleEndTime }
    };
  }
  return state;
};
registry.add(EntitlementActions.setActivationStatus, setActivationStatus);

export const activateRamAccessFailed = (
  state: IEntitlementState,
  // tslint:disable-next-line
  action: IEntitlementAction<Readonly<any>>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  const { payload } = action;
  const eligibilityRule = payload.response.error.message.includes(PimActivationRule.EligibilityRule);
  const errorCode = payload.response.error.code;
  let ramErrorDialogContent;

  switch (errorCode) {
    case PimRoleActivationErrorCode.RoleAssignmentExists:
      ramErrorDialogContent = {
        title: LocaleKeys.ramErrorRoleAlreadyExistsTitle,
        message: LocaleKeys.ramErrorRoleAlreadyExistsMessage,
        image: '/imgs/error_Warning.svg',
        code: errorCode
      };
      break;
    case PimRoleActivationErrorCode.RoleAssignmentRequestPolicyValidationFailed:
      if (eligibilityRule) {
        ramErrorDialogContent = {
          title: LocaleKeys.ramErrorRoleDoesNotExistTitle,
          message: LocaleKeys.ramErrorRoleDoesNotExistMessage,
          image: '/imgs/error_Warning.svg',
          code: errorCode
        };
      } else {
        ramErrorDialogContent = {
          title: LocaleKeys.ramErrorRoleValidationFailTitle,
          message: LocaleKeys.ramErrorRoleValidationFailMessage,
          image: '/imgs/error_Warning.svg',
          code: errorCode
        };
      }
      break;
    default:
      ramErrorDialogContent = {
        message: LocaleKeys.ramErrorTryAgain,
        image: '/imgs/error_Warning.svg',
        code: errorCode
      };
  }

  return { ...state, ramErrorDialogContent };
};
registry.add(EntitlementActions.activateRamAccessFailed, activateRamAccessFailed);

const generateRoleAssignmentActivateUrl = (scope: string, roleAssignmentScheduleRequestName: string): string => {
  const baseUrl = getRamBaseUrlBasedOnEnv();
  scope = removeLeadingSlash(scope);

  return `${baseUrl}${scope}/providers/Microsoft.Authorization/roleAssignmentScheduleRequests/${roleAssignmentScheduleRequestName}?api-version=2020-10-01`;
};

const getEndDate = (duration: string, startDate: string): string => {
  let minutes = 0;

  const hoursMatch = duration.match(/(\d+)H/);
  const minutesMatch = duration.match(/(\d+)M/);

  if (hoursMatch) {
    minutes += Number(hoursMatch[1]) * 60;
  }
  if (minutesMatch) {
    minutes += Number(minutesMatch[1]);
  }

  const date = new Date(startDate);
  date.setMinutes(date.getMinutes() + minutes);
  return formatConnectionDateTime(date);
};
