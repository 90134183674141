import { IPageData } from '../models/IPageData';

// tslint:disable-next-line
export const GetEntityList = (list: IPageData<any>, isFiltering: boolean) => {
  let entitiesList: {
    filteredEntities: ReadonlyArray<string>;
  } = {
    filteredEntities: isFiltering
      ? list.filtered.entities
      : list.unfiltered.entities
  };
  return entitiesList;
};
