import {
  ContextualMenu,
  css,
  FontClassNames,
  Link,
  IconButton
} from '@fluentui/react';
import { gridUnit } from '@uifabric/portal-ux/lib/styling/stylingConstants';
import React, { useState } from 'react';

import { useTelemetryWithMetadata, useTranslation } from '../../../hooks';
import { LocaleKeys } from '../../../shared';
import { IOverflowActionButtonProps } from './';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');

/**
 * This component has two main views:
 * The primary view renders as a text button with onClick event handler.
 * The secondary view renders as an ellipsis icon which shows a contextual menu on press.
 * The contextual menu can support arbitrary items with their own onClick handlers.
 */
export function OverflowActionButton(props: IOverflowActionButtonProps) {
  return (
    <div className={props.className}>
      {props.showPrimaryAction ? (
        <PrimaryActionButton {...props} />
      ) : (
        <SecondaryActionButton {...props} />
      )}
    </div>
  );
}

function PrimaryActionButton(props: IOverflowActionButtonProps) {
  const { reportCustomEventWithMetadata } = useTelemetryWithMetadata();

  return (
    <Link
      className={css(FontClassNames.medium, myAccessStyles.hoverUnderline)}
      onClick={(event) => {
        event.stopPropagation();
        reportCustomEventWithMetadata('action-button/click-primary');
        props.onPrimaryPress && props.onPrimaryPress();
      }}
    >
      {props.primaryText}
    </Link>
  );
}

function SecondaryActionButton(props: IOverflowActionButtonProps) {
  const [showContextualMenu, setShowContextualMenu] = useState(false);
  const t = useTranslation();
  const { reportCustomEventWithMetadata } = useTelemetryWithMetadata();

  return (
    <div>
      <IconButton
        id={`overflow-button-${props.itemKey}`}
        ariaLabel={t(LocaleKeys.more)}
        iconProps={{iconName: 'More'}}
        onClick={(event) => {
          event.stopPropagation();
          reportCustomEventWithMetadata('action-button/click-overflow');
          setShowContextualMenu(true);
        }}
        styles={{
          icon: {
            color: '#3a92db',
            fontSize: gridUnit * 2,
            fontWeight: 800
          }
        }}
      />

      <ContextualMenu
        hidden={!showContextualMenu}
        target={`#overflow-button-${props.itemKey}`}
        items={props.secondaryMenuItems!.map((item) => ({
          ...item,
          iconProps: {
            ...item.iconProps,
            iconName:
              (item.iconProps && item.iconProps.iconName) || 'LocationCircle',
          },
        }))}
        onDismiss={() => setShowContextualMenu(false)}
      />
    </div>
  );
}
