import { checkFeatureAccess, Feature } from '../../../shared';
import { registry } from '../myAccessRegistry';

/**
 * Get the client UX feature enabled/disabled and initialize admin opt-in feature to false
 */
export const updateFeatureOnAuth = registry.add(
  '@app/user/auth/success',
  (state, action) => {
    if (!action.payload || !action.payload.profile) {
      return state;
    }

    const { oid, tid } = action.payload.profile as { oid: string; tid: string };

    const features = { ...state.features.isEnabled };

    for (const key of Object.keys(features)) {
      features[key] = checkFeatureAccess(key as Feature, oid, tid);
    }

    return {
      ...state,
      features: {
        isLoaded: true,
        isEnabled: features,
      },
    };
  }
);
