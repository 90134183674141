import { IODataValueResponse } from '@microsoft/portal-app/lib/odata-utils';
import { IOverviewError } from '../../src/models/IOverviewData';
import { isNullOrUndefined } from 'util';

export const getResponseValue = <T = {}>(payload: IODataValueResponse<T>) => {
  if (!isNullOrUndefined(payload.response)) {
    if (!isNullOrUndefined(payload.response.value)) {
      return payload.response.value;
    } else {
      return [payload.response];
    }
  } else {
    return undefined;
  }
};

export const getResponseError = (error: any): IOverviewError[] => {
  let overviewError: IOverviewError = {
    code: 'unknown error',
    message: 'unknown error'
  };

  if (!isNullOrUndefined(error.response)) {
    if (!isNullOrUndefined(error.response.error)) {
      overviewError = {
        code: error.response.error.code,
        message: error.response.error.message
      }
    }
   } else if (!isNullOrUndefined(error.error)){
    if (error.error.message?.includes('AADSTS53003: Access has been blocked by Conditional Access policies'))
    {
      overviewError = {
        code: 'BlockedByConditionalAccess',
        message: error.error.message
      }
    }
  } else if (!isNullOrUndefined(error)) {
      overviewError = {
        code: error.name,
        message: error.message
      }
      console.log(overviewError);
   }

  return [overviewError];
}