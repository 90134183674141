import { AppPanel } from '@uifabric/portal-ux/lib/AppPanel';
import {
  css,
  FontClassNames,
  Icon,
  Link,
  PanelType,
} from '@fluentui/react';
import * as React from 'react';
import { InjectedTranslateProps } from 'react-i18next';
import { LocaleKeys } from '../../../shared/LocaleKeys';

const styles = require('./PanelOptionDetails.scoped.scss');
const myAccessStyles = require('../../../css/myAccess.scoped.scss');

export interface IPanelOptionDetailsProps extends InjectedTranslateProps {
  headerText: string;
  previousLinkText: string;
  onPrevious: () => void;
  onDismiss: () => void;
  onRenderBody: () => JSX.Element;
}

export class PanelOptionDetails extends React.Component<
  IPanelOptionDetailsProps,
  {}
> {
  constructor(nextProps: IPanelOptionDetailsProps) {
    super(nextProps);
  }

  public render(): JSX.Element {
    const {
      headerText,
      previousLinkText,
      t,
      onRenderBody,
      onPrevious,
    } = this.props;
    return (
      <AppPanel
        styles={{ headerText: { fontWeight: '400' } }}
        isBlocking={true}
        isOpen={true}
        onDismiss={this.props.onDismiss}
        type={PanelType.smallFixedFar}
        headerText={t(headerText)}
        isLightDismiss={true}
        closeButtonAriaLabel={t(LocaleKeys.cancel)}
        hasCloseButton={false}
      >
        <div className={css(styles.container)}>
          <Link onClick={onPrevious}>
            <div className={css(styles.content)}>
              <div className={css(styles.iconContainer)}>
                <Icon
                  iconName={'ChevronLeft'}
                  className={css(myAccessStyles.semiBoldText)}
                />
              </div>
              <div>
                <span
                  className={css(
                    FontClassNames.small,
                    myAccessStyles.semiBoldText
                  )}
                >
                  {t(previousLinkText)}
                </span>
              </div>
            </div>
          </Link>
        </div>
        {onRenderBody()}
      </AppPanel>
    );
  }
}
