import { FontClassNames } from '@fluentui/react';
import { TranslationFunction } from 'i18next';
import {
  ActivityItem,
  ColorClassNames,
  css,
  Label,
  Link,
} from '@fluentui/react';
import * as React from 'react';
import { IGrantRequest } from '../../../models/ELM/IGrantRequest';
import { asLocalizedText } from '../../../shared';
import { FormatDateTime } from '../../../shared/FormatDateTime';
import { LocaleKeys } from '../../../shared/LocaleKeys';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');

export interface IRequestSummaryProps {
  t: TranslationFunction;
  requestorName: string;
  targetName?: string;
  entitlementName: string;
  grantRequest?: IGrantRequest;
  showMoreGrantRequestDetails(): void;
}

export class RequestSummary extends React.Component<IRequestSummaryProps, {}> {
  constructor(nextProps: IRequestSummaryProps) {
    super(nextProps);
  }

  public render(): JSX.Element {
    const { requestorName, targetName, entitlementName, grantRequest } = this.props;

    const timeStampDiv = (
      <div>
        <div>
          <span className={css(FontClassNames.xSmall)}>
            {FormatDateTime(grantRequest!.createdDateTime)}{' '}
          </span>
        </div>
        <Link
          onClick={this.props.showMoreGrantRequestDetails}
          className={css(FontClassNames.medium, myAccessStyles.marginTopXSmall, myAccessStyles.underline)}
        >
          {this.props.t(LocaleKeys.details)}
        </Link>
      </div>
    );

    return (
      // Be very careful modifying the following div: css, localization is subtle.
      <div
        className={css(
          myAccessStyles.appPanelLabel,
          myAccessStyles.marginBottomMedium
        )}
      >
        <Label
          className={css(
            myAccessStyles.appPanelLabel,
            myAccessStyles.marginBottomXSmall
          )}
        >
          {this.props.t(LocaleKeys.requestDetails)}
        </Label>
        <ActivityItem
          activityDescription={getRequestDetailsSynopsis(this.props.t, entitlementName, requestorName, targetName)}
          timeStamp={timeStampDiv}
          className={css(
            'ms-pii',
            myAccessStyles.marginBottomXSmall,
            ColorClassNames.neutralLighterBackground,
            myAccessStyles.padding,
            myAccessStyles.wordBreak,
            myAccessStyles.paddingLeftSmallPlus,
            myAccessStyles.paddingRightSmallPlus
          )}
        />
      </div>
    );
  }
}

const getRequestDetailsSynopsis = (
  t: TranslationFunction,
  entitlementName: string,
  requestorName: string,
  targetName?: string,
): string => {
  let synopsis;
  if (targetName) {
    synopsis = {
      key: LocaleKeys.requestDetailsSynopsisWithTarget,
      options: {
        entitlementName: entitlementName,
        requestorName: requestorName,
        targetName: targetName,
      },
    };
  } else {
    synopsis = {
      key: LocaleKeys.requestDetailsSynopsis,
      options: {
        entitlementName: entitlementName,
        requestorName: requestorName,
      },
    }
  }

  return asLocalizedText(synopsis, t);
};
