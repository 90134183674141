import { isEmptyOrUndefined } from '../../shared/isEmptyOrUndefined';
import { DecisionType } from '../RequestApprovals/DecisionType';

/* Represents values to be presented in history activity cards. */
export enum RequestStatus {
  PendingValidation = 'pendingValidation',
  ValidationFailed = 'validationFailed',
  Accepted = 'accepted',
  PendingApproval = 'pendingApproval',
  PendingApprovalEscalated = 'pendingApprovalEscalated',
  PendingApprovalSecondStage = 'pendingApproval',
  PendingApprovalThirdStage = 'pendingApproval',
  PendingApprovalEscalatedSecondStage = 'pendingApprovalEscalated',
  PendingApprovalEscalatedThirdStage = 'pendingApprovalEscalated',
  ApprovedFirstStage = 'approvedFirstStage',
  ApprovedSecondStage = 'approvedSecondStage',
  ApprovedThirdStage = 'approvedThirdStage',
  AutoApproved = 'autoApproved',
  approvalTimeOut = 'approvalTimeOut',
  Approved = 'approved',
  PendingNotBefore = 'pendingNotBefore',
  ReadyToFulfill = 'readyToFulfill',
  Fulfilled = 'fulfilled',
  Denied = 'denied',
  Failed = 'failed',
  Canceled = 'canceled',
  FulfilledNotificationTriggered = 'fulfilledNotificationTriggered',
  PartiallyDelivered = 'partiallyDelivered',
  ApprovalTimedOut = 'approvalTimedOut',
  FailedPolicyExpirationCheck = 'failedPolicyExpirationCheck',
  RemoveFulfilled = 'removeFulfilled',
  WaitingForCallback = 'waitingForCallback',
  CallbackTimedOut = 'callbackTimedOut',
  AssignmentRequestCreated_Resume = 'assignmentRequestCreated_Resume',
  AssignmentRequestApproved_Resume = 'assignmentRequestApproved_Resume',
  AssignmentRequestGranted_Resume = 'assignmentRequestGranted_Resume',
  AssignmentRequestDenied_Resume = 'assignmentRequestDenied_Resume',
  AssignmentRequestRemoved_Resume = 'assignmentRequestRemoved_Resume',
  DontKnow = 'dontKnow',
  PendingRemoval = "pendingRemoval",
}

export const isRequestStatusCancellable = (
  requestStatus: RequestStatus
): boolean => {
  if (isEmptyOrUndefined(requestStatus)) {
    return false;
  }

  switch (requestStatus) {
    case RequestStatus.Accepted:
    case RequestStatus.PendingApproval:
    case RequestStatus.PendingNotBefore:
    case RequestStatus.PendingValidation:
    case RequestStatus.PendingApprovalEscalated:
      return true;
    default:
      return false;
  }
};

export const isRequestStatusResubmittable = (
  status: RequestStatus
): boolean => {
  switch (status) {
    case RequestStatus.Denied:
    case RequestStatus.ApprovalTimedOut:
      return true;
    default:
      return false;
  }
};

export const showHoverCardForStatus = (status: RequestStatus): boolean => {
  switch (status) {
    case RequestStatus.Approved:
    case RequestStatus.ApprovedFirstStage:
    case RequestStatus.ApprovedSecondStage:
    case RequestStatus.Denied:
      return true;
    default:
      return false;
  }
};

export const getDecisionTypeFromRequestStatus = (
  status: RequestStatus
): DecisionType | null => {
  switch (status) {
    case RequestStatus.Approved:
    case RequestStatus.ApprovedFirstStage:
    case RequestStatus.ApprovedSecondStage:
      return DecisionType.Approve;
    case RequestStatus.Denied:
      return DecisionType.Deny;
    default:
      return null;
  }
};
