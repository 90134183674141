
import { css, FontClassNames } from '@fluentui/react';
import * as React from 'react';
import { InjectedTranslateProps } from 'react-i18next';

import { IGrantRequest } from '../../../models';
import { IRaeRequest } from '../../../models/RequestApprovals/IRaeRequest';
import { IWithFeaturesProp } from '../../../shared';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { GrantRequestApprovalHistory } from '../../ELM/GrantRequestApprovalHistory/GrantRequestApprovalHistory';
import { ApprovalHistory } from '../../RequestApprovals/ApprovalHistory/ApprovalHistory';
import { PanelOptionDetails } from '../PanelOptionDetails/PanelOptionDetails';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');

export interface IApprovalDetails
  extends InjectedTranslateProps,
    IWithFeaturesProp {
  raeRequest?: IRaeRequest;
  grantRequest?: IGrantRequest;
  onPrevious: () => void;
  onDismiss: () => void;
}

export class ApprovalDetails extends React.Component<IApprovalDetails, {}> {
  constructor(nextProps: IApprovalDetails) {
    super(nextProps);
  }

  public render(): JSX.Element {
    const { t, onPrevious, onDismiss } = this.props;
    return (
      <PanelOptionDetails
        t={t}
        headerText={LocaleKeys.approvalHistory}
        previousLinkText={LocaleKeys.reviewRequest}
        onPrevious={onPrevious}
        onDismiss={onDismiss}
        onRenderBody={this._onRenderBodyApprovalHistory}
      />
    );
  }

  private _onRenderBodyApprovalHistory = (): JSX.Element => {
    const { grantRequest, t } = this.props;
    if (grantRequest) {
      return (
        <GrantRequestApprovalHistory
          grantRequest={grantRequest}
          t={t}
          displayHeading={false}
        />
      );
    }

    return (
      <div
        className={css(FontClassNames.smallPlus, myAccessStyles.marginTopSmall)}
      >
        {this.props.t(LocaleKeys.noHistoryInApprovalRequest)}
      </div>
    );
  }
}
