import { determineEnvironment, EnvironmentType } from '@microsoft/portal-app/lib/environment';
import { settingsManager } from './settings';
import { Routes } from './Routes';

export const onLeavePreview = () => {

  const environment = determineEnvironment(location);
  const accessReviewPath = '#/accessreviews';
  const showLeavePreviewLink =
  (environment === EnvironmentType.PPE ||
    environment === EnvironmentType.PROD) &&
  location.hash.includes(Routes.accessReviews);

  const leavePreviewUrl =
    settingsManager.get('userProfileUrl') + accessReviewPath;
  return showLeavePreviewLink ? () => {
      window.location.href = leavePreviewUrl! + '#/accessreviews';
    }
  : undefined;
};