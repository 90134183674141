import * as moment from 'moment';
import { IGrantRequest } from '../models';
import { RequestStatus } from '../models/ELM/RequestStatus';
import {
  IRaeRequest,
  IUserGovernanceCriteria
} from '../models/RequestApprovals/IRaeRequest';
import { getUserFromAuth } from './authHelper';
import { emptyGuid } from './constants';
import { isEmptyOrUndefined } from '../shared';

//#region "RAE's approval helper methods"
export const isSingleStageNormalApproval = (
  raeRequest: IRaeRequest
): boolean => {
  return !isMultiStageApproval(raeRequest) && !isEscalationApproval(raeRequest);
};

export const isMultiStageApproval = (raeRequest: IRaeRequest): boolean => {
  return raeRequest.parentReviewId !== emptyGuid;
};

export const isEscalationApproval = (raeRequest: IRaeRequest): boolean => {
  return (
    raeRequest.policy.escalationCriteria !== null &&
    raeRequest.policy.escalationCriteria.length > 0
  );
};

export const isOriginalApprover = (raeRequest: IRaeRequest): boolean => {
  const user = getUserFromAuth();
  let isOriginal = false;
  raeRequest.policy.decisionMakerCriteria.forEach(
    (criteria: IUserGovernanceCriteria) => {
      if (criteria.userId === user!.objectId) {
        isOriginal = true;
      }
    }
  );
  return isOriginal;
};

export const isAlternateApprover = (raeRequest: IRaeRequest): boolean => {
  const user = getUserFromAuth();
  let isAlternate = false;
  raeRequest.policy.escalationCriteria.forEach(
    (criteria: IUserGovernanceCriteria) => {
      if (criteria.userId === user!.objectId) {
        isAlternate = true;
      }
    }
  );
  return isAlternate;
};

export const getExpirationDate = (raeRequest: IRaeRequest): Date => {
  if (isEscalationApproval(raeRequest) && isOriginalApprover(raeRequest)) {
    // if it's original approver, show escalated date
    return getEscalatedDate(raeRequest);
  }

  return raeRequest.endDateTime;
};

export const getEscalatedDate = (raeRequest: IRaeRequest): Date => {
  const durationBeforeEscalated = moment.duration(
    raeRequest.settings.timeSpanToEscalation
  );
  const escalationDate = moment(raeRequest.createdDateTime)
    .add(durationBeforeEscalated)
    .toDate();
  return escalationDate;
};

export const pastDue = (dueDate: Date): boolean => {
  return moment().isAfter(dueDate);
};

//#endregion "RAE's approval helper methods"

//#region "ELM's Approval helper methods"

/**
 * Method to check whether the request or the approval stage has been escalated or not.
 * @param grantRequest The grantRequest object
 */
export const isGrantRequestEscalated = (grantRequest: IGrantRequest): boolean => {
  return (
    grantRequest !== null &&
    RequestStatus[grantRequest.requestStatus] === RequestStatus.PendingApprovalEscalated
  );
};

/**
 * Method to get the stage creation date based on the request activity history.
 * @param grantRequest The grantRequest object.
 */
export const getStageCreationDate = (grantRequest: IGrantRequest): Date => {
  let currentActivityIndex = grantRequest.history.length - 1;
  let requestCreatedDate = grantRequest.history[currentActivityIndex].actionDateTime;
  if (RequestStatus[grantRequest.history[currentActivityIndex].action] === RequestStatus.PendingApprovalEscalated) {
    return grantRequest.history[currentActivityIndex-1].actionDateTime;
  }
  return requestCreatedDate;
};

/**
 * Returns the date when the grant request or the individual stage was or will be escalated.
 * @param grantRequest The grantRequest object.
 */
export const getGrantRequestEscalatedDate = (grantRequest: IGrantRequest): Date => {
  let currentActivityIndex = grantRequest.history.length-1;
  let requestCreatedDate = grantRequest.history[currentActivityIndex].actionDateTime;
  if (RequestStatus[grantRequest.history[currentActivityIndex].action] === RequestStatus.PendingApprovalEscalated) {
    return requestCreatedDate;
  }
  let durationBeforeEscalated =
   getCurrentStageIndex(grantRequest) < 0 // Defensive code for approval stages change after request created.
   ? 0
   : grantRequest.approval.requestApprovalSettings.approvalStages[getCurrentStageIndex(grantRequest)].escalationTimeInMinutes;
  let requestEscalatedDate = moment(requestCreatedDate)
  .add(durationBeforeEscalated, 'minutes').toDate();
  return requestEscalatedDate;
};

/**
 * Checks if the current signed-in user is the primary approver or not.
 * Todo: add handling for subject type group later.
 * @param grantRequest The grantRequest object.
 */
export const isOriginalGrantRequestApprover = (grantRequest: IGrantRequest): boolean => {
  const user = getUserFromAuth();
  let isOriginal = false;

  // Defensive code to handle policy turn off after request created.
  if (isApprovalStageNullOrEmpty(grantRequest)) {
    return false;
  }
  let currentStageIndex = getCurrentStageIndex(grantRequest);
  grantRequest.approval.requestApprovalSettings.approvalStages[currentStageIndex].primaryApprovers.forEach(
    // tslint:disable-next-line:no-any
    (approver: any) => {
      if (approver.objectId === user!.objectId) {
        isOriginal = true;
      }
    }
  );
  return isOriginal;
};

/**
 * Checks if the current signed-in user is the alternate approver or not.
 * Todo: add handling for subject type group later.
 * @param grantRequest The grantRequest object.
 */
export const isAlternateGrantRequestApprover = (grantRequest: IGrantRequest): boolean => {
  const user = getUserFromAuth();
  let isAlternate = false;

  // Defensive code to handle policy turn off after request created.
  if (isApprovalStageNullOrEmpty(grantRequest)) {
    return false;
  }

  let currentStageIndex = getCurrentStageIndex(grantRequest);
  grantRequest.approval.requestApprovalSettings.approvalStages[currentStageIndex].escalationApprovers.forEach(
    // tslint:disable-next-line:no-any
    (approver: any) => {
      if (approver.objectId === user!.objectId) {
        isAlternate = true;
      }
    }
  );
  return isAlternate;
};

/**
 * Returns the index of current approval stage.
 * @param grantRequest The grantRequest object.
 */
export const getCurrentStageIndex = (grantRequest: IGrantRequest): number => {
  // return getTotalStagesOfGrantRequest(grantRequest) - 1; // This always returns the last stage other than current stage
  // If stages reduces in policy, return the highest stage index as current index which is consistent with previous behavior
  // to avoid out of boundary error.
  return grantRequest.approval.steps.length <= getTotalStagesOfGrantRequest(grantRequest)
  ? grantRequest.approval.steps.length - 1
  : getTotalStagesOfGrantRequest(grantRequest) - 1;
};

/**
 * Returns the count of total approval stages in the grant request.
 * @param grantRequest The grantRequest object.
 */
export const getTotalStagesOfGrantRequest = (grantRequest: IGrantRequest): number => {
  return grantRequest.approval.requestApprovalSettings.approvalStages.length;
};

/**
 * Returns true if the current approval stage has escalation enabled.
 * @param grantRequest The grantRequest object.
 */
export const canCurrentStageBeEscalated = (grantRequest: IGrantRequest): boolean => {
  // Defensive code to handle policy turn off after request created.
  if (isApprovalStageNullOrEmpty(grantRequest)) { return false; }
  let currentStageIndex = getCurrentStageIndex(grantRequest);
  return grantRequest.approval.requestApprovalSettings.approvalStages[currentStageIndex].isEscalationEnabled;
};

/**
 * Returns true if the grant request has just one approval stage which can't be escalated.
 * @param grantRequest The grantRequest object.
 */
export const isSingleStageNormalGrantRequest = (
  grantRequest: IGrantRequest
): boolean => {
  return (getTotalStagesOfGrantRequest(grantRequest) === 1) && !isGrantRequestEscalated(grantRequest);
};

/**
 * Returns stage completion date based on the request activity history.
 * @param grantRequest The grantRequest object.
 */
export const getGrantRequestCompletionDate = (grantRequest: IGrantRequest): Date => {
  return grantRequest.history[grantRequest.history.length - 1].actionDateTime;
};

/**
 * Returns true if approver's justification is required for current stage.
 * @param grantRequest The grantRequest object.
 */
export const isApproverJustificationRequiredForCurrentStage = (grantRequest: IGrantRequest): boolean => {
  // Defensive code to handle policy turn off after request created.
  if (isApprovalStageNullOrEmpty(grantRequest)) { return false; }
  let currentStageIndex = getCurrentStageIndex(grantRequest);
  return grantRequest.approval.requestApprovalSettings.approvalStages[currentStageIndex].isApproverJustificationRequired;
};

/**
 * Returns the due date based on the signed-in user's details and approval stage settings.
 * @param grantRequest The grantRequest object.
 */
export const getDueDate = (grantRequest: IGrantRequest): Date | null => {
  // Defensive code for policy changing to no approval,
  // should consider to store necessary approval settings in stead of getting current policy on the fly

  if (isApprovalStageNullOrEmpty(grantRequest)) {
    return null;
  }

  let currentStageIndex = getCurrentStageIndex(grantRequest);

  let currentActivityIndex = grantRequest.history.length-1;
  let currentActivity = grantRequest.history[currentActivityIndex];

  let timeOutInDays: number =
  grantRequest.approval.requestApprovalSettings.approvalStages[currentStageIndex].approvalStageTimeOutInDays;
  let dueDate: Date;

  if(RequestStatus[grantRequest.requestStatus] === RequestStatus.PendingApproval &&
  canCurrentStageBeEscalated(grantRequest) &&
  isOriginalGrantRequestApprover(grantRequest)) {
      let durationBeforeEscalated =
      grantRequest.approval.requestApprovalSettings.approvalStages[currentStageIndex].escalationTimeInMinutes;
      dueDate = moment(currentActivity.actionDateTime)
        .add(durationBeforeEscalated, 'minutes')
        .toDate();
  } else if(RequestStatus[grantRequest.requestStatus] === RequestStatus.PendingApprovalEscalated) {
    if(isAlternateGrantRequestApprover(grantRequest)) {
      let actionDateTime = RequestStatus[currentActivity.action] === RequestStatus.PendingApprovalEscalated ?
      grantRequest.history[currentActivityIndex-1].actionDateTime :
      currentActivity.actionDateTime;
      dueDate = moment(actionDateTime).add(timeOutInDays, 'd').toDate();
    } else {
      return getGrantRequestEscalatedDate(grantRequest);
    }
  } else {
    dueDate = moment(grantRequest.history[currentActivityIndex].actionDateTime).add(timeOutInDays, 'd').toDate();
  }
  return dueDate;
};

/**
 * Returns the stage approval requested date based on the signed-in user's details and approval stage settings.
 * @param grantRequest The grantRequest object.
 */
export const getRequestedDate = (grantRequest: IGrantRequest): Date | null => {
  // Defensive code for policy changing to no approval,
  // should consider to store necessary approval settings in stead of getting current policy on the fly

  if (isApprovalStageNullOrEmpty(grantRequest)) {
    return null;
  }

  let currentActivityIndex = grantRequest.history.length-1;
  let currentActivity = grantRequest.history[currentActivityIndex];

  let requestedDate: Date;

  if(RequestStatus[grantRequest.requestStatus] === RequestStatus.PendingApproval &&
  canCurrentStageBeEscalated(grantRequest) &&
  isOriginalGrantRequestApprover(grantRequest)) {
      requestedDate = moment(currentActivity.actionDateTime).toDate();
  } else if(RequestStatus[grantRequest.requestStatus] === RequestStatus.PendingApprovalEscalated) {
    if(isAlternateGrantRequestApprover(grantRequest)) {
      let actionDateTime = RequestStatus[currentActivity.action] === RequestStatus.PendingApprovalEscalated ?
      grantRequest.history[currentActivityIndex-1].actionDateTime :
      currentActivity.actionDateTime;
      requestedDate = moment(actionDateTime).toDate();
    } else {
      return moment(grantRequest.history[currentActivityIndex].actionDateTime).toDate();
    }
  } else {
    requestedDate = moment(grantRequest.history[currentActivityIndex].actionDateTime).toDate();
  }
  return requestedDate;
};

export const isApprovalStageNullOrEmpty = (grantRequest: IGrantRequest): boolean => {
  return isEmptyOrUndefined(grantRequest.approval?.requestApprovalSettings?.approvalStages) ||
  grantRequest.approval.requestApprovalSettings.approvalStages.length === 0;
};

//#endregion "ELM's Approval helper methods"