export interface IPimRoleManagementPolicyRule {
  enabledRules?: string[];
  maximumDuration?: string;
  ruleType: PimRoleManagementPolicyRuleRuleType;
  target: ITarget;
}

export enum Rule {
  Justification = 'Justification',
  Ticketing = 'Ticketing'
}

export enum PimRoleManagementPolicyRuleRuleType {
  RoleManagementPolicyEnablementRule = 'RoleManagementPolicyEnablementRule',
  RoleManagementPolicyExpirationRule = 'RoleManagementPolicyExpirationRule'
}

export enum TargetCaller {
  EndUser = 'EndUser'
}

export enum TargetLevel {
  Assignment = 'Assignment'
}

interface ITarget {
  caller: TargetCaller;
  operations: string[];
  level: TargetLevel;
}
