import {
  ColumnActionsMode,
  css,
  FontClassNames,
  ResponsiveMode,
} from '@fluentui/react';
import * as React from 'react';

import { OverflowActionButton } from '../';
import { EntitlementSearchFilter } from '../../../models/ELM/EntitlementSearchFilter';
import { IGrant } from '../../../models/ELM/IGrant';
import { IListColumn } from '../../../models/IListColumn';
import { GridSizes, resizeResponsiveGrid } from '../../../shared';
import { FormatDate } from '../../../shared/FormatDateTime';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { ColumnValue } from '../../Shared/ColumnValue/ColumnValue';
import { IExpiredGrantListProps } from './';
import { getShortenedResourcesString } from '../../../shared/getResourcesString';

const myAccessListStyles = require('../../../css/myAccessList.scoped.scss');

const sizes: GridSizes = {
  'accessPackage/displayName': {
    [ResponsiveMode.small]: [150, 350],
    [ResponsiveMode.medium]: [96, 334],
    [ResponsiveMode.large]: [335, 650],
    [ResponsiveMode.xLarge]: [126, 304],
    [ResponsiveMode.xxLarge]: [241, 499],
    [ResponsiveMode.xxxLarge]: [416, 750],
  },
  'accessPackage/description': {
    [ResponsiveMode.xLarge]: [126, 291],
    [ResponsiveMode.xxLarge]: [241, 508],
    [ResponsiveMode.xxxLarge]: [416, 1584],
  },
  'accessPackage/resource':{
    [ResponsiveMode.xLarge]: [126, 291],
    [ResponsiveMode.xxLarge]: [241, 508],
    [ResponsiveMode.xxxLarge]: [416, 1584],
  },
  'schedule/startDateTime': {
    [ResponsiveMode.xLarge]: 85,
    [ResponsiveMode.xxLarge]: 85,
    [ResponsiveMode.xxxLarge]: [85, 200],
  },
  'schedule/stopDateTime': {
    [ResponsiveMode.medium]: 85,
    [ResponsiveMode.large]: 85,
    [ResponsiveMode.xLarge]: 85,
    [ResponsiveMode.xxLarge]: 85,
    [ResponsiveMode.xxxLarge]: [85, 200],
  },
};

export const getExpiredGrantListColumns = (
  responsiveMode: ResponsiveMode,
  props: IExpiredGrantListProps,
  onCtaButtonClick: (item: IGrant) => void,
  onColumnClick: (
    ev: React.MouseEvent<HTMLElement>,
    column: IListColumn<IGrant>
  ) => void
): IListColumn<IGrant>[] => {
  const { t, selectedFilterKey, isSearching, searchTerm, showAddGrantRequest } =
    props;

  const column: IListColumn<IGrant> = {
    key: 'accessPackage/displayName',
    name: t(LocaleKeys.name),
    fieldName: 'accessPackage',
    minWidth: 100,
    maxWidth: 400,
    headerClassName: FontClassNames.smallPlus,
    isSorted: true,
    isSortedDescending: false,
    onColumnClick: onColumnClick,
    isResizable: true,
    onRender: (item: IGrant) => {
      const entitlementName = item.accessPackage.displayName!;
      return (
        <ColumnValue
          searchTerm={
            selectedFilterKey === EntitlementSearchFilter.AccessPackage ||
            selectedFilterKey === EntitlementSearchFilter.All
              ? searchTerm
              : ''
          }
          columnValue={entitlementName}
          isHighlightRequired={true}
          isSearching={isSearching}
        />
      );
    },
  } as IListColumn<IGrant>;

  const columns: IListColumn<IGrant>[] = [];
  columns.push(column);

  if (responsiveMode > ResponsiveMode.large) {
    columns.push(
      {
        key: 'accessPackage/description',
        name: t(LocaleKeys.description),
        fieldName: 'accessPackage',
        headerClassName: FontClassNames.smallPlus,
        columnActionsMode: ColumnActionsMode.disabled,
        minWidth: 60,
        maxWidth: 261,
        isResizable: true,
        onRender: (item: IGrant) => (
          <ColumnValue
            searchTerm={searchTerm}
            columnValue={item.accessPackage.description!}
            isHighlightRequired={true}
            isSearching={false}
          />
        ),
      },
      {
        key: 'schedule/startDateTime',
        name: t(LocaleKeys.startsOn),
        className: 'ms-pii',
        fieldName: 'schedule',
        columnActionsMode: ColumnActionsMode.disabled,
        minWidth: 80,
        maxWidth: 160,
        headerClassName: FontClassNames.smallPlus,
        isResizable: true,
        onRender: (item: IGrant) => (
          <span className={css('ms-pii', FontClassNames.medium)}>
            {FormatDate(item.schedule.startDateTime)}
          </span>
        ),
      } as IListColumn<IGrant>
    );

    if (!props.isGuestUser) {
      columns.splice(2, 0, {
        key: 'accessPackage/resource',
        name: t(LocaleKeys.resources),
        columnActionsMode:ColumnActionsMode.disabled,
        fieldName:'resource',
        headerClassName: FontClassNames.smallPlus,
        minWidth:60,
        maxWidth:261,
        isResizable:true,
        onRender: (item:IGrant) => {
          let displayResources = getShortenedResourcesString(item.accessPackage.accessPackageResourceRoleScopes, t);
          return(
          <ColumnValue
            searchTerm={searchTerm}
            columnValue={displayResources}
            isHighlightRequired={selectedFilterKey === EntitlementSearchFilter.Resources ||
              selectedFilterKey === EntitlementSearchFilter.All}
            isSearching={isSearching}
          />
          );
        }
      } as IListColumn<IGrant>);
     }
  }

  if (responsiveMode > ResponsiveMode.small) {
    columns.push({
      key: 'schedule/stopDateTime',
      name: t(LocaleKeys.expiresOn),
      className: 'ms-pii',
      fieldName: 'expiredDateTime',
      columnActionsMode: ColumnActionsMode.disabled,
      minWidth: 80,
      maxWidth: 160,
      headerClassName: FontClassNames.smallPlus,
      isResizable: true,
      onRender: (item: IGrant) => (
        <span className={css('ms-pii', FontClassNames.medium)}>
          {FormatDate(item.expiredDateTime)}
        </span>
      ),
    });
  }

  columns.push({
    key: 'clickThroughActionButton',
    name: t(LocaleKeys.actions),
    columnActionsMode: ColumnActionsMode.disabled,
    fieldName: '',
    minWidth: 100,
    maxWidth: 100,
    isResizable: false,
    onRender: (item) => (
      <OverflowActionButton
        className={myAccessListStyles.rightActionButton}
        itemKey={item.id}
        showPrimaryAction={true}
        primaryActionAriaDescription={t(
          LocaleKeys.renewAccessPackageButtonAria,
          { packageName: item.accessPackage.displayName }
        )}
        onPrimaryPress={() => {
          onCtaButtonClick(item);
          showAddGrantRequest();
        }}
        primaryText={t(LocaleKeys.renew, { context: 'capitalize' })}
      />
    ),
  });

  return resizeResponsiveGrid(columns, sizes, responsiveMode);
};
