import { TranslationFunction } from 'i18next';
import * as React from 'react';
import { IApprovalStep } from '../../../models/ELM/IApprovalStep';
import { ISubject } from '../../../models/ELM/ISubject';
import { DecisionType } from '../../../models/RequestApprovals/DecisionType';
import {
  ApprovalStatus,
  IApproval,
} from '../../../models/RequestApprovals/IApproval';
import { FormatDateTime } from '../../../shared/FormatDateTime';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { ApprovalHistoryActivityItem } from '../../RequestApprovals/ApprovalHistoryActivityItem/ApprovalHistoryActivityItem';
import { DecisionHoverCard } from '../DecisionHoverCard/DecisionHoverCard';
import { SimpleHoverCard } from '../SimpleHoverCard/SimpleHoverCard';

export interface IDecisionActivityCardProps {
  t: TranslationFunction;
  decision: IApproval | IApprovalStep;
  cardDescription: string;
  requestExpirationDate: Date;
}

export class DecisionActivityCard extends React.Component<
  IDecisionActivityCardProps,
  {}
> {
  public render(): JSX.Element {
    const { t, decision, cardDescription, requestExpirationDate } = this.props;

    if (!decision || !decision.reviewedBy) {
      return <div />;
    }

    let reviewedBy: string[] = this._isElmSubject(decision.reviewedBy) ?
      this._getReviewedByFromElmSubject(decision.reviewedBy) :
      this._getReviewedByFromAccessReviewSubject(decision.reviewedBy);

    switch (ApprovalStatus[decision.reviewResult]) {
      case ApprovalStatus.Approve:
        return (
          <ApprovalHistoryActivityItem
            t={t}
            title={t('requestActivity.approved', {
              actorDisplayName: reviewedBy && reviewedBy[0],
            })}
            cardAdditionalDescription={cardDescription}
            hoverCardDom={
              <DecisionHoverCard
                t={t}
                decisionType={DecisionType[decision.reviewResult]}
                actorDisplayName={reviewedBy && reviewedBy[0]}
                actorEmail={reviewedBy && reviewedBy[1]}
                detail={decision.justification}
              />
            }
            dateTime={FormatDateTime(decision.reviewedDateTime)}
            iconName={'CheckMark'}
          />
        );
      case ApprovalStatus.Deny:
        return (
          <ApprovalHistoryActivityItem
            t={t}
            title={t('requestActivity.denied', {
              actorDisplayName: reviewedBy && reviewedBy[0],
            })}
            cardAdditionalDescription={cardDescription}
            hoverCardDom={
              <DecisionHoverCard
                t={t}
                decisionType={DecisionType[decision.reviewResult]}
                actorDisplayName={reviewedBy && reviewedBy[0]}
                actorEmail={reviewedBy && reviewedBy[1]}
                detail={decision.justification}
              />
            }
            dateTime={FormatDateTime(decision.reviewedDateTime)}
            iconName={'Blocked2'}
          />
        );
      case ApprovalStatus.NotReviewed:
        return (
          <ApprovalHistoryActivityItem
            t={t}
            title={t(ApprovalStatus[decision.reviewResult])}
            cardAdditionalDescription={cardDescription}
            hoverCardDom={
              <SimpleHoverCard
                localizedTitle={t(ApprovalStatus[decision.reviewResult])}
                localizedDescription={t(
                  LocaleKeys.normalPendingApprovalMessage,
                  {
                    expirationDateTime: FormatDateTime(requestExpirationDate),
                  }
                )}
              />
            }
            dateTime={`${t(LocaleKeys.due)} ${FormatDateTime(
              requestExpirationDate
            )}`}
            iconName={'Sync'}
          />
        );
      default:
        return <div />;
    }
  }

  // tslint:disable-next-line:no-any
  private _isElmSubject = (reviewedBy: any): reviewedBy is ISubject => {
    return reviewedBy && reviewedBy.email && typeof (reviewedBy.email) === 'string';
  }

  // tslint:disable-next-line:no-any
  private _getReviewedByFromAccessReviewSubject = (reviewedBy: any): string[] => {
    let actorDisplayName = reviewedBy!.displayName;
    let actorEmail =
      (reviewedBy && reviewedBy.mail) ||
      (reviewedBy && reviewedBy.userPrincipalName);

    return [actorDisplayName, actorEmail];
  }

  // tslint:disable-next-line:no-any
  private _getReviewedByFromElmSubject = (reviewedBy: any): string[] => {
    let actorDisplayName = reviewedBy.displayName;
    let actorEmail =
      (reviewedBy && reviewedBy.email) ||
      (reviewedBy && reviewedBy.principalName);

    return [actorDisplayName, actorEmail];
  }
}
