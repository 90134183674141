import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { translate } from 'react-i18next';
import { connect, Dispatch } from 'react-redux';
import { EntitlementActions } from '../../../models';
import { IGrantRequest } from '../../../models/ELM/IGrantRequest';
import { EntityType } from '../../../models/EntityType';
import { IRootEntitlementsState } from '../../../models/IEntitlementState';
import { IFilter } from '../../../models/IFilter';
import { IListColumn } from '../../../models/IListColumn';
import { LoadMode } from '../../../models/IPageData';
import { GetEntityList } from '../../../shared/GetEntityList';
import { getPageMetaDataFromEntities } from '../../../shared/getPageMetaDataFromEntities';
import { isEmptyOrUndefined } from '../../../shared/isEmptyOrUndefined';
import { GrantRequestList } from './GrantRequestList';
import {
  IConnectedGrantRequestListProps,
  IGrantRequestListActions,
  IGrantRequestListMappedProps
} from './GrantRequestList.types';

const getIsFiltering = (filter: IFilter): boolean => {
  if (!filter) {
    return false;
  }
  if (filter.daysSinceSubmitted !== -1) {
    return true;
  }
  if (filter.selectedStates && filter.selectedStates.length > 0) {
    return true;
  }
  if (filter.selectedRequestedFor && filter.selectedRequestedFor.length > 0){
    return true;
  }
  return false;
};

/**
 * Maps the external (i.e. own) props and the state to the properties of the GrantRequestList control.
 * @param state The current redux state to map to props
 * @param ownProps The external properties to map to props
 * @returns The mapped properties that along with the actions
 * becomes the props (IGrantRequestListProps) for the GrantRequestList control
 */
const mapStateToProps = (
  state: IRootEntitlementsState,
  _ownProps: IConnectedGrantRequestListProps
): IGrantRequestListMappedProps => {
  const {
    grantRequests,
    searchTerm,
    errorHasOccurred,
    isTenantWhitelisted,
    showingConfirmDialog,
    showingGrantRequestDetails,
    showingGrantRequestFilter,
    submitting
  } = state.app;

  const filter = grantRequests.filterContext!;

  const isSearching: boolean = !isEmptyOrUndefined(searchTerm);
  const isFiltering: boolean = getIsFiltering(filter);
  const entitiesList = GetEntityList(grantRequests, isSearching || isFiltering);

  const grantRequestList: IGrantRequest[] = entitiesList.filteredEntities.reduce(
    (grantRequestsList: IGrantRequest[], key: string) => {
      const item = grantRequests.entitiesById.get(key);
      if (item !== undefined) {
        grantRequestsList.push(item);
      }
      return grantRequestsList;
    },
    []
  );

  const pageMetaData = getPageMetaDataFromEntities(grantRequests);

  const loadMode = grantRequests.loadMode!;

  return {
    isSearching,
    isFiltering,
    errorHasOccurred,
    isLoading: grantRequests.isLoading,
    isRefreshing: loadMode === LoadMode.Refresh,
    isLoadingMore: loadMode === LoadMode.LoadMore,
    grantRequestList,
    filter,
    pageMetaData,
    searchTerm,
    isTenantWhitelisted,
    showingConfirmDialog: showingConfirmDialog!,
    showingGrantRequestDetails: showingGrantRequestDetails!,
    showingGrantRequestFilter: showingGrantRequestFilter!,
    isSubmitting: submitting!,
    features: state.app.features.isEnabled
  };
};

/**
 * Maps the dispatch actions to the props for the GrantRequestList control.
 * @param dispatch Dispatches actions to the List
 * @returns The mapped actions that along with the mapped properties
 * becomes the props (IGrantRequestListProps) for the GrantRequestList control
 */
const mapDispatchToProps = (
  dispatch: Dispatch<IRootEntitlementsState>
): IGrantRequestListActions => {
  const showGrantRequestDetailsAction = createAction<boolean>(
    EntitlementActions.showGrantRequestDetails,
    dispatch
  );
  const showGrantRequestFilter = createAction<boolean>(
    EntitlementActions.showGrantRequestFilter,
    dispatch
  );
  const showConfirmDialog = createAction<boolean>(
    EntitlementActions.showConfirmDialog,
    dispatch
  );
  const setSortedByColumnAction = createAction<{
    column: IListColumn<IGrantRequest>;
    entityType: string;
  }>(EntitlementActions.setSortedByColumn, dispatch);
  const getEntitiesAction = createAction<{ entityType: string }>(
    EntitlementActions.getEntities,
    dispatch
  );
  const sortEntitiesAction = createAction<{
    entityType: string;
    orderby: string;
    isAscending: boolean;
  }>(EntitlementActions.sortEntities, dispatch);
  const sortFilteredEntitiesAction = createAction<{
    entityType: string;
    orderby: string;
    isAscending: boolean;
    searchTerm: string;
    filterContext: IFilter;
  }>(EntitlementActions.sortFilteredEntities, dispatch);
  const filterEntitiesOnServerAction = createAction<{
    entityType: string;
    filterContext: IFilter | undefined;
  }>(EntitlementActions.filterEntitiesOnServer, dispatch);
  const refreshEntitiesAction = createAction<{ entityType: string }>(
    EntitlementActions.refreshEntities,
    dispatch
  );
  const searchForMoreAction = createAction<{ entityType: string }>(
    EntitlementActions.searchForMore,
    dispatch
  );
  return {
    cancelGrantRequest: createAction<Partial<IGrantRequest>>(
      EntitlementActions.updateGrantRequest,
      dispatch
    ),
    getEntities: () =>
      getEntitiesAction({
        entityType: EntityType.grantRequests
      }),
    searchForMore: () =>
      searchForMoreAction({ entityType: EntityType.grantRequests }),
    filterEntitiesOnServer: (filter: IFilter | undefined) =>
      filterEntitiesOnServerAction({
        entityType: EntityType.grantRequests,
        filterContext: filter
      }),
    showGrantRequestDetails: () => showGrantRequestDetailsAction(true),
    dismissGrantRequestDetails: () => showGrantRequestDetailsAction(false),
    showGrantRequestFilter: () => showGrantRequestFilter(true),
    dismissGrantRequestFilter: () => showGrantRequestFilter(false),
    showConfirmDialog: () => showConfirmDialog(true),
    dismissConfirmDialog: () => showConfirmDialog(false),
    setSearchContext: createAction<string>(
      EntitlementActions.setSearchContext,
      dispatch
    ),
    setSortedByColumn: (column: IListColumn<IGrantRequest>) =>
      setSortedByColumnAction({
        column: column,
        entityType: EntityType.grantRequests
      }),
    sortEntities: (orderby: string, isAscending: boolean) =>
      sortEntitiesAction({
        entityType: EntityType.grantRequests,
        orderby: orderby,
        isAscending: isAscending
      }),
    sortFilteredEntities: (
      orderby: string,
      isAscending: boolean,
      searchTerm: string,
      filterContext: IFilter
    ) =>
      sortFilteredEntitiesAction({
        entityType: EntityType.grantRequests,
        orderby: orderby,
        isAscending: isAscending,
        searchTerm: searchTerm,
        filterContext: filterContext
      }),
    refreshGrantRequests: () =>
      refreshEntitiesAction({ entityType: EntityType.grantRequests }),
    showFiltersIcon: createAction<boolean>(
      EntitlementActions.showSearchFiltersIcon,
      dispatch
    )
  };
};

/**
 * A redux connected GrantRequestList control.
 * @description Supports translation
 */
export const ConnectedGrantRequestList = connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('MyAccess')(GrantRequestList));
