import { AppPanel } from '@uifabric/portal-ux/lib/AppPanel';

import {
  CommandButton,
  css,
  PanelType,
  PrimaryButton
} from '@fluentui/react';
import { withResponsiveMode } from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import * as React from 'react';
import { IGrantRequest } from '../../../models/ELM/IGrantRequest';
import {
  isRequestStateEnded,
  isRequestStateCancellable,
  RequestState
} from '../../../models/ELM/RequestState';
import {
  isRequestStatusResubmittable,
  RequestStatus
} from '../../../models/ELM/RequestStatus';
import { RequestType } from '../../../models/ELM/RequestType';
import { getRequestorName, getTargetNameLowercaseIfNotRequestor } from '../../../shared/getUserStrings';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { Routes } from '../../../shared/Routes';
import { getSpinner } from '../../../shared/spinner';
import { RequestActivities } from '../../Shared/RequestActivities/RequestActivities';
import { RequestSummary } from '../../Shared/RequestSummary/RequestSummary';
import {
  IGrantRequestDetailsProps,
  IGrantRequestDetailsState
} from './GrantRequestDetails.types';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');

/* Represent a row in request history. */
@withResponsiveMode
export class GrantRequestDetails extends React.Component<
  IGrantRequestDetailsProps,
  IGrantRequestDetailsState
> {
  constructor(nextProps: IGrantRequestDetailsProps) {
    super(nextProps);
  }

  public componentDidMount(): void {
    const grantRequest = this.props.grantRequest!;
    if (
      grantRequest &&
      !isRequestStateEnded(RequestState[grantRequest.requestState])
    ) {
      this.props.getGrantRequest(grantRequest.id);
    }
  }

  public render(): JSX.Element {
    if (
      !this.props.grantRequest ||
      !this.props.grantRequest.accessPackageAssignment ||
      !this.props.grantRequest.accessPackageAssignment.accessPackage
    ) {
      return <div />;
    }

    return (
      <AppPanel
        isBlocking={true}
        isOpen={true}
        onDismiss={this.props.onDismiss}
        type={PanelType.smallFixedFar}
        headerText={this._getPageTitle()}
        onRenderFooterContent={this._onRenderFooterContent}
        isLightDismiss={true}
        closeButtonAriaLabel={this.props.t(LocaleKeys.cancel)}
        hasCloseButton={true}
      >
        {this._getRequestDetails()}
        <hr />
        <RequestActivities
          grantRequest={this.props.grantRequest}
          t={this.props.t}
          isLoading={this.props.isLoading}
          showApproverInfo={this.props.showApproverInfo}
        />
      </AppPanel>
    );
  }

  private _getPageTitle = (): string => {
    const { grantRequest, t } = this.props;
    switch (grantRequest && grantRequest.requestType) {
      case RequestType.UserRemove:
      case RequestType.SystemRemove:
      case RequestType.AdminRemove:
        return t(LocaleKeys.removeRequest);
      default:
        return t(LocaleKeys.accessRequest);
    }
  }

  private _getRequestDetails = (): JSX.Element => {
    const { grantRequest, t } = this.props;
    if (!grantRequest) {
      return <div />;
    }
    const grant = grantRequest.accessPackageAssignment!;
    const entitlementName =
      grant.accessPackage && grant.accessPackage.displayName;

    return (
      <div className={css(myAccessStyles.marginBottomMedium)}>
        <RequestSummary
          t={t}
          requestorName={getRequestorName(grantRequest.requestor, grantRequest.requestType, t)}
          targetName={getTargetNameLowercaseIfNotRequestor(t, grantRequest.requestor, grantRequest.accessPackageAssignment.target)}
          entitlementName={entitlementName!}
          grantRequest={grantRequest}
          showMoreGrantRequestDetails={this.props.showMoreGrantRequestDetails}
        />
      </div>
    );
  }

  private _onRenderFooterContent = (): JSX.Element => {
    const { t, submitting, grantRequest } = this.props;
    if (submitting) {
      return getSpinner(t(LocaleKeys.submitting));
    }

    const showCancelButton = isRequestStateCancellable(
      RequestState[grantRequest.requestState]
    );

    const showResubmitButton = isRequestStatusResubmittable(
      RequestStatus[grantRequest.requestStatus]
    );

    const entitlementId =
      grantRequest.accessPackageAssignment &&
      grantRequest.accessPackageAssignment.accessPackage &&
      grantRequest.accessPackageAssignment.accessPackage.id;

    return (
      <div>
        {showCancelButton ? (
          <CommandButton
            className={css(myAccessStyles.marginRightSmall)}
            onClick={this._cancelGrantRequest}
            text={t(LocaleKeys.cancelMyRequest)}
            iconProps={{ iconName: 'Cancel' }}
            disabled={submitting}
          />
        ) : null}
        {showResubmitButton ? (
          <PrimaryButton
            // tslint:disable-next-line:jsx-no-lambda
            onClick={() =>
              this.props.navigateTo(
                `${Routes.requestAccessTab}/${entitlementId}`
              )
            }
            text={t(LocaleKeys.resubmit)}
          />
        ) : null}
      </div>
    );
  }

  private _cancelGrantRequest = (): void => {
    const grantRequestToCancel: Partial<IGrantRequest> = {
      id: this.props.grantRequest!.id,
      requestStatus: RequestStatus.Canceled
    };
    this.props.cancelGrantRequest(grantRequestToCancel);
    this.props.onCancelClick();
  }
}
