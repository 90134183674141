import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { EntitlementActions } from '../../../models/EntitlementActions';
import { EntityType } from '../../../models/EntityType';
import { IRootEntitlementsState } from '../../../models/IEntitlementState';
import { IFilter } from '../../../models/IFilter';
import { VirtualMachines } from './VirtualMachines';
import {
  IConnectedVirtualMachinesProps,
  IVirtualMachinesActions,
  IVirtualMachinesMappedProps
} from './VirtualMachines.types';

/**
 * Maps the external (i.e. own) props and the state to the properties of the VirtualMachines control.
 * @param state The current redux state to map to props
 * @param ownProps The external properties to map to props
 * @returns The mapped properties that along with the actions
 * becomes the props (IVirtualMachinesProps) for the VirtualMachines control
 */
const mapStateToProps = (
  state: IRootEntitlementsState,
  _ownProps: IConnectedVirtualMachinesProps
): IVirtualMachinesMappedProps => {
  const { virtualMachines: virtualMachinesInResponse, ramErrorMessageBar } = state.app;
  const searchTerm = virtualMachinesInResponse.searchTerm;
  const subscriptionFilter = virtualMachinesInResponse.filterContext?.selectedSubscription;

  return { virtualMachinesInResponse, ramErrorMessageBar, searchTerm, subscriptionFilter };
};

/**
 * Maps the dispatch actions to the props for the VirtualMachines control.
 * @param _dispatch Dispatches actions to the List
 * @returns The mapped actions that along with the mapped properties
 * becomes the props (IVirtualMachinesProps) for the VirtualMachines control
 */
const mapDispatchToProps = (dispatch: Dispatch<IRootEntitlementsState>): IVirtualMachinesActions => {
  const setRamErrorMessageBarAction = createAction<string>(EntitlementActions.setErrorMessageBar, dispatch);
  const refreshEntitiesAction = createAction<{ entityType: string }>(EntitlementActions.refreshEntities, dispatch);
  const searchVirtualMachinesAction = (searchTerm?: string) =>
    createAction<string>(EntitlementActions.searchVirtualMachinesOnServer, dispatch)(searchTerm);
  const filterEntitiesOnServerAction = createAction<{
    entityType: string;
    filterContext: IFilter | undefined;
  }>(EntitlementActions.filterEntitiesOnServer, dispatch);
  return {
    setRamErrorMessageBar: (errorMessage?: string) => {
      setRamErrorMessageBarAction(errorMessage);
    },
    refreshEntities: () => {
      return refreshEntitiesAction({ entityType: EntityType.virtualMachines });
    },
    searchVirtualMachines: (searchTerm?: string) => {
      return searchVirtualMachinesAction(searchTerm);
    },
    filterEntitiesOnServer: (filter: IFilter | undefined) => {
      return filterEntitiesOnServerAction({
        entityType: EntityType.virtualMachines,
        filterContext: filter
      });
    },
    showSearchBox: createAction<boolean>(EntitlementActions.showSearchBox, dispatch)
  };
};

/**
 * A redux connected VirtualMachines control.
 * @description Supports translation
 */
export const ConnectedVirtualMachines = connect(
  mapStateToProps,
  mapDispatchToProps
  // tslint:disable-next-line:no-any
)(translate('MyAccess')(VirtualMachines) as any);
