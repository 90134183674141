import {
  css,
  DefaultButton,
  IconButton,
  MessageBar,
  MessageBarType,
  Modal,
  Panel,
  PanelType,
  PrimaryButton,
  Text
} from '@fluentui/react';
import React from 'react';
import { IRequestGrantPanelProps, IRequestGrantPanelState, RequestGrantState } from './RequestGrantPanel.types';
const styles = require('./RequestGrantPanel.scoped.scss');
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { TruncatedText } from '../../../components/Shared/TruncatedText/TruncatedText';
import { ConnectedGetPolicySelection } from './GetPolicySelection';
import { IGrantPolicy } from '../../../models/ELM/IGrantPolicy';
import { ConnectedGrantRequestQuestions } from './GrantRequestQuestions';
import { AnswerString } from '../../../models/ELM/AnswerString';
import { ConnectedVerifyCredentialPresentation, STATUS_POLL_INTERVAL } from './VerifyCredentialPresentation';
import { IVerifiableCredentialStatusType } from '../../../models/ELM/IVerifiableCredentialStatus';
import { Routes } from '../../../shared/Routes';
import { onRenderValidationErrors } from '../AddGrantRequest/GrantRequestShared';
import { QuestionType } from '../../../models/ELM/QuestionType';
import { isEmptyOrUndefined } from '../../../shared';
import { ConnectedConsentForm } from './ConsentForm';

export class RequestGrantPanel extends React.Component<IRequestGrantPanelProps, IRequestGrantPanelState> {
  private get isValid(): boolean {
    if (!this.props.grantRequest) {
      return false;
    }
    const policy = this.props.policy;
    switch (this.state.requestState) {
      case RequestGrantState.ResourceView: {
        return true;
      }
      case RequestGrantState.SelectPolicy: {
        return policy !== undefined;
      }
      case RequestGrantState.Questions: {
        return policy !== undefined && this.areQuestionsAnswered(policy) && this.isJustificationAndScheduleOkay(policy);
      }
      case RequestGrantState.ConsentForm: {
        return this.props.needsConsent && this.state.consented;
      }
      default: {
        return false;
      }
    }
  }
  constructor(ownProps: IRequestGrantPanelProps) {
    super(ownProps);
    this.state = {
      startTimeUnixEpoch: Date.now(),
      requestState: RequestGrantState.SelectPolicy,
      consented: false
    };
    if (ownProps.policy !== undefined && ownProps.noPolicyOptions) {
      this.onNext(RequestGrantState.SelectPolicy, ownProps.policy)();
    }
  }

  public componentDidUpdate() {
    if (
      this.state.requestState === RequestGrantState.SelectPolicy &&
      this.props.policy !== undefined &&
      this.props.noPolicyOptions
    ) {
      this.onNext(RequestGrantState.SelectPolicy, this.props.policy)();
    }
  }
  public componentWillUnmount(): void {
    if (this.state.verifiableCredentialIntervalHandle) {
      clearInterval(this.state.verifiableCredentialIntervalHandle);
    }
    this.props.clearValidationErrors();
  }
  public render(): JSX.Element {
    if (!this) {
      return <div />;
    }

    const { onDismiss } = this.props;

    return (
      <Panel
        isBlocking={true}
        isOpen={true}
        onDismiss={onDismiss}
        type={PanelType.smallFixedFar}
        onRenderHeader={() => {
          return this.renderHeader();
        }}
        onRenderFooterContent={() => {
          return this.renderFooter();
        }}
        isFooterAtBottom={true}
        isLightDismiss={true}
        hasCloseButton={false}
        styles={{ scrollableContent: css(styles.root) }}
        onRenderBody={() => {
          return this.renderBody();
        }}
      />
    );
  }

  private renderBody(): JSX.Element {
    let content: JSX.Element | null = null;
    switch (this.state.requestState) {
      case RequestGrantState.SelectPolicy: {
        content = <ConnectedGetPolicySelection getRequestPolicies={this.props.getRequestPolicies} />;
        break;
      }
      case RequestGrantState.VerifiableCredential: {
        content = <ConnectedVerifyCredentialPresentation />;
        break;
      }
      case RequestGrantState.Questions: {
        content = <ConnectedGrantRequestQuestions policy={this.props.policy} />;
        break;
      }
      case RequestGrantState.ConsentForm: {
        content = <ConnectedConsentForm onToggleConsent={this.onToggleConsent} />;
        break;
      }
    }
    return <div className={css(styles.content)}>{content}</div>;
  }

  private renderHeader(): JSX.Element {
    if (!this) {
      return <div />;
    }

    const {
      t,
      entitlementName,
      entitlementDescription,
      policiesContainVerifiableCredentials,
      validationErrors,
      onDismiss
    } = this.props;

    const showVcWarning =
      this.state.requestState === RequestGrantState.SelectPolicy && policiesContainVerifiableCredentials;

    return (
      <div className={css(styles.header)}>
        <div className={css(styles.headerTop)}>
          {this.showBackButton() ? (
            <IconButton
              className={css(styles.headerBackButton)}
              iconProps={{ iconName: 'Back' }}
              ariaLabel={t(LocaleKeys.back)}
              onClick={this.onBack}
            />
          ) : null}
          <TruncatedText>
            <h3 className={css(styles.headerTitle)}>{entitlementName}</h3>
          </TruncatedText>
          <IconButton
            className={css(styles.closeButton)}
            iconProps={{ iconName: 'Cancel' }}
            ariaLabel={t(LocaleKeys.cancel)}
            onClick={onDismiss}
          />
        </div>

        <TruncatedText>
          <Text className={css(styles.headerDescription)}>{entitlementDescription}</Text>
        </TruncatedText>
        {showVcWarning ? <MessageBar>{t(LocaleKeys.verifiableCredentialExplainer)}</MessageBar> : null}
        {this.hideValidationErrors
          ? null
          : onRenderValidationErrors(entitlementName!, validationErrors, this.onExistingGrantLinkClicked, t)}
        {this.state.requestState === RequestGrantState.Questions &&
        this.props.policy?.verifiableCredentialRequirementStatus?.['@odata.type'] ===
          IVerifiableCredentialStatusType.verified ? (
          <MessageBar messageBarType={MessageBarType.success}>{t(LocaleKeys.verifiableCredentialShared)}</MessageBar>
        ) : null}
      </div>
    );
  }

  private onToggleConsent = (_ev?: React.FormEvent<HTMLElement>, isChecked?: boolean): void => {
    this.setState({
      ...this.state,
      consented: isChecked!
    });
  };

  private nextText(): string {
    // TODO: "Next" and "Previous" ?
    const { t, needsConsent } = this.props;
    const isInSubmitStep =
      (needsConsent && this.state.requestState === RequestGrantState.ConsentForm) ||
      (!needsConsent && this.state.requestState === RequestGrantState.Questions);
    if (isInSubmitStep) {
      return t(LocaleKeys.requestAccess, { context: 'capitalize' });
    } else {
      return t(LocaleKeys.next);
    }
  }

  private renderFooter(): JSX.Element {
    if (!this) {
      return <div />;
    }
    const { t, onDismiss, submitting } = this.props;
    const leftText = this.nextText();
    const rightText = t(LocaleKeys.cancel);

    const onNext = this.onNext(this.state.requestState, this.props.policy);

    return (
      <div className={css(styles.footer)}>
        <PrimaryButton
          ariaLabel={leftText}
          text={leftText}
          onClick={onNext}
          disabled={!this.isValid || submitting}
          className={css(styles.footerButton, styles.primaryFooterButton)}
        />
        <DefaultButton
          text={rightText}
          ariaLabel={rightText}
          disabled={submitting}
          onClick={onDismiss}
          className={css(styles.footerButton, styles.secondaryFooterButton)}
        />
      </div>
    );
  }

  private get hideValidationErrors(): boolean {
    const { validationErrors, submitting } = this.props;

    return !validationErrors || validationErrors.length === 0 || submitting;
  }

  private isJustificationAndScheduleOkay(policy: IGrantPolicy): boolean {
    if (
      policy.isRequestorJustificationRequired &&
      (this.props.grantRequest?.justification === undefined || this.props.grantRequest.justification.trim() === '')
    ) {
      return false;
    }
    if (policy.isCustomAssignmentScheduleAllowed) {
      const startDateTime = this.props.grantRequest!.accessPackageAssignment?.schedule?.startDateTime;
      const endDateTime = this.props.grantRequest!.accessPackageAssignment?.schedule?.stopDateTime;
      if (startDateTime !== undefined && startDateTime !== null && endDateTime !== undefined && endDateTime !== null) {
        // both dates are defined
        let today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);
        // start date is before today
        if (startDateTime < today) {
          return false;
        }
        // end date is beyond expiration
        if (
          !isEmptyOrUndefined(policy.expirationDate) &&
          policy.expirationDate < endDateTime
        ) {
          return false;
        }
        // start date is after end date
        if (endDateTime < startDateTime) {
          return false;
        }
      } else if (
        (startDateTime !== undefined && startDateTime !== null) !== (endDateTime !== undefined && endDateTime !== null)
      ) {
        // one is defined
        return false;
      }
    }
    return true;
  }

  private areQuestionsAnswered(policy: IGrantPolicy): boolean {
    if (policy.questions.length > 0) {
      const answers = this.props.grantRequest?.answers;
      let isValid = true;
      policy.questions.forEach((qa) => {
        if (isValid === false) {
          return;
        }
        const matchAnswers = answers?.filter((a) => a.answeredQuestion.id === qa.id);
        if (matchAnswers === undefined || matchAnswers === null || matchAnswers.length === 0) {
          isValid = false;
          return;
        }
        const answer = matchAnswers[0] as AnswerString;
        if (qa.isRequired && isEmptyOrUndefined(answer.value)) {
          isValid = false;
          return;
        }
        if (
          qa.$type === QuestionType.TextInputQuestion &&
          !isEmptyOrUndefined(qa.regexPattern) &&
          !isEmptyOrUndefined(answer.value)
        ) {
          const regex = new RegExp(qa.regexPattern);
          isValid = regex.test(qa.answerValue);
          if (!isValid) {
            return;
          }
        }
      });
      return isValid;
    }
    return true;
  }
  // background interval checks on VC STATUS_POLL_INTERVAL
  private checkForVcProgression(): void {
    const credentialStatus = this.props.policy?.verifiableCredentialRequirementStatus;
    if (credentialStatus) {
      switch (credentialStatus['@odata.type']) {
        case IVerifiableCredentialStatusType.required:
        case IVerifiableCredentialStatusType.retrieved: {
          if (this.state.requestState !== RequestGrantState.VerifiableCredential) {
            let verifiableCredentialIntervalHandle = this.state.verifiableCredentialIntervalHandle;
            if (!verifiableCredentialIntervalHandle) {
              verifiableCredentialIntervalHandle = setInterval(
                this.checkForVcProgression.bind(this),
                STATUS_POLL_INTERVAL
              );
            }
            this.setState({
              ...this.state,
              requestState: RequestGrantState.VerifiableCredential,
              verifiableCredentialIntervalHandle
            });
          }
          break;
        }
        case IVerifiableCredentialStatusType.verified: {
          if (this.state.requestState !== RequestGrantState.Questions) {
            if (this.state.verifiableCredentialIntervalHandle) {
              clearInterval(this.state.verifiableCredentialIntervalHandle);
            }
            this.setState({
              ...this.state,
              requestState: RequestGrantState.Questions,
              verifiableCredentialIntervalHandle: undefined
            });
          }
          break;
        }
      }
    }
  }

  private onNext(currentStep: RequestGrantState, policy?: IGrantPolicy): () => void {
    const { needsConsent } = this.props;
    return () => {
      switch (currentStep) {
        case RequestGrantState.SelectPolicy: {
          let verifiableCredentialIntervalHandle = this.state.verifiableCredentialIntervalHandle;
          let requestState = RequestGrantState.Questions;
          if (
            policy?.verifiableCredentialRequirementStatus &&
            policy.verifiableCredentialRequirementStatus['@odata.type'] !== IVerifiableCredentialStatusType.verified
          ) {
            requestState = RequestGrantState.VerifiableCredential;
            if (!verifiableCredentialIntervalHandle) {
              verifiableCredentialIntervalHandle = setInterval(
                this.checkForVcProgression.bind(this),
                STATUS_POLL_INTERVAL
              );
            }
          }
          this.setState({
            ...this.state,
            requestState,
            verifiableCredentialIntervalHandle
          });
          break;
        }
        case RequestGrantState.VerifiableCredential: {
          if (this.state.verifiableCredentialIntervalHandle) {
            clearInterval(this.state.verifiableCredentialIntervalHandle);
          }
          this.setState({
            ...this.state,
            requestState: RequestGrantState.Questions,
            verifiableCredentialIntervalHandle: undefined
          });
          break;
        }
        case RequestGrantState.Questions: {
          if (needsConsent) {
            this.setState({
              ...this.state,
              requestState: RequestGrantState.ConsentForm,
              verifiableCredentialIntervalHandle: undefined
            });
          } else {
            // submit!
            this.props.postGrantRequest({
              newGrantRequest: {
                ...this.props.grantRequest!
              },
              entitlementName: this.props.entitlementName!
            });
            this.logVCTelemetry();
          }
          break;
        }
        case RequestGrantState.ConsentForm: {
          // submit!
          this.props.postGrantRequest({
            newGrantRequest: {
              ...this.props.grantRequest!
            },
            entitlementName: this.props.entitlementName!
          });
          this.logVCTelemetry();
        }
      }
    };
  }

  private logVCTelemetry = (): void => {
    let elapsedTime = Math.round((Date.now() - this.state.startTimeUnixEpoch) / 1000);
    // log telemetry for VCs used
    let usedVerifiableCredential =
      this.props.policy?.verifiableCredentialRequirementStatus !== undefined &&
      this.props.policy?.verifiableCredentialRequirementStatus !== null;
    this.props.recordGrantRequestDetailedEvent(elapsedTime, usedVerifiableCredential);
  };

  private showBackButton = () => {
    // show the back button if we're the first step and can show that first step
    return this.state.requestState !== RequestGrantState.SelectPolicy && !this.props.noPolicyOptions;
  };

  private onBack = () => {
    // VC auto-forwards, so all backs should just go to select policy
    if (this.state.verifiableCredentialIntervalHandle) {
      clearInterval(this.state.verifiableCredentialIntervalHandle);
    }
    this.setState({
      ...this.state,
      requestState: RequestGrantState.SelectPolicy,
      verifiableCredentialIntervalHandle: undefined
    });
  };

  private onExistingGrantLinkClicked = (guid: string): void => {
    this.props.navigateTo(`${Routes.accessIHaveTab}/${guid}`);
    this.props.onDismiss();
  };
}
