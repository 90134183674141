import { IAjaxRequest } from '@microsoft/portal-app/lib/auth';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';

import { EntitlementActions } from '../../../models/EntitlementActions';
import { EntityType } from '../../../models/EntityType';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState, IRootEntitlementsState } from '../../../models/IEntitlementState';
import { IPimPendingApprovalRequest } from '../../../models/RAM/IPimPendingApprovalRequest';
import { getAudience } from '../../../shared/AttachAudience';
import { registry } from '../myAccessRegistry';

export const getPimPendingApprovalRequestEpic: Epic<IEntitlementAction<AnyPayload>, IRootEntitlementsState> = (
  action$: ActionsObservable<IEntitlementAction<AnyPayload>>,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
  return action$
    .ofType(EntitlementActions.getPimPendingApprovalRequest)
    .switchMap((_action: IEntitlementAction<AnyPayload>) => {
      const ajaxRequest: IAjaxRequest = {
        method: 'GET',
        url: 'https://management.azure.com/providers/Microsoft.Authorization/roleAssignmentScheduleRequests?api-version=2020-10-01&%24filter=status%20eq%20%27PendingApproval%27%20and%20asTarget()',
        audience: getAudience(EntityType.cloudInfrastructure)
      };

      return ajax(ajaxRequest)
        .map((payload) => {
          const pendingApprovalList: IPimPendingApprovalRequest[] = payload.response.value.map(
            (item: { properties: { linkedRoleEligibilityScheduleId: string } }) => {
              return { linkedRoleEligibilityScheduleId: item.properties.linkedRoleEligibilityScheduleId };
            }
          );

          return {
            type: EntitlementActions.getPimPendingApprovalRequestSucceeded,
            payload: {
              pendingApprovalList
            }
          };
        })
        .catch((_error) => {
          return Observable.of();
        });
    });
};
registry.addEpic('getPimPendingApprovalRequestEpic', getPimPendingApprovalRequestEpic);

export const getPimPendingApprovalRequestSucceeded = (
  state: IEntitlementState,
  action: IEntitlementAction<AnyPayload>
): Readonly<IEntitlementState> => {
  const pendingApprovalList: IPimPendingApprovalRequest[] = action.payload.pendingApprovalList;
  if (pendingApprovalList === undefined || pendingApprovalList.length === 0) {
    return state;
  }

  return { ...state, pendingApprovalList };
};
registry.add(EntitlementActions.getPimPendingApprovalRequestSucceeded, getPimPendingApprovalRequestSucceeded);
