import { Link, TextField } from '@fluentui/react';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';

import { useTelemetryWithMetadata, useTranslation } from '../../../hooks';
import { TranslationFunctionKeys } from '../../../models/ITranslation';
import { OsTypeEnum } from '../../../models/RAM/OsTypeEnum';
import { LocaleKeys } from '../../../shared';
import { CopyableTextField } from '../../Shared/CopyableTextField/CopyableTextField';
import { SshContentProps } from './Connection.types';
import {
  KEY_PATH_CHMOD_PLACEHOLDER,
  KEY_PATH_PLACEHOLDER,
  SshConnectionOption,
  sshWindowsLearnMoreLink
} from './Connection.utils';
import { createOrderedList, installationStep, textFieldStyles } from './sshContent.utils';

const ramConnectionStyles = require('./ram.connection.scss');

export const SshContent: FunctionComponent<SshContentProps> = (props: SshContentProps) => {
  const t = useTranslation();
  const { reportCustomEventWithMetadata } = useTelemetryWithMetadata();
  const [privateKeyPath, setPrivateKeyPath] = useState<string>('');

  // Log sshContent loading to track usage
  useEffect(() => {
    reportCustomEventWithMetadata('ram/connection-tab/ssh');
    reportCustomEventWithMetadata('ram/journey/3/ssh-tab');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCopyTextField = useCallback(
    (connectionOption: SshConnectionOption) => {
      let loggingMessage = 'ram/connection/ssh';
      switch (connectionOption) {
        case SshConnectionOption.EntraId:
          loggingMessage += '/entraId';
          break;
        case SshConnectionOption.Client:
          loggingMessage += '/client';
          break;
        default:
          break;
      }
      return (copy: boolean) => {
        copy ? (loggingMessage += '/success') : (loggingMessage += '/fail');
        reportCustomEventWithMetadata(loggingMessage);
      };
    },
    [reportCustomEventWithMetadata]
  );

  const connectionCommand = useMemo(() => {
    const keyPath = !privateKeyPath ? t(LocaleKeys.ramConnectionSshPrivateKeyPath) : privateKeyPath;
    return `ssh -i ${keyPath} ${props.username}@${props.publicIpAddress ?? `${t(LocaleKeys.ramConnectionIpAddress)}`}`;
  }, [privateKeyPath, props.publicIpAddress, props.username, t]);

  const onChangePrivateKey = useCallback(
    (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      setPrivateKeyPath(newValue ?? '');
    },
    [setPrivateKeyPath]
  );

  const privateKeyPathStep = useCallback(() => {
    return (
      <>
        <span>{t(LocaleKeys.ramConnectionProvideKeyPath)}</span>
        <div className={ramConnectionStyles.dialogSection}>
          <TextField
            placeholder={KEY_PATH_PLACEHOLDER}
            value={privateKeyPath}
            readOnly={false}
            onChange={onChangePrivateKey}
            styles={textFieldStyles}
          />
        </div>
      </>
    );
  }, [onChangePrivateKey, privateKeyPath, t]);

  const connectionStep = useCallback(
    (connectionCommand: string): JSX.Element => {
      return (
        <>
          <span>{t(LocaleKeys.ramConnectionSshConnectionStep)}</span>
          <div className={ramConnectionStyles.dialogSection}>
            <CopyableTextField value={connectionCommand} onCopy={onCopyTextField(SshConnectionOption.Client)} />
          </div>
        </>
      );
    },
    [onCopyTextField, t]
  );

  const loginStep = useCallback((): JSX.Element => {
    return (
      <>
        <span>{t(LocaleKeys.ramConnectionSshLoginStep)}</span>
        <div className={ramConnectionStyles.dialogSection}>
          <CopyableTextField
            value={`az ssh vm -n "${props.virtualMachineName}" -g "${props.resourceGroupName}"`}
            onCopy={onCopyTextField(SshConnectionOption.EntraId)}
          />
        </div>
      </>
    );
  }, [onCopyTextField, props.resourceGroupName, props.virtualMachineName, t]);

  const entraIdConnectionMethodList = useCallback(() => {
    return createOrderedList([installationStep(t), loginStep()]);
  }, [loginStep, t]);

  const clientConnectionMethodList = useCallback(() => {
    return createOrderedList([
      openClientStep(t),
      readOnlyAccessStep(t),
      privateKeyPathStep(),
      connectionStep(connectionCommand)
    ]);
  }, [connectionCommand, connectionStep, privateKeyPathStep, t]);

  const windowsSshConnection = (t: TranslationFunctionKeys): JSX.Element => {
    return (
      <div className={ramConnectionStyles.dialogSection}>
        {t(LocaleKeys.ramConnectionSshWindowsDescription)}{' '}
        <Link
          href={sshWindowsLearnMoreLink}
          onClick={() => {
            reportCustomEventWithMetadata('ram/connection/ssh/windows');
          }}
          target={'_blank'}
        >
          {t(LocaleKeys.ramConnectionSshWindowsLinkMessage)}
        </Link>
      </div>
    );
  };
  const linuxSshConnection = (t: TranslationFunctionKeys): JSX.Element => {
    return (
      <>
        <div>
          <h3 className={ramConnectionStyles.connectionHeading}>{t(LocaleKeys.ramConnectionSshOption1)}</h3>
          {entraIdConnectionMethodList()}
        </div>
        <div>
          <h3 className={ramConnectionStyles.connectionHeading}>{t(LocaleKeys.ramConnectionSshOption2)}</h3>
          {clientConnectionMethodList()}
        </div>
      </>
    );
  };
  return <>{props.osType === OsTypeEnum.Windows ? windowsSshConnection(t) : linuxSshConnection(t)}</>;
};

const openClientStep = (t: TranslationFunctionKeys): JSX.Element => {
  return <span>{t(LocaleKeys.ramConnectionSshLoginStep)}</span>;
};

const readOnlyAccessStep = (t: TranslationFunctionKeys): JSX.Element => {
  return (
    <>
      <div>{t(LocaleKeys.ramConnectionSshReadOnlyAccessStep)}</div>
      <div>
        <strong>{t(LocaleKeys.ramConnectionSshNote)}</strong> {t(LocaleKeys.ramConnectionSshChmodMessage)}
      </div>
      <div className={ramConnectionStyles.dialogSection}>
        <CopyableTextField value={KEY_PATH_CHMOD_PLACEHOLDER} />
      </div>
    </>
  );
};
