import { MultipleChoiceQuestionType } from '../../shared/constants';
import { IAnswerChoice } from './AnswerChoice';
import { IEntitlementManagementLocalizedContent } from './IEntitlementManagementLocalizedContent';
import { QuestionAnswer } from './QuestionAnswer';
export class MultipleChoiceQuestion extends QuestionAnswer {
  public '@odata.type': string = MultipleChoiceQuestionType;
  constructor(
    id: string,
    attributeName: string,
    isAttribute: boolean,
    text: IEntitlementManagementLocalizedContent,
    isRequired: boolean,
    sequence: number,
    choices: IAnswerChoice[],
    showAnswerToApprover: boolean
  ) {
    super(id, attributeName, isAttribute, text, isRequired, sequence, choices, showAnswerToApprover);
  }

}
