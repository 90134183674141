import { translate } from 'react-i18next';
import { connect, Dispatch } from 'react-redux';

import { createAction } from '@microsoft/portal-app/lib/redux/createAction';

import { EntitlementActions } from '../../../models';
import { EntitlementSearchFilter } from '../../../models/ELM/EntitlementSearchFilter';
import { IGrant } from '../../../models/ELM/IGrant';
import { EntityType } from '../../../models/EntityType';
import { IRootEntitlementsState } from '../../../models/IEntitlementState';
import { IListColumn } from '../../../models/IListColumn';
import { LoadMode } from '../../../models/IPageData';
import { GetEntityList } from '../../../shared/GetEntityList';
import { getPageMetaDataFromEntities } from '../../../shared/getPageMetaDataFromEntities';
import { isEmptyOrUndefined } from '../../../shared/isEmptyOrUndefined';
import { ExpiredGrantList } from './ExpiredGrantList';
import { isInternalUser } from '../../../shared';

import {
  IConnectedExpiredGrantListProps,
  IExpiredGrantListActions,
  IExpiredGrantListMappedProps,
} from './ExpiredGrantList.types';

/**
 * Maps the external (i.e. own) props and the state to the properties of the ExpiredGrantList control.
 * @param state The current redux state to map to props
 * @param ownProps The external properties to map to props
 * @returns The mapped properties that along with the actions
 * becomes the props (IExpiredGrantListProps) for the ExpiredGrantList control
 */
const mapStateToProps = (
  state: IRootEntitlementsState,
  _ownProps: IConnectedExpiredGrantListProps
): IExpiredGrantListMappedProps => {
  const {
    showingGrantFilter,
    expiredGrants,
    searchTerm,
    errorHasOccurred,
    showingAddGrantRequest,
    isTenantWhitelisted,
    showMessageBar,
  } = state.app;

  const filter = expiredGrants.filterContext!;

  let isSearching: boolean = !isEmptyOrUndefined(searchTerm);
  let isFiltering: boolean = !isEmptyOrUndefined(filter);
  const selectedFilterKey =
    state.search.selectedFilterKey || EntitlementSearchFilter.AccessPackage;

  const entitiesList = GetEntityList(expiredGrants, isFiltering || isSearching);
  const grantHistoryList: IGrant[] = entitiesList.filteredEntities.reduce(
    (grantsList: IGrant[], key: string) => {
      const item = expiredGrants.entitiesById.get(key);
      if (item !== undefined) {
        grantsList.push(item);
      }
      return grantsList;
    },
    []
  );
  const pageMetaData = getPageMetaDataFromEntities(expiredGrants);
  const loadMode = expiredGrants.loadMode!;
  const isGuestUser = !isInternalUser();

  return {
    isFiltering,
    isSearching,
    isLoading: expiredGrants.isLoading,
    isRefreshing: loadMode === LoadMode.Refresh,
    isLoadingMore: loadMode === LoadMode.LoadMore,
    grantHistoryList,
    showingGrantFilter,
    pageMetaData,
    searchTerm,
    selectedFilterKey: selectedFilterKey,
    errorHasOccurred,
    showingAddGrantRequest: showingAddGrantRequest!,
    isTenantWhitelisted,
    showMessageBar,
    features: state.app.features.isEnabled,
    isGuestUser,
  };
};

/**
 * Maps the dispatch actions to the props for the ExpiredGrantList control.
 * @param dispatch Dispatches actions to the List
 * @returns The mapped actions that along with the mapped properties
 * becomes the props (IExpiredGrantListProps) for the ExpiredGrantList control
 */
const mapDispatchToProps = (
  dispatch: Dispatch<IRootEntitlementsState>
): IExpiredGrantListActions => {
  const showAddGrantRequest = createAction<boolean>(
    EntitlementActions.showAddGrantRequest,
    dispatch
  );
  const showGrantFilter = createAction<boolean>(
    EntitlementActions.showGrantFilter,
    dispatch
  );
  const setSortedByColumnAction = createAction<{
    column: IListColumn<IGrant>;
    entityType: string;
  }>(EntitlementActions.setSortedByColumn, dispatch);
  const getEntitiesAction = createAction<{ entityType: string }>(
    EntitlementActions.getEntities,
    dispatch
  );
  const sortEntitiesAction = createAction<{
    entityType: string;
    orderby: string;
    isAscending: boolean;
  }>(EntitlementActions.sortEntities, dispatch);
  const sortFilteredEntitiesAction = createAction<{
    entityType: string;
    orderby: string;
    isAscending: boolean;
    searchTerm: string;
  }>(EntitlementActions.sortFilteredEntities, dispatch);
  const refreshEntitiesAction = createAction<{ entityType: string }>(
    EntitlementActions.refreshEntities,
    dispatch
  );
  const searchForMoreAction = createAction<{ entityType: string }>(
    EntitlementActions.searchForMore,
    dispatch
  );

  return {
    getEntities: () =>
      getEntitiesAction({
        entityType: EntityType.expiredGrants,
      }),
    showGrantFilter: () => showGrantFilter(true),
    dismissGrantFilter: () => showGrantFilter(false),
    setSearchContext: createAction<string>(
      EntitlementActions.setSearchContext,
      dispatch
    ),
    setSortedByColumn: (column: IListColumn<IGrant>) =>
      setSortedByColumnAction({
        column: column,
        entityType: EntityType.expiredGrants,
      }),
    showAddGrantRequest: () => showAddGrantRequest(true),
    dismissAddGrantRequest: () => showAddGrantRequest(false),
    sortEntities: (orderby: string, isAscending: boolean) =>
      sortEntitiesAction({
        entityType: EntityType.expiredGrants,
        orderby: orderby,
        isAscending: isAscending,
      }),
    sortFilteredEntities: (
      orderby: string,
      isAscending: boolean,
      searchTerm: string
    ) =>
      sortFilteredEntitiesAction({
        entityType: EntityType.expiredGrants,
        orderby: orderby,
        isAscending: isAscending,
        searchTerm: searchTerm,
      }),
    refreshEntities: () =>
      refreshEntitiesAction({ entityType: EntityType.expiredGrants }),
    searchForMore: () =>
      searchForMoreAction({ entityType: EntityType.expiredGrants }),
    setMessageBarVisibility: createAction<boolean>(
      EntitlementActions.showMessageBar,
      dispatch
    ),
  };
};

/**
 * A redux connected ExpiredGrantList control.
 * @description Supports translation
 */
export const ConnectedExpiredGrantList = connect(
  mapStateToProps,
  mapDispatchToProps
  // tslint:disable-next-line:no-any
)(translate('MyAccess')(ExpiredGrantList) as any);
