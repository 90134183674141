import {
  ColumnActionsMode,
  FontClassNames,
  ResponsiveMode,
} from '@fluentui/react';
import { TranslationFunction } from 'i18next';
import * as React from 'react';

import { EntitlementSearchFilter } from '../../../models/ELM/EntitlementSearchFilter';
import { IEntitlement } from '../../../models/ELM/IEntitlement';
import { IListColumn } from '../../../models/IListColumn';
import { FeatureMap, GridSizes, resizeResponsiveGrid } from '../../../shared';
import { getShortenedResourcesString } from '../../../shared/getResourcesString';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { ColumnValue } from '../../Shared/ColumnValue/ColumnValue';
import { OverflowActionButton } from '../OverflowActionButton';

const myAccessListStyles = require('../../../css/myAccessList.scoped.scss');

const sizes: GridSizes = {
  displayName: {
    [ResponsiveMode.small]: [150, 316],
    [ResponsiveMode.medium]: [317, 476],
    [ResponsiveMode.large]: [477, 860],
    [ResponsiveMode.xLarge]: [208, 349],
    [ResponsiveMode.xxLarge]: [310, 499],
    [ResponsiveMode.xxxLarge]: [500, 750],
  },
  description: {
    [ResponsiveMode.xLarge]: [208, 411],
    [ResponsiveMode.xxLarge]: [310, 696],
    [ResponsiveMode.xxxLarge]: [500, 2000],
  },
  resource: {
    [ResponsiveMode.xLarge]: [208, 411],
    [ResponsiveMode.xxLarge]: [310, 696],
    [ResponsiveMode.xxxLarge]: [500, 2000],
  },
};

export const getEntitlementListColumns = (
  responsiveMode: ResponsiveMode,
  t: TranslationFunction,
  selectedFilterKey: string,
  isSearching: boolean,
  searchTerm: string,
  features: FeatureMap,
  isSortedDescending: boolean,
  isGuestUser: boolean,
  onCtaButtonClick: (item: IEntitlement) => void,
  onColumnClick: (
    ev: React.MouseEvent<HTMLElement>,
    column: IListColumn<IEntitlement>
  ) => void
): IListColumn<IEntitlement>[] => {
  const columns: IListColumn<IEntitlement>[] = [
    {
      key: 'displayName',
      name: t(LocaleKeys.name),
      fieldName: 'displayName',
      minWidth: 200,
      maxWidth: 200,
      headerClassName: FontClassNames.smallPlus,
      onColumnClick: onColumnClick,
      isSorted: true,
      isSortedDescending: isSortedDescending,
      isResizable: true,
      onRender: (item: IEntitlement) => {
        return (
          <ColumnValue
            searchTerm={
              selectedFilterKey === EntitlementSearchFilter.AccessPackage ||
              selectedFilterKey === EntitlementSearchFilter.All
                ? searchTerm
                : ''
            }
            columnValue={item.displayName!}
            isHighlightRequired={true}
            isSearching={isSearching}
          />
        );
      },
    },
    {
      key: 'clickThroughActionButton',
      name: t(LocaleKeys.actions),
      columnActionsMode: ColumnActionsMode.disabled,
      fieldName: '',
      minWidth: 100,
      maxWidth: 100,
      isResizable: false,
      onRender: (item) => (
        <OverflowActionButton
          className={myAccessListStyles.rightActionButton}
          primaryActionAriaDescription={t(
            LocaleKeys.requestAccessPackageButtonAria,
            { packageName: item.displayName }
          )}
          itemKey={item.id}
          showPrimaryAction={true}
          onPrimaryPress={() => onCtaButtonClick(item)}
          primaryText={t(LocaleKeys.request, { context: 'capitalize' })}
        />
      ),
    },
  ];

  if (responsiveMode > ResponsiveMode.large) {
    columns.splice(1, 0, {
      key: 'description',
      name: t(LocaleKeys.description),
      columnActionsMode: ColumnActionsMode.disabled,
      fieldName: 'description',
      headerClassName: FontClassNames.smallPlus,
      minWidth: 300,
      maxWidth: 300,
      isResizable: true,
      onRender: (item: IEntitlement) => (
        <ColumnValue
          searchTerm={searchTerm}
          columnValue={item.description!}
          isHighlightRequired={true}
          isSearching={isSearching}
        />
      ),
    } as IListColumn<IEntitlement>);

    if (!isGuestUser) {
      columns.splice(2, 0, {
        key: 'resource',
        name: t(LocaleKeys.resources),
        columnActionsMode:ColumnActionsMode.disabled,
        fieldName:'resource',
        headerClassName: FontClassNames.smallPlus,
        minWidth:100,
        maxWidth:300,
        isResizable:true,
        onRender: (item:IEntitlement) => {
          let displayResources = getShortenedResourcesString(item.accessPackageResourceRoleScopes, t);
          return(
          <ColumnValue
            searchTerm={searchTerm}
            columnValue={displayResources}
            isHighlightRequired={true}
            isSearching={isSearching}
          />
          );
        }
      } as IListColumn<IEntitlement>);
    }
  }

  return resizeResponsiveGrid(columns, sizes, responsiveMode);
};
