import { settingsManager } from './settings';

export const getDirectReportsUrl = (userId: string): string => {
  const baseUrl = settingsManager.get('msGraphResourceName')!;
  return `${baseUrl}/v1.0/users/${userId}/directReports?$select=id,displayName,mail,userPrincipalName`;
};

export const getCoworkersUrl = (userId: string): string => {
  const baseUrl = settingsManager.get('msGraphResourceName')!;
  return `${baseUrl}/v1.0/users/${userId}/people?$select=id,displayName`;
};

export const getUserPhotoUrl = (userId: string): string => {
  const baseUrl = settingsManager.get('msGraphResourceName')!;
  return `${baseUrl}/v1.0/users/${userId}/photo/$value`;
};

export const getBatchUrl = (): string => {
  const baseUrl = settingsManager.get('msGraphResourceName')!;
  return `${baseUrl}/v1.0/$batch`;
};
