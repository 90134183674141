import { ILocalizableMessage } from '@microsoft/portal-app/lib/localization/ILocalizableMessage';
import '@microsoft/portal-app/lib/styling/patterns/Dialog.scss';
import { TranslationFunction } from 'i18next';

import {
  css,
  DefaultButton,
  Dialog,
  DialogFooter,
  PrimaryButton
} from '@fluentui/react';
import { ResponsiveMode } from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import * as React from 'react';
import { asLocalizedText } from '../../../shared/asLocalizedText';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { getSpinner } from '../../../shared/spinner';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');

export interface IConfirmDialogProps {
  title: string;
  subText: string | ILocalizableMessage;
  isSubmitting: boolean;
  yesText?: string;
  noText?: string;
  submittingText?: string | ILocalizableMessage;
  t: TranslationFunction;
  responsiveMode?: ResponsiveMode;
  onYes(): void;
  onDismiss(): void;
}

export class ConfirmDialog extends React.Component<IConfirmDialogProps, {}> {
  public render(): JSX.Element {
    const {
      title,
      subText,
      isSubmitting,
      submittingText,
      t,
      responsiveMode
    } = this.props;

    return (
      <Dialog
        hidden={false}
        onDismiss={this.props.onDismiss}
        title={t(title)}
        modalProps={{
          containerClassName:
            responsiveMode! < ResponsiveMode.large
              ? myAccessStyles.smallDialog
              : ''
        }}
      >
        <div className={css(myAccessStyles.marginBottomSmall)}>
            {asLocalizedText(subText,t)}
        </div>
        <DialogFooter>
          {isSubmitting
            ? getSpinner(
              asLocalizedText(submittingText || LocaleKeys.loading, t)
            )
            : this._getButtons(t)}
        </DialogFooter>
      </Dialog>
    );
  }

  private _getButtons = (t: TranslationFunction): JSX.Element => {
    return (
      <div>
        <PrimaryButton
          onClick={this._submitRemove}
          text={t(this.props.yesText || LocaleKeys.yes)}
          className={css(myAccessStyles.marginRightSmall)}
        />
        <DefaultButton
          onClick={this.props.onDismiss}
          text={t(this.props.noText || LocaleKeys.no)}
        />
      </div>
    );
  }

  private _submitRemove = (): void => {
    this.props.onYes();
  }
}
