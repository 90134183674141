import { IAjaxRequest } from '@microsoft/portal-app/lib/auth/withAuth';
import { getResponseValue, IODataValueResponse } from '@microsoft/portal-app/lib/odata-utils';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { Action, MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';

import { IEntity } from '../../../models/ELM/IEntity';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState, IRootEntitlementsState } from '../../../models/IEntitlementState';
import { getRequestWithAudience } from '../../../shared/AttachAudience';
import { checkFeatureAccess, Feature, FeatureDefinition, features } from '../../../shared/features';
import { getMyFeaturesApiUrl } from '../../../shared/getApiUrl';
import { registry } from '../myAccessRegistry';

export const getAdminOptInFeatureEpic: Epic<IEntitlementAction<AnyPayload>, IRootEntitlementsState> = (
  action$: ActionsObservable<Action>,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
  return action$.ofType(EntitlementActions.getAdminOptInFeatures).switchMap((_action: IEntitlementAction) => {
    const ajaxRequest: IAjaxRequest = getRequestWithAudience(getMyFeaturesApiUrl());

    return (
      ajax(ajaxRequest)
        .timeout(5000) // 5000ms
        .map((payload: IODataValueResponse<readonly IEntity[]>) => {
          return {
            type: EntitlementActions.getAdminOptInFeaturesSucceeded,
            payload: {
              features: getResponseValue(payload)
            }
          };
        })
        // tslint:disable-next-line:no-any
        .catch((_error: any) =>
          Observable.of({
            type: EntitlementActions.getAdminOptInFeaturesFailed,
            payload: {
              error: _error
            }
          })
        )
    );
  });
};
registry.addEpic('getAdminOptInFeatureEpic', getAdminOptInFeatureEpic);

export const getAdminOptInFeaturesSucceeded = (
  state: IEntitlementState,
  action: IEntitlementAction<any>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  const featureMap = { ...state.features.isEnabled };

  for (const key of Object.keys(featureMap)) {
    const definition = features[key] as FeatureDefinition;
    if (definition.isAdminOptInFeature) {
      featureMap[key] = checkFeatureAccess(key as Feature, undefined, undefined, action.payload.features);
    }
  }

  return {
    ...state,
    features: {
      isLoaded: true,
      isEnabled: featureMap
    }
  };
};

registry.add(EntitlementActions.getAdminOptInFeaturesSucceeded, getAdminOptInFeaturesSucceeded);

export const getAdminOptInFeaturesFailed = (
  state: IEntitlementState,
  // tslint:disable-next-line:no-any
  action: IEntitlementAction
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  // Regardless of what error received, will fall back to the default feature settings.
  const featureMap = { ...state.features.isEnabled };

  for (const key of Object.keys(featureMap)) {
    const definition = features[key] as FeatureDefinition;
    if (definition.isAdminOptInFeature) {
      featureMap[key] = checkFeatureAccess(key as Feature);
    }
  }

  return {
    ...state,
    features: {
      isLoaded: true,
      isEnabled: featureMap
    }
  };
};
registry.add(EntitlementActions.getAdminOptInFeaturesFailed, getAdminOptInFeaturesFailed);
