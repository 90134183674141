import { EntitlementActions } from '../../../models/EntitlementActions';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState } from '../../../models/IEntitlementState';
import { registry } from '../myAccessRegistry';

export const showMessageBar = (
  state: IEntitlementState,
  action: IEntitlementAction<boolean>
): IEntitlementState => {
  return {
    ...state,
    showMessageBar: action.payload!,
  };
};

registry.add(EntitlementActions.showMessageBar, showMessageBar);
