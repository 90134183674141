// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../dev/node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../dev/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1QDF8MNt6ja4mJ_81r0G59{margin:0 auto;padding:16px 0;text-align:center}.UvuQ9lwBMQpuwUx87QVBy{font-weight:600;margin:8px auto}._3JmgjuncMYUQve1RsAym_k{margin:0 auto}\n", "",{"version":3,"sources":["webpack://./src/components/Shared/InfinityList/InfinityList.scoped.scss"],"names":[],"mappings":"AAEA,yBACE,aAAc,CACd,cAAe,CACf,iBAAkB,CACnB,uBAGC,eAAgB,CAChB,eAAgB,CACjB,yBAGC,aAAc","sourcesContent":["@import '~@microsoft/portal-app/lib/styling/mixins.scss';\n\n.container {\n  margin: 0 auto;\n  padding: 16px 0;\n  text-align: center;\n}\n\n.bottomText {\n  font-weight: 600;\n  margin: 8px auto;\n}\n\n.image {\n  margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_1QDF8MNt6ja4mJ_81r0G59",
	"bottomText": "UvuQ9lwBMQpuwUx87QVBy",
	"image": "_3JmgjuncMYUQve1RsAym_k"
};
module.exports = ___CSS_LOADER_EXPORT___;
