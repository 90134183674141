import { IAjaxRequest } from '@microsoft/portal-app/lib/auth/withAuth';
import {
  getResponseValue,
  IODataValueResponse
} from '@microsoft/portal-app/lib/odata-utils';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import * as moment from 'moment';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';
import { IGrant } from '../../../models';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import {
  IEntitlementState,
  IRootEntitlementsState
} from '../../../models/IEntitlementState';
import { getAudience } from '../../../shared/AttachAudience';
import { getPendingApprovalGrantRequestsCountApiUrl } from '../../../shared/getApiUrl';
import { registry } from '../myAccessRegistry';
import { getResponseError } from '../../../shared/getResponseValue'
import { OverviewErrorEventName } from '../../../models/IOverviewData';

export const getOverviewPendingApprovalEpic: Epic<
  IEntitlementAction<AnyPayload>,
  IRootEntitlementsState
> = (
  action$: ActionsObservable<IEntitlementAction>,
  store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
    return action$
      .ofType(EntitlementActions.getOverviewApproval)
      .switchMap((action: IEntitlementAction) => {

        const ajaxRequest: IAjaxRequest = {
          url: getPendingApprovalGrantRequestsCountApiUrl(),
          audience: getAudience(),
          method: 'GET'
        };

        return (
          ajax(ajaxRequest)
            .timeout(10000) // 10000ms
            .map((payload: IODataValueResponse<ReadonlyArray<IGrant>>) => {
              return {
                type: EntitlementActions.getOverviewApprovalSucceeded,
                payload: {
                  count: payload.response!['@odata.count'],
                }
              };
            })
            // tslint:disable-next-line:no-any
            .catch((error: any) =>
              Observable.of({
                type: EntitlementActions.getOverviewApprovalFailed,
                payload: {
                  error: error
                }
              })
            )
        );
      });
  };
registry.addEpic('getOverviewPendingApprovalEpic', getOverviewPendingApprovalEpic);

export const getOverviewPendingApproval = (
  state: IEntitlementState
): Readonly<IEntitlementState> => {

  return {
    ...state,
    overviewPendingApprovalData: {
      isLoading: true,
      isSucceeded: false
    }
  };
};
registry.add(EntitlementActions.getOverviewApproval, getOverviewPendingApproval);

export const getOverviewPendingApprovalSucceeded = (
  state: IEntitlementState,
  action: IEntitlementAction
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  let newState = {
    ...state,
    overviewPendingApprovalData:{
      pendingActionEntityCount: action.payload.count!,
      isLoading: false,
      isSucceeded: true
    },
    errorHasOccurred: false
  };

  return newState;
};
registry.add(EntitlementActions.getOverviewApprovalSucceeded, getOverviewPendingApprovalSucceeded);

export const getOverviewPendingApprovalFailed = (
  state: IEntitlementState,
  // tslint:disable-next-line:no-any
  action: IEntitlementAction
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  return {
    ...state,
    overviewPendingApprovalData:{
      isLoading: false,
      isSucceeded: false,
      errorEventName: OverviewErrorEventName.pendingApprovalError,
      error: getResponseError(action.payload.error)
    }
  };
};
registry.add(EntitlementActions.getOverviewApprovalFailed, getOverviewPendingApprovalFailed);
