import { IGrantRequestTargetTypeActionPayload } from '../../../models';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState } from '../../../models/IEntitlementState';
import { registry } from '../myAccessRegistry';

export const setGrantRequestTargetType = (
  state: IEntitlementState,
  action: IEntitlementAction<IGrantRequestTargetTypeActionPayload>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  return {
    ...state,
    grantRequestTarget: {
      ...state.grantRequestTarget,
      targetType: action?.payload?.type
    }
  };
};
registry.add(EntitlementActions.setGrantRequestTargetType, setGrantRequestTargetType);
