import { AppPanel } from '@uifabric/portal-ux/lib/AppPanel';

import {
  css,
  FontClassNames,
  PanelType
} from '@fluentui/react';
import * as React from 'react';
import { AnswerString } from '../../../models/ELM/AnswerString';
import { getSpinner } from '../../../shared';
import { FormatDate, FormatDateTime } from '../../../shared/FormatDateTime';
import { getRequestorName, getSubjectEmailOrUpn, getTargetName } from '../../../shared/getUserStrings';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { LabelValuePair } from '../LabelValuePair/LabelValuePair';
import { RoleScopesCard } from '../RoleScopesCard/RoleScopesCard';
import { IGrantRequestMoreDetailsProps, IGrantRequestMoreDetailsState } from './GrantRequestMoreDetails.types';
import { getLocalizedText } from '../../../models/ELM/IEntitlementManagementLocalizedContent';

const cardStyles = require('../../../css/card.scoped.scss');

export class GrantRequestMoreDetails extends React.Component<
  IGrantRequestMoreDetailsProps,
  IGrantRequestMoreDetailsState
> {
  constructor(nextProps: IGrantRequestMoreDetailsProps) {
    super(nextProps);
  }

  public componentDidMount(): void {
    if (this.props.fetchEntityType) {
      const grantRequest = this.props.mappedGrantRequest;
      const grant = grantRequest && grantRequest.accessPackageAssignment;
      const entitlement = grant && grant.accessPackage;
      const roleScopes = entitlement && entitlement.accessPackageResourceRoleScopes;
      if (!roleScopes) {
        this.props.getGrantRequest(grantRequest.id, this.props.fetchEntityType);
      }
    }
  }

  public render(): JSX.Element {
    const { t } = this.props;
    return (
      <AppPanel
        isBlocking={true}
        isOpen={true}
        onDismiss={this.props.onDismiss}
        type={PanelType.smallFixedFar}
        headerText={t(LocaleKeys.details)}
        isLightDismiss={true}
        closeButtonAriaLabel={t(LocaleKeys.cancel)}
        hasCloseButton={true}
      >
        {this._onRenderBody()}
      </AppPanel>
    );
  }

  private _onRenderBody = (): JSX.Element => {
    const { t, mappedGrantRequest } = this.props;
    const grant = mappedGrantRequest!.accessPackageAssignment;
    const entitlement = mappedGrantRequest!.accessPackage!;

    if (!mappedGrantRequest || !grant || !entitlement) {
      return <div />;
    }
    const roleScopes =
      entitlement && entitlement.accessPackageResourceRoleScopes;
    const schedule = grant.schedule!;

    const startDateValue = FormatDate(schedule && schedule.startDateTime);

    const stopDateValue = FormatDate(schedule && schedule.stopDateTime);

    const isRoleScopesVisible =
      grant.accessPackage && grant.accessPackage.isRoleScopesVisible!;

    const answersView: JSX.Element[] = [];
    if (mappedGrantRequest!.answers && mappedGrantRequest!.answers.length > 0) {
      mappedGrantRequest!.answers.forEach((answer: AnswerString) => {
        let value = answer.displayValue ? answer.displayValue : answer.value ? answer.value : '';

        answersView.push(
          <div className={css(cardStyles.detailsCard)}>
            <LabelValuePair
              label={getLocalizedText(answer.answeredQuestion.text)}
              value={value}
              t={t}
            />
          </div>
        );
      });
    }

    return (
      <div>
        <div className={css(cardStyles.detailsCard, 'ms-pii')}>
          <LabelValuePair
            label={LocaleKeys.requestedBy}
            value={getRequestorName(mappedGrantRequest.requestor, mappedGrantRequest.requestType, t)}
            t={t}
          />
          <div>
            {getSubjectEmailOrUpn(mappedGrantRequest.requestor)}
          </div>
        </div>
        <div className={css(cardStyles.detailsCard, 'ms-pii')}>
          <LabelValuePair
            label={LocaleKeys.requestedFor}
            value={getTargetName(t, grant.target)}
            t={t}
          />
          <div>
            {getSubjectEmailOrUpn(grant.target)}
          </div>
        </div>
        <div className={css(cardStyles.detailsCard)}>
          <LabelValuePair
            label={LocaleKeys.requestSubmitted}
            value={FormatDateTime(mappedGrantRequest.createdDateTime)}
            t={t}
          />
        </div>
        <div className={css(cardStyles.detailsCard)}>
          <LabelValuePair
            label={LocaleKeys.requestAccessLabel}
            value={entitlement.displayName || ''}
            t={t}
          />
          <div className={css(FontClassNames.small, 'ms-pii')}>
            {entitlement.description}
          </div>
        </div>
        {answersView.length > 0 ? answersView : null}
        {mappedGrantRequest.justification ? (
          <div className={css(cardStyles.detailsCard)}>
            <LabelValuePair
              label={LocaleKeys.businessJustification}
              value={mappedGrantRequest.justification}
              t={t}
            />
          </div>
        ) : null}

        {startDateValue || stopDateValue ? (
          <div className={css(cardStyles.detailsCard)}>
            <LabelValuePair
              label={LocaleKeys.accessDates}
              value={
                startDateValue +
                ' - ' +
                (stopDateValue ? stopDateValue : t(LocaleKeys.noEndDate))
              }
              t={t}
            />
          </div>
        ) : null}
        {this.props.isLoading ? getSpinner(t(LocaleKeys.loading)) : null}
        {roleScopes ? (
          <div className={css(cardStyles.detailsCard)}>
            <RoleScopesCard
              accessPackageResourceRoleScopes={roleScopes!}
              t={t}
              canShowResources={isRoleScopesVisible!}
              canOpenResources={
                roleScopes && new Map(roleScopes.map(rs => [rs.id, false]))
              }
              errorMessage={
                isRoleScopesVisible
                  ? LocaleKeys.permissionsNotVisibleMessage
                  : null
              }
              isFlexRow={false}
            />
          </div>
        ) : null}
      </div>
    );
  }
}
