const GUEST_USERNAME_REGEX = /^(.+?)(?:_([^_]*?))?\d*#EXT#@/;
const TENANT_DOMAIN_REGEX = /^\@([a-zA-Z0-9\-]+\.[a-zA-Z0-9\-\.]+)(\/.*)*/;

/**
 * Copied from ReactAuth lib
 * https://msazure.visualstudio.com/One/_git/AD-IAM-Lib-ReactAuth?path=%2Fsrc%2Fhelpers%2Fuser.tsx
 */
export function getNormalUsername(username?: string): string | undefined {
  const match = username?.match(GUEST_USERNAME_REGEX);

  if (match == null) {
    return username;
  }

  const [, alias, domain] = match;
  const homeUsername = !domain ? alias : `${alias}@${domain}`;

  return homeUsername;
}

/**
 * Extract the value of the query param which matches any of the provided param names, case-insensitively.
 */
export function getQueryParam(url: URL, paramNames: readonly string[]): string | undefined {
  const lowercaseParamNameSet = new Set(paramNames.map((pn) => pn.toLowerCase()));

  // Search for the query param name case insensitively, but keep the casing of the value, if found
  let value: string | undefined = undefined;
  url.searchParams.forEach((paramValue, paramName) => {
    if (lowercaseParamNameSet.has(paramName.toLowerCase())) {
      value = value ?? paramValue;
    }
  });

  return value;
}

/**
 * Deletes query params from the provided URL, case-insensitively.
 */
export function deleteQueryParams(url: URL, paramNamesToDelete: readonly string[]): URL {
  const lowercaseParamNameSet = new Set(paramNamesToDelete.map((pn) => pn.toLowerCase()));
  const newUrl = new URL(url.toString());

  url.searchParams.forEach((_, paramName) => {
    if (lowercaseParamNameSet.has(paramName.toLowerCase())) {
      newUrl.searchParams.delete(paramName);
    }
  });

  return newUrl;
}

export interface IRemovedTenantDomain {
  /**
   * Tenant domain extracted from the path.
   */
  tenantDomain: string | undefined;

  /**
   * URL without the tenant domain in its path.
   */
  url: URL;
}
export function removeTenantDomainFromPath(url: URL): IRemovedTenantDomain {
  url = new URL(url.toString());
  const match = url.pathname.match(TENANT_DOMAIN_REGEX);

  const tenantDomain = match?.[1] ?? undefined;
  const remainingPath = match?.[2];

  if (match) {
    url.pathname = remainingPath ?? '/';
  }

  return {
    tenantDomain,
    url
  };
}
