import { IAjaxRequest } from '@microsoft/portal-app/lib/auth/withAuth';
import { IODataValueResponse } from '@microsoft/portal-app/lib/odata-utils';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { EntityType } from '../../../models/EntityType';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState, IRootEntitlementsState } from '../../../models/IEntitlementState';
import { IAccessReviewsFeatureFlags } from '../../../models/AccessReviews/IAccessReviewsFeatureFlags';
import { getRequestWithAudience } from '../../../shared/AttachAudience';
import { registry } from '../myAccessRegistry';
import { getEmergencyRaeStatusUrl } from '../../../shared/getAccessReviewsApiUrl';
import { AccessReviewPartnerGuid } from '../../../models/AccessReviews/AccessReviewType';
export const getAccessReviewsFeatureFlagsEpic: Epic<IEntitlementAction<AnyPayload>, IRootEntitlementsState> = (
  action$: ActionsObservable<IEntitlementAction>,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
  return action$.ofType(EntitlementActions.getAccessReviewsFeatureFlags).switchMap((_action: IEntitlementAction) => {
    const ajaxRequest: IAjaxRequest = getRequestWithAudience(
      getEmergencyRaeStatusUrl(AccessReviewPartnerGuid.Governance),
      EntityType.accessReviews
    );

    return ajax(ajaxRequest)
      .map((payload: IODataValueResponse<IAccessReviewsFeatureFlags>) => {
        return {
          type: EntitlementActions.getAccessReviewsFeatureFlagsSucceeded,
          payload: {
            sqlOutage: payload.response!['sqlOutage'],
            emergencyRAE: payload.response!['emergencyRAE']
          }
        } as IEntitlementAction<IAccessReviewsFeatureFlags>;
      })
      .catch((_error: any) =>
        Observable.of({
          type: EntitlementActions.getAccessReviewsFeatureFlagsFailed
        })
      );
  });
};
registry.addEpic('getAccessReviewsFeatureFlagsEpic', getAccessReviewsFeatureFlagsEpic);

export const getAccessReviewsFeatureFlags = (state: IEntitlementState): Readonly<IEntitlementState> => {
  return {
    ...state,
    accessReviewsFeatureFlags: {
      sqlOutage: false,
      emergencyRAE: ''
    }
  };
};
registry.add(EntitlementActions.getAccessReviewsFeatureFlags, getAccessReviewsFeatureFlags);

export const getAccessReviewsFeatureFlagsSucceeded = (
  state: IEntitlementState,
  action: IEntitlementAction<IAccessReviewsFeatureFlags>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  return {
    ...state,
    accessReviewsFeatureFlags: {
      sqlOutage: action.payload.sqlOutage!,
      emergencyRAE: action.payload.emergencyRAE!
    }
  };
};

registry.add(EntitlementActions.getAccessReviewsFeatureFlagsSucceeded, getAccessReviewsFeatureFlagsSucceeded);

export const getAccessReviewsFeatureFlagsFailed = (
  state: IEntitlementState,
  // tslint:disable-next-line:no-any
  action: IEntitlementAction
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  return {
    ...state,
    accessReviewsFeatureFlags: {
      sqlOutage: false,
      emergencyRAE: ''
    }
  };
};
registry.add(EntitlementActions.getAccessReviewsFeatureFlagsFailed, getAccessReviewsFeatureFlagsFailed);
