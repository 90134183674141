import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { translate } from 'react-i18next';
import { connect, Dispatch } from 'react-redux';
import { EntitlementActions } from '../../../models';
import { EntityType } from '../../../models/EntityType';
import { IRootEntitlementsState } from '../../../models/IEntitlementState';
import { IFilter } from '../../../models/IFilter';
import { getPageMetaDataFromEntities } from '../../../shared/getPageMetaDataFromEntities';
import { GrantRequestFilter } from './GrantRequestFilter';
import {
  IConnectedGrantRequestFilterProps,
  IGrantRequestFilterActions,
  IGrantRequestFilterMappedProps
} from './GrantRequestFilter.types';

/**
 * Maps the external (i.e. own) props and the state to the properties of the GrantRequestFilter control.
 * @param state The current redux state to map to props
 * @param ownProps The external properties to map to props
 * @returns The mapped properties that along with the actions
 * becomes the props (IGrantRequestFilterProps) for the GrantRequestFilter control
 */
const mapStateToProps = (
  state: IRootEntitlementsState,
  ownProps: IConnectedGrantRequestFilterProps
): IGrantRequestFilterMappedProps => {
  const hidden = !state.app.showingGrantRequestFilter;
  const { grantRequests } = state.app;
  const filter = grantRequests.filterContext;

  const selectedStates = filter && filter.selectedStates;
  const selectedRequestedFor = filter && filter.selectedRequestedFor;

  const pageMetaData = getPageMetaDataFromEntities(grantRequests);

  return {
    ...ownProps,
    hidden,
    selectedStates,
    pageMetaData,
    selectedRequestedFor,
    features: state.app.features.isEnabled,
  };
};

/**
 * Maps the dispatch actions to the props for the GrantRequestFilter control.
 * @param dispatch Dispatches actions to the store
 * @returns The mapped actions that along with the mapped properties
 * becomes the props (IGrantRequestFilterProps) for the GrantRequestFilter control
 */
const mapDispatchToProps = (
  dispatch: Dispatch<IRootEntitlementsState>
): IGrantRequestFilterActions => {
  const filterEntitiesAction = createAction<{
    entityType: string;
    filter: IFilter | undefined;
  }>(EntitlementActions.filterEntities, dispatch);
  const filterEntitiesOnServerAction = createAction<{
    entityType: string;
    filterContext: IFilter | undefined;
  }>(EntitlementActions.filterEntitiesOnServer, dispatch);
  return {
    filterEntities: (filter: IFilter | undefined) =>
      filterEntitiesAction({
        entityType: EntityType.grantRequests,
        filter: filter
      }),
    filterEntitiesOnServer: (filter: IFilter | undefined) =>
      filterEntitiesOnServerAction({
        entityType: EntityType.grantRequests,
        filterContext: filter
      })
  };
};

/**
 * A redux connected GrantRequestFilter control.
 * @description Supports translation
 */
export const ConnectedGrantRequestFilter = connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('MyAccess')(GrantRequestFilter));
