
import {
  IWithResponsiveModeState,
  withResponsiveMode
} from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import * as React from 'react';
import { InjectedTranslateProps, TranslationFunction } from 'react-i18next';
import { IGrantRequest } from '../../../models';
import { ApprovalStatus } from '../../../models/RequestApprovals/IApproval';
import * as ApprovalHelper from '../../../shared/approvalPersonaHelper';
import { FormatDateTime } from '../../../shared/FormatDateTime';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { DecisionActivityCard } from '../../Shared/DecisionActivityCard/DecisionActivityCard';
import { SimpleHoverCard } from '../../Shared/SimpleHoverCard/SimpleHoverCard';
import {
   GrantRequestApprovalHistoryActivityItem
  } from '../GrantRequestApprovalHistoryActivityItem/GrantRequestApprovalHistoryActivityItem';

export interface IGrantRequestEscalationApprovalHistoryProps
  extends IWithResponsiveModeState,
    InjectedTranslateProps {
  grantRequest: IGrantRequest | undefined;
  t: TranslationFunction;
  cardAdditionalDescription?: string;
}

@withResponsiveMode
export class GrantRequestEscalationApprovalHistory extends React.Component<
  IGrantRequestEscalationApprovalHistoryProps
> {
  constructor(nextProps: IGrantRequestEscalationApprovalHistoryProps) {
    super(nextProps);
  }

  public render(): JSX.Element {
    const { grantRequest, t } = this.props;
    if (!grantRequest) {
      return <div />;
    }

    if (!ApprovalHelper.isGrantRequestEscalated(grantRequest)) {
      return <div />;
    }

    // if decision made
    if (grantRequest.approval.steps && grantRequest.approval.steps.length > 0) {
      const decision = grantRequest.approval.steps[0];
      if (ApprovalStatus[decision.reviewResult] !== ApprovalStatus.NotReviewed) {
        return (
          <DecisionActivityCard
            t={t}
            decision={decision}
            requestExpirationDate={grantRequest && ApprovalHelper.getDueDate(grantRequest)}
            cardDescription={''}
          />
        );
      }
    }

    // if pending and original
    if (ApprovalHelper.isOriginalGrantRequestApprover(grantRequest)) {
      return this._getOriginalApproverHistory(grantRequest);
    }

    // if pending and escalated
    if (ApprovalHelper.isAlternateGrantRequestApprover(grantRequest)) {
      return this._getEscalationApproverHistory(grantRequest);
    }
    return <div />;
  }

  private _getOriginalApproverHistory(grantRequest: IGrantRequest): JSX.Element {
    const { t, cardAdditionalDescription } = this.props;
    const escalatedDate = ApprovalHelper.getGrantRequestEscalatedDate(grantRequest);

    if (ApprovalHelper.pastDue(escalatedDate)) {
      return (
        <GrantRequestApprovalHistoryActivityItem
          t={t}
          title={t(LocaleKeys.pendingApproval, { context: 'capitalize' })}
          cardAdditionalDescription={cardAdditionalDescription}
          hoverCardDom={
            <SimpleHoverCard
              localizedTitle={t(LocaleKeys.pendingApproval, {
                context: 'capitalize'
              })}
              localizedDescription={t(LocaleKeys.escalationMessageAfterDue, {
                expirationDate: FormatDateTime(ApprovalHelper.getDueDate(grantRequest))
              })}
            />
          }
          dateTime={`${t(LocaleKeys.due)} ${FormatDateTime(escalatedDate)}`}
          iconName={'Sync'}
        />
      );
    } else {
      return (
        <GrantRequestApprovalHistoryActivityItem
          t={t}
          title={t(LocaleKeys.pendingApproval, { context: 'capitalize' })}
          cardAdditionalDescription={cardAdditionalDescription}
          hoverCardDom={
            <SimpleHoverCard
              localizedTitle={t(LocaleKeys.pendingApproval, {
                context: 'capitalize'
              })}
              localizedDescription={t(LocaleKeys.escalationMessageBeforeDue, {
                dueDate: FormatDateTime(escalatedDate)
              })}
            />
          }
          dateTime={`${t(LocaleKeys.due)} ${FormatDateTime(escalatedDate)}`}
          iconName={'Sync'}
        />
      );
    }
  }

  private _getEscalationApproverHistory(grantRequest: IGrantRequest): JSX.Element {
    const { t, cardAdditionalDescription } = this.props;
    let dueDate = FormatDateTime(ApprovalHelper.getDueDate(grantRequest));
    return (
      <GrantRequestApprovalHistoryActivityItem
        t={t}
        title={t(LocaleKeys.pendingApproval, { context: 'capitalize' })}
        cardAdditionalDescription={cardAdditionalDescription}
        hoverCardDom={
          <SimpleHoverCard
            localizedTitle={t(LocaleKeys.pendingApproval, {
              context: 'capitalize'
            })}
            localizedDescription={t(LocaleKeys.escalationMessageAfterDue, {
              expirationDate: dueDate
            })}
          />
        }
        dateTime={`${t(LocaleKeys.due)} ${dueDate}`}
        iconName={'Sync'}
      />
    );
  }
}
