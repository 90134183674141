import { IEntitlement } from './IEntitlement';
import { IEntitlementAction } from '../IEntitlementAction';
import { IEntity } from './IEntity';

export enum AccessPackageSuggestionReasonType {
  AccessPackageSuggestionRelatedPeopleBased = '#Microsoft.IGAELM.EC.FrontEnd.ExternalModel.AccessPackageSuggestionRelatedPeopleBased',
  AccessPackageSuggestionSelfAssignmentHistoryBased = '#Microsoft.IGAELM.EC.FrontEnd.ExternalModel.AccessPackageSuggestionSelfAssignmentHistoryBased'
};

// This enum is for telemetry only
export enum RequestSuggestionType {
  None = 0,
  RelatedPeRequestSuggestionType = 1,
  AssignmentHistory = 2,
  Both = 3
};

export interface AccessPackageSuggestionReason
{
  readonly ['@odata.type']: string;
  readonly relatedPeopleAssignmentCount?: number;
  readonly pastAssignmentCount?: number;
  readonly lastAssignmentDateTime?: number;
}


export interface IAccessPackage {
  displayName?: string;
  description? : string;
}

export interface IAccessPackageRecommendation extends IEntity {
  readonly totalScore?: number;
  readonly accessPackageSuggestionReasons?: AccessPackageSuggestionReason[];
  readonly accessPackageId?: string;
  readonly accessPackage?: IEntitlement;
  readonly ['accessPackage@odata.context']?: string;
}

export interface IGetAccessPackageRecommendationsActionPayload {
  recommendations?: IAccessPackageRecommendation[];

  loadingError?: Error;
}

export type IGetAccessPackageRecommendationsAction = IEntitlementAction<IGetAccessPackageRecommendationsActionPayload>;
