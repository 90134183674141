import { createTheme, ITheme } from '@fluentui/style-utilities';
import { IPartialExtendedTheme } from '@uifabric/portal-ux/lib/styling';

export function getOfficeTheme(): ITheme {
  let theme = {};
  const background = '#f5f3f1';
  const primary = '#6e6d6c';

  const headerBackground = background;
  const headerBackgroundSearch = background;
  const headerBrandText = primary;
  const headerTextIcons = primary;
  const headerSearchButtonBackground = background;
  const headerSearchFilters = background;
  const headerButtonsBackground = background;
  const headerButtonsBackgroundHover = '#CCC2B8';
  const headerButtonsBackgroundSearch = background;
  const headerButtonsBackgroundSearchHover = background;
  const headerImage = background;
  const headerImageStatic = background;
  const headerSearchBoxBackground = background;
  const headerSearchBoxIcon = background;
  const headerSearchPlaceholderText = primary;
  const headerSearchButtonBackgroundHover = '#CCC2B8';
  const headerSearchButtonIcon = background;
  const headerSearchFiltersHover = '#CCC2B8';
  const headerBadgeBackground = background;
  const headerBadgeText = primary;
  const headerSearchIcon = background;

  theme = {
    themePrimary : primary,
    headerBackground,
    headerSearchButtonBackground,
    headerSearchFilters,
    headerTextIcons,
    headerBackgroundSearch,
    headerButtonsBackground,
    headerButtonsBackgroundHover,
    headerButtonsBackgroundSearch,
    headerButtonsBackgroundSearchHover,
    headerBrandText
  };

  const normalizedTheme = {
    palette: theme,
    semanticColors: {
      headerImage,
      headerImageStatic,
      headerBackground,
      headerBackgroundSearch,
      headerBrandText,
      headerTextIcons,
      headerSearchBoxBackground,
      headerSearchBoxIcon,
      headerSearchPlaceholderText,
      headerSearchButtonBackground,
      headerSearchButtonBackgroundHover,
      headerSearchButtonIcon,
      headerSearchFilters,
      headerSearchFiltersHover,
      headerButtonsBackground,
      headerButtonsBackgroundHover,
      headerButtonsBackgroundSearch,
      headerButtonsBackgroundSearchHover,
      headerBadgeBackground,
      headerBadgeText,
      headerSearchIcon,
      buttonBorder: background
    },
  } as IPartialExtendedTheme;
  const finalTheme = createTheme(normalizedTheme);
  return finalTheme;
}