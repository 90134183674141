import {
  getResponseValue,
  IODataValueResponse
} from '@microsoft/portal-app/lib/odata-utils';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';
import { IEntity } from '../../../models/ELM/IEntity';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { EntityType } from '../../../models/EntityType';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import {
  IEntitlementState,
  IRootEntitlementsState
} from '../../../models/IEntitlementState';
import { IEntityResult } from '../../../models/IEntityResult';
import { LoadMode } from '../../../models/IPageData';
import { getRequestWithAudience } from '../../../shared/AttachAudience';
import { getEntitiesApiUrl } from '../../../shared/getApiUrl';
import { registry } from '../myAccessRegistry';

export const refreshEntitiesEpic: Epic<
  IEntitlementAction<AnyPayload>,
  IRootEntitlementsState
> = (
  action$: ActionsObservable<IEntitlementAction<{ entityType: string, id?: string }>>,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
    return action$
      .ofType(EntitlementActions.refreshEntities)
      .switchMap((action: IEntitlementAction<{ entityType: EntityType, id?: string }>) => {
        const entityType = action.payload!.entityType;
        const id = action.payload!.id;

        const url = getEntitiesApiUrl(entityType, id);
        const ajaxRequest = getRequestWithAudience(url, entityType);
        return (
          ajax(ajaxRequest)
            .map((payload: IODataValueResponse<ReadonlyArray<IEntity>>) => {
              return {
                type: EntitlementActions.getEntitiesSucceeded,
                payload: {
                  entityType: entityType,
                  entities: getResponseValue(payload),
                  count: payload.response!['@odata.count'],
                  nextLink: payload.response!['@odata.nextLink']
                }
              } as IEntitlementAction<IEntityResult<IEntity>>;
            })
            // tslint:disable-next-line:no-any
            .catch((error: any) => {
              return Observable.of({
                type: EntitlementActions.getEntitiesFailed,
                payload: {
                  entityType: entityType,
                  errorCode: error && error.status
                }
              });
            })
        );
      });
  };
registry.addEpic('refreshEntitiesEpic', refreshEntitiesEpic);

export const refreshEntities = (
  state: IEntitlementState,
  action: IEntitlementAction<{ entityType: string }>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  const entityType = action.payload.entityType;

  return {
    ...state,
    [entityType]: {
      ...state[entityType],
      filtered: {
        entities: []
      },
      unfiltered: {
        entities: []
      },
      entitiesById: new Map(),
      filterContext: undefined,
      isLoading: true,
      loadMode: LoadMode.Refresh
    },
    searchTerm: ''
  };
};
registry.add(EntitlementActions.refreshEntities, refreshEntities);
