import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { translate } from 'react-i18next';
import { connect, Dispatch } from 'react-redux';
import { EntitlementActions } from '../../../models';
import { IGrantRequest } from '../../../models/ELM/IGrantRequest';
import { EntityType } from '../../../models/EntityType';
import { IRootEntitlementsState } from '../../../models/IEntitlementState';
import { GrantRequestMoreDetails } from './GrantRequestMoreDetails';
import {
  IConnectedGrantRequestMoreDetailsProps,
  IGrantRequestMoreDetailsActions,
  IGrantRequestMoreDetailsMappedProps,
} from './GrantRequestMoreDetails.types';

/**
 * Maps the external (i.e. own) props and the state to the properties of the GrantRequestMoreDetails control.
 * @param state The current redux state to map to props
 * @param ownProps The external properties to map to props
 * @returns The mapped properties that along with the actions
 * becomes the props (IGrantRequestMoreDetailsProps) for the GrantRequestMoreDetails control
 */
const mapStateToProps = (
  state: IRootEntitlementsState,
  ownProps: IConnectedGrantRequestMoreDetailsProps
): IGrantRequestMoreDetailsMappedProps => {
  let mappedGrantRequest: IGrantRequest | undefined;
  switch (ownProps.fetchEntityType) {
    case EntityType.completedGrantRequests:
      mappedGrantRequest = state.app.completedGrantRequests.entitiesById.get(
        ownProps.grantRequest.id
      );
      break;
    case EntityType.grantRequests:
      mappedGrantRequest = state.app.grantRequests.entitiesById.get(
        ownProps.grantRequest.id
      );
      break;
  }
  mappedGrantRequest = mappedGrantRequest || ownProps.grantRequest;

  return {
    ...ownProps,
    mappedGrantRequest,
    isLoading: state.app.isEntityDetailLoading,
  };
};

/**
 * Maps the dispatch actions to the props for the GrantRequestMoreDetails control.
 * @param dispatch Dispatches actions to the store
 * @returns The mapped actions that along with the mapped properties
 * becomes the props (IGrantRequestMoreDetailsProps) for the GrantRequestMoreDetails control
 */
const mapDispatchToProps = (
  dispatch: Dispatch<IRootEntitlementsState>
): IGrantRequestMoreDetailsActions => {
  const getEntity = createAction<{
    entityId: string;
    entityType: string;
  }>(EntitlementActions.getEntity, dispatch);
  return {
    getGrantRequest: (
      id: string,
      entityType: EntityType.grantRequests | EntityType.completedGrantRequests
    ) =>
      getEntity({
        entityId: id,
        entityType,
      }),
    cancelGrantRequest: createAction<Partial<IGrantRequest>>(
      EntitlementActions.updateGrantRequest,
      dispatch
    ),
  };
};

/**
 * A redux connected GrantRequestMoreDetails control.
 * @description Supports translation
 */
export const ConnectedGrantRequestMoreDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('MyAccess')(GrantRequestMoreDetails));
