import React, { useCallback, useMemo, useState } from 'react';

const noop = () => {};

export interface IAppContext {
  InProductFeedbackOption: {
    IPFState: {
      feedbackItemId: string | undefined;
      isIPFVisible: boolean;
    };
    onShowIPF: (feedbackItemId?: string) => void;
    onHideIPF: VoidFunction;
  };
  // feel free to add any other app wide context here going forward
}

type IPFState = IAppContext['InProductFeedbackOption']['IPFState'];

const defaultAppContextValue: IAppContext = {
  InProductFeedbackOption: {
    IPFState: {
      feedbackItemId: undefined,
      isIPFVisible: false,
    },
    onShowIPF: noop,
    onHideIPF: noop,
  },
};

export const AppContext = React.createContext<IAppContext>(defaultAppContextValue);

export const AppProvider: React.FC = ({ children }) => {
  const [IPFState, setIPFState] = useState<IPFState>(() => {
    return {
      feedbackItemId: undefined,
      isIPFVisible: false,
    };
  });

  const onShowIPF = useCallback((feedbackItemId?: string) => {
    setIPFState({
      feedbackItemId,
      isIPFVisible: true,
    });
  }, []);

  const onHideIPF = useCallback(() => {
    setIPFState({
      feedbackItemId: undefined,
      isIPFVisible: false,
    });
  }, []);

  const AppContextValue = useMemo(() => {
    return {
      InProductFeedbackOption: {
        IPFState,
        onShowIPF,
        onHideIPF,
      },
    };
  }, [IPFState, onHideIPF, onShowIPF]);

  return <AppContext.Provider value={AppContextValue}>{children}</AppContext.Provider>;
};
