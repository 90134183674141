import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { translate } from 'react-i18next';
import { connect, Dispatch } from 'react-redux';
import { EntitlementActions, IGrantRequest } from '../../../models';
import { IRootEntitlementsState } from '../../../models/IEntitlementState';
import { IDecision } from '../../../models/RequestApprovals/IDecision';
import { BulkActionDialog } from './BulkActionDialog';
import {
  IBulkActionDialogActions,
  IBulkActionDialogMappedProps,
  IConnectedBulkActionDialogProps
} from './BulkActionDialog.types';

/**
 * Maps the external (i.e. own) props and the state to the properties of the RaeRequestDetails control.
 * @param state The current redux state to map to props
 * @param ownProps The external properties to map to props
 * @returns The mapped properties that along with the actions
 * becomes the props (IRaeRequestDetailsProps) for the RaeRequestDetails control
 */
const mapStateToProps = (
  state: IRootEntitlementsState,
  ownProps: IConnectedBulkActionDialogProps
): IBulkActionDialogMappedProps => {
  const submitting = state.app.submitting;

  return {
    ...ownProps,
    submitting
  };
};

/**
 * Maps the dispatch actions to the props for the BulkActionDialog control.
 * @param dispatch Dispatches actions to the store
 * @returns The mapped actions that along with the mapped properties
 * becomes the props (IBulkActionDialogProps) for the BulkActionDialog control
 */
const mapDispatchToProps = (
  dispatch: Dispatch<IRootEntitlementsState>
): IBulkActionDialogActions => {
  return {
    postDecision: createAction<Partial<IDecision>>(
      EntitlementActions.postDecision,
      dispatch
    ),
    revokeGrantRequest: createAction<{
      newGrantRequest: Partial<IGrantRequest>;
      entitlementName: string;
    }>(EntitlementActions.addGrantRequest, dispatch),
  };
};

/**
 * A redux connected BulkActionDialog control.
 * @description Supports translation
 */
export const ConnectedBulkActionDialog = connect(
  mapStateToProps,
  mapDispatchToProps
  // tslint:disable-next-line:no-any
)(translate('MyAccess')(BulkActionDialog) as any);
