export enum GrantRequestFilterOptions {
  Target = 'target',
  CreatedBy = 'createdBy',
  Approver = 'approver',
  TargetOrRequestor = 'targetOrRequestor',
  TargetManager = 'targetManager',
  RequestForOthers = 'requestForOthers'
}

export enum GrantFilterOptions {
  Target = 'target',
  CreatedBy = 'createdBy',
}

export enum EntitlementFilterOptions {
  AllowedRequestor = 'allowedRequestor',
}
