import { EntitlementActions } from '../../../models/EntitlementActions';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState } from '../../../models/IEntitlementState';
import { registry } from '../myAccessRegistry';

export const showSearchBox = (
  state: IEntitlementState,
  action: IEntitlementAction<boolean>
): IEntitlementState => {
  if (action.payload === undefined) {
    return state;
  }
  return {
    ...state,
    searchBoxVisible: action.payload
  };
};
registry.add(EntitlementActions.showSearchBox, showSearchBox);
