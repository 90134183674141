import { connect, Dispatch } from 'react-redux';

import { IRootEntitlementsState } from '../../../models';
import { createTelemetryAction } from '../../../redux';
import { ResourceSummary } from './ResourceSummary';
import {
  IConnectedResourceSummaryProps,
  IResourceSummaryActions,
} from './ResourceSummary.types';

const mapDispatchToProps = (
  dispatch: Dispatch<IRootEntitlementsState>
): IResourceSummaryActions => ({
  recordBusinessEvent: (eventName) =>
    dispatch(createTelemetryAction(eventName)),
});

export const ConnectedResourceSummary = connect<
  {},
  IResourceSummaryActions,
  IConnectedResourceSummaryProps
>(
  null,
  mapDispatchToProps
)(ResourceSummary);
