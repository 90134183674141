import { connect, Dispatch } from 'react-redux';

import { IRootEntitlementsState } from '../../../models';
import { createTelemetryAction } from '../../../redux';
import { EntitlementCard } from './EntitlementCard';
import {
  IConnectedEntitlementCardProps,
  IEntitlementCardActions,
} from './EntitlementCard.types';

const mapDispatchToProps = (
  dispatch: Dispatch<IRootEntitlementsState>
): IEntitlementCardActions => ({
  recordBusinessEvent: (eventName) =>
    dispatch(createTelemetryAction(eventName)),
});

export const ConnectedEntitlementCard = connect<
  {},
  IEntitlementCardActions,
  IConnectedEntitlementCardProps
>(
  null,
  mapDispatchToProps
)(EntitlementCard);
