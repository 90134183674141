import { ChoiceGroup, css, IChoiceGroupOption } from '@fluentui/react';
import * as React from 'react';

import { useGetPolicies, useGrantRequestTargetType, usePartialGrantRequest, usePoliciesLoadingState, useSelector, useTranslation } from '../../../hooks';
import { IGrantRequestTargetType } from '../../../models';
import { ISubject } from '../../../models/ELM/ISubject';
import { ValidationErrorCode } from '../../../models/ELM/IValidationError';
import { LocaleKeys } from '../../../shared';
import { getErrorExists } from '../../../shared/validationErrorHelper';
import {
  ITargetTypeChoiceOption
} from './OnBehalfOfComboBox.types';
import { OnBehalfOfComboBoxDirectReports } from './OnBehalfOfComboBoxDirectReports';
const styles = require('./OnBehalfOfComboBox.scoped.scss');

export function OnBehalfOfComboBox(): JSX.Element {
  const validationErrors = useSelector((state) => state?.app?.validationErrors);
  const { myself, target } = usePoliciesLoadingState();
  const {
    grantRequestTargetType,
    setGrantRequestTargetType,
  } = useGrantRequestTargetType();
  const { partialGrantRequest, setPartialGrantRequest } = usePartialGrantRequest();
  const { getPolicies, clearValidationErrors } = useGetPolicies();

  const accessPackageAssignment = partialGrantRequest?.accessPackageAssignment;
  const requestType = partialGrantRequest?.requestType;
  const selectedTarget = accessPackageAssignment?.target;
  const selectedTargetObjectId = selectedTarget?.objectId;
  const currentAccessPackageId = accessPackageAssignment?.accessPackageId;
  const onOBOMode = grantRequestTargetType === IGrantRequestTargetType.OnBehalfOf;
  const t = useTranslation();

  const setTargetInGrantRequest = (target?: ISubject): void => {
    setPartialGrantRequest({ ...partialGrantRequest, accessPackageAssignment: { ...accessPackageAssignment, target } });
    clearValidationErrors();
  };

  const onTargetTypeChange = (
    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    option?: IChoiceGroupOption
  ): void => {
    if (option) {
      // Clear the target if the user switches from on behalf of to myself
      if (
        onOBOMode &&
        option.key === IGrantRequestTargetType.Myself
      ) {
        setTargetInGrantRequest();
      }
      else {
        clearValidationErrors();
      }
      setGrantRequestTargetType({ type: option.key as IGrantRequestTargetType });
    }
  };

  React.useEffect(() => {
    if (onOBOMode && selectedTarget && currentAccessPackageId && !target.isLoading && !target.hasError && !target.hasLoaded) {
      getPolicies({
        entityId: currentAccessPackageId,
        parameters: {
          requestType,
          subject: selectedTarget
        }
      });
    }
  }, [onOBOMode, selectedTarget, requestType, target, currentAccessPackageId, getPolicies]);

  const targetTypeOptions: ITargetTypeChoiceOption[] = [
    { key: IGrantRequestTargetType.Myself, text: t(LocaleKeys.myself), disabled: myself.alreadyHasAccess },
    { key: IGrantRequestTargetType.OnBehalfOf, text: t(LocaleKeys.someoneElse) }
  ];
  let errorMessage;
  if (target.hasError && target.alreadyHasAccess) {
    const existingOpenRequest = getErrorExists(validationErrors, ValidationErrorCode.ExistingOpenRequest)
    errorMessage = existingOpenRequest ? t(LocaleKeys.userHasOpenedRequest) : t(LocaleKeys.userAlreadyHasAccess);
  } else if (target.hasError) {
    errorMessage = t(LocaleKeys.userNotInScope);
  }
  return (
    <div className={css(styles.comboBoxWrapper)}>
      <ChoiceGroup
        options={targetTypeOptions}
        onChange={onTargetTypeChange}
        label={t(LocaleKeys.requestingFor)}
        selectedKey={grantRequestTargetType ?? IGrantRequestTargetType.Myself}
      />
      {onOBOMode && (
        <OnBehalfOfComboBoxDirectReports
          t={t}
          errorMessage={errorMessage}
          isLoadingTargetPolicies={target.isLoading}
          selectedReport={selectedTargetObjectId}
          setTargetInGrantRequest={setTargetInGrantRequest}
        />
      )}
    </div>
  );
}
