import { settingsManager } from '../shared/settings';
import {
  DEFAULT_OCV_APP_ID,
  IPFBrowserStorageOverrideKey,
  IPFFeatureArea,
} from './constants';

export function isInProductFeedbackEnabled(): boolean {
  try {
    const IPFSetting = settingsManager.getRequired('inProductFeedback');
    return IPFSetting.isInProductFeedbackEnabled;
  } catch (e) {
    return false;
  }
}

export function getIPFOverrideSetting(ipfType: keyof typeof IPFBrowserStorageOverrideKey): {isOverriden: boolean, shouldShow: boolean} {
  const result = {
    isOverriden: false,
    shouldShow: false,
  };
  try {
    const shouldShowState = localStorage.getItem(IPFBrowserStorageOverrideKey[ipfType].key);
    if (shouldShowState !== null) {
      result.isOverriden = true;
      result.shouldShow = JSON.parse(shouldShowState);
    }
  } catch {
    // do nothing
  }
  return result;
}

export function getBasedFeedbackMetadata(): {
  ocvClientFeedbackLink: string;
  appId: number;
  tenantId: string;
  featureArea: IPFFeatureArea;
  surveyId: string;
  surveyName: string;
  ocpsEndpoint: string;
} | null {
  try {
    const IPFSetting = settingsManager.getRequired('inProductFeedback');
    return {
      ocvClientFeedbackLink: IPFSetting.ocvClientFeedbackLink ?? '',
      appId: IPFSetting.ocvAppId ?? DEFAULT_OCV_APP_ID,
      tenantId: '',
      featureArea: IPFFeatureArea.MyAccess,
      surveyId: '2a14fff8-25ea-4888-af31-ffe9973743d1', // generic survey id
      surveyName: '',
      ocpsEndpoint: IPFSetting.ocpsEndpoint ?? '',
    };
  } catch (e) {
    return null;
  }
}
