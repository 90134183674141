import { ComboBox, css, IComboBox, ResponsiveMode } from '@fluentui/react';
import { User } from '@microsoft/microsoft-graph-types';
import { useResponsiveMode } from '@uifabric/portal-ux/lib/styling/useResponsiveMode';
import * as React from 'react';

import { useGetPeople } from '../../../hooks';
import { TranslationFunctionKeys } from '../../../models';
import { ISubject } from '../../../models/ELM/ISubject';
import { isEmptyOrUndefined, LocaleKeys } from '../../../shared';
import { SELECT_ONE_KEY } from './OnBehalfOfComboBox.constants';
import { IOnBehalfOfComboBoxOption } from './OnBehalfOfComboBox.types';
import { OnBehalfOfComboBoxOption } from './OnBehalfOfComboBoxOption';

const styles = require('./OnBehalfOfComboBox.scoped.scss');

const loadingIconProps = {
  iconProps: { iconName: 'ProgressRingDots', styles: () => ({ root: css(styles.loadingIcon) }) }
};

export interface IOnBehalfOfComboBoxDirectReportsProps {
  t: TranslationFunctionKeys;
  isLoadingTargetPolicies: boolean;
  setTargetInGrantRequest: (target?: ISubject) => void;
  errorMessage?: string;
  selectedReport?: string;
}

function onRenderOption(option?: IOnBehalfOfComboBoxOption): JSX.Element {
  if (!option) {
    return <></>;
  }
  return <OnBehalfOfComboBoxOption option={option} />;
}

function convertUsertoComboBoxOption(user: User): IOnBehalfOfComboBoxOption | null {
  if (!user.id || !user.displayName) {
    return null;
  }

  return {
    key: user.id,
    text: user.displayName,
    secondaryText: user.userPrincipalName,
    title: isEmptyOrUndefined(user.userPrincipalName) ? user.displayName : user.userPrincipalName,
    imageUrl: null,
    id: user.id
  };
}

function convertUserArrayToComboBoxOptions(userList: readonly User[]): IOnBehalfOfComboBoxOption[] {
  const comboBoxOption = userList.map((user) => convertUsertoComboBoxOption(user)).filter((option) => option !== null) as IOnBehalfOfComboBoxOption[];
  return comboBoxOption;
}

export function OnBehalfOfComboBoxDirectReports(props: IOnBehalfOfComboBoxDirectReportsProps): JSX.Element {
  const responsiveMode = useResponsiveMode();
  const { t, errorMessage, isLoadingTargetPolicies, selectedReport, setTargetInGrantRequest } = props;
  const [directReportsOptions, setDirectReportsOptions] = React.useState<IOnBehalfOfComboBoxOption[]>([]);
  const { loadableDirectReports } = useGetPeople();
  const { isLoading: isLoadingDirectReports, value: directReports } = loadableDirectReports;

  const onDirectReportChange = (_event: React.FormEvent<IComboBox>, newTarget?: IOnBehalfOfComboBoxOption): void => {
    if (!newTarget || newTarget.key === SELECT_ONE_KEY) {
      return;
    }
    setTargetInGrantRequest({ displayName: newTarget.text, email: newTarget.secondaryText, objectId: newTarget.id, subjectType: "user" });
  };

  React.useEffect(() => {
    const comboBoxOptions = convertUserArrayToComboBoxOptions(directReports);
    comboBoxOptions.unshift({
      key: SELECT_ONE_KEY,
      text: t(LocaleKeys.selectOne)
    });
    setDirectReportsOptions(comboBoxOptions)
  }, [directReports, t]);


  const hasDirectReports = directReports.length > 0;
  const isLoading = isLoadingDirectReports || isLoadingTargetPolicies;
  const hasSmallScreen = responsiveMode <= ResponsiveMode.xLarge;
  return (
    <div>
      <ComboBox
        placeholder={hasDirectReports ? t(LocaleKeys.selectOne) : t(LocaleKeys.noDirectReports)}
        errorMessage={errorMessage}
        label={t(LocaleKeys.directReports)}
        selectedKey={selectedReport}
        iconButtonProps={isLoading ? loadingIconProps : undefined}
        onChange={onDirectReportChange}
        options={directReportsOptions}
        onRenderOption={onRenderOption}
        styles={{ container: css(styles.comboBox), root: css(styles.comboBoxInput) }}
        useComboBoxAsMenuWidth={hasSmallScreen}
        disabled={isLoading || !hasDirectReports}
        allowFreeform
      />
    </div>
  );
}