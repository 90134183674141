import { IPolicyRequirements, IRequestorSettings } from '../models/ELM/IGrantPolicy';
import { ManagerType, UserSetType } from '../models/ELM/IUserSets';

export function getPolicyTargetKey(accessPackageId: string, targetObjectId?: string): string {
  if (!targetObjectId) {
    return accessPackageId;
  }
  return `${accessPackageId}-${targetObjectId}`;
}

export function policiesContainsOBODirectReport(policies: readonly IPolicyRequirements[]): boolean {
  return policies?.some((policy) => requestorSettingAllowsDirectReportOBO(policy?.requestorSettings));
}

function requestorSettingAllowsDirectReportOBO(requestorSettings?: IRequestorSettings): boolean {
  return (
    requestorSettings?.enableOnBehalfRequestorsToAddAccess === true &&
    requestorSettings?.onBehalfRequestors.find(
      (requestor) =>
        requestor?.['@odata.type'] === UserSetType.targetManager &&
        requestor?.managerLevel === ManagerType.directManager
    ) !== undefined
  );
}
