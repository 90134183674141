import { DefaultButton, Label, Panel, PanelType, SearchBox } from '@fluentui/react';
import React from 'react';

import { useTranslation } from '../../../hooks';
import { LocaleKeys } from '../../../shared/LocaleKeys';

interface FilterPanelProps {
  onClose: () => void;
  onResetFilters: () => void;
  onFilterSubscription: (searchText: string) => void;
  subscriptionFilter: string;
  onChangeSubscriptionFilter: (subscription: string) => void;
  isOpen: boolean;
}

export const FilterPanel: React.FC<FilterPanelProps> = (props) => {
  const t = useTranslation();

  const onRenderFooterContent = (): JSX.Element => {
    return (
      <div>
        <DefaultButton onClick={props.onClose} styles={{ root: { marginRight: '8px' } }}>
          {t(LocaleKeys.ramFilterPanelClose)}
        </DefaultButton>
        <DefaultButton
          onClick={() => {
            props.onChangeSubscriptionFilter('');
            props.onResetFilters();
          }}
        >
          {t(LocaleKeys.ramFilterPanelResetFilters)}
        </DefaultButton>
      </div>
    );
  };

  const onRenderBodyContent = (): JSX.Element => {
    return (
      <div>
        <Label>{t(LocaleKeys.ramFilterPanelSubscriptionsLabel)}</Label>
        <SearchBox
          ariaLabel={t(LocaleKeys.ramFilterPanelSubscriptionsLabel)}
          value={props.subscriptionFilter}
          onEscape={() => {}}
          onClear={() => {
            props.onChangeSubscriptionFilter('');
            props.onResetFilters();
          }}
          onChange={(_, newValue) => props.onChangeSubscriptionFilter(newValue || '')}
          onSearch={props.onFilterSubscription}
        />
      </div>
    );
  };
  return (
    <Panel
      headerText={t(LocaleKeys.ramFilterPanelFiltersTitle)}
      isOpen={props.isOpen}
      onDismiss={props.onClose}
      closeButtonAriaLabel={t(LocaleKeys.ramFilterPanelClose)}
      onRenderFooterContent={onRenderFooterContent}
      type={PanelType.smallFixedFar}
      isFooterAtBottom={true}
      styles={{
        scrollableContent: {
          overflowY: 'false'
        }
      }}
    >
      {onRenderBodyContent()}
    </Panel>
  );
};
