import { IAjaxRequest } from '@microsoft/portal-app/lib/auth/withAuth';
import {
  getResponseValue,
  IODataValueResponse
} from '@microsoft/portal-app/lib/odata-utils';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { EntityType } from '../../../models/EntityType';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import {
  IEntitlementState,
  IRootEntitlementsState
} from '../../../models/IEntitlementState';
import { IEntityResult } from '../../../models/IEntityResult';
import { LoadMode } from '../../../models/IPageData';
import { IRaeRequest } from '../../../models/RequestApprovals/IRaeRequest';
import { getRequestWithAudience } from '../../../shared/AttachAudience';
import { getRaeRequestsApiUrl } from '../../../shared/getRaeApiUrl';
import { registry } from '../myAccessRegistry';

export const searchCompletedRaeRequestsOnServerEpic: Epic<
  IEntitlementAction<AnyPayload>,
  IRootEntitlementsState
> = (
  action$: ActionsObservable<IEntitlementAction<string>>,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
  return action$
    .ofType(EntitlementActions.searchCompletedRaeRequestsOnServer)
    .switchMap((action: IEntitlementAction<string>) => {
      let ajaxRequest: IAjaxRequest;

      let searchTerm = action.payload && action.payload.toLowerCase();

      const selectedFilterKey = _store.getState().search.selectedFilterKey;

      const completedRaeRequests = _store.getState().app.completedRaeRequests;
      const nextLink = completedRaeRequests.filtered.nextLink!;
      const orderby =
        completedRaeRequests.sortedColumn &&
        completedRaeRequests.sortedColumn.key;
      const isAscending =
        completedRaeRequests.sortedColumn &&
        !completedRaeRequests.sortedColumn.isSortedDescending;

      ajaxRequest = getRequestWithAudience(
        nextLink
          ? nextLink
          : getRaeRequestsApiUrl(
              'Completed',
              orderby,
              isAscending,
              searchTerm,
              selectedFilterKey
            ),
        EntityType.completedRaeRequests
      );

      return ajax(ajaxRequest)
        .map((payload: IODataValueResponse<ReadonlyArray<IRaeRequest>>) => {
          return {
            type: EntitlementActions.searchEntitiesOnServerSucceeded,
            payload: {
              entityType: EntityType.completedRaeRequests,
              entities: getResponseValue(payload),
              searchTerm: searchTerm,
              count: payload.response!['@odata.count'],
              nextLink: payload.response!['@odata.nextLink']
            }
          } as IEntitlementAction<IEntityResult<IRaeRequest>>;
        })
        .catch(() =>
          Observable.of({
            type: EntitlementActions.searchEntitiesOnServerFailed,
            payload: EntityType.completedRaeRequests
          })
        );
    });
};
registry.addEpic(
  'searchCompletedRaeRequestsOnServerEpic',
  searchCompletedRaeRequestsOnServerEpic
);

const searchCompletedRaeRequestsOnServer = (
  state: IEntitlementState,
  action: IEntitlementAction<string>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }
  const searchTerm = action.payload;
  return {
    ...state,
    searchTerm: searchTerm!,
    completedRaeRequests: {
      ...state.completedRaeRequests,
      filtered: {
        ...state.completedRaeRequests.filtered,
        entities: [],
        count: undefined,
        nextLink: undefined
      },
      isLoading: true,
      loadMode: LoadMode.LoadMore,
      filterContext: undefined
    }
  };
};
registry.add(
  EntitlementActions.searchCompletedRaeRequestsOnServer,
  searchCompletedRaeRequestsOnServer
);
