import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IPolicyRequirementsRequestBody } from '../../../models/ELM/IGrantPolicy';
import { IGrantRequest } from '../../../models/ELM/IGrantRequest';
import { RequestSuggestionType } from '../../../models/ELM/IAccessPackageRecommendations';
import { ValidationErrorCode } from '../../../models/ELM/IValidationError';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { IRootEntitlementsState } from '../../../models/IEntitlementState';
import { createTelemetryAction } from '../../../redux';
import { history } from '../../../redux/configureStore';
import { getPolicyTargetKey, isInternalUser } from '../../../shared';
import { telemetry } from '../../../shared/telemetry';
import { getErrorExists } from '../../../shared/validationErrorHelper';
import { RequestGrantModal } from './RequestGrantModal/RequestGrantModal';
import { RequestGrantPanel } from './RequestGrantPanel';
import {
  IConnectedRequestGrantPanelProps,
  IRequestGrantPanelActions,
  IRequestGrantPanelMappedProps
} from './RequestGrantPanel.types';

const GRANT_REQUEST_LATENCY = `${EntitlementActions.addGrantRequest}/secondsTaken`;

const mapStateToProps = (
  state: IRootEntitlementsState,
  _ownProps: IConnectedRequestGrantPanelProps
): IRequestGrantPanelMappedProps => {
  const needsConsent = !isInternalUser();
  const grantRequest = state.app.partialGrantRequest;

  const grantRequestTarget = state.app?.grantRequestTarget;
  const grantTargetType = grantRequestTarget?.targetType;

  const accessPackageAssignment = grantRequest?.accessPackageAssignment;
  const policyId = accessPackageAssignment?.assignmentPolicyId;
  const entitlementId = accessPackageAssignment?.accessPackageId;
  const grantTarget = accessPackageAssignment?.target;
  const grantTargetObjectId = grantTarget?.objectId;
  const policy = policyId ? state.app.policies.entitiesById.get(policyId) : undefined;

  let policiesContainVerifiableCredentials = false;
  let allPoliciesRequireVerifiableCredentials = false;
  let noPolicyOptions = false;
  let policiesDefined = false;
  // if we have the access package ID (we should)
  if (entitlementId) {
    const policyAssignmentKey = getPolicyTargetKey(entitlementId, grantTargetObjectId);
    const policyIds = state.app.policyAssignments.get(policyAssignmentKey);
    policiesDefined = state.app.policyAssignments.has(policyAssignmentKey);
    // if the policies for the package have been loaded
    if (policyIds) {
      noPolicyOptions = policyIds.length <= 1;
      // check each policy for a VC status, and if ANY are non-null.
      const policiesWithVerifiableCredentials = policyIds
        .map(
          (policyIdToCheck) =>
            state.app.policies.entitiesById.get(policyIdToCheck)?.verifiableCredentialRequirementStatus ?? null
        )
        .filter((vcStatus) => vcStatus && vcStatus !== null);

      policiesContainVerifiableCredentials = policiesWithVerifiableCredentials.length > 0;
      allPoliciesRequireVerifiableCredentials = policiesWithVerifiableCredentials.length === policyIds.length;
    }
  }

  const invalidRequestExistingGrant = getErrorExists(
    state.app.validationErrors,
    ValidationErrorCode.InvalidRequestExistingGrant
  );
  const existingOpenRequest = getErrorExists(state.app.validationErrors, ValidationErrorCode.ExistingOpenRequest);
  const licenseRequirementsNotMet =
    getErrorExists(state.app.validationErrors, ValidationErrorCode.NoLicense) ||
    getErrorExists(state.app.validationErrors, ValidationErrorCode.NoGovernanceSku);
  const notRequestableForMyself = invalidRequestExistingGrant || existingOpenRequest || licenseRequirementsNotMet;
  const oboAllowed = entitlementId !== undefined && state?.app?.oboEnabledPackages.get(entitlementId) === true;
  const oboChecked = entitlementId !== undefined && state?.app?.oboEnabledPackages.has(entitlementId);

  return {
    grantRequest,
    policy,
    noPolicyOptions,
    policiesContainVerifiableCredentials,
    allPoliciesRequireVerifiableCredentials,
    enableVerifiableCredential: state.app.features.isEnabled.enableVerifiableCredential,
    oboAllowed,
    oboChecked,
    policiesDefined,
    policiesLoading: state.app.policies.isLoading,
    submitting: state.app.submitting,
    validationErrors: state.app.validationErrors,
    needsConsent,
    navigateTo(url: string): void {
      history.push(url);
    },
    invalidRequestExistingGrant,
    existingOpenRequest,
    notRequestableForMyself,
    grantTarget,
    grantTargetType,
    loadableDirectReports: state.app.directReports,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<IRootEntitlementsState>): IRequestGrantPanelActions => {
  const getPolicies = createAction<{
    entityId: string;
    parameters: IPolicyRequirementsRequestBody;
  }>(EntitlementActions.getPolicies, dispatch);

  const getRequestPolicies = (grantRequest: Partial<IGrantRequest>): void => {
    if (!grantRequest.accessPackageAssignment?.accessPackageId) {
      return;
    }
    telemetry.reportCustomEvent(`grant-panel/${EntitlementActions.getPolicies}`);
    getPolicies({
      entityId: grantRequest.accessPackageAssignment?.accessPackageId,
      parameters: {
        requestType: grantRequest.requestType,
        subject: grantRequest.accessPackageAssignment?.target
      }
    });
  };

  const getHasOBOPolicies = (grantRequest: Partial<IGrantRequest>): void => {
    if (!grantRequest.accessPackageAssignment?.accessPackageId) {
      return;
    }
    getPolicies({
      entityId: grantRequest.accessPackageAssignment?.accessPackageId,
      parameters: {
        requestType: grantRequest.requestType,
        isOnBehalfRequest: true
      }
    });
  };

  const getAccessPackage= createAction<{
    entityId: string;
    parameters: IPolicyRequirementsRequestBody;
  }>(EntitlementActions.getEligibleAccessPackageDetail, dispatch);

  const getEligibleAccessPackage = (grantRequest: Partial<IGrantRequest>): void => {
    if (!grantRequest.accessPackageAssignment?.accessPackageId) {
      return;
    }
    telemetry.reportCustomEvent(`grant-panel/${EntitlementActions.getEligibleAccessPackageDetail}`);
    getAccessPackage({
      entityId: grantRequest.accessPackageAssignment?.accessPackageId,
      parameters: {
        requestType: grantRequest.requestType,
        subject: grantRequest.accessPackageAssignment?.target
      }
    });
  };

  const getHasOBOEligibleAccessPackage = (grantRequest: Partial<IGrantRequest>): void => {
    if (!grantRequest.accessPackageAssignment?.accessPackageId) {
      return;
    }
    getAccessPackage({
      entityId: grantRequest.accessPackageAssignment?.accessPackageId,
      parameters: {
        requestType: grantRequest.requestType,
        isOnBehalfRequest: true
      }
    });
  };

  const doClearValidationErrors = createAction<boolean>(EntitlementActions.clearValidationErrors, dispatch);
  return {
    recordGrantRequestDetailedEvent: (secondsElapsed: number, usedVerifiableCredentials: boolean) => {
      dispatch(
        createTelemetryAction(GRANT_REQUEST_LATENCY, {
          secondsElapsed,
          usedVerifiableCredentials
        })
      );
    },
    postGrantRequest: createAction<{
      newGrantRequest: Partial<IGrantRequest>;
      entitlementName: string;
    }>(EntitlementActions.addGrantRequest, dispatch),
    showCopyLinkNotification: createAction(EntitlementActions.showCopyNotification, dispatch),
    clearValidationErrors: () => {
      doClearValidationErrors(true);
    },
    getRequestPolicies,
    getHasOBOPolicies,
    getEligibleAccessPackage,
    getHasOBOEligibleAccessPackage
  };
};

export const ConnectedRequestGrantPanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('MyAccess')(RequestGrantPanel));

export const ConnectedRequestGrantModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('MyAccess')(RequestGrantModal));
