import { gridUnit } from '@uifabric/portal-ux/lib/styling/stylingConstants';
import { IBaseProps } from '@uifabric/utilities';
import { IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import { TranslationFunction } from 'react-i18next';

export const DEFAULT_EXPANDED_WIDTH = 928;
export const DEFAULT_COLLAPSED_WIDTH = 73 * gridUnit;
export const SEARCH_BOX_SOURCE = 'search_box';

export interface ISearchBoxProps extends IBaseProps {
  disabled?: boolean;
  isSearchBoxExpanded?: boolean;
  isFocusInSearch?: boolean;
  showFiltersIcon?: boolean;
  showFiltersMenu?: boolean;
  searchText?: string;
  searchPlaceholderText?: string;
  areSearchResultsShown?: boolean; // If search results are shown
  backButtonTitle?: string; // Tooltip for the back button
  executeSearchLabel?: string;
  customCollapsedMaxWidth?: number; // The max with when the control is collapsed
  selectedSuggestionIndex?: number;

  shouldRenderPinIcon?: boolean;
  shouldShowPinSearchIcon?: boolean; // If true, the pin icon next to the filter dropdown will show and allow user to pin searches
  pinSearchLabel?: string;
  unpinSearchLabel?: string;
  isTextAlreadyPinned?: boolean;
  removePersonaButtonAriaLabel?: string;
  selectedFilterKey?: string;
  filterItems?: IContextualMenuItem[];
  t: TranslationFunction;
  onBackClick?(): void; // Func called after the back button is clicked
  onBlurred?(): void; // Func called when the control gets blurred
  onFocused?(): void; // Func when the control gets focus
  onFilterClick?(): void; // Func called when a filter is clicked
  onChange?(value: string): void;
  onSearch?(actionSource: string): void; // Func called after a search is kicked off

  toggleSaveSearch?(searchText: string): void;

  selectFilterItem?(key: string): void;
  hideFilter?(): void;
}
