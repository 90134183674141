import { IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import { TranslationFunction } from 'react-i18next';

import { LocaleKeys } from '../../../shared/LocaleKeys';
import { IVmListProps } from './VmList.types';

export const getVmListCommands = (t: TranslationFunction, props: IVmListProps): IContextualMenuItem[] => {
  const refreshListCommand: IContextualMenuItem = {
    key: 'refresh',
    role: 'button',
    name: t(LocaleKeys.refreshList),
    iconProps: { iconName: 'refresh' },
    disabled: false,
    onClick: () => {
      props.setVirtualMachinesSearchTerm();
      props.refreshEntities();
      props.getPimPendingApprovalRequestEpic();
    }
  };

  return [refreshListCommand];
};
