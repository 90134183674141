export const getDomainNameFromPathName = (): string => {
  const pathName = decodeURIComponent(location.pathname);

  if (!pathName || !pathName.length) {
    return '';
  }

  if (pathName.includes('@')) {
    const tempPath = pathName.replace(/\//g, '');
    const splits = tempPath.split('@');
    if (splits.length > 1) {
      return splits[splits.length - 1];
    } else {
      return '';
    }
  }

  if (pathName.split('.').length > 1) {
    const splits = pathName.split('/');
    if (splits.length > 1) {
      return splits[splits.length - 1];
    }
  }

  return '';
};
