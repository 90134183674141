import {
  ColumnActionsMode,
  css,
  FontClassNames,
  Icon,
  ResponsiveMode,
} from '@fluentui/react';
import * as React from 'react';

import { OverflowActionButton } from '../';
import { EntitlementSearchFilter } from '../../../models/ELM/EntitlementSearchFilter';
import { IGrant } from '../../../models/ELM/IGrant';
import { IListColumn } from '../../../models/IListColumn';
import { GridSizes, resizeResponsiveGrid } from '../../../shared';
import { FormatDate } from '../../../shared/FormatDateTime';
import { IsRenewAccessVisible } from '../../../shared/IsRenewAccessVisible';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { ColumnValue } from '../../Shared/ColumnValue/ColumnValue';
import { IActiveGrantListProps } from './';
import { getShortenedResourcesString } from '../../../shared/getResourcesString';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');
const myAccessListStyles = require('../../../css/myAccessList.scoped.scss');
const roleScopeListStyles = require('../../../css/RoleScopeList.scoped.scss');

const sizes: GridSizes = {
  'accessPackage/displayName': {
    [ResponsiveMode.small]: [150, 350],
    [ResponsiveMode.medium]: [96, 334],
    [ResponsiveMode.large]: [335, 650],
    [ResponsiveMode.xLarge]: [126, 304],
    [ResponsiveMode.xxLarge]: [241, 499],
    [ResponsiveMode.xxxLarge]: [416, 750],
  },
  'description': {
    [ResponsiveMode.xLarge]: [126, 291],
    [ResponsiveMode.xxLarge]: [241, 508],
    [ResponsiveMode.xxxLarge]: [416, 1584],
  },
  'resource': {
    [ResponsiveMode.xLarge]: [126, 291],
    [ResponsiveMode.xxLarge]: [241, 508],
    [ResponsiveMode.xxxLarge]: [416, 1584],
  },
  'schedule/startDate': {
    [ResponsiveMode.xLarge]: 85,
    [ResponsiveMode.xxLarge]: 85,
    [ResponsiveMode.xxxLarge]: [85, 200],
  },
  'schedule/stopDate': {
    [ResponsiveMode.medium]: 105,
    [ResponsiveMode.large]: 105,
    [ResponsiveMode.xLarge]: 105,
    [ResponsiveMode.xxLarge]: 105,
    [ResponsiveMode.xxxLarge]: [105, 200],
  },
};

export const getActiveGrantListColumns = (
  responsiveMode: ResponsiveMode,
  props: IActiveGrantListProps,
  onCtaButtonClick: (item: IGrant) => void,
  onColumnClick: (
    ev: React.MouseEvent<HTMLElement>,
    column: IListColumn<IGrant>
  ) => void
): IListColumn<IGrant>[] => {
  const {
    t,
    selectedFilterKey,
    isSearching,
    searchTerm,
    showAddGrantRequest,
    showCopyLink,
    showConfirmDialog,
  } = props;

  const columns: IListColumn<IGrant>[] = [
    {
      key: 'accessPackage/displayName',
      name: t(LocaleKeys.name),
      fieldName: 'accessPackage',
      minWidth: 100,
      maxWidth: 400,
      headerClassName: FontClassNames.smallPlus,
      onColumnClick: onColumnClick,
      isSorted: true,
      isSortedDescending: false,
      isResizable: true,
      onRender: (item: IGrant) => {
        const entitlementName = item.accessPackage.displayName!;
        return (
          <ColumnValue
            searchTerm={
              selectedFilterKey === EntitlementSearchFilter.AccessPackage ||
              selectedFilterKey === EntitlementSearchFilter.All
                ? searchTerm
                : ''
            }
            columnValue={entitlementName}
            isHighlightRequired={selectedFilterKey === EntitlementSearchFilter.AccessPackage ||
              selectedFilterKey === EntitlementSearchFilter.All}
            isSearching={isSearching}
          />
        );
      },
    } as IListColumn<IGrant>,
  ];

  if (responsiveMode! > ResponsiveMode.large) {
    columns.push(
      {
        key: 'description',
        name: t(LocaleKeys.description),
        columnActionsMode: ColumnActionsMode.disabled,
        fieldName: 'accessPackage',
        headerClassName: FontClassNames.smallPlus,
        minWidth: 60,
        maxWidth: 261,
        isResizable: true,
        onRender: (item: IGrant) => (
          <ColumnValue
            searchTerm={searchTerm}
            columnValue={item.accessPackage.description!}
            isHighlightRequired={true}
            isSearching={isSearching}
          />
        ),
      } as IListColumn<IGrant>,
      {
        key: 'schedule/startDate',
        name: t(LocaleKeys.startsOn),
        className: 'ms-pii',
        fieldName: 'schedule',
        minWidth: 80,
        maxWidth: 160,
        isResizable: true,
        headerClassName: FontClassNames.smallPlus,
        columnActionsMode: ColumnActionsMode.disabled,
        onRender: (item: IGrant) => (
          <span className={css('ms-pii', FontClassNames.medium)}>
            {FormatDate(item.schedule.startDateTime)}
          </span>
        ),
      } as IListColumn<IGrant>
    );

    if (!props.isGuestUser) {
      columns.splice(2, 0, {
        key: 'resource',
        name: t(LocaleKeys.resources),
        columnActionsMode:ColumnActionsMode.disabled,
        fieldName:'resource',
        headerClassName: FontClassNames.smallPlus,
        minWidth:60,
        maxWidth:261,
        isResizable:true,
        onRender: (item:IGrant) => {
          let displayResources = getShortenedResourcesString(item.accessPackageAssignmentResourceRoles, t);
          return(
          <ColumnValue
            searchTerm={searchTerm}
            columnValue={displayResources}
            isHighlightRequired={selectedFilterKey === EntitlementSearchFilter.Resources ||
              selectedFilterKey === EntitlementSearchFilter.All}
            isSearching={isSearching}
          />
          );
        }
      } as IListColumn<IGrant>);
     }
  }

  if (responsiveMode! > ResponsiveMode.small) {
    columns.push({
      key: 'schedule/stopDate',
      name: t(LocaleKeys.expiresOn),
      className: 'ms-pii',
      fieldName: 'schedule',
      minWidth: 96,
      maxWidth: 160,
      isResizable: true,
      headerClassName: FontClassNames.smallPlus,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: IGrant) => (
        <div>
          <span className={css('ms-pii', FontClassNames.medium, roleScopeListStyles.centerContent)}>
          {IsRenewAccessVisible(item.schedule.stopDateTime! as Date) && (
              <Icon
                  iconName={'Warning'}
                  className={css(
                  myAccessStyles.warningColor,
                  myAccessStyles.smallIcon,
                  // myAccessStyles.absoluteRight
                )}
                  aria-label={'expiring indicator'}
              />
            )
          }
            {item.schedule.stopDateTime
              ? FormatDate(item.schedule.stopDateTime)
              : t(LocaleKeys.noEndDate)}
          </span>
        </div>
      ),
    });
  }

  columns.push({
    key: 'clickThroughActionButton',
    name: t(LocaleKeys.actions),
    columnActionsMode: ColumnActionsMode.disabled,
    fieldName: '',
    minWidth: 100,
    maxWidth: 100,
    isResizable: false,
    onRender: (grant: IGrant) => {
      const showPrimaryAction = IsRenewAccessVisible(
        grant.schedule.stopDateTime! as Date
      );

      return (
        <OverflowActionButton
          className={myAccessListStyles.rightActionButton}
          itemKey={grant.id}
          showPrimaryAction={showPrimaryAction}
          primaryText={t(LocaleKeys.extend, { context: 'capitalize' })}
          primaryActionAriaDescription={t(
            LocaleKeys.extendAccessPackageButtonAria,
            { packageName: grant.accessPackage.displayName }
          )}
          onPrimaryPress={() => {
            onCtaButtonClick(grant);
            showAddGrantRequest();
          }}
          secondaryMenuItems={[
            {
              key: 'remove',
              ariaLabel: t(LocaleKeys.removeAccess, { context: 'aria' }),
              text: t(LocaleKeys.removeAccess),
              iconProps: { iconName: 'Delete' },
              onClick: () => {
                onCtaButtonClick(grant);
                showConfirmDialog();
              },
            },
            {
              key: 'share',
              ariaLabel: t(LocaleKeys.share, { context: 'aria' }),
              text: t(LocaleKeys.share),
              iconProps: { iconName: 'Share' },
              onClick: () => {
                onCtaButtonClick(grant);
                showCopyLink();
              },
            },
          ]}
        />
      );
    },
  });

  return resizeResponsiveGrid(columns, sizes, responsiveMode);
};
