import { IAppRootState, getRootAppReducers, rootEpics } from '@microsoft/portal-app/lib/redux/modules/root';
import { ReducerRegistry } from '@microsoft/portal-app/lib/redux/ReducerRegistry';
import { Action, combineReducers, ReducersMapObject } from 'redux';
import { Reducer } from '@microsoft/portal-app/lib/redux/compat';
import { combineEpics, Epic } from 'redux-observable';
import { IRootEntitlementsState } from '../../models/IEntitlementState';
import { entitlementEpics, entitlementReducers } from './entitlement';
import { History } from 'history';

// tslint:disable-next-line:no-any ~ Expected generic type
type AnyEpic = Epic<Action, any, any>;

const epics: { [key: string]: AnyEpic[] } = {};

export const getRootEpic = () =>
  combineEpics<Action, IGlobalState>(
    ...rootEpics,
    ...entitlementEpics,
    ...Object.keys(epics).reduce((epicItems: AnyEpic[], key: string) => epicItems.concat(epics[key]), [])
  );

export interface IGlobalState extends IRootEntitlementsState, IAppRootState {}

const reducers: ReducersMapObject = {};

export const rootReducer = (history: History) =>
  combineReducers<IGlobalState>({
    ...getRootAppReducers(history),
    ...reducers,
    app: entitlementReducers
  }) as Reducer<IGlobalState>;

export const addReducer = <TRootState, TState, TAction extends Action>(
  key: keyof TRootState,
  value: ReducerRegistry<TAction, TState, TRootState>
) => {
  reducers[key as string] = value.getReducer() as Reducer<TState>;
  epics[key as string] = value.getEpics();
};
