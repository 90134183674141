import { useContext } from 'react';
import { Dispatch } from 'redux';

import { Context } from '../context';
import { IGlobalState } from '../redux/modules/root';

export function useRedux(): [IGlobalState, Dispatch<IGlobalState>] {
  const context = useContext(Context);
  return [context?.state, context?.dispatch];
}

export function useDispatch(): Dispatch<IGlobalState> {
  const [, dispatch] = useRedux();
  return dispatch;
}

export function useSelector<TSelected>(selector: (state: IGlobalState) => TSelected): TSelected {
  const [state] = useRedux();
  return selector(state);
}
