const validator = new RegExp(
  '^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$',
  'i'
);

const guidFinder = new RegExp(
  '({){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(}){0,1}'
);

export const isGuid = (guid: string): boolean => {
  if (!guid) {
    return false;
  }
  return validator.test(guid);
};

export const findGuid = (text: string): string => {
  if (!text || text === '') {
    return '';
  }

  let matches = text.match(guidFinder);
  if (matches && matches.length > 0) {
    return matches[0];
  }
  return '';
};

export const findGuids = (text: string): RegExpMatchArray | null => {
  return text.match(guidFinder);
};
