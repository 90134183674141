import { IAjaxRequest } from '@microsoft/portal-app/lib/auth';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';

import { EntitlementActions } from '../../../models/EntitlementActions';
import { EntityType } from '../../../models/EntityType';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState, IRootEntitlementsState } from '../../../models/IEntitlementState';
import { IPimRoleManagementPolicyListRequestParameters } from '../../../models/RAM/IPimRoleManagementPolicyGetRequestParameter';
import {
  IPimRoleManagementPolicyRule,
  PimRoleManagementPolicyRuleRuleType,
  Rule,
  TargetCaller,
  TargetLevel
} from '../../../models/RAM/IQuickActivationDialogValue';
import { getAudience } from '../../../shared/AttachAudience';
import { getRamBaseUrlBasedOnEnv } from '../../../shared/getRamApiUrl';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { registry } from '../myAccessRegistry';
import { removeLeadingSlash } from './utils';

export const listRoleManagementPolicyEpic: Epic<IEntitlementAction<AnyPayload>, IRootEntitlementsState> = (
  action$: ActionsObservable<IEntitlementAction<IPimRoleManagementPolicyListRequestParameters>>,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
  return action$
    .ofType(EntitlementActions.listRoleManagementPolicy)
    .switchMap((action: IEntitlementAction<IPimRoleManagementPolicyListRequestParameters>) => {
      const parameters = action.payload!;
      const ajaxRequest: IAjaxRequest = {
        method: 'GET',
        url: genRoleManagementPolicyListUrl(parameters),
        audience: getAudience(EntityType.cloudInfrastructure)
      };

      return ajax(ajaxRequest)
        .map((payload) => {
          const policy = payload.response.value[0];

          return {
            type: EntitlementActions.listRoleManagementPolicySuccess,
            payload: policy.properties.effectiveRules
          };
        })
        .catch((error) => {
          return Observable.of({
            type: EntitlementActions.listRoleManagementPolicyFailed,
            payload: error
          });
        });
    });
};
registry.addEpic('listRoleManagementPolicyEpic', listRoleManagementPolicyEpic);

export const setQuickActivationDialogValueFromResponse = (
  state: IEntitlementState,
  action: IEntitlementAction<IPimRoleManagementPolicyRule[]>
): Readonly<IEntitlementState> => {
  const rules = action
    .payload!.filter(
      (rule) =>
        rule.ruleType === PimRoleManagementPolicyRuleRuleType.RoleManagementPolicyEnablementRule ||
        rule.ruleType === PimRoleManagementPolicyRuleRuleType.RoleManagementPolicyExpirationRule
    )
    .filter((rule) => rule.target.caller === TargetCaller.EndUser && rule.target.level === TargetLevel.Assignment);

  const isJustificationRequired = rules.some((rule) => rule.enabledRules?.includes(Rule.Justification));
  const isTicketingRequired = rules.some((rule) => rule.enabledRules?.includes(Rule.Ticketing));
  const maximumDuration = rules.find(
    (rule) => rule.ruleType === PimRoleManagementPolicyRuleRuleType.RoleManagementPolicyExpirationRule
  )?.maximumDuration;

  return {
    ...state,
    isJustificationRequired,
    isTicketingRequired,
    maximumDuration
  };
};
registry.add(EntitlementActions.listRoleManagementPolicySuccess, setQuickActivationDialogValueFromResponse);

export const clearQuickActivationDialogValue = (state: IEntitlementState): Readonly<IEntitlementState> => {
  return {
    ...state,
    isJustificationRequired: undefined,
    isTicketingRequired: undefined,
    maximumDuration: undefined,
    isMfaRequirementMet: undefined
  };
};
registry.add(EntitlementActions.clearQuickActivationDialogValue, clearQuickActivationDialogValue);

export const listRoleManagementPolicyFailed = (
  state: IEntitlementState,
  // tslint:disable-next-line
  action: IEntitlementAction<Readonly<any>>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  const ramErrorDialogContent = {
    message: LocaleKeys.ramErrorTryAgain,
    image: '/imgs/error_Warning.svg'
  };
  return { ...state, ramErrorDialogContent };
};
registry.add(EntitlementActions.listRoleManagementPolicyFailed, listRoleManagementPolicyFailed);

const genRoleManagementPolicyListUrl = (parameters: IPimRoleManagementPolicyListRequestParameters): string => {
  const baseUrl = getRamBaseUrlBasedOnEnv();
  const scope = removeLeadingSlash(parameters.scope);
  const filter = `roleDefinitionId eq '${parameters.scope}/providers/Microsoft.Authorization/roleDefinitions/${parameters.roleDefinitionId}'`;

  return `${baseUrl}${scope}/providers/Microsoft.Authorization/roleManagementPolicyAssignments?api-version=2020-10-01&$filter=${filter}`;
};
