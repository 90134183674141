import { getResponseValue, IODataValueResponse } from '@microsoft/portal-app/lib/odata-utils';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';
import { IEntitlement } from '../../../models/ELM/IEntitlement';
import { IEntity } from '../../../models/ELM/IEntity';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { EntityType } from '../../../models/EntityType';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState, IRootEntitlementsState } from '../../../models/IEntitlementState';
import { IEntityFilterable } from '../../../models/IEntityFilterable';
import { IEntityResult } from '../../../models/IEntityResult';
import { IFilter } from '../../../models/IFilter';
import { LoadMode } from '../../../models/IPageData';
import { getRequestWithAudience } from '../../../shared/AttachAudience';
import { sortEntitiesApiUrl, sortFilteredEntitiesApiUrl } from '../../../shared/getApiUrl';
import { getSupervisorCentricAccessReviewDecisionsApiUrl, getFilteredSupervisorCentricAccessReviewDecisionsApiUrl } from '../../../shared/getAccessReviewsApiUrl';
import { registry } from '../myAccessRegistry';

export const sortEntitiesEpic: Epic<IEntitlementAction<AnyPayload>, IRootEntitlementsState> = (
  action$: ActionsObservable<
    IEntitlementAction<{
      entityType: string;
      orderby: string;
      isAscending: boolean;
    }>
  >,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
  return action$.ofType(EntitlementActions.sortEntities).switchMap((action: IEntitlementAction<IEntityFilterable>) => {
    const orderby = action.payload!.orderby!;
    const isAscending = action.payload!.isAscending;
    let entityType = action.payload!.entityType;
    let url = sortEntitiesApiUrl(entityType!, orderby!, isAscending!);

    // Special case for supervisor centric view
    if (location.href.includes('user-access-reviews')) {
      url = getSupervisorCentricAccessReviewDecisionsApiUrl(orderby, isAscending);
    }

    const ajaxRequest = getRequestWithAudience(url, entityType as EntityType);

    return (
      ajax(ajaxRequest)
        .map((payload: IODataValueResponse<ReadonlyArray<IEntity>>) => {
          return {
            type: EntitlementActions.getEntitiesSucceeded,
            payload: {
              entityType: entityType,
              entities: getResponseValue(payload),
              count: payload.response!['@odata.count'],
              nextLink: payload.response!['@odata.nextLink']
            }
          } as IEntitlementAction<IEntityResult<IEntity>>;
        })
        // tslint:disable-next-line:no-any
        .catch((error: any) =>
          Observable.of({
            type: EntitlementActions.getEntitiesFailed,
            payload: {
              entityType: entityType,
              errorCode: error && error.status
            }
          })
        )
    );
  });
};
registry.addEpic('sortEntitiesEpic', sortEntitiesEpic);

export const sortEntities = (
  state: IEntitlementState,
  action: IEntitlementAction<{ entityType: string }>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }
  let entityType = action.payload.entityType;

  return {
    ...state,
    [entityType]: {
      ...state[entityType],
      isLoading: true,
      loadMode: LoadMode.LoadMore,
      entitiesById: new Map(),
      unfiltered: {
        ...state[entityType].unfiltered,
        entities: [],
        nextLink: undefined
      }
    }
  };
};
registry.add(EntitlementActions.sortEntities, sortEntities);

export const sortFilteredEntitiesEpic: Epic<IEntitlementAction<AnyPayload>, IRootEntitlementsState> = (
  action$: ActionsObservable<
    IEntitlementAction<{
      entityType: string;
      orderby: string;
      isAscending: boolean;
      searchTerm: string;
      filterContext?: IFilter;
    }>
  >,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
  return action$
    .ofType(EntitlementActions.sortFilteredEntities)
    .switchMap((action: IEntitlementAction<IEntityFilterable>) => {
      const orderby = action.payload!.orderby!;
      const searchTerm = action.payload!.searchTerm!;
      const isAscending = action.payload!.isAscending;
      const entityType = action.payload!.entityType;
      const filterContext = action.payload!.filterContext!;
      const selectedFilterKey = _store.getState().search.selectedFilterKey!;

      let url = sortFilteredEntitiesApiUrl(entityType!, orderby, isAscending!, searchTerm, filterContext, selectedFilterKey);

      // Special case for supervisor centric view
      if (location.href.includes('user-access-reviews')) {
        url = getFilteredSupervisorCentricAccessReviewDecisionsApiUrl(searchTerm, filterContext, orderby, isAscending);
      }

      const ajaxRequest = getRequestWithAudience(
        url,
        entityType as EntityType
      );



      return ajax(ajaxRequest)
        .map((payload: IODataValueResponse<ReadonlyArray<IEntitlement>>) => {
          return {
            type: EntitlementActions.searchEntitiesOnServerSucceeded,
            payload: {
              entityType: entityType,
              entities: getResponseValue(payload),
              searchTerm: searchTerm,
              count: payload.response!['@odata.count'],
              nextLink: payload.response!['@odata.nextLink']
            }
          } as IEntitlementAction<IEntityResult<IEntitlement>>;
        })
        .catch(() =>
          Observable.of({
            type: EntitlementActions.searchEntitiesOnServerFailed,
            payload: entityType
          })
        );
    });
};
registry.addEpic('sortFilteredEntitiesEpic', sortFilteredEntitiesEpic);

export const sortFilteredEntities = (
  state: IEntitlementState,
  action: IEntitlementAction<{ entityType: string }>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }
  let entityType = action.payload.entityType;
  return {
    ...state,
    [entityType]: {
      ...state[entityType],
      isLoading: true,
      loadMode: LoadMode.LoadMore,
      filtered: {
        ...state[entityType].filtered,
        entities: [],
        nextLink: undefined
      }
    }
  };
};
registry.add(EntitlementActions.sortFilteredEntities, sortFilteredEntities);
