import { IOrganization } from '@iamexperiences/suite-header/lib-commonjs/SuiteHeader/SuiteHeader.types';
import { IAjaxCreationMethod } from '@microsoft/portal-app/lib/auth';
import { IAction } from '@microsoft/portal-app/lib/redux';
import {
  addAjaxEpic,
  getFailureActionType,
  getSuccessActionType
} from '@microsoft/portal-app/lib/redux/createAjaxEpic';
import { getMyOrganizationsApiUrl } from '@microsoft/portal-app/lib/User/redux/userApi';
import { EntitlementActions, IEntitlementState } from '../../../models';
import { registry } from '../myAccessRegistry';

export const getOrganizationsAjax = (ajax: IAjaxCreationMethod) => {
  return ajax.postJSON(getMyOrganizationsApiUrl());
};

export const getOrganizationsSuccess = (
  state: IEntitlementState,
  action: IAction<{ Organizations: IOrganization[] }>
): IEntitlementState => {
  if (!action.payload) {
    return state;
  }

  const res = action.payload;
  return {
    ...state,
    organizations: {
      isLoading: false,
      value: res.Organizations
    }
  };
};

export const getOrganizationsFailed = (state: IEntitlementState, action: IAction<string>): IEntitlementState => {
  if (!action.payload) {
    return state;
  }

  return {
    ...state,
    hasError: true
  };
};

addAjaxEpic(registry, EntitlementActions.getOrganizations, getOrganizationsAjax, {
  showErrorAsToast:false
});
registry.add(getSuccessActionType(EntitlementActions.getOrganizations), getOrganizationsSuccess);
registry.add(getFailureActionType(EntitlementActions.getOrganizations), getOrganizationsFailed);
