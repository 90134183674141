import { css } from '@fluentui/react';
import { Dialog, DialogFooter, TextField } from '@fluentui/react';
import {
  DefaultButton,
  PrimaryButton
} from '@fluentui/react/lib/Button';
import { ResponsiveMode } from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import * as React from 'react';
import { DecisionType } from '../../../models/RequestApprovals/DecisionType';
import { asLocalizedText } from '../../../shared/asLocalizedText';
import { getDecisionsFromGrantRequests } from '../../../shared/getDecisionFromRequest';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { getSpinner } from '../../../shared/spinner';
import {
  IBulkActionDialogProps,
  IBulkActionDialogState
} from './BulkActionDialog.types';
import { GetGrantFromEntitlement, GetGrantRequestFromGrant } from '../../../shared/GetGrantRequestFromEntity';
import { RequestType } from '../../../models/ELM/RequestType';
import { IGrantRequest } from '../../../models/ELM/IGrantRequest';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');
export class BulkActionDialog extends React.Component<
  IBulkActionDialogProps,
  IBulkActionDialogState
> {
  constructor(nextProps: IBulkActionDialogProps) {
    super(nextProps);

    this.state = {
      decision:
        this.props.decisionType != DecisionType.Revoke &&
          this.props.selectedGrantRequests &&
          this.props.selectedGrantRequests.length > 0
          ? getDecisionsFromGrantRequests(
            nextProps.selectedGrantRequests!,
            nextProps.decisionType
          )
          : undefined,
      textFieldClean: true,
      approvalReason: ''
    };
  }

  public render(): JSX.Element {
    const {
      isApproverReasonRequired,
      decisionType,
      selectedGrantRequests: selectedGrantRequests,
      t,
      responsiveMode
    } = this.props;

    let title = '';
    switch (decisionType) {
      case DecisionType.Approve: title = t(LocaleKeys.approveAccess); break;
      case DecisionType.Deny: title = t(LocaleKeys.denyAccess); break;
      case DecisionType.Revoke: title = t(LocaleKeys.removeAccess); break;
    }

    let subTextKey = '';
    switch (decisionType) {
      case DecisionType.Approve: subTextKey = LocaleKeys.approvalsWithCount; break;
      case DecisionType.Deny: subTextKey = LocaleKeys.denialsWithCount; break;
      case DecisionType.Revoke: subTextKey = LocaleKeys.removeAccessApproverConfirmation; break;
    }

    let subText = {
      key: subTextKey,
      options: {
        count: selectedGrantRequests && selectedGrantRequests.length
      }
    };

    const entitlementName = selectedGrantRequests![0].accessPackageAssignment.accessPackage?.displayName;
    const userDisplayName = selectedGrantRequests![0].requestor.displayName;
    let subTextRevoke = {
      key: subTextKey,
      options: {
        userDisplayName: userDisplayName,
        entitlementName: entitlementName
      },
    };



    const reason = this.state.decision! && this.state.approvalReason!;
    const submitDisabled = isApproverReasonRequired && reason!.length === 0;

    return (
      <Dialog
        isOpen={true}
        onDismiss={this.props.onDismiss}
        isBlocking={false}
        dialogContentProps={{
          title: title,
          subText: asLocalizedText(decisionType === DecisionType.Revoke ? subTextRevoke : subText, t)
        }}
        modalProps={{
          containerClassName:
            responsiveMode! < ResponsiveMode.large
              ? myAccessStyles.smallDialog
              : ''
        }}
      >
        <div
          className={css(
            myAccessStyles.marginTopSmall,
            myAccessStyles.marginBottomMedium
          )}
        >
          <TextField
            label={t(LocaleKeys.reason)}
            className={'ms-pii'}
            value={this.state.decision && this.state.approvalReason}
            placeholder={
              isApproverReasonRequired ? t(LocaleKeys.required) : null
            }
            onChange={this._onReasonChanged}
            required={isApproverReasonRequired}
            onGetErrorMessage={this._getErrorMessage}
            multiline
          />
        </div>
        <DialogFooter>
          {this.props.submitting ? (
            getSpinner(asLocalizedText(LocaleKeys.submitting, t))
          ) : (
            <div>
              <PrimaryButton
                onClick={this._save}
                disabled={submitDisabled}
                text={decisionType === DecisionType.Revoke ? t(LocaleKeys.remove) : t(LocaleKeys.submit)}
                className={css(myAccessStyles.marginRightSmall)}
              />
              <DefaultButton
                onClick={this.props.onDismiss}
                text={t(LocaleKeys.cancel)}
              />
            </div>
          )}
        </DialogFooter>
      </Dialog>
    );
  }

  private _getErrorMessage = (value: string): string => {
    const { isApproverReasonRequired, t } = this.props;

    if (!isApproverReasonRequired) {
      return '';
    }

    return value.length !== 0 || this.state.textFieldClean
      ? t(LocaleKeys.empty)
      : t(LocaleKeys.reasonNotEmpty);
  }

  private _onReasonChanged = (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    this.setState({
      ...this.state,
      textFieldClean: false,
      approvalReason: newValue!
    });
  }

  private _save = (): void => {
    if (this.props.decisionType == DecisionType.Revoke) {
      for (let request of this.props.selectedGrantRequests!) {
        const grant = GetGrantFromEntitlement(request.accessPackageAssignment.accessPackage, request.accessPackageAssignment.id);
        let grantRequest = GetGrantRequestFromGrant(grant!, RequestType.ApproverRemove);

        const newGrantRequest: Partial<IGrantRequest> = {
          ...grantRequest,
          justification: this.state.approvalReason,
        };

        this.props.revokeGrantRequest({
          newGrantRequest: newGrantRequest!,
          entitlementName: request.accessPackageAssignment.accessPackage?.displayName!
        });
      }
    }
    else {
      for (let request of this.state.decision!) {
        request.justification = this.state.approvalReason;
        this.props.postDecision(request);
      }
    }
  }
}
