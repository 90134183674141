import { TranslationFunction } from 'i18next';
import { LocaleKeys } from './LocaleKeys';
import { IRoleScope } from '../models';

export const getShortenedResourcesString = ( roleScopes: IRoleScope[]|undefined, t: TranslationFunction): string => {
  let shortenedResourcesString = '';
  let resources = roleScopes?.map(scope => {
    let resourceScope = scope && scope.accessPackageResourceScope;
    let accessPackageResource = resourceScope && resourceScope.accessPackageResource;
    let resourceName: string =
      accessPackageResource !== null ||
      accessPackageResource !== undefined
        ? accessPackageResource.displayName
        : '';
    return resourceName;
  });

  // Only show the first 3 resources full name
  if (resources !== null && resources !== undefined) {
    shortenedResourcesString = resources.slice(0, Math.min(resources.length, 3)).join(', ');
    if (resources.length > 3) {
      shortenedResourcesString += t(LocaleKeys.collapsedResources,{ count: resources.length - 3 });
    }
  }

  return shortenedResourcesString;
};