import {
  IWithResponsiveModeState,
  withResponsiveMode,
} from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import React, { useMemo } from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch, Store } from 'redux';

import { IGlobalState } from '../redux/modules/root';

interface MappedState {
  state: IGlobalState;
}

interface MappedDispatch {
  dispatch: Dispatch<IGlobalState>;
}

interface OwnProps {
  store: Store<IGlobalState | undefined>;
  children: React.ReactNode;
}

type ContextValue = InjectedTranslateProps &
  MappedState &
  MappedDispatch &
  IWithResponsiveModeState;

type ContextProviderProps = {
  children: React.ReactNode;
} & ContextValue &
  OwnProps;

export const Context = React.createContext<ContextValue>(
  undefined as unknown as ContextValue
);

function ContextProvider({
  children,
  state,
  dispatch,
  t,
  responsiveMode,
}: ContextProviderProps): JSX.Element {
  const memoizedValue = useMemo(
    () => ({
      state,
      dispatch,
      t,
      responsiveMode,
    }),
    [state, responsiveMode, dispatch, t]
  );

  return <Context.Provider value={memoizedValue}>{children}</Context.Provider>;
}

export const ConnectedContextProvider = connect<
  MappedState,
  MappedDispatch,
  OwnProps,
  IGlobalState
>(
  (state: IGlobalState) => ({ state }),
  (dispatch: Dispatch<IGlobalState>) => ({ dispatch })
)(withResponsiveMode(translate('MyAccess')(ContextProvider)));
