import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { useDispatch } from 'react-redux';
import { EntitlementActions, LoadMode } from '../models';
import { useSelector } from '.';
import { IAccessPackageRecommendation } from '../models/ELM/IAccessPackageRecommendations';
import { GetEntityList } from '../shared';

export const useGetRecommendations = () => {
  const { suggestions } = useSelector((state) => ({
    suggestions: state.app.suggestions
  }));

  let suggestionList: IAccessPackageRecommendation[] = [];
  let loadMode: LoadMode = LoadMode.Initial;

  if (suggestions !== undefined && suggestions.isLoading === false){
  const entitiesList = GetEntityList(suggestions, false);

  suggestionList = entitiesList.filteredEntities.reduce(
    (suggestionList: IAccessPackageRecommendation[], key: string) => {
      const item = suggestions.entitiesById.get(key);
      if (item !== undefined){
        const newItem: IAccessPackageRecommendation = {
          accessPackage: item.accessPackage,
          accessPackageId: item.accessPackageId,
          accessPackageSuggestionReasons: item.accessPackageSuggestionReasons,
          totalScore: item.totalScore,
          id: item.id
        };
        suggestionList.push(newItem);
      }
      return suggestionList;
    },
    []
  );

    loadMode = suggestions.loadMode!;
  }

  const dispatch = useDispatch();
  const refreshRecommendations = createAction<{entityType: string}>(EntitlementActions.refreshEntities, dispatch);
  const getRecommendations = createAction<{entityType: string}>(EntitlementActions.getEntities, dispatch);
  return { suggestions, loadMode, suggestionList, refreshRecommendations, getRecommendations };
};
