export interface IGrantRequestTarget {
  targetType?: IGrantRequestTargetType;
}

export interface IGrantRequestTargetTypeActionPayload {
  type?: IGrantRequestTargetType;
}

export enum IGrantRequestTargetType {
  Myself = 'myself',
  OnBehalfOf = 'obo'
}
