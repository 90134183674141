import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { EntitlementActions, LoadMode } from '../../../models';
import { EntityType } from '../../../models/EntityType';
import { IRootEntitlementsState } from '../../../models/IEntitlementState';
import { IFilter } from '../../../models/IFilter';
import { IListColumn } from '../../../models/IListColumn';
import { IActivationStatusPayload } from '../../../models/RAM/IActivationStatusPayload';
import { IErrorDialog } from '../../../models/RAM/IErrorDialog';
import { IPimActivationRequestParameters } from '../../../models/RAM/IPimActivationRequestParameters';
import { IPimRoleManagementPolicyListRequestParameters } from '../../../models/RAM/IPimRoleManagementPolicyGetRequestParameter';
import { IVirtualMachine, IVirtualMachineExtendedDetails } from '../../../models/RAM/IVirtualMachine';
import { getPageMetaDataFromEntities } from '../../../shared/getPageMetaDataFromEntities';
import { VmList } from './VmList';
import { IConnectedVmListProps, IVmListActions, IVmListMappedProps } from './VmList.types';
import { getVirtualMachineList, IGetVirtualMachineListContext } from './VmList.utils';

/**
 * Maps the external (i.e. own) props and the state to the properties of the VmList control.
 * @param state The current redux state to map to props
 * @param ownProps The external properties to map to props
 * @returns The mapped properties that along with the actions
 * becomes the props (IVmListProps) for the VmList control
 */
const mapStateToProps = (state: IRootEntitlementsState, _ownProps: IConnectedVmListProps): IVmListMappedProps => {
  const {
    virtualMachines: virtualMachinesInResponse,
    activationStatus,
    isJustificationRequired,
    maximumDuration,
    isMfaRequirementMet,
    isTicketingRequired,
    errorHasOccurred,
    ramErrorDialogContent,
    selectedVirtualMachineDetails,
    pendingApprovalList,
    errorCode
  } = state.app;
  const filter = virtualMachinesInResponse.filterContext;
  const isFiltering: boolean = filter !== undefined && Boolean(filter.selectedSubscription?.trim());
  const isSearching: boolean = Boolean(virtualMachinesInResponse.searchTerm?.trim());
  const vmListContext: IGetVirtualMachineListContext = {
    pendingApprovalList,
    isFiltering: isSearching || isFiltering
  };
  const vmList: IVirtualMachine[] = getVirtualMachineList(virtualMachinesInResponse, vmListContext);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const loadMode = virtualMachinesInResponse.loadMode!;
  const pageMetaData = getPageMetaDataFromEntities(virtualMachinesInResponse, errorHasOccurred);

  return {
    vmList,
    pageMetaData,
    isLoading: virtualMachinesInResponse.isLoading,
    isRefreshing: loadMode === LoadMode.Refresh,
    isLoadingMore: loadMode === LoadMode.LoadMore,
    activationStatus,
    isJustificationRequired,
    isTicketingRequired,
    maximumDuration,
    isMfaRequirementMet,
    errorHasOccurred,
    errorCode,
    ramErrorDialogContent,
    selectedVirtualMachineDetails,
    isSearching,
    isFiltering
  };
};

/**
 * Maps the dispatch actions to the props for the VmList control.
 * @param dispatch Dispatches actions to the List
 * @returns The mapped actions that along with the mapped properties
 * becomes the props (IVmListProps) for the VmList control
 */
const mapDispatchToProps = (dispatch: Dispatch<IRootEntitlementsState>): IVmListActions => {
  const refreshEntitiesAction = createAction<{ entityType: string }>(EntitlementActions.refreshEntities, dispatch);
  const getEntitiesAction = createAction<{ entityType: string }>(EntitlementActions.getEntities, dispatch);
  const setSortedByColumnAction = createAction<{
    column: IListColumn<IVirtualMachine>;
    entityType: string;
  }>(EntitlementActions.setSortedByColumn, dispatch);
  const sortEntities = createAction<{
    entityType: string;
    orderby: string;
    isAscending: boolean;
  }>(EntitlementActions.sortEntities, dispatch);
  const activateRamAccessAction = createAction<IPimActivationRequestParameters>(
    EntitlementActions.activateRamAccess,
    dispatch
  );
  const setActivationStatus = createAction<IActivationStatusPayload>(EntitlementActions.setActivationStatus, dispatch);
  const listRoleManagementPolicy = createAction<IPimRoleManagementPolicyListRequestParameters>(
    EntitlementActions.listRoleManagementPolicy,
    dispatch
  );
  const clearQuickActivationDialogValue = createAction(EntitlementActions.clearQuickActivationDialogValue, dispatch);
  const validateRoleActivationAction = createAction<IPimActivationRequestParameters>(
    EntitlementActions.validateRoleActivation,
    dispatch
  );
  const setRamErrorMessageBarAction = createAction<string>(EntitlementActions.setErrorMessageBar, dispatch);
  const setRamErrorDialogAction = createAction<IErrorDialog>(EntitlementActions.setRamErrorDialog, dispatch);
  const getVirtualMachineDetailsAction = createAction<IVirtualMachine>(
    EntitlementActions.getVirtualMachineDetails,
    dispatch
  );
  const setSelectedVirtualMachineDetailsAction = createAction<IVirtualMachineExtendedDetails>(
    EntitlementActions.setSelectedVirtualMachineDetails,
    dispatch
  );
  const searchVirtualMachinesAction = (searchTerm?: string) =>
    createAction<string>(EntitlementActions.searchVirtualMachinesOnServer, dispatch)(searchTerm);
  const filterEntitiesOnServerAction = createAction<{
    entityType: string;
    filterContext: IFilter | undefined;
  }>(EntitlementActions.filterEntitiesOnServer, dispatch);
  const getPimPendingApprovalRequestEpic = createAction(EntitlementActions.getPimPendingApprovalRequest, dispatch);
  const setVirtualMachinesSearchTermAction = (searchTerm?: string) =>
    createAction<string>(EntitlementActions.setVirtualMachinesSearchTerm, dispatch)(searchTerm);
  return {
    refreshEntities: () => {
      return refreshEntitiesAction({ entityType: EntityType.virtualMachines });
    },
    getEntities: () => {
      return getEntitiesAction({ entityType: EntityType.virtualMachines });
    },
    setSortedByColumn: (column: IListColumn<IVirtualMachine>) =>
      setSortedByColumnAction({
        column,
        entityType: EntityType.virtualMachines
      }),
    sortEntities: (orderby: string, isAscending: boolean) =>
      sortEntities({
        entityType: EntityType.virtualMachines,
        orderby,
        isAscending
      }),
    activateRamAccess: (parameters: IPimActivationRequestParameters) => {
      activateRamAccessAction(parameters);
    },
    setActivationStatus: (payload: IActivationStatusPayload) => {
      setActivationStatus(payload);
    },
    listRoleManagementPolicy: (parameters: IPimRoleManagementPolicyListRequestParameters) => {
      listRoleManagementPolicy(parameters);
    },
    clearQuickActivationDialogValue: () => {
      clearQuickActivationDialogValue();
    },
    validateRoleActivation: (parameters: IPimActivationRequestParameters) => {
      validateRoleActivationAction(parameters);
    },
    setRamErrorMessageBar: (errorMessage?: string) => {
      setRamErrorMessageBarAction(errorMessage);
    },
    setRamErrorDialog: (errorDialogContent?: IErrorDialog) => {
      setRamErrorDialogAction(errorDialogContent);
    },
    getVirtualMachineDetails: (virtualMachine?: IVirtualMachine) => {
      getVirtualMachineDetailsAction(virtualMachine);
    },
    setSelectedVirtualMachineDetails: (virtualMachineDetails?: IVirtualMachineExtendedDetails) => {
      setSelectedVirtualMachineDetailsAction(virtualMachineDetails);
    },
    searchVirtualMachines: (searchTerm?: string) => {
      return searchVirtualMachinesAction(searchTerm);
    },
    filterEntitiesOnServer: (filter: IFilter | undefined) =>
      filterEntitiesOnServerAction({
        entityType: EntityType.virtualMachines,
        filterContext: filter
      }),
    getPimPendingApprovalRequestEpic: () => {
      getPimPendingApprovalRequestEpic();
    },
    setVirtualMachinesSearchTerm: (searchTerm?: string) => setVirtualMachinesSearchTermAction(searchTerm)
  };
};

/**
 * A redux connected VmList control.
 */
export const ConnectedVmList = connect(
  mapStateToProps,
  mapDispatchToProps
  // tslint:disable-next-line:no-any
)(translate('MyAccess')(VmList) as any);
