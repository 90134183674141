import { User } from '@microsoft/microsoft-graph-types';
import { IAjaxRequest } from '@microsoft/portal-app/lib/auth/withAuth';
import { LoadingState } from '@microsoft/portal-app/lib/models/ILoading';
import { IODataValueResponse } from '@microsoft/portal-app/lib/odata-utils';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';

import { EntityType } from '../../../models';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState, IRootEntitlementsState } from '../../../models/IEntitlementState';
import { getAudience, getUserFromAuth } from '../../../shared';
import { getCoworkersUrl, getDirectReportsUrl } from '../../../shared/getUsersApiUrl';
import { registry } from '../myAccessRegistry';

export enum GetPeopleRequestType {
  directReports = 'directReports',
  coworkers = 'coworkers'
}

export interface IGetPeoplePayload {
  requestType: GetPeopleRequestType;
}

type IGetPeopleAction = IEntitlementAction<IGetPeoplePayload>;
type IGetPeopleSuccessAction = IEntitlementAction<IGetPeopleSuccessActionPayload>;
type IGetPeopleFailAction = IEntitlementAction<IGetPeopleFailActionPayload>;
type IGetPeopleResponse = IODataValueResponse<readonly User[]>;

interface IGetPeopleSuccessActionPayload {
  users?: readonly User[];
  requestType?: GetPeopleRequestType;
}

interface IGetPeopleFailActionPayload {
  error?: Error;
  requestType?: GetPeopleRequestType;
}
export const getPeopleEpic: Epic<IEntitlementAction<AnyPayload>, IRootEntitlementsState> = (
  action$: ActionsObservable<IGetPeopleAction>,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IGetPeopleSuccessAction | IGetPeopleFailAction> => {
  return action$.ofType(EntitlementActions.getPeople).switchMap((action: IGetPeopleAction) => {
    const user = getUserFromAuth();

    if (!user?.objectId) {
      return Observable.of<IGetPeopleFailAction>({
        type: EntitlementActions.getPeopleFailed,
        payload: {
          error: new Error('User not authenticated'),
          requestType: action.payload?.requestType
        }
      });
    }

    const getPeopleRequest: IAjaxRequest = {
      url:
        action.payload?.requestType === GetPeopleRequestType.directReports
          ? getDirectReportsUrl(user.objectId)
          : getCoworkersUrl(user.objectId),
      method: 'GET',
      audience: getAudience(EntityType.graphObject)
    };

    return ajax(getPeopleRequest)
      .timeout(5000) // 5000ms
      .map((payload: IGetPeopleResponse): IGetPeopleSuccessAction => {
        return {
          type: EntitlementActions.getPeopleSucceeded,
          payload: {
            users: payload.response?.value,
            requestType: action.payload?.requestType
          }
        };
      })
      .catch((error: Error) => {
        return Observable.of<IGetPeopleFailAction>({
          type: EntitlementActions.getPeopleFailed,
          payload: {
            error,
            requestType: action.payload?.requestType
          }
        });
      });
  });
};
registry.addEpic('getPeopleEpic', getPeopleEpic);

export const getPeopleLoading = (state: IEntitlementState, action: IGetPeopleAction): Readonly<IEntitlementState> => {
  const property = action.payload?.requestType === GetPeopleRequestType.directReports ? 'directReports' : 'coworkers';
  return {
    ...state,
    [property]: {
      ...state[property],
      isLoading: true,
      loadingState: LoadingState.loading
    }
  };
};
registry.add(EntitlementActions.getPeople, getPeopleLoading);

export const getPeopleSucceeded = (
  state: IEntitlementState,
  action: IGetPeopleSuccessAction
): Readonly<IEntitlementState> => {
  const property = action.payload?.requestType === GetPeopleRequestType.directReports ? 'directReports' : 'coworkers';
  return {
    ...state,
    [property]: {
      ...state[property],
      isLoading: false,
      loadingState: LoadingState.loaded,
      value: action.payload?.users ?? []
    }
  };
};
registry.add(EntitlementActions.getPeopleSucceeded, getPeopleSucceeded);

export const getPeopleFailed = (
  state: IEntitlementState,
  action: IGetPeopleFailAction
): Readonly<IEntitlementState> => {
  const property = action.payload?.requestType === GetPeopleRequestType.directReports ? 'directReports' : 'coworkers';
  return {
    ...state,
    [property]: {
      ...state[property],
      isLoading: false,
      loadingState: LoadingState.error,
      loadingError: action.payload?.error
    }
  };
};
registry.add(EntitlementActions.getPeopleFailed, getPeopleFailed);
