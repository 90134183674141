export interface ISettingsManager<ISettings> {
  get<T extends keyof ISettings>(settingsName: T): ISettings[T];
  getAll(): ISettings;
  getRequired<T extends keyof ISettings>(settingName: T): Exclude<ISettings[T], undefined | null>;
  addSettings(settings: ISettings): ISettingsManager<ISettings>;
}

export function create<ISettings>(settings?: ISettings): ISettingsManager<ISettings> {
  let savedSettings: ISettings = settings || ({} as ISettings);
  const settingsManager = {
    get: <T extends keyof ISettings>(settingName: T): ISettings[T] => {
      return savedSettings[settingName];
    },
    getAll: () => {
      return savedSettings;
    },
    getRequired: <T extends keyof ISettings>(settingName: T): Exclude<ISettings[T], undefined | null> => {
      const value = savedSettings[settingName];

      if (value == null) {
        throw Error(`"${String(settingName)}" is not available in the current environment`);
      }

      return value as Exclude<ISettings[T], undefined | null>;
    },
    addSettings: (newSettings: ISettings): ISettingsManager<ISettings> => {
      savedSettings = {
        ...savedSettings,
        ...newSettings
      };
      return settingsManager;
    }
  };

  return settingsManager;
}
