import * as React from 'react';
import { IOverviewData } from '../../../models/IOverviewData';
import { OverviewErrorCard, OverviewLoadingCard } from './';
import { DocumentCard, DocumentCardTitle, PrimaryButton } from '@fluentui/react';
import { QosProvider } from '@iamexperiences/ecos-telemetry'
const overviewCardStyle = require('../../../css/overview.scoped.scss');
const myAccessStyles = require('../../../css/myAccess.scoped.scss');

export interface IOverviewActionCardProps extends IOverviewData {
  id: string;
  title: string;
  cardImageUrl: string;
  cardDescription: string;
  primaryButtonLabel?: string;
  linkLabel?: string;
  linkUrl?: string;
  primaryButtonAction: () => void;
  refreshAction: () => void;
}

export function OverviewActionCard(props: IOverviewActionCardProps) {
  return (
    <QosProvider name='OverviewActionCard'>
    <div>
      {props.isSucceeded && !props.isLoading ? (
       // Disabling for inline styles
       // tslint:disable
        <DocumentCard className={overviewCardStyle.cardNormal} style={{ alignItems: 'normal' }} aria-label={props.title}>
          <DocumentCardTitle title={props.title} className={overviewCardStyle.cardTitle} />
          <div className={overviewCardStyle.contentContainer}>
            <span className={overviewCardStyle.contentNumber}>{props.pendingActionEntityCount}</span>
            <p>{props.cardDescription}</p>
            <img
              height={60}
              className={myAccessStyles.marginRightSmall}
              src={props.cardImageUrl}
              role="presentation"
            />
          </div>
          <div className={overviewCardStyle.cardBottom}>
              <PrimaryButton onClick={props.primaryButtonAction}>{props.primaryButtonLabel}</PrimaryButton>
          </div>
        </DocumentCard>
      ) : props.isLoading ? (
        <OverviewLoadingCard />
      ) : (
        <OverviewErrorCard
          title={props.title}
          refreshCardAction={props.refreshAction}
        />
      )}
    </div>
    </QosProvider>
  );
}
