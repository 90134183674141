import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { useDispatch } from 'react-redux';

import { EntitlementActions, IPageData } from '../models';
import { IGrantPolicy } from '../models/ELM/IGrantPolicy';
import { IPolicyAssignment } from '../models/ELM/IPolicyAssignment';
import { GetPoliciesActionPayload } from '../redux';
import { useSelector } from '.';

interface IUseGetPolicies {
  getPolicies: (payload: GetPoliciesActionPayload) => void;
  clearValidationErrors: () => void;
  policies: IPageData<IGrantPolicy>;
  policyAssignments: IPolicyAssignment;
}

export const useGetPolicies = (): IUseGetPolicies => {
  const dispatch = useDispatch();
  const { policies, policyAssignments } = useSelector((state) => ({
    policies: state?.app?.policies,
    policyAssignments: state?.app?.policyAssignments
  }));

  return {
    getPolicies: createAction<GetPoliciesActionPayload>(EntitlementActions.getPolicies, dispatch),
    clearValidationErrors: createAction(EntitlementActions.clearValidationErrors, dispatch),
    policies,
    policyAssignments
  };
};
