import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { translate } from 'react-i18next';
import { connect, Dispatch } from 'react-redux';
import { isNullOrUndefined } from 'util';

import { EntitlementActions } from '../../../models';
import { IAccessReviewDecision } from '../../../models/AccessReviews/IAccessReviewDecision';
import { IDecisionsCriteria } from '../../../models/AccessReviews/IDecisionsCriteria';
import { ISubmitDecision } from '../../../models/AccessReviews/ISubmitDecision';
import { EntityType } from '../../../models/EntityType';
import { IRootEntitlementsState } from '../../../models/IEntitlementState';
import { IFilter } from '../../../models/IFilter';
import { IListColumn } from '../../../models/IListColumn';
import { LoadMode } from '../../../models/IPageData';
import { GetEntityList } from '../../../shared/GetEntityList';
import { getPageMetaDataFromEntities } from '../../../shared/getPageMetaDataFromEntities';
import { isEmptyOrUndefined } from '../../../shared/isEmptyOrUndefined';
import { AccessReviewDecisionsList } from './AccessReviewDecisionsList';
import {
  IAccessReviewDecisionsListActions,
  IAccessReviewDecisionsListMappedProps,
  IConnectedAccessReviewDecisionsListProps
} from './AccessReviewDecisionsList.types';

const getIsFiltering = (filter: IFilter): boolean => {
  if (!filter) {
    return false;
  }
  if (filter.selectedRecommendations && filter.selectedRecommendations.length > 0) {
    return true;
  }
  if (filter.selectedDecisions && filter.selectedDecisions.length > 0) {
    return true;
  }
  return false;
};

/**
 * Maps the external (i.e. own) props and the state to the properties of the AccessReviewDecisionsList control.
 * @param state The current redux state to map to props
 * @param ownProps The external properties to map to props
 * @returns The mapped properties that along with the actions
 * becomes the props (IAccessReviewDecisionsListProps) for the AccessReviewDecisionsList control
 */
const mapStateToProps = (
  state: IRootEntitlementsState,
  _ownProps: IConnectedAccessReviewDecisionsListProps
): IAccessReviewDecisionsListMappedProps => {
  const {
    accessReviewDecisions,
    searchTerm,
    errorHasOccurred,
    errorCode,
    isTenantWhitelisted,
    showingConfirmDialog,
    showingReviewDecisionsFilter,
    showingReviewDetails,
    showingUserCentricPanel,
    showingDecisionDetails,
    showingBulkDecisionDialog,
    showingReviewDecisionsDialog,
    showingResetDecisions,
    showingAcceptRecommendations,
    submitting,
    bulkDecisionType,
    entityLoadingList
  } = state.app;

  const filter = accessReviewDecisions.filterContext!;
  const reviewId = (_ownProps.match && _ownProps.match.params.reviewId) || '';
  const isSearching: boolean = !isEmptyOrUndefined(searchTerm);
  const isFiltering: boolean = getIsFiltering(filter);
  const entitiesList = GetEntityList(
    accessReviewDecisions,
    isSearching || isFiltering
  );

  // Get current accessReview from all type of reviewlists by ID
  let currentAccessReview = state.app.accessReviews.entitiesById.get(
    reviewId
  );

  if (isNullOrUndefined(currentAccessReview)) {
    currentAccessReview = state.app.aadRolesReviews.entitiesById.get(
      reviewId
    );
  }

  if (isNullOrUndefined(currentAccessReview)) {
    currentAccessReview = state.app.rbacReviews.entitiesById.get(
      reviewId
    );
  }

  if (isNullOrUndefined(currentAccessReview)) {
    currentAccessReview = state.app.userAccessReviews.entitiesById.get(
      reviewId
    );
  }

  if (isNullOrUndefined(currentAccessReview)) {
    currentAccessReview = state.app.accessPackageReviews.entitiesById.get(
      reviewId
    );
  }

  let accessReviewDecisionList: IAccessReviewDecision[] = entitiesList.filteredEntities.reduce(
    (decisionList: IAccessReviewDecision[], key: string) => {
      const item = accessReviewDecisions.entitiesById.get(key);
      if (item !== undefined) {
        decisionList.push(item);
      }
      return decisionList;
    },
    []
  );

  if (isNullOrUndefined(accessReviewDecisionList)) { accessReviewDecisionList = []; }

  // The current decisions criteria is the only item returned by the API, mapped to '0'
  const currentCriteria = state.app.decisionsCriteria.entitiesById.get('0');
  const decisionsCriteria: IDecisionsCriteria = { typeId: '' };
  if (!isNullOrUndefined(currentCriteria)) {
    decisionsCriteria.groupDisplayName = currentCriteria.groupDisplayName!;
    decisionsCriteria.appDisplayName = currentCriteria.appDisplayName!;
    decisionsCriteria.accessPackageDisplayName = currentCriteria.accessPackageDisplayName!;
    decisionsCriteria.accessPackageResources = currentCriteria.accessPackageResources!;
    decisionsCriteria.subjectType = currentCriteria.subjectType!;
    decisionsCriteria.resourceDisplayName = currentCriteria.resourceDisplayName!;
    decisionsCriteria.resourceType = currentCriteria.resourceType!;
    decisionsCriteria.typeId = currentCriteria.typeId!;
    decisionsCriteria.resourceId = currentCriteria.resourceId!;
  }

  const pageMetaData = getPageMetaDataFromEntities(accessReviewDecisions);

  const loadMode = accessReviewDecisions.loadMode!;

  return {
    reviewId,
    isSearching,
    isFiltering,
    errorHasOccurred,
    errorCode,
    isLoading: accessReviewDecisions.isLoading,
    isRefreshing: loadMode === LoadMode.Refresh,
    isLoadingMore: loadMode === LoadMode.LoadMore,
    accessReviewDecisionList,
    filter,
    pageMetaData,
    searchTerm,
    isTenantWhitelisted,
    currentAccessReview: currentAccessReview!,
    entityLoadingList,
    showingConfirmDialog: showingConfirmDialog!,
    showingReviewDecisionsFilter: showingReviewDecisionsFilter!,
    showingReviewDetails: showingReviewDetails!,
    showingUserCentricPanel: showingUserCentricPanel!,
    showingDecisionDetails: showingDecisionDetails!,
    showingBulkDecisionDialog: showingBulkDecisionDialog!,
    showingReviewDecisionsDialog: showingReviewDecisionsDialog!,
    showingResetDecisions: showingResetDecisions!,
    showingAcceptRecommendations: showingAcceptRecommendations!,
    isSubmitting: submitting,
    bulkDecisionType,
    decisionsCriteria,
    features: state.app.features.isEnabled,
  };
};

/**
 * Maps the dispatch actions to the props for the AccessReviewDecisionsList control.
 * @param dispatch Dispatches actions to the List
 * @returns The mapped actions that along with the mapped properties
 * becomes the props (IAccessReviewDecisionsListProps) for the AccessReviewDecisionsList control
 */
const mapDispatchToProps = (
  dispatch: Dispatch<IRootEntitlementsState>
): IAccessReviewDecisionsListActions => {
  const showReviewDecisionsFilter = createAction<boolean>(
    EntitlementActions.showReviewDecisionsFilter,
    dispatch
  );

  const showReviewDecisionsDialog = createAction<boolean>(
    EntitlementActions.showReviewDecisionsDialog,
    dispatch
  );

  const showReviewDetails = createAction<boolean>(
    EntitlementActions.showReviewDetails,
    dispatch
  );
  const showUserCentricPanel = createAction<boolean>(
    EntitlementActions.showUserCentricPanel,
    dispatch
  );
  const showDecisionDetails = createAction<boolean>(
    EntitlementActions.showDecisionDetails,
    dispatch
  );
  const showConfirmDialog = createAction<boolean>(
    EntitlementActions.showConfirmDialog,
    dispatch
  );
  const showBulkDecisionDialog = createAction<{
    isShowing: boolean;
    decisionType?: string;
  }>(EntitlementActions.showBulkDecisionDialog, dispatch);
  const showResetDecisions = createAction<boolean>(
    EntitlementActions.showResetDecisions,
    dispatch
  );
  const showAcceptRecommendations = createAction<boolean>(
    EntitlementActions.showAcceptRecommendations,
    dispatch
  );
  const setSortedByColumnAction = createAction<{
    column: IListColumn<IAccessReviewDecision>;
    entityType: string;
  }>(EntitlementActions.setSortedByColumn, dispatch);
  const getEntitiesAction = createAction<{ entityType: string, id?: string }>(
    EntitlementActions.getEntities,
    dispatch
  );
  const sortEntitiesAction = createAction<{
    entityType: string;
    orderby: string;
    isAscending: boolean;
  }>(EntitlementActions.sortEntities, dispatch);
  const sortFilteredEntitiesAction = createAction<{
    entityType: string;
    orderby: string;
    isAscending: boolean;
    searchTerm: string;
    filterContext: IFilter;
  }>(EntitlementActions.sortFilteredEntities, dispatch);
  const filterEntitiesOnServerAction = createAction<{
    entityType: string;
    filterContext: IFilter | undefined;
  }>(EntitlementActions.filterEntitiesOnServer, dispatch);
  const refreshEntitiesAction = createAction<{ entityType: string, id?: string }>(
    EntitlementActions.refreshEntities,
    dispatch
  );
  const getEntity = createAction<{
    entityId: string;
    entityType: string;
  }>(EntitlementActions.getEntity, dispatch);
  const getEntities = createAction<{
    entityType: string;
    id?: string;
  }>(EntitlementActions.getEntities, dispatch);
  const getSummary = createAction<{
    entityId: string;
  }>(EntitlementActions.getDecisionsSummary, dispatch);
  const getHistory = createAction<{
    entityId: string;
    secondaryId: string;
  }>(EntitlementActions.getDecisionHistory, dispatch);
  const getSecondary = createAction<string>(EntitlementActions.getSecondaryDecisions, dispatch);
  const searchForMoreAction = createAction<{ entityType: string }>(
    EntitlementActions.searchForMore,
    dispatch
  );
  return {
    submitDecision: createAction<ISubmitDecision[]>(
      EntitlementActions.submitDecision,
      dispatch
    ),
    submitAllDecisions: createAction<ISubmitDecision>(
      EntitlementActions.submitAllDecisions,
      dispatch
    ),
    getEntities: (type: string, id?: string) =>
      getEntitiesAction({
        entityType: type,
        id
      }),
    getCurrentReview: () =>
      getEntity({
        entityId: '',
        entityType: EntityType.accessReviews
      }),
    getDecisionsCriteria: () => {
      getEntity({
        entityId: '',
        entityType: EntityType.decisionsCriteria
      });
    },
    getDecisionsSummary: (reviewId: string) =>
      getSummary({
        entityId: reviewId
      }),
    getDecisionHistory: (reviewId: string, decisionId: string) => {
      getHistory({
        entityId: reviewId,
        secondaryId: decisionId
      });
    },
    getSecondaryDecisions: (principalId: string) => {
      getSecondary({
        principalId
      });
    },
    getReviewDetails: () =>
      getEntitiesAction({
        entityType: EntityType.accessReviews
      }),
    searchForMore: () =>
      searchForMoreAction({ entityType: EntityType.accessReviewDecisions }),
    filterEntitiesOnServer: (filter: IFilter | undefined) =>
      filterEntitiesOnServerAction({
        entityType: EntityType.accessReviewDecisions,
        filterContext: filter
      }),
    showReviewDecisionsFilter: () => showReviewDecisionsFilter(true),
    dismissReviewDecisionsFilter: () => showReviewDecisionsFilter(false),
    showReviewDetails: () => showReviewDetails(true),
    dismissReviewDetails: () => showReviewDetails(false),
    showUserCentricPanel: () => showUserCentricPanel(true),
    dismissUserCentricPanel: () => showUserCentricPanel(false),
    showDecisionDetails: () => showDecisionDetails(true),
    dismissDecisionDetails: () => showDecisionDetails(false),
    showConfirmDialog: () => showConfirmDialog(true),
    dismissConfirmDialog: () => showConfirmDialog(false),
    showBulkDecisionDialog: (isShowing: boolean, decisionType?: string) =>
      showBulkDecisionDialog({
        isShowing,
        decisionType
      }),
    showReviewDecisionsDialog: () => showReviewDecisionsDialog(true),
    dismissReviewDecisionsDialog: () => showReviewDecisionsDialog(false),
    dismissBulkDecisionDialog: () =>
      showBulkDecisionDialog({ isShowing: false }),
    showResetDecisions: () => showResetDecisions(true),
    dismissResetDecisions: () => showResetDecisions(false),
    showAcceptRecommendations: () => showAcceptRecommendations(true),
    dismissAcceptRecommendations: () => showAcceptRecommendations(false),
    setSearchContext: createAction<string>(
      EntitlementActions.setSearchContext,
      dispatch
    ),
    setSortedByColumn: (column: IListColumn<IAccessReviewDecision>) =>
      setSortedByColumnAction({
        column,
        entityType: EntityType.accessReviewDecisions
      }),
    sortEntities: (orderby: string, isAscending: boolean) =>
      sortEntitiesAction({
        entityType: EntityType.accessReviewDecisions,
        orderby,
        isAscending
      }),
    sortFilteredEntities: (
      orderby: string,
      isAscending: boolean,
      searchTerm: string,
      filterContext: IFilter
    ) =>
      sortFilteredEntitiesAction({
        entityType: EntityType.accessReviewDecisions,
        orderby,
        isAscending,
        searchTerm,
        filterContext
      }),
    refreshReviewDecisions: (type: string, id: string) =>
      refreshEntitiesAction({ entityType: EntityType.accessReviewDecisions, id }),
    showFiltersIcon: createAction<boolean>(
      EntitlementActions.showSearchFiltersIcon,
      dispatch
    )
  };
};

/**
 * A redux connected AccessReviewDecisionsList control.
 * @description Supports translation
 */
export const ConnectedAccessReviewDecisionsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('MyAccess')(AccessReviewDecisionsList));
