import '@microsoft/portal-app/lib/styling/patterns/Dialog.scss';
import { AppPanel } from '@uifabric/portal-ux/lib/AppPanel';

import {
  IObjectWithKey,
  PanelType,
  Selection,
  SelectionMode
} from '@fluentui/react';
import { withResponsiveMode } from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import * as React from 'react';
import { IKeyValuePair } from '../../../models/ELM/IKeyValuePair';
import { DecisionFilterType } from '../../../models/RequestApprovals/DecisionType';
import { RecommendationFilterType } from '../../../models/RequestApprovals/RecommendationType';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { FilterList } from '../../Shared/FilterList/FilterList';
import {
  IDecisionsFilterProps,
  IDecisionsFilterState
} from './DecisionsFilter.types';

@withResponsiveMode
export class DecisionsFilter extends React.Component<
IDecisionsFilterProps,
IDecisionsFilterState
> {
  private _hasMounted: boolean;
  private _recommendationSelection: Selection;
  private _recommendationOptions: IKeyValuePair[];
  private _decisionSelection: Selection;
  private _decisionOptions: IKeyValuePair[];

  constructor(nextProps: IDecisionsFilterProps) {
    super(nextProps);

    this._hasMounted = false;

    this._recommendationOptions = this._initSelectionOptions(RecommendationFilterType);
    this._recommendationSelection = this._initSelection(
      this._recommendationOptions,
      this.props.selectedRecommendations
    );

    this._decisionOptions = this._initSelectionOptions(DecisionFilterType);
    this._decisionSelection = this._initSelection(
      this._decisionOptions,
      this.props.selectedDecisions
    );
  }

  public componentDidMount(): void {
    this._hasMounted = true;
  }

  public render(): JSX.Element {
    const { hidden, onDismiss, t } = this.props;
    return (
      <AppPanel
        isBlocking={false}
        isOpen={!hidden}
        onDismiss={onDismiss}
        type={PanelType.smallFixedFar}
        headerText={t(LocaleKeys.filter, {
          context: 'capitalize',
          count: 0
        })}
        closeButtonAriaLabel={t(LocaleKeys.cancel)}
        hasCloseButton={true}
      >
        <FilterList
          t={t}
          selection={this._recommendationSelection}
          options={this._recommendationOptions}
          localizedLabel={t(LocaleKeys.recommendation, { context: 'capitalize' })}
          translateOptions={true}
        />
        <FilterList
          t={t}
          selection={this._decisionSelection}
          options={this._decisionOptions}
          localizedLabel={t(LocaleKeys.decision)}
          translateOptions={true}
        />
      </AppPanel>
    );
  }

  // tslint:disable-next-line:no-any
  private _initSelectionOptions(enums: any): IKeyValuePair[] {
    const list = Object.keys(enums).filter(
      // tslint:disable-next-line:no-any
      (k: any) => typeof enums[k] === 'string'
    ) as string[];
    return list.map((item: string) => {
      const itemWithKey: IKeyValuePair = {
        key: item,
        value: enums[item]
      };
      return itemWithKey;
    });
  }

  private _initSelection(
    options: IObjectWithKey[],
    selectedItems: string[] | undefined
  ): Selection {
    let selection = new Selection({
      onSelectionChanged: this._onSelectionChanged,
      selectionMode: SelectionMode.multiple
    });

    selection.setItems(options);
    if (selectedItems === undefined) {
      selection.setAllSelected(false);
    } else {
      selectedItems.forEach((item: string) => {
        selection.setKeySelected(item, true, true);
      });
    }

    return selection;
  }

  private _onSelectionChanged = (): void => {
    if (this._hasMounted) {
      this.forceUpdate();
      this._applyFilters();
    }
  }

  private _applyFilters = (): void => {
    const selectedRecommendations: string[] = Array.from(
      this._recommendationSelection.getSelection(),
      (state: IObjectWithKey) => state.key!.toString()
    );
    const selectedDecisions: string[] = Array.from(
      this._decisionSelection.getSelection(),
      (state: IObjectWithKey) => state.key!.toString()
    );

    const filter = {
      selectedRecommendations: selectedRecommendations as string[],
      selectedDecisions: selectedDecisions as string[]
    };

    this.props.filterEntitiesOnServer(filter);
  }
}
