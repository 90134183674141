import { InjectedTranslateProps } from 'react-i18next';

import { IGrantRequest } from '../../../../models';
import { IVerifiableCredentialRequirementStatus } from '../../../../models/ELM/IVerifiableCredentialStatus';

/**
 * The verifiable credential status polling interval in milliseconds
 */
export const STATUS_POLL_INTERVAL = 3000; // ms

/**
 * The veriifable credential time left before expiry in which to stop polling
 */
export const STATUS_POLL_CUTOFF_MS = STATUS_POLL_INTERVAL * 2; // ms

/**
 * The props for the VerifyCredentialPresentation control.
 */
export interface IVerifyCredentialPresentationProps
  extends IVerifyCredentialPresentationActions,
    IVerifyCredentialPresentationMappedProps,
    InjectedTranslateProps,
    IVerifyCredentialPresentationOwnProps {}

export interface IVerifyCredentialPresentationOwnProps {
  usingModal?: boolean;
}

/**
 * Used as a standalone interface by mapStateToProps. Defines non-action properties
 */
export interface IVerifyCredentialPresentationMappedProps {
  status?: IVerifiableCredentialRequirementStatus;
  pollingForStatus: boolean;
  grantRequest: Partial<IGrantRequest>;
  navigateTo: (url: string) => void;
}

/**
 * Used as a standalone interface by mapDispatchToProps. Defines action properties
 */
export interface IVerifyCredentialPresentationActions {
  pollStatus: (grantRequest: Partial<IGrantRequest>) => void;
  deleteVerifiableCredentialPresentation: (policyId: string) => void;
}

/**
 * Represents the internal state of VerifyCredentialPresentation control.
 */
export interface IVerifyCredentialPresentationState {
  url?: string;
  qrCode?: string;
  loadingQrCode: boolean;
  expiry?: Date;
  estimatedExpiry: number;
  scanned: boolean;
  showingCantScanMessage: boolean;
  pollIntervalHandle?: NodeJS.Timer;
}
