/* Represent the values to be shown in Status column in Request History list. */
export enum RequestState {
  Delivering = 'delivering',
  PendingApproval = 'pendingApproval_capitalize',
  Denied = 'denied',
  Scheduled = 'scheduled',
  Delivered = 'delivered',
  DeliveryFailed = 'deliveryFailed',
  Canceled = 'canceled',
  PartiallyDelivered = 'partiallyDelivered',
  Submitted = 'submitted'
}

export enum RequestedFor {
  Others = 'requestedForOthers',
  Target = 'requestedForYou'
}

export const isRequestStateEnded = (state: RequestState): boolean => {
  switch (state) {
    case RequestState.Delivered:
    case RequestState.DeliveryFailed:
    case RequestState.Denied:
    case RequestState.Canceled:
      return true;
    default:
      return false;
  }
};

export const isRequestStateCancellable = (state: RequestState): boolean => {
  // This is to be consistent with portal behavior
  switch (state) {
    case RequestState.Submitted:
    case RequestState.PendingApproval:
    case RequestState.Scheduled:
      return true;
    default:
      return false;
  }
};