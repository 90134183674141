import { OsTypeEnum } from '../../../models/RAM/OsTypeEnum';
import { ConnectionTypeEnum } from './Connection.types';

export const KEY_PATH_PLACEHOLDER: string = '~/.ssh/<keyname>.pem';
export const KEY_PATH_CHMOD_PLACEHOLDER: string = 'chmod 400 ~/.ssh/<keyname>.pem';
export const rdpTroubleshootUrl =
  'https://learn.microsoft.com/en-us/azure/virtual-machines/linux/use-remote-desktop?tabs=azure-cli';
export const generalTroubleshootUrl = (virtualMachineId: string): string =>
  `https://portal.azure.com/#view/Microsoft_Azure_Support/TroubleshootV2Blade/assetId/${virtualMachineId}/metadata`;
export const sshWindowsLearnMoreLink =
  'https://learn.microsoft.com/en-us/azure/virtual-machines/windows/connect-ssh?tabs=azurecli';
export const windowsEntraLoginLink =
  'https://learn.microsoft.com/en-us/entra/identity/devices/howto-vm-sign-in-azure-ad-windows';
export const linuxEntraLoginLink =
  'https://learn.microsoft.com/en-us/entra/identity/devices/howto-vm-sign-in-azure-ad-linux';
export const deallocatedVmLink =
  'https://learn.microsoft.com/en-us/troubleshoot/azure/virtual-machines/allocation-failure';

const RDP_DEFAULT_PORT = '3389';
const createRdpFileData = (ipAddress: string, port: string): string => {
  return `full address:s:${ipAddress}:${port}
    prompt for credentials:i:1
    administrative session:i:1`;
};

export const onDownloadRdpFile = (vmName: string, ipAddress: string, port: string = RDP_DEFAULT_PORT): void => {
  const rdpFile = createRdpFileData(ipAddress, port);
  const hiddenDownloadButton = document.createElement('a');
  hiddenDownloadButton.id = 'CreateRDPFileDownloadButton';
  const file = new Blob([rdpFile], { type: 'text/plain' });
  hiddenDownloadButton.href = URL.createObjectURL(file);
  hiddenDownloadButton.download = `${vmName}.rdp`;
  document.body.appendChild(hiddenDownloadButton); // Required for this to work in FireFox
  hiddenDownloadButton.click();
  document.body.removeChild(hiddenDownloadButton);
};

export enum SshConnectionOption {
  EntraId = 0,
  Client = 1,
  AzureCli = 2,
  Password = 3
}

export const formatConnectionDateTime = (date: Date): string => {
  const time = date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', timeZoneName: 'short' });
  const dateString = date.toLocaleDateString();
  return `${time} ${dateString}`;
};

export const getSelectedConnectionPivot = (osType: OsTypeEnum | undefined): ConnectionTypeEnum | undefined => {
  if (osType === OsTypeEnum.Linux) {
    return ConnectionTypeEnum.Ssh;
  } else if (osType === OsTypeEnum.Windows) {
    return ConnectionTypeEnum.Rdp;
  }
  return undefined;
};
