import {
  css,
  DefaultButton,
  IconButton,
  Image,
  Link,
  Panel,
  PanelType,
  Persona,
  PersonaSize,
  Pivot,
  PivotItem,
  PrimaryButton,
  Text,
  MessageBar,
} from '@fluentui/react';
import React, { useState } from 'react';
import { IGrantPolicy } from '../../../models/ELM/IGrantPolicy';

import { useTelemetryWithMetadata, useTranslation } from '../../../hooks';
import { IEntitlement, IRoleAssignment, OriginSystem } from '../../../models';
import { getSpinner, LocaleKeys } from '../../../shared';
import { TruncatedText } from '../../Shared/';

const styles = require('./EntitlementDetailsPanel.scoped.scss');

const PivotKeys = {
  Resources: 'resource',
  Activity: 'activity',
} as const;

export interface IEntitlementDetailsPanelProps {
  isVisible: boolean;
  entitlement?: IEntitlement;
  onDismiss?: () => void;
  earlyPolicyLoadingEnabled: boolean;
  getPolicies: (entitlementId: string) => void;
  hideResourcesWithMessage?: string;
  messageImageSrc?: string;
  policiesLoading: boolean;
  hasValidationErrors: boolean;
  policies: IGrantPolicy[];
  buttons: {
    leftText: string;
    rightText: string;
    leftIsPrimary: boolean;
    onLeftClick: () => void;
    onRightClick: () => void;
  };
}

export function EntitlementDetailsPanel(props: IEntitlementDetailsPanelProps) {
  const {reportCustomEventWithMetadata} = useTelemetryWithMetadata();
  const t = useTranslation();

  if (!props.entitlement) {
    return null;
  }
  if (props.earlyPolicyLoadingEnabled &&
    props.policies.length === 0 &&
    !props.policiesLoading &&
    !props.hasValidationErrors &&
    props.isVisible) {
    props.getPolicies(props.entitlement.id);
  }

  function onOpen() {
    reportCustomEventWithMetadata('entitlement-details-panel/open');
  }

  function onDismiss() {
    reportCustomEventWithMetadata('entitlement-details-panel/close');
    if (props.onDismiss) {
      props.onDismiss();
    }
  }

  const displayVCWarning = props.earlyPolicyLoadingEnabled && props.policies
    .map((policy) => policy.verifiableCredentialRequirementStatus !== undefined && policy.verifiableCredentialRequirementStatus !== null)
    .reduce((aggregate, containsVc) => aggregate || containsVc, false);

  return (
    <Panel
      isOpen={props.isVisible}
      hasCloseButton={false}
      isBlocking={true}
      isFooterAtBottom={true}
      type={PanelType.smallFixedFar}
      onOpen={onOpen}
      isLightDismiss={true}
      onDismiss={onDismiss}
      onRenderHeader={() => (
        <Header entitlement={props.entitlement} onDismiss={onDismiss} />
      )}
      onRenderFooterContent={() => <Footer buttons={props.buttons} />}
      className={css(styles.root)}
    >
      {props.hideResourcesWithMessage ? (
        <ErrorMessage
          message={props.hideResourcesWithMessage}
          imgSrc={props.messageImageSrc}
        />
      ) : props.earlyPolicyLoadingEnabled && props.policiesLoading ? (
        <div className={css(styles.policiesLoading)}>
          {getSpinner(t(LocaleKeys.loading))}
        </div>
      ) : (
        <div>
          {displayVCWarning ? (
            <MessageBar>
              {t(LocaleKeys.verifiableCredentialExplainer)}
            </MessageBar>
          ) : null}
          < Pivot
            defaultSelectedKey={PivotKeys.Resources}
            className={css(styles.pivot)}
          >
            <PivotItem
              headerText={t(LocaleKeys.resource, {
                context: 'panelHeader',
                count: props.entitlement!.accessPackageResourceRoleScopes!.length,
              })}
              itemKey={PivotKeys.Resources}
              className={styles.pivotItem}
            >
              <ResourcesPanel entitlement={props.entitlement!} />
            </PivotItem>
          </Pivot>
        </div>
      )
      }
    </Panel >
  );
}

function ErrorMessage({
  message,
  imgSrc,
}: {
  message: string;
  imgSrc?: string;
}) {
  return (
    <div className={css(styles.messageContainer)}>
      {imgSrc && <Image className={css(styles.messageImage)} src={imgSrc} />}
      <Text className={css(styles.messageText)}>{message}</Text>
    </div>
  );
}

function Header({
  entitlement,
  onDismiss,
}: Pick<IEntitlementDetailsPanelProps, 'entitlement' | 'onDismiss'>) {
  const t = useTranslation();

  return (
    <div className={css(styles.header)}>
      <div className={css(styles.headerTop)}>
        <TruncatedText>
          <h3 className={css(styles.headerTitle)}>
            {entitlement!.displayName}
          </h3>
        </TruncatedText>
        <IconButton
          className={css(styles.headerCloseButton)}
          iconProps={{ iconName: 'Cancel' }}
          ariaLabel={t(LocaleKeys.cancel)}
          onClick={onDismiss}
        />
      </div>

      <TruncatedText>
        <Text className={css(styles.headerDescription)}>
          {entitlement!.description}
        </Text>
      </TruncatedText>
    </div>
  );
}

function Footer({ buttons }: Pick<IEntitlementDetailsPanelProps, 'buttons'>) {
  const {reportCustomEventWithMetadata} = useTelemetryWithMetadata();

  return (
    <div className={css(styles.footer)}>
      {buttons.leftIsPrimary ? (
        <PrimaryButton
          ariaLabel={buttons.leftText}
          text={buttons.leftText}
          onClick={() => {
            reportCustomEventWithMetadata('entitlement-details-panel/click-left-button');
            buttons.onLeftClick();
          }}
          className={css(styles.footerButton)}
        />
      ) : (
        <DefaultButton
          ariaLabel={buttons.leftText}
          text={buttons.leftText}
          onClick={() => {
            reportCustomEventWithMetadata('entitlement-details-panel/click-left-button');
            buttons.onLeftClick();
          }}
          className={css(styles.footerButton, styles.secondaryFooterButton)}
        />
      )}
      <DefaultButton
        text={buttons.rightText}
        ariaLabel={buttons.rightText}
        onClick={() => {
          reportCustomEventWithMetadata('entitlement-details-panel/click-right-button');
          buttons.onRightClick();
        }}
        className={css(styles.footerButton, styles.secondaryFooterButton)}
      />
    </div>
  );
}
function ResourcesPanel({
  entitlement,
}: Pick<IEntitlementDetailsPanelProps, 'entitlement'>) {
  const resources = getResources(entitlement!);

  return (
    <div>
      {Object.values(OriginSystem)
        .filter((originSystem) => originSystem.toLowerCase() in resources)
        .map((originSystem) => {
          const roleScopes = resources[originSystem.toLowerCase()];

          return (
            <ResourceList
              key={originSystem}
              originSystem={originSystem}
              roleScopes={roleScopes}
            />
          );
        })}
    </div>
  );
}

/**
 * The component representing a list of resources for a given origin system.
 */
function ResourceList({
  roleScopes,
  originSystem,
}: {
  roleScopes: IRoleAssignment[];
  originSystem: string;
}) {
  const t = useTranslation();
  const [collapsed, setCollapsed] = useState(false);

  const headerText = t(originSystem, {
    context: 'panelHeader',
    count: roleScopes.length,
  });

  return (
    <div className={css(styles.resourceList)}>
      <div>
        <span>
          <IconButton
            iconProps={{ iconName: collapsed ? 'ChevronRight' : 'ChevronDown' }}
            onClick={() => setCollapsed(!collapsed)}
            toggle={true}
            className={styles.resourceDropdownIcon}
            ariaDescription={t(LocaleKeys.toggleCollapseOriginSystemAria, {
              originSystem: t(originSystem),
            })}
          />
          <Text className={css(styles.resourceDropdownText)}>{headerText}</Text>
        </span>

        {collapsed
          ? null
          : roleScopes.map((roleScope) => {
            const { accessPackageResource: resource } =
              roleScope.accessPackageResourceScope;

            return (
              <div key={resource.id} className={css(styles.resourceListItem)}>
                <ResourceCard roleScope={roleScope} />
              </div>
            );
          })}
      </div>
    </div>
  );
}

function ResourceCard({ roleScope }: { roleScope: IRoleAssignment }) {
  const t = useTranslation();
  const { reportCustomEventWithMetadata } = useTelemetryWithMetadata();

  const { accessPackageResource: resource } =
    roleScope.accessPackageResourceScope;

  const openable = (roleScope.status as string) === 'Fulfilled' && resource.url;

  function onOpenResource(): void {
    reportCustomEventWithMetadata('entitlement-details-panel/open-resource', {
      originSystem: roleScope.accessPackageResourceRole.originSystem,
    });
  }

  return (
    <div className={styles.resourceCard}>
      <Persona
        text={resource.displayName}
        size={PersonaSize.size32}
        className={styles.persona}
        onRenderPrimaryText={() => {
          return (
            <div className={styles.cardContent}>
              {openable ? (
                <TruncatedText>
                  <Link
                    className={css('ms-pii', styles.displayName)}
                    target="_blank"
                    onClick={onOpenResource}
                    href={resource.url}
                    aria-description={t(LocaleKeys.openResourceAria, {
                      resourceName: resource.displayName,
                    })}
                  >
                    {resource.displayName}
                  </Link>
                </TruncatedText>
              ) : (
                <TruncatedText>
                  <Text className={css('ms-pii', styles.displayName)}>
                    {resource.displayName}
                  </Text>
                </TruncatedText>
              )}

              {resource.description && (
                <TruncatedText>
                  <Text className={css('ms-pii', styles.description)}>
                    {resource.description}
                  </Text>
                </TruncatedText>
              )}

              <TruncatedText>
                <Text className={css('ms-pii', styles.assignedRole)}>
                  {t(LocaleKeys.roleAssignedWithRole, {
                    role: roleScope.accessPackageResourceRole.displayName,
                  })}
                </Text>
              </TruncatedText>
            </div>
          );
        }}
      />
    </div>
  );
}

/**
 * Gets the resources grouped by origin system.
 * @param entitlement the entitlement to get the resources for.
 * @returns the RoleScopes grouped by origin system. The key is returned in lowercase.
 */
function getResources(entitlement: IEntitlement): {
  [key: string]: IRoleAssignment[];
} {
  return entitlement.accessPackageResourceRoleScopes!.reduce(
    (accum, roleScope: IRoleAssignment) => {
      const originSystem =
        roleScope.accessPackageResourceRole.originSystem.toLowerCase();

      const roleScopes: IRoleAssignment[] =
        originSystem in accum ? accum[originSystem] : [];
      roleScopes.push(roleScope);

      return {
        ...accum,
        [originSystem]: roleScopes,
      };
    },
    {}
  );
}
