import { MessageBar, MessageBarType } from '@fluentui/react';
import React, { useEffect, useState } from 'react';

interface ITimedMessageBarProps {
  message: string;
  onDismiss?: () => void;
}
const infoIcon = { iconName: 'Info' };
const timedDuration = 1000 * 10;

export const TimedMessageBar: React.FunctionComponent<ITimedMessageBarProps> = (props: ITimedMessageBarProps) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, timedDuration);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!visible && props.onDismiss) {
      props.onDismiss();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const handleDismiss = (): void => {
    setVisible(false);
    if (props.onDismiss) {
      props.onDismiss();
    }
  };

  return (
    <>
      {visible && (
        <MessageBar
          messageBarType={MessageBarType.error}
          messageBarIconProps={infoIcon}
          isMultiline={false}
          styles={{
            root: {
              position: 'fixed',
              bottom: '20px',
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 1000,
              width: 'auto'
            }
          }}
          onDismiss={handleDismiss}
        >
          {props.message}
        </MessageBar>
      )}
    </>
  );
};
