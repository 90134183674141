import { IAjaxRequest } from '@microsoft/portal-app/lib/auth/withAuth';
import { notificationsMerge } from '@microsoft/portal-app/lib/Notifications/helpers/notificationsMerge';
import {
  INotification,
  NotificationSeverity,
  NotificationType,
} from '@microsoft/portal-app/lib/Notifications/models/INotification';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { errorHandler } from '@microsoft/portal-app/lib/redux/observableErrorHandler';
import { TranslationOptions } from 'i18next';
import * as moment from 'moment';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import 'rxjs/add/observable/concat';
import { Observable } from 'rxjs/Observable';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';
import { catchError, flatMap, mergeMap } from 'rxjs/operators';
import { v4 as guid } from 'uuid';
import { IGrantRequest } from '../../../models/ELM/IGrantRequest';
import { IValidationError } from '../../../models/ELM/IValidationError';
import {
  getSimpleRequestTypeFromRequestType,
  RequestType,
} from '../../../models/ELM/RequestType';

import {
  EntitlementActions,
  EntityType,
  // EntityType,
  IEntitlementAction,
  IEntitlementState,
  IRootEntitlementsState,
} from '../../../models';

import { getELMBaseUrlBasedOnEnv } from '../../../shared/getApiUrl';

import { getAudience } from '../../../shared/AttachAudience';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { registry } from '../myAccessRegistry';
import { IGrantPolicy } from '../../../models/ELM/IGrantPolicy';

export const addGrantRequestEpic: Epic<
  IEntitlementAction<AnyPayload>,
  IRootEntitlementsState
> = (
  action$: ActionsObservable<
    IEntitlementAction<{
      newGrantRequest: Partial<IGrantRequest>;
      entitlementName: string;
    }>
  >,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
    return action$.ofType(EntitlementActions.addGrantRequest).pipe(
      // tslint:disable-next-line:no-any
      mergeMap((action: IEntitlementAction<any>) => {
        const payload = action.payload!;
        const grantRequest = payload && payload.newGrantRequest;
        const entitlementName = payload && payload.entitlementName;
        const entitlementId = grantRequest && grantRequest.accessPackageAssignment?.accessPackageId;

        const correlationId = guid();
        const ajaxRequest: IAjaxRequest = {
          method: 'POST',
          url: getELMBaseUrlBasedOnEnv() + 'accessPackageAssignmentRequests',
          body: grantRequest,
          audience: getAudience(),
          headers: {
            'x-ms-client-request-id': correlationId,
          },
        };

        const addSucceededAction = {
          type: EntitlementActions.addGrantRequestSucceeded,
          payload: {
            isValidationOnly: grantRequest.isValidationOnly,
            requestType: grantRequest.requestType,
            entitlementName: entitlementName,
            entitlementId: entitlementId
          },
        };

        const refreshApprovalHistoryAction = {
          type: EntitlementActions.refreshEntities,
          payload: {
            entityType: EntityType.completedGrantRequests,
          },
        };

        return ajax(ajaxRequest).pipe(
          flatMap(() => {
            if (
              (grantRequest.requestType === RequestType.UserAdd ||
                grantRequest.requestType === RequestType.UserExtend) &&
              !grantRequest.isValidationOnly
            ) {

              return Observable.concat(
                // Remove unnecessary refresh query
                // Observable.of(refreshEntitiesAction),
                Observable.of(addSucceededAction)
              );
            }

            if (grantRequest.requestType === RequestType.ApproverRemove) {
              return Observable.concat(
                Observable.of(refreshApprovalHistoryAction),
                Observable.of(addSucceededAction)
              );
            }

            return Observable.of(addSucceededAction);
          }),
          catchError(
            errorHandler<IEntitlementAction>(
              EntitlementActions.addGrantRequestFailed
            )
          )
        );
      })
    );
  };
registry.addEpic('addGrantRequestEpic', addGrantRequestEpic);

export const addGrantRequest = (
  state: IEntitlementState,
  action: IEntitlementAction<{
    newGrantRequest: Partial<IGrantRequest>;
    entitlementName: string;
  }>
): Readonly<IEntitlementState> => {
  const payload = action.payload!;
  const grantRequest = payload && payload.newGrantRequest;
  if (grantRequest.isValidationOnly) {
    return {
      ...state,
      validating: true,
      validationErrors: [],
    };
  }

  return {
    ...state,
    submitting: true,
  };
};
registry.add(EntitlementActions.addGrantRequest, addGrantRequest);

export const addGrantRequestSucceeded = (
  state: IEntitlementState,
  // tslint:disable-next-line
  action: IEntitlementAction<Readonly<any>>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  const requestType = action.payload!.requestType;
  const requestAction = getSimpleRequestTypeFromRequestType(requestType);

  if (requestType !== RequestType.ApproverRemove) {
    if (state.validating) {
      return {
        ...state,
        validating: false,
        showingAddGrantRequest: true,
        validationErrors: [],
      };
    }
  }

  const entitlementName = action.payload.entitlementName;

  const toastKey = requestType == RequestType.ApproverRemove ? 'approverRemoveAccessSucceededMessage' : `${requestAction.toLowerCase()}AccessSucceededMessage`;
  const toastOptions: TranslationOptions = {
    requestAction: requestAction.toLowerCase(),
    entitlementName: entitlementName,
  };

  const entitlementId = action.payload.entitlementId;
  const mappedPolicies = state.policyAssignments.get(entitlementId);

  // Remove cached policies of this entitlement
  const entitiesByIdCopy = Array.from(state.policies.entitiesById);
  const newEntitiesById = new Map<string, IGrantPolicy>(entitiesByIdCopy.filter(p => !mappedPolicies?.includes(p[0])));
  // Remove entitlementId from the mapping
  const newAssignmentMap = new Map<string, string[]>(Array.from(state.policyAssignments).filter(p => p[0] !== entitlementId));

  const notification = {
    localizableTitle: {
      key: `${requestAction}AccessSucceeded`,
      options: toastOptions,
    },
    localizableMessage: {
      key: toastKey,
      options: toastOptions,
    },
    createdDateTime: moment(),
    severity: NotificationSeverity.success,
  };

  let notifications: INotification[] = [
    {
      ...notification,
      id: guid(),
      type: NotificationType.card,
    },
    {
      ...notification,
      id: guid(),
      type: NotificationType.toast,
    },
  ];

  return {
    ...state,
    policies: {
      ...state.policies,
      isLoading: false,
      entitiesById: newEntitiesById,
    },
    policyAssignments: newAssignmentMap,
    // TODO: change logic here back to enable message bar later
    notifications: notificationsMerge(
      notifications,
      state.notifications,
      state.notificationsLimit
    ),
    partialGrantRequest: undefined,
    showingAddGrantRequest: false,
    showingConfirmDialog: false,
    showingBulkActionDialog: false,
    showMessageBar: false,
    submitting: false,
  };
};
registry.add(
  EntitlementActions.addGrantRequestSucceeded,
  addGrantRequestSucceeded
);

export const addGrantRequestFailed = (
  state: IEntitlementState,
  action: IEntitlementAction<Readonly<AnyPayload>>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  const payload = action.payload;
  let error =
    payload.response &&
    payload.response.error &&
    payload.response.error.message;

  let validationErrors: IValidationError[] = [];
  try {
    validationErrors = JSON.parse(error) as IValidationError[];
  } catch (e) {
    validationErrors = [];
  }

  //  need to check direct error object as well:
  error =
    payload.response &&
    payload.response.error;
  if (error) {
    validationErrors.push({
      Code: error.code || error.Code,
      Detail: error.message || error.Message,
    } as IValidationError);
  }

  if (!state.showingBulkActionDialog) {
    if (state.validating || state.features.isEnabled.enableRequestGrantRefresh) {
      let showingAddGrantRequest = !state.features.isEnabled.enableRequestGrantRefresh;
      return {
        ...state,
        validating: false,
        submitting: false,
        showingAddGrantRequest,
        validationErrors: validationErrors,
      };
    }
  }

  const errorMessage =
    validationErrors && validationErrors.length > 0
      ? validationErrors[0].Detail
      : LocaleKeys.generalErrorMessage;

  let correlationId =
    payload.request &&
    payload.request.headers &&
    payload.request.headers['x-ms-client-request-id'];

  let toastOptions: TranslationOptions = {
    error: errorMessage,
    correlationId: correlationId,
  };

  const notification = {
    localizableTitle: {
      key: LocaleKeys.somethingWentWrong,
      options: toastOptions,
    },
    localizableMessage: {
      key: LocaleKeys.errorTemplate,
      options: toastOptions,
    },
    createdDateTime: moment(),
    severity: NotificationSeverity.error,
  };

  let notifications: INotification[] = [
    {
      ...notification,
      id: guid(),
      type: NotificationType.card,
    },
    {
      ...notification,
      id: guid(),
      type: NotificationType.toast,
    },
  ];

  return {
    ...state,
    notifications: notificationsMerge(
      notifications,
      state.notifications,
      state.notificationsLimit
    ),
    showingAddGrantRequest: false,
    showingConfirmDialog: false,
    showingBulkActionDialog: false,
    submitting: false,
  };
};
registry.add(EntitlementActions.addGrantRequestFailed, addGrantRequestFailed);
