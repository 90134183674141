import { ConsentForm } from './ConsentForm';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import {IConsentFormMappedProps, IConnectedConsentFormProps} from './ConsentForm.types';
import { IRootEntitlementsState } from '../../../../models/IEntitlementState';

const mapStateToProps = (
  state: IRootEntitlementsState,
  ownProps: IConnectedConsentFormProps
): IConsentFormMappedProps => {

  const tenantContext = state.app.tenantContext!;
  const privacyUrl =
    tenantContext && tenantContext.tenant && tenantContext.tenant.privacyUrl!;
  const tenantDisplayName =
    tenantContext && tenantContext.tenant && tenantContext.tenant.displayName!;

  return {
    ...ownProps,
    privacyUrl: privacyUrl!,
    tenantDisplayName: tenantDisplayName!,
  };
};

export const ConnectedConsentForm = connect(
  mapStateToProps
)(translate('MyAccess')(ConsentForm));