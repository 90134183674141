import { TextInputQuestionType } from '../../shared/constants';
import { IAnswerChoice } from './AnswerChoice';
import { IEntitlementManagementLocalizedContent } from './IEntitlementManagementLocalizedContent';
import { QuestionAnswer } from './QuestionAnswer';

export class TextInputQuestion extends QuestionAnswer {

  public '@odata.type': string = TextInputQuestionType;

  public isSingleLineQuestion: boolean;

  constructor(
    id: string,
    attributeName: string,
    isAttribute: boolean,
    text: IEntitlementManagementLocalizedContent,
    isRequired: boolean,
    sequence: number,
    choices: IAnswerChoice[],
    showAnswerToApprover: boolean,
    isSingleLineQuestion: boolean,
    regexPattern: string
  ) {
    super(id, attributeName, isAttribute, text, isRequired, sequence, choices, showAnswerToApprover);
    this.isSingleLineQuestion = isSingleLineQuestion;
    this.regexPattern = regexPattern;
  }

}
