import { css, TooltipHost } from '@fluentui/react';
import * as React from 'react';

import { ColorClassNames, FontClassNames } from '@fluentui/react';

import { highlightSearch } from '../../../shared/highlightSearch';

const myAccessListStyles = require('../../../css/myAccessList.scoped.scss');

export interface IColumnValueProps {
  isHighlightRequired: boolean;
  isSearching: boolean;
  searchTerm: string;
  columnValue: string;
  maxLen?: number;
  isSecondary?: boolean;
}

export function ColumnValue({
  isHighlightRequired,
  isSearching,
  searchTerm,
  columnValue,
  maxLen,
  isSecondary,
}: IColumnValueProps): JSX.Element {

  const colorClassName = isSecondary
    ? ColorClassNames.neutralSecondary
    : ColorClassNames.neutralPrimary;

  const fontClassName = isSecondary
    ? FontClassNames.smallPlus
    : FontClassNames.medium;

  if (maxLen && columnValue && columnValue.length > maxLen!) {
    return (
      <TooltipHost
        className={css('ms-pii', fontClassName, colorClassName)}
        content={columnValue}
        calloutProps={{ gapSpace: 0 }}
      >
        <span className={css('ms-pii', fontClassName, colorClassName)}>
          {isSearching && isHighlightRequired
            ? highlightSearch(searchTerm, columnValue)
            : columnValue.substring(0, maxLen) + '...'}
        </span>
      </TooltipHost>
    );
  } else {
    return (
      <span
        className={css(
          'ms-pii',
          fontClassName,
          colorClassName,
          myAccessListStyles.columnValue
        )}
      >
        {isSearching && isHighlightRequired
          ? highlightSearch(searchTerm, columnValue)
          : columnValue}
      </span>
    );
  }
}
