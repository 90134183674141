import { TranslationFunction } from 'i18next';
import {
  ColorClassNames,
  FontClassNames,
  FontWeights,
  HoverCard,
  HoverCardType,
  IPlainCardProps,
  Link,
} from '@fluentui/react';
import * as React from 'react';
import { css } from '@fluentui/react';

import { IRoleScope } from '../../../models/ELM/IRoleScope';
import { OriginSystem } from '../../../models/ELM/OriginSystem';
import { asLocalizedText } from '../../../shared/asLocalizedText';
import { highlightSearch } from '../../../shared/highlightSearch';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { IResourceSummaryProps } from './ResourceSummary.types';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');
const ResourceSummaryStyles = require('./ResourceSummary.scoped.scss');

export class ResourceSummary extends React.Component<
  IResourceSummaryProps,
  {}
> {
  public render(): JSX.Element {
    const originSystemList = Object.keys(OriginSystem).filter(
      // tslint:disable-next-line
      (k: any) => typeof OriginSystem[k] === 'string'
    ) as string[];
    const view: JSX.Element[] = [];
    originSystemList.map((resourceType: string) => {
      view.push(
        <div>
          {this._getSummaryViewForResourceType(
            this.props.accessPackageResourceRoleScopes!,
            resourceType,
            this.props.t
          )}
        </div>
      );
    });

    return (
      <div
        className={css(
          this.props.isFlexRow
            ? ResourceSummaryStyles.roleScopeContainer
            : ResourceSummaryStyles.roleScopeContainerFlexColumn
        )}
      >
        {view}
      </div>
    );
  }

  private _getSummaryViewForResourceType(
    roleScopes: IRoleScope[],
    resourceType: string,
    t: TranslationFunction
  ): JSX.Element {
    const filteredRoles = roleScopes.filter(
      (roleScope: IRoleScope) =>
        roleScope.accessPackageResourceRole.originSystem.toLowerCase() ===
        resourceType.toLowerCase()
    );

    if (filteredRoles.length === 0) {
      return <div />;
    }

    const resourcesStats = asLocalizedText(
      {
        key: OriginSystem[resourceType],
        options: {
          count: 0,
        },
      },
      t
    );

    let roleScopesDiv: JSX.Element[] = [];

    filteredRoles.forEach((roleScope: IRoleScope) => {
      const roleScopeDiv = this.props.isFlexRow
        ? this._onRenderResourceDetailInHoverCard(roleScope)
        : this._onRenderResourceDetailsInPlain(roleScope);
      roleScopesDiv.push(roleScopeDiv);
    });

    return (
      <div
        className={css(
          this.props.isFlexRow
            ? ResourceSummaryStyles.resourceCol
            : ResourceSummaryStyles.roleScopeDiv
        )}
      >
        <div
          className={css(
            ResourceSummaryStyles.resourceLabel,
            this.props.isFlexRow
              ? myAccessStyles.marginBottomSmallPlus
              : myAccessStyles.marginBottomSmall,
            myAccessStyles.semiLightText,
            FontClassNames.medium
          )}
        >
          {resourcesStats}
        </div>
        <div>{roleScopesDiv}</div>
      </div>
    );
  }

  private _onRenderResourceDetailsInPlain = (item: IRoleScope): JSX.Element => {
    return (
      <div
        className={css(
          myAccessStyles.marginBottomSmallPlus,
          myAccessStyles.marginTopSmall
        )}
      >
        <div
          className={css(
            FontClassNames.medium,
            myAccessStyles.semiBoldText,
            myAccessStyles.marginBottomSmall
          )}
        >
          <span className={css('ms-pii', myAccessStyles.floatLeft)}>
            {highlightSearch(
              this.props.searchTerm!,
              item.accessPackageResourceScope.accessPackageResource.displayName
            )}
          </span>
          {this.props.canOpenResources.get(item.id) ? (
            <Link
              onClick={this.recordResourceOpenEvent(item)}
              href={item.accessPackageResourceScope.accessPackageResource.url}
              target="_blank"
              className={css(myAccessStyles.floatRight)}
            >
              {this.props.t(LocaleKeys.open)}
            </Link>
          ) : null}
          <div
            // tslint:disable-next-line:jsx-ban-props
            style={{ clear: 'both' }}
          />
        </div>
        <div
          className={css(
            FontClassNames.medium,
            myAccessStyles.marginBottomXMallPlus,
            myAccessStyles.wordBreak,
            'ms-pii'
          )}
        >
          {item.accessPackageResourceScope.accessPackageResource.description}
        </div>
        <div
          className={css(
            myAccessStyles.marginBottomSmall,
            FontClassNames.small,
            FontWeights.regular
          )}
        >
          <span>{this.props.t(LocaleKeys.roleAssigned) + ': '}</span>
          <span className={css(myAccessStyles.semiBoldText, 'ms-pii')}>
            {highlightSearch(
              this.props.searchTerm!,
              item.accessPackageResourceRole.displayName
            )}
          </span>
        </div>
      </div>
    );
  }

  private _onRenderResourceDetailInHoverCard = (item: IRoleScope): JSX.Element => {
    const plainCardProps: IPlainCardProps = {
      onRenderPlainCard: () => this._onRenderResourceHoverCard(item),
      renderData: item,
    };

    return (
      <div className={css(myAccessStyles.marginBottomXSmall)}>
        <HoverCard
          trapFocus={true}
          setInitialFocus={true}
          instantOpenOnClick={true}
          type={HoverCardType.plain}
          plainCardProps={plainCardProps}
        >
          <Link
            data-is-focusable={false}
            className={css(
              FontClassNames.medium,
              'ms-pii',
              myAccessStyles.marginBottomSmall
            )}
          >
            {highlightSearch(
              this.props.searchTerm!,
              item.accessPackageResourceScope.accessPackageResource
                .displayName
            )}
          </Link>
        </HoverCard>
      </div>
    );
  }

  private _onRenderResourceHoverCard = (item: IRoleScope): JSX.Element => {
    return (
      <div
        className={css(
          myAccessStyles.paddingSmallPlus,
          ResourceSummaryStyles.cardDefaultWidth,
          myAccessStyles.wordBreak
        )}
      >
        <div className={css(ResourceSummaryStyles.hoverCardInfo)}>
          <div
            className={css(
              FontClassNames.xLarge,
              myAccessStyles.marginBottomSmallPlus,
              ColorClassNames.neutralPrimary,
              'ms-pii'
            )}
          >
            {item.accessPackageResourceScope.accessPackageResource.displayName}
          </div>
          <div
            className={css(
              myAccessStyles.marginBottomSmallPlus,
              FontClassNames.small,
              ColorClassNames.neutralPrimary,
              'ms-pii'
            )}
          >
            {item.accessPackageResourceScope.accessPackageResource.description}
          </div>
          <div className={css(FontClassNames.small)}>
            <div
              className={css(
                FontWeights.semilight,
                ColorClassNames.neutralSecondary
              )}
            >
              {this.props.t(LocaleKeys.roleAssigned)}
            </div>
            <span
              className={css(
                myAccessStyles.semiBoldText,
                ColorClassNames.neutralPrimary,
                'ms-pii'
              )}
            >
              {item.accessPackageResourceRole.displayName}
            </span>
          </div>
        </div>
        {this.props.canOpenResources.get(item.id) ? (
          <div className={css(ResourceSummaryStyles.hoverCardLink)}>
            <Link
              onClick={this.recordResourceOpenEvent(item)}
              href={item.accessPackageResourceScope.accessPackageResource.url}
              target="_blank"
            >
              {this.props.t(LocaleKeys.open)}
            </Link>
          </div>
        ) : null}
      </div>
    );
  }

  private recordResourceOpenEvent = (item: IRoleScope) => (): void => {
    if (item.accessPackageResourceScope.accessPackageResource.url) {
      this.props.recordBusinessEvent(
        `resource/open/${item.accessPackageResourceScope.originSystem}`
      );
    }
  };
}
