import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { translate } from 'react-i18next';
import { connect, Dispatch } from 'react-redux';
import { EntitlementActions } from '../../../models';
import { IRootEntitlementsState } from '../../../models/IEntitlementState';
import { IDecision } from '../../../models/RequestApprovals/IDecision';
import { PendingApprovalGrantRequestDetails } from './PendingApprovalGrantRequestDetails';
import {
  IConnectedPendingApprovalGrantRequestDetailsProps,
  IPendingApprovalGrantRequestDetailsActions,
  IPendingApprovalGrantRequestDetailsMappedProps
} from './PendingApprovalGrantRequestDetails.types';

/**
 * Maps the external (i.e. own) props and the state to the properties of the GrantRequestDetails control.
 * @param state The current redux state to map to props
 * @param ownProps The external properties to map to props
 * @returns The mapped properties that along with the actions
 * becomes the props (IGrantRequestDetailsProps) for the GrantRequestDetails control
 */
const mapStateToProps = (
  state: IRootEntitlementsState,
  ownProps: IConnectedPendingApprovalGrantRequestDetailsProps
): IPendingApprovalGrantRequestDetailsMappedProps => {
  const submitting = state.app.submitting;
  const isLoading = state.app.isEntityDetailLoading;
  const pendingApprovalGrantRequest = state.app.pendingApprovalGrantRequests.entitiesById.get(
    ownProps.pendingApprovalGrantRequestId!
  )!;
  return {
    ...ownProps,
    isLoading,
    submitting,
    pendingApprovalGrantRequest,
    enableELMRecordDecision: state.app.features.isEnabled.enableELMRecordDecision,
  };
};

/**
 * Maps the dispatch actions to the props for the GrantRequestDetails control.
 * @param dispatch Dispatches actions to the store
 * @returns The mapped actions that along with the mapped properties
 * becomes the props (IGrantRequestDetailsProps) for the GrantRequestDetails control
 */
const mapDispatchToProps = (
  dispatch: Dispatch<IRootEntitlementsState>
): IPendingApprovalGrantRequestDetailsActions => {
  return {
    postDecision: createAction<Partial<IDecision>>(
      EntitlementActions.postDecision,
      dispatch
    ),
    patchDecision: createAction<Partial<IDecision>>(
      EntitlementActions.patchDecision,
      dispatch
    )
  };
};

/**
 * A redux connected GrantRequestDetails control.
 * @description Supports translation
 */
export const ConnectedPendingApprovalGrantRequestDetails = connect(
  mapStateToProps,
  mapDispatchToProps
  // tslint:disable-next-line:no-any
)(translate('MyAccess')(PendingApprovalGrantRequestDetails) as any);
