import { EntitlementActions } from '../../../models/EntitlementActions';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState } from '../../../models/IEntitlementState';
import { registry } from '../myAccessRegistry';

export const showGrantRequestFilter = (
  state: IEntitlementState,
  action: IEntitlementAction<boolean>
): IEntitlementState => {
  let nextState = {
    ...state,
    showingGrantRequestFilter: action.payload
  };

  return nextState;
};
registry.add(EntitlementActions.showGrantRequestFilter, showGrantRequestFilter);
