import { notificationsMerge } from '@microsoft/portal-app/lib/Notifications/helpers/notificationsMerge';
import {
  INotification,
  NotificationSeverity,
  NotificationType
} from '@microsoft/portal-app/lib/Notifications/models/INotification';
import { IODataValueResponse } from '@microsoft/portal-app/lib/odata-utils';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { TranslationOptions } from 'i18next';
import * as moment from 'moment';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import { Observable } from 'rxjs/Observable';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';
import { IAccessReviewDecision } from '../../../models/AccessReviews/IAccessReviewDecision';
import { IEntity } from '../../../models/ELM/IEntity';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { EntityType } from '../../../models/EntityType';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState, IRootEntitlementsState } from '../../../models/IEntitlementState';
import { getRequestWithAudience } from '../../../shared/AttachAudience';
import { getEntityApiUrl } from '../../../shared/getApiUrl';
import { getResponseValue } from '../../../shared/getResponseValue';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { registry } from '../myAccessRegistry';

export const getSecondaryDecisionsEpic: Epic<IEntitlementAction<AnyPayload>, IRootEntitlementsState> = (
  action$: ActionsObservable<IEntitlementAction<string>>,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
  return action$.ofType(EntitlementActions.getSecondaryDecisions).mergeMap((action: IEntitlementAction<any>) => {
    const principalId = action.payload!.principalId;
    const type = action.payload!.type;
    let url = getEntityApiUrl(EntityType.secondaryDecisions, principalId);
    if (type && type === EntityType.supervisorCentricReviewDecisionsResources) {
      url = getEntityApiUrl(EntityType.supervisorCentricReviewDecisionsResources, principalId);
    }

    const ajaxRequest = getRequestWithAudience(url, EntityType.secondaryDecisions as EntityType);
    return (
      ajax(ajaxRequest)
        .map((payload: IODataValueResponse<ReadonlyArray<IEntity>>) => {
          return {
            type: EntitlementActions.getSecondaryDecisionsSucceeded,
            payload: {
              entities: getResponseValue(payload),
              principalId: principalId,
              payload: payload
            }
          };
        })
        // tslint:disable-next-line:no-any
        .catch((error: any) =>
          Observable.of({
            type: EntitlementActions.getSecondaryDecisionsFailed,
            payload: {
              principalId: principalId,
              errorCode: error && error.status
            }
          })
        )
    );
  });
};
registry.addEpic('getSecondaryDecisionsEpic', getSecondaryDecisionsEpic);

export const getSecondaryDecisionsSucceeded = (
  state: IEntitlementState,
  action: IEntitlementAction<{
    entities: Array<IAccessReviewDecision>;
    principalId: string;
  }>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  const { entities, principalId } = action.payload!;

  if (entities === undefined) {
    return {
      ...state,
      isEntityDetailLoading: false
    };
  }

  let currentDecision = state.accessReviewDecisions.entitiesById.get(principalId);

  // If the decision was not found, return
  if (!currentDecision) {
    return {
      ...state
    };
  }

  // Store secondary decisions in the current decision and add it to the allDecisions map keyed on the principal id
  currentDecision!.secondaryDecisions = entities;
  let allDecisions = state.accessReviewDecisions.entitiesById as Map<string, IAccessReviewDecision>;
  allDecisions.set(principalId, currentDecision!);

  return {
    ...state,
    accessReviewDecisions: {
      ...state.accessReviewDecisions,
      entitiesById: allDecisions as ReadonlyMap<string, IAccessReviewDecision>
    }
  };
};
registry.add(EntitlementActions.getSecondaryDecisionsSucceeded, getSecondaryDecisionsSucceeded);

export const getSecondaryDecisionsFailed = (
  state: IEntitlementState,
  // tslint:disable-next-line:no-any
  action: IEntitlementAction<Readonly<any>>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  const errorCode = action.payload.errorCode;

  let toastKey = LocaleKeys.generalErrorMessage;
  let toastOptions: TranslationOptions = {};

  let notifications: INotification[] = [
    {
      id: '', // v4(),
      localizableMessage: {
        key: toastKey,
        options: toastOptions
      },
      createdDateTime: moment(),
      severity: NotificationSeverity.error,
      type: NotificationType.card
    }
  ];

  return {
    ...state,
    notifications: notificationsMerge(notifications, state.notifications, state.notificationsLimit),
    isEntityDetailLoading: false,
    errorHasOccurred: true,
    isTenantWhitelisted: errorCode !== 403
  };
};
registry.add(EntitlementActions.getSecondaryDecisionsFailed, getSecondaryDecisionsFailed);
