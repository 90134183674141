import { Observable } from 'rxjs/Observable';
import { filter, mergeMap } from 'rxjs/operators';

import { EntitlementActions } from '../../../models/EntitlementActions';
import { registry } from '../myAccessRegistry';
import { createTelemetryAction, ofType } from './';

export const approvalsTelemetryEpic = registry.addEpic(
  'approvalsTelemetry',
  (action$, _store) =>
    action$.pipe(
      ofType(EntitlementActions.postDecision),
      filter(
        (action) => action.payload && action.payload.reviewResult !== undefined
      ),
      mergeMap((action) => {
        const { reviewResult }: { reviewResult: string } = action.payload;

        return Observable.of(
          createTelemetryAction(`approvals/${reviewResult.toLowerCase()}`)
        );
      })
    )
);
