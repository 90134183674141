import React, { useCallback } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { BrowserRouter, HashRouter } from 'react-router-dom';

import { ConnectedAccessReviewDecisionsList } from '../../components/AccessReviews/AccessReviewDecisionsList';
import { ConnectedAccessReviewsList } from '../../components/AccessReviews/AccessReviewsList';
import { ConnectedSupervisorCentricDecisionsList } from '../../components/AccessReviews/SupervisorCentricDecisionsList';
import { ConnectedCatalogsPage } from '../../components/ELM/CatalogsPage';
import { ConnectedGrantRequestList } from '../../components/ELM/GrantRequestList';
import { ConnectedGrantRequests } from '../../components/ELM/GrantRequests';
import { EntitlementsPage, EntitlementsPageV2, OverviewPage } from '../../components/ELM2';
import { CloudInfrastructurePage } from '../../components/RAM/CloudInfrastructurePage/CloudInfrastructurePage';
import { ConnectedDiagnosticsPanel } from '../../components/Shared/DiagnosticsPanel';
import { useFeatures } from '../../hooks';
import { isInternalUser } from '../../shared';
import { NAVIGATION_LINKS } from '../../shared/constants';
import { Layout } from '../layout/Layout';

export const Routes: React.FC = () => {
  const features = useFeatures();

  const routeRender = useCallback(
    (props: RouteProps) => (
      <Layout {...props}>
        <HashRouter>
          <Route path={'/'} exact component={OverviewPage} />

          <Route path={`${NAVIGATION_LINKS.overview.link}`} exact component={OverviewPage} />

          <Route path={`${NAVIGATION_LINKS.accessPackages.link}*`} exact component={features.enableRecommendationForEndUser? EntitlementsPageV2 : EntitlementsPage} />

          <Route path={`${NAVIGATION_LINKS.requestHistory.link}`} exact component={ConnectedGrantRequestList} />

          <Route path={`${NAVIGATION_LINKS.requestApprovals.link}*`} exact component={ConnectedGrantRequests} />

          {/* Access review */}
          <Route path={`${NAVIGATION_LINKS.accessReviews.link}`} exact component={ConnectedAccessReviewsList} />
          <Route
            path={`${NAVIGATION_LINKS.accessReviews.link}/:reviewId`}
            exact
            component={ConnectedAccessReviewDecisionsList}
          />

          <Route
            path={`${NAVIGATION_LINKS.supervisorCentricReviews.link}/:reviewId`}
            exact
            component={ConnectedSupervisorCentricDecisionsList}
          />

          {features.enableCatalogView && (
            <Route path={`${NAVIGATION_LINKS.catalogs.link}*`} exact component={ConnectedCatalogsPage} />
          )}
          {features.enableCatalogView && (
            <Route path={`${NAVIGATION_LINKS.catalogs.link}/:catalogId`} exact component={ConnectedCatalogsPage} />
          )}

          {features.enableRam && isInternalUser() && (
            <Route path={`${NAVIGATION_LINKS.ram.link}*`} exact component={CloudInfrastructurePage} />
          )}

          <Route path={`${NAVIGATION_LINKS.me.link}`} exact component={ConnectedDiagnosticsPanel} />
        </HashRouter>
      </Layout>
    ),
    [features.enableCatalogView, features.enableRam, features.enableRecommendationForEndUser]
  );

  return (
    <BrowserRouter>
      <Route render={routeRender} />
    </BrowserRouter>
  );
};
