export enum RequestType {
  UserAdd = 'UserAdd',
  UserRemove = 'UserRemove',
  UserUpdate = 'UserUpdate',
  UserExtend = 'UserExtend',
  AdminAdd = 'AdminAdd',
  AdminRemove = 'AdminRemove',
  AdminUpdate = 'AdminUpdate',
  SystemRemove = 'SystemRemove',
  SystemAdd = 'SystemAdd',
  ApproverRemove = 'ApproverRemove'
}

export enum SimpleRequestType {
  Add = 'add',
  Remove = 'remove',
  Extend = 'extend',
  Update = 'update'
}

export const getSimpleRequestTypeFromRequestType = (rt: string): string => {
  switch (rt) {
    case RequestType.UserAdd:
    case RequestType.AdminAdd:
    case RequestType.SystemAdd:
      return SimpleRequestType.Add;
    case RequestType.UserExtend:
      return SimpleRequestType.Extend;
    case RequestType.AdminUpdate:
      return SimpleRequestType.Update;
    case RequestType.UserRemove:
    case RequestType.AdminRemove:
    case RequestType.SystemRemove:
    case RequestType.ApproverRemove:
      return SimpleRequestType.Remove;
    default:
      return '';
  }
};

export const getRequestTypeFromSimpleRequestType = (
  srt: string[] | undefined
): string[] => {
  if (!srt || srt.length === 0) {
    return [];
  }
  let requestTypes = new Array();
  srt.forEach((type: string) => {
    switch (SimpleRequestType[type]) {
      case SimpleRequestType.Add:
        requestTypes.push(RequestType.UserAdd);
        requestTypes.push(RequestType.AdminAdd);
        break;
      case SimpleRequestType.Extend:
        requestTypes.push(RequestType.UserExtend);
        break;
      case SimpleRequestType.Remove:
        requestTypes.push(RequestType.UserRemove);
        requestTypes.push(RequestType.AdminRemove);
        break;
      default:
        break;
    }
  });
  return requestTypes as string[];
};
