import { Dropdown, IDropdown, IIconProps, Label, PrimaryButton, TextField } from '@fluentui/react';
import React, { FormEvent, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';

import { useTelemetryWithMetadata, useTranslation } from '../../../hooks';
import { LocaleKeys } from '../../../shared';
import { CopyableTextField } from '../../Shared/CopyableTextField/CopyableTextField';
import { IpAddressTypeEnum, RdpContentProps } from './Connection.types';
import { onDownloadRdpFile } from './Connection.utils';

const ramConnectionStyles = require('./ram.connection.scss');
const downloadIcon: IIconProps = { iconName: 'Download' };

export const RdpContent: FunctionComponent<RdpContentProps> = ({
  virtualMachineName,
  publicIpAddress,
  privateIpAddress,
  userName
}: RdpContentProps) => {
  const t = useTranslation();
  const { reportCustomEventWithMetadata } = useTelemetryWithMetadata();

  // Log rdpContent loading to track usage
  useEffect(() => {
    reportCustomEventWithMetadata('ram/connection-tab/rdp');
    reportCustomEventWithMetadata('ram/journey/3/rdp-tab');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className={ramConnectionStyles.dialogSection}>{t(LocaleKeys.ramConnectionRdpGuide)}</div>
      <div className={ramConnectionStyles.dialogSection}>
        <TextField label={t(LocaleKeys.ramConnectionRdpPublicIp)} readOnly defaultValue={publicIpAddress} />
      </div>
      <div className={ramConnectionStyles.dialogSection}>
        <Label>{t(LocaleKeys.ramConnectionRdpUsernameLabel)} </Label>
        <CopyableTextField readOnly value={userName} ariaLabel={t(LocaleKeys.ramConnectionRdpUsernameLabel)} />
      </div>
      <div className={ramConnectionStyles.dialogSection}>
        <PrimaryButton
          text={t(LocaleKeys.ramConnectionRdpDownloadRdpFile)}
          onClick={() => {
            onDownloadRdpFile(virtualMachineName, publicIpAddress);
            reportCustomEventWithMetadata('ram/connection/rdpFileDownload');
          }}
          allowDisabledFocus
          iconProps={downloadIcon}
        />
      </div>
    </div>
  );
};
