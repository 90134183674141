import {
  IGrantRequestQuestionsMappedProps,
  IConnectedGrantRequestQuestionsProps,
  IGrantRequestQuestionsActions
} from './GrantRequestQuestions.types';
import { IRootEntitlementsState } from '../../../../models/IEntitlementState';
import { history } from '../../../../redux/configureStore';
import { connect, Dispatch } from 'react-redux';
import { IGrantRequest } from '../../../../models/ELM/IGrantRequest';
import { EntitlementActions } from '../../../../models/EntitlementActions';
import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { GrantRequestQuestions } from './GrantRequestQuestions';
import { translate } from 'react-i18next';

const mapStateToProps = (
  state: IRootEntitlementsState,
  ownProps: IConnectedGrantRequestQuestionsProps
): IGrantRequestQuestionsMappedProps => {
  const grantRequest = state.app.partialGrantRequest;
  return {
    ...ownProps,
    grantRequest,
    submitting: state.app.submitting,
    validationErrors: state.app.validationErrors,
    navigateTo(url: string): void {
      history.push(url);
    },
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<IRootEntitlementsState>
): IGrantRequestQuestionsActions => {
  return {
    setPartialGrantRequest: createAction<
      Partial<IGrantRequest>>(EntitlementActions.setPartialGrantRequest, dispatch)
  };
};

/**
 * A redux connected GrantRequestQuestions control.
 * @description Supports translation
 */
export const ConnectedGrantRequestQuestions = connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('MyAccess')(GrantRequestQuestions));
