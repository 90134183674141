import { useAuth } from '@iamexperiences/react-auth/lib-commonjs/react';
import { IConfiguration } from '@iamexperiences/suite-header';
import { SuiteHeader } from '@iamexperiences/suite-header/lib-commonjs/SuiteHeader';
import { IOrganization, IUserData } from '@iamexperiences/suite-header/lib-commonjs/SuiteHeader/SuiteHeader.types';
import { ITenantProps } from '@iamexperiences/suite-header/lib-commonjs/SuiteHeader/TenantList/ITenantProps';
import { AppKeys, getIAMUXAppsUrl,getIAMUXGroupsUrl, getUrlForApp } from '@microsoft/portal-app/lib/environment';
import { NavBarData } from '@suiteux/suiteux-shell-applauncher';
import i18next from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';

import { auth } from '../../../auth';
import { useTelemetryWithMetadata, useTranslation } from '../../../hooks';
import { useHeaderOrganizations } from '../../../hooks/header/userHeaderOrganizations';
import { useDataBoundary } from '../../../hooks/useDataBoundary';
import { useTelemetryUtils } from '../../../hooks/useTelemetryUtils';
import { IAppInfo } from '../../../models/Header/interfaces';
import { isInternalUser } from '../../../shared';
import { onLeavePreview } from '../../../shared/leavePreviewHelper';
import { settingsManager } from '../../../shared/settings';
import { ConnectedCustomSearchBox } from '../CustomSearch/ConnectedCustomSearchBox';
import { getMenuItems } from './enabledApps.util';
import { IHeaderProps } from './Header.types';

const DEFAULT_HELPLINK = 'https://docs.microsoft.com/en-us/azure/active-directory/user-help/my-account-portal-overview';

export const Header: React.FC<IHeaderProps> = (props) => {
  const {
    navBarData,
    tenantBrandingUrl,
    currentOrganizationDisplayName,
    hasError,
    userImageUrl,
    getNavBarData,
    getTenantBranding: getTenantLogo,
    getUserImageUrl,
    handleAppBrandingClick,
    enableClaimBasedTelemetry
  } = props;

  const clientId = useAuth().clientId;
  const user = useAuth().user();
  const tenantId = user?.tenantId;
  const objectId = user && user.objectId;
  const username = user && user.username;
  const name = user && user.name;
  const claims = user && (user.rawClaims as { xms_pl?: unknown } | undefined);
  const [language, setLanguage] = useState<string | undefined>(
    claims && typeof claims.xms_pl === 'string' ? claims.xms_pl : undefined
  );

  const t = useTranslation();
  const { dataBoundary, isRegionalEndpointEnabled } = useDataBoundary();
  const { updateTelemetryReference } = useTelemetryUtils();
  useEffect(() => {
    if (enableClaimBasedTelemetry) {
      updateTelemetryReference(isRegionalEndpointEnabled, dataBoundary);
    }
  }, [dataBoundary, isRegionalEndpointEnabled, updateTelemetryReference, enableClaimBasedTelemetry]);
  const { reportCustomEventWithMetadata, reportErrorWithMetadata } = useTelemetryWithMetadata();
  const getToken = useCallback(
    async (scope: string) => {
      return await auth.acquireToken();
    },
    [auth],
  );

  const configuration: IConfiguration = React.useMemo(
    () => ({
      msGraphBaseUrl: settingsManager.getRequired('msGraphResourceName'),
      getToken
    }),
    [getToken],
  );

  const userData: IUserData = {
    tenantId: tenantId || '',
    tenantDisplayName: currentOrganizationDisplayName,
    upn: username || '',
    name: name || '',
    imageUrl: userImageUrl,
    homeTenantId: user?.homeTenantId || '',
    rawClaims: user?.rawClaims || {}
  };

  const { organizations, isLoading, error: headerOrganizationError } = useHeaderOrganizations(configuration, userData);

  const localizationMap = {
    apps: t('apps'),
    switchOrgIntroText: t('switchOrgIntroText'),
    currentOrganizationTitle: t('currentOrganizationTitle'),
    otherOrganizationsTitle: t('otherOrganizationsTitle'),
    manageOrganizations: t('manageOrganizationsLinkText'),
    closePanelAriaLabel: t('closeButtonAriaLabel'),
    tenantBrandingImageAlt: t('tenantBranding', { displayName: currentOrganizationDisplayName }),
    organizations: t('organizations'),
    loadingOrganizations: t('loadingOrganizations'),
    organizationsEmpty: t('emptyOrganizations'),
    finishLoading: t('loaded'),
    organizationError: t('organizationError'),
    helpPanelHeaderText: t('help'),
    viewApps: t('viewApps'),
    helpPanelTermsOfUseLink: t('termsOfUse'),
    helpPanelPrivacyLink: t('privacy'),
    accountManager: t('accountManager'),
    switchOrganization: t('switchOrganization'),
    moreOptions: t('moreOptions'),
    leaveNewExp: t('leaveNewExp'),
    waffleNotAvailableHeader: t('fallbackWaffleHeaderText'),
    waffleNotAvailableDescription: t('fallbackWaffleMainText'),
    accessibilityLink: t('accessibilityLink')
  };

  const helpLink = settingsManager.get('panelHelpLink') || DEFAULT_HELPLINK;
  const evoBase = settingsManager.get('adalInstance') || 'https://login.microsoftonline.com';
  const viewAccountLink = settingsManager.get('meControlViewAccountLink') || '';
  const isUniversalMeControlEnabled = settingsManager.get('isUniversalMeControlEnabled') || false;
  const myGroupsUrl = settingsManager.get('myGroupsUrl');
  const myAppsUrl = settingsManager.get('myAppsUrl');

  const appsMap: Map<AppKeys, IAppInfo> = new Map<AppKeys, IAppInfo>();
  appsMap.set(
    AppKeys.access,
    {
      appKey: 'My Access',
      appName: t('myAccess'),
      icon: 'AppIconDefaultList',
      defaultUrl: window.location.origin
    });

    myAppsUrl && appsMap.set(
      AppKeys.apps,
      {
        appKey: 'My Apps',
        appName: t('myApps'),
        icon: 'AppIconDefaultList',
        iamuxUrl: getIAMUXAppsUrl(),
        defaultUrl: myAppsUrl
      });
    myGroupsUrl && appsMap.set(
      AppKeys.groups,
      {
        appKey: 'My Groups',
        appName: t('myGroups'),
        icon: 'Group',
        iamuxUrl: getIAMUXGroupsUrl(),
        defaultUrl: myGroupsUrl
      });

  useEffect(() => {
    const lng = i18next.languages[0];
    setLanguage(lng);
  }, [i18next.languages[0]]);

  useEffect(() => {
    const getHeaderData = async () => {
      isInternalUser() && objectId && getUserImageUrl(objectId);

      const audience = settingsManager.get('officeSuiteHeaderAudience');
      if (!audience) {
        reportErrorWithMetadata('officeSuiteHeaderAudience setting not present', {});
      } else {
        const token = await auth.acquireToken(audience, {
          prompt: 'none',
          errorHandling: 'empty'
        });
        if (token !== '') {
          getNavBarData(token);
        }
      }
    };
    getHeaderData();
  }, [tenantId, clientId, objectId]);

  const changeTenant = useCallback(
    (targetTenant: ITenantProps, currentTenant?: IOrganization) => {
      reportCustomEventWithMetadata('suite-header/switch-organization', {
        source: currentTenant?.displayName,
        target: targetTenant.displayName
      });
      history.replaceState('', '', '/#/');
      auth.login({ tenantId: targetTenant.tenantId });
    },
    [auth]
  );

  const switchTo = useCallback(
    (upn: string) => {
      auth.login({
        username: upn,
        tenantId: 'common'
      });
    },
    [auth.login]
  );

  const switchAccount = useCallback(() => {
    auth.login({
      tenantId: 'common',
      prompt: 'account'
    });
  }, [auth.login]);

  const logOut = useCallback(() => {
    reportCustomEventWithMetadata('suite-header/MeControl/logOut', {
      message: 'The user logged out from MeControl'
    });

    auth.logout();
  }, [auth]);

  const handleHeaderRender = useCallback(() => {
    reportCustomEventWithMetadata('suite-header/rendered');
  }, [reportCustomEventWithMetadata]);

  const handleHeaderButtonClick = useCallback((buttonId: string) => {
      reportCustomEventWithMetadata('suite-header/button-clicked', {buttonId});
  }, [reportCustomEventWithMetadata]);

  if (language === undefined) {
    return null;
  }


  return (
    <SuiteHeader
      appId={clientId}
      userData={userData}
      searchBox={getSearchBoxRenderer()}
      workloadId={'MyAccess'}
      navbarData={navBarData as NavBarData}
      culture={language}
      localizationMap={localizationMap}
      imageUrl={tenantBrandingUrl}
      appName={t('myAccess')}
      onClickAppBranding={handleAppBrandingClick}
      enabledApps={getMenuItems(appsMap, true)}
      organizations={{
        value: organizations,
        isLoading
      }}
      changeTenant={changeTenant}
      onSwitchTo={switchTo}
      onSwitch={switchAccount}
      hasError={hasError || headerOrganizationError !== null}
      helpLink={helpLink}
      onRenderComplete={handleHeaderRender}
      onHeaderButtonClicked={handleHeaderButtonClick}
      onLeavePreview={onLeavePreview()}
      isUniversalMeControlEnabled={isUniversalMeControlEnabled}
      evoBase={evoBase}
      viewAccountLink={viewAccountLink}
      onSignOut={logOut}
    />
  );
};

const getSearchBoxRenderer = () => () => {
  const t = useTranslation();
  return <ConnectedCustomSearchBox t={t} />;
};
