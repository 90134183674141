import { css } from '@fluentui/react';
import { TranslationFunction } from 'i18next';
import { FontClassNames } from '@fluentui/react';
import * as React from 'react';
import { IRoleScope } from '../../../models/ELM/IRoleScope';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { ConnectedResourceSummary } from '../ResourceSummary/ConnectedResourceSummary';

const RoleScopesCardStyles = require('./RoleScopesCard.scoped.scss');

export interface IRoleScopesCardProps {
  t: TranslationFunction;
  accessPackageResourceRoleScopes: IRoleScope[];
  canShowResources: boolean;
  canOpenResources: Map<String, boolean>;
  errorMessage?: string | null;
  isFlexRow: boolean;
  searchTerm?: string | null;
  isDelivering?: boolean;
}

export class RoleScopesCard extends React.Component<IRoleScopesCardProps, {}> {
  public render(): JSX.Element {
    const {
      accessPackageResourceRoleScopes,
      t,
      canShowResources,
      canOpenResources,
      errorMessage,
      isDelivering,
      isFlexRow,
      searchTerm,
    } = this.props;
    if (!accessPackageResourceRoleScopes) {
      return <div />;
    }

    if (!canShowResources && errorMessage) {
      return (
        <div
          className={css(
            this.props.isFlexRow ? RoleScopesCardStyles.paddingLeft : null,
            FontClassNames.medium
          )}
        >
          {t(errorMessage)}
        </div>
      );
    }

    if (isDelivering) {
      return (
        <div
          className={css(
            this.props.isFlexRow ? RoleScopesCardStyles.paddingLeft : null,
            FontClassNames.medium
          )}
        >
          {t(LocaleKeys.deliveringPackages)}
        </div>
      );
    }

    if (accessPackageResourceRoleScopes.length === 0) {
      return (
        <div
          className={css(
            this.props.isFlexRow ? RoleScopesCardStyles.paddingLeft : null,
            FontClassNames.medium
          )}
        >
          {t(LocaleKeys.noPermissionsInAccessPackage)}
        </div>
      );
    }

    return (
      <ConnectedResourceSummary
        accessPackageResourceRoleScopes={accessPackageResourceRoleScopes}
        t={t}
        isFlexRow={isFlexRow}
        searchTerm={searchTerm}
        canOpenResources={canOpenResources}
      />
    );
  }
}
