// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../dev/node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../dev/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3yQ3Ad0w7J9jXlYcq7zU-u{padding-left:40px}\n", "",{"version":3,"sources":["webpack://./src/components/Shared/RoleScopesCard/RoleScopesCard.scoped.scss"],"names":[],"mappings":"AAEA,yBACE,iBAA+B","sourcesContent":["@import '~@microsoft/portal-app/lib/styling/mixins.scss';\n\n.paddingLeft {\n  padding-left: $ms-grid-unit * 5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paddingLeft": "_3yQ3Ad0w7J9jXlYcq7zU-u"
};
module.exports = ___CSS_LOADER_EXPORT___;
