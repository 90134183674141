import { IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import { IWithResponsiveModeState } from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import { InjectedTranslateProps } from 'react-i18next';

import { EntitlementActions, EntityType } from '../../../models';
import { IAccessReview } from '../../../models/AccessReviews/IAccessReview';
import { IAccessReviewsFeatureFlags } from '../../../models/AccessReviews/IAccessReviewsFeatureFlags';
import { IEntitlement } from '../../../models/ELM/IEntitlement';
import { IGrantRequest } from '../../../models/ELM/IGrantRequest';
import { IFilter } from '../../../models/IFilter';
import { IListColumn } from '../../../models/IListColumn';
import { IPageMetaData } from '../../../models/IPageMetaData';
import { IWithFeaturesProp } from '../../../shared';

/**
 * The props for the AccessReviewsList control.
 */
export interface IAccessReviewsListProps
  extends IAccessReviewsListActions,
    IAccessReviewsListMappedProps,
    InjectedTranslateProps,
    IConnectedAccessReviewsListProps,
    IWithResponsiveModeState {}

/**
 * Represents the AccessReviewsList control's internal state
 */
export interface IAccessReviewsListState {
  commands?: IContextualMenuItem[];
  columns: Array<IListColumn<IAccessReview>>;
  elmColumns: Array<IListColumn<IAccessReview>>;
  aadRolesColumns: Array<IListColumn<IAccessReview>>;
  rbacColumns: Array<IListColumn<IAccessReview>>;
  byodColumns: Array<IListColumn<IAccessReview>>;
  userAccessColumns: Array<IListColumn<IAccessReview>>;
  entitlement?: IEntitlement;
  grantRequest?: IGrantRequest;
  selectedPivot: AccessReviewsPivot;
  showGroups: boolean;
  totalReviews: number;
  totalActiveReviews: number;
}

/**
 * Used as a standalone interface by mapStateToProps, the IAccessReviewsListMappedProps defines the non action properties.
 * for the AccessReviewsList control.
 */
export interface IAccessReviewsListMappedProps extends IWithFeaturesProp {
  accessReviewsList: readonly IAccessReview[];
  accessPackageReviewsList: readonly IAccessReview[];
  aadRolesReviewsList: readonly IAccessReview[];
  rbacReviewsList: readonly IAccessReview[];
  byodReviewsList: readonly IAccessReview[];
  userAccessReviewsList: readonly IAccessReview[];
  isLoading: boolean;
  isGroupsAppsRefreshing: boolean;
  isAccessPackagesRefreshing: boolean;
  isAadRolesRefreshing: boolean;
  isRbacRefreshing: boolean;
  isByodRefreshing: boolean;
  isUserAccessRefreshing: boolean;
  isLoadingMoreGroupsApps: boolean;
  isLoadingMoreAccessPackages: boolean;
  isLoadingMoreAadRoles: boolean;
  isLoadingMoreRbac: boolean;
  isLoadingMoreByod: boolean;
  isLoadingMoreUserAccess: boolean;
  isSearching: boolean;
  isFiltering: boolean;
  searchTerm: string;
  errorHasOccurred: boolean;
  errorCode: number;
  isTenantWhitelisted: boolean;
  pageMetaData: IPageMetaData;
  filter: IFilter;
  showingConfirmDialog: boolean;
  featureFlags: IAccessReviewsFeatureFlags;
}

export enum AccessReviewsPivot {
  GroupsApps = 'groupAndAppMembership',
  AccessPackages = 'accessPackageAssignment',
  AadRoles = 'aadRoles',
  Rbac = 'rbac',
  Byod = 'byod',
  UserAccess = 'userAccess'
}

/**
 * Used as a standalone interface by mapDispatchToProps, the IAccessReviewsListActions interface defines the actions
 * for the AccessReviewsList control
 */
export interface IAccessReviewsListActions {
  sortEntities: (orderby: string, isAscending: boolean, type: string) => void;
  sortFilteredEntities: (
    orderby: string,
    isAscending: boolean,
    searchTerm: string,
    filterContext: IFilter,
    type: string
  ) => void;
  searchForMore: () => void;
  getEntities: () => void;
  getDecisionsSummary: (reviewId: string) => void;
  openReview: () => void;
  setReviewListPivot: (pivot: AccessReviewsPivot) => void;
  setSearchContext: (context: EntitlementActions) => void;
  setSortedByColumn: (payload: IListColumn<IAccessReview>) => void;
  refreshAccessReviews: () => void;
  refreshAccessPackageReviews: () => void;
  refreshAadRolesReviews: () => void;
  refreshRbacReviews: () => void;
  refreshByodReviews: () => void;
  refreshUserAccessReviews: () => void;
  getAccessReviewsFeatureFlags: () => void;
  loadMoreEntities: (type: EntityType) => void;
}

/**
 * The props for the redux connected AccessReviewsList
 * @description Called "own" props in the redux function mapStateToProps.
 * The mapStateToProps can map the properties of this interface to the IAccessReviewsListProps interface.
 */
export interface IConnectedAccessReviewsListProps {}