import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { useDispatch } from 'react-redux';

import { EntitlementActions, IGrantRequestTargetTypeActionPayload } from '../../models';
import { useSelector } from '../index';

interface IUseGrantRequestTargetType {
  setGrantRequestTargetType: (payload: IGrantRequestTargetTypeActionPayload) => void;
  grantRequestTargetType: string | undefined;
}

export const useGrantRequestTargetType = (): IUseGrantRequestTargetType => {
  const dispatch = useDispatch();
  const { grantRequestTarget } = useSelector((state) => ({
    grantRequestTarget: state?.app?.grantRequestTarget
  }));

  return {
    setGrantRequestTargetType: createAction<IGrantRequestTargetTypeActionPayload>(
      EntitlementActions.setGrantRequestTargetType,
      dispatch
    ),
    grantRequestTargetType: grantRequestTarget?.targetType
  };
};
