import { IAjaxRequest } from '@microsoft/portal-app/lib/auth/withAuth';
import { notificationsMerge } from '@microsoft/portal-app/lib/Notifications/helpers/notificationsMerge';
import {
  INotification,
  NotificationSeverity,
  NotificationType
} from '@microsoft/portal-app/lib/Notifications/models/INotification';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { errorHandler } from '@microsoft/portal-app/lib/redux/observableErrorHandler';
import { TranslationOptions } from 'i18next';
import * as moment from 'moment';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import 'rxjs/add/observable/concat';
import { Observable } from 'rxjs/Observable';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';
import { catchError, flatMap } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { v4 as guid } from 'uuid';
import { IAccessReviewDecision } from '../../../models/AccessReviews/IAccessReviewDecision';
import { IAccessReviewSubject } from '../../../models/AccessReviews/IAccessReviewSubject';
import { ISubmitDecision } from '../../../models/AccessReviews/ISubmitDecision';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { EntityType } from '../../../models/EntityType';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState, IRootEntitlementsState } from '../../../models/IEntitlementState';
import { DecisionType } from '../../../models/RequestApprovals/DecisionType';
import { history } from '../../../redux/configureStore';
import { getAudience } from '../../../shared/AttachAudience';
import { getUserFromAuth } from '../../../shared/authHelper';
import { emptyGuid } from '../../../shared/constants';
import { submitReviewDecisionApiUrl } from '../../../shared/getAccessReviewsApiUrl';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { Routes } from '../../../shared/Routes';
import { registry } from '../myAccessRegistry';

export const submitDecisionEpic: Epic<IEntitlementAction<AnyPayload>, IRootEntitlementsState> = (
  action$: ActionsObservable<IEntitlementAction<ISubmitDecision[]>>,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
  return action$.ofType(EntitlementActions.submitDecision).pipe(
    flatMap((action: IEntitlementAction<ISubmitDecision[]>) => {
      const decisionPayload = {
        justification: action.payload![0].justification,
        reviewResult: action.payload![0].decisionType
      };
      const acceptRec = action.payload![0].acceptRecommendation;
      const submitUrl = submitReviewDecisionApiUrl(action.payload!, acceptRec);

      // Batching multiple decisions and rec API uses POST, single decision uses PATCH
      let methodType = 'PATCH';
      if (action.payload!.length > 1 || acceptRec) {
        methodType = 'POST';
      }

      const correlationId = guid();
      const request: IAjaxRequest = {
        method: methodType,
        url: submitUrl,
        body: decisionPayload,
        audience: getAudience(EntityType.accessReviewDecisions),
        headers: {
          'x-ms-client-request-id': correlationId
        }
      };

      const addSucceededAction = {
        type: EntitlementActions.submitDecisionSucceeded,
        payload: action.payload!
      };

      return ajax(request).pipe(
        flatMap(() => {
          return Observable.of(addSucceededAction);
        }),
        catchError(errorHandler<IEntitlementAction>(EntitlementActions.submitDecisionFailed))
      );
    })
  );
};
registry.addEpic('submitDecisionEpic', submitDecisionEpic);

export const submitDecision = (
  state: IEntitlementState,
  action: IEntitlementAction<ISubmitDecision[]>
): IEntitlementState => {
  if (action.payload === undefined) {
    return state;
  }

  return {
    ...state,
    submitting: true
  };
};
registry.add(EntitlementActions.submitDecision, submitDecision);

export const submitDecisionSucceeded = (
  state: IEntitlementState,
  action: IEntitlementAction<ISubmitDecision[]>
): IEntitlementState => {
  if (action.payload === undefined) {
    return state;
  }

  let entities = state.accessReviewDecisions.entitiesById as Map<string, IAccessReviewDecision>;

  for (let decision of action.payload) {
    const currentDateTime = new Date();
    const user = getUserFromAuth();

    let userName = '';
    let userMail = '';
    let userId = emptyGuid;
    if (user) {
      userMail = user!.email!;
      userId = user!.objectId;
      userName = user!.name!;
    }

    const userSubject: IAccessReviewSubject = {
      id: userId,
      displayName: userName,
      mail: userMail,
      userPrincipalName: userMail
    };

    const emptyUserSubject: IAccessReviewSubject = {
      id: '',
      displayName: '',
      mail: '',
      userPrincipalName: ''
    };

    let mainDecision = state.accessReviewDecisions.entitiesById.get(decision.decisionId!)!;
    let parentDecision = state.accessReviewDecisions.entitiesById.get(decision.principalId!)!;

    let secondaryList: IAccessReviewDecision[] = [];
    let newDecision = state.accessReviewDecisions.entitiesById.get(decision.decisionId!)!;

    if (isNullOrUndefined(newDecision) && !isNullOrUndefined(state.accessReviewDecisions.entitiesById)) {
      // search for secondary decisions that match the ID
      for (let par of state.accessReviewDecisions.entitiesById) {
        if (!isNullOrUndefined(par) && !isNullOrUndefined(par[1]) && !isNullOrUndefined(par[1].secondaryDecisions)) {
          for (let dec of par[1].secondaryDecisions!) {
            if (dec.id === decision.decisionId) {
              secondaryList = par[1].secondaryDecisions!;
              newDecision = dec;
              parentDecision = par[1];
            }
          }
        }
      }
    }

    if (newDecision) {
      if (decision.decisionType !== DecisionType.NotReviewed) {
        newDecision.reviewResult = decision.decisionType as DecisionType;
        newDecision.justification = decision.justification;
        newDecision.reviewedBy = userSubject;
        newDecision.reviewedDateTime = currentDateTime;
      } else {
        newDecision.reviewResult = decision.decisionType as DecisionType;
        newDecision.justification = undefined;
        newDecision.reviewedBy = emptyUserSubject;
        newDecision.reviewedDateTime = undefined;
      }

      if (!isNullOrUndefined(mainDecision)) {
        entities.set(decision.decisionId!, newDecision);
      } else if (!isNullOrUndefined(parentDecision)) {
        // tslint:disable-next-line
        for (let i in secondaryList) {
          if (secondaryList[i].id === newDecision.id) {
            secondaryList[i] = newDecision;
          }
          parentDecision.secondaryDecisions = secondaryList;
        }
        entities.set(parentDecision.id, parentDecision);
      }
    }

    if (decision.selfReview) {
      setTimeout(() => {
        history.push(Routes.accessReviews);
      });
    }
  }

  return {
    ...state,
    accessReviewDecisions: {
      ...state.accessReviewDecisions,
      entitiesById: entities as ReadonlyMap<string, IAccessReviewDecision>
    },
    submitting: false,
    showingConfirmDialog: false,
    showingBulkDecisionDialog: false,
    showingReviewDecisionsDialog: false,
    showingAcceptRecommendations: false,
    showingResetDecisions: false
  };
};
registry.add(EntitlementActions.submitDecisionSucceeded, submitDecisionSucceeded);

export const submitDecisionFailed = (
  state: IEntitlementState,
  action: IEntitlementAction<Readonly<AnyPayload>>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  const payload = action.payload;
  let error = payload.response && payload.response.error && payload.response.error.message;

  let correlationId = payload.request && payload.request.headers && payload.request.headers['x-ms-client-request-id'];

  let toastOptions: TranslationOptions = {
    error: error,
    correlationId: correlationId
  };

  const notification = {
    localizableTitle: {
      key: LocaleKeys.accessReviewSubmitDecisionFailed,
      options: toastOptions
    },
    localizableMessage: {
      key: LocaleKeys.errorTemplate,
      options: toastOptions
    },
    createdDateTime: moment(),
    severity: NotificationSeverity.error
  };

  let notifications: INotification[] = [
    {
      ...notification,
      id: guid(),
      type: NotificationType.card
    },
    {
      ...notification,
      id: guid(),
      type: NotificationType.toast
    }
  ];

  return {
    ...state,
    notifications: notificationsMerge(notifications, state.notifications, state.notificationsLimit),
    submitting: false,
    showingConfirmDialog: false,
    showingBulkDecisionDialog: false,
    showingReviewDecisionsDialog: false,
    showingAcceptRecommendations: false,
    showingResetDecisions: false
  };
};
registry.add(EntitlementActions.submitDecisionFailed, submitDecisionFailed);
