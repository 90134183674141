import { ILocalizableMessage } from '@microsoft/portal-app/lib/localization/ILocalizableMessage';
import '@microsoft/portal-app/lib/styling/patterns/Dialog.scss';
import { TranslationFunction } from 'i18next';

import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
  TextField
} from '@fluentui/react';
import { ResponsiveMode } from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import * as React from 'react';
import { asLocalizedText } from '../../../shared/asLocalizedText';
import { LocaleKeys } from '../../../shared/LocaleKeys';

const copy = require('clipboard-copy');
const myAccessStyles = require('../../../css/myAccess.scoped.scss');

export interface ICopyLinkProps {
  t: TranslationFunction;
  url: string;
  entityName: string;
  responsiveMode?: ResponsiveMode;
  onDismiss(): void;
}

export interface ICopyLinkState {
  isUrlCopied: boolean;
}

export class CopyLink extends React.Component<ICopyLinkProps, ICopyLinkState> {
  constructor(nextProps: ICopyLinkProps) {
    super(nextProps);
    this.state = {
      isUrlCopied: false
    };
  }

  public render(): JSX.Element {
    const action = this.state.isUrlCopied
      ? LocaleKeys.urlCopied
      : LocaleKeys.urlCreated;

    const { entityName, onDismiss, t, url, responsiveMode } = this.props;
    const title: ILocalizableMessage = {
      key: action,
      options: {
        entity: entityName
      }
    };

    return (
      <div>
        <Dialog
          hidden={false}
          onDismiss={onDismiss}
          dialogContentProps={{
            type: DialogType.close,
            title: asLocalizedText(title, t),
            subText: t(LocaleKeys.copyLinkTip),
            className: 'ms-pii'
          }}
          modalProps={{
            containerClassName:
              responsiveMode! < ResponsiveMode.large
                ? myAccessStyles.smallDialog
                : ''
          }}
        >
          <TextField
            ariaLabel={t(LocaleKeys.copyLinkTip)}
            value={url}
            id="urlInput"
            spellCheck={false}
            multiline
            autoAdjustHeight
            readOnly
          />
          <DialogFooter>
            <PrimaryButton
              data-clipboard-text={url}
              text={this.props.t(LocaleKeys.copy)}
              onClick={this._onCopyClicked}
            />
            <DefaultButton
              text={this.props.t(LocaleKeys.cancel)}
              onClick={this.props.onDismiss}
            />
          </DialogFooter>
        </Dialog>
      </div>
    );
  }

  private _onCopyClicked = (): void => {
    copy(this.props.url).then(() => {
      this.setState({
        isUrlCopied: true
      });
      const input = document.getElementById('urlInput')! as HTMLInputElement;
      input.select();
    });
  }
}
