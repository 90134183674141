import React, { useCallback, useEffect, useState } from 'react';

import { CycleActivationStatus, IActivationStatusPayload } from '../../../models/RAM/IActivationStatusPayload';
import { IErrorDialog, PimRoleActivationErrorCode } from '../../../models/RAM/IErrorDialog';
import { IPimActivationRequestParameters } from '../../../models/RAM/IPimActivationRequestParameters';
import { IPimRoleManagementPolicyListRequestParameters } from '../../../models/RAM/IPimRoleManagementPolicyGetRequestParameter';
import { IVirtualMachine, IVirtualMachineExtendedDetails } from '../../../models/RAM/IVirtualMachine';
import { ActivationStatusDialog } from '../ActivationStatus/ActivationStatusDialog';
import { ConnectionDialog } from '../Connection/ConnectionDialog';
import { ManualApproveMessageDialog } from '../ManualApproveMessageDialog/ManualApproveMessageDialog';
import { QuickActivationDialog, QuickActivationDialogRole } from '../QuickActivation/QuickActivationDialog';
import { RamErrorDialog } from '../RamErrorModals/RamErrorDialog';

export interface RamDialogsProps {
  showQuickActivationDialog: boolean;
  activationStatus: IActivationStatusPayload;
  activateRamAccess: (parameters: IPimActivationRequestParameters) => void;
  setActivationStatus: (payload: IActivationStatusPayload) => void;
  onDismissActivationStatusDialog: VoidFunction;
  onDismissQuckActivationDialog: VoidFunction;
  setQuickActivationRole: (role: QuickActivationDialogRole) => void;
  quickActivationRole: QuickActivationDialogRole;
  refreshEntities: VoidFunction;
  validateRoleActivation: (parameters: IPimActivationRequestParameters) => void;
  selectedVm: IVirtualMachine;
  maximumDuration?: string;
  isJustificationRequired?: boolean;
  isTicketingRequired?: boolean;
  isMfaRequirementMet?: boolean;
  hideConnectionDialog: boolean;
  setHideConnectionDialog: (hide: boolean) => void;
  ramErrorDialogContent?: IErrorDialog;
  setRamErrorDialog: (errorDialogContent?: IErrorDialog) => void;
  getVirtualMachineDetails: (virtualMachine?: IVirtualMachine) => void;
  selectedVirtualMachineDetails?: IVirtualMachineExtendedDetails;
  listRoleManagementPolicy: (parameters: IPimRoleManagementPolicyListRequestParameters) => void;
  clearQuickActivationDialogValue: () => void;
}

export const RamDialogs: React.FunctionComponent<RamDialogsProps> = (props: RamDialogsProps) => {
  const [hideManualApproveMessageDialog, setHideManualApproveMessageDialog] = useState<boolean>(true);

  const onTicketInfoChange = useCallback(
    (newTicketSystem?: string, newTicketNumber?: string) => {
      const system = newTicketSystem ?? props.quickActivationRole.roleProperties.ticketInfo?.ticketSystem ?? '';
      const number = newTicketNumber ?? props.quickActivationRole.roleProperties.ticketInfo?.ticketNumber ?? '';
      return props.setQuickActivationRole({
        ...props.quickActivationRole,
        roleProperties: {
          ...props.quickActivationRole.roleProperties,
          ticketInfo: {
            ticketSystem: system,
            ticketNumber: number
          }
        }
      });
    },
    [props]
  );

  const onRoleDurationChange = useCallback(
    (newDuration: string) => {
      return props.setQuickActivationRole({
        ...props.quickActivationRole,
        roleDurationInIso8601Format: newDuration
      });
    },
    [props]
  );

  useEffect(() => {
    if (props.ramErrorDialogContent) {
      props.onDismissQuckActivationDialog();
      props.onDismissActivationStatusDialog();
      props.setHideConnectionDialog(true);
      setHideManualApproveMessageDialog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ramErrorDialogContent]);

  return (
    <>
      {props.ramErrorDialogContent && (
        <RamErrorDialog
          onDismiss={() => {
            props.setRamErrorDialog(undefined);
          }}
          onContinue={
            props.ramErrorDialogContent.code === PimRoleActivationErrorCode.RoleAssignmentExists
              ? () => {
                  props.setHideConnectionDialog(false);
                }
              : undefined
          }
          {...props.ramErrorDialogContent}
        />
      )}
      {props.showQuickActivationDialog && (
        <QuickActivationDialog
          activateRamAccess={props.activateRamAccess}
          onDismiss={props.onDismissQuckActivationDialog}
          editRoleJustification={(newValue: string) =>
            props.setQuickActivationRole({
              ...props.quickActivationRole,
              roleProperties: { ...props.quickActivationRole.roleProperties, justification: newValue }
            })
          }
          roleInfo={props.quickActivationRole}
          setActivationStatus={props.setActivationStatus}
          isJustificationRequired={props.isJustificationRequired}
          isTicketingRequired={props.isTicketingRequired}
          editRoleTicketing={onTicketInfoChange}
          validateRoleActivation={props.validateRoleActivation}
          isMfaRequirementMet={props.isMfaRequirementMet}
          setRamErrorDialog={props.setRamErrorDialog}
          virtualMachine={props.selectedVm}
          editRoleDuration={onRoleDurationChange}
          maximumDuration={props.maximumDuration}
          listRoleManagementPolicy={props.listRoleManagementPolicy}
          setQuickActivationRole={props.setQuickActivationRole}
          clearQuickActivationDialogValue={props.clearQuickActivationDialogValue}
        />
      )}
      {props.activationStatus.status !== CycleActivationStatus.None && (
        <ActivationStatusDialog
          onDismiss={() => {
            if (props.activationStatus.status === CycleActivationStatus.Provisioned) {
              props.setHideConnectionDialog(false);
            } else if (props.activationStatus.status === CycleActivationStatus.PendingApprovalProvisioning) {
              setHideManualApproveMessageDialog(false);
            }
            props.onDismissActivationStatusDialog();
          }}
          activationStatus={props.activationStatus.status}
        ></ActivationStatusDialog>
      )}
      {!props.hideConnectionDialog && (
        <ConnectionDialog
          onDismiss={() => {
            props.setHideConnectionDialog(true);
            if (props.activationStatus.roleEndTime !== '') props.refreshEntities();
            props.setActivationStatus({ status: CycleActivationStatus.None, roleEndTime: '' });
          }}
          virtualMachine={props.selectedVm}
          publicIpAddress={props.selectedVm.publicIpAddress}
          privateIpAddress={props.selectedVm.publicIpAddress} // TODO: remove private IP or add field in IVm for private IP
          virtualMachineName={props.selectedVm.displayName}
          virtualMachineId={props.selectedVm.id}
          roleEndTime={props.activationStatus.roleEndTime}
          userName={props.selectedVm.username}
          virtualMachineType={props.selectedVm.resourceType}
          virtualMachineOsType={props.selectedVm.osType}
          setRamErrorDialog={props.setRamErrorDialog}
          getVirtualMachineDetails={props.getVirtualMachineDetails}
          selectedVirtualMachineDetails={props.selectedVirtualMachineDetails}
          quickActivationRole={props.quickActivationRole}
        />
      )}
      {!hideManualApproveMessageDialog && (
        <ManualApproveMessageDialog
          virtualMachineName={props.selectedVm.displayName}
          onDismiss={() => {
            setHideManualApproveMessageDialog(true);
            props.refreshEntities();
          }}
        />
      )}
    </>
  );
};
