import { AppPanel } from '@uifabric/portal-ux/lib/AppPanel';

import { css, PanelType } from '@fluentui/react';
import {
  IWithResponsiveModeState,
  withResponsiveMode
} from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import * as React from 'react';
import { InjectedTranslateProps, TranslationFunction } from 'react-i18next';
import { IApprovalStep } from '../../../models/ELM/IApprovalStep';
import { IGrantRequest } from '../../../models/ELM/IGrantRequest';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { RequestSummary } from '../../Shared/RequestSummary/RequestSummary';
import { GrantRequestApprovalHistory } from '../GrantRequestApprovalHistory/GrantRequestApprovalHistory';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');
export interface ICompletedGrantRequestDetailsProps
  extends IWithResponsiveModeState,
  InjectedTranslateProps {
  grantRequest: IGrantRequest | undefined;
  decisions: IApprovalStep[];
  t: TranslationFunction;
  onDismiss(): void;
  showMoreGrantRequestDetails(): void;
  enableApproverRevoke?: boolean;
}

@withResponsiveMode
export class CompletedGrantRequestDetails extends React.Component<
  ICompletedGrantRequestDetailsProps
> {
  constructor(nextProps: ICompletedGrantRequestDetailsProps) {
    super(nextProps);
  }

  public render(): JSX.Element {
    return (
      <AppPanel
        isBlocking={true}
        isOpen={true}
        onDismiss={this.props.onDismiss}
        type={PanelType.smallFixedFar}
        headerText={this.props.t(LocaleKeys.accessRequest)}
        isLightDismiss={true}
        closeButtonAriaLabel={this.props.t(LocaleKeys.cancel)}
        hasCloseButton={true}
      >
        {this._onRenderBodyTop()}
      </AppPanel>
    );
  }

  private _onRenderBodyTop = (): JSX.Element => {
    const { t, grantRequest } = this.props;
    if (!grantRequest) {
      return <div />;
    }

    return (
      <div>
        <div className={css(myAccessStyles.marginBottomMedium)}>
          <RequestSummary
            t={t}
            requestorName={grantRequest.requestor?.displayName || ''}
            targetName={grantRequest.accessPackageAssignment.target?.displayName || ''}
            entitlementName={grantRequest.accessPackageAssignment.accessPackage!.displayName || ''}
            grantRequest={grantRequest}
            showMoreGrantRequestDetails={this.props.showMoreGrantRequestDetails}
          />
        </div>
        <hr />
        <GrantRequestApprovalHistory grantRequest={grantRequest} t={t} enableApproverRevoke={this.props.enableApproverRevoke} />
      </div>
    );
  }
}
