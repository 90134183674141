import { useSelector } from '../index';

interface IUseGetUserImageUrl {
  userImageUrl?: string;
}

export const useGetUserImageUrl = (): IUseGetUserImageUrl => {
  const { userImageUrl } = useSelector((state) => ({
    userImageUrl: state.user.userImage?.image
  }));

  return {
    userImageUrl
  };
};
