import {
  ColumnActionsMode,
  css,
  FontClassNames,
  IColumn,
  Icon,
  Link,
  Pivot,
  PivotItem,
  ProgressIndicator,
  SelectionMode
} from '@fluentui/react';
import { ResponsiveMode, withResponsiveMode } from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import { QosProvider } from '@iamexperiences/ecos-telemetry';
import { ErrorBanner } from '@microsoft/portal-app/lib/Banners/ErrorBanner';
import * as React from 'react';
import { TranslationFunction } from 'react-i18next';
import { isNullOrUndefined } from 'util';

import { EntitlementActions } from '../../../models';
import { AccessReviewType } from '../../../models/AccessReviews/AccessReviewType';
import { IAccessReview } from '../../../models/AccessReviews/IAccessReview';
import { RecurrenceType } from '../../../models/AccessReviews/RecurrenceType';
import { IEntity } from '../../../models/ELM/IEntity';
import { EntityType } from '../../../models/EntityType';
import { IListColumn } from '../../../models/IListColumn';
import { ReviewScopeResourceType } from '../../../models/RequestApprovals/DecisionType';
import { history } from '../../../redux/configureStore';
import { checkFeatureAccess } from '../../../shared';
import { asLocalizedText } from '../../../shared/asLocalizedText';
import { logOnDevelopment } from '../../../shared/consoleLogger';
import { allRolesReviewTypeId } from '../../../shared/constants';
import { FormatDate } from '../../../shared/FormatDateTime';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { Routes } from '../../../shared/Routes';
import { getNewColumnsOnColumnClicked } from '../../../shared/sortingHelper';
import { getInlineSpinner, getSpinner } from '../../../shared/spinner';
import { ChevronButton } from '../../Shared/ChevronButton/ChevronButton';
import { ColumnValue } from '../../Shared/ColumnValue/ColumnValue';
import { InfinityList } from '../../Shared/InfinityList/InfinityList';
import { AccessReviewsPivot, IAccessReviewsListProps, IAccessReviewsListState } from './AccessReviewsList.types';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');
const myAccessListStyles = require('../../../css/myAccessList.scoped.scss');
const detailsPageStyles = require('@microsoft/portal-app/lib/styling/patterns/DetailsPage.scoped.scss');
const globalStyles = require('../../../css/global.scss');

/* Represent the list of request history. */
@withResponsiveMode
export class AccessReviewsList extends React.Component<IAccessReviewsListProps, IAccessReviewsListState> {
  private showNoEntitiesGroups = false;
  private showNoEntitiesPackages = false;
  private showNoEntitiesAadRoles = false;
  private showNoEntitiesRbac = false;
  private showNoEntitiesByod = false;
  private showNoEntitiesUserAccess = false;
  private totalReviewCount = 0;

  constructor(nextProps: IAccessReviewsListProps) {
    const supervisorCentricAccessReviews = checkFeatureAccess('supervisorCentricAccessReviews');
    super(nextProps);
    this.state = {
      entitlement: undefined,
      columns: this._getAccessReviewsListColumns(
        AccessReviewType.Group,
        this._getResponsiveMode(),
        this.props.t,
        supervisorCentricAccessReviews
      ),
      elmColumns: this._getAccessReviewsListColumns(
        AccessReviewType.AccessPackage,
        this._getResponsiveMode(),
        this.props.t,
        supervisorCentricAccessReviews
      ),
      aadRolesColumns: this._getAccessReviewsListColumns(
        AccessReviewType.AadRole,
        this._getResponsiveMode(),
        this.props.t,
        supervisorCentricAccessReviews
      ),
      rbacColumns: this._getAccessReviewsListColumns(
        AccessReviewType.Rbac,
        this._getResponsiveMode(),
        this.props.t,
        supervisorCentricAccessReviews
      ),
      byodColumns: this._getAccessReviewsListColumns(
        AccessReviewType.Byod,
        this._getResponsiveMode(),
        this.props.t,
        supervisorCentricAccessReviews
      ),
      userAccessColumns: this._getAccessReviewsListColumns(
        AccessReviewType.UserAccess,
        this._getResponsiveMode(),
        this.props.t,
        supervisorCentricAccessReviews
      ),
      selectedPivot: AccessReviewsPivot.GroupsApps,
      showGroups: false
    };
  }

  public componentDidUpdate(prevProps: IAccessReviewsListProps): void {
    if (
      prevProps.accessReviewsList.length !== this.props.accessReviewsList.length ||
      prevProps.isLoading !== this.props.isLoading
    ) {
      this.props.accessReviewsList.forEach((review: IAccessReview) => {
        if (isNullOrUndefined(review.decisionsSummary)) {
          this.props.getDecisionsSummary(review.id);
        }
      });
    }
    if (
      prevProps.accessPackageReviewsList.length !== this.props.accessPackageReviewsList.length ||
      prevProps.isLoading !== this.props.isLoading
    ) {
      this.props.accessPackageReviewsList.forEach((review: IAccessReview) => {
        if (isNullOrUndefined(review.decisionsSummary)) {
          this.props.getDecisionsSummary(review.id);
        }
      });
    }
    if (
      prevProps.aadRolesReviewsList.length !== this.props.aadRolesReviewsList.length ||
      prevProps.isLoading !== this.props.isLoading
    ) {
      this.props.aadRolesReviewsList.forEach((review: IAccessReview) => {
        if (isNullOrUndefined(review.decisionsSummary)) {
          this.props.getDecisionsSummary(review.id);
        }
      });
    }
    if (
      prevProps.rbacReviewsList.length !== this.props.rbacReviewsList.length ||
      prevProps.isLoading !== this.props.isLoading
    ) {
      this.props.rbacReviewsList.forEach((review: IAccessReview) => {
        if (isNullOrUndefined(review.decisionsSummary)) {
          this.props.getDecisionsSummary(review.id);
        }
      });
    }
    if (
      prevProps.byodReviewsList.length !== this.props.byodReviewsList.length ||
      prevProps.isLoading !== this.props.isLoading
    ) {
      this.props.byodReviewsList.forEach((review: IAccessReview) => {
        if (isNullOrUndefined(review.decisionsSummary)) {
          this.props.getDecisionsSummary(review.id);
        }
      });
    }
    if (
      prevProps.userAccessReviewsList.length !== this.props.userAccessReviewsList.length ||
      prevProps.isLoading !== this.props.isLoading
    ) {
      this.props.userAccessReviewsList.forEach((review: IAccessReview) => {
        if (isNullOrUndefined(review.decisionsSummary)) {
          this.props.getDecisionsSummary(review.id);
        }
      });
    }

    if (prevProps.responsiveMode !== this.props.responsiveMode) {
      const supervisorCentricAccessReviews = checkFeatureAccess('supervisorCentricAccessReviews');
      this.setState({
        ...this.state,
        columns: this._getAccessReviewsListColumns(
          AccessReviewType.Group,
          this._getResponsiveMode(),
          this.props.t,
          supervisorCentricAccessReviews
        ),
        elmColumns: this._getAccessReviewsListColumns(
          AccessReviewType.AccessPackage,
          this._getResponsiveMode(),
          this.props.t,
          supervisorCentricAccessReviews
        ),
        aadRolesColumns: this._getAccessReviewsListColumns(
          AccessReviewType.AadRole,
          this._getResponsiveMode(),
          this.props.t,
          supervisorCentricAccessReviews
        ),
        rbacColumns: this._getAccessReviewsListColumns(
          AccessReviewType.Rbac,
          this._getResponsiveMode(),
          this.props.t,
          supervisorCentricAccessReviews
        ),
        byodColumns: this._getAccessReviewsListColumns(
          AccessReviewType.Byod,
          this._getResponsiveMode(),
          this.props.t,
          supervisorCentricAccessReviews
        ),
        userAccessColumns: this._getAccessReviewsListColumns(
          AccessReviewType.UserAccess,
          this._getResponsiveMode(),
          this.props.t,
          supervisorCentricAccessReviews
        )
      });
    }
  }

  public componentDidMount(): void {
    this.setState({
      showGroups: true,
      selectedPivot: AccessReviewsPivot.GroupsApps
    });

    this.props.getAccessReviewsFeatureFlags();
    this.props.setSearchContext(EntitlementActions.searchAccessReviewsOnServer);
    this.props.setReviewListPivot(AccessReviewsPivot.GroupsApps);
    this.props.refreshAccessReviews();
  }

  public componentWillUnmount(): void {
    if (this.props.showingConfirmDialog) {
      logOnDevelopment();
    }
  }

  public render(): JSX.Element {
    const {
      isGroupsAppsRefreshing,
      isAccessPackagesRefreshing,
      isAadRolesRefreshing,
      isRbacRefreshing,
      isByodRefreshing,
      isUserAccessRefreshing,
      errorHasOccurred,
      errorCode,
      isLoadingMoreGroupsApps,
      isLoadingMoreAccessPackages,
      isLoadingMoreAadRoles,
      isLoadingMoreRbac,
      isLoadingMoreByod,
      isLoadingMoreUserAccess,
      t,
      isTenantWhitelisted
    } = this.props;

    if (errorHasOccurred) {
      if (!isTenantWhitelisted) {
        return (
          <main data-automation-id="AccessReviewsListPage" className={css(globalStyles.detailsPage)}>
            <ErrorBanner text={t(LocaleKeys.tenantNotWhitelistedMessage)} />
          </main>
        );
      }

      if (errorCode === 0 || errorCode === 429) {
        return (
          <main data-automation-id="AccessReviewsListPage" className={css(globalStyles.detailsPage)}>
            <ErrorBanner text={t(LocaleKeys.throttleError)} />
          </main>
        );
      }

      return (
        <main data-automation-id="AccessReviewsListPage" className={css(globalStyles.detailsPage)}>
          <ErrorBanner
            text={t(LocaleKeys.errorMessage)}
            onAction={this.props.getEntities}
            actionText={t(LocaleKeys.retry)}
          />
        </main>
      );
    }

    const accessReviews = this.props.accessReviewsList as IAccessReview[];
    const accessPackageReviews = this.props.accessPackageReviewsList as IAccessReview[];
    const aadRolesReviews = this.props.aadRolesReviewsList as IAccessReview[];
    const rbacReviews = this.props.rbacReviewsList as IAccessReview[];
    const byodReviews = this.props.byodReviewsList as IAccessReview[];
    const userAccessReviews = this.props.userAccessReviewsList as IAccessReview[];
    const { isSearching, isFiltering } = this.props;

    const totalReviews = this._getTotalReviewCount();
    const totalActiveReviews = this._getOverallReviewCount();
    const supervisorCentricAccessReviews = checkFeatureAccess('supervisorCentricAccessReviews');

    this.showNoEntitiesGroups =
      accessReviews.length === 0 && !isGroupsAppsRefreshing && !isFiltering && !isSearching && !isLoadingMoreGroupsApps;

    this.showNoEntitiesPackages =
      accessPackageReviews.length === 0 &&
      !isAccessPackagesRefreshing &&
      !isFiltering &&
      !isSearching &&
      !isLoadingMoreAccessPackages;

    this.showNoEntitiesAadRoles =
      aadRolesReviews.length === 0 && !isAadRolesRefreshing && !isFiltering && !isSearching && !isLoadingMoreAadRoles;

    this.showNoEntitiesRbac =
      rbacReviews.length === 0 && !isRbacRefreshing && !isFiltering && !isSearching && !isLoadingMoreRbac;

    this.showNoEntitiesByod =
      byodReviews.length === 0 && !isByodRefreshing && !isFiltering && !isSearching && !isLoadingMoreByod;

    this.showNoEntitiesUserAccess =
      userAccessReviews.length === 0 &&
      !isUserAccessRefreshing &&
      !isFiltering &&
      !isSearching &&
      !isLoadingMoreUserAccess;

    let filteredCount;
    let loading;
    switch (this.state.selectedPivot) {
      case AccessReviewsPivot.GroupsApps:
        filteredCount = accessReviews.length;
        loading = isLoadingMoreGroupsApps;
        break;
      case AccessReviewsPivot.AccessPackages:
        filteredCount = accessPackageReviews.length;
        loading = isLoadingMoreAccessPackages;
        break;
      case AccessReviewsPivot.AadRoles:
        filteredCount = aadRolesReviews.length;
        loading = isLoadingMoreAadRoles;
        break;
      case AccessReviewsPivot.Rbac:
        filteredCount = rbacReviews.length;
        loading = isLoadingMoreRbac;
        break;
      case AccessReviewsPivot.Byod:
        filteredCount = byodReviews.length;
        loading = isLoadingMoreByod;
        break;
      case AccessReviewsPivot.UserAccess:
        filteredCount = userAccessReviews.length;
        loading = isLoadingMoreUserAccess;
        break;
    }

    const showNoFilteredResults = !loading && (isFiltering || isSearching) && filteredCount === 0;

    const showGrid =
      (!isGroupsAppsRefreshing && this.state.selectedPivot === AccessReviewsPivot.GroupsApps) ||
      (!isAccessPackagesRefreshing && this.state.selectedPivot === AccessReviewsPivot.AccessPackages) ||
      (!isAadRolesRefreshing && this.state.selectedPivot === AccessReviewsPivot.AadRoles) ||
      (!isRbacRefreshing && this.state.selectedPivot === AccessReviewsPivot.Rbac) ||
      (!isByodRefreshing && this.state.selectedPivot === AccessReviewsPivot.Byod) ||
      (!isUserAccessRefreshing && this.state.selectedPivot === AccessReviewsPivot.UserAccess);

    return (
      <QosProvider name="MyAccessAccessReviewsList">
        <main data-automation-id="AccessReviewsListPage" className={css(globalStyles.detailsPage)}>
          {/* Banners */}
          {this.props.featureFlags?.sqlOutage ? (
            <>
              <div
                className={css(myAccessStyles.emergencyBannerContainer)}
                arial-aria-label={t(LocaleKeys.accessReviewsAvailabilityBannerMessage)}
              >
                <Icon
                  iconName={'Warning'}
                  className={css(
                    myAccessStyles.warningColor,
                    myAccessStyles.marginLeftXSmall,
                    myAccessStyles.smallIcon
                  )}
                />
                <span className={css(myAccessStyles.marginLeftSmall)}>
                  {t(LocaleKeys.accessReviewsAvailabilityBannerMessage)}
                </span>
              </div>
            </>
          ) : null}
          {
            // TODO: Replace with correct feature flag usages this.props.features.supervisorCentricAccessReviews
            supervisorCentricAccessReviews ? (
              <>
                <div
                  className={css(myAccessStyles.emergencyBannerContainer)}
                  arial-aria-label={t(LocaleKeys.userAccessAvailabilityBannerMessage)}
                  style={{ backgroundColor: '#DFF6DD' }}
                >
                  <Icon
                    iconName={'Checkmark'}
                    className={css(
                      myAccessStyles.checkColor,
                      myAccessStyles.marginLeftXSmall,
                      myAccessStyles.smallIcon
                    )}
                  />
                  <span className={css(myAccessStyles.marginLeftSmall)} style={{ fontWeight: 'bold' }}>
                    {t(LocaleKeys.new)}
                  </span>
                  <span className={css(myAccessStyles.marginLeftXSmall)}>
                    {t(LocaleKeys.userAccessAvailabilityBannerMessage)}
                  </span>
                  {/* <span className={css(myAccessStyles.marginLeftXSmall, myAccessStyles.linkColor)}> //TODO: Links not ready until public preview. Hiding this until then
                    <Link
                      href="https://aka.ms/MyAccessUserAccess"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t(LocaleKeys.learnMoreText)}
                    </Link>
                  </span> */}
                </div>
              </>
            ) : null
          }

          <div className={css(myAccessListStyles.listPage, myAccessStyles.detailPageContent)}>
            <div className={css(myAccessListStyles.padding)}>
              <h1 className={css(myAccessListStyles.pageTitle, myAccessListStyles.pageTitleH1Margin)}>
                {t(LocaleKeys.accessReviews)}
              </h1>
              <div
                className={css(
                  myAccessStyles.marginTopXSmall,
                  myAccessStyles.marginBottomSmall,
                  myAccessListStyles.pageSubtitle
                )}
              >
                <div>
                  {supervisorCentricAccessReviews ? (
                    <>
                      <strong>
                        {asLocalizedText(
                          {
                            key: LocaleKeys.entityNameWithCount,
                            options: {
                              count: totalActiveReviews,
                              entityName: t(LocaleKeys.activeReviews, {
                                count: totalActiveReviews
                              })
                            }
                          },
                          t
                        )}
                      </strong>
                      {/* <span className={css(myAccessStyles.marginLeftXSmall)}>{t(LocaleKeys.learnMoreWithOur)}</span>
                          <span className={css(myAccessStyles.marginLeftXSmall, myAccessStyles.linkColor)} style={{ marginLeft: '3px' }}>
                            <Link href="https://aka.ms/MyAccessUserAccess" target="_blank" rel="noopener noreferrer">
                              {t(LocaleKeys.accessReviewWalkthrough)}
                              <Icon iconName={'OpenInNewTab'} className={css(myAccessStyles.smallIcon)} style={{ marginLeft: '5px' }} />
                            </Link>
                          </span> */}
                    </>
                  ) : (
                    asLocalizedText(
                      {
                        key: LocaleKeys.entityNameWithCount,
                        options: {
                          count: totalReviews,
                          entityName: t(LocaleKeys.review, {
                            count: totalReviews
                          })
                        }
                      },
                      t
                    )
                  )}
                </div>
              </div>
            </div>
            {this.state.showGroups ? this._renderPivots(supervisorCentricAccessReviews) : null}
            <div className={css(myAccessListStyles.listPage, myAccessListStyles.padding)}>
              {this._renderReviewGrid(t, showGrid, showNoFilteredResults)}
            </div>
          </div>
        </main>
      </QosProvider>
    );
  }

  private _renderPivots(supervisorCentricAccessReviews: boolean): JSX.Element {
    const { t } = this.props;

    const tableStyles = {
      overflowX: 'auto',
      whiteSpace: 'nowrap'
    };

    const pivotItems = [
      <PivotItem
        key={AccessReviewsPivot.GroupsApps}
        headerText={
          supervisorCentricAccessReviews
            ? asLocalizedText(
                {
                  key: LocaleKeys.entityNameWithCountRev,
                  options: {
                    entityName: t(LocaleKeys.groupAndAppMembership, {
                      count: this.props.accessReviewsList.length
                    }),
                    count: this.props.accessReviewsList.length
                  }
                },
                t
              )
            : this.props.t(LocaleKeys.groupsApps, { context: 'capitalize' })
        }
        itemKey={AccessReviewsPivot.GroupsApps}
      />,
      <PivotItem
        key={AccessReviewsPivot.AccessPackages}
        headerText={
          supervisorCentricAccessReviews
            ? asLocalizedText(
                {
                  key: LocaleKeys.entityNameWithCountRev,
                  options: {
                    entityName: t(LocaleKeys.accessPackageAssignment, {
                      count: this.props.accessPackageReviewsList.length
                    }),
                    count: this.props.accessPackageReviewsList.length
                  }
                },
                t
              )
            : this.props.t(LocaleKeys.accessPackage, { context: 'plural' })
        }
        itemKey={AccessReviewsPivot.AccessPackages}
      />
    ];

    if (checkFeatureAccess('pimAccessReviews')) {
      pivotItems.push(
        <PivotItem
          headerText={this.props.t(LocaleKeys.aadRole, { context: 'plural' })}
          itemKey={AccessReviewsPivot.AadRoles}
        />
      );
      pivotItems.push(
        <PivotItem
          headerText={this.props.t(LocaleKeys.azureRbac, { context: 'plural' })}
          itemKey={AccessReviewsPivot.Rbac}
        />
      );
    }

    // TODO: Replace with correct feature flag usages this.props.features.supervisorCentricAccessReviews
    if (supervisorCentricAccessReviews) {
      pivotItems.push(
        <PivotItem
          key={AccessReviewsPivot.UserAccess}
          headerText={asLocalizedText(
            {
              key: LocaleKeys.entityNameWithCountRev,
              options: {
                entityName: t(LocaleKeys.userAccess, {
                  count: this.props.userAccessReviewsList.length
                }),
                count: this.props.userAccessReviewsList.length
              }
            },
            t
          )}
          itemKey={AccessReviewsPivot.UserAccess}
        />
      );
    }

    if (this.props.features.byodAccessReviews) {
      pivotItems.push(
        <PivotItem
          headerText={this.props.t(LocaleKeys.byod)}
          itemKey={AccessReviewsPivot.Byod}
          key={AccessReviewsPivot.Byod}
        />
      );
    }

    return (
      <div
        style={tableStyles as React.CSSProperties}
        className={css(
          'ms-DetailsPivot',
          detailsPageStyles.detailsPivot,
          myAccessStyles.detailsPivot,
          myAccessStyles.subtitle
        )}
      >
        <Pivot selectedKey={this.state.selectedPivot} onLinkClick={this._handlePivotClick} headersOnly={true}>
          {pivotItems}
        </Pivot>
      </div>
    );
  }

  private _renderReviewGrid(t: TranslationFunction, showGrid: boolean, showNoFilteredResults: boolean): JSX.Element {
    const accessReviews = this.props.accessReviewsList as IAccessReview[];
    const accessPackageReviews = this.props.accessPackageReviewsList as IAccessReview[];
    const aadRolesReviews = this.props.aadRolesReviewsList as IAccessReview[];
    const rbacReviews = this.props.rbacReviewsList as IAccessReview[];
    const byodReviews = this.props.byodReviewsList as IAccessReview[];
    const userAccessReviews = this.props.userAccessReviewsList as IAccessReview[];

    let isPivotLoading = false;
    switch (this.state.selectedPivot) {
      case AccessReviewsPivot.GroupsApps:
        isPivotLoading = this.props.isLoadingMoreGroupsApps;
        break;
      case AccessReviewsPivot.AccessPackages:
        isPivotLoading = this.props.isLoadingMoreAccessPackages;
        break;
      case AccessReviewsPivot.AadRoles:
        isPivotLoading = this.props.isLoadingMoreAadRoles;
        break;
      case AccessReviewsPivot.Rbac:
        isPivotLoading = this.props.isLoadingMoreRbac;
        break;
      case AccessReviewsPivot.Byod:
        isPivotLoading = this.props.isLoadingMoreByod;
        break;
      case AccessReviewsPivot.UserAccess:
        isPivotLoading = this.props.isLoadingMoreUserAccess;
        break;
    }
    const showLoadMore =
      !this.props.pageMetaData.isAllEntitiesFullyCached &&
      !this.props.isLoading &&
      !isPivotLoading &&
      !this.props.pageMetaData.isFilteredEntitiesFullyCached;

    if (showGrid) {
      switch (this.state.selectedPivot) {
        case AccessReviewsPivot.GroupsApps:
          return (
            <InfinityList
              t={t}
              entityList={accessReviews}
              entityType={EntityType.accessReviews}
              ariaLabel={t(LocaleKeys.accessReviewsGridAriaLabel)}
              columns={this.state.columns as Array<IListColumn<IEntity>>}
              showLoadMore={showLoadMore}
              showSpinner={this.props.isLoadingMoreGroupsApps}
              spinnerLabel={t(LocaleKeys.loadingPage, {
                pageName: t(LocaleKeys.reviews, { count: 0 })
              })}
              showNoEntities={this.showNoEntitiesGroups}
              noEntitiesProps={{
                iconName: 'PlannerLogo',
                noRowMessage: LocaleKeys.noAccessReviewsMessage,
                showButton: false
              }}
              showNoFilteredResults={showNoFilteredResults}
              onLoadMore={this._loadMore}
              onItemSelected={this._onItemSelected}
              selectionMode={SelectionMode.none}
              isExpanded={false}
            />
          );
        case AccessReviewsPivot.AccessPackages:
          return (
            <InfinityList
              t={t}
              entityList={accessPackageReviews}
              entityType={EntityType.accessPackageReviews}
              ariaLabel={t(LocaleKeys.accessReviewsGridAriaLabel)}
              columns={this.state.elmColumns as Array<IListColumn<IEntity>>}
              showLoadMore={showLoadMore}
              showSpinner={this.props.isLoadingMoreAccessPackages}
              spinnerLabel={t(LocaleKeys.loadingPage, {
                pageName: t(LocaleKeys.reviews, { count: 0 })
              })}
              showNoEntities={this.showNoEntitiesPackages}
              noEntitiesProps={{
                iconName: 'PlannerLogo',
                noRowMessage: LocaleKeys.noAccessReviewsMessage,
                showButton: false
              }}
              showNoFilteredResults={showNoFilteredResults}
              onLoadMore={this._loadMoreAccessPackageReviews}
              onItemSelected={this._onItemSelected}
              selectionMode={SelectionMode.none}
              isExpanded={false}
            />
          );
        case AccessReviewsPivot.AadRoles:
          return (
            <InfinityList
              t={t}
              entityList={aadRolesReviews}
              entityType={EntityType.aadRoleReviews}
              ariaLabel={t(LocaleKeys.accessReviewsGridAriaLabel)}
              columns={this.state.aadRolesColumns as Array<IListColumn<IEntity>>}
              showLoadMore={showLoadMore}
              showSpinner={this.props.isLoadingMoreAadRoles}
              spinnerLabel={t(LocaleKeys.loadingPage, {
                pageName: t(LocaleKeys.reviews, { count: 0 })
              })}
              showNoEntities={this.showNoEntitiesAadRoles}
              noEntitiesProps={{
                iconName: 'PlannerLogo',
                noRowMessage: LocaleKeys.noAccessReviewsMessage,
                showButton: false
              }}
              showNoFilteredResults={showNoFilteredResults}
              onLoadMore={this._loadMoreAadRoleReviews}
              onItemSelected={this._onItemSelected}
              selectionMode={SelectionMode.none}
              isExpanded={false}
            />
          );
        case AccessReviewsPivot.Rbac:
          return (
            <InfinityList
              t={t}
              entityList={rbacReviews}
              entityType={EntityType.rbacReviews}
              ariaLabel={t(LocaleKeys.accessReviewsGridAriaLabel)}
              columns={this.state.rbacColumns as Array<IListColumn<IEntity>>}
              showLoadMore={showLoadMore}
              showSpinner={this.props.isLoadingMoreRbac}
              spinnerLabel={t(LocaleKeys.loadingPage, {
                pageName: t(LocaleKeys.reviews, { count: 0 })
              })}
              showNoEntities={this.showNoEntitiesRbac}
              noEntitiesProps={{
                iconName: 'PlannerLogo',
                noRowMessage: LocaleKeys.noAccessReviewsMessage,
                showButton: false
              }}
              showNoFilteredResults={showNoFilteredResults}
              onLoadMore={this._loadMoreRbacRoleReviews}
              onItemSelected={this._onItemSelected}
              selectionMode={SelectionMode.none}
              isExpanded={false}
            />
          );
        case AccessReviewsPivot.Byod:
          return (
            <InfinityList
              t={t}
              entityList={byodReviews}
              entityType={EntityType.byodReviews}
              ariaLabel={t(LocaleKeys.accessReviewsGridAriaLabel)}
              columns={this.state.byodColumns as Array<IListColumn<IEntity>>}
              showLoadMore={showLoadMore}
              showSpinner={this.props.isLoadingMoreByod}
              spinnerLabel={t(LocaleKeys.loadingPage, {
                pageName: t(LocaleKeys.reviews, { count: 0 })
              })}
              showNoEntities={this.showNoEntitiesByod}
              noEntitiesProps={{
                iconName: 'PlannerLogo',
                noRowMessage: LocaleKeys.noAccessReviewsMessage,
                showButton: false
              }}
              showNoFilteredResults={showNoFilteredResults}
              onLoadMore={this._loadMoreByodReviews}
              onItemSelected={this._onItemSelected}
              selectionMode={SelectionMode.none}
              isExpanded={false}
            />
          );
        case AccessReviewsPivot.UserAccess:
          return (
            <InfinityList
              t={t}
              entityList={userAccessReviews}
              entityType={EntityType.userAccessReviews}
              ariaLabel={t(LocaleKeys.accessReviewsGridAriaLabel)}
              columns={this.state.userAccessColumns as Array<IListColumn<IEntity>>}
              showLoadMore={showLoadMore}
              showSpinner={this.props.isLoadingMoreUserAccess}
              spinnerLabel={t(LocaleKeys.loadingPage, {
                pageName: t(LocaleKeys.reviews, { count: 0 })
              })}
              showNoEntities={this.showNoEntitiesUserAccess}
              noEntitiesProps={{
                iconName: 'PlannerLogo',
                noRowMessage: LocaleKeys.noAccessReviewsMessage,
                showButton: false
              }}
              showNoFilteredResults={showNoFilteredResults}
              onLoadMore={this._loadMoreUserAccessReviews}
              onItemSelected={this._onItemSelected}
              selectionMode={SelectionMode.none}
              isExpanded={false}
            />
          );
        default:
          return <div />;
      }
    } else {
      return getSpinner(
        t(LocaleKeys.loadingPage, {
          pageName: t(LocaleKeys.reviews, { count: 0 })
        })
      );
    }
  }

  private readonly _onItemSelected = (selectedItems: IAccessReview[]): void => {
    logOnDevelopment(selectedItems);
  };

  private readonly _loadMore = (): void => {
    if (!this.props.isLoading) {
      if (this.props.isSearching) {
        this._searchEntitiesOnServer();
      }
      this._getEntities();
    }
  };

  private readonly _loadMoreAccessPackageReviews = (): void => {
    if (!this.props.isLoading) {
      if (this.props.isSearching) {
        this._searchEntitiesOnServer();
      }
      this._getEntitiesType(EntityType.accessPackageReviews);
    }
  };

  private readonly _loadMoreAadRoleReviews = (): void => {
    if (!this.props.isLoading) {
      if (this.props.isSearching) {
        this._searchEntitiesOnServer();
      }
      this._getEntitiesType(EntityType.aadRoleReviews);
    }
  };

  private readonly _loadMoreRbacRoleReviews = (): void => {
    if (!this.props.isLoading) {
      if (this.props.isSearching) {
        this._searchEntitiesOnServer();
      }
      this._getEntitiesType(EntityType.rbacReviews);
    }
  };

  private readonly _loadMoreByodReviews = (): void => {
    if (!this.props.isLoading) {
      if (this.props.isSearching) {
        this._searchEntitiesOnServer();
      }
      this._getEntitiesType(EntityType.byodReviews);
    }
  };

  private readonly _loadMoreUserAccessReviews = (): void => {
    if (!this.props.isLoading) {
      if (this.props.isSearching) {
        this._searchEntitiesOnServer();
      }
      this._getEntitiesType(EntityType.userAccessReviews);
    }
  };

  private readonly _getEntities = (): void => {
    if (this.props.pageMetaData.isAllEntitiesFullyCached || this.props.isLoading) {
      return;
    }
    this.props.getEntities();
  };

  private readonly _getEntitiesType = (type: EntityType): void => {
    if (this.props.pageMetaData.isAllEntitiesFullyCached || this.props.isLoading) {
      return;
    }
    this.props.loadMoreEntities(type);
  };

  private readonly _searchEntitiesOnServer = (): void => {
    if (this.props.pageMetaData.isAllEntitiesFullyCached || this.props.pageMetaData.isFilteredEntitiesFullyCached) {
      return;
    }
    this.props.searchForMore();
  };

  private _getResponsiveMode(): ResponsiveMode {
    let { responsiveMode } = this.props;
    if (responsiveMode === undefined) {
      responsiveMode = ResponsiveMode.large;
    }
    return responsiveMode;
  }

  private readonly _getAccessReviewsListColumns = (
    gridKey: string,
    responsiveMode: ResponsiveMode,
    t: TranslationFunction,
    isSupervisorCentric: boolean
  ): Array<IListColumn<IAccessReview>> => {
    const columns: Array<IListColumn<IAccessReview>> = [];

    columns.push({
      key: 'displayName' + gridKey,
      name: t(LocaleKeys.name),
      fieldName: 'displayName',
      minWidth: 100,
      maxWidth: 240,
      headerClassName: FontClassNames.smallPlus,
      isSorted: false,
      isSortedDescending: true,
      // onColumnClick: this._onColumnClick as (ev: React.MouseEvent<HTMLElement>, column: IColumn) => void,
      columnActionsMode: ColumnActionsMode.clickable,
      isResizable: true,
      onRender: (item: IAccessReview) => {
        if (isSupervisorCentric) {
          return (
            <div
              style={{ flex: 1, display: 'flex', alignItems: 'center', cursor: 'pointer' }}
              onClick={() =>
                history.push(
                  this.state.selectedPivot === AccessReviewsPivot.UserAccess
                    ? Routes.supervisorCentricReviews + '/' + item.id + '/decisions'
                    : Routes.accessReviews + '/' + item.id
                )
              }
            >
              <span className={css('ms-pii', FontClassNames.medium)}>{FormatDate(item.displayName)}</span>
            </div>
          );
        } else {
          return (
            <Link
              // tslint:disable-next-line:jsx-no-lambda
              onClick={() => history.push(Routes.accessReviews + '/' + item.id)}
              className={css(FontClassNames.medium, myAccessStyles.themeDarkFont)}
            >
              {item.displayName}
            </Link>
          );
        }
      }
    });

    if (responsiveMode > ResponsiveMode.small) {
      columns.push({
        key: 'endDateTime' + gridKey,
        name: t(LocaleKeys.due),
        fieldName: 'endDateTime',
        minWidth: 100,
        maxWidth: 180,
        headerClassName: FontClassNames.smallPlus,
        // onColumnClick: this._onColumnClick as (ev: React.MouseEvent<HTMLElement>, column: IColumn) => void,
        columnActionsMode: ColumnActionsMode.clickable,
        isResizable: true,
        onRender: (item: IAccessReview) => {
          if (isSupervisorCentric) {
            const dueSoonStatus = this._getDueSoonStatus(item);
            return (
              <div
                style={{ flex: 1, display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                onClick={() =>
                  history.push(
                    this.state.selectedPivot === AccessReviewsPivot.UserAccess
                      ? Routes.supervisorCentricReviews + '/' + item.id + '/decisions'
                      : Routes.accessReviews + '/' + item.id
                  )
                }
              >
                <span className={css('ms-pii', FontClassNames.medium)}>
                  {FormatDate(dueSoonStatus.date)}
                  <br />
                  {dueSoonStatus.isDueSoon && (
                    <span style={{ display: 'inline-block', color: 'red' }}>
                      <span style={{ color: 'red' }}>{t(LocaleKeys.dueSoon)}</span>
                      <Icon
                        iconName={'Clock'}
                        className={css(myAccessStyles.smallIcon)}
                        style={{ marginLeft: '5px', color: 'red', position: 'relative', top: '2px' }}
                      />
                    </span>
                  )}
                </span>
              </div>
            );
          } else {
            return <span className={css('ms-pii', FontClassNames.medium)}>{FormatDate(item.endDateTime)}</span>;
          }
        }
      });
    }

    if (responsiveMode > ResponsiveMode.small) {
      if (gridKey === AccessReviewType.Byod) {
        columns.push({
          key: 'customDataProvider',
          name: t(LocaleKeys.dataProvider),
          fieldName: 'customDataProvider',
          minWidth: 60,
          maxWidth: 110,
          headerClassName: FontClassNames.smallPlus,
          onColumnClick: this._onColumnClick as (ev: React.MouseEvent<HTMLElement>, column: IColumn) => void,
          columnActionsMode: ColumnActionsMode.clickable,
          isResizable: true,
          onRender: (item: IAccessReview) => (
            <span className={css('ms-pii', FontClassNames.medium)}>{item.customDataProvider}</span>
          )
        });
      } else {
        if (gridKey === AccessReviewType.Rbac) {
          columns.push({
            key: 'reviewScope' + gridKey,
            name: t(LocaleKeys.scope),
            fieldName: 'reviewScope',
            minWidth: 60,
            maxWidth: 110,
            headerClassName: FontClassNames.smallPlus,
            columnActionsMode: ColumnActionsMode.disabled,
            isResizable: true,
            onRender: (item: IAccessReview) => (
              <span className={css('ms-pii', FontClassNames.medium)}>{this._getReviewResourceName(item, gridKey)}</span>
            )
          });

          columns.push({
            key: 'reviewScopeType' + gridKey,
            name: t(LocaleKeys.scopeType),
            fieldName: 'reviewScopeType',
            minWidth: 60,
            maxWidth: 110,
            headerClassName: FontClassNames.smallPlus,
            columnActionsMode: ColumnActionsMode.disabled,
            isResizable: true,
            onRender: (item: IAccessReview) => {
              let scopeResourceType = '';
              let resourceType = '';
              if (
                item.decisionsCriteria &&
                item.decisionsCriteria.length > 0 &&
                item.decisionsCriteria[0]?.resourceType
              ) {
                resourceType = item.decisionsCriteria[0]?.resourceType;
              }

              switch (resourceType) {
                case ReviewScopeResourceType.Subscription: {
                  scopeResourceType = t(LocaleKeys.subscription);
                  break;
                }
                case ReviewScopeResourceType.ManagementGroup: {
                  scopeResourceType = t(LocaleKeys.managementGroup);
                  break;
                }
                case ReviewScopeResourceType.ResourceGroup: {
                  scopeResourceType = t(LocaleKeys.resourceGroup);
                  break;
                }
                default: {
                  scopeResourceType = t(LocaleKeys.resource);
                  break;
                }
              }
              return <span className={css('ms-pii', FontClassNames.medium)}>{scopeResourceType}</span>;
            }
          });
        } else {
          columns.push({
            key: 'reviewResource' + gridKey,
            name: t(LocaleKeys.resourceTypeSpace),
            fieldName: 'reviewResource',
            minWidth: 150,
            maxWidth: 200,
            headerClassName: FontClassNames.smallPlus,
            columnActionsMode: ColumnActionsMode.disabled,
            isResizable: true,
            onRender: (item: IAccessReview) => (
              <div
                style={{ flex: 1, display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                onClick={() =>
                  history.push(
                    this.state.selectedPivot === AccessReviewsPivot.UserAccess
                      ? Routes.supervisorCentricReviews + '/' + item.id + '/decisions'
                      : Routes.accessReviews + '/' + item.id
                  )
                }
              >
                <span className={css('ms-pii', FontClassNames.medium)}>
                  {this._getReviewResourceName(item, gridKey)}
                </span>
              </div>
            )
          });
        }
      }
    }

    if (responsiveMode > ResponsiveMode.small) {
      columns.push({
        key: 'progress' + gridKey,
        name: t(LocaleKeys.accessReviewsProgress),
        fieldName: 'progress',
        minWidth: 180,
        maxWidth: 200,
        headerClassName: FontClassNames.smallPlus,
        columnActionsMode: ColumnActionsMode.disabled,
        isResizable: true,
        onRender: (item: IAccessReview) => {
          if (isSupervisorCentric) {
            return (
              <div
                style={{ flex: 1, display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                onClick={() =>
                  history.push(
                    this.state.selectedPivot === AccessReviewsPivot.UserAccess
                      ? Routes.supervisorCentricReviews + '/' + item.id + '/decisions'
                      : Routes.accessReviews + '/' + item.id
                  )
                }
              >
                <span className={css(FontClassNames.medium, myAccessStyles.inlineLongProgress)}>
                  {item.decisionsSummary ? (
                    <div style={{ position: 'relative' }}>
                      <ProgressIndicator
                        label={this._getReviewProgressString(item)}
                        percentComplete={this._getReviewProgressPercentage(item)}
                        barHeight={4}
                        ariaValueText={t(LocaleKeys.accessReviewsProgress)}
                        styles={{
                          progressBar: {
                            backgroundColor: this._getReviewProgressPercentage(item) === 1 ? 'green' : undefined
                          }
                        }}
                      />
                      {this._getReviewProgressPercentage(item) === 1 && (
                        <Icon
                          iconName="SkypeCircleCheck"
                          className={css(myAccessStyles.checkmarkIcon)}
                          style={{ position: 'absolute', right: '-10px', top: '60%', color: 'green' }}
                        />
                      )}
                    </div>
                  ) : (
                    getInlineSpinner()
                  )}
                </span>
              </div>
            );
          } else {
            return (
              <span className={css(FontClassNames.medium, myAccessStyles.inlineProgress)}>
                {item.decisionsSummary ? (
                  <span>
                    <ProgressIndicator
                      label={this._getReviewProgressStringLegacy(item)}
                      percentComplete={this._getReviewProgressPercentageLegacy(item)}
                      barHeight={4}
                      ariaValueText={t(LocaleKeys.accessReviewsProgress)}
                    />
                  </span>
                ) : (
                  getInlineSpinner()
                )}
              </span>
            );
          }
        }
      });
    }

    if (responsiveMode > ResponsiveMode.small && isSupervisorCentric) {
      columns.push({
        key: 'viewDetails',
        name: '',
        fieldName: 'id',
        minWidth: 20,
        maxWidth: 20,
        headerClassName: FontClassNames.xSmall,
        columnActionsMode: ColumnActionsMode.disabled,
        onRender: (item: IAccessReview) => (
          <div
            style={{ flex: 1, display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={() =>
              history.push(
                this.state.selectedPivot === AccessReviewsPivot.UserAccess
                  ? Routes.supervisorCentricReviews + '/' + item.id + '/decisions'
                  : Routes.accessReviews + '/' + item.id
              )
            }
          >
            <div className={css(myAccessStyles.chevronRotatePointRight)}>
              <ChevronButton
                t={t}
                onClick={() =>
                  history.push(
                    this.state.selectedPivot === AccessReviewsPivot.UserAccess
                      ? Routes.supervisorCentricReviews + '/' + item.id + '/decisions'
                      : Routes.accessReviews + '/' + item.id
                  )
                }
              />
            </div>
          </div>
        )
      });
    }

    const displayFrequency = false;

    // Disable review frequency column until accurate data is available from backend
    if (displayFrequency) {
      columns.push({
        key: 'reviewFrequency' + gridKey,
        name: t(LocaleKeys.accessReviewsRecurring),
        fieldName: 'reviewFrequency',
        minWidth: 60,
        maxWidth: 120,
        className: 'ms-pii',
        headerClassName: FontClassNames.smallPlus,
        columnActionsMode: ColumnActionsMode.disabled,
        isResizable: true,
        onRender: (item: IAccessReview) => {
          let recurrenceString = item.settings!.recurrenceSettings.recurrenceType;

          switch (recurrenceString) {
            case RecurrenceType.OneTime:
              recurrenceString = t(LocaleKeys.reviewTypeOneTime);
              break;
            case RecurrenceType.Weekly:
              recurrenceString = t(LocaleKeys.reviewTypeWeekly);
              break;
            case RecurrenceType.Monthly:
              recurrenceString = t(LocaleKeys.reviewTypeMonthly);
              break;
            case RecurrenceType.Quarterly:
              recurrenceString = t(LocaleKeys.reviewTypeQuarterly);
              break;
            case RecurrenceType.Biannually:
              recurrenceString = t(LocaleKeys.reviewTypeBiannually);
              break;
            case RecurrenceType.Annually:
              recurrenceString = t(LocaleKeys.reviewTypeAnnually);
              break;
            default:
              recurrenceString = t(LocaleKeys.reviewTypeOneTime);
              break;
          }

          return (
            <ColumnValue
              searchTerm={this.props.searchTerm}
              columnValue={recurrenceString}
              isHighlightRequired={false}
              isSearching={false}
            />
          );
        }
      } as IListColumn<IAccessReview>);
    }

    return columns;
  };

  private readonly _handlePivotClick = (item: PivotItem | undefined): void => {
    if (!item || !item.props) {
      return;
    }

    if (this.state.selectedPivot === (item.props.itemKey as AccessReviewsPivot)) {
      return;
    }

    const selectedPivot = item.props.itemKey as AccessReviewsPivot;

    this.setState({ selectedPivot });
    this.props.setReviewListPivot(selectedPivot);

    switch (item.props.itemKey as AccessReviewsPivot) {
      case AccessReviewsPivot.GroupsApps:
        this.props.refreshAccessReviews();
        break;
      case AccessReviewsPivot.AccessPackages:
        this.props.refreshAccessPackageReviews();
        break;
      case AccessReviewsPivot.AadRoles:
        this.props.refreshAadRolesReviews();
        break;
      case AccessReviewsPivot.Rbac:
        this.props.refreshRbacReviews();
        break;
      case AccessReviewsPivot.Byod:
        this.props.refreshByodReviews();
        break;
      case AccessReviewsPivot.UserAccess:
        this.props.refreshUserAccessReviews();
        break;
      default:
        break;
    }
  };

  private _getDueSoonStatus(review: IAccessReview): { date: string; isDueSoon: boolean } {
    const dueDate = review.endDateTime ? new Date(review.endDateTime) : undefined;
    const today = new Date();
    const timeDiff = (dueDate?.getTime() ?? 0) - today.getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    if (daysDiff <= 15) {
      return { date: review.endDateTime?.toString() ?? '', isDueSoon: true };
    } else {
      return { date: review.endDateTime?.toString() ?? '', isDueSoon: false };
    }
  }

  private _getReviewResourceName(review: IAccessReview, gridKey: string): string {
    let resourceName = '';
    if (review.decisionsCriteria?.[0]?.resourceTypes ?? '' !== '') {
      resourceName = review.decisionsCriteria?.[0]?.resourceTypes ?? '';

      resourceName = resourceName
        .split(',')
        .map((word) => word.trim())
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(', ');
    } else if (review.reviewedEntity) {
      resourceName = review.reviewedEntity.displayName;
    }

    if (gridKey === AccessReviewType.Rbac) {
      if (resourceName === '' && review.decisionsCriteria && review.decisionsCriteria.length > 0) {
        resourceName = review.decisionsCriteria[0]?.resourceDisplayName ?? '';
      }
    }

    if (gridKey === AccessReviewType.AadRole) {
      if (
        review.decisionsCriteria &&
        review.decisionsCriteria.length > 0 &&
        review.decisionsCriteria[0]?.typeId === allRolesReviewTypeId
      ) {
        resourceName = this.props.t(LocaleKeys.multiple, {
          itemType: this.props.t(LocaleKeys.roles)
        });
      }
    }

    return resourceName;
  }

  private _getReviewProgressString(review: IAccessReview): string {
    if (review.decisionsSummary) {
      const reviewedCount = review.decisionsSummary.totalCount - review.decisionsSummary.notReviewedCount;
      if (reviewedCount === review.decisionsSummary.totalCount) {
        return this.props.t(LocaleKeys.completed);
      } else {
        return (
          this.props.t(LocaleKeys.inProgressText) +
          ' (' +
          reviewedCount.toString() +
          ' ' +
          this.props.t(LocaleKeys.of).toString() +
          ' ' +
          review.decisionsSummary.totalCount.toString() +
          ' ' +
          this.props.t(LocaleKeys.pending).toString() +
          ')'
        );
      }
    } else {
      return '';
    }
  }

  private _getReviewProgressPercentage(review: IAccessReview): number {
    if (review.decisionsSummary) {
      const reviewedCount = review.decisionsSummary.totalCount - review.decisionsSummary.notReviewedCount;
      return reviewedCount / review.decisionsSummary.totalCount;
    } else {
      return 0;
    }
  }

  private _getReviewProgressStringLegacy(review: IAccessReview): string {
    if (review.decisionsSummary) {
      const reviewedCount = review.decisionsSummary.totalCount - review.decisionsSummary.notReviewedCount;
      return reviewedCount + ' / ' + review.decisionsSummary.totalCount;
    } else {
      return '';
    }
  }

  private _getReviewProgressPercentageLegacy(review: IAccessReview): number {
    if (review.decisionsSummary) {
      const reviewedCount = review.decisionsSummary.totalCount - review.decisionsSummary.notReviewedCount;
      return reviewedCount / review.decisionsSummary.totalCount;
    } else {
      return 0;
    }
  }

  private readonly _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IListColumn<IAccessReview>): void => {
    ev.preventDefault();
    column.isSorted = true;
    column.isSortedDescending = !column.isSortedDescending;

    let type = EntityType.accessReviews;
    switch (this.state.selectedPivot) {
      case AccessReviewsPivot.GroupsApps:
        this.setState({
          columns: getNewColumnsOnColumnClicked(this.state.columns, column)
        });
        break;
      case AccessReviewsPivot.AccessPackages:
        type = EntityType.accessPackageReviews;
        this.setState({
          columns: getNewColumnsOnColumnClicked(this.state.elmColumns, column)
        });
        break;
      case AccessReviewsPivot.AadRoles:
        type = EntityType.aadRoleReviews;
        this.setState({
          columns: getNewColumnsOnColumnClicked(this.state.aadRolesColumns, column)
        });
        break;
      case AccessReviewsPivot.Rbac:
        type = EntityType.rbacReviews;
        this.setState({
          columns: getNewColumnsOnColumnClicked(this.state.rbacColumns, column)
        });
        break;
      case AccessReviewsPivot.Byod:
        type = EntityType.byodReviews;
        this.setState({
          columns: getNewColumnsOnColumnClicked(this.state.byodColumns, column)
        });
        break;
      case AccessReviewsPivot.UserAccess:
        type = EntityType.userAccessReviews;
        this.setState({
          columns: getNewColumnsOnColumnClicked(this.state.userAccessColumns, column)
        });
        break;
      default:
        break;
    }

    this.props.setSortedByColumn(column);
    if (!this.props.isSearching && !this.props.isFiltering) {
      this.props.sortEntities(column.fieldName, !column.isSortedDescending, type);
    } else {
      this.props.sortFilteredEntities(
        column.fieldName,
        !column.isSortedDescending,
        this.props.searchTerm,
        this.props.filter,
        type
      );
    }
  };

  private _getOverallReviewCount(): number {
    this.totalReviewCount = 0;
    this.totalReviewCount += this.props.accessReviewsList.length;
    this.totalReviewCount += this.props.accessPackageReviewsList.length;
    this.totalReviewCount += this.props.aadRolesReviewsList.length;
    this.totalReviewCount += this.props.rbacReviewsList.length;
    this.totalReviewCount += this.props.byodReviewsList.length;
    this.totalReviewCount += this.props.userAccessReviewsList.length;
    return this.totalReviewCount;
  }

  private _getTotalReviewCount(): number {
    switch (this.state.selectedPivot) {
      case AccessReviewsPivot.GroupsApps:
        return this.props.accessReviewsList.length;
      case AccessReviewsPivot.AccessPackages:
        return this.props.accessPackageReviewsList.length;
      case AccessReviewsPivot.AadRoles:
        return this.props.aadRolesReviewsList.length;
      case AccessReviewsPivot.Rbac:
        return this.props.rbacReviewsList.length;
      case AccessReviewsPivot.Byod:
        return this.props.byodReviewsList.length;
      default:
        return 0;
    }
  }
}
