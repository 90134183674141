import { ColorClassNames, FontClassNames } from '@fluentui/react';
import { TranslationFunction } from 'i18next';
import { css, Icon } from '@fluentui/react';
import * as React from 'react';
import { LocaleKeys } from '../../../shared/LocaleKeys';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');

export interface INoFilteredResultsProps {
  t: TranslationFunction;
}

export class NoFilteredResults extends React.Component<
  INoFilteredResultsProps,
  {}
> {
  public render(): JSX.Element {
    const t = this.props.t;
    return (
      <div
        className={css(
          myAccessStyles.alignCenter,
          myAccessStyles.marginTopXLarge
        )}
      >
        <Icon
          iconName="ClearFilter"
          className={css(
            myAccessStyles.largeIcon,
            ColorClassNames.neutralPrimary
          )}
        />
        <div>
          <div
            className={css(
              FontClassNames.xLarge,
              myAccessStyles.marginTopMedium,
              myAccessStyles.marginBottomSmall,
              ColorClassNames.neutralPrimary
            )}
          >
            {t(LocaleKeys.noFilteredResultsMessage)}
          </div>
          <div
            className={css(
              FontClassNames.medium,
              ColorClassNames.neutralPrimary
            )}
          >
            {t(LocaleKeys.removeFilterAction)}
          </div>
        </div>
      </div>
    );
  }
}
