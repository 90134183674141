import { ILocalizableMessage } from '@microsoft/portal-app/lib/localization/ILocalizableMessage';
import '@microsoft/portal-app/lib/styling/patterns/Dialog.scss';
import { TranslationFunction } from 'i18next';
import {
  css,
  DefaultButton,
  Dialog,
  DialogFooter,
  PrimaryButton
} from '@fluentui/react';
import { ResponsiveMode } from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import * as React from 'react';
import { IAccessReviewDecision } from '../../../models/AccessReviews/IAccessReviewDecision';
import { DecisionType } from '../../../models/RequestApprovals/DecisionType';
import { asLocalizedText } from '../../../shared/asLocalizedText';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { getSpinner } from '../../../shared/spinner';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');

export interface IResetDecisionsProps {
  userList: IAccessReviewDecision[];
  isSubmitting: boolean;
  submittingText?: string | ILocalizableMessage;
  t: TranslationFunction;
  responsiveMode?: ResponsiveMode;
  onSubmit(resetAll: boolean): void;
  onDismiss(): void;
}

export class ResetDecisions extends React.Component<IResetDecisionsProps, {}> {
  public render(): JSX.Element {
    const {
      userList,
      isSubmitting,
      submittingText,
      responsiveMode,
      t
    } = this.props;

    const resetAll = !(userList && userList.length > 0);

    // When displaying the number of resets, only count those that have decisions made
    let resetCount = 0;
    userList.forEach((decision: IAccessReviewDecision) => {
      if (decision.reviewResult && decision.reviewResult !== DecisionType.NotReviewed) {
        resetCount++;
      }
    });

    const resetMessage = resetAll ? t(LocaleKeys.resetAllDecisions)
      : asLocalizedText(
        {
          key: LocaleKeys.entityNameWithCount,
          options: {
            count: resetCount,
            entityName: t(LocaleKeys.reset, { count: resetCount })
          }
        },
        t
      );

    return (
      <div>
        <Dialog
          hidden={false}
          isOpen={true}
          onDismiss={this.props.onDismiss}
          title={t(LocaleKeys.resetDecisions)}
          modalProps={{
            containerClassName:
              responsiveMode! < ResponsiveMode.large
                ? myAccessStyles.smallDialog
                : ''
          }}
        >
          <div className={css(myAccessStyles.marginBottomMedium)}>
            {t(LocaleKeys.accessReviewDecisionWarning)}
          </div>
          {!isSubmitting ?
            <div>
              {resetMessage}
            </div> : null}
          <DialogFooter>
            {isSubmitting
              ? getSpinner(
                asLocalizedText(submittingText || LocaleKeys.loading, t)
              )
              : <div>
                <PrimaryButton
                  // tslint:disable-next-line:jsx-no-lambda
                  onClick={() => this.props.onSubmit(resetAll)}
                  text={t(LocaleKeys.submit)}
                  className={css(myAccessStyles.marginRightSmall)}
                />
                <DefaultButton
                  onClick={this.props.onDismiss}
                  text={t(LocaleKeys.cancel)}
                />
              </div>}
          </DialogFooter>
        </Dialog>
      </div>
    );
  }
}
