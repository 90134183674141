import { useSelector, useTranslation } from '../index';
import { EntitlementActions } from '../../models';
import { useDispatch } from 'react-redux';
import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { IOverviewData, OverviewType } from '../../models/IOverviewData';
import { LocaleKeys, Routes } from '../../shared';
import { useHistory } from 'react-router';
import { IOverviewActionCardProps } from '../../components/Shared/OverviewCard';
import { isNullOrUndefined } from 'util';

export const useOverviewExpiringGrantsManager = () => {
  const { expiringGrantData } = useSelector((state) => ({
    expiringGrantData: state.app.overviewExpiringGrantData
  }));

  const dispatch = useDispatch();
  const refreshExpiringGrantOverview = createAction<boolean>(
    EntitlementActions.getOverviewExpiringGrants,
    dispatch
  );

  const history = useHistory();
  const t = useTranslation();

  const extendGrants = () => {
    let path = Routes.accessIHaveTab;
    history.push(path);
  };

  const buildOverviewExpiringGrantCardData = (expiringGrantsOverviewData: IOverviewData): IOverviewActionCardProps => {
    let overviewExpiringGrantsCardData: IOverviewActionCardProps = {
      id: OverviewType.expiringGrants,
      isLoading: true,
      isSucceeded: false,
      title: t(LocaleKeys.accessExtension),
      cardDescription: t(LocaleKeys.expiringPackage),
      cardImageUrl: '/imgs/overview_AccessExtension.png',
      primaryButtonLabel: t(LocaleKeys.renew, {context: 'capitalize'}),
      primaryButtonAction: extendGrants,
      refreshAction: refreshExpiringGrantOverview
    };

    if (!isNullOrUndefined(expiringGrantData)) {
      overviewExpiringGrantsCardData.isLoading = expiringGrantsOverviewData.isLoading;
      overviewExpiringGrantsCardData.isSucceeded = expiringGrantsOverviewData.isSucceeded;
      overviewExpiringGrantsCardData.pendingActionEntityCount = expiringGrantsOverviewData.pendingActionEntityCount;
      overviewExpiringGrantsCardData.errorEventName = expiringGrantsOverviewData.errorEventName;
      overviewExpiringGrantsCardData.error = expiringGrantsOverviewData.error;
      if (expiringGrantsOverviewData.pendingActionEntityCount && expiringGrantsOverviewData.pendingActionEntityCount > 1) {
        overviewExpiringGrantsCardData.cardDescription = t(LocaleKeys.expiringPackage, {context: 'plural'});
      }
    }

    return overviewExpiringGrantsCardData;
  };

  const overviewExpiringGrantData: IOverviewActionCardProps = buildOverviewExpiringGrantCardData(expiringGrantData);

  return { overviewExpiringGrantData, refreshExpiringGrantOverview, extendGrants };
};
