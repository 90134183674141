import { TranslationFunction } from 'i18next';
import {
  ColumnActionsMode,
  FontClassNames,
  IconButton,
  ResponsiveMode
} from '@fluentui/react';
import * as React from 'react';
import { EntitlementSearchFilter } from '../../../models/ELM/EntitlementSearchFilter';
import { IEntitlement } from '../../../models/ELM/IEntitlement';
import { IListColumn } from '../../../models/IListColumn';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { ColumnValue } from '../../Shared/ColumnValue/ColumnValue';

const entitlementListStyles = require('./EntitlementList.scoped.scss');

export const getEntitlementListColumns = (
  responsiveMode: ResponsiveMode,
  t: TranslationFunction,
  selectedFilterKey: string,
  isSearching: boolean,
  searchTerm: string,
  selectedKey: string | null,
  hoveredKey: string | null,
  isSortedDescending: boolean,
  onHoveredKeyClick: () => void,
  onColumnClick: (
    ev: React.MouseEvent<HTMLElement>,
    column: IListColumn<IEntitlement>
  ) => void
): IListColumn<IEntitlement>[] => {
  let columns: IListColumn<IEntitlement>[] = [
    {
      key: 'displayName',
      name: t(LocaleKeys.name),
      fieldName: 'displayName',
      minWidth: 60,
      maxWidth: 400,
      headerClassName: FontClassNames.smallPlus,
      onColumnClick: onColumnClick,
      isSorted: true,
      isSortedDescending: isSortedDescending,
      isResizable: true,
      onRender: (item: IEntitlement) => {
        return (
          <ColumnValue
            searchTerm={
              selectedFilterKey === EntitlementSearchFilter.AccessPackage ||
              selectedFilterKey === EntitlementSearchFilter.All
                ? searchTerm
                : ''
            }
            columnValue={item.displayName!}
            isHighlightRequired={true}
            isSearching={isSearching}
          />
        );
      }
    } as IListColumn<IEntitlement>,
    {
      key: '',
      name: '',
      columnActionsMode: ColumnActionsMode.disabled,
      fieldName: 'id',
      minWidth: 30,
      maxWidth: 30,
      onRender: (item: IEntitlement) =>
        item.id === hoveredKey || item.id === selectedKey ? (
          <IconButton
            iconProps={{ iconName: 'Add' }}
            className={entitlementListStyles.submitButton}
            onClick={onHoveredKeyClick}
            title={t(LocaleKeys.requestAccess, { context: 'capitalize' })}
          />
        ) : null
    }
  ];

  if (responsiveMode > ResponsiveMode.small) {
    columns.push({
      key: 'description',
      name: t(LocaleKeys.description),
      columnActionsMode: ColumnActionsMode.disabled,
      fieldName: 'description',
      headerClassName: FontClassNames.smallPlus,
      minWidth: 200,
      maxWidth: 500,
      isResizable: true,
      onRender: (item: IEntitlement) => (
        <ColumnValue
          searchTerm={searchTerm}
          columnValue={item.description!}
          isHighlightRequired={false}
          isSearching={false}
        />
      )
    } as IListColumn<IEntitlement>);
  }

  // a work around to be able to display chevron to the right.
  // this fake column replaced with chevon column in entity card component.
  columns.push({
    key: '',
    name: '',
    headerClassName: FontClassNames.smallPlus,
    minWidth: 50,
    maxWidth: 50,
    fieldName: 'id',
    columnActionsMode: ColumnActionsMode.disabled
  });

  return columns;
};
