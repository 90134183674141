import { css, Modal } from '@fluentui/react';
import * as React from 'react';
const styles = require('./StyledModal.scoped.scss');

interface IStyledModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  children: React.ReactNode;
}

export function StyledModal({ isOpen, onDismiss, children }: IStyledModalProps): JSX.Element {
  return (
    <Modal
      isOpen={isOpen}
      allowTouchBodyScroll
      onDismiss={onDismiss}
      styles={{
        scrollableContent: css(styles.modalContent),
        main: css(styles.modalMain),
        layer: css(styles.modalLayer)
      }}
    >
      {children}
    </Modal>
  );
}