import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { IRootEntitlementsState } from '../../../../models';
import { EntitlementActions } from '../../../../models/EntitlementActions';
import { history } from '../../../../redux/configureStore';
import { GetPoliciesActionPayload } from '../../../../redux/modules/policy/getPolicies';
import { telemetry } from '../../../../shared/telemetry';
import { VerifyCredentialPresentation } from './VerifyCredentialPresentation';
import {
  IVerifyCredentialPresentationActions,
  IVerifyCredentialPresentationMappedProps,
  IVerifyCredentialPresentationOwnProps
} from './VerifyCredentialPresentation.types';

/**
 * Maps IConnectedVerifyCredentialPresentationProps and state to the
 * VerifyCredentialPresentation control.
 * @param state The current redux state to map
 * @param ownProps The external properties to map
 * @returns The mapped properties required for VerifyCredentialPresentation control
 */
const mapStateToProps = (
  state: IRootEntitlementsState,
  _ownProps: IVerifyCredentialPresentationOwnProps
): IVerifyCredentialPresentationMappedProps => {
  const policyId = state.app.partialGrantRequest?.accessPackageAssignment?.assignmentPolicyId;
  const policy = policyId ? state.app.policies.entitiesById.get(policyId) : undefined;
  const pollingForStatus = state.app.policies.isLoading;
  return {
    status: policy?.verifiableCredentialRequirementStatus,
    pollingForStatus,
    grantRequest: state.app.partialGrantRequest!,
    navigateTo(url: string): void {
      history.push(url);
    }
  };
};

/**
 * Maps the dispatch actions to the props for the VerifyCredentialPresentation control.
 * @param dispatch Dispatches actions to the store
 * @returns The Mapped actions that become the IVerifyCredentialPresentationProps for the
 * VerifyCredentialPresentation control.
 */
const mapDispatchToProps = (dispatch: Dispatch<IRootEntitlementsState>): IVerifyCredentialPresentationActions => {
  const pollPolicy = createAction<GetPoliciesActionPayload>(EntitlementActions.getPolicies, dispatch);
  const deleteVCPresentation = createAction<{
    entityId: string;
  }>(EntitlementActions.deleteVerifiableCredentialPresentationStatus, dispatch);
  return {
    deleteVerifiableCredentialPresentation: (policyId) => {
      deleteVCPresentation({ entityId: policyId });
    },
    pollStatus: (grantRequest) => {
      telemetry.reportCustomEvent(`verified-credentials/${EntitlementActions.getPolicies}`);
      pollPolicy({
        entityId: grantRequest.accessPackageAssignment?.accessPackageId!,
        isPollingForStatus: true,
        parameters: {
          requestType: grantRequest.requestType,
          subject: grantRequest.accessPackageAssignment?.target
        }
      });
    }
  };
};

/**
 * A redux connected VerifyCredentialPresentation control.
 * @description Supports translation
 */
export const ConnectedVerifyCredentialPresentation = connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('MyAccess')(VerifyCredentialPresentation as any));
