import { FontClassNames } from '@fluentui/react';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { css } from '@fluentui/react/lib/Utilities';
import * as React from 'react';

const myAccessStyles = require('../css/myAccess.scoped.scss');

export const getSpinner = (message: string): JSX.Element => {
  return (
    <div className="ms-SpinnerContainer">
      <Spinner
        size={SpinnerSize.large}
        label={message}
        className={css(FontClassNames.medium)}
      />
    </div>
  );
};

export const getInlineSpinner = (): JSX.Element => {
  return (
    <div className={myAccessStyles.inlineSpinner}>
      <Spinner
        size={SpinnerSize.small}
        className={css(FontClassNames.medium)}
      />
    </div>
  );
};
