import { EntitlementActions } from '../../../models/EntitlementActions';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState } from '../../../models/IEntitlementState';
import { IErrorDialog } from '../../../models/RAM/IErrorDialog';
import { registry } from '../myAccessRegistry';

export const setRamErrorDialog = (
  state: IEntitlementState,
  action: IEntitlementAction<IErrorDialog>
): IEntitlementState => {
  const nextState = {
    ...state,
    ramErrorDialogContent: action.payload
  };

  return nextState;
};
registry.add(EntitlementActions.setRamErrorDialog, setRamErrorDialog);
