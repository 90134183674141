import { getSuccessActionType } from '@microsoft/portal-app/lib/redux/createAjaxEpic';
import { UserActions } from '@microsoft/portal-app/lib/User/models';
import { ofType } from 'redux-observable';
import { mapTo } from 'rxjs/operators';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { registry } from '../myAccessRegistry';

export const postLoginEpic = action$ =>
  action$.pipe(
    ofType(getSuccessActionType(UserActions.getMyUserRequest)),
    mapTo({ type: EntitlementActions.getTenant })
  );
registry.addEpic('postLoginEpic', postLoginEpic);
