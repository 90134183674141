import { css, Icon, Link } from '@fluentui/react';
import * as React from 'react';
import { InjectedTranslateProps } from 'react-i18next';

const styles = require('./PanelOption.scoped.scss');

export interface IPanelOption extends InjectedTranslateProps {
  title: string;
  iconName: string;
  showingPackageDetails?: boolean;
  onClick(): void;
}

export class PanelOption extends React.Component<IPanelOption, {}> {
  private _linkRef = React.createRef<HTMLObjectElement>();

  public componentDidUpdate(prevProps: Readonly<IPanelOption>): void {
    const { showingPackageDetails } = this.props;
    const { showingPackageDetails: wasShowingPackageDetails } = prevProps;
    if (wasShowingPackageDetails && !showingPackageDetails) {
      this._linkRef.current!.focus();
    }
  }

  public render(): JSX.Element {
    const { t, title, iconName, onClick } = this.props;
    return (
      <div className={css(styles.container)}>
        <Link onClick={onClick} className={css(styles.link)} ref={this._linkRef}>
          <div className={css(styles.subContainer)}>
            <div className={css(styles.content)}>
              <div>
                <span className={css(styles.linkText)}>
                  {t(title)}
                </span>
              </div>
            </div>

            <div className={css(styles.content)}>
              <div className={css(styles.paddingTop)}>
                <Icon iconName={iconName} />
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}
