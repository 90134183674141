// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../dev/node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../dev/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1kwDo1HyglMAtT6J9XFXOV{width:105px;height:32px}._9qvcBfgq4ivGtNsGJCWRW{width:280px;height:32px;margin-right:8px}._2Uv4lE7qBPIEnwihEIhMlP{margin-right:24px;margin-left:24px;display:flex;flex-direction:row}@media (max-width: 640px){._2Uv4lE7qBPIEnwihEIhMlP{max-width:490px}._9qvcBfgq4ivGtNsGJCWRW{max-width:280px}}@media (max-width: 480px){._2Uv4lE7qBPIEnwihEIhMlP{display:'flex';flex-direction:'row'}._9qvcBfgq4ivGtNsGJCWRW{min-width:100px}._1kwDo1HyglMAtT6J9XFXOV{min-width:105px}}\n", "",{"version":3,"sources":["webpack://./src/components/RAM/VirtualMachines/virtualMachines.scss"],"names":[],"mappings":"AAAA,yBACE,WAAY,CACZ,WAAY,CACb,wBAEC,WAAY,CACZ,WAAY,CACZ,gBAAiB,CAClB,yBAEC,iBAAkB,CAClB,gBAAiB,CACjB,YAAa,CACb,kBAAmB,CACpB,0BAEC,yBACE,eAAgB,CACjB,wBAEC,eAAgB,CACjB,CAEH,0BACE,yBACE,cAAe,CACf,oBAAqB,CACtB,wBAEC,eAAgB,CACjB,yBAEC,eAAgB,CACjB","sourcesContent":[".button {\n  width: 105px;\n  height: 32px;\n}\n.searchbox {\n  width: 280px;\n  height: 32px;\n  margin-right: 8px;\n}\n.container {\n  margin-right: 24px;\n  margin-left: 24px;\n  display: flex;\n  flex-direction: row;\n}\n@media (max-width: 640px) {\n  .container {\n    max-width: 490px;\n  }\n  .searchbox {\n    max-width: 280px;\n  }\n}\n@media (max-width: 480px) {\n  .container {\n    display: 'flex';\n    flex-direction: 'row';\n  }\n  .searchbox {\n    min-width: 100px;\n  }\n  .button {\n    min-width: 105px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "_1kwDo1HyglMAtT6J9XFXOV",
	"searchbox": "_9qvcBfgq4ivGtNsGJCWRW",
	"container": "_2Uv4lE7qBPIEnwihEIhMlP"
};
module.exports = ___CSS_LOADER_EXPORT___;
