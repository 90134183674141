import { ILoading, LoadingState } from '@microsoft/portal-app/lib/models/ILoading';

import { getPolicyTargetKey } from '../shared';
import { useSelector } from '.';

interface ITargetPoliciesLoadingState {
  hasLoaded: boolean;
  isLoading: boolean;
  hasError: boolean;
  alreadyHasAccess: boolean;
}

interface IPoliciesLoadingState {
  myself: ITargetPoliciesLoadingState;
  target: ITargetPoliciesLoadingState;
}

export const defaultLoadingState = {
  hasLoaded: false,
  hasError: false,
  alreadyHasAccess: false,
  isLoading: false
};

export function mapILoadingToTargetLoadingState(loadingState: ILoading): ITargetPoliciesLoadingState {
  return {
    hasLoaded: loadingState.loadingState === LoadingState.loaded && !loadingState.isLoading,
    hasError:
      loadingState.loadingState === LoadingState.error ||
      loadingState.isUserError === true ||
      loadingState.loadingError !== undefined,
    alreadyHasAccess: loadingState.isUserError ?? false,
    isLoading: loadingState.loadingState === LoadingState.loading || loadingState.isLoading
  };
}

export const usePoliciesLoadingState = (): IPoliciesLoadingState => {
  const { partialGrantRequest, policyTargetLoadingMap } = useSelector((state) => ({
    partialGrantRequest: state?.app?.partialGrantRequest,
    policyTargetLoadingMap: state?.app?.policyTargetLoadingMap,
    grantRequestTarget: state?.app?.grantRequestTarget
  }));

  const currentPoliciesLoadingState = {
    myself: { ...defaultLoadingState },
    target: { ...defaultLoadingState }
  };

  const accessPackageId = partialGrantRequest?.accessPackageAssignment?.accessPackageId;
  const targetObjectId = partialGrantRequest?.accessPackageAssignment?.target?.objectId;

  if (!accessPackageId) {
    return currentPoliciesLoadingState;
  }

  const myselfLoadingState = policyTargetLoadingMap.get(getPolicyTargetKey(accessPackageId));
  if (myselfLoadingState) {
    currentPoliciesLoadingState.myself = mapILoadingToTargetLoadingState(myselfLoadingState);
  }

  const targetLoadingState =
    targetObjectId && policyTargetLoadingMap.get(getPolicyTargetKey(accessPackageId, targetObjectId));
  if (targetLoadingState) {
    currentPoliciesLoadingState.target = mapILoadingToTargetLoadingState(targetLoadingState);
  }

  return currentPoliciesLoadingState;
};
