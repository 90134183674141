import { notificationsMerge } from '@microsoft/portal-app/lib/Notifications/helpers/notificationsMerge';
import {
  INotification,
  NotificationSeverity,
  NotificationType
} from '@microsoft/portal-app/lib/Notifications/models/INotification';
import { IODataValueResponse } from '@microsoft/portal-app/lib/odata-utils';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { TranslationOptions } from 'i18next';
import * as moment from 'moment';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';
import { v4 } from 'uuid';

import { IEntity } from '../../../models/ELM/IEntity';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { EntityType } from '../../../models/EntityType';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState, IRootEntitlementsState } from '../../../models/IEntitlementState';
import { IEntityFilterable } from '../../../models/IEntityFilterable';
import { getRequestWithAudience } from '../../../shared/AttachAudience';
import { getEntityApiUrl } from '../../../shared/getApiUrl';
import { getResponseValue } from '../../../shared/getResponseValue';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { registry } from '../myAccessRegistry';

export interface IGetEntityActionPayload {
  entityId: string;
  entityType: EntityType;
}

export const getEntityEpic: Epic<IEntitlementAction<AnyPayload>, IRootEntitlementsState> = (
  action$: ActionsObservable<IEntitlementAction<IGetEntityActionPayload>>,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
  return action$.ofType(EntitlementActions.getEntity).mergeMap((action: IEntitlementAction<IEntityFilterable>) => {
    const { entityId, entityType } = action.payload!;
    const ajaxRequest = getRequestWithAudience(getEntityApiUrl(entityType!, entityId!), entityType as EntityType);
    return (
      ajax(ajaxRequest)
        .map((payload: IODataValueResponse<readonly IEntity[]>) => {
          return {
            type: EntitlementActions.getEntitySucceeded,
            payload: {
              entities: getResponseValue(payload),
              entityType,
              payload
            }
          };
        })
        // tslint:disable-next-line:no-any
        .catch((error: any) =>
          Observable.of({
            type: EntitlementActions.getEntityFailed,
            payload: {
              entityType: action.payload!.entityType,
              errorCode: error && error.status
            }
          })
        )
    );
  });
};
registry.addEpic('getEntityEpic', getEntityEpic);

const getEntity = (state: IEntitlementState, action: IEntitlementAction<any>): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  const loadingList = state.entityLoadingList;
  loadingList[action.payload.entityType] = true;
  return {
    ...state,
    isEntityDetailLoading: true,
    entityLoadingList: loadingList
  };
};
registry.add(EntitlementActions.getEntity, getEntity);

export const getEntitySucceeded = (
  state: IEntitlementState,
  action: IEntitlementAction<{
    entities: readonly IEntity[];
    entityType: string;
  }>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  const { entities, entityType } = action.payload;
  const loadingList = state.entityLoadingList;
  loadingList[entityType] = false;

  if (!entities.length) {
    return {
      ...state,
      isEntityDetailLoading: false,
      entityLoadingList: loadingList
    };
  }

  const entity = entities[0];
  if (!entity) {
    return state;
  }

  const mergedEntity = {
    ...state[entityType].entitiesById.get(entity.id),
    ...entity
  };

  return {
    ...state,
    [entityType]: {
      ...state[entityType],
      entitiesById: new Map([
        // tslint:disable-next-line:no-any
        ...(Array.from(state[entityType].entitiesById) as any),
        [entity.id || '0', mergedEntity]
      ])
    },
    isEntityDetailLoading: false,
    errorHasOccurred: false,
    entityLoadingList: loadingList
  };
};
registry.add(EntitlementActions.getEntitySucceeded, getEntitySucceeded);

export const getEntityFailed = (
  state: IEntitlementState,
  // tslint:disable-next-line:no-any
  action: IEntitlementAction<Readonly<any>>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  const errorCode = action.payload.errorCode;

  const toastKey = LocaleKeys.generalErrorMessage;
  const toastOptions: TranslationOptions = {};

  const notifications: INotification[] = [
    {
      id: v4(),
      localizableMessage: {
        key: toastKey,
        options: toastOptions
      },
      createdDateTime: moment(),
      severity: NotificationSeverity.error,
      type: NotificationType.card
    }
  ];

  return {
    ...state,
    notifications: notificationsMerge(notifications, state.notifications, state.notificationsLimit),
    isEntityDetailLoading: false,
    errorHasOccurred: true,
    isTenantWhitelisted: errorCode !== 403
  };
};
registry.add(EntitlementActions.getEntityFailed, getEntityFailed);
