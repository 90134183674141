import { translate } from 'react-i18next';
import { connect, Dispatch } from 'react-redux';
import { IGrantRequest } from '../../../models/ELM/IGrantRequest';
import { IRootEntitlementsState } from '../../../models/IEntitlementState';
import { GrantRequestApproverInfo } from './GrantRequestApproverInfo';
import {
  IConnectedGrantRequestApproverInfoProps,
  IGrantRequestApproverInfoActions,
  IGrantRequestApproverInfoMappedProps,
} from './GrantRequestApproverInfo.types';

/**
 * Maps the external (i.e. own) props and the state to the properties of the GrantRequestApproverInfo control.
 * @param state The current redux state to map to props
 * @param ownProps The external properties to map to props
 * @returns The mapped properties that along with the actions
 * becomes the props (IGrantRequestApproverInfoProps) for the GrantRequestApproverInfo control
 */
const mapStateToProps = (
  state: IRootEntitlementsState,
  ownProps: IConnectedGrantRequestApproverInfoProps
): IGrantRequestApproverInfoMappedProps => {
  let mappedGrantRequest: IGrantRequest | undefined;

  mappedGrantRequest = state.app.grantRequests.entitiesById.get(
    ownProps.grantRequest.id
  );
  mappedGrantRequest = mappedGrantRequest || ownProps.grantRequest;

  return {
    ...ownProps,
    mappedGrantRequest,
    isLoading: state.app.isEntityDetailLoading,
  };
};

/**
 * Maps the dispatch actions to the props for the GrantRequestApproverInfo control.
 * @param dispatch Dispatches actions to the store
 * @returns The mapped actions that along with the mapped properties
 * becomes the props (IGrantRequestApproverInfoProps) for the GrantRequestApproverInfo control
 */
const mapDispatchToProps = (
  dispatch: Dispatch<IRootEntitlementsState>
): IGrantRequestApproverInfoActions => {
  return {};
};

/**
 * A redux connected GrantRequestApproverInfo control.
 * @description Supports translation
 */
export const ConnectedGrantRequestApproverInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('MyAccess')(GrantRequestApproverInfo));
