
import { InProductFeedback } from '@iamexperiences/inproductfeedback';
import { useAuth } from '@iamexperiences/react-auth/lib-commonjs/react';
import { useLocalization } from '@microsoft/portal-app/lib/shared/localization';
import i18next from 'i18next';
import React, { useCallback } from 'react';

import { feedbackItems,IFeedbackItem, KnownIPFSurveyName } from './constants';
import { getInProductFeedbackLocalizedSettings } from './getInProductFeedbackLocalizedSettings';

export interface InProductFeedbackDialogProps {
  feedbackItemId?: string;
  onDismiss?: VoidFunction;
}

export const InProductFeedbackDialog: React.FC<InProductFeedbackDialogProps> = ({ feedbackItemId, onDismiss }) => {
  const auth = useAuth();
  const [tInProductFeedback] = useLocalization('InProductFeedback');
  const [tInProductFeedbackMyAccess] = useLocalization('InProductFeedbackMyAccess');
  const getToken = useCallback(
    async (baseUrl: string) => {
      return await auth.acquireToken(baseUrl);
    },
    [auth],
  );

  const ondismissInProductFeedback = useCallback(() => {
    onDismiss?.();
  }, [onDismiss]);

  const inProductFeedbackLocalizedSettings = getInProductFeedbackLocalizedSettings(tInProductFeedback);
  inProductFeedbackLocalizedSettings.appContext.feedback.tenantId = auth.user()?.tenantId ?? '';
  inProductFeedbackLocalizedSettings.appContext.feedback.userId = auth.user()?.objectId ?? '';
  inProductFeedbackLocalizedSettings.appContext.feedback.email = auth.user()?.username ?? '';
  inProductFeedbackLocalizedSettings.appContext.feedback.getToken = getToken;
  inProductFeedbackLocalizedSettings.appContext.onDismiss = ondismissInProductFeedback;

  const surveyName = (feedbackItemId ?? location.hash).replace(/[/#-]/g, '');

  const ipfFeedbackItemSettings = getInProductFeedbackItemSettings(
    tInProductFeedbackMyAccess,
    surveyName,
  );

  inProductFeedbackLocalizedSettings.appContext.feedback.surveyId = ipfFeedbackItemSettings.surveyId;
  inProductFeedbackLocalizedSettings.appContext.feedback.surveyName = surveyName;
  inProductFeedbackLocalizedSettings.appContext.ces = ipfFeedbackItemSettings.ces;
  inProductFeedbackLocalizedSettings.appContext.cva = ipfFeedbackItemSettings.cva;
  inProductFeedbackLocalizedSettings.appContext.csat = ipfFeedbackItemSettings.csat;

  return (
    <InProductFeedback
      context={inProductFeedbackLocalizedSettings.context}
      appContext={inProductFeedbackLocalizedSettings.appContext}
    />
  );
};

export const getInProductFeedbackItemSettings = (
  t: i18next.TranslationFunction,
  surveyName: string,
): IFeedbackItem => {
  const myaccessgeneric: string = 'myaccessgeneric';

  // here we default to user initiated feedbackItem if we are provided a surveyName that doesn't exist in our feedbackItems map
  const item = JSON.parse(JSON.stringify(feedbackItems[surveyName] ?? feedbackItems[KnownIPFSurveyName.myaccessgenericuserinitiated]));

  if (item.ces.enable) {
    item.ces.strings = {
      label: t((item.hasCustomLabel ? surveyName : myaccessgeneric) + 'CesLabel'),
    };
  }
  if (item.cva.enable) {
    item.cva.strings = {
      label: t((item.hasCustomLabel ? surveyName : myaccessgeneric) + 'CvaLabel'),
    };
  }
  if (item.csat.enable) {
    item.csat.strings = {
      label: t((item.hasCustomLabel ? surveyName : myaccessgeneric) + 'CsatLabel'),
    };
  }

  return item;
};