
import {
  ChoiceGroup,
  ColorClassNames,
  DatePicker,
  DefaultButton,
  IChoiceGroupOption,
  Icon,
  IconButton,
  Label,
  Panel,
  PanelType,
  PrimaryButton,
  Text,
  TextField,
  Toggle,
  TooltipHost,
} from '@fluentui/react';
import { withResponsiveMode } from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import * as React from 'react';
import { isNullOrUndefined } from 'util';

import { css } from '@fluentui/react';

import { AnswerString } from '../../../models/ELM/AnswerString';
import { IAnswer } from '../../../models/ELM/IAnswer';
import { IGrantPolicy } from '../../../models/ELM/IGrantPolicy';
import { ValidationErrorCode } from '../../../models/ELM/IValidationError';
import { QuestionAnswer } from '../../../models/ELM/QuestionAnswer';
import { RequestType } from '../../../models/ELM/RequestType';
import { FormatDate } from '../../../shared/FormatDateTime';
import { getDatePickerStrings } from '../../../shared/getDatePickerStrings';
import { isEmptyOrUndefined } from '../../../shared/isEmptyOrUndefined';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { Routes } from '../../../shared/Routes';
import { getSpinner } from '../../../shared/spinner';
import {
  getErrorExists,
  getPolicyExpirationDateFromValidationErrors,
  getPolicyIsCustomAssignmentScheduleAllowed,
  getQuestionAnswers,
  getSelectedGrantPolicy,
  isValidationErrorTopLevel,
  MAX_DATE,
} from '../../../shared/validationErrorHelper';
import { ConsentToggle } from '../../ELM/ConsentToggle/ConsentToggle';
import { QuestionAnswer as QuestionAnswerControl } from '../../ELM/QuestionAnswer/QuestionAnswer';
import {
  IAddGrantRequestProps,
  IAddGrantRequestState,
} from './AddGrantRequest.types';
import { getQuestionObject, onRenderValidationErrors } from './GrantRequestShared';
import { QuestionType } from '../../../models/ELM/QuestionType';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');
const styles = require('./AddGrantRequest.scoped.scss');

@withResponsiveMode
export class AddGrantRequest extends React.Component<
  IAddGrantRequestProps,
  IAddGrantRequestState
> {
  private _minStartDate: Date = new Date(Date.now());
  private _maxStartDate: Date = MAX_DATE;
  private _minStopDate: Date = new Date(Date.now());
  private _maxStopDate: Date = MAX_DATE;
  private _grantPolicyOptions: IChoiceGroupOption[] = [];
  private _grantPolicies: IGrantPolicy[] = [];

  constructor(nextProps: IAddGrantRequestProps) {
    super(nextProps);
    this.state = {
      newGrantRequest: nextProps.grantRequest!,
      datesEnabled: false,
      consented: false,
      textFieldClean: true,
      selectedKey: '',
      questionAnswers: [],
    };
  }

  public componentWillReceiveProps(nextProps: IAddGrantRequestProps): void {
    const nextErrors = nextProps.validationErrors;
    const currentErrors = this.props.validationErrors;
    if (nextProps.policies !== undefined) {
      this._grantPolicies = nextProps.policies;
    }
    if (
      nextErrors &&
      nextErrors.length > 0 &&
      (!currentErrors || currentErrors.length === 0)
    ) {
      if (
        getErrorExists(
          nextProps.validationErrors,
          ValidationErrorCode.InvalidExpiredDateTime
        )
      ) {
        const expirationDate = getPolicyExpirationDateFromValidationErrors(
          nextErrors,
          getSelectedGrantPolicy(this._grantPolicies, this.state.selectedKey)
        );
        this._maxStopDate = expirationDate;
        this._maxStartDate = expirationDate;
      }
    }
    if (nextProps.useApplicablePoliciesFeature ||
      getErrorExists(
        nextProps.validationErrors,
        ValidationErrorCode.MissingQuestionAnswers
      )
    ) {
      let selectedKey = this.state.selectedKey;
      if (selectedKey?.toString().trim() === '' &&
        this._grantPolicies && this._grantPolicies.length === 1) {
        selectedKey = this._grantPolicies[0].id;
      }
      const questionAnswers = getQuestionAnswers(
        nextErrors,
        getSelectedGrantPolicy(this._grantPolicies, selectedKey)
      );
      this.setState({
        ...this.state,
        selectedKey,
        questionAnswers,
      });
    }
  }

  public componentWillMount(): void {
    const { grantRequest } = this.props;

    if (!grantRequest) {
      return;
    }

    if (this.props.useApplicablePoliciesFeature) {
      if (this.props.policies !== undefined) {
        this._grantPolicies = this.props.policies;
      } else {
        this.props.getRequestPolicies({
          entityId: grantRequest.accessPackageAssignment?.accessPackageId!,
          parameters: {
            requestType: grantRequest?.requestType!
          }
        });
      }
    } else {
      let stopDateTime = new Date();
      stopDateTime.setFullYear(stopDateTime.getFullYear() + 100);

      // Trigger pre-validation
      const grantRequestForValidation: Partial<IGrantRequest> = {
        ...grantRequest,
        accessPackageAssignment: {
          ...grantRequest.accessPackageAssignment,
          schedule: {
            startDateTime: null,
            stopDateTime: stopDateTime,
          },
        },
        isValidationOnly: true,
      };

      this.props.postGrantRequest({
        newGrantRequest: grantRequestForValidation,
        entitlementName: this.props.entitlementName!,
      });
    }
  }

  public componentWillUnmount(): void {
    this.props.clearValidationErrors();
  }

  public render(): JSX.Element {
    const {
      grantRequest,
      entitlementName,
      validationErrors,
      submitting,
      validating,
      t,
    } = this.props;

    if (!grantRequest || !grantRequest.accessPackageAssignment) {
      return <div />;
    }

    const hideValidationErrors =
      !validationErrors ||
      validationErrors.length === 0 ||
      submitting ||
      validating;

    return (

      <Panel
        isBlocking={true}
        isOpen={true}
        onDismiss={this.props.onDismiss}
        type={PanelType.smallFixedFar}
        onRenderHeader={this.renderHeader}
        onRenderFooterContent={this.renderFooter}
        isFooterAtBottom={true}
        isLightDismiss={true}
        hasCloseButton={false}
        className={css(styles.root)}
      >
        {
          hideValidationErrors ? null : onRenderValidationErrors(
            entitlementName!,
            validationErrors,
            this.onExistingGrantLinkClicked,
            t,
          )
        }
        {this.getPolicyOptions()}
        {this.onRenderBodyBottom()}
      </Panel>
    );
  }

  private onExistingGrantLinkClicked = (guid: string): void => {
    this.props.navigateTo(`${Routes.accessIHaveTab}/${guid}`);
    this.props.onDismiss();
  };

  private getPolicyOptions = (): JSX.Element => {
    const { t, submitting, validating } = this.props;

    this._grantPolicyOptions = [];
    if (this._grantPolicies && this._grantPolicies.length > 0) {
      this._grantPolicies.forEach((grantPolicy: IGrantPolicy) => {
        this._grantPolicyOptions.push({
          key: grantPolicy.id,
          text: grantPolicy.displayName,
          // tslint:disable-next-line:no-any
          onRenderField: (props: IChoiceGroupOption, render: any) => {
            return (
              <div>
                <div>{render!(props)}</div>
                <div
                  className={css(
                    myAccessStyles.marginLeftSmaller,
                    ColorClassNames.neutralSecondary
                  )}
                >
                  {grantPolicy.description}
                </div>
              </div>
            );
          },
        });
      });
    }

    const questionAnswersView: JSX.Element[] = [];
    if (this.state.questionAnswers && this.state.questionAnswers.length > 0) {
      this.state.questionAnswers.forEach((questionAnswer: QuestionAnswer) => {
        questionAnswersView.push(
          <QuestionAnswerControl
            key={questionAnswer.id}
            t={t}
            submitting={submitting}
            validating={validating}
            questionAnswer={questionAnswer}
            onChange={this.onAnswerChanged}
          />
        );
      });
    }

    return (
      <div>
        {this._grantPolicyOptions.length > 0 ? (
          <div className={css(myAccessStyles.marginTopMedium)}>
            <Label required={true}>
              {t(LocaleKeys.selectAPolicy)}{' '}
              <TooltipHost content={t(LocaleKeys.policyTooltip)} id='policyInfo'>
                <IconButton iconProps={{iconName: 'info'}} aria-label={t(LocaleKeys.policyTooltip)}/>
              </TooltipHost>
            </Label>
            <ChoiceGroup
              options={this._grantPolicyOptions}
              onChange={this.onPolicySelectionChanged}
              selectedKey={this.state.selectedKey!}
            />
          </div>
        ) : null}
        {this.state.questionAnswers && this.state.questionAnswers.length > 0
          ? questionAnswersView
          : null}
      </div>
    );
  };

  private onToggleConsent = (
    _ev?: React.FormEvent<HTMLElement>,
    isChecked?: boolean
  ): void => {
    this.setState({
      consented: isChecked!,
    });
  };

  private getIsJustificationRequired = (): boolean => {
    const selectedPolicy = getSelectedGrantPolicy(this._grantPolicies, this.state.selectedKey);
    return selectedPolicy?.isRequestorJustificationRequired ?? false;
  };

  private onRenderBodyBottom = (): JSX.Element => {
    const {
      grantRequest,
      t,
      submitting,
      validating,
      validationErrors,
    } = this.props;

    if (validating) {
      return (
        <div className={css(myAccessStyles.marginTopXLarge)}>
          {getSpinner(t(LocaleKeys.loading))}
        </div>
      );
    }

    if (submitting) {
      return (
        <div className={css(myAccessStyles.marginTopXLarge)}>
          {getSpinner(t(LocaleKeys.submittingYourAccessRequest))}
        </div>
      );
    }

    if (isValidationErrorTopLevel(validationErrors)) {
      return <div />;
    }

    const startDateValue =
      this.state.newGrantRequest &&
      this.state.newGrantRequest!.accessPackageAssignment!.schedule!
        .startDateTime!;

    const stopDateValue =
      this.state.newGrantRequest &&
      this.state.newGrantRequest!.accessPackageAssignment!.schedule!
        .stopDateTime!;

    const policyAllowCustomSchedule = getPolicyIsCustomAssignmentScheduleAllowed(
      this._grantPolicies,
      this.state.selectedKey
    );

    return (
      <div>
        <div className={css(myAccessStyles.marginTopSmall)}>
          <TextField
            label={t(LocaleKeys.businessJustification)}
            multiline
            rows={4}
            placeholder={
              this.getIsJustificationRequired() ? t(LocaleKeys.required) : null
            }
            value={
              this.state.newGrantRequest &&
              this.state.newGrantRequest!.justification
            }
            onChange={this.onJustificationChanged}
            required={this.getIsJustificationRequired()}
            onGetErrorMessage={this.getErrorMessage}
            disabled={submitting || validating}
          />
        </div>

        <div className={css(myAccessStyles.marginTopSmall)}>
          {policyAllowCustomSchedule ? (
            <Toggle
              label={t(LocaleKeys.requestForSpecificPeriod)}
              defaultChecked={false}
              onChanged={this.onDatesToggled}
              onText={t(LocaleKeys.yes)}
              offText={t(LocaleKeys.no)}
              checked={this.state.datesEnabled}
            />
          ) : null}
          {this.state.datesEnabled && policyAllowCustomSchedule ? (
            <div>
              {grantRequest!.requestType === RequestType.UserExtend ? null : (
                <div>
                  <Label>{t(LocaleKeys.startsOn)}</Label>
                  <DatePicker
                    onSelectDate={this.onStartDateSelected}
                    value={startDateValue as Date}
                    formatDate={FormatDate}
                    minDate={this._minStartDate}
                    maxDate={this._maxStartDate}
                    disabled={!this.state.datesEnabled}
                    ariaLabel={'Pick the start date.'}
                    strings={getDatePickerStrings(t)}
                  />
                </div>
              )}
              <div>
                <Label>{t(LocaleKeys.expiresOn)}</Label>
                <DatePicker
                  onSelectDate={this.onStopDateSelected}
                  value={stopDateValue as Date}
                  formatDate={FormatDate}
                  minDate={this._minStopDate}
                  maxDate={this._maxStopDate}
                  disabled={!this.state.datesEnabled}
                  ariaLabel={'Pick the expiration date.'}
                  strings={getDatePickerStrings(t)}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  private renderHeader = (): JSX.Element => {
    const { entitlementName, t, onDismiss } = this.props;

    return (
      <div className={css(styles.header)}>
        <h3 className={css(styles.headerTitle)}>
          {t(LocaleKeys.requestAccess, {
            context: 'capitalize',
          })}
        </h3>

        <IconButton
          className={css(styles.headerCloseButton)}
          iconProps={{ iconName: 'Cancel' }}
          ariaLabel={t(LocaleKeys.cancel)}
          onClick={onDismiss}
        />
        <div className={styles.flexBreak} />

        <Text className={css(styles.headerDescription)}>{entitlementName}</Text>
      </div>
    );
  };

  private renderFooter = (): JSX.Element => {
    const {
      t,
      needsConsent,
      tenantDisplayName,
      privacyUrl,
      submitting,
      validating,
      validationErrors,
    } = this.props;
    if (
      isValidationErrorTopLevel(validationErrors)
    ) {
      return <div />;
    }

    return (
      <div className={css(styles.footer)}>
        {needsConsent && !submitting && !validating ? (
          <ConsentToggle
            t={t}
            tenantDisplayName={tenantDisplayName}
            privacyUrl={privacyUrl}
            onChange={this.onToggleConsent}
          />
        ) : null}
        <PrimaryButton
          onClick={this.save}
          className={css(styles.primaryFooterButton)}
          disabled={this.isSubmitButtonDisabled() || submitting || validating}
          text={t(LocaleKeys.submit)}
        />
        <DefaultButton
          onClick={this.closePanel}
          text={t(LocaleKeys.cancel)}
          disabled={submitting || validating}
          className={css(styles.secondaryFooterButton)}
        />
      </div>
    );
  };

  private isSubmitButtonDisabled = (): boolean => {
    const { needsConsent, submitting, validating } = this.props;
    const policyAllowCustomSchedule = getPolicyIsCustomAssignmentScheduleAllowed(
      this._grantPolicies,
      this.state.selectedKey
    );
    const isJustificationBlocking =
      this.getIsJustificationRequired() &&
      this.state.newGrantRequest.justification!.length === 0;
    const isConsentBlocking = needsConsent && !this.state.consented;
    const isStatusBlocking = submitting || validating;
    const isGrantPolicyBlocking =
      this._grantPolicyOptions.length > 0 && this.state.selectedKey === '';
    const isDateBlocking =
      policyAllowCustomSchedule &&
      this.state.datesEnabled! &&
      this.state.newGrantRequest &&
      this.state.newGrantRequest.accessPackageAssignment &&
      this.state.newGrantRequest.accessPackageAssignment.schedule &&
      this.state.newGrantRequest.accessPackageAssignment.schedule
        .startDateTime === null &&
      this.state.newGrantRequest.accessPackageAssignment.schedule
        .stopDateTime === null;
    const isQuestionAnswersBlocking =
      this.state.questionAnswers &&
      this.state.questionAnswers.some(
        (qa: QuestionAnswer) =>
          (qa.isRequired && isEmptyOrUndefined(qa.answerValue)) ||
          qa.isValid === false
      );
    const isSODCheckBlocking = getErrorExists(
      this.props.validationErrors,
      ValidationErrorCode.InvalidRequestSODCheck
    );
    return (
      isJustificationBlocking ||
      isConsentBlocking ||
      isStatusBlocking ||
      isGrantPolicyBlocking ||
      isDateBlocking! ||
      isQuestionAnswersBlocking ||
      isSODCheckBlocking
    );
  };

  private getErrorMessage = (value: string): string => {
    if (!this.getIsJustificationRequired()) {
      return LocaleKeys.empty;
    }

    return value.length !== 0 || this.state.textFieldClean
      ? LocaleKeys.empty
      : this.props.t(LocaleKeys.justificationNotEmpty);
  };

  private save = (): void => {
    if (this.props.postGrantRequest !== undefined) {
      if (this.state.questionAnswers && this.state.questionAnswers.length > 0) {
        let answers: IAnswer[] = this.state.questionAnswers
          .filter(
            (qa: QuestionAnswer) =>
              qa.isRequired || !isEmptyOrUndefined(qa.answerValue)
          )
          .map((qa: QuestionAnswer) => {
            return new AnswerString(
              qa.answerValue,
              getQuestionObject(qa),
              qa.answerDisplayValue
            );
          });

        this.state.newGrantRequest.answers = answers;
      }

      this.props.postGrantRequest({
        newGrantRequest: this.state.newGrantRequest,
        entitlementName: this.props.entitlementName!,
      });
    }
  };

  private closePanel = (): void => {
    if (this.props.onDismiss) {
      this.props.onDismiss();
    }
  };

  private onPolicySelectionChanged = (_event?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption): void => {
    this.setState({
      ...this.state,
      newGrantRequest: {
        ...this.state.newGrantRequest,
        accessPackageAssignment: {
          ...this.state.newGrantRequest.accessPackageAssignment,
          assignmentPolicyId: String(option!.key),
          schedule: {
            ...this.state.newGrantRequest.accessPackageAssignment!.schedule,
            stopDateTime: null,
            startDateTime: null,
          },
        },
      },
      selectedKey: option!.key,
      questionAnswers: getQuestionAnswers(
        this.props.validationErrors,
        getSelectedGrantPolicy(this._grantPolicies, option!.key)
      ),
    });

    const expirationDate = getPolicyExpirationDateFromValidationErrors(
      this.props.validationErrors,
      getSelectedGrantPolicy(this._grantPolicies, option!.key)
    );
    this._maxStopDate = expirationDate;
    this._maxStartDate = expirationDate;
  }

  private onJustificationChanged = (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    this.setState({
      ...this.state,
      newGrantRequest: {
        ...this.state.newGrantRequest,
        justification: newValue,
      },
      textFieldClean: false,
    });
  }

  private onStartDateSelected = (date: Date | undefined | null): void => {
    if (!date) {
      return;
    }
    let newDateTime = date;
    if (date.getDate() === new Date().getDate()) {
      newDateTime = new Date();
    }
    this.setState({
      ...this.state,
      newGrantRequest: {
        ...this.state.newGrantRequest,
        accessPackageAssignment: {
          ...this.state.newGrantRequest.accessPackageAssignment,
          schedule: {
            ...this.state.newGrantRequest.accessPackageAssignment!.schedule,
            startDateTime: newDateTime,
          },
        },
      },
    });
    this._minStopDate = newDateTime;
  };

  private onStopDateSelected = (date: Date | undefined | null): void => {
    if (!date) {
      return;
    }
    date.setHours(23, 59, 59);
    this.setState({
      ...this.state,
      newGrantRequest: {
        ...this.state.newGrantRequest,
        accessPackageAssignment: {
          ...this.state.newGrantRequest.accessPackageAssignment,
          schedule: {
            ...this.state.newGrantRequest.accessPackageAssignment!.schedule,
            stopDateTime: date,
          },
        },
      },
    });
    this._maxStartDate = date;
  };

  private onDatesToggled = (): void => {
    if (this.state.datesEnabled) {
      this._minStopDate = new Date(Date.now());
      this._maxStartDate = new Date(9999, 12);
    }
    this.setState({
      ...this.state,
      newGrantRequest: {
        ...this.state.newGrantRequest,
        accessPackageAssignment: {
          ...this.state.newGrantRequest.accessPackageAssignment!,
          schedule: {
            ...this.state.newGrantRequest.accessPackageAssignment!.schedule,
            stopDateTime: null,
            startDateTime: null,
          },
        },
      },
      datesEnabled: !this.state.datesEnabled,
    });
  };

  private onAnswerChanged = (
    questionId: string,
    answerValue: string,
    answerDisplayValue: string,
    isValid: boolean = true
  ): void => {
    let updatedQuestionAnswers = this.state.questionAnswers.map(
      (qa: QuestionAnswer) => {
        if (qa.id === questionId) {
          qa.answerValue = answerValue;
          qa.answerDisplayValue = answerDisplayValue;
          if (qa.$type === QuestionType.TextInputQuestion &&
            !isEmptyOrUndefined(qa.regexPattern) &&
            !isEmptyOrUndefined(qa.answerValue)) {
            const regex = new RegExp(qa.regexPattern);
            isValid = regex.test(qa.answerValue);
          }
          qa.isValid = isValid;
          return qa;
        }
        return qa;
      }
    );

    this.setState({
      ...this.state,
      questionAnswers: updatedQuestionAnswers,
    });
  };

}
