import { ILoadable } from '@microsoft/portal-app/lib/models/ILoading';
import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { useDispatch } from 'react-redux';

import { EntitlementActions } from '../../models';
import { IApproverSubstitution, ISetApproverSubstitutionPayload } from '../../models/RequestApprovals/IApproverSubstitution';
import { useSelector } from '..';

interface IUseApprovalSubstitution {
  getApproverSubstitution: () => void;
  setApproverSubstitution: (payload: ISetApproverSubstitutionPayload) => void;
  approverSubstitution: ILoadable<IApproverSubstitution | null>;
}

export const useApprovalSubstitution = (): IUseApprovalSubstitution => {
  const { approverSubstitution } = useSelector((state) => ({
    approverSubstitution: state.app.approverSubstitution
  }));
  const dispatch = useDispatch();
  const getApproverSubstitution = createAction(EntitlementActions.getApproverSubstitution, dispatch);
  const setApproverSubstitution = createAction(EntitlementActions.setApproverSubstitution, dispatch);
  return { getApproverSubstitution, approverSubstitution, setApproverSubstitution };
};
