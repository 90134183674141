import { IDropdownOption } from '@fluentui/react/lib/Dropdown';

import { removeLeadingSlash } from '../../../redux/modules/ram/utils';
import { Routes } from '../../../shared';

export const replaceForwardSlashes = (str: string): string => {
  str = removeLeadingSlash(str);
  return str.replace(/\//g, '\\');
};

export const genRestoreUri = (virtualMachineId: string, roleId: string): string => {
  const hostname = window.location.hostname.toLowerCase();
  return `https://${hostname}/#${Routes.ram}/${replaceForwardSlashes(virtualMachineId)}/${replaceForwardSlashes(
    roleId
  )}`;
};

const utcTimeZoneOffsets = [
  '-12:00',
  '-11:00',
  '-10:00',
  '-09:00',
  '-08:00',
  '-07:00',
  '-06:00',
  '-05:00',
  '-04:00',
  '-03:00',
  '-02:00',
  '-01:00',
  '+00:00',
  '+01:00',
  '+02:00',
  '+03:00',
  '+03:30',
  '+04:00',
  '+04:30',
  '+05:00',
  '+05:30',
  '+05:45',
  '+06:00',
  '+07:00',
  '+08:00',
  '+08:45',
  '+09:00',
  '+09:30',
  '+10:00',
  '+10:30',
  '+11:00',
  '+12:00',
  '+12:45',
  '+13:00',
  '+14:00'
];

export const timeZoneDropdownOptions = (timeZoneText: string): IDropdownOption[] => {
  return utcTimeZoneOffsets.map((offset) => {
    return { key: offset, text: `${timeZoneText}${offset}` };
  });
};

export const combineDateTimezone = (date: Date, time: string, timezone: string): string => {
  const timeParts = time.split(':');
  const hours = parseInt(timeParts[0]);
  const minutes = parseInt(timeParts[1]);
  const timezoneOffset = parseInt(timezone) || 0;

  const newDate = new Date(date.getTime());
  newDate.setHours(hours - timezoneOffset);
  newDate.setMinutes(minutes);
  return newDate.toISOString();
};

export const getUserTimezoneOffset = (): string => {
  const offsetMinutes = new Date().getTimezoneOffset();
  const offsetHours = offsetMinutes / 60;
  const sign = offsetHours > 0 ? '-' : '+';
  const absoluteOffsetHours = Math.abs(offsetHours);
  const paddedOffsetHours = absoluteOffsetHours < 10 ? `0${absoluteOffsetHours}` : absoluteOffsetHours.toString();
  return `${sign}${paddedOffsetHours}:00`;
};

export const iso8601ToHours = (
  iso8601Duration: string,
  reportCustomEventWithMetadata: (eventName: string, payload?: {} | undefined) => void
): number => {
  if (!iso8601Duration) return 0;

  const match = iso8601Duration.match(/(\d+)([MDHS])/);

  if (match) {
    const number = parseInt(match[1], 10);
    const unit = match[2];

    switch (unit) {
      case 'M':
        return number / 60;
      case 'H':
        return number;
      case 'D':
        return number * 24;
      case 'S':
        return number / 3600;
    }
  }
  reportCustomEventWithMetadata('ram/quickActivation/iso8601ToHours/invalidDuration', { iso8601Duration });
  return 0;
};

export const hoursToIso8601 = (hoursStr: string): string => {
  const hours = parseFloat(hoursStr);
  const minutes = hours * 60;
  return `PT${minutes}M`;
};

export const isValidTimeFormat = (time: string): boolean => {
  const timeFormat = /^([1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/i;
  return timeFormat.test(time);
};

export const getTimeString = (date: Date): string => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${formattedHours}:${formattedMinutes} ${ampm}`;
};
