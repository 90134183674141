import { FeedbackSurface,IInProductFeedbackProps } from '@iamexperiences/inproductfeedback';
import i18next from 'i18next';

import { getBasedFeedbackMetadata } from './util';

const appContextCes = (t: i18next.TranslationFunction) => ({
  enable: true,
  strings: {
    label: t('appContextCesLabel'),
  },
});

const appContextCva = (t: i18next.TranslationFunction) => ({
  enable: true,
  strings: {
    label: t('appContextCvaLabel'),
  },
});

const appContextCsat = (t: i18next.TranslationFunction) => ({
  enable: true,
  strings: {
    label: t('appContextCsatLabel'),
  },
});

const appContextExperience = (t: i18next.TranslationFunction) => ({
  enable: true,
  strings: {
    label: t('appContextExperienceLabel'),
    placeholder: t('appContextExperiencePlaceholder'),
  },
});

export const getInProductFeedbackLocalizedSettings = (t: i18next.TranslationFunction): IInProductFeedbackProps => ({
  appContext: {
    type: FeedbackSurface.Dialog,
    feedback: JSON.parse(JSON.stringify(getBasedFeedbackMetadata())),
    ces: JSON.parse(JSON.stringify(appContextCes(t))),
    cva: JSON.parse(JSON.stringify(appContextCva(t))),
    csat: JSON.parse(JSON.stringify(appContextCsat(t))),
    experience: JSON.parse(JSON.stringify(appContextExperience(t))),
  },
  context: {
    strings: {
      header: {
        closeLabel: t('contextHeaderCloseLabel'),
        moveLabel: t('contextHeaderMoveLabel'),
        title: t('contextHeaderTitle'),
      },
      ces: {
        effort: [
          { text: t('contextEffortTextOne'), ariaLabel: t('contextCesEffortAriaLabelOne') },
          { text: t('contextEffortTextTwo'), ariaLabel: t('contextCesEffortAriaLabelTwo') },
          { text: t('contextEffortTextThree'), ariaLabel: t('contextCesEffortAriaLabelThree') },
          { text: t('contextEffortTextFour'), ariaLabel: t('contextCesEffortAriaLabelFour') },
          { text: t('contextEffortTextFive'), ariaLabel: t('contextCesEffortAriaLabelFive') },
        ],
        rating: {
          low: t('contextCesRatingLow'),
          high: t('contextCesRatingHigh'),
        },
      },
      cva: {
        value: [
          { text: t('contextEffortTextOne'), ariaLabel: t('contextCvaEffortAriaLabelOne') },
          { text: t('contextEffortTextTwo'), ariaLabel: t('contextCvaEffortAriaLabelTwo') },
          { text: t('contextEffortTextThree'), ariaLabel: t('contextCvaEffortAriaLabelThree') },
          { text: t('contextEffortTextFour'), ariaLabel: t('contextCvaEffortAriaLabelFour') },
          { text: t('contextEffortTextFive'), ariaLabel: t('contextCvaEffortAriaLabelFive') },
        ],
        rating: {
          low: t('contextCvaRatingLow'),
          high: t('contextCvaRatingHigh'),
        },
      },
      csat: {
        value: [
          { text: t('contextEffortTextOne'), ariaLabel: t('contextCsatEffortAriaLabelOne') },
          { text: t('contextEffortTextTwo'), ariaLabel: t('contextCsatEffortAriaLabelTwo') },
          { text: t('contextEffortTextThree'), ariaLabel: t('contextCsatEffortAriaLabelThree') },
          { text: t('contextEffortTextFour'), ariaLabel: t('contextCsatEffortAriaLabelFour') },
          { text: t('contextEffortTextFive'), ariaLabel: t('contextCsatEffortAriaLabelFive') },
        ],
        rating: {
          low: t('contextCsatRatingLow'),
          high: t('contextCsatRatingHigh'),
        },
      },
      contactMe: {
        checkBoxLabel: t('contextContactMeCheckBoxLabel'),
        emailLabel: t('contextContactMeEmailLabel'),
        privacyInfoText: t('contextContactMePrivacyInfoText'),
        privacyLinkText: t('contextContactMePrivacyLinkText'),
      },
      footer: {
        submitLabel: t('contextFooterSubmitLabel'),
        cancelLabel: t('contextFooterCancelLabel'),
      },
      validation: {
        invalidEmail: t('contextValidationInvalidEmail'),
        required: t('contextValidationRequired'),
      },
      success: {
        header: {
          closeLabel: t('contextSuccessHeaderCloseLabel'),
          moveLabel: t('contextSuccessHeaderMoveLabel'),
          dialogHeader: t('contextSuccessHeaderDialogHeader'),
        },
        body: {
          infoText: t('contextSuccessBodyInfoText'),
        },
        footer: {
          closeLabel: t('contextSuccessFooterCloseLabel'),
        },
      },
      noFeedback: {
        header: {
          closeLabel: t('contextNoFeedbackHeaderCloseLabel'),
          moveLabel: t('contextNoFeedbackHeaderMoveLabel'),
          dialogHeader: t('contextNoFeedbackHeaderDialogHeader'),
        },
        body: {
          infoText: t('contextNoFeedbackBodyInfoText'),
        },
        footer: {
          closeLabel: t('contextNoFeedbackFooterCloseLabel'),
        },
      },
    },
  },
});
