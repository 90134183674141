// Required for MSAL to work in IE (doesn't work in portal-app polyfills).
import 'core-js/features/promise/finally';

import { IAuth } from '@iamexperiences/react-auth/lib-commonjs/core';
import { getAuthV1 } from '@iamexperiences/react-auth/lib-commonjs/implementations/V1';

import { settingsManager } from './shared/settings';
import { telemetry } from './shared/telemetry';

// Default to PROD endpoint
const DEFAULT_AUTHORITY = 'https://login.microsoftonline.com/common/';
const authority = settingsManager.get('authAuthorityEndpoint') ?? DEFAULT_AUTHORITY;
const clientId = settingsManager.get('adalClientId');

export const auth: IAuth = getAuthV1({
  // Replace this with the client ID (UUID) for your app registration.
  clientId: clientId ?? process.env.APP_ADAL_CLIENT_ID ?? '810dcf14-1858-4bf2-8134-4c369fa3235b',
  authority
  // And any other MSAL configuration you need...,
});
export const subscribeToErrorObservable = (authInstance: IAuth): void => {
  authInstance.loginError.subscribe((error) => {
    if (error === null) {
      return;
    }
    telemetry.error('/login/failed', error);
  });
  authInstance.tokenError.subscribe((error) => {
    if (error === null) {
      return;
    }
    telemetry.error('/token/failed', error);
  });
};
