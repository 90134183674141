export const emptyGuid = '00000000-0000-0000-0000-000000000000';
export const selfReviewTypeId = 'aca2d526-6de7-4854-9eff-b8901bf9604c';
export const allRolesReviewTypeId = 'e0a240cd-752c-422b-8068-bdd370a272b8';
export const armAllRolesReviewTypeId = 'deb8e8e3-9575-4bcc-b9ff-8f7e98635e4b';

export const P1_SERVICE_PLAN_ID = '41781fb2-bc02-4b7c-bd55-b576c07bb09d';
export const P2_SERVICE_PLAN_ID = 'eec0eb4f-6444-4f95-aba0-50c24d67f998';
export const ENTRA_IDENTITY_GOVERNANCE_SERVICE_PLAN_ID = 'e866a266-3cff-43a3-acca-0c90a7e00c8b';
export enum MSGraphScopes {
  CrossTenantUserProfileSharingReadWriteAll = 'CrossTenantUserProfileSharing.ReadWrite.All',
  OrganizationReadAll = 'Organization.Read.All',
  UserReadAll = 'User.Read.All',
  UserReadWrite = 'User.ReadWrite',
  MailboxSettingsReadWrite = 'MailboxSettings.ReadWrite',
  BitlockerKeyReadAll = 'BitlockerKey.Read.All',
}
export const AnswerStringType = '#Microsoft.IGAELM.EC.FrontEnd.ExternalModel.answerString';

export const MultipleChoiceQuestionType = '#Microsoft.IGAELM.EC.FrontEnd.ExternalModel.multipleChoiceQuestion';
export const TextInputQuestionType = '#Microsoft.IGAELM.EC.FrontEnd.ExternalModel.textInputQuestion';
export const ONE_DS_CONSOLE_QUERY_STRING = '1dsConsole';

export const ThrottleResponseCode = 429;

/**
 * This lowercase eu string is used to compare the results of the
 * data boundary claim. The Data boundary claim expected results are:
 * 'EU' - for European tenants
 * undefined - for the rest of the world
 */
export const EU_DATA_BOUNDARY_CLAIM_VALUE = 'eu';

export const MOBILE_BREAK_POINT = 's';

// route map
interface RoutePath {
  link: string;
  key: string;
  subRoutePaths?: Record<string, RoutePath>;
}
type RoutePathMap = Record<
  | 'overview'
  | 'catalogs'
  | 'accessPackages'
  | 'requestHistory'
  | 'requestApprovals'
  | 'accessReviews'
  | 'supervisorCentricReviews'
  | 'ram'
  | 'me',
  RoutePath
>;
export const NAVIGATION_LINKS: RoutePathMap = {
  overview: { link: '/overview', key: 'overview' },
  catalogs: { link: '/catalogs', key: 'catalogs' },
  accessPackages: {
    link: '/access-packages',
    key: 'accessPackages',
    subRoutePaths: {
      activeEntitlements: { link: '/access-packages/active', key: 'activeEntitlements' },
      expiredEntitlements: { link: '/access-packages/expired', key: 'expiredEntitlements' },
      recommendations: { link: '/access-packages/recommendation', key: 'recommendations'},
      available: {link: '/access-packages/available', key: 'available'}
    }
  },
  requestHistory: { link: '/request-history', key: 'requestHistory' },
  requestApprovals: {
    link: '/request-approval',
    key: 'requestApprovals',
    subRoutePaths: {
      pendingRequestApprovals: { link: '/request-approval/pending', key: 'pendingRequestApprovals' },
      completedRequestApprovals: { link: '/request-approval/completed', key: 'completedRequestApprovals' }
    }
  },
  accessReviews: { link: '/access-reviews', key: 'accessReviews' },
  supervisorCentricReviews: { link: '/user-access-reviews', key: 'accessReviews' },
  ram: { link: '/ram', key: 'ram' },
  me: { link: '/me', key: 'me' }
};
