import { withAuth } from '@microsoft/portal-app/lib/auth/withAuth';
import { createHashHistory } from 'history';
import { routerMiddleware } from 'react-router-redux';
import { applyMiddleware, createStore } from 'redux';
import { patchStore, Store } from '@microsoft/portal-app/lib/redux/compat';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createEpicMiddleware } from 'redux-observable';
import { ajax } from 'rxjs/observable/dom/ajax';
import { IGlobalState, getRootEpic } from './modules/root';
import { rootReducer } from './modules/root';

const createReduxWaitForMiddleware = require('redux-wait-for-action').default;

const withAuthAjax = withAuth(ajax);
export const history = createHashHistory();
const routerMiddlewareToApply = routerMiddleware(history);

export const configureStore = (): Store<IGlobalState> => {
  const epicMiddleware = createEpicMiddleware(getRootEpic(), {
    dependencies: { ajax: withAuthAjax }
  });

  const store = patchStore(
    createStore<IGlobalState>(
      rootReducer(history),
      composeWithDevTools(
        applyMiddleware(
          epicMiddleware,
          routerMiddlewareToApply,
          createReduxWaitForMiddleware(),
        )
      )
    )
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./modules/root', async () => {
      const root = await import('./modules/root');
      store.replaceReducer(root.rootReducer(history));
    });
  }

  return store;
};
