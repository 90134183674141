import { notificationsMerge } from '@microsoft/portal-app/lib/Notifications/helpers/notificationsMerge';
import {
  INotification,
  NotificationSeverity,
  NotificationType
} from '@microsoft/portal-app/lib/Notifications/models/INotification';
import { getResponseValue, IODataValueResponse } from '@microsoft/portal-app/lib/odata-utils';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { TranslationOptions } from 'i18next';
import * as moment from 'moment';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';
import { v4 } from 'uuid';
import { isNullOrUndefined } from 'util';
import { IEntitlement } from '../../../models/ELM/IEntitlement';
import { IEntity } from '../../../models/ELM/IEntity';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { EntityType } from '../../../models/EntityType';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState, IRootEntitlementsState } from '../../../models/IEntitlementState';
import { IEntityResult } from '../../../models/IEntityResult';
import { LoadMode } from '../../../models/IPageData';
import { getRequestWithAudience } from '../../../shared/AttachAudience';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { registry } from '../myAccessRegistry';

export const searchForMoreEpic: Epic<IEntitlementAction<AnyPayload>, IRootEntitlementsState> = (
  action$: ActionsObservable<
    IEntitlementAction<{
      entityType: EntityType;
    }>
  >,
  store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
  return action$
    .ofType(EntitlementActions.searchForMore)
    .switchMap((action: IEntitlementAction<{ entityType: EntityType }>) => {
      const entityType = action.payload!.entityType;

      const state: IEntitlementState = store.getState().app;
      const nextLink = state[entityType].filtered.nextLink;

      return ajax(getRequestWithAudience(nextLink!, entityType as EntityType))
        .map((payload: IODataValueResponse<ReadonlyArray<IEntity>>) => {
          return {
            type: EntitlementActions.searchEntitiesOnServerSucceeded,
            payload: {
              entityType: entityType,
              entities: getResponseValue(payload),
              count: payload.response!['@odata.count'],
              nextLink: payload.response!['@odata.nextLink']
            }
          } as IEntitlementAction<IEntityResult<IEntitlement>>;
        })
        .catch(() =>
          Observable.of({
            type: EntitlementActions.searchEntitiesOnServerFailed,
            payload: entityType
          })
        );
    });
};
registry.addEpic('searchForMoreEpic', searchForMoreEpic);

export const searchForMore = (
  state: IEntitlementState,
  action: IEntitlementAction<{ entityType: string }>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  let entityType = action.payload.entityType;
  const loadMode = state[entityType].filtered.nextLink ? LoadMode.LoadMore : LoadMode.Refresh;

  return {
    ...state,
    [entityType]: {
      ...state[entityType],
      isLoading: true,
      loadMode: loadMode
    }
  };
};
registry.add(EntitlementActions.searchForMore, searchForMore);

export const searchEntitiesOnServerSucceeded = (
  state: IEntitlementState,
  action: IEntitlementAction<IEntityResult<IEntity>>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined || !action.payload.entities) {
    return state;
  }
  const { entityType, entities, count, nextLink } = action.payload;

  let usePrincipal = false;

  let mappedEntities = entities.map((item: IEntity) => {
    if (isNullOrUndefined(item.id)) {
      usePrincipal = true;
    }
    return [item.id, item];
  }) as [string, IEntitlement][];

  if (usePrincipal) {
    let idList: string[] = [];
    let tempEnt = action.payload.entities as any[];
    if (tempEnt.length > 0 && tempEnt[0].principal) {
      mappedEntities = [];
      mappedEntities = tempEnt.map((item: any) => {
        item.id = item.principal.id;
        idList.push(item.id);
        return [item.principal.id, item];
      }) as [string, IEntitlement][];
    }

    for (let index of Object.keys(mappedEntities)) {
      let prin = mappedEntities[index][1] as any;
      if (prin.principal) {
        mappedEntities[index][0] = prin.principal.id;
        mappedEntities[index][1].id = prin.principal.id;
        idList.push(mappedEntities[index][0]);
      }
    }
  }

  const newEntitiesById = new Map([
    // tslint:disable-next-line:no-any
    ...(Array.from(state[entityType].entitiesById) as any),
    ...mappedEntities
  ]);

  let newState = {
    ...state,
    [entityType]: {
      ...state[entityType],
      entitiesById: newEntitiesById,
      filtered: {
        ...state[entityType].filtered,
        entities: state[entityType].filtered.entities.concat(entities.map((item: IEntity) => item.id)),
        count: count,
        nextLink: nextLink
      },
      isLoading: false,
      loadMode: undefined
    }
  };

  if (
    entityType === EntityType.entitlements ||
    entityType === EntityType.expiredGrants ||
    entityType === EntityType.validGrants
  ) {
    newState = {
      ...newState,
      pageCounts: {
        ...state.pageCounts,
        [entityType]: action.payload.count!
      }
    };
  }

  return newState;
};
registry.add(EntitlementActions.searchEntitiesOnServerSucceeded, searchEntitiesOnServerSucceeded);

export const searchEntitiesOnServerFailed = (
  state: IEntitlementState,
  action: IEntitlementAction<Readonly<string>>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  const entityType = action.payload;

  let toastKey = LocaleKeys.generalErrorMessage;
  let toastOptions: TranslationOptions = {};

  let notifications: INotification[] = [
    {
      id: v4(),
      createdDateTime: moment(),
      localizableMessage: {
        key: toastKey,
        options: toastOptions
      },
      severity: NotificationSeverity.error,
      type: NotificationType.card
    }
  ];

  return {
    ...state,
    notifications: notificationsMerge(notifications, state.notifications, state.notificationsLimit),
    [entityType]: {
      ...state[entityType],
      isLoading: false,
      loadMode: undefined
    }
  };
};
registry.add(EntitlementActions.searchEntitiesOnServerFailed, searchEntitiesOnServerFailed);
