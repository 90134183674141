import { QuestionAnswer } from '../models/ELM/QuestionAnswer';
import { AnswerString } from '../models/ELM/AnswerString';
import { ExternalQuestionType, QuestionType } from '../models/ELM/QuestionType';
import { IAnswer } from '../models/ELM/IAnswer';
import { IQuestion } from '../models/ELM/IQuestion';
import { AnswerStringType } from '../shared/constants';

export const convertAnswersToQuestionAnswers = (answers: IAnswer[] | undefined): QuestionAnswer[] => {
  let questionAnswers: QuestionAnswer[] = [];
  if (answers && answers.length > 0) {
    questionAnswers = answers.map(answer => {
      return {
        '$type': answer.answeredQuestion.choices == null ? QuestionType.TextInputQuestion : QuestionType.MultipleChoiceQuestion,
          id: answer.answeredQuestion.id,
          attributeName: answer.answeredQuestion.attributeName,
          isAttribute: answer.answeredQuestion.isAttribute,
          text: answer.answeredQuestion.text,
          questionType: answer.answeredQuestion.questionType,
          isRequired: answer.answeredQuestion.isRequired,
          showAnswerToApprover: answer.answeredQuestion.showAnswerToApprover!,
          sequence: answer.answeredQuestion.sequence,
          choices: answer.answeredQuestion.choices,
          maximumCharacters: answer.answeredQuestion.maximumCharacters,
          minimumValue: answer.answeredQuestion.minimumValue,
          maximumValue: answer.answeredQuestion.maximumValue,
          maximumRating: answer.answeredQuestion.maximumRating,
          step: answer.answeredQuestion.step,
          dateFormat: answer.answeredQuestion.dateFormat,
          numberOfLines: answer.answeredQuestion.numberOfLines,
          regexPattern: answer.answeredQuestion.regexPattern,
          answerValue: (answer as AnswerString).value,
          answerDisplayValue: answer.displayValue!,
          isSingleLineQuestion: true, // It is loaded as single line always for the requesting user
          isAnswerEditable: true,
          isNonAnswerable: false
        };
      });
    }
    return questionAnswers;
};

export const convertQuestionAnswersToAnswers= (questionAnswers: QuestionAnswer[]): IAnswer[] => {
  let answers: IAnswer[] = [];
  if (questionAnswers && questionAnswers.length > 0) {
  answers = questionAnswers.map(questionAnswer => {
    const answeredQuestion: IQuestion = {
      '@odata.type': questionAnswer.$type === QuestionType.TextInputQuestion
        ? ExternalQuestionType.TextInputQuestion
        : ExternalQuestionType.MultipleChoiceQuestion,
        id: questionAnswer.id,
        attributeName: questionAnswer.attributeName,
        isAttribute: questionAnswer.isAttribute,
        text: questionAnswer.text,
        questionType: questionAnswer.questionType,
        isRequired: questionAnswer.isRequired,
        sequence: questionAnswer.sequence,
        choices: questionAnswer.choices,
        maximumCharacters: questionAnswer.maximumCharacters,
        minimumValue: questionAnswer.minimumValue,
        maximumValue: questionAnswer.maximumValue,
        maximumRating: questionAnswer.maximumRating,
        step: questionAnswer.step,
        dateFormat: questionAnswer.dateFormat,
        numberOfLines: questionAnswer.numberOfLines,
        regexPattern: questionAnswer.regexPattern
    };
    return {
    '@odata.type': AnswerStringType,
        value: questionAnswer.answerValue,
        answeredQuestion: answeredQuestion,
        displayValue: questionAnswer.answerDisplayValue
      };
    });
  }
  return answers;
};