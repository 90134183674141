import '@microsoft/portal-app/lib/styling/patterns/Dialog.scss';
import { TranslationFunction } from 'i18next';

import {
  ChoiceGroup,
  css,
  DefaultButton,
  IChoiceGroupOption,
  PrimaryButton,
  TextField
} from '@fluentui/react';
import { ResponsiveMode } from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import * as React from 'react';
import { AccessReviewType } from '../../../models/AccessReviews/AccessReviewType';
import { IAccessReviewDecision } from '../../../models/AccessReviews/IAccessReviewDecision';
import { DecisionType } from '../../../models/RequestApprovals/DecisionType';
import { history } from '../../../redux/configureStore';
import { asLocalizedText } from '../../../shared/asLocalizedText';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { Routes } from '../../../shared/Routes';
import { getSpinner } from '../../../shared/spinner';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');
const selfReviewStyles = require('./SelfReview.scoped.scss');

export interface ISelfReviewProps {
  resourceName: string;
  resourceType: string;
  isSubmitting: boolean;
  reviewDecision: IAccessReviewDecision | null;
  justificationRequired: boolean | null;
  t: TranslationFunction;
  responsiveMode?: ResponsiveMode;
  onSubmit(decisionType: string, justification: string): void;
}

export interface ISelfReviewState {
  decisionType: string;
  justification: string;
}

export class SelfReview extends React.Component<ISelfReviewProps, ISelfReviewState> {
  constructor(nextProps: ISelfReviewProps) {
    super(nextProps);
    this.state = {
      decisionType: (this.props.reviewDecision ? this.props.reviewDecision.reviewResult! : ''),
      justification: (this.props.reviewDecision ? this.props.reviewDecision.justification! : '')
    };
  }

  public render(): JSX.Element {
    let entityTypeText = '';
    switch (this.props.resourceType) {
      case AccessReviewType.Group: {
        entityTypeText = this.props.t(LocaleKeys.group);
        break;
      }
      case AccessReviewType.App: {
        entityTypeText = this.props.t(LocaleKeys.app);
        break;
      }
      case AccessReviewType.AccessPackage: {
        entityTypeText = this.props.t(LocaleKeys.accessPackage);
        break;
      }
    }

    let currentDecision = DecisionType.NotReviewed;
    if (this.props.reviewDecision) {
      currentDecision = this.props.reviewDecision.reviewResult!;
    }
    const choiceGroupOptions = [
      {
        key: DecisionType.Approve,
        text: this.props.t(LocaleKeys.yes),
        checked: currentDecision === DecisionType.Approve
      },
      {
        key: DecisionType.Deny,
        text: this.props.t(LocaleKeys.no),
        checked: currentDecision === DecisionType.Deny
      }
    ];

    return (
      <div className={css(selfReviewStyles.selfReviewPanel)}>
        <div className={css(selfReviewStyles.selfReviewContent)}>
          <div className={css(selfReviewStyles.selfReviewTitle)}>
            {asLocalizedText(
              {
                key: LocaleKeys.selfReviewTitle,
                options: {
                  entityType: entityTypeText,
                  entityName: this.props.resourceName
                }
              },
              this.props.t
            )}
          </div>
          <ChoiceGroup
            className={css(selfReviewStyles.bottomMargin)}
            options={choiceGroupOptions}
            onChange={this._onDecisionChanged}
          />
          <TextField
            label={this.props.t(LocaleKeys.reason)}
            value={this.state.justification}
            className={css(selfReviewStyles.selfReviewTextBox)}
            required={this.props.justificationRequired! && this.state.decisionType === DecisionType.Approve}
            onChange={this._onJustificationChanged}
            disabled={this.props.isSubmitting}
            multiline
          />
          {this.props.isSubmitting ? (
            getSpinner(asLocalizedText(LocaleKeys.submitting, this.props.t))
          ) : (
              <div>
                <PrimaryButton
                  // tslint:disable-next-line:jsx-no-lambda
                  onClick={() => this.props.onSubmit(this.state.decisionType, this.state.justification)}
                  text={this.props.t(LocaleKeys.submit)}
                  className={css(myAccessStyles.marginRightSmall)}
                  disabled={
                    (this.props.justificationRequired
                      && this.state.decisionType === DecisionType.Approve
                      && this.state.justification === '')
                    || this.state.decisionType === ''
                  }
                />
                <DefaultButton
                  // tslint:disable-next-line:jsx-no-lambda
                  onClick={() => { history.push(Routes.accessReviews); }}
                  text={this.props.t(LocaleKeys.cancel)}
                />
              </div>
            )}
        </div>
      </div>
    );
  }

  private _onDecisionChanged = (_event?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption): void => {
    this.setState({
      ...this.state,
      decisionType: DecisionType[option!.key]
    });
  }

  private _onJustificationChanged =  (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void =>  {
    this.setState({
      ...this.state,
      justification: newValue!
    });
  }
}
