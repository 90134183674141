import { css, FontClassNames } from '@fluentui/react';
import * as React from 'react';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');
const cardStyles = require('../../../css/card.scoped.scss');

export interface ISimpleHoverCardProps {
  localizedTitle: string;
  localizedDescription: string;
}

export class SimpleHoverCard extends React.Component<ISimpleHoverCardProps, {}> {
  public render(): JSX.Element {
    const { localizedDescription, localizedTitle } = this.props;

    return (
      <div className={css(cardStyles.smallCard)} tabIndex={0}>
        <div className={css(cardStyles.card)}>
          <div className={css(FontClassNames.large, myAccessStyles.marginBottomSmall)}>{localizedTitle}</div>
          <div>{localizedDescription}</div>
        </div>
      </div>
    );
  }
}
