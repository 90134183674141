import { FontClassNames } from '@fluentui/react';
import { css } from '@fluentui/react';
import * as React from 'react';
import { InjectedTranslateProps } from 'react-i18next';

const panelSubHeadingStyles = require('./PanelSubHeading.scoped.scss');

export interface IPanelSubHeadingProps extends InjectedTranslateProps {
  title: string;
  primaryValue: string;
  secondaryValue?: string;
}

export class PanelSubHeading extends React.Component<
  IPanelSubHeadingProps,
  {}
> {
  public render(): JSX.Element {
    const { t, title, primaryValue, secondaryValue } = this.props;

    return (
      <div className={css(panelSubHeadingStyles.container)}>
        <div className={css(panelSubHeadingStyles.titleSection)}>
          <span className={css(FontClassNames.small)}>{t(title)}</span>
        </div>
        <div className={css(panelSubHeadingStyles.valueSection)}>
          <div className={css(panelSubHeadingStyles.subSection)}>
            <span className={css(FontClassNames.small)}>{primaryValue}</span>
          </div>

          {secondaryValue ? (
            <div className={css(panelSubHeadingStyles.subSection)}>
              <span className={css(FontClassNames.xSmall)}>
                {secondaryValue}
              </span>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
