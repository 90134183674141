import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { translate } from 'react-i18next';
import { connect, Dispatch } from 'react-redux';
import { EntitlementActions } from '../../../models';
import { IGrantRequest } from '../../../models/ELM/IGrantRequest';
import { EntityType } from '../../../models/EntityType';
import { IRootEntitlementsState } from '../../../models/IEntitlementState';
import { history } from '../../../redux/configureStore';
import { GrantRequestDetails } from './GrantRequestDetails';
import {
  IConnectedGrantRequestDetailsProps,
  IGrantRequestDetailsActions,
  IGrantRequestDetailsMappedProps
} from './GrantRequestDetails.types';

/**
 * Maps the external (i.e. own) props and the state to the properties of the GrantRequestDetails control.
 * @param state The current redux state to map to props
 * @param ownProps The external properties to map to props
 * @returns The mapped properties that along with the actions
 * becomes the props (IGrantRequestDetailsProps) for the GrantRequestDetails control
 */
const mapStateToProps = (
  state: IRootEntitlementsState,
  ownProps: IConnectedGrantRequestDetailsProps
): IGrantRequestDetailsMappedProps => {
  const grantRequest = state.app.grantRequests.entitiesById.get(
    ownProps.grantRequestId
  );

  const submitting = state.app.submitting;

  return {
    ...ownProps,
    isLoading: state.app.isEntityDetailLoading,
    grantRequest: grantRequest!,
    submitting,
    navigateTo(url: string): void {
      history.push(url);
    }
  };
};

/**
 * Maps the dispatch actions to the props for the GrantRequestDetails control.
 * @param dispatch Dispatches actions to the store
 * @returns The mapped actions that along with the mapped properties
 * becomes the props (IGrantRequestDetailsProps) for the GrantRequestDetails control
 */
const mapDispatchToProps = (
  dispatch: Dispatch<IRootEntitlementsState>
): IGrantRequestDetailsActions => {
  const getEntity = createAction<{
    entityId: string;
    entityType: string;
  }>(EntitlementActions.getEntity, dispatch);
  return {
    getGrantRequest: (id: string) =>
      getEntity({
        entityId: id,
        entityType: EntityType.grantRequests
      }),
    cancelGrantRequest: createAction<Partial<IGrantRequest>>(
      EntitlementActions.updateGrantRequest,
      dispatch
    )
  };
};

/**
 * A redux connected GrantRequestDetails control.
 * @description Supports translation
 */
export const ConnectedGrantRequestDetails = connect(
  mapStateToProps,
  mapDispatchToProps
  // tslint:disable-next-line:no-any
)(translate('MyAccess')(GrantRequestDetails) as any);
