// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../dev/node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../dev/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2nqhtPbk391kZpB6KbkNyv{display:flex;justify-content:space-between;align-items:center}\n", "",{"version":3,"sources":["webpack://./src/components/ELM/GrantRequests/GrantRequests.scoped.scss"],"names":[],"mappings":"AAAA,yBACE,YAAa,CACb,6BAA8B,CAC9B,kBAAmB","sourcesContent":[".titleContainer {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleContainer": "_2nqhtPbk391kZpB6KbkNyv"
};
module.exports = ___CSS_LOADER_EXPORT___;
