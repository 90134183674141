import { getTheme, makeStyles, TextField } from '@fluentui/react';
import * as React from 'react';
import { FunctionComponent } from 'react';

import { CopyButton } from '../CopyButton/CopyButton';

/**
 * Props for CopyableTextField Component
 */
export interface CopyableTextFieldProps {
  /**
   * Value to be copied
   */
  readonly value: string;
  /**
   * Placeholder of the text field
   */
  readonly placeHolder?: string;
  /**
   * Denotes if text field is read only. Text field is read only by default.
   */
  readonly readOnly?: boolean;
  /**
   * Called when the TextField value is changed.
   */
  onChange?: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => void;
  /**
   * Called when the value is copied
   */
  readonly onCopy?: (copied: boolean) => void;
  /**
   * Aria label for the text field.
   */
  readonly ariaLabel?: string;
}

/**
 * CopyableTextField component.
 *
 */
export const CopyableTextField: FunctionComponent<CopyableTextFieldProps> = ({
  value,
  readOnly,
  placeHolder,
  onChange,
  onCopy,
  ariaLabel
}: CopyableTextFieldProps) => {
  const { copyButtonContainer, textFieldContainer } = copyableTextFieldStyles();

  return (
    <div className={textFieldContainer}>
      <TextField
        placeholder={placeHolder}
        readOnly={readOnly ?? true}
        value={value}
        onChange={onChange}
        styles={textFieldStyles}
        ariaLabel={ariaLabel}
      />
      <span className={copyButtonContainer}>
        <CopyButton value={value} onCopy={onCopy} />
      </span>
    </div>
  );
};

const copyableTextFieldStyles = makeStyles(() => ({
  copyButtonContainer: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    padding: '0px 4px',
    color: getTheme().palette.blue
  },
  textFieldContainer: {
    position: 'relative'
  }
}));

const textFieldStyles = {
  fieldGroup: [{ height: 38 }],
  field: { textOverflow: 'ellipsis', paddingRight: 40 }
};
