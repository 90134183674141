import '@microsoft/portal-app/lib/styling/patterns/Dialog.scss';
import { AppPanel } from '@uifabric/portal-ux/lib/AppPanel';

import {
  ActivityItem,
  ChoiceGroup,
  ColorClassNames,
  css,
  DefaultButton,
  FontClassNames,
  IChoiceGroupOption,
  Icon,
  Label,
  PanelType,
  PrimaryButton,
  TextField
} from '@fluentui/react';
import { withResponsiveMode } from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import * as React from 'react';
import { isNullOrUndefined } from 'util';
import { IAccessReviewDecision } from '../../../models/AccessReviews/IAccessReviewDecision';
import { IAccessReviewSubject } from '../../../models/AccessReviews/IAccessReviewSubject';
import { DecisionType } from '../../../models/RequestApprovals/DecisionType';
import { RecommendationType } from '../../../models/RequestApprovals/RecommendationType';
import { checkFeatureAccess } from '../../../shared';
import { asLocalizedText } from '../../../shared/asLocalizedText';
import { FormatDate, FormatDateTime } from '../../../shared/FormatDateTime';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { getSpinner } from '../../../shared/spinner';
import {
  IDecisionDetailsProps,
  IDecisionDetailsState
} from './DecisionDetails.types';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');

@withResponsiveMode
export class DecisionDetails extends React.Component<
  IDecisionDetailsProps,
  IDecisionDetailsState
> {
  constructor(nextProps: IDecisionDetailsProps) {
    super(nextProps);
    this.state = {
      decisionType: '',
      justification: ''
    };
  }

  public componentDidUpdate(prevProps: IDecisionDetailsProps): void {
    if (prevProps.isSubmitting && !this.props.isSubmitting) {
      this.props.onDismiss();
    } else if (prevProps.hidden && !this.props.hidden) {
      this.setState({
        decisionType: '',
        justification: ''
      });
    }
  }

  public render(): JSX.Element {
    const { hidden, decision, t } = this.props;

    let isByod = false;
    let displayName = '';
    let lastSignIn = '';
    let recommendation = '';
    let description = '';
    let principal;
    let resource;

    let approveRadioText = this.props.t(LocaleKeys.approve);
    let denyRadioText = this.props.t(LocaleKeys.deny);
    let dontKnowRadioText = this.props.t(LocaleKeys.dontKnow);

    if (!isNullOrUndefined(decision)) {
      if (!isNullOrUndefined(decision.isByod)) {
        isByod = decision.isByod;
      }

      if (isByod) {
        if (!isNullOrUndefined(decision.principal)) {
          principal = this._renderByodProperty(decision.principal, true);
        }
        if (!isNullOrUndefined(decision.resource)) {
          resource = this._renderByodProperty(decision.resource, false);
        }
      } else {
        displayName = decision.userDisplayName;
      }

      lastSignIn = FormatDate(decision.lastUserSignInDateTime);
      recommendation = decision.accessRecommendation!;

      if (decision.groupDisplayName) {
        displayName = decision.groupDisplayName;
      } else if (decision.servicePrincipalDisplayName) {
        displayName = decision.servicePrincipalDisplayName;
      }

      switch (recommendation) {
        case RecommendationType.Approve:
          recommendation = t(LocaleKeys.approve);
          description = t(LocaleKeys.recommendApprove, {
            lookback: this.props.lookbackDuration
          });
          approveRadioText = asLocalizedText(
            {
              key: LocaleKeys.decisionRecommended,
              options: {
                decisionType: this.props.t(LocaleKeys.approve)
              }
            },
            this.props.t
          );
          break;
        case RecommendationType.Deny:
          recommendation = t(LocaleKeys.deny);
          description = t(LocaleKeys.recommendDeny, {
            lookback: this.props.lookbackDuration
          });
          denyRadioText = asLocalizedText(
            {
              key: LocaleKeys.decisionRecommended,
              options: {
                decisionType: this.props.t(LocaleKeys.deny)
              }
            },
            this.props.t
          );
          break;
        default:
          recommendation = t(LocaleKeys.notAvailable);
          break;
      }
    }

    const customApprove = () => {
      const standardTitle = <div>{approveRadioText}</div>;
      const boldTitle = <b>{approveRadioText}</b>;
      return (
        <div className={css(myAccessStyles.customRadioButton)}>
          {recommendation === RecommendationType.Approve ? (
            <div>
              {boldTitle}
              {!this.props.insights.inactive &&
                this.props.currentReview?.settings?.lastSignInRecommendationEnabled ? (
                <div className={css(myAccessStyles.insightDetails)}>
                  {this.props.t(LocaleKeys.insightActive, {
                    lookback: this.props.lookbackDuration
                  })}
                </div>
              ) : null}
              {this.props.currentReview?.settings?.groupPeerOutlierRecommendationEnabled &&
                !this.props.insights.outlier ? (
                <div className={css(myAccessStyles.insightDetails)}>
                  {this.props.t(LocaleKeys.insightNotOutlier)}
                </div>
              ) : null}
            </div>
          ) : standardTitle}
        </div>
      );
    };

    const customDeny = () => {
      const standardTitle = <div>{denyRadioText}</div>;
      const boldTitle = <b>{denyRadioText}</b>;
      return (
        <div className={css(myAccessStyles.customRadioButton)}>
          {recommendation === RecommendationType.Deny ? (
            <div>
              {boldTitle}
              {this.props.insights.inactive &&
                this.props.currentReview?.settings?.lastSignInRecommendationEnabled ? (
                <div className={css(myAccessStyles.insightDetails)}>
                  {this.props.t(LocaleKeys.insightInactive, {
                    lookback: this.props.lookbackDuration
                  })}
                </div>
              ) : null}
              {this.props.insights.outlier ? (
                <div className={css(myAccessStyles.insightDetails)}>
                  {this.props.t(LocaleKeys.insightOutlier)}
                </div>
              ) : null}
            </div>
          ) : standardTitle}
        </div>
      );
    };

    const choiceGroupOptions = [
      {
        key: DecisionType.Approve,
        ariaLabel: DecisionType.Approve,
        text: approveRadioText,
        onRenderLabel: customApprove
      },
      {
        key: DecisionType.Deny,
        ariaLabel: DecisionType.Deny,
        text: denyRadioText,
        onRenderLabel: customDeny
      },
      {
        key: DecisionType.DontKnow,
        ariaLabel: "Don't Know",
        text: dontKnowRadioText
      }
    ];

    let showRecommendations = false;

    if (this.props.currentReview &&
      this.props.currentReview.settings &&
      this.props.currentReview.settings.accessRecommendationsEnabled) {
      showRecommendations = this.props.currentReview.settings.accessRecommendationsEnabled;
    }

    return (
      <AppPanel
        isBlocking={false}
        isOpen={!hidden}
        onDismiss={this._panelDismiss}
        type={PanelType.custom}
        customWidth={'440px'}
        headerText={displayName}
        onRenderFooterContent={this._onRenderFooterContent}
        closeButtonAriaLabel={t(LocaleKeys.cancel)}
        aria-hidden={false}
        hasCloseButton={true}
      >
        {!isNullOrUndefined(decision) ? (
          <div>
            {isByod ? (
              <div className={css(myAccessStyles.wordBreak)}>
                <div>
                  <div className={css('ms-pii', myAccessStyles.marginBottomXSmall, FontClassNames.large, myAccessStyles.bold)}>
                    {t(LocaleKeys.identity)}
                  </div>
                  <table className={css('ms-pii', myAccessStyles.decisionDetailsTable)}>
                    {principal}
                  </table>
                </div>
                <div>
                  <div className={css('ms-pii', myAccessStyles.marginBottomXSmall, myAccessStyles.marginTopSmall)} />
                  <div className={css('ms-pii', FontClassNames.large, myAccessStyles.bold)}>
                    {t(LocaleKeys.resource)}
                  </div>
                  <table className={css('ms-pii', myAccessStyles.decisionDetailsTable)}>
                    {resource}
                  </table>
                </div>
              </div>) :
              <div className={css(myAccessStyles.wordBreak, myAccessStyles.secondaryText)}>
                {decision.userPrincipalName}
              </div>}

            {recommendation !== RecommendationType.NotAvailable && showRecommendations && !isByod ? (
              <div>
                <div className={css('ms-pii', myAccessStyles.marginBottomSmall, myAccessStyles.marginTopXSmall)}>
                  {this.props.currentReview?.settings?.lastSignInRecommendationEnabled ? (
                    <div className={css('ms-pii')}>
                      <span className={css('ms-pii', FontClassNames.medium)}>
                        {description}
                      </span>
                      {decision.lastUserSignInDateTime ? (
                        <span className={css('ms-pii', FontClassNames.medium)}>
                          {' (' + lastSignIn + ')'}
                        </span>
                      ) : null}
                    </div>
                  ) : null}
                  {this.props.currentReview?.settings?.groupPeerOutlierRecommendationEnabled &&
                    this.props.insights.outlier === false ? (
                    <div>
                      {this.props.t(LocaleKeys.insightNotOutlier)}
                    </div>
                  ) : null}
                </div>
              </div>) : null
            }

            {this._renderDecisionHistory(decision)}
            {this._renderCurrentDecision(decision)}

            {decision.reviewResult !== DecisionType.NotReviewed ? (
              <Label className={css(myAccessStyles.appPanelLabel)}>
                {this.props.t(LocaleKeys.changeDecision, {
                  context: 'capitalize'
                })}
              </Label>
            ) : <div className={css(myAccessStyles.marginBottomSmall)} />}

            {decision.reviewResult === DecisionType.NotReviewed && this.props.currentReview.stage! > 0 ? (
              <div>
                <Label className={css(myAccessStyles.appPanelLabel)}>
                  {this.props.t(LocaleKeys.stageTitle, {
                    stageNumber: this.props.currentReview.stage! + 1,
                    context: 'capitalize'
                  })}
                </Label>
                <div className={css(myAccessStyles.subtitleText)}>
                  {t(LocaleKeys.decisionWillOverride)}
                </div>
              </div>
            ) : <div className={css(myAccessStyles.marginBottomSmall)} />}

            <ChoiceGroup
              className={css(myAccessStyles.marginBottomSmall)}
              options={choiceGroupOptions}
              onChange={this._onDecisionChanged}
            />
            <TextField
              label={this.props.t(LocaleKeys.reason)}
              value={this.state.justification}
              className={css(myAccessStyles.marginBottomXSmall)}
              required={this.props.justificationRequired! && this.state.decisionType === DecisionType.Approve}
              onChange={this._onJustificationChanged}
              disabled={this.props.isSubmitting}
              multiline
            />
          </div>
        ) : null}
      </AppPanel>
    );
  }

  private _renderCurrentDecision(decision: IAccessReviewDecision): JSX.Element | void {

    if (decision.reviewResult !== DecisionType.NotReviewed && decision.reviewedBy) {
      return (<div className={css(myAccessStyles.appPanelLabel, myAccessStyles.marginBottomMedium)}>
        <Label className={css(myAccessStyles.appPanelLabel, myAccessStyles.marginBottomXSmall)}>
          {this.props.t(LocaleKeys.currentDecision)}
        </Label>
        {this._getDecisionActivityItem(decision)}
      </div>);
    }
  }

  private _renderDecisionHistory(decision: IAccessReviewDecision): JSX.Element | void {
    const result: JSX.Element[] = [];
    if (decision.histories && decision.histories.length > 0 && this.props.currentReview.stage! > 0) {
      result.push(
        <Label className={css(myAccessStyles.appPanelLabel, myAccessStyles.marginBottomXSmall)} key="title">
          {this.props.t(LocaleKeys.decisionHistory)}
        </Label>
      );
      decision.histories.forEach((d: IAccessReviewDecision) => {
        if (d.stage < this.props.currentReview.stage!) {
          result.push(this._getPreviousDecision(d, d.id, true));
        }
      });
    }
    return <div>{result}</div>;
  }

  private _getPreviousDecision(decision: IAccessReviewDecision, key?: string, stage?: boolean): JSX.Element {
    return this._getDecisionActivityItem(decision, key, stage);
  }

  private _getDecisionActivityItem(decision: IAccessReviewDecision, key?: string, stage?: boolean): JSX.Element {
    return (
      <ActivityItem
        activityDescription={this._getActivityDescription(decision, stage)}
        activityIcon={this._getActivityIcon(decision)}
        className={css(
          'ms-pii',
          myAccessStyles.marginBottomXSmall,
          ColorClassNames.neutralLighterBackground,
          myAccessStyles.padding
        )}
        key={key}
      />
    );
  }

  private _panelDismiss = (): void => {
    this.setState({
      ...this.state,
      decisionType: '',
      justification: ''
    });
    this.props.onDismiss();
  }

  private _getActivityIcon(decision: IAccessReviewDecision): React.ReactNode {
    let iconString = 'CheckMark';
    switch (decision!.reviewResult) {
      case DecisionType.Deny:
        iconString = 'Cancel';
        break;
      case DecisionType.DontKnow:
        iconString = 'Help';
        break;
      case DecisionType.NotReviewed:
        iconString = 'StatusCircleBlock2';
        break;
      default:
        break;
    }
    return <Icon iconName={iconString} />;
  }

  private _getActivityDescription(
    decision: IAccessReviewDecision,
    stage?: boolean
  ): JSX.Element {
    let decisionString = LocaleKeys.decisionApproved;

    if (decision.reviewResult === DecisionType.Deny) {
      decisionString = LocaleKeys.decisionDenied;
    } else if (decision.reviewResult === DecisionType.DontKnow) {
      decisionString = LocaleKeys.decisionDontKnow;
    } else if (decision.reviewResult === DecisionType.NotReviewed) {
      decisionString = LocaleKeys.notReviewed;
    }

    return (
      <div>
        {stage ?
          <div className={css(myAccessStyles.semiBoldText)}>
            {asLocalizedText(
              {
                key: LocaleKeys.stageTitle,
                options: {
                  stageNumber: decision!.stage + 1
                }
              },
              this.props.t
            )}
          </div> : null}
        <div className={css(myAccessStyles.semiBoldText)}>
          {asLocalizedText(
            {
              key: decisionString,
              options: {
                reviewerName: decision!.reviewedBy!.displayName
              }
            },
            this.props.t
          )}
        </div>
        <div>{decision!.reviewedBy!.userPrincipalName}</div>
        <div>{FormatDateTime(decision.reviewedDateTime)}</div>
        {decision!.justification ?
          <div>
            <div className={css(myAccessStyles.semiBoldText, myAccessStyles.paddingTopSmall)}>
              {this.props.t(LocaleKeys.reason)}
            </div>
            <div>{decision!.justification}</div>
          </div> : null}
      </div>
    );
  }

  private _onDecisionChanged = (_event?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption): void => {
    this.setState({
      ...this.state,
      decisionType: DecisionType[option!.key]
    });
  }

  private _onJustificationChanged = (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    this.setState({
      ...this.state,
      justification: newValue!
    });
  }

  private _onRenderFooterContent = (): JSX.Element => {
    return (
      <div>
        {this.props.isSubmitting ? (
          getSpinner(asLocalizedText(LocaleKeys.submitting, this.props.t))
        ) : (
          <div>
            <PrimaryButton
              // tslint:disable-next-line:jsx-no-lambda
              onClick={() => this.props.onSubmit(this.state.decisionType, this.state.justification)}
              text={this.props.t(LocaleKeys.submit)}
              ariaLabel={(LocaleKeys.submit)}
              aria-hidden={false}
              className={css(myAccessStyles.marginRightSmall)}
              disabled={
                (this.props.justificationRequired
                  && this.state.decisionType === DecisionType.Approve
                  && this.state.justification === '')
                || this.state.decisionType === ''
              }
            />
            <DefaultButton
              onClick={this.props.onDismiss}
              ariaLabel={(LocaleKeys.cancel)}
              aria-hidden={false}
              text={this.props.t(LocaleKeys.cancel)}
            />
          </div>
        )}
      </div>
    );
  }

  private _renderByodProperty = (property: IAccessReviewSubject, isPrincipal: boolean): any => {
    return Object.entries(property).map(([key, value]) => {
      if (key.includes('@odata.type') ||
        key === 'schemaId' ||
        key === 'homeTenantId' ||
        key === 'homeTenantDisplayName' ||
        key === 'homeTenantPrimaryDomain' ||
        (isPrincipal && key === 'type')) {
        return;
      }

      let highlght = key === 'displayName' ||
        (isPrincipal && key === 'principalType') ||
        (!isPrincipal && key === 'type');

      // Make sure we render empty string for null value
      if (isNullOrUndefined(value)) {
        value = '';
      }

      let isLink = !Array.isArray(value) && value instanceof String && value.toLowerCase().startsWith('http');

      return (
        <tr key={key} className={css(myAccessStyles.wordBreak, highlght ? myAccessStyles.bold : '')}>
          <td>
            {key}:
          </td>
          <td>
            {Array.isArray(value) ? value.map((singleValue: string) => {
              return (
                <div key={singleValue}>
                  {singleValue}
                </div>
              );
            }) : isLink ? <a href={value} target="_blank" className={css(myAccessStyles.linkColor)}>{value}</a>
              : value}
          </td>
        </tr>
      );
    });
  }
}
