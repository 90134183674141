import {
  ColumnActionsMode,
  FontClassNames,
  ResponsiveMode,
} from '@fluentui/react';
import * as React from 'react';

import { IEntitlement } from '../../../models/ELM/IEntitlement';
import { IListColumn } from '../../../models/IListColumn';
import { GridSizes, resizeResponsiveGrid } from '../../../shared';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { ColumnValue } from '../../Shared/ColumnValue/ColumnValue';
import { OverflowActionButton } from '../OverflowActionButton';
import { IAccessPackageRecommendation, AccessPackageSuggestionReason, AccessPackageSuggestionReasonType } from '../../../models/ELM/IAccessPackageRecommendations';
import { isNullOrUndefined } from 'util';
import { TranslationFunctionKeys } from '../../../models';

const myAccessListStyles = require('../../../css/myAccessList.scoped.scss');

const sizes: GridSizes = {
  displayName: {
    [ResponsiveMode.small]: [150, 316],
    [ResponsiveMode.medium]: [317, 476],
    [ResponsiveMode.large]: [160, 320],
    [ResponsiveMode.xLarge]: [200, 233],
    [ResponsiveMode.xxLarge]: [235, 300],
    [ResponsiveMode.xxxLarge]: [295, 600],
  },
  description: {
    [ResponsiveMode.xLarge]: [208, 360],
    [ResponsiveMode.xxLarge]: [363, 700],
    [ResponsiveMode.xxxLarge]: [660, 1100],
  },
  insights: {
    [ResponsiveMode.large]: [200, 395],
    [ResponsiveMode.xLarge]: [240, 280],
    [ResponsiveMode.xxLarge]: [280, 460],
    [ResponsiveMode.xxxLarge]: [445, 900],
  },
  clickThroughActionButton:{
    [ResponsiveMode.xxxLarge]: [130, 200]
  }
};

export const getInsightsString = (reasons: AccessPackageSuggestionReason[], t: TranslationFunctionKeys): string  => {
  if (reasons.length === 0){
    return '';
  }

  const relatedPeopleBasedSuggestion = reasons.find(r => r['@odata.type'] == AccessPackageSuggestionReasonType.AccessPackageSuggestionRelatedPeopleBased);
  const workWithCount: number | undefined = relatedPeopleBasedSuggestion?.relatedPeopleAssignmentCount ?? 0;
  const hasAssignmentHistoryRecommendation: boolean = !isNullOrUndefined(reasons.find(r => r['@odata.type'] == AccessPackageSuggestionReasonType.AccessPackageSuggestionSelfAssignmentHistoryBased));

  let insightsString = '';
  if (workWithCount > 0 && hasAssignmentHistoryRecommendation){
    insightsString = workWithCount == 1? t(LocaleKeys.insightsForRelatedPersonAndHistory) : t(LocaleKeys.insightsForRelatedPeopleAndHistory, {count: workWithCount});
  } else if (workWithCount > 0){
    insightsString = workWithCount == 1? t(LocaleKeys.insightsForRelatedPerson) : t(LocaleKeys.insightsForRelatedPeople, {count: workWithCount});
  } else if (hasAssignmentHistoryRecommendation) {
    insightsString = t(LocaleKeys.insightsForHistory);
  }
  return insightsString;
}
export const getEntitlementRecommendationListColumns = (
  responsiveMode: ResponsiveMode,
  t: TranslationFunctionKeys,
  isSearching: boolean,
  isSortedDescending: boolean,
  onCtaButtonClick: (item: IEntitlement) => void,
): IListColumn<IEntitlement>[] => {
  const columns: IListColumn<IAccessPackageRecommendation>[] = [
    {
      key: 'displayName',
      name: t(LocaleKeys.name),
      fieldName: 'displayName',
      minWidth: 100,
      maxWidth: 300,
      headerClassName: FontClassNames.smallPlus,
      onColumnClick: () => {},
      isResizable: true,
      onRender: (item: IAccessPackageRecommendation) => {
        return (
          <ColumnValue
            searchTerm=''
            columnValue={item.accessPackage?.displayName?? ''}
            isHighlightRequired={false}
            isSearching={false}
          />
        );
      },
    },
    {
      key: 'clickThroughActionButton',
      name: t(LocaleKeys.actions),
      columnActionsMode: ColumnActionsMode.disabled,
      fieldName: '',
      minWidth: 100,
      maxWidth: 170,
      isResizable: false,
      onRender: (item) => (
        <OverflowActionButton
          className={myAccessListStyles.rightActionButton}
          primaryActionAriaDescription={t(
            LocaleKeys.requestAccessPackageButtonAria,
            { packageName: item.accessPackage?.displayName }
          )}
          itemKey={item.id}
          showPrimaryAction={true}
          onPrimaryPress={() => onCtaButtonClick(item)}
          primaryText={t(LocaleKeys.request, { context: 'capitalize' })}
        />
      ),
    },
  ];

  if (responsiveMode > ResponsiveMode.medium){
    columns.splice(1, 0, {
      key: 'insights',
      name: t(LocaleKeys.insight),
      columnActionsMode: ColumnActionsMode.disabled,
      fieldName: 'insights',
      headerClassName: FontClassNames.smallPlus,
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      onRender: (item: IAccessPackageRecommendation) => (
        <ColumnValue
          searchTerm={''}
          columnValue={getInsightsString(item.accessPackageSuggestionReasons?? [], t)}
          isHighlightRequired={true}
          isSearching={isSearching}
        />
      ),
    } as IListColumn<IAccessPackageRecommendation>);
  }

  if (responsiveMode > ResponsiveMode.large){
    columns.splice(2, 0, {
      key: 'description',
      name: t(LocaleKeys.description),
      columnActionsMode: ColumnActionsMode.disabled,
      fieldName: 'description',
      headerClassName: FontClassNames.smallPlus,
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      onRender: (item: IAccessPackageRecommendation) => (
        <ColumnValue
          searchTerm={''}
          columnValue={item.accessPackage?.description?? ''}
          isHighlightRequired={false}
          isSearching={false}
        />
      ),
    } as IListColumn<IAccessPackageRecommendation>);
  }

  return resizeResponsiveGrid(columns, sizes, responsiveMode);
};
