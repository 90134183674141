import { ColorClassNames, FontClassNames } from '@fluentui/react';
import { css } from '@fluentui/react';
import { ActivityItem, HoverCard, HoverCardType, Icon, IPlainCardProps, Link } from '@fluentui/react';
import {
  IWithResponsiveModeState,
  withResponsiveMode
} from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import * as React from 'react';
import { InjectedTranslateProps, TranslationFunction } from 'react-i18next';
import { LocaleKeys } from '../../../shared/LocaleKeys';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');

export interface IApprovalHistoryActivityItemProps extends IWithResponsiveModeState, InjectedTranslateProps {
  t: TranslationFunction;
  cardAdditionalDescription?: string;
  title: string;
  dateTime: string;
  hoverCardDom?: JSX.Element;
  iconName: string;
}

@withResponsiveMode
export class ApprovalHistoryActivityItem extends React.Component<IApprovalHistoryActivityItemProps> {
  constructor(nextProps: IApprovalHistoryActivityItemProps) {
    super(nextProps);
  }

  public render(): JSX.Element {
    const { title, dateTime, cardAdditionalDescription, hoverCardDom, iconName } = this.props;

    const plainCardProps: IPlainCardProps = {
      onRenderPlainCard: () => hoverCardDom || <div />
    };
    const descriptionDiv = (
      <div className={css(FontClassNames.smallPlus, myAccessStyles.marginBottomXSmall)}>
        <div className={css('ms-pii', myAccessStyles.wordBreak)}>{title}</div>
        {cardAdditionalDescription ? (
          <span className={css(myAccessStyles.semiBoldText)}>{cardAdditionalDescription}</span>
        ) : null}
      </div>
    );

    const timeStampDiv = (
      <div>
        <span className={css(FontClassNames.xSmall)}>{dateTime}</span>
        {hoverCardDom &&
          <HoverCard
            instantOpenOnClick={true}
            type={HoverCardType.plain}
            plainCardProps={plainCardProps}
            setInitialFocus={true}
            trapFocus={true}
          >
            <Link className={css(FontClassNames.small)}>{this.props.t(LocaleKeys.more)}</Link>
          </HoverCard>
        }
      </div>
    );
    return (
      <ActivityItem
        activityDescription={descriptionDiv}
        timeStamp={timeStampDiv}
        activityIcon={<Icon iconName={iconName} />}
        className={css(
          'ms-pii',
          myAccessStyles.marginBottomXSmall,
          ColorClassNames.neutralLighterBackground,
          myAccessStyles.padding
        )}
      />
    );
  }
}
