import { ColorClassNames, css, FontClassNames, Icon, Link } from '@fluentui/react';
import { withResponsiveMode } from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { getPageMetaDataFromEntities, isEmptyOrUndefined, LocaleKeys, Routes } from '../../../shared';
import { Subtitle } from '../../Shared/Subtitle/Subtitle';
import { ConnectedCatalogsList } from '../CatalogsList';
import { ConnectedEntitlementList } from '../EntitlementList';
import { ICatalogsPageProps, ICatalogsPageState } from './CatalogsPage.types';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');
const myAccessListStyles = require('../../../css/myAccessList.scoped.scss');
const globalStyles = require('../../../css/global.scss');

@withResponsiveMode
export class CatalogsPage extends React.Component<ICatalogsPageProps, ICatalogsPageState> {
  constructor(props: ICatalogsPageProps) {
    super(props);
  }

  public render(): React.ReactNode {
    const { catalogId } = this.props.match.params as { catalogId: string };
    const isSingleCatalogView = !!catalogId;

    return (
      <main data-automation-id="CatalogsPage" className={css(globalStyles.detailsPage)}>
        <div className={css(myAccessListStyles.listPage)}>
          <div className={css(myAccessStyles.paddingAround)}>
            {isSingleCatalogView ? this.renderBackButton() : null}
            {this.renderHeader()}
          </div>
          {this.renderMainContent()}
        </div>
      </main>
    );
  }

  private readonly navigateTo = (path: string) => () => this.props.history.push(path);

  private readonly renderBackButton = (): JSX.Element => (
    <div>
      <Link onClick={this.navigateTo(Routes.catalogs)} className={css(ColorClassNames.black)}>
        <Icon iconName={'Back'} />
        <span className={css(FontClassNames.mediumPlus, myAccessStyles.marginLeftXSmall)}>
          {this.props.t(LocaleKeys.all, {
            context: 'capitalize'
          })}
        </span>
      </Link>
    </div>
  );

  private readonly renderHeader = (): JSX.Element => {
    const { t, searchTerm, catalogs } = this.props;
    const pageMetadata = getPageMetaDataFromEntities(catalogs);
    const { catalogId } = this.props.match.params as { catalogId: string };
    const isSingleCatalogView = !!catalogId;

    return (
      <>
        <div id="pageTitle" role="region" aria-labelledby="pageTitle" className={css(myAccessListStyles.pageTitle)}>
          {t(LocaleKeys.catalogUpper, { context: 'plural' })}
        </div>
        <div
          id="pageSubtitle"
          role="region"
          aria-labelledby="pageSubtitle"
          className={css(myAccessStyles.marginTopXSmall, myAccessListStyles.pageSubtitle)}
        >
          <Subtitle
            isSearching={!isEmptyOrUndefined(searchTerm)}
            isFiltering={isSingleCatalogView}
            pageMetaData={pageMetadata}
            searchTerm={searchTerm}
            entityName={LocaleKeys.catalog}
            t={t}
          />
        </div>
      </>
    );
  };

  /**
   * The main content in the page. Is either the catalogs or entitlements list.
   */
  private readonly renderMainContent = (): JSX.Element => (
    <Switch>
      {/* All access packages for a catalog */}
      <Route exact path={`${Routes.catalogs}${Routes.catalogId}`} component={ConnectedEntitlementList} />

      {/* All catalogs */}
      <Route exact path={Routes.catalogs} component={ConnectedCatalogsList} />

      {/* Fallback redirect to /catalogs */}
      <Redirect path={Routes.root} to={Routes.catalogs} />
    </Switch>
  );
}
