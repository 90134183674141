import { css, DefaultButton, Dialog, DialogFooter, DialogType, ResponsiveMode } from '@fluentui/react';
import { useResponsiveMode } from '@uifabric/portal-ux/lib/styling/useResponsiveMode';
import React, { useEffect, useState } from 'react';

import { useTranslation } from '../../../hooks';
import { IErrorDialog } from '../../../models/RAM/IErrorDialog';
import { LocaleKeys } from '../../../shared';

const ramDialogStyles = require('../Styles/ram.dialog.scss');
const ramErrorDialogStyles = require('./ram.errorDialog.scss');
const errorDialogStyles = {
  [ResponsiveMode.small]: ramDialogStyles.dialogSmall,
  [ResponsiveMode.medium]: ramDialogStyles.dialogMedium,
  [ResponsiveMode.large]: ramDialogStyles.dialogLarge,
  [ResponsiveMode.xLarge]: ramDialogStyles.dialogXLarge,
  [ResponsiveMode.xxLarge]: ramDialogStyles.dialogXXLarge,
  [ResponsiveMode.xxxLarge]: ramDialogStyles.dialogXXXLarge
};

const dialogContentProps = {
  type: DialogType.close
};

interface IRamErrorDialogProps extends IErrorDialog {
  onDismiss: VoidFunction;
}
export const RamErrorDialog: React.FunctionComponent<IRamErrorDialogProps> = (props: IRamErrorDialogProps) => {
  const t = useTranslation();
  const responsiveMode = useResponsiveMode();
  const [containerClassName, setContainerClassName] = useState<string>(errorDialogStyles[responsiveMode]);

  useEffect(() => {
    setContainerClassName(errorDialogStyles[responsiveMode]);
  }, [responsiveMode]);

  return (
    <Dialog
      hidden={false}
      onDismiss={() => props.onDismiss()}
      dialogContentProps={dialogContentProps}
      modalProps={{ isBlocking: true, containerClassName }}
    >
      <div className={css(ramErrorDialogStyles.center)}>
        {props.image && <img src={props.image} alt="error" />}
        {props.title && <strong>{t(props.title)}</strong>}
        {props.message && t(props.message)}
      </div>
      <DialogFooter>
        {props.onContinue ? (
          <DefaultButton
            onClick={() => {
              if (props.onContinue) props.onContinue();
              props.onDismiss();
            }}
            text={t(LocaleKeys.ramErrorContinue)}
          />
        ) : (
          <DefaultButton onClick={() => props.onDismiss()} text={t(LocaleKeys.ramErrorClose)} />
        )}
      </DialogFooter>
    </Dialog>
  );
};
