import { TranslationFunction } from 'i18next';

import { css, FontClassNames } from '@fluentui/react';
import * as React from 'react';
import { DecisionType } from '../../../models/RequestApprovals/DecisionType';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { LabelValuePair } from '../LabelValuePair/LabelValuePair';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');
const cardStyles = require('../../../css/card.scoped.scss');

export interface IDecisionHoverCardProps {
  t: TranslationFunction;
  decisionType: DecisionType;
  actorDisplayName?: string;
  actorEmail?: string;
  detail?: string;
}

export class DecisionHoverCard extends React.Component<IDecisionHoverCardProps, {}> {
  public render(): JSX.Element {
    const { t, decisionType, actorDisplayName, actorEmail, detail } = this.props;

    return (
      <div className={css(myAccessStyles.wordBreak, cardStyles.smallCard)} tabIndex={0}>
        <div className={css(cardStyles.card)}>
          <div className={css(FontClassNames.large)}>{t(this._getTitleLocaleKey(decisionType))}</div>
          <div className={css(myAccessStyles.marginTopSmallPlus, 'ms-pii')}>
            <LabelValuePair label={this._getLabelHeaderLocaleKey(decisionType)} value={actorDisplayName || ''} t={t} />
          </div>
          <div className={css(FontClassNames.small)}>{actorEmail}</div>
        </div>
        {detail ? (
          <div className={css(cardStyles.card)}>
            <LabelValuePair label={LocaleKeys.reason} value={detail} t={t} />
          </div>
        ) : null}
      </div>
    );
  }

  private _getTitleLocaleKey = (decisionType: DecisionType): string => {
    switch (decisionType) {
      case DecisionType.Approve:
        return LocaleKeys.approved;
      case DecisionType.Deny:
        return LocaleKeys.denied;
      case DecisionType.DontKnow:
        return LocaleKeys.dontKnow;
      case DecisionType.AccessRemoved:
        return LocaleKeys.accessRemoved;
      default:
        return '';
    }
  };

  private _getLabelHeaderLocaleKey = (decisionType: DecisionType): string => {
    switch (decisionType) {
      case DecisionType.Approve:
        return LocaleKeys.actorApprovedThis;
      case DecisionType.Deny:
        return LocaleKeys.actorDeniedThis;
      case DecisionType.DontKnow:
        return LocaleKeys.actorMarkedDontKnow;
      case DecisionType.AccessRemoved:
        return LocaleKeys.actorRemovedThis;
      default:
        return '';
    }
  };
}
