import { IAjaxRequest } from '@microsoft/portal-app/lib/auth/withAuth';
import { getResponseValue, IODataValueResponse } from '@microsoft/portal-app/lib/odata-utils';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';
import { IEntity } from '../../../models/ELM/IEntity';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { EntityType } from '../../../models/EntityType';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import {
  IEntitlementState,
  IRootEntitlementsState
} from '../../../models/IEntitlementState';
import { IEntityResult } from '../../../models/IEntityResult';
import { getRequestWithAudience } from '../../../shared/AttachAudience';
import { registry } from '../myAccessRegistry';
import { getAccessReviewsCountApiUrl } from '../../../shared/getAccessReviewsApiUrl';
import { AccessReviewPartnerGuid } from '../../../models/AccessReviews/AccessReviewType';
import { OverviewErrorEventName } from '../../../models/IOverviewData';
import { getResponseError } from '../../../shared/getResponseValue';
export const getGroupReviewCountEpic: Epic<
  IEntitlementAction<AnyPayload>,
  IRootEntitlementsState
> = (
  action$: ActionsObservable<IEntitlementAction>,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
  return action$
    .ofType(EntitlementActions.getGroupReviewCount)
    .switchMap((_action: IEntitlementAction) => {
      const ajaxRequest: IAjaxRequest = getRequestWithAudience(
        getAccessReviewsCountApiUrl(AccessReviewPartnerGuid.Governance),
        EntityType.accessReviews
      );

      return (
        ajax(ajaxRequest)
          .timeout(10000) // 10000ms
          .map((payload: IODataValueResponse<ReadonlyArray<IEntity>>) => {
            return {
              type: EntitlementActions.getGroupReviewCountSucceeded,
              payload: {
                count: payload.response!['@odata.count']
              }
            } as IEntitlementAction<IEntityResult<IEntity>>;
          })
          // tslint:disable-next-line:no-any
          .catch((error: any) =>
            Observable.of({
              type: EntitlementActions.getGroupReviewCountFailed,
              payload: {
                error: error
              }
            })
          )
      );
    });
};
registry.addEpic('getGroupReviewCountEpic', getGroupReviewCountEpic);

export const getGroupReviewCount = (
  state: IEntitlementState
): Readonly<IEntitlementState> => {

  return {
    ...state,
    overviewGroupReviewData: {
      isLoading: true,
      isSucceeded: false
    }
  };
};
registry.add(EntitlementActions.getGroupReviewCount, getGroupReviewCount);

export const getGroupReviewCountSucceeded = (
  state: IEntitlementState,
  action: IEntitlementAction<IEntityResult<IEntity>>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

return {
    ...state,
   overviewGroupReviewData: {
    isLoading: false,
    isSucceeded: true,
    pendingActionEntityCount: action.payload.count!
   }
  };
};

registry.add(
  EntitlementActions.getGroupReviewCountSucceeded,
  getGroupReviewCountSucceeded
);

export const getGroupReviewCountFailed = (
  state: IEntitlementState,
  // tslint:disable-next-line:no-any
  action: IEntitlementAction
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  return {
    ... state,
    overviewGroupReviewData:{
      isLoading: false,
      isSucceeded: false,
      errorEventName: OverviewErrorEventName.groupReviewError,
      error: getResponseError(action.payload.error)
    }
  };
};
registry.add(
  EntitlementActions.getGroupReviewCountFailed,
  getGroupReviewCountFailed
);
