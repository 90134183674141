import { IAjaxRequest } from '@microsoft/portal-app/lib/auth/withAuth';
import { IODataValueResponse } from '@microsoft/portal-app/lib/odata-utils';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';
import { IEntity } from '../../../models/ELM/IEntity';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { EntityType } from '../../../models/EntityType';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import {
  IEntitlementState,
  IRootEntitlementsState
} from '../../../models/IEntitlementState';
import { IEntityResult } from '../../../models/IEntityResult';
import { getRequestWithAudience } from '../../../shared/AttachAudience';
import { getPendingRaeRequestsCountApiUrl } from '../../../shared/getRaeApiUrl';
import { registry } from '../myAccessRegistry';

export const getPendingApprovalsCountEpic: Epic<
  IEntitlementAction<AnyPayload>,
  IRootEntitlementsState
> = (
  action$: ActionsObservable<IEntitlementAction>,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
  return action$
    .ofType(EntitlementActions.getPendingApprovalsCount)
    .switchMap((_action: IEntitlementAction<{ entityType: EntityType }>) => {
      const ajaxRequest: IAjaxRequest = getRequestWithAudience(
        getPendingRaeRequestsCountApiUrl(),
        EntityType.pendingRaeRequests
      );

      return (
        ajax(ajaxRequest)
          .map((payload: IODataValueResponse<ReadonlyArray<IEntity>>) => {
            return {
              type: EntitlementActions.getPendingApprovalsCountSucceeded,
              payload: {
                count: payload.response!['@odata.count']
              }
            } as IEntitlementAction<IEntityResult<IEntity>>;
          })
          // tslint:disable-next-line:no-any
          .catch((_error: any) =>
            Observable.of({
              type: EntitlementActions.getPendingApprovalsCountFailed
            })
          )
      );
    });
};
registry.addEpic('getPendingApprovalsCountEpic', getPendingApprovalsCountEpic);

export const getPendingApprovalsCountSucceeded = (
  state: IEntitlementState,
  action: IEntitlementAction<IEntityResult<IEntity>>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  const pendingApprovalsCount = action.payload.count!;
  return {
    ...state,
    pageCounts: {
      ...state.pageCounts,
      approvals: pendingApprovalsCount
    }
  };
};
registry.add(
  EntitlementActions.getPendingApprovalsCountSucceeded,
  getPendingApprovalsCountSucceeded
);

export const getPendingApprovalsCountFailed = (
  state: IEntitlementState,
  // tslint:disable-next-line:no-any
  _action: IEntitlementAction<Readonly<any>>
): Readonly<IEntitlementState> => {
  return state;
};
registry.add(
  EntitlementActions.getPendingApprovalsCountFailed,
  getPendingApprovalsCountFailed
);
