import { EntitlementActions } from '../../../models/EntitlementActions';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState } from '../../../models/IEntitlementState';
import { registry } from '../myAccessRegistry';

export const clearValidationErrors = (
  state: IEntitlementState,
  _action: IEntitlementAction<boolean>
): IEntitlementState => {
  return {
    ...state,
    validationErrors: []
  };
};
registry.add(EntitlementActions.clearValidationErrors, clearValidationErrors);
