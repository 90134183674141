import '@microsoft/portal-app/lib/styling/patterns/Dialog.scss';
import { AppPanel } from '@uifabric/portal-ux/lib/AppPanel';
import {
  css,
  FontClassNames,
  HoverCard,
  HoverCardType,
  IPlainCardProps,
  Label,
  Link,
  PanelType,
  KeyCodes
} from '@fluentui/react';
import { withResponsiveMode } from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import * as React from 'react';
import { isNullOrUndefined } from 'util';
import { v4 as guid } from 'uuid';
import { IAccessReviewResource } from '../../../models/AccessReviews/IAccessReviewResource';
import { ResourceType } from '../../../models/AccessReviews/ResourceType';
import { IEnvironment } from '../../../models/ELM/IEnvironment';
import { IResource } from '../../../models/ELM/IResource';
import { IRole } from '../../../models/ELM/IRole';
import { IRoleScope } from '../../../models/ELM/IRoleScope';
import { IScope } from '../../../models/ELM/IScope';
import { OriginSystem } from '../../../models/ELM/OriginSystem';
import { DecisionCriteriaTypeId } from '../../../models/RequestApprovals/DecisionType';
import { asLocalizedText } from '../../../shared/asLocalizedText';
import { getUserFromAuth } from '../../../shared/authHelper';
import { FormatDateTime } from '../../../shared/FormatDateTime';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { ConnectedResourceSummary } from '../../Shared/ResourceSummary/ConnectedResourceSummary';
import {
  IAccessReviewDetailsProps,
  IAccessReviewDetailsState,
} from './AccessReviewDetails.types';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');
const cardStyles = require('../../../css/card.scoped.scss');

@withResponsiveMode
export class AccessReviewDetails extends React.Component<
  IAccessReviewDetailsProps,
  IAccessReviewDetailsState
> {
  constructor(nextProps: IAccessReviewDetailsProps) {
    super(nextProps);
    this.state = { sliderValue: 0, sliderTitle: "", isReviewerHoverCardExpanded: false };
  }

  public componentDidMount(): void {
    let currentReview = this.props.currentReview;
    let reviewerIds: string[] = [];
    if (
      currentReview &&
      currentReview.policy &&
      currentReview.policy.decisionMakerCriteria
    ) {
      currentReview.policy.decisionMakerCriteria.forEach((user) => {
        reviewerIds.push(user.userId!);
      });

      if (reviewerIds && reviewerIds.length > 0) {
        this.props.getReviewerDisplayNames(reviewerIds);
      }
    }
  }

  public componentDidUpdate(prevProps: Readonly<IAccessReviewDetailsProps>): void {
    if (prevProps.hidden && !this.props.hidden) {
      this.setState({ isReviewerHoverCardExpanded: false });
    }
  }

  public render(): JSX.Element {
    const { hidden, onDismiss, t, currentReview } = this.props;
    let reviewName = '';
    !isNullOrUndefined(currentReview)
      ? (reviewName = currentReview.displayName)
      : null;

    // Convert resource data into IRoleScope
    const allRoleScopeData: IRoleScope[] = this._convertResourcesToRoleScope();

    return (
      <AppPanel
        isBlocking={false}
        isOpen={!hidden}
        onDismiss={onDismiss}
        type={PanelType.smallFixedFar}
        headerText={reviewName}
        closeButtonAriaLabel={t(LocaleKeys.cancel)}
        aria-hidden={false}
        hasCloseButton={true}
      >
        {!isNullOrUndefined(currentReview) ? (
          <div>
            <Label className={css(myAccessStyles.appPanelLabel)}>
              {t(LocaleKeys.dueBy)}
            </Label>
            <div className={css(myAccessStyles.marginBottomSmall)}>
              {FormatDateTime(currentReview.endDateTime)}
            </div>
            {currentReview.createdBy &&
              currentReview.createdBy.displayName !== '' ? (
              <div className={css(myAccessStyles.appPanelBorder)}>
                <Label className={css(myAccessStyles.appPanelLabel)}>
                  {t(LocaleKeys.requestedBy)}
                </Label>
                <div className={css(myAccessStyles.marginBottomSmall)}>
                  {currentReview.createdBy.displayName}
                </div>
              </div>
            ) : null}
            {currentReview.description ? (
              <div className={css(myAccessStyles.appPanelBorder)}>
                <Label className={css(myAccessStyles.appPanelLabel)}>
                  {t(LocaleKeys.description)}
                </Label>
                <div className={css(myAccessStyles.marginBottomSmall)}>
                  {currentReview.description}
                </div>
              </div>
            ) : null}
            {(this.props.reviewerDisplayNames! && this.props.reviewerDisplayNames!.length > 0)
              ? this._renderReviewerNames(this.props.reviewerDisplayNames!) : null}
            {this.props.decisionsCriteria &&
              this.props.decisionsCriteria.typeId === DecisionCriteriaTypeId.RBACScope &&
              this.props.resourceId && this.props.resourceId.length > 1 ? (
              <div className={css(myAccessStyles.appPanelBorder)}>
                <Label className={css(myAccessStyles.appPanelLabel)}>
                  {t(LocaleKeys.scope)}
                </Label>
                <div className={css(myAccessStyles.marginBottomSmall)}>
                  {this.props.resourceId}
                </div>
              </div>
            ) : null}
            <div
              className={css(
                myAccessStyles.marginTopSmall,
                myAccessStyles.appPanelBorder
              )}
            >
              <ConnectedResourceSummary
                accessPackageResourceRoleScopes={allRoleScopeData}
                t={t}
                isFlexRow={false}
                searchTerm={null}
                canOpenResources={
                  new Map(allRoleScopeData.map((rs) => [rs.id, false]))
                }
              />
            </div>
          </div>
        ) : null}
      </AppPanel>
    );
  }

  private _convertResourcesToRoleScope = (): IRoleScope[] => {
    const allRoleScopeData: IRoleScope[] = [];
    if (
      this.props.decisionsCriteria &&
      this.props.decisionsCriteria.accessPackageResources
    ) {
      this.props.decisionsCriteria.accessPackageResources.forEach(
        (resource: IAccessReviewResource) => {
          // Until we move to reading from originId (which is not currently stored in access reviews),
          // we need to test the resourceType property to verify the type of resource. There are many
          // 'Group' types, all of which end with the string "Group", and will all be mapped to the same
          // table. This workaround will not be necessary with originId, which is all-encompassing.
          let resourceType = resource.resourceType;
          if (resourceType.endsWith(ResourceType.Group)) {
            resourceType = ResourceType.Group;
          }

          // Convert ResourceType to OriginSystem enum
          switch (resourceType) {
            case ResourceType.Group:
              resourceType = OriginSystem.AadGroup;
              break;
            case ResourceType.App:
              resourceType = OriginSystem.AadApplication;
              break;
            case ResourceType.SharePoint:
              resourceType = OriginSystem.SharePointOnline;
              break;
            case ResourceType.Authorization:
              resourceType = OriginSystem.AadAuthorization;
              break;
            case ResourceType.DirectoryRoleBuiltIn:
            case ResourceType.DirectoryRoleCustom:
              resourceType = OriginSystem.DirectoryRole;
              break;
            default:
              break;
          }

          const environment: IEnvironment = {
            displayName: '',
            description: '',
            connectionInfoStr: '',
            originSystem: resourceType,
          };

          const accessPackageResource: IResource = {
            id: guid(),
            description: resource.resourceDescription,
            displayName: resource.resourceDisplayName,
            url: '',
            environment: environment,
          };

          const accessPackageResourceRole: IRole = {
            id: guid(),
            description: resource.resourceDescription,
            displayName: resource.roleDisplayName,
            originSystem: resourceType,
          };

          const accessPackageResourceScope: IScope = {
            id: guid(),
            description: resource.resourceDescription,
            displayName: resource.resourceDisplayName,
            originSystem: resourceType,
            accessPackageResource: accessPackageResource,
          };

          allRoleScopeData.push({
            id: guid(),
            accessPackageResourceRole: accessPackageResourceRole,
            accessPackageResourceScope: accessPackageResourceScope,
          } as IRoleScope);
        }
      );
    }
    return allRoleScopeData;
  }

  private _renderReviewerNames = (reviewers: string[]): JSX.Element => {
    // Show the first two reviewer names before displaying the link
    let preview = '';
    let reviewerCount = reviewers.length;
    if (reviewerCount > 0) {
      // undefined first user indicates a self review, get user's own name
      if (reviewers[0] === undefined) {
        const user = getUserFromAuth();
        reviewers[0] = user!.name!;
      }
      preview += reviewers[0];
    }
    if (reviewerCount > 1) {
      preview += ', ' + reviewers[1];
    }

    return (
      <div className={css(myAccessStyles.appPanelBorder)}>
        <Label className={css(myAccessStyles.appPanelLabel)}>
          {this.props.t(LocaleKeys.reviewers)}
        </Label>
        <div className={css(myAccessStyles.marginBottomSmall)}>
          {preview}
          {reviewerCount > 2 ? (
            <div>{this._getReviewerHoverCard(reviewers)}</div>
          ) : null}
        </div>
      </div>
    );
  }

  private _getReviewerHoverCard = (reviewers: string[]): JSX.Element => {
    const reviewersLinkText = asLocalizedText(
      {
        key: LocaleKeys.additionalReviewers,
        options: {
          number: reviewers.length - 2,
        },
      },
      this.props.t
    );

    return (
      <HoverCard
        instantOpenOnClick={true}
        type={HoverCardType.plain}
        plainCardProps={this._getHoverCardProps(reviewers, reviewersLinkText)}
        onCardHide={this._handleHoverCardDismiss}
        onCardExpand={this._handleHoverCardOpen}
        setInitialFocus={true}
        trapFocus={true}
      >
        <Link aria-expanded={this.state.isReviewerHoverCardExpanded} >
          <span className={css(FontClassNames.mediumPlus)}>
            {reviewersLinkText}
          </span>
        </Link>
      </HoverCard>
    );
  }

  private _handleHoverCardDismiss = () => {
    this.setState({ isReviewerHoverCardExpanded: false });
  }

  private _handleHoverCardOpen = () => {
    this.setState({ isReviewerHoverCardExpanded: true });
  }

  private _getHoverCardProps = (reviewers: string[], reviewersLinkText: string): IPlainCardProps => {
    const fullList: JSX.Element[] = [];
    const idCard = "reviewersHoverCard";
    let fullLabelString: string = reviewersLinkText + ' ' + this.props.t(LocaleKeys.button) + ' ' + this.props.t(LocaleKeys.expanded);
    reviewers.map((name: string) => {
      fullList.push(<div>{name}</div>);
    });

    return {
      onRenderPlainCard: () => (
        <div aria-describedby={idCard} tabIndex={0}>
          <div className={css(myAccessStyles.wordBreak, cardStyles.smallCard)} tabIndex={0}>
            <div className={css(cardStyles.card)} tabIndex={0} id={idCard} >
              <div style={{ display: 'none' }} aria-hidden="false">{fullLabelString}</div>
              <div className={css(FontClassNames.large)} tabIndex={0}>
                {this.props.t(LocaleKeys.reviewers)}
              </div>
              <div className={css(myAccessStyles.marginTopSmallPlus, 'ms-pii')} tabIndex={0}>
                {fullList}
              </div>
            </div>
          </div>
        </div>
      ),
    };
  }
}
