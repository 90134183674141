import { TranslationFunction } from 'i18next';
import {
  ColorClassNames,
  css,
  FontClassNames,
  Label
} from '@fluentui/react';
import * as React from 'react';
import { highlightSearch } from '../../../shared/highlightSearch';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');

export interface ILabelValuePairProps {
  t: TranslationFunction;
  label: string;
  value: string;
  searchTerm?: string | null;
}

export class LabelValuePair extends React.Component<ILabelValuePairProps, {}> {
  public render(): JSX.Element {
    const { t, label, value } = this.props;
    return (
      <div className={css(myAccessStyles.marginBottomXSmallPlus)}>
        <Label
          className={css(
            ColorClassNames.neutralSecondary,
            myAccessStyles.semiLightText,
            FontClassNames.medium
          )}
        >
          {t(label, { context: 'capitalize' })}
        </Label>
        <span
          className={css(
            'ms-pii',
            FontClassNames.medium,
            ColorClassNames.neutralPrimary
          )}
        >
          {highlightSearch(this.props.searchTerm!, value)}
        </span>
      </div>
    );
  }
}
