import { Observable } from 'rxjs/Observable';
import { filter, mergeMap } from 'rxjs/operators';
import { IEntitlementAction, IEntitlementState, ITelemetryAction } from '../../../models';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { FeatureMap, isInternalUser } from '../../../shared';
import { registry } from '../myAccessRegistry';
import { createTelemetryAction, ofType, otherTypes } from './';
import { telemetry } from '../../../shared/telemetry';

export const getPath = (state: any) => {
  // The location stored in redux state is not initialized for first few actions, so there could be a race condition
  // where the location is not set if the telemetry event fires before that. Use the location hash as a fallback.
  return (
    (state && state.router && state.router.location && state.router.location.pathname) ||
    (location && location.hash && location.hash.substring(1)) ||
    'PATH_NOT_SET'
  );
};

export const getFeatures = (state: IEntitlementState) => {
  return state && state.features && state.features.isLoaded && state.features.isEnabled;
};

export const generateDescription = (path: string, features: false | FeatureMap, metadata?: {}): string => {
  return JSON.stringify({
    ...metadata,
    path,
    features,
    isInternalUser: isInternalUser()
  });
};

/**
 * Epic to record telemetry events.
 */
export const telemetryRecorderEpic = registry.addEpic('telemetryRecorder', (action$, store) =>
  action$.pipe(
    ofType(EntitlementActions.emitTelemetry),
    mergeMap((action: ITelemetryAction) => {
      const { eventName, metadata } = action.payload!;
      // tslint:disable-next-line: no-any
      const state = store.getState() as any;
      const path: string = getPath(state);
      const appState: IEntitlementState = state && state.app;
      const features = getFeatures(appState);
      const description = generateDescription(path, features, metadata);

      telemetry.reportCustomEvent(eventName, { message: description });
      return Observable.empty();
    })
  )
);

/**
 * Epic for routing events.
 */
export const routingTelemetryEpic = registry.addEpic('routingTelemetry', (action$, _store) =>
  action$.pipe(
    ofType('@@router/LOCATION_CHANGE'),
    filter((action: any) => action.payload && action.payload.pathname),
    mergeMap((action: any) => Observable.of(createTelemetryAction(`navigate${action.payload.pathname}`)))
  )
);

/**
 * Fallback epic to emit a telemetry action for all other redux events.
 */
export const fallbackTelemetryActionEmitterEpic = registry.addEpic(
  'fallbackTelemetryActionEmitter',
  (action$, _store) =>
    action$.pipe(
      otherTypes(),
      // tslint:disable-next-line: no-any
      mergeMap((action: IEntitlementAction<any>) => {
        // Entity events: get/refresh/filter/sort/etc
        if (action.payload && action.payload.entityType) {
          return Observable.of(createTelemetryAction(`${action.type}/${action.payload.entityType}`));
        }

        // Handle boolean payloads for binary actions
        if (typeof action.payload === 'boolean') {
          return Observable.of(createTelemetryAction(`${action.type}/${action.payload}`));
        }

        return Observable.of(createTelemetryAction(action.type));
      })
    )
);
