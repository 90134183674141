import { LoadingState } from '@microsoft/portal-app/lib/models/ILoading';
import { notificationsMerge } from '@microsoft/portal-app/lib/Notifications/helpers/notificationsMerge';
import {
  INotification,
  NotificationSeverity,
  NotificationType
} from '@microsoft/portal-app/lib/Notifications/models/INotification';
import { IODataValueResponse } from '@microsoft/portal-app/lib/odata-utils';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { TranslationOptions } from 'i18next';
import * as moment from 'moment';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import { Observable } from 'rxjs/Observable';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';
import { v4 } from 'uuid';
import { IMember } from '../../../models/ELM/PDS/IMember';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { EntityType } from '../../../models/EntityType';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import {
  IEntitlementState,
  IRootEntitlementsState
} from '../../../models/IEntitlementState';
import { IEntityFilterable } from '../../../models/IEntityFilterable';
import { getRequestWithAudience } from '../../../shared/AttachAudience';
import { getMemberUrl } from '../../../shared/getPdsApiUrl';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { registry } from '../myAccessRegistry';

export const getMemberEpic: Epic<
  IEntitlementAction<AnyPayload>,
  IRootEntitlementsState
> = (
  action$: ActionsObservable<IEntitlementAction>,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
    return action$
      .ofType(EntitlementActions.getMember)
      .mergeMap((_action: IEntitlementAction<IEntityFilterable>) => {
        const ajaxRequest = getRequestWithAudience(
          getMemberUrl(),
          EntityType.pdsMember
        );
        return (
          ajax(ajaxRequest)
            .map((payload: IODataValueResponse<IMember>) => {
              return {
                type: EntitlementActions.getMemberSucceeded,
                payload: payload && payload.response
              };
            })
            // tslint:disable-next-line:no-any
            .catch((error: any) =>
              Observable.of({
                type: EntitlementActions.getMemberFailed,
                payload: {
                  errorCode: error && error.status
                }
              })
            )
        );
      });
  };
registry.addEpic('getMemberEpic', getMemberEpic);

const getMember = (
  state: IEntitlementState,
  _action: IEntitlementAction<string>
): Readonly<IEntitlementState> => {
  return {
    ...state,
    member: {
      ...state.member!,
      isLoading: true
    }
  };
};
registry.add(EntitlementActions.getMember, getMember);

export const getMemberSucceeded = (
  state: IEntitlementState,
  action: IEntitlementAction<IMember>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  const entity = action.payload!;
  if (!entity) {
    return state;
  }

  return {
    ...state,
    member: {
      ...state.member!,
      value: entity,
      isLoading: false
    }
  };
};
registry.add(EntitlementActions.getMemberSucceeded, getMemberSucceeded);

export const getMemberFailed = (
  state: IEntitlementState,
  // tslint:disable-next-line:no-any
  action: IEntitlementAction<Readonly<any>>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  const errorCode = action.payload.errorCode;

  let toastKey = LocaleKeys.generalErrorMessage;
  let toastOptions: TranslationOptions = {};

  let notifications: INotification[] = [
    {
      id: v4(),
      localizableMessage: {
        key: toastKey,
        options: toastOptions
      },
      createdDateTime: moment(),
      severity: NotificationSeverity.error,
      type: NotificationType.card
    }
  ];

  return {
    ...state,
    notifications: notificationsMerge(
      notifications,
      state.notifications,
      state.notificationsLimit
    ),
    member: {
      ...state.member!,
      isLoading: false,
      loadingState: LoadingState.error
    },
    isTenantWhitelisted: errorCode !== 403
  };
};
registry.add(EntitlementActions.getMemberFailed, getMemberFailed);
