// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../dev/node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../dev/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".B7sRMiPB88qW92hO5jF_H{margin-top:-8px;margin-bottom:-8px}.B7sRMiPB88qW92hO5jF_H:hover{background-color:#e1dfdd}\n", "",{"version":3,"sources":["webpack://./src/components/ELM/EntitlementList/EntitlementList.scoped.scss"],"names":[],"mappings":"AAAA,uBACE,eAAgB,CAChB,kBAAmB,CACpB,6BAGC,wBAAyB","sourcesContent":[".submitButton {\n  margin-top: -8px;\n  margin-bottom: -8px;\n}\n\n.submitButton:hover {\n  background-color: #e1dfdd;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"submitButton": "B7sRMiPB88qW92hO5jF_H"
};
module.exports = ___CSS_LOADER_EXPORT___;
