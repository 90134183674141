import { IFilter } from './IFilter';
import { IListColumn } from './IListColumn';
import { IPageList } from './IPageList';

export interface IPageData<T> {
  readonly filtered: IPageList;
  readonly unfiltered: IPageList;
  readonly entitiesById: ReadonlyMap<string, T>;
  readonly sortedColumn?: IListColumn<T>;
  readonly filterContext?: IFilter;
  readonly isLoading: boolean;
  readonly loadMode?: LoadMode;
  readonly searchTerm?: string;
}

export enum LoadMode {
  Initial = 0,
  Refresh = 1,
  LoadMore = 2,
}
