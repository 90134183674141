import { EntitlementActions } from '../../../models/EntitlementActions';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState } from '../../../models/IEntitlementState';
import { registry } from '../myAccessRegistry';

export const setErrorMessageBar = (state: IEntitlementState, action: IEntitlementAction<string>): IEntitlementState => {
  const nextState = {
    ...state,
    ramErrorMessageBar: action.payload
  };

  return nextState;
};
registry.add(EntitlementActions.setErrorMessageBar, setErrorMessageBar);
