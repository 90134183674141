import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { EntitlementActions } from '../../../models';
import { IRootEntitlementsState } from '../../../models/IEntitlementState';
import { GrantRequests } from './GrantRequests';
import {
  IConnectedGrantRequestsProps,
  IGrantRequestsActions,
  IGrantRequestsMappedProps
} from './GrantRequests.types';

/**
 * Maps the external (i.e. own) props and the state to the properties of the GrantRequests control.
 * @param state The current redux state to map to props
 * @param ownProps The external properties to map to props
 * @returns The mapped properties that along with the actions
 * becomes the props (IGrantRequestsProps) for the GrantRequests control
 */
const mapStateToProps = (
  state: IRootEntitlementsState,
  _ownProps: IConnectedGrantRequestsProps
): IGrantRequestsMappedProps => {
  const { pendingApprovalGrantRequests, completedGrantRequests, searchTerm } = state.app;

  return {
    pendingApprovalGrantRequests,
    completedGrantRequests,
    searchTerm,
    features: state.app.features.isEnabled
  };
};

/**
 * Maps the dispatch actions to the props for the GrantRequests control.
 * @param dispatch Dispatches actions to the List
 * @returns The mapped actions that along with the mapped properties
 * becomes the props (IGrantRequestsProps) for the GrantRequests control
 */
const mapDispatchToProps = (
  dispatch: Dispatch<IRootEntitlementsState>
): IGrantRequestsActions => {
  return {
    setSearchContext: createAction<string | null>(
      EntitlementActions.setSearchContext,
      dispatch
    ),
    showFiltersIcon: createAction<boolean>(
      EntitlementActions.showSearchFiltersIcon,
      dispatch
    )
  };
};

/**
 * A redux connected GrantRequests control.
 * @description Supports translation
 */
export const ConnectedGrantRequests = connect(
  mapStateToProps,
  mapDispatchToProps
  // tslint:disable-next-line:no-any
)(translate('MyAccess')(GrantRequests) as any);
