import { ISettings } from './ISettings';
import * as arSettings from './settings.ar.json';
import * as baseSettings from './settings.base.json';
import * as mcSettings from './settings.cn.json';
import * as exSettings from './settings.ex.json';
import * as ppeSettings from './settings.ppe.json';
import * as debugSettings from './settings.debug.json';
import * as prodSettings from './settings.prod.json';
import * as rxSettings from './settings.rx.json';
import * as SettingsManager from './SettingsManager';

const getEnvSettings = () => {
  let envSettings: ISettings = prodSettings;
  const hostname = window.location.hostname.toLowerCase();
  const urlParams = new URLSearchParams(location.search);
  let useLocalApi = false;
  if (urlParams.has('useLocalApi')) {
    if (urlParams.get('useLocalApi') === 'true') {
      useLocalApi = true;
    }
  }
  if (/\.ppe\./.test(hostname)) {
    envSettings = ppeSettings;
  } else if (/\.canary\./.test(hostname) && useLocalApi) {
    envSettings = debugSettings;
  } else if (/\.com$/.test(hostname)) {
    envSettings = prodSettings;
  } else if (/\.us$/.test(hostname)) {
    envSettings = arSettings;
  } else if (/\.cn$/.test(hostname)) {
    envSettings = mcSettings;
  } else if (/\.gov$/.test(hostname)) {
    envSettings = exSettings;
  } else if (/\.scloud$/.test(hostname)) {
    envSettings = rxSettings;
  }
  return envSettings;
};

export const settingsManager = SettingsManager.create<ISettings>()
  .addSettings(baseSettings)
  .addSettings(getEnvSettings());
