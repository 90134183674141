import { logIntoTenant } from '@microsoft/portal-app/lib/auth/auth';
import { IODataValueResponse } from '@microsoft/portal-app/lib/odata-utils';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { errorHandler } from '@microsoft/portal-app/lib/redux/observableErrorHandler';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';
import { ITenant } from '../../../models/ELM/ITenant';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import {
  IEntitlementState,
  IRootEntitlementsState,
} from '../../../models/IEntitlementState';
import { getRequestWithAudience } from '../../../shared/AttachAudience';
import { getAuth, getUserFromAuth } from '../../../shared/authHelper';
import { getELMBaseUrlBasedOnEnv } from '../../../shared/getApiUrl';
import { getDomainNameFromPathName } from '../../../shared/getDomainNameFromPathName';
import { registry } from '../myAccessRegistry';

export const findTenantByDomainNameEpic: Epic<
  IEntitlementAction<AnyPayload>,
  IRootEntitlementsState
> = (
  action$: ActionsObservable<IEntitlementAction<string>>,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
  return action$
    .ofType(EntitlementActions.findTenantByDomainName)
    .switchMap((action: IEntitlementAction<string>) => {
      const domainName = action.payload!;
      const url =
        getELMBaseUrlBasedOnEnv() +
        `tenants/FindByDomainName(domainName='${domainName}')`;
      const ajaxRequest = getRequestWithAudience(url);
      return ajax(ajaxRequest)
        .map((payload: IODataValueResponse<ITenant>) => {
          return {
            type: EntitlementActions.findTenantByDomainNameSucceeded,
            payload: payload.response,
          } as IEntitlementAction<ITenant>;
        })
        .catch(
          errorHandler<IEntitlementAction>(
            EntitlementActions.findTenantByDomainNameFailed
          )
        );
    });
};
registry.addEpic('findTenantByDomainNameEpic', findTenantByDomainNameEpic);

export const findTenantByDomainName = (
  state: IEntitlementState,
  _action: IEntitlementAction
): Readonly<IEntitlementState> => {
  return {
    ...state,
    tenantContext: {
      ...state.tenantContext!,
      isChecking: true,
    },
  };
};
registry.add(EntitlementActions.findTenantByDomainName, findTenantByDomainName);

export const findTenantByDomainNameSucceeded = (
  state: IEntitlementState,
  action: IEntitlementAction<ITenant>
): Readonly<IEntitlementState> => {
  const tenant = action.payload!;
  const auth = getAuth();
  const user = getUserFromAuth();
  const existingTid = user && user.tenantId;

  if (auth && existingTid !== tenant.id) {
    auth.login({ tenantId: tenant.id });
    return state;
  }

  return {
    ...state,
    tenantContext: {
      ...state.tenantContext!,
      tenantId: tenant.id,
      domainName: tenant.initialDomainName!,
      tenant: tenant,
      isChecking: false,
    },
  };
};
registry.add(
  EntitlementActions.findTenantByDomainNameSucceeded,
  findTenantByDomainNameSucceeded
);

export const findTenantByDomainNameFailed = (
  state: IEntitlementState,
  // tslint:disable-next-line
  action: IEntitlementAction<Readonly<any>>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return {
      ...state,
      tenantContext: {
        ...state.tenantContext!,
        isChecking: false,
        isValid: false,
      },
    };
  }

  const response = action.payload;
  if (
    response.error &&
    (response.error.includes('Token') || response.error.includes('popup'))
  ) {
    return {
      ...state,
      tenantContext: {
        ...state.tenantContext!,
        errorMessage: 'Acquire token failed.',
      },
    };
  }

  const domainName = getDomainNameFromPathName();
  history.replaceState('', '', '/#/');
  logIntoTenant(domainName);

  let tenantContext = state.tenantContext!;

  switch (response.status) {
    case 403:
      tenantContext = {
        ...tenantContext,
        isPartnered: false,
        errorMessage: `You are not authorized to access resources in ${domainName}`,
      };
      break;
    case 404:
      tenantContext = {
        ...tenantContext,
        isValid: false,
        errorMessage: `${domainName} is not a valid domain name`,
      };
      break;
    default:
      tenantContext = {
        ...tenantContext,
        errorMessage: `An error has occurred accessing ${domainName}.`,
      };
  }

  return {
    ...state,
    tenantContext: {
      ...tenantContext,
      isChecking: false,
    },
  };
};
registry.add(
  EntitlementActions.findTenantByDomainNameFailed,
  findTenantByDomainNameFailed
);
