import {
  ColorClassNames,
  DetailsRow,
  FontClassNames,
  IColumn,
} from '@fluentui/react';
import {
  ResponsiveMode,
  withResponsiveMode,
} from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import * as React from 'react';

import { css } from '@fluentui/react';

import { EntitlementSearchFilter } from '../../../models/ELM/EntitlementSearchFilter';
import { ChevronButton } from '../../Shared/ChevronButton/ChevronButton';
import { RoleScopesCard } from '../../Shared/RoleScopesCard/RoleScopesCard';
import {
  IEntitlementCardProps,
  IEntitlementCardState,
} from './EntitlementCard.types';

const EntitlementCardStyles = require('./EntitlementCard.scoped.scss');
const myAccessStyles = require('../../../css/myAccess.scoped.scss');

@withResponsiveMode
export class EntitlementCard extends React.Component<
  IEntitlementCardProps,
  IEntitlementCardState
> {
  constructor(props: IEntitlementCardProps) {
    super(props);

    this.state = {
      isExpanded:
        this.props.isExpandedByDefault ||
        this.props.isExpandedBasedOnSearchFilter,
    };
  }

  public componentWillReceiveProps(nextProps: IEntitlementCardProps): void {
    // Update the state only if it is searching changes
    if (
      this.props.isExpandedBasedOnSearchFilter !==
      nextProps.isExpandedBasedOnSearchFilter
    ) {
      this.setState({
        isExpanded: nextProps.isExpandedBasedOnSearchFilter,
      });
    }
  }

  public render(): React.ReactNode {
    const { t } = this.props;
    let props = this.props.detailsRowProps;

    // pop the fake placeholder column
    let lastColumn: IColumn | undefined = props.columns.pop();
    if (!lastColumn) {
      return <DetailsRow {...props} />;
    }
    lastColumn.onRender = () => {
      return <ChevronButton t={t} onClick={this.toggleIsExpanded} />;
    };
    props.columns.push(lastColumn);

    return (
      <div
        className={
          this.state.isExpanded ? css(EntitlementCardStyles.border) : ''
        }
      >
        <DetailsRow {...props} />
        {this.state.isExpanded ? this.getExpandedCard() : null}
      </div>
    );
  }

  private getLargeExpandedCard = (): React.ReactNode => {
    const {
      entitlement,
      accessPackageResourceRoleScopes,
      canShowResources,
      canOpenResources,
      t,
      errorMessage,
      isDelivering,
    } = this.props;

    return (
      <div
        className={css(
          EntitlementCardStyles.contentRow,
          EntitlementCardStyles.paddingTopBot,
          EntitlementCardStyles.paddingLeftRight,
          ColorClassNames.whiteBackground
        )}
      >
        <div
          className={css(
            EntitlementCardStyles.infoCol,
            EntitlementCardStyles.infoPadding,
            EntitlementCardStyles.verticalDivider
          )}
        >
          <div
            className={css(
              'ms-pii',
              EntitlementCardStyles.marginBottomMediumPlus,
              FontClassNames.medium,
              ColorClassNames.neutralPrimary
            )}
          >
            {entitlement.description}
          </div>
        </div>
        <RoleScopesCard
          t={t}
          accessPackageResourceRoleScopes={accessPackageResourceRoleScopes!}
          canShowResources={canShowResources}
          canOpenResources={canOpenResources}
          isFlexRow={true}
          errorMessage={errorMessage}
          searchTerm={
            this.props.selectedFilterKey ===
              EntitlementSearchFilter.Resources ||
            this.props.selectedFilterKey === EntitlementSearchFilter.All
              ? this.props.searchTerm
              : ''
          }
          isDelivering={isDelivering}
        />
      </div>
    );
  };

  private getSmallExpandedCard = (): React.ReactNode => {
    const {
      entitlement,
      accessPackageResourceRoleScopes,
      canShowResources,
      canOpenResources,
      errorMessage,
      t,
      isDelivering,
    } = this.props;

    return (
      <div
        className={css(
          EntitlementCardStyles.contentRow,
          EntitlementCardStyles.paddingSmallPlus,
          ColorClassNames.whiteBackground
        )}
      >
        <div className={css(myAccessStyles.marginBottomSmall)}>
          <span className={css(FontClassNames.small, 'ms-pii')}>
            {entitlement && entitlement.description}
          </span>
        </div>
        <RoleScopesCard
          accessPackageResourceRoleScopes={accessPackageResourceRoleScopes}
          t={t}
          canShowResources={canShowResources}
          canOpenResources={canOpenResources}
          errorMessage={errorMessage}
          isFlexRow={false}
          searchTerm={
            this.props.selectedFilterKey ===
              EntitlementSearchFilter.Resources ||
            this.props.selectedFilterKey === EntitlementSearchFilter.All
              ? this.props.searchTerm
              : ''
          }
          isDelivering={isDelivering}
        />
      </div>
    );
  };

  private getExpandedCard = (): React.ReactNode => {
    return this.props.responsiveMode! > ResponsiveMode.small
      ? this.getLargeExpandedCard()
      : this.getSmallExpandedCard();
  };

  private toggleIsExpanded = (): void => {
    const isExpanded = !this.state.isExpanded;
    this.props.recordBusinessEvent(`entitlement-card/expand/${isExpanded}`);
    this.setState({
      isExpanded,
    });
  };
}
