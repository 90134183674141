import { ILocalizableMessage } from '@microsoft/portal-app/lib/localization/ILocalizableMessage';
import { isInternalUser } from '../../../shared';

import {
  EntitlementActions,
  IEntitlementAction,
  IEntitlementState,
} from '../../../models';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { registry } from '../myAccessRegistry';

export const setSearchContext = (
  state: IEntitlementState,
  action: IEntitlementAction<EntitlementActions>
): IEntitlementState => {
  if (action.payload === undefined) {
    return state;
  }

  const searchPlaceholderText = getSearchPlaceholderText(action.payload);

  return {
    ...state,
    searchContext: action.payload,
    searchPlaceholderText,
    searchTerm: getSearchTermForContext(action.payload, state),
  };
};
registry.add(EntitlementActions.setSearchContext, setSearchContext);

const getSearchPlaceholderText = (
  searchContext: EntitlementActions
): ILocalizableMessage => {
  const isGuestUser = !isInternalUser();
  switch (searchContext) {
    case EntitlementActions.searchEntitlementsOnServer:
      return isGuestUser
      ? {key: LocaleKeys.searchPackagesByNameOrDescription}
      : {key: LocaleKeys.searchPackagesByAll};
    case EntitlementActions.searchValidGrantsOnServer:
      return {key: LocaleKeys.searchPackagesByAll};
    case EntitlementActions.searchExpiredGrantsOnServer:
      return { key: LocaleKeys.searchPackagesByName };
    case EntitlementActions.searchGrantRequestsOnServer:
      return { key: LocaleKeys.searchRequests };
    case EntitlementActions.searchPendingApprovalGrantRequestsOnServer:
    case EntitlementActions.searchCompletedGrantRequestsOnServer:
    case EntitlementActions.searchPendingRaeRequestsOnServer:
    case EntitlementActions.searchCompletedRaeRequestsOnServer:
      return { key: LocaleKeys.searchApprovals };
    case EntitlementActions.searchAccessReviewsOnServer:
      return { key: LocaleKeys.searchReviews };
    case EntitlementActions.searchReviewDecisionsOnServer:
      return { key: LocaleKeys.searchUsers };
    case EntitlementActions.searchCatalogsOnServer:
      return { key: LocaleKeys.searchCatalogs };
    default:
      return { key: LocaleKeys.searchPackages };
  }
};

/**
 * Restores the previous search term for a context or get default (empty string).
 * This is used for transitioning between nested objects (e.g. catalogs > access packages).
 * We want to mantain the search context when navigating back to parent.
 * @param searchContext the search context.
 * @param state the redux app state.
 */
const getSearchTermForContext = (
  searchContext: EntitlementActions,
  state: IEntitlementState
): string => {
  switch (searchContext) {
    case EntitlementActions.searchCatalogsOnServer:
      return state.catalogs.searchTerm || '';
    default:
      return '';
  }
};
