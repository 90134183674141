import { filter } from 'rxjs/operators';

import { IEntitlementAction } from '../../../models';

const filteredTypes = new Set<string>();

/**
 * A rxjs pipe operator that filters an action by its type and registers the type
 * in memory so the type can be excluded later using `otherTypes`.
 *
 * @param types the varargs of type strings.
 */
export const ofType = (...types: string[]) => {
  types.forEach((t) => filteredTypes.add(t));
  // tslint:disable-next-line: no-any
  return filter((action: IEntitlementAction<any>) =>
    types.includes(action.type)
  );
};

/**
 * Redux observable operator that passes through all types that
 * have not been used in the `ofType` operator.
 */
export const otherTypes = () =>
  filter((a: IEntitlementAction) => !filteredTypes.has(a.type));
