import { AppPanel } from '@uifabric/portal-ux/lib/AppPanel';

import {
  css,
  FontClassNames,
  PanelType,
  Link,
  Icon,
} from '@fluentui/react';
import * as React from 'react';
import { settingsManager } from '../../../shared/settings';
import { asLocalizedText } from '../../../shared/asLocalizedText';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { IGrantRequestApproverInfoProps, IGrantRequestApproverInfoState } from './GrantRequestApproverInfo.types';

import { IApproverInfo } from '../../../models/ELM/IApproverInfo';
import { RequestStatus } from '../../../models/ELM/RequestStatus';
import { TruncatedTextWithCopy } from '../../../components/Shared/TruncatedText/TruncatedText';
const myAccessStyles = require('../../../css/myAccess.scoped.scss');

export class GrantRequestApproverInfo extends React.Component<
  IGrantRequestApproverInfoProps,
  IGrantRequestApproverInfoState
> {
  constructor(nextProps: IGrantRequestApproverInfoProps) {
    super(nextProps);
  }

  public render(): JSX.Element {
    const { t, mappedGrantRequest } = this.props;

    let pendingApprovalActivity = mappedGrantRequest.history[this.props.index];
    let requestStatus = RequestStatus[pendingApprovalActivity.action.toString()];
    let headerLocaleKey = `requestActivity.${requestStatus}`;

    return (
      <AppPanel
        isBlocking={true}
        isOpen={true}
        onDismiss={this.props.onDismiss}
        type={PanelType.smallFixedFar}
        headerText={t(headerLocaleKey, { context: 'capitalize' })}
        isLightDismiss={true}
        closeButtonAriaLabel={t(LocaleKeys.cancel)}
        hasCloseButton={true}
      >
        {this._onRenderBody()}
      </AppPanel>
    );
  }

  private _onRenderBody = (): JSX.Element => {
    const myGroupsUrl = settingsManager.get('myGroupsUrl');
    const { t, mappedGrantRequest } = this.props;


    if (!mappedGrantRequest) {
      return <div />;
    }
    let pendingApprovalActivity = mappedGrantRequest.history[this.props.index];
    let approvers: IApproverInfo[] = [];
    try {
      approvers = JSON.parse(pendingApprovalActivity.detail) as IApproverInfo[];
    } catch (e) {
      approvers = [];
    }
    const approverList: Array<JSX.Element> = [];
    approvers.forEach(
      (approver: IApproverInfo, index: number) => {
        let displayName = approver.Type === 'Group' ?
          asLocalizedText(
            {
              key: LocaleKeys.approverGroup,
              options: {
                DisplayName: approver.DisplayName
              }
            },
            this.props.t
          ) : approver.DisplayName;
        let groupLink = '';
        if (approver.Type === 'Group') {
          groupLink = myGroupsUrl + "/" + approver.ObjectId
        }
        const groupLinkElement = (<Link key="group-link" href={groupLink} aria-labelledby="openInNewTab" target="_blank">
          {displayName}
          <Icon
            id="openInNewTab"
            iconName="OpenInNewTab"
            aria-label="External link, opens in new tab"
            className={myAccessStyles.marginLeftXSmall}
          />
        </Link>);
        const userElement = (<div className={css(FontClassNames.medium)}><span>{displayName}</span> <span>({approver.Email})</span>
        </div>)
        const approverItem = (
          <li>
            {approver.Type === 'Group' ?
              groupLinkElement : <TruncatedTextWithCopy children={userElement} copyValue={approver.Email} toolTipContent={userElement} />
            }
          </li >);
        approverList.push(approverItem);
      })
    return (
      <div tabIndex={0} >
        <div className={css(FontClassNames.mediumPlus)}>{t(LocaleKeys.approvers)}</div>
        <ul className={css(myAccessStyles.paddingLeftMedium)}>{approverList}</ul>
      </div>
    );
  }
}
