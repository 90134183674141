import { DefaultButton, getTheme, IContextualMenuItem } from '@fluentui/react';
import * as React from 'react';
import { TranslationFunction } from 'react-i18next';

import { IVirtualMachine } from '../../../models/RAM/IVirtualMachine';
import { OsTypeEnum } from '../../../models/RAM/OsTypeEnum';
import { ResourceTypeEnum } from '../../../models/RAM/ResourceTypeEnum';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { onDownloadRdpFile, SshConnectionOption } from '../Connection/Connection.utils';

const copySshCommandToClipboard = (
  text: string,
  connectionType: SshConnectionOption,
  reportCustomEventWithMetadata: (eventName: string, payload?: {} | undefined) => void
): void => {
  const logMessage = sshQuickConnectionLogMessage(connectionType);
  navigator.clipboard
    .writeText(text)
    .then(() => reportCustomEventWithMetadata(logMessage + '/success'))
    .catch(() => reportCustomEventWithMetadata(logMessage + '/fail')); // TODO: add error messagebar
};
// menu item icon props
const menuItemIconStyles = { root: { color: getTheme().palette.blue } };
const blueCopyIcon = { iconName: 'Copy', styles: menuItemIconStyles };
const blueDownloadIcon = { iconName: 'Download', styles: menuItemIconStyles };

// connection commands
const azureLinuxEntraIdConnectCommand = (vmName: string, resourceGroupName: string): string => {
  return `az ssh vm -n "${vmName}" -g "${resourceGroupName}"`;
};
const azureLinuxSshClientConnectCommand = (t: TranslationFunction, username: string, ipAddress?: string): string => {
  return `ssh -i ${t(LocaleKeys.ramConnectionSshPrivateKeyPath) as string} ${username}@${
    ipAddress ?? `${t(LocaleKeys.ramConnectionIpAddress) as string}`
  }`;
};
const azureWindowsAzureCliConnectCommand = (resourceGroup: string, vmName: string, username: string): string => {
  return `az ssh vm  -g "${resourceGroup}" -n "${vmName}" --local-user "${username}"`;
};
const azureArcConnectCommand = (
  t: TranslationFunction,
  requireLocalUser: boolean,
  subscription: string,
  resourceGroup: string,
  machineName: string
): string => {
  const command = `az ssh arc --subscription "${subscription}" --resource-group "${resourceGroup}" --name "${machineName}"`;
  return requireLocalUser
    ? command + ` --local-user "${t(LocaleKeys.ramConnectionArcSshUsername) as string}"`
    : command;
};

// connection menu items
const azureLinuxSshMenuItems = (
  reportCustomEventWithMetadata: (eventName: string, payload?: {} | undefined) => void,
  t: TranslationFunction,
  vmName: string,
  resourceGroupName: string,
  username: string,
  ipAddress?: string
): IContextualMenuItem[] => [
  {
    key: 'entraId',
    text: t(LocaleKeys.ramConnectionCopyCommandEntraId),
    iconProps: blueCopyIcon,
    onClick: () =>
      copySshCommandToClipboard(
        azureLinuxEntraIdConnectCommand(vmName, resourceGroupName),
        SshConnectionOption.EntraId,
        reportCustomEventWithMetadata
      )
  },
  {
    key: 'sshClient',
    text: t(LocaleKeys.ramConnectionCopyCommandSshClient),
    iconProps: blueCopyIcon,
    onClick: () =>
      copySshCommandToClipboard(
        azureLinuxSshClientConnectCommand(t, username, ipAddress),
        SshConnectionOption.Client,
        reportCustomEventWithMetadata
      )
  }
];

const azureWindowsRdpMenuItems = (
  t: TranslationFunction,
  vmName: string,
  reportCustomEventWithMetadata: (eventName: string, payload?: {} | undefined) => void,
  ipAddress?: string
): IContextualMenuItem[] => [
  {
    key: 'downloadRdpFile',
    text: t(LocaleKeys.ramConnectionRdpDownloadRdpFile),
    iconProps: blueDownloadIcon,
    onClick: () => {
      if (!ipAddress) {
        reportCustomEventWithMetadata('ram/quickConnection/rdp/fail/missingIp');
        return;
      }
      try {
        onDownloadRdpFile(vmName, ipAddress);
        reportCustomEventWithMetadata('ram/quickConnection/rdp');
      } catch (e) {
        reportCustomEventWithMetadata('ram/quickConnection/rdp/fail');
      }
    }
  }
];

const azureWindowsSshMenuItems = (
  resourceGroup: string,
  vmName: string,
  username: string,
  reportCustomEventWithMetadata: (eventName: string, payload?: {} | undefined) => void,
  t: TranslationFunction
): IContextualMenuItem[] => [
  {
    key: 'azureCli',
    text: t(LocaleKeys.ramConnectionCopyCommandAzureCli),
    iconProps: blueCopyIcon,
    onClick: () =>
      copySshCommandToClipboard(
        azureWindowsAzureCliConnectCommand(resourceGroup, vmName, username),
        SshConnectionOption.AzureCli,
        reportCustomEventWithMetadata
      )
  }
];

const azureArcSshMenuItems = (
  distribution: OsTypeEnum | undefined,
  subscription: string,
  resourceGroup: string,
  machineName: string,
  reportCustomEventWithMetadata: (eventName: string, payload?: {} | undefined) => void,
  t: TranslationFunction
): IContextualMenuItem[] => {
  const entraIdConnectionItem = {
    key: 'entraId',
    text: t(LocaleKeys.ramConnectionCopyCommandEntraId),
    iconProps: blueCopyIcon,

    onClick: () =>
      copySshCommandToClipboard(
        azureArcConnectCommand(t, false, subscription, resourceGroup, machineName),
        SshConnectionOption.EntraId,
        reportCustomEventWithMetadata
      )
  };
  const passwordConnectionItem = {
    key: 'password',
    text: t(LocaleKeys.ramConnectionCopyCommandPassword),
    iconProps: blueCopyIcon,
    onClick: () =>
      copySshCommandToClipboard(
        azureArcConnectCommand(t, true, subscription, resourceGroup, machineName),
        SshConnectionOption.Password,
        reportCustomEventWithMetadata
      )
  };

  return distribution === OsTypeEnum.Linux ? [entraIdConnectionItem, passwordConnectionItem] : [passwordConnectionItem];
};

// creates the quick connect contextual menu buttons
const generateQuickConnectMenuButtons = (
  isDisabled: boolean,
  sshMenuItems?: IContextualMenuItem[],
  rdpMenuItems?: IContextualMenuItem[]
): JSX.Element => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      {sshMenuItems ? quickConnectButton('SSH', sshMenuItems, isDisabled) : <></>}
      {rdpMenuItems ? quickConnectButton('RDP', rdpMenuItems, isDisabled) : <></>}
    </div>
  );
};

const quickConnectButton = (title: string, menuItems: IContextualMenuItem[], isDisabled: boolean): JSX.Element => {
  return (
    <DefaultButton
      iconProps={{ iconName: 'Copy' }}
      text={title}
      style={{ marginRight: '8px' }}
      disabled={isDisabled}
      menuProps={{
        items: menuItems,
        styles: {
          root: {
            selectors: {
              '.ms-ContextualMenu-item': {
                margin: 0
              }
            }
          }
        }
      }}
    />
  );
};

export const generateQuickConnectColumnItems = (
  vm: IVirtualMachine,
  reportCustomEventWithMetadata: (eventName: string, payload?: {} | undefined) => void,
  t: TranslationFunction
): JSX.Element => {
  if (vm.resourceType === ResourceTypeEnum.VirtualMachine) {
    if (vm.osType === OsTypeEnum.Linux) {
      return generateQuickConnectMenuButtons(
        false,
        azureLinuxSshMenuItems(
          reportCustomEventWithMetadata,
          t,
          vm.displayName,
          vm.resourceGroupName,
          vm.username,
          vm.publicIpAddress
        )
      );
    } else {
      return generateQuickConnectMenuButtons(
        vm.osType === undefined,
        azureWindowsSshMenuItems(vm.resourceGroupName, vm.displayName, vm.username, reportCustomEventWithMetadata, t),
        azureWindowsRdpMenuItems(t, vm.displayName, reportCustomEventWithMetadata, vm.publicIpAddress)
      );
    }
  } else {
    return generateQuickConnectMenuButtons(
      false,
      azureArcSshMenuItems(
        vm.osType,
        vm.subscriptionId, // TODO: change to subscriptionName
        vm.resourceGroupName,
        vm.displayName,
        reportCustomEventWithMetadata,
        t
      )
    );
  }
};

const sshQuickConnectionLogMessage = (connectionOption: SshConnectionOption): string => {
  let loggingMessage = 'ram/quickConnection/ssh';
  switch (connectionOption) {
    case SshConnectionOption.EntraId:
      loggingMessage += '/entraId';
      break;
    case SshConnectionOption.Client:
      loggingMessage += '/client';
      break;
    case SshConnectionOption.AzureCli:
      loggingMessage += '/azureCli';
      break;
    case SshConnectionOption.Password:
      loggingMessage += '/password';
      break;
    default:
      break;
  }
  return loggingMessage;
};
