import { IOrganization, IPrivacyProfile } from '@iamexperiences/account-organizations';
import { auth } from '../../auth';
import { getUserFromAuth } from '../../shared';
import { useQuery } from 'react-query';
import {
  P2_SERVICE_PLAN_ID,
  EU_DATA_BOUNDARY_CLAIM_VALUE,
  ENTRA_IDENTITY_GOVERNANCE_SERVICE_PLAN_ID,
  P1_SERVICE_PLAN_ID,
} from '../../shared/constants';
import { settingsManager } from '../../shared/settings';

interface IQueryResponse {
  readonly currentOrganization: IOrganization;
  readonly showMyAccess: boolean;
  readonly inEUTenant: boolean;
  readonly isLoading: boolean;
  readonly error: unknown;
}

interface IResponsePayload {
  readonly value: IRawOrganization[];
}

interface IRawOrganization {
  readonly assignedPlans: IAssignedPlan[];
  readonly countryLetterCode: string;
  readonly displayName: string;
  readonly id: string;
  readonly privacyProfile?: IPrivacyProfile;
  readonly dataBoundary?: string;
}

interface IAssignedPlan {
  readonly assignedDateTime: string;
  readonly capabilityStatus: string;
  readonly service: string;
  readonly servicePlanId: string;
}

export const useCurrentOrganization = (): IQueryResponse => {
  const authUser = getUserFromAuth();
  const currentTenantId = authUser?.tenantId;
  const homeTenantId = authUser?.homeTenantId;
  const { data, isLoading, error } = useQuery('getCurrentOrganization', async () => {
    // TODO - pass scopes to acquireToken after MSGraph migration
    const token = await auth.acquireToken();
    const MSGraphEndPoint = settingsManager.get('msGraphResourceName');
    const response = await fetch(`${MSGraphEndPoint}/v1.0/organization`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const responseText = await response.text();
      throw new Error(`${response.status}: Failed to get current organization. ${responseText}`);
    }

    const res = (await response.json()) as IResponsePayload;
    // The response is an array with one element
    return res.value[0];
  });

  const currentOrganization: IOrganization = {
    orgName: data?.displayName || '',
    tenantId: data?.id || '',
    isCurrent: data?.id === currentTenantId,
    isHome: data?.id === homeTenantId,
    isCollabJoined: data?.id !== homeTenantId, // Since the user cannot sign into a NI-only org, current org must be collab-joined if it's not a home org
    isNIJoined: false,
    privacyProfile: data?.privacyProfile ?? null,
  };

  const hasP1ServicePlanId = data?.assignedPlans.some((ap) => ap.servicePlanId === P1_SERVICE_PLAN_ID && ap.capabilityStatus == 'Enabled') || false;
  const hasP2License = data?.assignedPlans.some((ap) => ap.servicePlanId === P2_SERVICE_PLAN_ID && ap.capabilityStatus == 'Enabled') || false;
  const hasGovernanceServicePlan = data?.assignedPlans.some((ap) => ap.servicePlanId === ENTRA_IDENTITY_GOVERNANCE_SERVICE_PLAN_ID && ap.capabilityStatus == 'Enabled') || false;
  const showMyAccess = hasP2License || (hasP1ServicePlanId && hasGovernanceServicePlan);

  const inEUTenant = data?.dataBoundary?.toLowerCase() === EU_DATA_BOUNDARY_CLAIM_VALUE;
  return { currentOrganization, showMyAccess, inEUTenant, isLoading, error };
};
