import { IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import { TranslationFunction } from 'react-i18next';
import { IGrantRequest } from '../../../models/ELM/IGrantRequest';
import {
  isRequestStateCancellable,
  RequestState
} from '../../../models/ELM/RequestState';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { IGrantRequestListProps } from './GrantRequestList.types';

export const getGrantRequestListCommands = (
  t: TranslationFunction,
  props: IGrantRequestListProps,
  selectedItems?: IGrantRequest[]
): IContextualMenuItem[] => {
  // const refreshCommand: IContextualMenuItem = {
  //   key: 'refresh',
  //   name: t(ClientResources.refresh),
  //   iconProps: { iconName: 'refresh' },
  //   onClick: props.refreshGrantRequests
  // };

  const cancelRequestCommand: IContextualMenuItem = {
    key: 'cancelRequest',
    role: 'button',
    ariaLabel: t(LocaleKeys.cancelMyRequest),
    name: t(LocaleKeys.cancelMyRequest),
    iconProps: { iconName: 'clear' },
    disabled: true,
    onClick: props.showConfirmDialog
  };
  const state =
    selectedItems && selectedItems.length > 0
    ? selectedItems[0].requestState
    : null;
  cancelRequestCommand.disabled = !isRequestStateCancellable(
    RequestState[state!]
  );

  let commands: IContextualMenuItem[] = [];
  commands.push(cancelRequestCommand);
  return commands;
};

export const getGrantRequestListFarCommands = (
  t: TranslationFunction,
  props: IGrantRequestListProps
): IContextualMenuItem[] => {
  const filter: IContextualMenuItem = {
    key: 'filter',
    name: t(LocaleKeys.filter, { context: 'capitalize' }),
    iconProps: { iconName: 'Filter' },
    role: 'button',
    onClick: () => props.showGrantRequestFilter()
  };
  return [filter];
};
