import { createAction } from "@microsoft/portal-app/lib/redux/createAction";
import { useDispatch } from "react-redux";
import { EntitlementActions } from "../models";

export const useSearchBox = () => {
  const dispatch = useDispatch();

  return {
    showSearchBox: createAction<boolean>(EntitlementActions.showSearchBox, dispatch)
  };
}