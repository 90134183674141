import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { useDispatch } from 'react-redux';
import { EntitlementActions, EntityType } from '../models';
import { useSelector } from '.';

export const useGetEntityCount = () => {
  const { pageCount } = useSelector((state) => ({
    pageCount: state.app.pageCounts
  }));

  const dispatch = useDispatch();
  const getEntitlementsCount = createAction(EntitlementActions.getEntitlementsCount, dispatch);
  const getActiveGrantsCount = createAction(EntitlementActions.getActiveGrantsCount, dispatch);
  const getExpiredGrantsCount = createAction(EntitlementActions.getExpiredGrantsCount, dispatch);

  return {pageCount, getEntitlementsCount, getActiveGrantsCount, getExpiredGrantsCount};
}