import { useSelector, useTranslation } from '../index';
import { EntitlementActions } from '../../models';
import { useDispatch } from 'react-redux';
import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { IOverviewData, OverviewErrorEventName, OverviewType } from '../../models/IOverviewData';
import { LocaleKeys, Routes } from '../../shared';
import { useHistory } from 'react-router';
import { isNullOrUndefined } from 'util';
import { IOverviewActionCardProps } from '../../components/Shared/OverviewCard';

export const useOverviewAccessReviewManager = () => {
  const {
    pendingGroupReviewData,
    pendingAccessPackageReviewData } = useSelector((state) => ({
    pendingGroupReviewData: state.app.overviewGroupReviewData,
    pendingAccessPackageReviewData: state.app.overviewAccessPackageReviewData
  }));

  const dispatch = useDispatch();
  const t = useTranslation();
  const getGroupReviewCount = createAction(EntitlementActions.getGroupReviewCount, dispatch);
  const getAccessPackageReviewCount = createAction(EntitlementActions.getAccessPackageReviewCount, dispatch);

  const history = useHistory();

  const viewAccessReviews = () => {
    let path = Routes.accessReviews;
    history.push(path);
  };

  const buildOverviewAccessReviewCardData =
  (groupReviewOverviewData: IOverviewData, accessPackageOverviewData: IOverviewData): IOverviewActionCardProps => {
    let overviewAccessReviewCardData: IOverviewActionCardProps = {
      id: OverviewType.pendingAccessReviews,
      title: t(LocaleKeys.accessReview),
      cardDescription: t(LocaleKeys.pendingAccessReview),
      cardImageUrl: '/imgs/overview_AccessReview.png',
      primaryButtonLabel: t(LocaleKeys.reviewNow),
      isLoading: true,
      isSucceeded: false,
      primaryButtonAction: viewAccessReviews,
      refreshAction: ()=> {
        getGroupReviewCount();
        getAccessPackageReviewCount();
      }
    };

    if (!isNullOrUndefined(groupReviewOverviewData) && !isNullOrUndefined(accessPackageOverviewData)) {
      overviewAccessReviewCardData.isLoading = groupReviewOverviewData.isLoading || accessPackageOverviewData.isLoading;
      overviewAccessReviewCardData.isSucceeded = groupReviewOverviewData.isSucceeded && accessPackageOverviewData.isSucceeded;
      overviewAccessReviewCardData.pendingActionEntityCount =
        groupReviewOverviewData.pendingActionEntityCount! + accessPackageOverviewData.pendingActionEntityCount!;
      overviewAccessReviewCardData.errorEventName =
        groupReviewOverviewData.errorEventName && accessPackageOverviewData.errorEventName
        ? OverviewErrorEventName.accessReviewError
        : groupReviewOverviewData.errorEventName || accessPackageOverviewData.errorEventName;
      overviewAccessReviewCardData.error = [...(groupReviewOverviewData.error || []), ...(accessPackageOverviewData.error || [])];
      if (overviewAccessReviewCardData.pendingActionEntityCount && overviewAccessReviewCardData.pendingActionEntityCount > 1) {
        overviewAccessReviewCardData.cardDescription = t(LocaleKeys.pendingAccessReview, {context: 'plural'});
      }
    }

    return overviewAccessReviewCardData;
  };

  const accessReviewData: IOverviewActionCardProps =
   buildOverviewAccessReviewCardData(pendingGroupReviewData, pendingAccessPackageReviewData);

  return {accessReviewData, getGroupReviewCount, getAccessPackageReviewCount, viewAccessReviews};
};