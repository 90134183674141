import { Icon, KeyCodes } from '@fluentui/react';
import {
  ResponsiveMode,
  withResponsiveMode
} from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import * as React from 'react';
import { ExtendedSearchBox } from '../Search/ExtendedSearchBox';
import {
  ICustomSearchBoxProps,
  ICustomSearchBoxState
} from './ConnectedCustomSearchBox.types';

const customSearchBoxStyles = require('./CustomSearchBox.scoped.scss');

@withResponsiveMode
export class CustomSearchBox extends React.Component<
ICustomSearchBoxProps,
ICustomSearchBoxState
> {
  constructor(props: ICustomSearchBoxProps) {
    super(props);

    this.state = {
      isFocusInSearch: false,
      isSearchBoxVisible: false
    };
  }
  public render(): JSX.Element {
    const {
      backButtonLabel,
      searchVisible,
      searchPlaceholderText,
      executeSearchLabel,
      searchContext,
      filterItems,
      showFiltersMenu,
      showFiltersIcon,
      selectedFilterKey,
      theme,
      t
    } = this.props;

    let responsiveMode = this.props.responsiveMode;
    if (responsiveMode === undefined) {
      responsiveMode = ResponsiveMode.large;
    }

    const hideSearch = searchVisible !== true;

    const isSearchBoxExpanded = this._isSearchBoxExpanded();

    if (hideSearch) {
      return <div />;
    }

    const searchBox = (
      <ExtendedSearchBox
        disabled={!searchContext}
        searchText={this.props.searchTerm}
        isSearchBoxExpanded={isSearchBoxExpanded}
        isFocusInSearch={this.state.isFocusInSearch}
        backButtonTitle={backButtonLabel}
        searchPlaceholderText={this.props.t(searchPlaceholderText!.key)}
        executeSearchLabel={executeSearchLabel}
        filterItems={filterItems}
        selectedFilterKey={selectedFilterKey}
        showFiltersMenu={showFiltersMenu}
        showFiltersIcon={showFiltersIcon}
        onBackClick={this._goSearchBack}
        onBlurred={this._onSearchBlurred}
        onFocused={this._onSearchFocused}
        onFilterClick={this.props.onFilterClick}
        onChange={this._onSearchChange}
        onSearch={this._onSearch}
        selectFilterItem={this.props.selectFilterItem}
        hideFilter={this.props.hideFilter}
        t={t}
      />
    );
    const mobile = this.state.isSearchBoxVisible ? (
      <div className={customSearchBoxStyles.searchBoxFixed}>{searchBox}</div>
    ) : (
      <Icon
        iconName="Search"
        className={customSearchBoxStyles.searchIcon}
        onClick={this._showSearchBox}
        onKeyDown={this._handleSearchKeyDown}
        tabIndex={0}
      />
    );
    return responsiveMode < ResponsiveMode.large ? mobile : searchBox;
  }

  private _hasSearchTerm = (): boolean => {
    const { searchTerm } = this.props;
    return searchTerm !== undefined && searchTerm.trim().length > 0;
  }

  private _isSearchBoxExpanded = (): boolean => {
    return (
      this.state.isFocusInSearch ||
      this.props.showFiltersMenu ||
      !this._isDefaultFilterSelected() ||
      this._hasSearchTerm()
    );
  }

  private _isDefaultFilterSelected = (): boolean => {
    if (
      this.props.selectedFilterKey === undefined ||
      this.props.filterItems === undefined ||
      this.props.filterItems.length === 0
    ) {
      return true;
    }
    return this.props.filterItems[0].key === this.props.selectedFilterKey;
  }

  private _onSearchChange = (searchTerm: string): void => {
    this.props.onSearchChange &&
      this.props.onSearchChange(this.props.searchContext!, searchTerm);
  }

  private _onSearch = (actionSource: string): void => {
    this.props.onSearch &&
      this.props.onSearch(
        this.props.searchContext!,
        this.props.searchTerm!,
        actionSource
      );
  }

  private _goSearchBack = (): void => {
    if (this.props.goBack) {
      this.props.goBack(this.props.searchContext!);
    }

    this._goBack();
  }

  private _goBack = (): void => {
    this.setState({
      isSearchBoxVisible: false,
      isFocusInSearch: false
    });
  }

  private _onSearchFocused = (): void => {
    this.setState({
      isSearchBoxVisible: true,
      isFocusInSearch: true
    });
  }

  private _onSearchBlurred = (): void => {
    this.setState({
      isSearchBoxVisible: false,
      isFocusInSearch: false
    });
  }

  private _handleSearchKeyDown = (event) => {
    if (event.keyCode === KeyCodes.enter) {
      event.preventDefault();
      this._showSearchBox();
    }
  }

  private _showSearchBox = (): void => {
    this.setState({
      isSearchBoxVisible: true,
      isFocusInSearch: true
    });
  }
}
