import { User } from '@microsoft/microsoft-graph-types';
import { ILoadable, LoadingState } from '@microsoft/portal-app/lib/models/ILoading';

import { IGetPhotoActionPayload } from '../../redux';

export function shouldLoadPhotoDirectReports(
  enableNewRequestForm: boolean,
  userObjectIdIsPresent: boolean,
  directReportsNotLoaded: boolean,
  getDirectReports: () => void,
  prevDirectReportsLoadingState: LoadingState | undefined,
  loadableDirectReports: ILoadable<readonly User[]>,
  usersPhoto: Map<string, string | null>,
  getPhoto: (payload: IGetPhotoActionPayload) => void
): void {
  if (!enableNewRequestForm) {
    return;
  }

  if (userObjectIdIsPresent && directReportsNotLoaded) {
    getDirectReports();
  }

  // Only get direct reports photos if state changed from loading to loaded
  if (
    prevDirectReportsLoadingState === LoadingState.loading &&
    loadableDirectReports.loadingState === LoadingState.loaded
  ) {
    loadableDirectReports.value.forEach((reportee) => {
      if (reportee?.id && reportee.id !== '' && !usersPhoto.has(reportee.id)) {
        getPhoto({ userObjectId: reportee?.id });
      }
    });
  }
}
