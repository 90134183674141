import { Link, TextField } from '@fluentui/react';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';

import { useTelemetryWithMetadata, useTranslation } from '../../../hooks';
import { OsTypeEnum } from '../../../models/RAM/OsTypeEnum';
import { LocaleKeys } from '../../../shared';
import { CopyableTextField } from '../../Shared/CopyableTextField/CopyableTextField';
import { SshConnectionOption } from './Connection.utils';
import { createOrderedList, installationStep, textFieldStyles } from './sshContent.utils';

interface ArcSshContentProps {
  subscription: string;
  resourceGroup: string;
  machineName: string;
  osType?: OsTypeEnum;
}

const ramConnectionStyles = require('./ram.connection.scss');

export const ArcSshContent: FunctionComponent<ArcSshContentProps> = (props: ArcSshContentProps) => {
  const t = useTranslation();
  const { reportCustomEventWithMetadata } = useTelemetryWithMetadata();
  const [username, setUsername] = useState<string>('');

  // Log sshContent loading to track usage
  useEffect(() => {
    reportCustomEventWithMetadata('ram/connection-tab/ssh');
    reportCustomEventWithMetadata('ram/journey/3/ssh-tab');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCopyTextField = useCallback(
    (connectionOption: SshConnectionOption) => {
      let loggingMessage = 'ram/connection/ssh';
      switch (connectionOption) {
        case SshConnectionOption.EntraId:
          loggingMessage += '/entraId';
          break;
        case SshConnectionOption.Client:
          loggingMessage += '/client';
          break;
        default:
          break;
      }
      return (copy: boolean) => {
        copy ? (loggingMessage += '/success') : (loggingMessage += '/fail');
        reportCustomEventWithMetadata(loggingMessage);
      };
    },
    [reportCustomEventWithMetadata]
  );

  const connectionCommand = useCallback(
    (requireLocalUser: boolean) => {
      const usernameStr = !username ? t(LocaleKeys.ramConnectionArcSshUsername) : `"${username}"`;
      const command = `az ssh arc --subscription "${props.subscription}" --resource-group "${props.resourceGroup}" --name "${props.machineName}"`;

      return requireLocalUser ? command + ` --local-user ${usernameStr}` : command;
    },
    [username, t, props.subscription, props.resourceGroup, props.machineName]
  );

  const onChangeUsername = useCallback(
    (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      setUsername(newValue ?? '');
    },
    [setUsername]
  );

  const usernameStep = useCallback(() => {
    return (
      <>
        <span>{t(LocaleKeys.ramConnectionProvideLocalUsername)}</span>
        <div className={ramConnectionStyles.dialogSection}>
          <TextField value={username} readOnly={false} onChange={onChangeUsername} styles={textFieldStyles} />
        </div>
      </>
    );
  }, [onChangeUsername, username, t]);

  const connectionStep = useCallback(
    (connectionCommand: string, connectOption: SshConnectionOption): JSX.Element => {
      return (
        <>
          <span>{t(LocaleKeys.ramConnectionSshConnectionStep)}</span>
          <div className={ramConnectionStyles.dialogSection}>
            <CopyableTextField value={connectionCommand} onCopy={onCopyTextField(connectOption)} />
          </div>
        </>
      );
    },
    [onCopyTextField, t]
  );

  const loginStep = useCallback((): JSX.Element => {
    const learnMoreLink =
      'https://learn.microsoft.com/en-us/entra/identity/devices/howto-vm-sign-in-azure-ad-linux#configure-role-assignments-for-the-vm';
    return (
      <>
        <div>
          <span>{t(LocaleKeys.ramConnectionSshLoginStep)}</span>
        </div>
        <div>
          <span>
            {t(LocaleKeys.ramConnectionArcSshLoginStepMessage)}
            <Link href={learnMoreLink} target="_blank">
              {t(LocaleKeys.learnMore)}
            </Link>
          </span>
        </div>
      </>
    );
  }, [t]);

  const entraIdConnectionMethodList = useCallback(() => {
    return createOrderedList([
      installationStep(t),
      loginStep(),
      connectionStep(connectionCommand(false), SshConnectionOption.EntraId)
    ]);
  }, [connectionCommand, connectionStep, loginStep, t]);

  const clientConnectionMethodList = useCallback(() => {
    return createOrderedList([
      installationStep(t),
      usernameStep(),
      connectionStep(connectionCommand(true), SshConnectionOption.Client)
    ]);
  }, [connectionCommand, connectionStep, t, usernameStep]);

  if (props.osType === undefined || props.osType === OsTypeEnum.Windows)
    return <div>{clientConnectionMethodList()}</div>;
  return (
    <>
      <div>
        <h3 className={ramConnectionStyles.connectionHeading}>{t(LocaleKeys.ramConnectionSshOption1)}</h3>
        {entraIdConnectionMethodList()}
      </div>
      <div>
        <h3 className={ramConnectionStyles.connectionHeading}>{t(LocaleKeys.ramConnectionSshOption2)}</h3>
        {clientConnectionMethodList()}
      </div>
    </>
  );
};
