import { TranslationFunction } from 'i18next';

import {
  ActivityItem,
  ColorClassNames,
  css,
  FontClassNames,
  HoverCard,
  HoverCardType,
  IPlainCardProps,
  Link,
} from '@fluentui/react';
import * as React from 'react';
import { IGrantRequest } from '../../../models/ELM/IGrantRequest';
import { IRequestActivity } from '../../../models/ELM/IRequestActivity';
import {
  getDecisionTypeFromRequestStatus,
  RequestStatus,
  showHoverCardForStatus,
} from '../../../models/ELM/RequestStatus';
import {
  getSimpleRequestTypeFromRequestType,
  SimpleRequestType,
} from '../../../models/ELM/RequestType';
import { LocaleKeys } from '../../../shared';
import { FormatDateTime } from '../../../shared/FormatDateTime';
import { getIconForRequestStatus } from '../../../shared/getIconForRequestStatus';
import { getSpinner } from '../../../shared/spinner';
import { DecisionHoverCard } from '../DecisionHoverCard/DecisionHoverCard';
import { isNullOrUndefined } from 'util';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');

export interface IRequestActivitiesProps {
  t: TranslationFunction;
  grantRequest: IGrantRequest;
  isLoading: boolean;
  showApproverInfo(index: number): void;
}

/* Represent the side panel showing activity history of the request. */
export class RequestActivities extends React.Component<
  IRequestActivitiesProps,
  {}
> {
  public render(): JSX.Element {
    const { grantRequest, t, isLoading } = this.props;
    if (!grantRequest || typeof grantRequest.history === 'string') {
      return <div />;
    }

    /* Render each activity card in the history list. */
    const activityList: Array<JSX.Element> = [];
    let requestStatusList: Array<RequestStatus> = [];
    grantRequest.history.forEach(
      (requestActivity: IRequestActivity, index: number) => {
        let action = requestActivity.action.toString();
        // TODO - @Vikama: this is special handling for the resume action as the action name string with '-' cannot be added as enum
        if (action.includes('-Resume')) {
          action = action.replace('-', '_');
        }
        // **Note: {requestStatus} temporary for bug fix, will revert after fix below
        // **Todo: Admin Portal to update request history status as 'removeFulfilled' for removal
        let requestStatus =
          RequestStatus[action] === RequestStatus.Fulfilled &&
            getSimpleRequestTypeFromRequestType(grantRequest.requestType) ===
            SimpleRequestType.Remove
            ? RequestStatus.RemoveFulfilled
            : RequestStatus[action];

        requestStatusList.push(requestStatus);

        /* Extra handling for two stages approval;
        case 1: [approvedFirstStage, approvedSecondStage, approved]
        case 2: [approvedFirstStage, approved] */
        if (requestStatus === RequestStatus.Approved) {
          let isFirstStageApproved = requestStatusList.indexOf(
            RequestStatus.ApprovedFirstStage
          ) !== -1 ? true : false;
          let isSecondStageApproved = requestStatusList.indexOf(
            RequestStatus.ApprovedSecondStage
          ) !== -1 ? true : false;
          let isThirdStageApproved = requestStatusList.indexOf(
            RequestStatus.ApprovedThirdStage
          ) !== -1 ? true : false;

          if (
            isFirstStageApproved &&
            isSecondStageApproved &&
            !isThirdStageApproved
          ) {
            // Case 1: [approvedFirstStage, approvedSecondStage, approved]
            // Ignore the `approved` request status for 2 stage approval.
            requestStatus = undefined;
          } else if (isFirstStageApproved && !isSecondStageApproved) {
            // Case 2: [approvedFirstStage, approved]
            requestStatus = RequestStatus.ApprovedSecondStage;
          }
        }

        // Skip `approvedThirdStage` request status.
        if (
          !requestStatus ||
          requestStatus === RequestStatus.ApprovedThirdStage
        ) {
          return;
        }

        const activityItem = (
          <ActivityItem
            key={index}
            activityDescription={this._getDescription(
              requestActivity,
              requestStatus
            )}
            timeStamp={this._getTimeStampDiv(requestActivity, index)}
            activityIcon={getIconForRequestStatus(requestStatus)}
            className={css(
              'ms-pii',
              myAccessStyles.marginBottomXSmall,
              ColorClassNames.neutralLighterBackground,
              myAccessStyles.padding,
              myAccessStyles.paddingLeftSmallPlus,
              myAccessStyles.paddingRightSmallPlus
            )}
          />
        );

        activityList.push(activityItem);
      }
    );

    return (
      <div>
        <div
          className={css(FontClassNames.xLarge, myAccessStyles.marginTopMedium)}
        >
          {t(LocaleKeys.requestActivity)}
        </div>
        <div
          className={css(
            myAccessStyles.marginTopSmall,
            myAccessStyles.marginBottomMedium
          )}
        >
          {activityList}
          {isLoading ? getSpinner(t(LocaleKeys.loading)) : null}
        </div>
      </div>
    );
  }

  private _getTimeStampDiv(requestActivity: IRequestActivity, index: number): JSX.Element {
    const { t } = this.props;
    const requestStatus = RequestStatus[requestActivity.action];

    const plainCardProps: IPlainCardProps = {
      onRenderPlainCard: () => (
        <DecisionHoverCard
          decisionType={getDecisionTypeFromRequestStatus(requestStatus)!}
          actorDisplayName={requestActivity.actorDisplayName}
          actorEmail={requestActivity.actorPrincipalName}
          detail={requestActivity.detail}
          t={t}
        />
      ),
      renderData: requestActivity,
    };

    return (
      <div>
        <span className={css(FontClassNames.xSmall)}>
          {FormatDateTime(requestActivity.actionDateTime)}{' '}
        </span>
        {showHoverCardForStatus(requestStatus) ? (
          <HoverCard
            className={css(myAccessStyles.activityDetails)}
            instantOpenOnClick={true}
            type={HoverCardType.plain}
            plainCardProps={plainCardProps}
          >
            <Link className={css(FontClassNames.small, myAccessStyles.underline)}>
              {t(LocaleKeys.details)}
            </Link>
          </HoverCard>
        ) : this._isPendingApprovalStatusWithDetails(requestActivity) ? (<div><Link className={css(FontClassNames.small, myAccessStyles.underline, myAccessStyles.activityDetails)} onClick={() => this.props.showApproverInfo(index)}>
          {t(LocaleKeys.details)}
        </Link></div>) : this._isPendingApprovalStatusWithOtherActivityDetailsPresent(requestActivity) ? (<div className={css(FontClassNames.small, myAccessStyles.bold, myAccessStyles.activityDetails)}>{t(LocaleKeys.detailsNotAvailable)}</div>) : null}
      </div>
    );
  }

  private _isPendingApprovalStatusWithDetails(requestActivity: IRequestActivity): boolean {
    const requestStatus = RequestStatus[requestActivity.action];
    return (requestStatus === RequestStatus.PendingApproval || requestStatus === RequestStatus.PendingApprovalEscalated) &&
      !isNullOrUndefined(requestActivity.detail) &&
      requestActivity.detail != '';
  }

// This check is used to determine if any other PendingApproval activity in history has 'Details' value set so that
// "Details not available" message can be shown for the activity being input to this method.
  private _isPendingApprovalStatusWithOtherActivityDetailsPresent(requestActivity: IRequestActivity): boolean {
    let grantRequest = this.props.grantRequest;
    var activityWithDetailsPresent = grantRequest.history.some(a => this._isPendingApprovalStatusWithDetails(a));
    const requestStatus = RequestStatus[requestActivity.action];
    return (requestStatus === RequestStatus.PendingApproval || requestStatus === RequestStatus.PendingApprovalEscalated) && activityWithDetailsPresent;
  }

  private _getDescription(
    requestActivity: IRequestActivity,
    requestStatus: string,
  ): JSX.Element {
    const { t } = this.props;
    // **Note: {requestStatus} temporary for bug fix, will revert after fix below
    // **Todo: Admin Portal to update request history status as 'removeFulfilled' for removal
    // const requestStatus = RequestStatus[requestActivity.action];

    const localeOptions = {
      scheduledDateTime: FormatDateTime(requestActivity.scheduledDateTime),
      actorDisplayName: requestActivity.actorDisplayName,
      actorUpn: requestActivity.actorPrincipalName,
    };

    const localeKey = `requestActivity.${requestStatus}`;
    let cardAdditionalDescription = this._getCardAdditionalDescription(
      requestStatus
    );

    // TODO - @Vikama, this is temp code for showing resume details
    if (requestActivity.action.toString().includes('-Resume')) {
      cardAdditionalDescription = `${requestActivity.detail}`;
    }

    return (
      <div
        className={css(
          'ms-pii',
          myAccessStyles.wordBreak,
          FontClassNames.smallPlus,
          myAccessStyles.marginBottomXSmall
        )}
      >
        <div>{t(localeKey, localeOptions)}</div>
        {cardAdditionalDescription ? (
          <span className={css(myAccessStyles.semiBoldText)}>
            {cardAdditionalDescription}
          </span>
        ) : null}
      </div>
    );
  }

  private _getCardAdditionalDescription(
    requestStatus: string
  ): string | null {
    const { t, grantRequest } = this.props;
    switch (requestStatus) {
      case RequestStatus.ApprovedFirstStage:
        return t(LocaleKeys.firstApproverWithoutStageCount);
      case RequestStatus.ApprovedSecondStage:
        return t(LocaleKeys.secondApproverWithoutStageCount);
      case RequestStatus.Approved:
      case RequestStatus.Denied:
        const history = grantRequest.history;
        let isMultiStage = false;
        let isThirdStage = false;
        history.forEach((ra: IRequestActivity) => {
          if (RequestStatus[ra.action] === RequestStatus.ApprovedFirstStage) {
            isMultiStage = true;
          }
          if (RequestStatus[ra.action] === RequestStatus.ApprovedSecondStage) {
            isThirdStage = true;
          }
        });
        if (isMultiStage) {
          if (isThirdStage) {
            return t(LocaleKeys.thirdApproverWithoutStageCount);
          } else {
            return t(LocaleKeys.secondApproverWithoutStageCount);
          }
        }
        return null;
      default:
        return null;
    }
  }
}
