import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';

import {
  getResponseValue,
  IODataValueResponse,
} from '@microsoft/portal-app/lib/odata-utils';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';

import {
  EntitlementActions,
  EntityType,
  ICatalog,
  IEntitlementAction,
  IEntitlementState,
  IEntityResult,
  IRootEntitlementsState,
  LoadMode,
} from '../../../models';
import { getCatalogApiUrl, getRequestWithAudience } from '../../../shared';
import { registry } from '../myAccessRegistry';

/**
 * Epic to search catalog on server.
 */
export const searchCatalogsOnServerEpic: Epic<
  IEntitlementAction<AnyPayload>,
  IRootEntitlementsState
> = (
  action$: ActionsObservable<IEntitlementAction<string>>,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
  return action$
    .ofType(EntitlementActions.searchCatalogsOnServer)
    .switchMap((action: IEntitlementAction<string>) => {
      const searchTerm = action.payload && action.payload.toLowerCase();
      const url = getCatalogApiUrl(searchTerm);
      const request = getRequestWithAudience(url, EntityType.catalogs);

      return ajax(request)
        .map((payload: IODataValueResponse<ReadonlyArray<ICatalog>>) => {
          return {
            type: EntitlementActions.searchEntitiesOnServerSucceeded,
            payload: {
              entityType: EntityType.catalogs,
              entities: getResponseValue(payload),
              searchTerm,
              count: payload.response!['@odata.count'],
              nextLink: payload.response!['@odata.nextLink'],
            },
          } as IEntitlementAction<IEntityResult<ICatalog>>;
        })
        .catch(() =>
          Observable.of({
            type: EntitlementActions.searchEntitiesOnServerFailed,
            payload: EntityType.catalogs,
          })
        );
    });
};
registry.addEpic('searchCatalogsOnServerEpic', searchCatalogsOnServerEpic);

/**
 * Action handler to start a new catalog search.
 * Filtered entities set to empty and nextLink set to undefined to force fresh search.
 */
export const searchCatalogsOnServer = (
  state: IEntitlementState,
  action: IEntitlementAction<string>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  const searchTerm = action.payload;

  return {
    ...state,
    searchTerm,
    catalogs: {
      ...state.catalogs,
      filtered: {
        ...state.catalogs.filtered,
        entities: [],
        count: undefined,
        nextLink: undefined,
      },
      isLoading: true,
      loadMode: LoadMode.LoadMore,
      searchTerm,
    },
  };
};
registry.add(EntitlementActions.searchCatalogsOnServer, searchCatalogsOnServer);
