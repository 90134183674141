import {
  ChoiceGroup,
  css,
  DatePicker,
  defaultDatePickerStrings,
  Dropdown,
  IChoiceGroupOption,
  IDropdownOption,
  Label,
  Slider,
  TextField
} from '@fluentui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { useTelemetryWithMetadata, useTranslation } from '../../../hooks';
import { IPimRoleManagementPolicyListRequestParameters } from '../../../models/RAM/IPimRoleManagementPolicyGetRequestParameter';
import { IVirtualMachine } from '../../../models/RAM/IVirtualMachine';
import { LocaleKeys } from '../../../shared';
import { isEligibilityScopedAtResource, roleToActivateForVM } from '../VmList/VmList.columns';
import {
  combineDateTimezone,
  getTimeString,
  getUserTimezoneOffset,
  hoursToIso8601,
  iso8601ToHours,
  isValidTimeFormat,
  timeZoneDropdownOptions
} from './QuickActivation.utils';
import { QuickActivationDialogRole } from './QuickActivationDialog';

// TODO: Augusto is working on a new way to import scss files, ignore for now
// eslint-disable-next-line @typescript-eslint/no-var-requires
const ramQuickActivationStyles = require('./ram.quickActivation.scss');

interface CustomizableContentProps {
  role: QuickActivationDialogRole;
  virtualMachine: IVirtualMachine;
  startTime: string;
  onStartTimeChange: (newValue: string) => void;
  roleDuration: string;
  onRoleDurationChange: (newValue: string) => void;
  customizeSettings: boolean;
  listRoleManagementPolicy: (parameters: IPimRoleManagementPolicyListRequestParameters) => void;
  setQuickActivationRole: (role: QuickActivationDialogRole) => void;
  maximumDuration?: string;
  clearQuickActivationDialogValue: () => void;
}
enum ActivationStartTimeEnum {
  Immediately = 'immediately',
  PickTime = 'pickTime'
}

export const CustomizableContent: React.FunctionComponent<CustomizableContentProps> = (
  props: CustomizableContentProps
) => {
  const t = useTranslation();
  const { reportCustomEventWithMetadata } = useTelemetryWithMetadata();
  const currentDate = new Date(Date.now());
  const minDuration = 0.5;
  const [activationDuration, setActivationDuration] = useState<string>(
    iso8601ToHours(props.role.roleDurationInIso8601Format, reportCustomEventWithMetadata).toString()
  );
  const [roleToActivate, setRoleToActivate] = useState(props.role.roleName);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(currentDate);
  const [selectedTime, setSelectedTime] = useState<string>(getTimeString(currentDate));
  const [selectedTimezone, setSelectedTimezone] = useState<string | undefined>(getUserTimezoneOffset());
  const [selectedStartKey, setSelectedStartKey] = useState(ActivationStartTimeEnum.Immediately);
  const [startDateTime, setStartDateTime] = useState(new Date(Date.now()).toISOString());
  const maxDuration = iso8601ToHours(props.role.roleDurationInIso8601Format, reportCustomEventWithMetadata);

  const timeZoneDropdownItems = timeZoneDropdownOptions(t(LocaleKeys.ramCustomActivationUtc));

  const options: IChoiceGroupOption[] = React.useMemo(
    () => [
      { key: ActivationStartTimeEnum.Immediately, text: t(LocaleKeys.ramCustomActivationImmediately) },
      { key: ActivationStartTimeEnum.PickTime, text: t(LocaleKeys.ramCustomActivationPickAStartingTime) }
    ],
    [t]
  );

  useEffect(() => {
    if (isValidTimeFormat(selectedTime)) {
      const formattedDate = combineDateTimezone(selectedDate as Date, selectedTime, selectedTimezone as string);
      setStartDateTime(formattedDate);
      props.setQuickActivationRole({
        ...props.role,
        roleProperties: {
          ...props.role.roleProperties,
          scheduleInfo: {
            ...props.role.roleProperties.scheduleInfo,
            startDateTime: formattedDate
          }
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, selectedTimezone, selectedTime]);

  const onDurationSliderChange = React.useCallback(
    (value) => {
      const duration = Math.round(value * 2) / 2;
      setActivationDuration(duration.toString());
      props.onRoleDurationChange(hoursToIso8601(value.toString()));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setActivationDuration, props.onRoleDurationChange]
  );

  const onStartTimeError = React.useCallback(
    (value) => {
      const numValue = parseFloat(value);
      if (!value || isNaN(numValue) || numValue < minDuration) {
        setActivationDuration(minDuration.toString());
      } else if (numValue > maxDuration) {
        setActivationDuration(maxDuration.toString());
      } else {
        setActivationDuration((Math.round(numValue * 2) / 2).toString());
      }
      return '';
    },
    [maxDuration]
  );

  const roleDropDownOptions = React.useMemo(() => {
    return props.virtualMachine.eligibleRoleAssignmentList.reduce<Array<{ key: string; text: string }>>(
      (uniqueRoles, role) => {
        if (uniqueRoles.findIndex((item) => item.key === role.roleDefinitionId) === -1) {
          uniqueRoles.push({
            key: role.roleDefinitionId,
            text: role.roleDefinition.displayName
          });
        }
        return uniqueRoles;
      },
      []
    );
  }, [props.virtualMachine.eligibleRoleAssignmentList]);

  const onRoleChange = React.useCallback(
    (_event, option) => {
      props.clearQuickActivationDialogValue();
      const quickRoleAssignment = roleToActivateForVM(props.virtualMachine, option?.text);

      if (quickRoleAssignment) {
        props.listRoleManagementPolicy({
          scope: quickRoleAssignment.directoryScopeId,
          roleDefinitionId: quickRoleAssignment.roleDefinitionId
        });

        props.setQuickActivationRole({
          roleName: quickRoleAssignment.roleDefinition.displayName,
          roleStartTimeDisplayText: t(LocaleKeys.ramQuickActivationDialogRoleSectionImmediatelyDisplayText),
          roleDurationInIso8601Format: props.maximumDuration ?? '',
          roleScope: quickRoleAssignment.directoryScopeId,
          roleProperties: {
            principalId: quickRoleAssignment.principalId,
            roleDefinitionId: `${quickRoleAssignment.directoryScopeId}/providers/Microsoft.Authorization/roleDefinitions/${quickRoleAssignment.roleDefinitionId}`,
            requestType: 'SelfActivate',
            justification: '',
            scheduleInfo: {
              startDateTime: startDateTime || '',
              expiration: {
                type: 'AfterDuration',
                endDateTime: '',
                duration: props.maximumDuration ?? ''
              }
            },
            linkedRoleEligibilityScheduleId: quickRoleAssignment.roleEligibilityScheduleId.split('/').pop() as string
          },
          roleActivationScope: isEligibilityScopedAtResource(quickRoleAssignment.directoryScopeId)
            ? quickRoleAssignment.directoryScopeId
            : props.virtualMachine.id.replace(/(\/resourcegroups\/[^/]+).*/i, '$1'),
          roleId: quickRoleAssignment.id
        });
      }

      setRoleToActivate(option?.text || '');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.setQuickActivationRole, startDateTime, t]
  );

  const activationSubText = (
    <div className={css(ramQuickActivationStyles.subTextPadding)}>{t(LocaleKeys.ramCustomActivationSubtitle)}</div>
  );

  const durationSlider = (
    <>
      <Label required>{t(LocaleKeys.ramCustomActivationDuration)}</Label>
      <div className={css(ramQuickActivationStyles.rootHorizontal)}>
        <div className={css(ramQuickActivationStyles.horizontalItem)}>
          <Slider
            className={css(ramQuickActivationStyles.slider)}
            min={minDuration}
            max={maxDuration}
            step={minDuration}
            showValue={false}
            value={!isNaN(parseFloat(activationDuration)) ? parseFloat(activationDuration) : 0}
            onChange={onDurationSliderChange}
            aria-label={t(LocaleKeys.ramCustomActivationDuration)}
          />
        </div>
        <div className={css(ramQuickActivationStyles.sliderTextBoxItem)}>
          <TextField
            className={css(ramQuickActivationStyles.sliderTextBox)}
            onChange={(_e, value) => {
              setActivationDuration((value ?? minDuration).toString());
            }}
            value={activationDuration}
            validateOnFocusOut={true}
            onGetErrorMessage={onStartTimeError}
            ariaLabel={t(LocaleKeys.ramCustomActivationDuration)}
          />
        </div>
      </div>
    </>
  );

  const roleDropdown = (
    <Dropdown
      label={t(LocaleKeys.ramCustomActivationRole)}
      placeholder={roleToActivate}
      options={roleDropDownOptions}
      onChange={onRoleChange}
      required
    />
  );

  const datePicker = (
    <DatePicker
      label={t(LocaleKeys.ramCustomActivationStartDate)}
      isRequired
      value={selectedDate}
      onSelectDate={React.useCallback(
        (date) => {
          if (date) setSelectedDate(date);
        },
        [setSelectedDate]
      )}
      strings={defaultDatePickerStrings}
    />
  );

  const timePicker = (
    <TextField
      value={selectedTime}
      label={t(LocaleKeys.ramCustomActivationTime)}
      required
      validateOnFocusOut={true}
      onChange={(_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        const value = newValue || '';

        setSelectedTime(value);
      }}
      onGetErrorMessage={(value) => {
        if (!isValidTimeFormat(value)) {
          setSelectedTime(getTimeString(new Date(Date.now())));
        }
        return '';
      }}
    />
  );

  const timeZone = (
    <Dropdown
      label={t(LocaleKeys.ramCustomActivationTimeZone)}
      required
      selectedKey={selectedTimezone}
      onChange={(_event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
        setSelectedTimezone(item?.key as string);
      }}
      options={timeZoneDropdownItems}
    />
  );

  const customActivationSettings = props.customizeSettings && (
    <>
      {activationSubText}
      <div className={css(ramQuickActivationStyles.root)}>
        <div className={css(ramQuickActivationStyles.item)}>{roleDropdown}</div>
        <div className={css(ramQuickActivationStyles.item)}>{durationSlider}</div>
        <div className={css(ramQuickActivationStyles.item)}>
          <ChoiceGroup
            selectedKey={selectedStartKey}
            options={options}
            onChange={(_event, option): void => {
              option && setSelectedStartKey(option.key as ActivationStartTimeEnum);
            }}
            label={t(LocaleKeys.ramCustomActivationStartingTime)}
          />
        </div>
        <div className={css(ramQuickActivationStyles.item)}>
          {selectedStartKey === ActivationStartTimeEnum.PickTime && (
            <div className={css(ramQuickActivationStyles.rootHorizontalDateContainer)}>
              <div className={css(ramQuickActivationStyles.itemDatePicker)}>{datePicker}</div>
              <div className={css(ramQuickActivationStyles.timeContainer)}>
                <div className={css(ramQuickActivationStyles.itemTimePicker)}>{timePicker}</div>
                <div className={css(ramQuickActivationStyles.itemTimeZone)}>{timeZone}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );

  return <>{customActivationSettings}</>;
};
