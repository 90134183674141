export interface IActivationStatusPayload {
  status: CycleActivationStatus;
  roleEndTime: string;
}

export enum CycleActivationStatus {
  None = 'None',
  Requesting = 'Requesting',
  Provisioned = 'Provisioned',
  PendingApprovalProvisioning = 'PendingApprovalProvisioning'
}

export enum PimActivationApiRequestStatus {
  Provisioned = 'Provisioned',
  PendingApprovalProvisioning = 'PendingApprovalProvisioning'
}

export enum PimActivationRule {
  MfaRule = 'MfaRule',
  EligibilityRule = 'EligibilityRule'
}
