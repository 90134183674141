// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../dev/node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../dev/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3ZVBoCLx0fH9cJf6V1GPR4{position:fixed;margin-top:0px;left:4px;right:4px;z-index:1}._3Gm593kpCtnojqzC7r3b2r{font-size:16px;margin:8px;padding:8px}\n", "",{"version":3,"sources":["webpack://./src/components/Shared/CustomSearch/CustomSearchBox.scoped.scss"],"names":[],"mappings":"AAAA,yBACE,cAAe,CACf,cAAe,CACf,QAAS,CACT,SAAU,CACV,SAAU,CACX,yBAGC,cAAe,CACf,UAAW,CACX,WAAY","sourcesContent":[".searchBoxFixed {\n  position: fixed;\n  margin-top: 0px;\n  left: 4px;\n  right: 4px;\n  z-index: 1;\n}\n\n.searchIcon {\n  font-size: 16px;\n  margin: 8px;\n  padding: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchBoxFixed": "_3ZVBoCLx0fH9cJf6V1GPR4",
	"searchIcon": "_3Gm593kpCtnojqzC7r3b2r"
};
module.exports = ___CSS_LOADER_EXPORT___;
