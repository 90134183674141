import { ResponsiveMode } from '@fluentui/react';
import { IColumn } from '@fluentui/react/lib/DetailsList';

/**
 * The type for the grid size definition.
 * Possible sizes:
 * @see ResponsiveMode.small: < 480
 * @see ResponsiveMode.medium: >= 480
 * @see ResponsiveMode.large: >= 640
 * @see ResponsiveMode.xLarge: >= 1024
 * @see ResponsiveMode.xxLarge: >= 1366
 * @see ResponsiveMode.xxxLarge: >= 1920
 */
export type GridSizes = {
  [key: string]: Partial<Record<ResponsiveMode, number | [number, number]>>;
};

/**
 * Resizes columns based on the given responsive mode and sizes.
 * Mutates the original columns are returns resized columns.
 *
 * @param columns the columns to resize.
 * @param sizes the sizes.
 * @param responsiveMode the current responsive mode.
 * @returns the resized columns; same reference as the original columns (mutated, not a pure function).
 */
export function resizeResponsiveGrid<T extends IColumn>(
  columns: T[],
  sizes: GridSizes,
  responsiveMode: ResponsiveMode
): T[] {
  for (let column of columns) {
    if (sizes[column.key]) {
      const size = sizes[column.key][responsiveMode];

      if (typeof size === 'number') {
        [column.minWidth, column.maxWidth] = [size, size];
      } else if (size !== undefined) {
        [column.minWidth, column.maxWidth] = size;
      }
    }
  }

  return columns;
}
