import { TranslationFunction } from 'i18next';
import { IDatePickerStrings } from '@fluentui/react';

export const getDatePickerStrings = (
  t: TranslationFunction
): IDatePickerStrings => {
  return {
    months: [
      t('months.jan'),
      t('months.feb'),
      t('months.mar'),
      t('months.apr'),
      t('months.may'),
      t('months.jun'),
      t('months.jul'),
      t('months.aug'),
      t('months.sep'),
      t('months.oct'),
      t('months.nov'),
      t('months.dec')
    ],
    shortMonths: [
      t('shortMonths.jan'),
      t('shortMonths.feb'),
      t('shortMonths.mar'),
      t('shortMonths.apr'),
      t('shortMonths.may'),
      t('shortMonths.jun'),
      t('shortMonths.jul'),
      t('shortMonths.aug'),
      t('shortMonths.sep'),
      t('shortMonths.oct'),
      t('shortMonths.nov'),
      t('shortMonths.dec')
    ],
    days: [
      t('days.sunday'),
      t('days.monday'),
      t('days.tuesday'),
      t('days.wednesday'),
      t('days.thursday'),
      t('days.friday'),
      t('days.saturday')
    ],
    shortDays: [
      t('shortDays.sunday'),
      t('shortDays.monday'),
      t('shortDays.tuesday'),
      t('shortDays.wednesday'),
      t('shortDays.thursday'),
      t('shortDays.friday'),
      t('shortDays.saturday')
    ],
    goToToday: t('goToToday'),
    prevMonthAriaLabel: 'Go to previous month',
    nextMonthAriaLabel: 'Go to next month',
    prevYearAriaLabel: 'Go to previous year',
    nextYearAriaLabel: 'Go to next year'
  };
};
