import {
  CheckboxVisibility,
  css,
  DefaultButton,
  MessageBar,
  MessageBarType,
  SelectionMode,
  Announced
} from '@fluentui/react';
import { withResponsiveMode } from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import * as React from 'react';

import { ErrorBanner } from '@microsoft/portal-app/lib/Banners/ErrorBanner';

import { ConnectedAddGrantRequest, EntitlementDetailsPanel } from '../';
import { EntitlementActions } from '../../../models';
import { IGrant } from '../../../models/ELM/IGrant';
import { RequestType } from '../../../models/ELM/RequestType';
import { IListColumn } from '../../../models/IListColumn';
import { Routes } from '../../../shared';
import {
  GetGrantFromEntitlement,
  GetGrantRequestFromGrant,
} from '../../../shared/GetGrantRequestFromEntity';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { getNewColumnsOnColumnClicked } from '../../../shared/sortingHelper';
import { getSpinner } from '../../../shared/spinner';
import { InfinityList } from '../../Shared/InfinityList/InfinityList';
import { getExpiredGrantListColumns } from './ExpiredGrantList.columns';
import {
  IExpiredGrantListProps,
  IExpiredGrantListState,
} from './ExpiredGrantList.types';
import { QosProvider } from '@iamexperiences/ecos-telemetry';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');
const myAccessListStyles = require('../../../css/myAccessList.scoped.scss');

@withResponsiveMode
export class ExpiredGrantList extends React.Component<
  IExpiredGrantListProps,
  IExpiredGrantListState
> {
  constructor(nextProps: IExpiredGrantListProps) {
    super(nextProps);

    this.state = {
      columns: getExpiredGrantListColumns(
        nextProps.responsiveMode!,
        nextProps,
        this.onCtaButtonClick,
        this.onColumnClick
      ),
      showEntitlementPanel: false,
    };
  }

  public componentDidMount(): void {
    this.props.setSearchContext(EntitlementActions.searchExpiredGrantsOnServer);
    this.props.refreshEntities();
    this.props.setMessageBarVisibility(false);
  }

  public componentWillUnmount(): void {
    this.props.setMessageBarVisibility(false);
  }

  public componentDidUpdate(prevProps: IExpiredGrantListProps): void {
    if (
      prevProps.isSearching !== this.props.isSearching ||
      prevProps.responsiveMode !== this.props.responsiveMode
    ) {
      this.setState({
        ...this.state,
        columns: getExpiredGrantListColumns(
          this.props.responsiveMode!,
          this.props,
          this.onCtaButtonClick,
          this.onColumnClick
        ),
      });
    }
  }
  public render(): JSX.Element {
    const {
      isLoading,
      isRefreshing,
      isLoadingMore,
      isSearching,
      isFiltering,
      pageMetaData,
      errorHasOccurred,
      showingAddGrantRequest,
      isTenantWhitelisted,
      showMessageBar,
      t,
    } = this.props;

    if (isRefreshing) {
      return getSpinner(
        t(LocaleKeys.loadingPage, {
          pageName: t(LocaleKeys.package, { context: 'plural' }),
        })
      );
    }

    if (errorHasOccurred) {
      if (!isTenantWhitelisted) {
        return <ErrorBanner text={t(LocaleKeys.tenantNotWhitelistedMessage)} />;
      }

      return (
        <ErrorBanner
          text={t(LocaleKeys.errorMessage)}
          onAction={this.props.getEntities}
          actionText={t(LocaleKeys.retry)}
        />
      );
    }

    const grants = this.props.grantHistoryList as IGrant[];

    const filteredCount = pageMetaData.filteredEntityCount
      ? pageMetaData.filteredEntityCount
      : 0;

    const { selectedGrant } = this.state;

    const selectedEntitlement = selectedGrant && selectedGrant.accessPackage;

    const grant = GetGrantFromEntitlement(selectedEntitlement);
    const grantRequest = GetGrantRequestFromGrant(grant!, RequestType.UserAdd);

    const showNoEntities =
      pageMetaData.allEntityCount === 0 && !isFiltering && !isSearching;

    const showNoFilteredResults =
      !isLoading && (isFiltering || isSearching) && filteredCount === 0;

    const showLoadMore =
      !pageMetaData.isAllEntitiesFullyCached &&
      !isLoading &&
      !isLoadingMore &&
      !pageMetaData.isFilteredEntitiesFullyCached;

    return (
      <QosProvider name='ExpiredGrantsList'>
        <div
          className={css(
            myAccessListStyles.listPage,
            myAccessListStyles.paddingSmallTop,
            myAccessListStyles.listWithHoverRow
          )}
        >
          {!this.props.isLoading && isSearching && <Announced message={`${filteredCount} items found`}/>}
          <InfinityList
            t={t}
            entityList={grants}
            ariaLabel={'List of expired access packages.'}
            columns={this.state.columns}
            showLoadMore={showLoadMore}
            showSpinner={isLoadingMore}
            spinnerLabel={t(LocaleKeys.loadingPage, {
              pageName: t(LocaleKeys.package, { context: 'plural' }),
            })}
            showNoEntities={showNoEntities}
            noEntitiesProps={{
              iconName: 'Unlock',
              noRowMessage: LocaleKeys.noExpiredGrantsMessage,
              showButton: false,
            }}
            showNoFilteredResults={showNoFilteredResults}
            onLoadMore={this.loadMore}
            onRowClicked={this.onRowClicked}
            selectionMode={SelectionMode.none}
            isExpanded={false}
            searchTerm={this.props.searchTerm}
            selectedFilterKey={this.props.selectedFilterKey}
            checkboxVisibility={CheckboxVisibility.hidden}
          />

          {showingAddGrantRequest && selectedEntitlement ? (
            <ConnectedAddGrantRequest
              title={LocaleKeys.renewAccess}
              grantRequest={grantRequest}
              entitlementName={selectedEntitlement.displayName}
              entitlementDescription={selectedEntitlement.description}
              onDismiss={() => {
                this.props.dismissAddGrantRequest();
                this.dismissEntitlementDetailsPanel();
              }}
            />
          ) : null}

          <EntitlementDetailsPanel
            isVisible={this.state.showEntitlementPanel && !showingAddGrantRequest}
            entitlement={selectedEntitlement}
            onDismiss={this.dismissEntitlementDetailsPanel}
            hideResourcesWithMessage={selectedEntitlement && selectedEntitlement.isRoleScopesVisible
              ? undefined
              : t(LocaleKeys.resourcesNotVisible)}
            messageImageSrc="/imgs/resources_expired.png"
            buttons={{
              leftText: t(LocaleKeys.renew, { context: 'capitalize' }),
              rightText: t(LocaleKeys.cancel),
              leftIsPrimary: true,
              onLeftClick: this.props.showAddGrantRequest,
              onRightClick: this.dismissEntitlementDetailsPanel,
            }}
          />

          {showMessageBar ? (
            <MessageBar
              className={css(myAccessStyles.messageBar)}
              messageBarType={MessageBarType.success}
              isMultiline={false}
              onDismiss={() => this.props.setMessageBarVisibility(false)}
              actions={
                <DefaultButton
                  text={t(LocaleKeys.viewPackage)}
                  href={`#${Routes.requests}`}
                />
              }
            >
              {t(LocaleKeys.successfulRequestMessage)}
            </MessageBar>
          ) : null}
        </div>
      </QosProvider>
    );
  }

  private dismissEntitlementDetailsPanel = (): void => {
    this.setState({
      ...this.state,
      showEntitlementPanel: false,
    });
  };

  private onRowClicked = (grant: IGrant): void => {
    this.setState({
      ...this.state,
      showEntitlementPanel: true,
      selectedGrant: grant,
    });
  };

  private onCtaButtonClick = (grant: IGrant): void => {
    this.setState({
      ...this.state,
      showEntitlementPanel: false,
      selectedGrant: grant,
    });
  };

  private loadMore = (): void => {
    if (!this.props.isLoading) {
      if (!this.props.isFiltering) {
        this.getEntities();
      } else {
        this.searchEntitiesOnServer();
      }
    }
  };

  private getEntities = (): void => {
    if (this.props.pageMetaData.isAllEntitiesFullyCached) {
      return;
    }
    this.props.getEntities();
  };

  private searchEntitiesOnServer = (): void => {
    if (
      this.props.pageMetaData.isAllEntitiesFullyCached! ||
      this.props.pageMetaData.isFilteredEntitiesFullyCached
    ) {
      return;
    }
    this.props.searchForMore();
  };

  private onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IListColumn<IGrant>
  ): void => {
    ev.preventDefault();
    column.isSorted = true;
    column.isSortedDescending = !column.isSortedDescending;

    this.setState({
      columns: getNewColumnsOnColumnClicked(this.state.columns, column),
    });
    this.props.setSortedByColumn(column);
    if (!this.props.isFiltering && !this.props.isSearching) {
      this.props.sortEntities(column.key, !column.isSortedDescending!);
    } else {
      this.props.sortFilteredEntities(
        column.key,
        !column.isSortedDescending!,
        this.props.searchTerm!
      );
    }
  };
}
