import * as moment from 'moment';

import { ApprovalSearchFilter, GrantRequestFilterOptions } from '../models';
import { AccessReviewPartnerGuid } from '../models/AccessReviews/AccessReviewType';
import { EntitlementSearchFilter } from '../models/ELM/EntitlementSearchFilter';
import { EntityType } from '../models/EntityType';
import { IFilter } from '../models/IFilter';
import {
  getAccessReviewDecisionsApiUrl,
  getAccessReviewsApiUrl,
  getDecisionHistoryApiUrl,
  getDecisionsCriteriaApiUrl,
  getDecisionsSummaryApiUrl,
  getFilteredSupervisorCentricAccessReviewDecisionsApiUrl,
  getReviewDetailsApiUrl,
  getSecondaryDecisionsApiUrl,
  getSupervisorCentricAccessReviewDecisionPrincipalResourcesApiUrl,
  getSupervisorCentricAccessReviewDecisionsApiUrl
} from './getAccessReviewsApiUrl';
import { getPendingRaeRequestApiUrl, getRaeRequestsApiUrl } from './getRaeApiUrl';
import { getVirtualMachinesApiUrl } from './getRamApiUrl';
import { isEmptyOrUndefined } from './isEmptyOrUndefined';
import { settingsManager } from './settings';

export const getEntitiesApiUrl = (entityType: string, id?: string): string => {
  switch (entityType) {
    case EntityType.entitlements:
      if (id) {
        return getEntitlementsApiUrl(undefined, undefined, undefined, { id });
      }
      return getEntitlementsApiUrl();
    case EntityType.catalogs:
      return getCatalogApiUrl();
    case EntityType.grantRequests:
      return getGrantRequestsApiUrl();
    case EntityType.pendingApprovalGrantRequests:
      return getApprovalGrantRequestsApiUrl('PendingApproval');
    case EntityType.completedGrantRequests:
      return getApprovalGrantRequestsApiUrl('Delivered');
    case EntityType.validGrants:
      return getGrantsApiUrl(true);
    case EntityType.expiredGrants:
      return getGrantsApiUrl(false);
    case EntityType.suggestions:
      return getPeopleRecommendationsAPIUrl();
    case EntityType.pendingRaeRequests:
      return getRaeRequestsApiUrl('InProgress');
    case EntityType.completedRaeRequests:
      return getRaeRequestsApiUrl('Completed');
    case EntityType.accessReviews:
      return getAccessReviewsApiUrl(AccessReviewPartnerGuid.Governance);
    case EntityType.accessPackageReviews:
      return getAccessReviewsApiUrl(AccessReviewPartnerGuid.AccessPackage);
    case EntityType.aadRoleReviews:
      return getAccessReviewsApiUrl(AccessReviewPartnerGuid.AadRoles);
    case EntityType.rbacReviews:
      return getAccessReviewsApiUrl(AccessReviewPartnerGuid.Rbac);
    case EntityType.userAccessReviews:
      return getAccessReviewsApiUrl(AccessReviewPartnerGuid.UserAccess);
    case EntityType.byodReviews:
      return getAccessReviewsApiUrl(AccessReviewPartnerGuid.Governance, true);
    case EntityType.accessReviewDecisions:
      if (id === EntityType.supervisorCentricReviewDecisions) {
        return getSupervisorCentricAccessReviewDecisionsApiUrl();
      }
      return getAccessReviewDecisionsApiUrl(id);
    case EntityType.secondaryDecisions:
      return getSecondaryDecisionsApiUrl(id);
    case EntityType.virtualMachines:
      return getVirtualMachinesApiUrl();
    default:
      return '';
  }
};

export const getEntityApiUrl = (entityType: string, entityId: string, secondaryId?: string): string => {
  switch (entityType) {
    case EntityType.entitlements:
      return getEntitlementApiUrl(entityId);
    case EntityType.validGrants:
      return getGrantApiUrl(entityId);
    case EntityType.grantRequests:
      return getGrantRequestApiUrl(entityId);
    case EntityType.pendingApprovalGrantRequests:
      return getApprovalGrantRequestApiUrl(entityId, 'PendingApproval');
    case EntityType.completedGrantRequests:
      return getApprovalGrantRequestApiUrl(entityId, 'Delivered');
    case EntityType.pendingRaeRequests:
      return getPendingRaeRequestApiUrl(entityId);
    case EntityType.accessReviews:
      return getReviewDetailsApiUrl();
    case EntityType.decisionsCriteria:
      return getDecisionsCriteriaApiUrl();
    case EntityType.decisionsSummary:
      return getDecisionsSummaryApiUrl(entityId);
    case EntityType.decisionHistory:
      return getDecisionHistoryApiUrl(entityId, secondaryId!);
    case EntityType.secondaryDecisions:
      return getSecondaryDecisionsApiUrl(entityId);
    case EntityType.policies:
      return getApplicablePolicyRequirementsApiUrl(entityId);
    case EntityType.supervisorCentricReviewDecisionsResources:
      return getSupervisorCentricAccessReviewDecisionPrincipalResourcesApiUrl(entityId);
    default:
      return '';
  }
};

export const filterEntitiesApiUrl = (entityType: string, filterContext: IFilter): string => {
  switch (entityType) {
    case EntityType.validGrants:
      return getGrantsApiUrl(true, undefined, undefined, undefined, filterContext);
    case EntityType.expiredGrants:
      return getGrantsApiUrl(false, undefined, undefined, undefined, filterContext);
    case EntityType.grantRequests:
      return getGrantRequestsApiUrl(undefined, undefined, undefined, filterContext);
    case EntityType.entitlements:
      return getEntitlementsApiUrl(undefined, undefined, undefined, filterContext);
    case EntityType.accessReviewDecisions:
      return getAccessReviewDecisionsApiUrl('', '', undefined, undefined, undefined, filterContext);
    case EntityType.supervisorCentricReviewDecisionsResources:
      return getFilteredSupervisorCentricAccessReviewDecisionsApiUrl('', filterContext);
    case EntityType.virtualMachines:
      return getVirtualMachinesApiUrl(undefined, filterContext);
    default:
      return '';
  }
};

export const sortEntitiesApiUrl = (entityType: string, orderby: string, isAscending: boolean): string => {
  switch (entityType) {
    case EntityType.entitlements:
      return getEntitlementsApiUrl('', orderby, isAscending);
    case EntityType.grantRequests:
      return getGrantRequestsApiUrl('', orderby, isAscending);
    case EntityType.pendingApprovalGrantRequests:
      return getApprovalGrantRequestsApiUrl('PendingApproval', orderby, isAscending);
    case EntityType.completedGrantRequests:
      return getApprovalGrantRequestsApiUrl('Delivered', orderby, isAscending);
    case EntityType.validGrants:
      return getGrantsApiUrl(true, '', orderby, isAscending);
    case EntityType.expiredGrants:
      return getGrantsApiUrl(false, '', orderby, isAscending);
    case EntityType.pendingRaeRequests:
      return getRaeRequestsApiUrl('InProgress', orderby, isAscending);
    case EntityType.completedRaeRequests:
      return getRaeRequestsApiUrl('Completed', orderby, isAscending);
    case EntityType.accessReviews:
      return getAccessReviewsApiUrl(AccessReviewPartnerGuid.Governance, false, '', orderby, isAscending);
    case EntityType.accessPackageReviews:
      return getAccessReviewsApiUrl(AccessReviewPartnerGuid.AccessPackage, false, '', orderby, isAscending);
    case EntityType.aadRoleReviews:
      return getAccessReviewsApiUrl(AccessReviewPartnerGuid.AadRoles, false, '', orderby, isAscending);
    case EntityType.rbacReviews:
      return getAccessReviewsApiUrl(AccessReviewPartnerGuid.Rbac, false, '', orderby, isAscending);
    case EntityType.byodReviews:
      return getAccessReviewsApiUrl(AccessReviewPartnerGuid.Governance, true, '', orderby, isAscending);
    case EntityType.accessReviewDecisions:
      return getAccessReviewDecisionsApiUrl('', '', '', orderby, isAscending);
    case EntityType.virtualMachines:
      return getVirtualMachinesApiUrl(undefined, undefined, orderby, isAscending);
    case EntityType.supervisorCentricReviewDecisions:
      return getSupervisorCentricAccessReviewDecisionsApiUrl(orderby, isAscending);
    default:
      return '';
  }
};

export const sortFilteredEntitiesApiUrl = (
  entityType: string,
  orderby: string,
  isAscending: boolean,
  searchTerm: string,
  filterContext: IFilter,
  selectedFilterKey: string
): string => {
  switch (entityType) {
    case EntityType.entitlements:
      return getEntitlementsApiUrl(searchTerm, orderby, isAscending, filterContext, selectedFilterKey);
    case EntityType.grantRequests:
      return getGrantRequestsApiUrl(searchTerm, orderby, isAscending, filterContext);
    case EntityType.pendingApprovalGrantRequests:
      return getApprovalGrantRequestsApiUrl('', orderby, isAscending, searchTerm, selectedFilterKey);
    case EntityType.completedGrantRequests:
      return getApprovalGrantRequestsApiUrl('Delivered', orderby, isAscending, searchTerm, selectedFilterKey);
    case EntityType.validGrants:
      return getGrantsApiUrl(true, searchTerm, orderby, isAscending, filterContext, selectedFilterKey);
    case EntityType.expiredGrants:
      return getGrantsApiUrl(false, searchTerm, orderby, isAscending, filterContext, selectedFilterKey);
    case EntityType.pendingRaeRequests:
      return getRaeRequestsApiUrl('', orderby, isAscending, searchTerm, selectedFilterKey);
    case EntityType.completedRaeRequests:
      return getRaeRequestsApiUrl('Completed', orderby, isAscending, searchTerm, selectedFilterKey);
    case EntityType.accessReviews:
      return getAccessReviewsApiUrl(AccessReviewPartnerGuid.Governance, false, searchTerm, orderby, isAscending);
    case EntityType.accessPackageReviews:
      return getAccessReviewsApiUrl(AccessReviewPartnerGuid.AccessPackage, false, searchTerm, orderby, isAscending);
    case EntityType.aadRoleReviews:
      return getAccessReviewsApiUrl(AccessReviewPartnerGuid.AadRoles, false, searchTerm, orderby, isAscending);
    case EntityType.rbacReviews:
      return getAccessReviewsApiUrl(AccessReviewPartnerGuid.Rbac, false, searchTerm, orderby, isAscending);
    case EntityType.byodReviews:
      return getAccessReviewsApiUrl(AccessReviewPartnerGuid.Governance, true, searchTerm, orderby, isAscending);
    case EntityType.accessReviewDecisions:
      return getAccessReviewDecisionsApiUrl('', '', searchTerm, orderby, isAscending, filterContext);
    case EntityType.supervisorCentricReviewDecisions:
      return getSupervisorCentricAccessReviewDecisionsApiUrl(orderby, isAscending);
    default:
      return '';
  }
};
export const getELMBaseUrlBasedOnEnv = (): string => {
  return settingsManager.get('elmApiBaseUrl')!;
};

export const findByDomainNameApiUrl = (domainName: string): string => {
  const baseUrl = getELMBaseUrlBasedOnEnv();
  const options = `/FindByDomainName(domainName='${domainName}')`;
  return `${baseUrl}tenants${options}`;
};

export const getTenantApiUrl = (): string => {
  const baseUrl = getELMBaseUrlBasedOnEnv();
  const select =
    '?$select=id,initialDomainName,displayName,isTestTenant,premiumSku,privacyUrl,brandingLocale,bannerLogoUrl';
  return `${baseUrl}tenants${select}`;
};

export const getPatchDecisionApiUrl = (requestId: string, stepId: string): string => {
  const baseUrl = getELMBaseUrlBasedOnEnv();
  return `${baseUrl}accessPackageAssignmentRequestApprovals/${requestId}/steps/${stepId}/recordDecision`;
};

export const getEntitlementsApiUrl = (
  searchTerm?: string,
  orderBy?: string,
  isAscending?: boolean,
  filterContext?: IFilter,
  selectedFilterKey?: string
): string => {
  const baseUrl = getELMBaseUrlBasedOnEnv();
  let options = `?$count=true&$top=20&$expand=accessPackageCatalog,accessPackageResourceRoleScopes(
    $expand=accessPackageResourceRole,accessPackageResourceScope($expand=accessPackageResource))`;

  let filter = '';
  let search = '';
  if (!isEmptyOrUndefined(searchTerm)) {
    // Access package name is the default filter
    if (selectedFilterKey === EntitlementSearchFilter.AccessPackage) {
      filter = `&$filter=contains(tolower(displayName), '${searchTerm}')`;
    } else if (selectedFilterKey === EntitlementSearchFilter.Resources) {
      filter =
        `&$filter=accessPackageResourceRoleScopes/any(roleScopes:contains` +
        `(tolower(roleScopes/accessPackageResourceScope/displayName), '${searchTerm}'))`;
    } else if (isEmptyOrUndefined(selectedFilterKey) || selectedFilterKey === EntitlementSearchFilter.All) {
      search = `&$search="${searchTerm}"`;
    }
  }
  if (orderBy && orderBy !== '') {
    options = options + `&$orderby=${orderBy} ${isAscending ? 'asc' : 'desc'}`;
  } else {
    options = options + '&$orderby=displayName asc';
  }

  // Add filter by access package id
  if (filterContext && !isEmptyOrUndefined(filterContext.id)) {
    if (filter === '') {
      filter = '&$filter=';
    } else {
      filter += 'and ';
    }

    filter += `id eq '${filterContext.id}'`;
  }

  // Add filter by catalog id
  if (
    filterContext &&
    !isEmptyOrUndefined(filterContext.selectedCatalogs) &&
    filterContext.selectedCatalogs?.length > 0
  ) {
    if (filter === '') {
      filter = '&$filter=';
    } else {
      filter += 'and ';
    }

    const catalogId = filterContext.selectedCatalogs[0];
    filter += `accessPackageCatalog/id eq '${catalogId}'`;
  }

  return `${baseUrl}accessPackages/Search()${options}${filter}${search}`;
};

export const getEntitlementApiUrl = (id: string): string => {
  const baseUrl = getELMBaseUrlBasedOnEnv();
  const options = `?$expand=accessPackageCatalog,accessPackageResourceRoleScopes(
    $expand=accessPackageResourceRole,accessPackageResourceScope($expand=accessPackageResource))`;
  const filter = `&$filter=id eq '${id}'`;
  return `${baseUrl}accessPackages/Search()${options}${filter}`;
};

export const getApplicablePolicyRequirementsApiUrl = (id: string): string => {
  const baseUrl = getELMBaseUrlBasedOnEnv();
  return `${baseUrl}accessPackages/${id}/getApplicablePolicyRequirements`;
};

export const getRequestableAccessPackageDetailApiUrl = (id: string): string => {
  const baseUrl = getELMBaseUrlBasedOnEnv();
  return `${baseUrl}accessPackages/${id}/getRequestableAccessPackageDetail?$expand=accessPackageResourceRoleScopes($expand=accessPackageResourceRole,accessPackageResourceScope($expand=accessPackageResource))`;
};

export const getDeleteVerifiableCredentialPresentationStatusApiUrl = (): string => {
  const baseUrl = getELMBaseUrlBasedOnEnv();
  return `${baseUrl}accessPackageAssignmentRequests/verifiableCredentialPresentationStatusDelete`;
};

export const getGrantApiUrl = (id: string): string => {
  const baseUrl = getELMBaseUrlBasedOnEnv();
  const expand = `$expand=accessPackage($expand=accessPackageCatalog),accessPackageAssignmentResourceRole
    ($expand=accessPackageResourceRole,accessPackageResourceScope($expand=accessPackageResource))`;
  const filter = `$filter=id eq '${id}'`;
  return `${baseUrl}accessPackageAssignments/filterByCurrentUser(on='${GrantRequestFilterOptions.Target}')?${expand}&${filter}`;
};

export const getGrantRequestApiUrl = (id: string): string => {
  const baseUrl = getELMBaseUrlBasedOnEnv();
  // Fix 4 level expansion issue of failing to get request history package detail, get accessPackageResource from expanding
  // accessPackageAssignmentRequests->accessPackage->accessPackageResourceRoleScopes->accessPackageResource
  // was expanding
  // accessPackageAssignmentRequests->AccessPackageAssignment->accessPackage->accessPackageResourceRoleScopes->accessPackageResource
  const expand =
    `&$expand=requestor,accessPackage($expand=accessPackageCatalog,` +
    `accessPackageResourceRoleScopes($expand=accessPackageResourceRole,` +
    `accessPackageResourceScope($expand=accessPackageResource))),accessPackageAssignment($expand=accessPackage,target)`;
  return (
    `${baseUrl}accessPackageAssignmentRequests/filterByCurrentUser(on='${GrantRequestFilterOptions.TargetOrRequestor}')?` +
    `$filter=id eq '${id}'${expand}`
  );
};

export const getCatalogApiUrl = (searchTerm?: string): string => {
  // Base URL
  const url = new URL('accessPackageCatalogs/Search()', getELMBaseUrlBasedOnEnv());

  // Default params
  const params: Record<string, string> = {
    $count: 'true',
    $top: '20',
    $orderby: 'displayName'
  };

  // Apply search params if it exists
  if (!isEmptyOrUndefined(searchTerm)) {
    params.$filter = `contains(tolower(displayName), '${searchTerm}')`;
  }

  url.search = new URLSearchParams(params).toString();

  return url.href;
};

export const getGrantsApiUrl = (
  isValidOnly: boolean,
  searchTerm?: string,
  orderBy?: string,
  isAscending?: boolean,
  filterContext?: IFilter,
  _selectedFilterKey?: string
): string => {
  const baseUrl = getELMBaseUrlBasedOnEnv();
  const count = `$count=true&$top=20`;
  const expand = isValidOnly
    ? `&$expand=accessPackage($expand=accessPackageCatalog),accessPackageAssignmentResourceRoles
    ($expand=accessPackageResourceRole,accessPackageResourceScope($expand=accessPackageResource))`
    : `&$expand=accessPackage($expand=accessPackageCatalog,accessPackageResourceRoleScopes
      ($expand=accessPackageResourceRole,accessPackageResourceScope($expand=accessPackageResource)))`;
  let filter = '';
  let search = '';
  if (isValidOnly) {
    // Search by access package name, description and resource name
    if (!isEmptyOrUndefined(searchTerm)) {
      search += `&$search="${searchTerm}"`;
    }
    filter += `&$filter=assignmentStatus ne 'Expired' and assignmentStatus ne 'ExpiredNotificationTriggered' and assignmentStatus ne 'Delivering' and assignmentStatus ne 'DeliveryFailed'`;
  } else {
    // Search by access package name only, so keep using the old name filter
    filter += `&$filter=assignmentStatus eq 'Expired' or assignmentStatus eq 'ExpiredNotificationTriggered'`;
    if (!isEmptyOrUndefined(searchTerm)) {
      filter += ` and contains(tolower(accessPackage/displayName), '${searchTerm}')`;
    }
  }

  if (
    !isEmptyOrUndefined(filterContext) &&
    !isEmptyOrUndefined(filterContext.selectedCatalogs) &&
    filterContext.selectedCatalogs.length > 0
  ) {
    const selectedCatalogs = filterContext.selectedCatalogs;
    filter += ` and (accessPackage/accessPackageCatalog/id eq '${selectedCatalogs[0]}'`;
    for (let i = 1; i < selectedCatalogs.length; i++) {
      filter += ` or accessPackage/accessPackageCatalog/id eq '${selectedCatalogs[i]}'`;
    }
    filter += `)`;
  }

  if (filterContext && !isEmptyOrUndefined(filterContext.id)) {
    if (filter === '') {
      filter = '&$filter=';
    } else {
      filter += 'and ';
    }

    filter += `id eq '${filterContext.id}'`;
  }

  let orderby = '';
  if (isEmptyOrUndefined(orderBy)) {
    orderby += `&$orderby=accessPackage/displayName`;
  } else {
    orderby += `&$orderby=${orderBy} ${isAscending ? 'asc' : 'desc'}`;
  }
  return (
    `${baseUrl}accessPackageAssignments/filterByCurrentUser(on='${GrantRequestFilterOptions.Target}')?` +
    `${count}${expand}${filter}${search}${orderby}`
  );
};

export const getGrantRequestsApiUrl = (
  searchTerm?: string,
  orderBy?: string,
  isAscending?: boolean,
  filterContext?: IFilter
): string => {
  const baseUrl = getELMBaseUrlBasedOnEnv();
  const count = `$count=true&$top=20`;
  let filter = '';
  if (!isEmptyOrUndefined(searchTerm)) {
    filter += `&$filter=contains(tolower(accessPackageAssignment/accessPackage/displayName), '${searchTerm}')`;
  }
  if (
    !isEmptyOrUndefined(filterContext) &&
    !isEmptyOrUndefined(filterContext.selectedStates) &&
    filterContext.selectedStates.length > 0
  ) {
    if (filter === '') {
      filter = '&$filter=';
    } else {
      filter += 'and ';
    }
    const selectedStates = filterContext.selectedStates;
    filter += `(requestState eq '${selectedStates[0]}'`;
    for (let i = 1; i < selectedStates.length; i++) {
      filter += ` or requestState eq '${selectedStates[i]}'`;
    }
    filter += `)`;
  }

  if (
    !isEmptyOrUndefined(filterContext) &&
    !isEmptyOrUndefined(filterContext.daysSinceSubmitted) &&
    filterContext.daysSinceSubmitted !== -1
  ) {
    if (filter === '') {
      filter = '&$filter=';
    } else {
      filter += 'and ';
    }
    filter += `createdDateTime gt cast(${moment()
      .subtract(filterContext.daysSinceSubmitted, 'days')
      .startOf('day')
      .format()},Edm.DateTimeOffset)`.replace(/\+/g, '%2b');
  }

  let filterByOption = GrantRequestFilterOptions.TargetOrRequestor;

  if (!isEmptyOrUndefined(filterContext) &&
    !isEmptyOrUndefined(filterContext.selectedRequestedFor) &&
    filterContext.selectedRequestedFor.length === 1) {
    // Check with option is selected
    if (filterContext.selectedRequestedFor[0] === 'Target') {
      filterByOption = GrantRequestFilterOptions.Target
    }
    else if (filterContext.selectedRequestedFor[0] === 'Others') {
      filterByOption = GrantRequestFilterOptions.RequestForOthers
    }
  }

  let orderByString = '';
  if (isEmptyOrUndefined(orderBy)) {
    orderByString = `&$orderby=createdDateTime desc`;
  } else {
    orderByString = `&$orderby=${orderBy} ${isAscending ? 'asc' : 'desc'}`;
  }

  const expand = `&$expand=requestor,accessPackageAssignment($expand=accessPackage,target)`;
  return (
    `${baseUrl}accessPackageAssignmentRequests/filterByCurrentUser(on='${filterByOption}')?` +
    `${count}${expand}${filter}${orderByString}`
  );
};

export const cancelGrantRequestApiUrl = (id: string): string => {
  const baseUrl = getELMBaseUrlBasedOnEnv();
  return `${baseUrl}accessPackageAssignmentRequests('${id}')/cancel`;
};

export const getCheckGrantExistsUrl = (entitlementId: string): string => {
  const baseUrl = getELMBaseUrlBasedOnEnv();
  const filter = `$filter=accessPackageId eq '${entitlementId}' and assignmentStatus ne 'Expired'`;
  return `${baseUrl}accessPackageAssignments/filterByCurrentUser(on='${GrantRequestFilterOptions.Target}')?${filter}`;
};

export const getApprovalGrantRequestsApiUrl = (
  status: string,
  orderBy?: string,
  isAscending?: boolean,
  searchTerm?: string,
  selectedFilterKey?: string
): string => {
  const baseUrl = getELMBaseUrlBasedOnEnv();

  let filter = '';
  const expand =
    '$expand=approval($expand=steps($expand=reviewedBy)),requestor,' +
    'accessPackageAssignment($expand=target,accessPackage)';
  if (isEmptyOrUndefined(orderBy)) {
    if (status === 'Delivered') {
      orderBy = 'createdDateTime';
      isAscending = false;
    }
  }

  // Add 'ne' filter for filtering out completed approvals.
  if (status === 'Delivered') {
    filter = '(requestState ne %27PendingApproval%27)';
    filter += ` and createdDateTime gt cast(${moment()
      .subtract(365, 'days')
      .startOf('day')
      .format()},Edm.DateTimeOffset)`.replace(/\+/g, '%2b');
  } else {
    filter += `createdDateTime gt cast(${moment()
      .subtract(90, 'days')
      .startOf('day')
      .format()},Edm.DateTimeOffset)`.replace(/\+/g, '%2b');
  }

  if (!isEmptyOrUndefined(searchTerm)) {
    if (!isEmptyOrUndefined(filter)) {
      filter += ' and ';
    }

    switch (selectedFilterKey) {
      case ApprovalSearchFilter.AccessPackage:
        filter += ` contains(accessPackageAssignment/accessPackage/displayName, '${searchTerm}')`;
        break;

      case ApprovalSearchFilter.Name:
        filter += ` contains(accessPackageAssignment/target/displayName, '${searchTerm}')`;
        break;
      case ApprovalSearchFilter.All:
      default:
        filter +=
          ` contains(accessPackageAssignment/accessPackage/displayName, '${searchTerm}') or` +
          ` contains(accessPackageAssignment/target/displayName, '${searchTerm}')`;
        break;
    }
  }

  let options = `?$count=true&$top=20&$skip=0`;

  if (filter !== '') {
    options = options + `&$filter=${filter}`;
  }

  if (!isEmptyOrUndefined(orderBy) && status === 'Delivered') {
    options = options + `&$orderby=${orderBy} ${isAscending ? 'asc' : 'desc'}`;
  }

  if (!isEmptyOrUndefined(expand)) {
    options = options + `&${expand}`;
  }
  return `${baseUrl}accessPackageAssignmentRequests/filterByCurrentUser(on='${GrantRequestFilterOptions.Approver}')${options}`;
};

export const getApprovalGrantRequestApiUrl = (id: string, status: string): string => {
  const baseUrl = getELMBaseUrlBasedOnEnv();
  let filter = `id eq '${id}'`;
  if (status === 'Delivered') {
    filter += 'and (requestState ne %27PendingApproval%27)';
  }
  // Fix 4 level expansion issue of failing to get approval package detail, get accessPackageResource from expanding
  // accessPackageAssignmentRequests->accessPackage->accessPackageResourceRoleScopes->accessPackageResource
  // was expanding
  // accessPackageAssignmentRequests->AccessPackageAssignment->accessPackage->accessPackageResourceRoleScopes->accessPackageResource
  const expand =
    `&$expand=accessPackage($expand=accessPackageCatalog,` +
    `accessPackageResourceRoleScopes($expand=accessPackageResourceRole,accessPackageResourceScope` +
    `($expand=accessPackageResource))),accessPackageAssignment($expand=target,accessPackage)`;
  return (
    `${baseUrl}accessPackageAssignmentRequests/filterByCurrentUser(on='${GrantRequestFilterOptions.Approver}')?` +
    `$filter=${filter}${expand}`
  );
};

export const getRecommendationsAPIUrl = (): string => {
  const baseUrl = settingsManager.get('msGraphResourceName')!;
  return `${baseUrl}/testppebetaaccessPackageRecommendation/identityGovernance/insights/accessPackageRecommendations`;
};

export const getPeopleRecommendationsAPIUrl = (): string => {
  const baseUrl = getELMBaseUrlBasedOnEnv();
  return `${baseUrl}accessPackageSuggestions/filterByCurrentUser()?$count=true&$top=100&$expand=accessPackage`;
};

// Entitlement page

/**
 * Returns the get access packages count api
 * @returns - The url of get access packages count
 */
export const getAvailableEntitlementsCountApiUrl = (): string => {
  const baseUrl = getELMBaseUrlBasedOnEnv();
  return `${baseUrl}accessPackages/Search()?$count=true&$top=1&$skip=0`; // workaround as $top=0 is not working
};

/**
 * Returns the get grants count api
 * @param isValidationOnly - Is active grants or not
 * @returns - The url of get grants count
 */

export const getGrantsCountApiUrl = (isValidOnly: boolean): string => {
  const baseUrl = getELMBaseUrlBasedOnEnv();
  const count = `$count=true&$top=0`;
  let filter = '';
  if (isValidOnly) {
    filter += `&$filter=assignmentStatus ne 'Expired' and assignmentStatus ne 'ExpiredNotificationTriggered' and assignmentStatus ne 'Delivering' and assignmentStatus ne 'DeliveryFailed'`;
  } else {
    filter += `&$filter=assignmentStatus eq 'Expired' or assignmentStatus eq 'ExpiredNotificationTriggered'`;
  }
  return (
    `${baseUrl}accessPackageAssignments/filterByCurrentUser(on='${GrantRequestFilterOptions.Target}')?` +
    `${count}${filter}`
  );
};

// Overview

/**
 * Returns the get my features api, for admin opt-in feature only.
 * @returns - The url of get my features
 */
export const getMyFeaturesApiUrl = (): string => {
  const baseUrl = getELMBaseUrlBasedOnEnv();
  return `${baseUrl}settings/myFeatures`;
};

/**
 * Returns the overview get expiring grants count API
 * @returns - The url of get expiring grants count
 */
export const getOverviewExpiringGrantsApiUrl = (): string => {
  const dueEndDateTime = moment().add(14, 'days').utc(true).toISOString();
  const baseUrl = getELMBaseUrlBasedOnEnv();
  const filter =
    `&$filter=assignmentStatus ne 'Expired' and assignmentStatus ne 'ExpiredNotificationTriggered'` +
    ` and schedule/expiration/endDateTime lt cast(${dueEndDateTime},Edm.DateTimeOffset)`;
  return (
    `${baseUrl}accessPackageAssignments/filterByCurrentUser(on='${GrantRequestFilterOptions.Target}')?$count=true&$top=0` +
    `${filter}`
  );
};

/**
 * Returns the overview get pending approvals count API
 * @returns - The url of get pending approvals count
 */
export const getPendingApprovalGrantRequestsCountApiUrl = (): string => {
  const baseUrl = getELMBaseUrlBasedOnEnv();
  return `${baseUrl}accessPackageAssignmentRequests/filterByCurrentUser(on='${GrantRequestFilterOptions.Approver}')?$count=true&$top=0`;
};

export const getApproverSubstitutionApiUrl = (): string => {
  const baseUrl = getELMBaseUrlBasedOnEnv();
  return `${baseUrl}entitlementManagement/currentUserApproverDelegate`;
};

export const setApproverSubstitutionApiUrl = (): string => {
  const baseUrl = getELMBaseUrlBasedOnEnv();
  return `${baseUrl}entitlementManagement/currentUserApproverDelegate`;
};