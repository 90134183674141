import { Icon } from '@fluentui/react';
import * as React from 'react';
import { RequestStatus } from '../models/ELM/RequestStatus';

/* Determine which icon to use in the activity history card based on request status. */
export const getIconForRequestStatus = (
  requestStatus: RequestStatus
): JSX.Element => {
  let iconName = '';
  switch (requestStatus) {
    case RequestStatus.Accepted:
      iconName = 'Add';
      break;

    case RequestStatus.Approved:
    case RequestStatus.AutoApproved:
    case RequestStatus.ApprovedFirstStage:
    case RequestStatus.ApprovedSecondStage:
    case RequestStatus.Fulfilled:
    case RequestStatus.AssignmentRequestCreated_Resume:
    case RequestStatus.AssignmentRequestApproved_Resume:
    case RequestStatus.AssignmentRequestGranted_Resume:
    case RequestStatus.AssignmentRequestDenied_Resume:
    case RequestStatus.AssignmentRequestRemoved_Resume:
      iconName = 'CheckMark';
      break;

    case RequestStatus.Canceled:
    case RequestStatus.Failed:
    case RequestStatus.PartiallyDelivered:
    case RequestStatus.ValidationFailed:
    case RequestStatus.FailedPolicyExpirationCheck:
    case RequestStatus.approvalTimeOut:
    case RequestStatus.RemoveFulfilled:
    case RequestStatus.CallbackTimedOut:
    case RequestStatus.AdminRemove:
    case RequestStatus.SystemRemove:
    case RequestStatus.ApproverRemove:
    case RequestStatus.UserRemove:
      iconName = 'Cancel';
      break;
    case RequestStatus.FulfilledNotificationTriggered:
      iconName = 'Mail';
      break;

    case RequestStatus.PendingValidation:
    case RequestStatus.PendingApproval:
    case RequestStatus.ReadyToFulfill:
    case RequestStatus.PendingApprovalEscalated:
    case RequestStatus.WaitingForCallback:
      iconName = 'Sync';
      break;

    case RequestStatus.PendingNotBefore:
      iconName = 'Calendar';
      break;

    case RequestStatus.ApprovalTimedOut:
      iconName = 'BufferTimeAfter';
      break;

    case RequestStatus.Denied:
      iconName = 'Blocked2';
      break;
  }

  return <Icon iconName={iconName} />;
};
