import '@microsoft/portal-app/lib/styling/patterns/Dialog.scss';
import { AppPanel } from '@uifabric/portal-ux/lib/AppPanel';

import {
  ActivityItem,
  CheckboxVisibility,
  ChoiceGroup,
  ColorClassNames,
  CommandBar,
  css,
  DefaultButton,
  DirectionalHint,
  FontClassNames,
  IChoiceGroupOption,
  ITooltipHostStyles,
  Icon,
  Label,
  PanelType,
  PrimaryButton,
  SelectionMode,
  TextField,
  TooltipHost
} from '@fluentui/react';
import { IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import { withResponsiveMode } from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import * as React from 'react';
import { TranslationFunction } from 'react-i18next';
import { AcceptRecommendations } from '../AcceptRecommendations/AcceptRecommendations';
import { BulkDecisionDialog } from '../BulkDecisionDialog/BulkDecisionDialog';
import { ResetDecisions } from '../ResetDecisions/ResetDecisions';
import { IAccessReviewDecision } from '../../../models/AccessReviews/IAccessReviewDecision';
import { ISubmitDecision } from '../../../models/AccessReviews/ISubmitDecision';
import { IEntity } from '../../../models/ELM/IEntity';
import { EntityType } from '../../../models/EntityType';
import { IListColumn } from '../../../models/IListColumn';
import { DecisionType } from '../../../models/RequestApprovals/DecisionType';
import { RecommendationType } from '../../../models/RequestApprovals/RecommendationType';
import { checkFeatureAccess } from '../../../shared';
import { asLocalizedText } from '../../../shared/asLocalizedText';
import { FormatDate, FormatDateTime } from '../../../shared/FormatDateTime';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { InfinityList } from '../../Shared/InfinityList/InfinityList';
import { getSpinner } from '../../../shared/spinner';
import {
  IUserCentricPanelProps,
  IUserCentricPanelState
} from './UserCentricPanel.types';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');
const myAccessListStyles = require('../../../css/myAccessList.scoped.scss');

@withResponsiveMode
export class UserCentricPanel extends React.Component<
  IUserCentricPanelProps,
  IUserCentricPanelState
> {
  constructor(nextProps: IUserCentricPanelProps) {
    super(nextProps);
    this.state = {
      isSubmitting: false,
      clearItems: false,
      submitCounter: 0,
      decisionType: '',
      justification: '',
      columns: this._getUserCentricColumns(
        this.props.t!
      )
    };
  }

  public componentWillReceiveProps(
    nextProps: IUserCentricPanelProps
  ): void {
    if (
      nextProps.decision !== this.props.decision
    ) {
      this.setState({
        commands: this._getCommands(),
        overflowCommands: this._getOverflowItems(),
        accessReviewSelectedUsers: []
      });
    }
  }

  public componentDidMount(): void {
    this.setState({
      commands: this._getCommands(),
      overflowCommands: this._getOverflowItems(),
      accessReviewSelectedUsers: []
    });
  }

  public componentWillUnmount(): void {
    if (this.props.showingBulkDecisionDialog) {
      this.props.dismissBulkDecisionDialog();
    }
  }

  public componentDidUpdate(prevProps: IUserCentricPanelProps): void {
    if (this.state.clearItems) {
      this.setState({
        clearItems: false
      });
    }

    if (this.state.setAllSecondary) {
      this.setState({
        setAllSecondary: false
      });
    }

    if (prevProps.isSubmitting && !this.props.isSubmitting) {
      this.setState({
        clearItems: true,
        setAllSecondary: false
      });
    }
  }

  public render(): JSX.Element {
    const {
      hidden,
      decision,
      isLoading,
      isRefreshing,
      errorHasOccurred,
      isLoadingMore,
      t,
      isSubmitting,
      responsiveMode,
      showingBulkDecisionDialog,
      showingResetDecisions,
      showingAcceptRecommendations
    } = this.props;

    let displayName = this.props.decision ? this.props.decision.principal?.displayName : 'Resources';
    let principalType = '';
    let principalName = '';

    if (this.props.decision?.secondaryDecisions && this.props.decision?.secondaryDecisions!.length > 0) {
      principalName = this.props.decision?.secondaryDecisions[0].userPrincipalName;
      if (this.props.decision?.secondaryDecisions[0].principal) {
        principalType = this.props.decision?.secondaryDecisions[0].principal.type!;
      }
    }

    const showLoadMore =
      !isLoading &&
      !isLoadingMore;

    return (
      <AppPanel
        isBlocking={false}
        isOpen={!hidden}
        onDismiss={this._panelDismiss}
        type={PanelType.custom}
        customWidth={'500px'}
        headerText={displayName}
        closeButtonAriaLabel={t(LocaleKeys.cancel)}
        isLightDismiss={true}
      >
        {this.props.isLoading ? getSpinner(
          t(LocaleKeys.loadingPage, {
            pageName: t(LocaleKeys.review)
          })
        ) : (
          <div>
            <div className={css(myAccessStyles.wordBreak, myAccessStyles.secondaryText)}>
              {principalType === 'User' ? principalType : ''}
              {principalName}
            </div>
            <CommandBar
              className={css(myAccessListStyles.commandBar, myAccessStyles.marginTopSmall)}
              items={this.state.commands!}
              overflowItems={this.state.overflowCommands!}
              farItems={this.state.farCommands!}
            />
            <InfinityList
              t={t}
              entityList={this.props.decision?.secondaryDecisions!}
              entityType={EntityType.accessReviewDecisions}
              ariaLabel={'List of resources to review.'}
              clearSelection={this.state.clearItems}
              columns={this.state.columns as IListColumn<IEntity>[]}
              showLoadMore={false}
              showSpinner={this.props.isLoadingMore}
              spinnerLabel={t(LocaleKeys.loadingPage, {
                pageName: t(LocaleKeys.user, {
                  context: 'plural'
                })
              })}
              showNoEntities={false}
              noEntitiesProps={{
                iconName: 'UserFollowed',
                noRowMessage: LocaleKeys.completedReview,
                showButton: false
              }}
              showNoFilteredResults={false}
              showColumnHeaders={false}
              // tslint:disable-next-line
              onLoadMore={() => { }}
              onItemSelected={this._onItemSelected}
              selectionMode={SelectionMode.multiple}
              isExpanded={false}
              selectAll={this.state.setAllSecondary}
            />
            {showingBulkDecisionDialog ? <BulkDecisionDialog
              t={t}
              decisionType={this.props.bulkDecisionType}
              isSubmitting={isSubmitting}
              userList={this.state.accessReviewSelectedUsers!}
              // tslint:disable-next-line:jsx-no-lambda
              onSubmit={justification =>
                this._patchDecision(this.props.bulkDecisionType, justification)
              }
              onDismiss={this.props.dismissBulkDecisionDialog}
              justificationRequired={(this.props.currentReview ? this.props.currentReview.settings!.justificationRequiredOnApproval : true)}
              responsiveMode={responsiveMode}
            /> : null}
            {showingResetDecisions ? (
              <ResetDecisions
                t={t}
                isSubmitting={isSubmitting}
                userList={this.state.accessReviewSelectedUsers!}
                // tslint:disable-next-line:jsx-no-lambda
                onSubmit={resetAll =>
                  this._patchDecision(DecisionType.NotReviewed, '', resetAll)
                }
                onDismiss={this.props.dismissResetDecisions}
                responsiveMode={responsiveMode}
              />
            ) : null}
            {showingAcceptRecommendations ? (
              <AcceptRecommendations
                t={t}
                userList={this.state.accessReviewSelectedUsers!}
                isSubmitting={isSubmitting}
                // tslint:disable-next-line:jsx-no-lambda
                onSubmit={acceptAll =>
                  this._patchDecision(
                    DecisionType.AcceptRecommendation,
                    '',
                    acceptAll
                  )
                }
                onDismiss={this.props.dismissAcceptRecommendations}
                responsiveMode={responsiveMode}
              />
            ) : null}
          </div>
        )}
      </AppPanel>
    );
  }

  private _onItemSelected = (selectedItems: IAccessReviewDecision[]): void => {
    this.setState({
      commands: this._getCommands(selectedItems),
      overflowCommands: this._getOverflowItems(selectedItems),
      accessReviewSelectedUsers: selectedItems
    });
  }

  private _patchDecision = (
    type: string,
    justification: string,
    decideAll?: boolean
  ): void => {
    this.setState({
      isSubmitting: true
    });
    let submitDecisionType = type;
    let submitDecisionList: ISubmitDecision[] = [];
    this.state.accessReviewSelectedUsers!.forEach(
      (decision: IAccessReviewDecision) => {
        // Do nothing if one of the selected reviews is unreviewed and trying to be reset
        if (decision.reviewResult === DecisionType.NotReviewed && type === DecisionType.NotReviewed) {
          return;
        }

        if (
          type === DecisionType.AcceptRecommendation &&
          decision.accessRecommendation !== RecommendationType.NotAvailable &&
          decision.reviewResult === DecisionType.NotReviewed
        ) {
          submitDecisionType = decision.accessRecommendation!;
        }

        let submitDecision: ISubmitDecision = {
          decisionId: decision.id,
          decisionType: submitDecisionType,
          justification: justification
        };

        if (
          submitDecision.decisionType !== DecisionType.AcceptRecommendation
        ) {
          submitDecisionList.push(submitDecision);
        }
      }
    );

    this.props.submitDecision(submitDecisionList);
  }

  private _getOverflowItems = (selectedItems?: IAccessReviewDecision[]): IContextualMenuItem[] => {
    const noUsers = selectedItems && selectedItems.length > 0 ? false : true;
    const decisionCommandStatus =
      selectedItems &&
        selectedItems.length > 0 ? false : true;

    // Reset Decisions will be enabled if no items are selected, or if at least one item
    // has a decision result other than "NotReviewed"
    let resetDisabled = true;
    if (!noUsers && selectedItems) {
      selectedItems!.forEach((decision: IAccessReviewDecision) => {
        if (decision.reviewResult !== DecisionType.NotReviewed) {
          resetDisabled = false;
        }
      });
    }

    const resetDecisionsCommand: IContextualMenuItem = {
      key: 'resetDecisionsCommand',
      role: 'button',
      ariaLabel: this.props.t(LocaleKeys.resetDecisions),
      name: this.props.t(LocaleKeys.resetDecisions),
      iconProps: { iconName: 'returntosession' },
      disabled: resetDisabled,
      onClick: this.props.showResetDecisions,
      split: true
    };

    const acceptRecommendationsCommand: IContextualMenuItem = {
      key: 'acceptRecommendationsCommand',
      role: 'button',
      ariaLabel: this.props.t(LocaleKeys.acceptRecommendations),
      name: this.props.t(LocaleKeys.acceptRecommendations),
      iconProps: { iconName: 'multiselect' },
      // When select all feature enabled, accept all Recommendation is available from review dialog
      disabled: noUsers || (checkFeatureAccess('reviewerExperience') && decisionCommandStatus),
      onClick: this.props.showAcceptRecommendations
    };

    const commands: IContextualMenuItem[] = [
      resetDecisionsCommand,
      acceptRecommendationsCommand
    ];

    return commands;
  }

  private _getCommands = (selectedItems?: IAccessReviewDecision[]): IContextualMenuItem[] => {
    const decisionCommandStatus =
      selectedItems &&
        selectedItems.length > 0 ? false : true;

    const approveCommand: IContextualMenuItem = {
      key: 'approveCommand',
      role: 'button',
      ariaLabel: this.props.t(LocaleKeys.approve),
      name: this.props.t(LocaleKeys.approve),
      iconProps: { iconName: 'accept' },
      disabled: true,
      onClick: () => {
        this.props.showBulkDecisionDialog(true, DecisionType.Approve);
      }
    };
    approveCommand.disabled = decisionCommandStatus;

    const denyCommand: IContextualMenuItem = {
      key: 'denyCommand',
      role: 'button',
      ariaLabel: this.props.t(LocaleKeys.deny),
      name: this.props.t(LocaleKeys.deny),
      iconProps: { iconName: 'clear' },
      disabled: true,
      onClick: () => {
        this.props.showBulkDecisionDialog(true, DecisionType.Deny);
      }
    };
    denyCommand.disabled = decisionCommandStatus;

    const dontKnowCommand: IContextualMenuItem = {
      key: 'dontKnowCommand',
      role: 'button',
      ariaLabel: this.props.t(LocaleKeys.dontKnow),
      name: this.props.t(LocaleKeys.dontKnow),
      iconProps: { iconName: 'help' },
      disabled: false,
      onClick: () => {
        this.props.showBulkDecisionDialog(true, DecisionType.DontKnow);
      }
    };
    dontKnowCommand.disabled = decisionCommandStatus;

    const selectAllCommand: IContextualMenuItem = {
      key: 'selectAllCommand',
      role: 'button',
      ariaLabel: this.props.t(LocaleKeys.all),
      name: this.props.t(LocaleKeys.selectAll),
      iconProps: { iconName: 'CircleRing' },
      disabled: false,
      onClick: () => {
        this.setState({
          setAllSecondary: true
        });
      }
    };
    selectAllCommand.disabled = false;

    const commands: IContextualMenuItem[] = [
      approveCommand,
      denyCommand,
      dontKnowCommand,
      selectAllCommand
    ];

    return commands;
  }

  private _panelDismiss = (): void => {
    this.setState({
      ...this.state,
      decisionType: '',
      justification: ''
    });
    this.props.onDismiss();
  }

  private _getUserCentricColumns = (
    t: TranslationFunction
  ): IListColumn<IAccessReviewDecision>[] => {
    const primaryColumnName = t(LocaleKeys.name);
    const primaryColumnWidth = 200;
    const userColumn: IListColumn<IAccessReviewDecision> = {
      key: 'userDisplayName',
      name: primaryColumnName,
      fieldName: 'userName',
      minWidth: 80,
      maxWidth: primaryColumnWidth,
      className: 'ms-pii',
      headerClassName: FontClassNames.smallPlus,
      isSorted: true,
      isSortedDescending: false,
      isResizable: true,
      onRender: (item: IAccessReviewDecision) => {
        let recommendation = '';
        let decisionString = '';
        let description = '';

        switch (item.reviewResult) {
          case DecisionType.Approve: {
            decisionString = t(LocaleKeys.approved);
            break;
          }
          case DecisionType.Deny: {
            decisionString = t(LocaleKeys.denied);
            break;
          }
          case DecisionType.DontKnow: {
            decisionString = t(LocaleKeys.dontKnow);
            break;
          }
          default: {
            decisionString = '';
            break;
          }
        }

        switch (item.accessRecommendation) {
          case RecommendationType.Approve: {
            recommendation = t(LocaleKeys.approve);
            description = t(LocaleKeys.recommendApprove, {
              lookback: this.props.lookbackDuration
            });
            break;
          }
          case RecommendationType.Deny: {
            recommendation = t(LocaleKeys.deny);
            description = t(LocaleKeys.recommendDeny, {
              lookback: this.props.lookbackDuration
            });
            break;
          }
          default: {
            recommendation = t(LocaleKeys.notAvailable);
            break;
          }
        }

        const inlineBlockStyle: Partial<ITooltipHostStyles> = {
          root: { display: 'inline-block' },
        };

        let lastSignIn = '';
        item.lastUserSignInDateTime ? lastSignIn = FormatDate(item.lastUserSignInDateTime) : '';

        return (
          // Disabling for inline styles
          // tslint:disable
          <div>
            <div style={{ width: '100%', display: 'table' }}>
              <div style={{ display: 'table-row' }}>
                <div style={{ width: '600px', display: 'table-cell' }}>
                  <div style={{ fontSize: '14px' }}>{item.resource?.displayName}</div>
                  <TooltipHost
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    styles={inlineBlockStyle}
                    content={item.lastUserSignInDateTime ?
                      <div>
                        {item.lastUserSignInDateTime ? (
                          <span className={css('ms-pii', FontClassNames.medium)}>
                            {description + ' (' + lastSignIn + ')'}
                          </span>
                        ) : null}
                      </div>
                      : undefined}>
                    <div>{t(LocaleKeys.recommendSecondary, { decisionType: recommendation })}</div>
                  </TooltipHost>
                </div>
                <div style={{ display: 'table-cell', textAlign: 'right', paddingRight: '10px' }}>
                  {item.reviewResult !== DecisionType.NotReviewed ? (<div>
                    <div style={{ fontSize: '14px' }}>{decisionString}</div>
                    <div>{item.reviewedBy?.displayName}</div>
                  </div>) : <div />}
                </div>
              </div>
            </div>
          </div>
          // tslint:enable
        );
      }
    } as IListColumn<IAccessReviewDecision>;

    let columns: IListColumn<IAccessReviewDecision>[] = [];
    columns.push(userColumn);

    return columns;
  };
}
