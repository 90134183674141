import { DatePicker, IComboBox, IComboBoxOption, TimePicker } from '@fluentui/react';
import * as React from 'react';

const snapTimeToUpdatedDateAnchor = (datePickerDate: Date, hours: number, minutes: number): Date => {
  if (isNaN(hours) || isNaN(minutes)) {
    throw new Error('Invalid time format');
  }

  const currentDate = new Date(datePickerDate);
  currentDate.setHours(hours);
  currentDate.setMinutes(minutes);

  return currentDate;
};

interface IDateTimePickerProps {
  containerStyles?: string;
}

export const DateTimePicker = React.forwardRef<Date, IDateTimePickerProps>(
  ({ containerStyles }, ref) => {
    const currentDate = new Date();
    const [datePickerDate, setDatePickerDate] = React.useState<Date>(currentDate);

    React.useImperativeHandle(ref, () => datePickerDate, [datePickerDate]);


    const onSelectDate = React.useCallback(
      (selectedDate: Date | null | undefined) => {
        if (selectedDate === null || selectedDate === undefined) {
          return
        }
        setDatePickerDate(selectedDate);
      },
      [],
    );

    const onTimePickerChange = React.useCallback((_ev: React.FormEvent<IComboBox>, dateOption: IComboBoxOption | undefined) => {
      if (dateOption?.key === undefined || typeof dateOption.key !== 'string') {
        return;
      }
      const [hours, minutes] = dateOption.key.split(':').map(Number);

      const updatedDate = snapTimeToUpdatedDateAnchor(datePickerDate, hours, minutes);
      setDatePickerDate(updatedDate);
    }, [datePickerDate]);



    return (
      <div className={containerStyles}>
        <DatePicker
          placeholder="Select a date..."
          value={datePickerDate}
          onSelectDate={onSelectDate}
          ariaLabel="Date picker"
        />
        <TimePicker
          placeholder="Select a time"
          useComboBoxAsMenuWidth
          allowFreeform
          defaultValue={datePickerDate.toString()}
          onChange={onTimePickerChange}
          ariaLabel="Time picker"
        />
      </div>
    );
  }
);

// Set the display name for the component
DateTimePicker.displayName = 'DateTimePicker';