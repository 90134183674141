import { Routes } from '../../shared';
import { useHistory } from 'react-router';
export const useOverviewPackageLibraryManager = () => {
  const history = useHistory();

  const viewLibrary = () => {
    let path = Routes.accessPackages;
    history.push(path);
  };

  return { viewLibrary };
};