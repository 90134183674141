export enum AccessReviewType {
  Group = 'Group',
  App = 'App',
  AccessPackage = 'Access Package',
  AadRole = 'Azure AD Role',
  Rbac = 'Azure Resource Role',
  Byod = 'Others',
  UserAccess = 'User Access'
}

export enum AccessReviewProviderType {
  AadRole = 'aadRoles',
  Rbac = 'azureResources',
}

export enum AccessReviewTypeGuid {
  Group = '75c027a5-aa97-468d-baf1-390b706dcebe',
  App = '86a7da99-fd39-4055-8f5d-1de70dae2edd',
  AccessPackage = '67ba058e-c95f-48ad-a861-dd4651ee5e21'
}

export enum AccessReviewPartnerGuid {
  Governance = 'D5EC9F3B-324E-4F8A-AF55-B69EDD48ECBE',
  AccessPackage = 'CC075F07-124C-47C8-9CC0-9890DB325F91',
  AadRoles = '0A7B9F79-7FF3-4663-AC01-0222A72F8C02',
  Rbac = '9FB60D0C-FC64-4DE9-AD80-22C1B531F505',
  UserAccess = '1167804E-C335-4620-B069-AB441FF07674'
}