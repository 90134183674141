import { IAuthUser } from '@iamexperiences/react-auth/lib-commonjs/core/types';
import { TranslationFunction } from 'i18next';
import { ISubject } from '../models/ELM/ISubject';
import { RequestType } from '../models/ELM/RequestType';
import { getUserFromAuth } from './authHelper';
import { LocaleKeys } from './LocaleKeys';
import { isEmptyOrUndefined } from '../../src/shared/isEmptyOrUndefined';

export const getSubjectEmailOrUpn = (
  subject?: ISubject
): string | undefined => {
  return !subject ? undefined : subject.email || subject.principalName;
};

export const getRequestorName = (
  requestor: ISubject,
  requestType:string,
  t: TranslationFunction
): string => {
  const requestorDisplayName =
    RequestType[requestType] === RequestType.SystemRemove
      ? t(LocaleKeys.azureAd)
      : requestor.displayName!;
  return showNameAsYou(requestor) ? t(LocaleKeys.you) : requestorDisplayName;
};

export const getTargetName = (
  t: TranslationFunction,
  target?: ISubject
): string => {
  return !target ? '' : showNameAsYou(target) ? t(LocaleKeys.you) : target.displayName;
};

export const getTargetNameLowercaseIfNotRequestor = (
  t: TranslationFunction,
  requestor: ISubject,
  target?: ISubject
): string => {
  if (!target || (target.objectId && target.objectId === requestor.objectId) || (target.principalName && target.principalName === requestor.principalName)) {
    return '';
  } else {
    return showNameAsYou(target) ? t(LocaleKeys.you_lowercase) : target.displayName;
  }
};

export const authUserMatchesSubject = (
  authUser: IAuthUser,
  subject: ISubject
): boolean => {
  if (authUser.objectId && authUser.objectId === subject.objectId) {
    return true;
  }
  // Users not in directory have no object ID, fall back to UPN comparison
  if (authUser.username && authUser.username === subject.principalName) {
    return true;
  }
  // UPN usually won't match for external users in directory (auth has home UPN, subject has resource UPN with #EXT#), fall back to email comparison
  return !!authUser.email && authUser.email === subject.email;
};

const showNameAsYou = (
  subject: ISubject
): boolean => {
  const curUser = getUserFromAuth();
  const isSelf = authUserMatchesSubject(curUser, subject);
  const curUserHasNoName = isEmptyOrUndefined(curUser.email) || curUser.email.trim() === '';
  return isSelf || curUserHasNoName
};