import '@microsoft/portal-app/lib/styling/patterns/Dialog.scss';
import * as React from 'react';
import { AppPanel } from '@uifabric/portal-ux/lib/AppPanel';
import {
  ChoiceGroup,
  css,
  DefaultButton,
  FontClassNames,
  IChoiceGroupOption,
  Label,
  PanelType,
  PrimaryButton,
  TextField,
  Icon
} from '@fluentui/react';
import { isNullOrUndefined } from 'util';
import { DecisionType } from '../../../models/RequestApprovals/DecisionType';
import { RecommendationType } from '../../../models/RequestApprovals/RecommendationType';
import { asLocalizedText } from '../../../shared/asLocalizedText';
import { FormatDate } from '../../../shared/FormatDateTime';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { getSpinner } from '../../../shared/spinner';
import {
  ISupervisorCentricDecisionDetailsProps,
  ISupervisorCentricDecisionDetailsState
} from './SupervisorCentricDecisionDetails.types';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');

export class SupervisorCentricDecisionDetails extends React.Component<
  ISupervisorCentricDecisionDetailsProps,
  ISupervisorCentricDecisionDetailsState
> {
  constructor(nextProps: ISupervisorCentricDecisionDetailsProps) {
    super(nextProps);
    this.state = {
      decisionType: '',
      justification: ''
    };
  }

  public componentDidUpdate(prevProps: ISupervisorCentricDecisionDetailsProps): void {
    if (prevProps.isSubmitting && !this.props.isSubmitting) {
      this.props.onDismiss();
    } else if (prevProps.hidden && !this.props.hidden) {
      this.setState({
        decisionType: '',
        justification: ''
      });
    }
  }

  public render(): JSX.Element {
    const { hidden, decision, t } = this.props;

    let lastSignIn;
    let recommendation = '';
    let description;

    let approveRadioText = this.props.t(LocaleKeys.approve);
    let denyRadioText = this.props.t(LocaleKeys.deny);
    let dontKnowRadioText = this.props.t(LocaleKeys.dontKnow);

    if (!isNullOrUndefined(decision)) {

      lastSignIn = FormatDate(decision.lastUserSignInDateTime);
      recommendation = decision.accessRecommendation!;

      switch (recommendation) {
        case RecommendationType.Approve:
          recommendation = t(LocaleKeys.approve);
          description = t(LocaleKeys.recommendApprove, {
            lookback: this.props.lookbackDuration
          });
          approveRadioText = asLocalizedText(
            {
              key: LocaleKeys.decisionRecommended,
              options: {
                decisionType: this.props.t(LocaleKeys.approve)
              }
            },
            this.props.t
          );
          break;
        case RecommendationType.Deny:
          recommendation = t(LocaleKeys.deny);
          description = t(LocaleKeys.recommendDeny, {
            lookback: this.props.lookbackDuration
          });
          denyRadioText = asLocalizedText(
            {
              key: LocaleKeys.decisionRecommended,
              options: {
                decisionType: this.props.t(LocaleKeys.deny)
              }
            },
            this.props.t
          );
          break;
        default:
          recommendation = t(LocaleKeys.notAvailable);
          break;
      }
    }

    const customApprove = () => {
      const standardTitle = <div>{approveRadioText}</div>;
      const boldTitle = <b>{approveRadioText}</b>;
      return (
        <div className={css(myAccessStyles.customRadioButton)}>
          {recommendation === RecommendationType.Approve ? (
            <div>
              {boldTitle}
              {!this.props.insights.inactive &&
                this.props.currentReview?.settings?.lastSignInRecommendationEnabled ? (
                <div className={css(myAccessStyles.insightDetails)}>
                  {this.props.t(LocaleKeys.insightActive, {
                    lookback: this.props.lookbackDuration
                  })}
                </div>
              ) : null}
              {this.props.currentReview?.settings?.groupPeerOutlierRecommendationEnabled &&
                !this.props.insights.outlier ? (
                <div className={css(myAccessStyles.insightDetails)}>
                  {this.props.t(LocaleKeys.insightNotOutlier)}
                </div>
              ) : null}
            </div>
          ) : standardTitle}
        </div>
      );
    };

    const customDeny = () => {
      const standardTitle = <div>{denyRadioText}</div>;
      const boldTitle = <b>{denyRadioText}</b>;
      return (
        <div className={css(myAccessStyles.customRadioButton)}>
          {recommendation === RecommendationType.Deny ? (
            <div>
              {boldTitle}
              {this.props.insights.inactive &&
                this.props.currentReview?.settings?.lastSignInRecommendationEnabled ? (
                <div className={css(myAccessStyles.insightDetails)}>
                  {this.props.t(LocaleKeys.insightInactive, {
                    lookback: this.props.lookbackDuration
                  })}
                </div>
              ) : null}
              {this.props.insights.outlier ? (
                <div className={css(myAccessStyles.insightDetails)}>
                  {this.props.t(LocaleKeys.insightOutlier)}
                </div>
              ) : null}
            </div>
          ) : standardTitle}
        </div>
      );
    };

    const choiceGroupOptions = [
      {
        key: DecisionType.Approve,
        ariaLabel: DecisionType.Approve,
        text: approveRadioText,
        onRenderLabel: customApprove
      },
      {
        key: DecisionType.Deny,
        ariaLabel: DecisionType.Deny,
        text: denyRadioText,
        onRenderLabel: customDeny
      },
      {
        key: DecisionType.DontKnow,
        ariaLabel: "Don't Know",
        text: dontKnowRadioText
      }
    ];

    let showRecommendations = false;

    if (this.props.currentReview &&
      this.props.currentReview.settings &&
      this.props.currentReview.settings.accessRecommendationsEnabled) {
      showRecommendations = this.props.currentReview.settings.accessRecommendationsEnabled;
    }

    return (
      <AppPanel
        isBlocking={false}
        isOpen={!hidden}
        onDismiss={this._panelDismiss}
        type={PanelType.custom}
        customWidth={'440px'}
        headerText={t(LocaleKeys.changeOrUpdateDecision)}
        onRenderFooterContent={this._onRenderFooterContent}
        closeButtonAriaLabel={t(LocaleKeys.cancel)}
        aria-hidden={false}
        hasCloseButton={true}
      >
        {!isNullOrUndefined(decision) ? (
          <div>
            {
              recommendation !== RecommendationType.NotAvailable && showRecommendations ?
                (
                  <div className={css(myAccessStyles.supervisorCentricDecisionsDetailsInactiveUserWarningSummary)}>
                    <div className={css(myAccessStyles.supervisorCentricDecisionsDetailsInactiveUserWarningIcon)}>
                      <Icon
                        iconName={'Warning'}
                        className={css(
                          myAccessStyles.warningColor,
                          myAccessStyles.smallIcon
                        )}
                      />
                    </div>
                    <div className={css('ms-pii', myAccessStyles.supervisorCentricDecisionsDetailsInactiveUserWarningDescription)}>
                      <div className={css('ms-pii', FontClassNames.medium, myAccessStyles.bold)} >
                        {this.props.t(LocaleKeys.insightCardInactive)}
                      </div>
                      <div className={css('ms-pii', myAccessStyles.marginTopXSmall, FontClassNames.medium)}>
                        {
                          this.props.currentReview?.settings?.lastSignInRecommendationEnabled ?
                            (
                              <div className={css('ms-pii')}>
                                <span className={css('ms-pii', FontClassNames.medium)}>
                                  {description}
                                </span>
                                {
                                  decision.lastUserSignInDateTime ?
                                    (
                                      <span className={css('ms-pii', FontClassNames.medium)}>
                                        {' (' + lastSignIn + ')'}
                                      </span>
                                    ) : null
                                }
                              </div>
                            ) : null
                        }
                        {this.props.currentReview?.settings?.groupPeerOutlierRecommendationEnabled &&
                          this.props.insights.outlier === false ? (
                          <div>
                            {this.props.t(LocaleKeys.insightNotOutlier)}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : null
            }

            {/* Principal details */}
            <div className={css(myAccessStyles.wordBreak, myAccessStyles.supervisorCentricDecisionsDetailsBorderBottom)}>
              <div>
                <thead className={css(myAccessStyles.decisionDetailsTable, myAccessStyles.marginBottomMedium)}>
                  <tr className={css('ms-pii', myAccessStyles.marginBottomMedium)}>
                    <td className={css('ms-pii', FontClassNames.medium, myAccessStyles.bold, myAccessStyles.marginBottomMedium)} style={{ width: '100px' }}>
                      {t(LocaleKeys.identity)}:
                    </td>
                    <td className={css('ms-pii', myAccessStyles.decisionDetailsTableValue, FontClassNames.medium)}>
                      {decision.userDisplayName} ({decision.userPrincipalName})
                    </td>
                  </tr>
                  <tr className={css('ms-pii', myAccessStyles.marginBottomMedium)} style={{ height: '20px' }}>
                    <td></td>
                  </tr>
                  <tr className={css('ms-pii', myAccessStyles.marginBottomMedium)} >
                    <td className={css('ms-pii', FontClassNames.medium, myAccessStyles.bold)} style={{ width: '100px' }}>
                      {t(LocaleKeys.accessReviewsPrincipalType)}:
                    </td>
                    <td className={css('ms-pii', myAccessStyles.decisionDetailsTableValue, FontClassNames.medium)}>
                      {decision.schemaId}
                    </td>
                  </tr>
                  <tr className={css('ms-pii', myAccessStyles.marginBottomMedium)} style={{ height: '20px' }}>
                    <td></td>
                  </tr>
                  <tr className={css('ms-pii', myAccessStyles.marginBottomMedium)} >
                    <td className={css('ms-pii', FontClassNames.medium, myAccessStyles.bold)} style={{ width: '100px' }}>
                      {t(LocaleKeys.accessReviewsPermission)}:
                    </td>
                    <td className={css('ms-pii', myAccessStyles.decisionDetailsTableValue, FontClassNames.medium)}>
                      {decision.permission}
                    </td>
                  </tr>
                  <tr className={css('ms-pii', myAccessStyles.marginBottomMedium)} style={{ height: '20px' }}>
                    <td></td>
                  </tr>
                  <tr className={css('ms-pii', myAccessStyles.marginBottomMedium)} >
                    <td className={css('ms-pii', FontClassNames.medium, myAccessStyles.bold)} style={{ width: '100px' }}>
                      {t(LocaleKeys.scope)}:
                    </td>
                    <td className={css('ms-pii', myAccessStyles.decisionDetailsTableValue, FontClassNames.medium)}>
                      {decision.resource?.resourceDisplayName}
                    </td>
                  </tr>
                  <tr className={css('ms-pii', myAccessStyles.marginBottomMedium)} style={{ height: '20px' }}>
                    <td></td>
                  </tr>
                </thead>
              </div>
            </div>

            {/* Make a decision section */}
            {decision.reviewResult !== DecisionType.NotReviewed ? (
              <Label className={css(FontClassNames.medium, myAccessStyles.bold, myAccessStyles.supervisorCentricDecisionsDetailsMakeDecisionComboBox)}>
                {this.props.t(LocaleKeys.makeDecision, {
                  context: 'capitalize'
                })}
              </Label>
            ) : <div className={css(myAccessStyles.marginBottomSmall)} />}

            {/* Combo box render */}
            {decision.reviewResult === DecisionType.NotReviewed && this.props.currentReview.stage! > 0 ? (
              <div>
                <Label className={css(myAccessStyles.appPanelLabel)}>
                  {this.props.t(LocaleKeys.stageTitle, {
                    stageNumber: this.props.currentReview.stage! + 1,
                    context: 'capitalize'
                  })}
                </Label>
                <div className={css(myAccessStyles.subtitleText)}>
                  {t(LocaleKeys.decisionWillOverride)}
                </div>
              </div>
            ) : <div className={css(myAccessStyles.marginBottomSmall)} />}

            <ChoiceGroup
              className={css(myAccessStyles.marginBottomSmall)}
              options={choiceGroupOptions}
              onChange={this._onDecisionChanged}
            />
            <TextField
              label={this.props.t(LocaleKeys.provideJustification)}
              value={this.state.justification}
              className={css(myAccessStyles.marginBottomXSmall)}
              required={this.props.justificationRequired! && this.state.decisionType === DecisionType.Approve}
              onChange={this._onJustificationChanged}
              disabled={this.props.isSubmitting}
              multiline
            />
          </div>
        ) : null}
      </AppPanel>
    );
  }

  private _panelDismiss = (): void => {
    this.setState({
      ...this.state,
      decisionType: '',
      justification: ''
    });
    this.props.onDismiss();
  }

  private _onDecisionChanged = (_event?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption): void => {
    this.setState({
      ...this.state,
      decisionType: DecisionType[option!.key]
    });
  }

  private _onJustificationChanged = (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    this.setState({
      ...this.state,
      justification: newValue!
    });
  }

  private _onRenderFooterContent = (): JSX.Element => {
    return (
      <div>
        {this.props.isSubmitting ? (
          getSpinner(asLocalizedText(LocaleKeys.submitting, this.props.t))
        ) : (
          <div>
            <PrimaryButton
              // tslint:disable-next-line:jsx-no-lambda
              onClick={() => this.props.onSubmit(this.state.decisionType, this.state.justification)}
              text={this.props.t(LocaleKeys.submit)}
              ariaLabel={(LocaleKeys.submit)}
              aria-hidden={false}
              className={css(myAccessStyles.marginRightSmall)}
              disabled={
                (this.props.justificationRequired
                  && this.state.decisionType === DecisionType.Approve
                  && this.state.justification === '')
                || this.state.decisionType === ''
              }
            />
            <DefaultButton
              onClick={this.props.onDismiss}
              ariaLabel={(LocaleKeys.cancel)}
              aria-hidden={false}
              text={this.props.t(LocaleKeys.cancel)}
            />
          </div>
        )}
      </div>
    );
  }
}
