import { AppPanel } from '@uifabric/portal-ux/lib/AppPanel';

import {
  css,
  IObjectWithKey,
  PanelType,
  PrimaryButton,
  Selection,
  SelectionMode
} from '@fluentui/react';
import {
  ResponsiveMode,
  withResponsiveMode
} from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import * as React from 'react';
import { ICatalog } from '../../../models/ELM/ICatalog';
import { IKeyValuePair } from '../../../models/ELM/IKeyValuePair';
import { isEmptyOrUndefined } from '../../../shared/isEmptyOrUndefined';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { FilterList } from '../../Shared/FilterList/FilterList';
import { IGrantFilterProps, IGrantFilterState } from './GrantFilter.types';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');

@withResponsiveMode
export class GrantFilter extends React.Component<
  IGrantFilterProps,
  IGrantFilterState
> {
  private _hasMounted: boolean;
  private _catalogSelection: Selection;
  private _catalogOptions: IKeyValuePair[];

  constructor(nextProps: IGrantFilterProps) {
    super(nextProps);
    this._hasMounted = false;
    this._catalogSelection = new Selection({
      onSelectionChanged: this._onSelectionChanged,
      selectionMode: SelectionMode.multiple
    });
    this._catalogOptions = [];
  }

  public componentWillReceiveProps(nextProps: IGrantFilterProps): void {
    if (this.props.hidden && !nextProps.hidden) {
      this._getCatalog();
      if (!isEmptyOrUndefined(nextProps.catalogList)) {
        this._catalogOptions = this._initCatalogOptions(nextProps.catalogList);
        this._updateCatalogSelection(
          this._catalogOptions,
          nextProps.selectedCatalogs
        );
      }
    }
    if (this.props.isCatalogsLoading && !nextProps.isCatalogsLoading) {
      this._catalogOptions = this._initCatalogOptions(nextProps.catalogList);
      this._updateCatalogSelection(
        this._catalogOptions,
        nextProps.selectedCatalogs
      );
    }
  }

  public componentDidMount(): void {
    this._hasMounted = true;
  }

  public render(): JSX.Element {
    const { t, isCatalogsLoading } = this.props;

    const showLoadMore =
      !this.props.catalogsPageMetaData.isAllEntitiesFullyCached &&
      !isCatalogsLoading;

    return (
      <div>
        <AppPanel
          isBlocking={false}
          isOpen={!this.props.hidden}
          onDismiss={this.props.onDismiss}
          type={PanelType.smallFixedFar}
          headerText={t(LocaleKeys.filter, {
            context: 'capitalize',
            count: 0
          })}
          onRenderFooterContent={this._onRenderFooterContent}
          closeButtonAriaLabel={t(LocaleKeys.cancel)}
          hasCloseButton={true}
        >
          <FilterList
            t={t}
            localizedLabel={t(LocaleKeys.catalog, {
              context: 'capitalize',
              count: 0
            })}
            selection={this._catalogSelection}
            options={this._catalogOptions}
            translateOptions={false}
            isLoadingOptions={isCatalogsLoading}
            showLoadMoreOptions={showLoadMore}
            onLoadMoreOptions={this._getCatalog}
          />
        </AppPanel>
      </div>
    );
  }

  private _initCatalogOptions(
    catalogs: ReadonlyArray<ICatalog>
  ): IKeyValuePair[] {
    return catalogs.map((catalog: ICatalog) => {
      const catalogWithKey: IKeyValuePair = {
        key: catalog.id,
        value: catalog.displayName!
      };
      return catalogWithKey;
    });
  }

  private _updateCatalogSelection(
    options: IObjectWithKey[],
    selectedCatalogs: string[] | undefined
  ): void {
    this._catalogSelection.setItems(options);
    if (selectedCatalogs === undefined) {
      this._catalogSelection.setAllSelected(false);
    } else {
      selectedCatalogs.forEach((catalogId: string) => {
        this._catalogSelection.setKeySelected(catalogId, true, true);
      });
    }
  }

  private _onSelectionChanged = (): void => {
    if (this._hasMounted) {
      this.forceUpdate();
      this._applyFilters();
    }
  }

  private _getResponsiveMode(): ResponsiveMode {
    let { responsiveMode } = this.props;
    if (responsiveMode === undefined) {
      responsiveMode = ResponsiveMode.large;
    }
    return responsiveMode;
  }

  private _onRenderFooterContent = (): JSX.Element => {
    const responsiveMode = this._getResponsiveMode();
    return (
      <div>
        <PrimaryButton
          onClick={this._resetFilter}
          text={this.props.t(LocaleKeys.reset, { context: 'capitalize' })}
        />
        {responsiveMode < ResponsiveMode.medium ? (
          <div className={css(myAccessStyles.marginBottomMedium)} />
        ) : null}
      </div>
    );
  }

  private _applyFilters = (): void => {
    const selectedCatalogs: string[] = Array.from(
      this._catalogSelection.getSelection(),
      (catalog: IObjectWithKey) => catalog.key!.toString()
    );
    const filter =
      this._catalogSelection.isAllSelected() || selectedCatalogs.length === 0
        ? undefined
        : {
            selectedCatalogs: selectedCatalogs as string[]
          };
    if (this.props.pageMetaData.isAllEntitiesFullyCached) {
      this.props.filterEntities(filter);
    } else {
      this.props.filterEntitiesOnServer(filter);
    }
  }

  private _resetFilter = (): void => {
    this._catalogSelection.setAllSelected(false);
  }

  private _getCatalog = (): void => {
    if (this.props.catalogsPageMetaData.isAllEntitiesFullyCached) {
      return;
    }
    this.props.getCatalogs();
  }
}
