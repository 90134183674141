export enum OriginSystem {
  AadGroup = 'aadGroup',
  DirectoryRole = 'directoryRole',
  SharePointOnline = 'sharepointOnline',
  AadApplication = 'aadApplication',
  AadAuthorization = 'aadAuthorization',
  Dynamics365 = 'dynamics365',
  License = 'license'
}

export const msGraphNameToOriginSystemMap: Map<string, OriginSystem> = new Map<string, OriginSystem>([
  ['AadGroup', OriginSystem.AadGroup],
  ['DirectoryRole', OriginSystem.DirectoryRole],
  ['SharePointOnline', OriginSystem.SharePointOnline],
  ['AadApplication', OriginSystem.AadApplication],
  ['AadAuthorization', OriginSystem.AadAuthorization],
  ['Dynamics365', OriginSystem.Dynamics365],
  ['License', OriginSystem.License]
]);

export const originSystemMap: Map<number, OriginSystem> = new Map<number, OriginSystem>([
  [0, OriginSystem.AadGroup],
  [2, OriginSystem.DirectoryRole],
  [3, OriginSystem.SharePointOnline],
  [4, OriginSystem.AadApplication],
  [5, OriginSystem.AadAuthorization],
  [9, OriginSystem.Dynamics365]
]);
