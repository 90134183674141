import type { IStyle } from '@fluentui/react';

export interface ILayoutStyles {
  pageContent: IStyle;
  title: IStyle;
  content: IStyle;
  mobileContent: IStyle;
}

export const layoutStyles: ILayoutStyles = {
  pageContent: {
    position: 'relative',
    flex: '1',
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    paddingTop: '8px',
    paddingLeft: '12px'
  },
  content: {
    paddingLeft: '32px',
    flex: '1',
    display: 'flex',
    flexDirection: 'column'
  },
  mobileContent: {
    padding: '0 16px',
    display: 'flex',
    flexFlow: 'column'
  }
};
