import { IEntity } from '../models/ELM/IEntity';
import { IListColumn } from '../models/IListColumn';

export const getNewColumnsOnColumnClicked = (
  columns: IListColumn<IEntity>[],
  clickedColumn: IListColumn<IEntity>
): IListColumn<IEntity>[] => {
  columns.forEach((col: IListColumn<IEntity>) => {
    if (col.key === clickedColumn.key) {
      col.isSorted = true;
      col.isSortedDescending = !col.isSortedDescending;
    } else {
      col.isSorted = false;
    }
  });

  return columns;
};
