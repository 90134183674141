import { EntitlementActions } from '../../../models/EntitlementActions';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState } from '../../../models/IEntitlementState';
import { registry } from '../myAccessRegistry';
import { notificationsMerge } from '@microsoft/portal-app/lib/Notifications/helpers/notificationsMerge';
import {
  INotification,
  NotificationSeverity,
  NotificationType
} from '@microsoft/portal-app/lib/Notifications/models/INotification';
import { TranslationOptions } from 'i18next';
import * as moment from 'moment';
import { v4 as guid } from 'uuid';

export const showCopyNotification = (
  state: IEntitlementState,
  _action: IEntitlementAction<Readonly<any>>
): Readonly<IEntitlementState> => {
  const toastOptions: TranslationOptions = {};
  const notification = {
    localizableTitle: {
      key: 'copied',
      options: toastOptions
    },
    localizableMessage: {
      key: 'copyLinkMessage',
      options: toastOptions
    },
    createdDateTime: moment(),
    severity: NotificationSeverity.success
  };

  let notifications: INotification[] = [
    {
      ...notification,
      id: guid(),
      type: NotificationType.card
    },
    {
      ...notification,
      id: guid(),
      type: NotificationType.toast
    }
  ];

  return {
    ...state,
    notifications: notificationsMerge(notifications, state.notifications, state.notificationsLimit)
  };
};
registry.add(EntitlementActions.showCopyNotification, showCopyNotification);
