import { css, DefaultButton, Dialog, DialogFooter, DialogType, ResponsiveMode } from '@fluentui/react';
import { useResponsiveMode } from '@uifabric/portal-ux/lib/styling/useResponsiveMode';
import React, { useEffect, useState } from 'react';

import { useTelemetryWithMetadata, useTranslation } from '../../../hooks';
import { LocaleKeys } from '../../../shared';

// TODO: Augusto is working on a new way to import scss files, ignore for now
// eslint-disable-next-line @typescript-eslint/no-var-requires
const ramDialogStyles = require('../Styles/ram.dialog.scss');
const ramManualApproveMessageStyles = require('./ram.manualApproveMessage.scss');

const manualApproveMessageDialogStyles = {
  [ResponsiveMode.small]: ramDialogStyles.dialogSmall,
  [ResponsiveMode.medium]: ramDialogStyles.dialogMedium,
  [ResponsiveMode.large]: ramDialogStyles.dialogLarge,
  [ResponsiveMode.xLarge]: ramDialogStyles.dialogXLarge,
  [ResponsiveMode.xxLarge]: ramDialogStyles.dialogXXLarge,
  [ResponsiveMode.xxxLarge]: ramDialogStyles.dialogXXXLarge
};

export interface ManualApproveMessageDialogProps {
  virtualMachineName: string;
  onDismiss: VoidFunction;
}

export const ManualApproveMessageDialog: React.FunctionComponent<ManualApproveMessageDialogProps> = (
  props: ManualApproveMessageDialogProps
) => {
  const t = useTranslation();
  const { reportCustomEventWithMetadata } = useTelemetryWithMetadata();
  const responsiveMode = useResponsiveMode();

  const [containerClassName, setContainerClassName] = useState<string>(
    manualApproveMessageDialogStyles[responsiveMode]
  );

  useEffect(() => {
    setContainerClassName(manualApproveMessageDialogStyles[responsiveMode]);
  }, [responsiveMode]);

  useEffect(() => {
    reportCustomEventWithMetadata('ram/journey/3/mannualApprove');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dialogContentProps = {
    type: DialogType.close,
    title: t(LocaleKeys.ramManualApproverMessageDialogTitle, { virtualMachineName: props.virtualMachineName })
  };

  return (
    <Dialog
      hidden={false}
      onDismiss={props.onDismiss}
      dialogContentProps={dialogContentProps}
      modalProps={{ isBlocking: true, containerClassName }}
    >
      <div className={css(ramManualApproveMessageStyles.center)}>
        <img src="/imgs/paperAirplane.svg" alt="paper airplane" />
        <strong>{t(LocaleKeys.ramManualApproverMessageDialogBoldText)}</strong>
        {t(LocaleKeys.ramManualApproverMessageDialogMessageText)}
      </div>
      <DialogFooter>
        <DefaultButton
          onClick={() => {
            props.onDismiss();
          }}
          text={t(LocaleKeys.ramManualApproverMessageDialogCloseButtonText)}
        />
      </DialogFooter>
    </Dialog>
  );
};
