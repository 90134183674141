import { IAjaxCreationMethod } from '@microsoft/portal-app/lib/auth';
import { IAction } from '@microsoft/portal-app/lib/redux';
import { addAjaxEpic, getSuccessActionType } from '@microsoft/portal-app/lib/redux/createAjaxEpic';
import { getCurrentOrganizationUrl } from '@microsoft/portal-app/lib/User/redux/userApi';
import { EntitlementActions, IEntitlementState } from '../../../models';
import { IOrganizationInfo, IAssignedPlan } from '../../../models/Header/interfaces';
import { registry } from '../myAccessRegistry';

const P2_SERVICE_PLAN_ID = 'eec0eb4f-6444-4f95-aba0-50c24d67f998';

export const getCurrentOrganizationAjax = (ajax: IAjaxCreationMethod) => {
  return ajax.getJSON<IOrganizationInfo>(getCurrentOrganizationUrl());
};

export const getCurrentOrganizationResponse = (
  state: IEntitlementState,
  action: IAction<{ value: IOrganizationInfo[] }>
): IEntitlementState => {
  if (!action.payload) {
    return state;
  }

  const result = action.payload.value;
  if (result.length > 0) {
    const orgDisplayName = result[0].displayName;
    const orgId = result[0].id;
    const hasP2License = result[0].assignedPlans.some((ap: IAssignedPlan) => ap.servicePlanId === P2_SERVICE_PLAN_ID);

    return {
      ...state,
      currentOrgId: orgId,
      currentOrgDisplayName: orgDisplayName,
      hasP2License
    };
  }

  return state;
};

addAjaxEpic(registry, EntitlementActions.getCurrentOrganization, getCurrentOrganizationAjax, {
  showErrorAsToast: false
});

registry.add(getSuccessActionType(EntitlementActions.getCurrentOrganization), getCurrentOrganizationResponse);
