import { LoadingState } from '@microsoft/portal-app/lib/models/ILoading';
import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { translate } from 'react-i18next';
import { connect, Dispatch } from 'react-redux';
import { EntitlementActions } from '../../../models';
import { IRootEntitlementsState } from '../../../models/IEntitlementState';
import { DiagnosticsPanel } from './DiagnosticsPanel';
import {
  IConnectedDiagnosticsPanelProps,
  IDiagnosticsPanelActions,
  IDiagnosticsPanelMappedProps
} from './DiagnosticsPanel.types';

/**
 * Maps the external (i.e. own) props and the state to the properties of the DiagnosticsPanel control.
 * @param state The current redux state to map to props
 * @param ownProps The external properties to map to props
 * @returns The mapped properties that along with the actions
 * becomes the props (IDiagnosticsPanelProps) for the DiagnosticsPanel control
 */
const mapStateToProps = (
  state: IRootEntitlementsState,
  ownProps: IConnectedDiagnosticsPanelProps
): IDiagnosticsPanelMappedProps => {
  const loadableMember = state.app.member;
  const isLoading = loadableMember && loadableMember.isLoading!;
  const member = loadableMember && loadableMember.value;
  const errorHasOccurred =
    loadableMember && loadableMember.loadingState === LoadingState.error;

  return {
    ...ownProps,
    isLoading: isLoading!,
    member: member!,
    errorHasOccurred: errorHasOccurred!
  };
};

/**
 * Maps the dispatch actions to the props for the DiagnosticsPanel control.
 * @param dispatch Dispatches actions to the store
 * @returns The mapped actions that along with the mapped properties
 * becomes the props (IDiagnosticsPanelProps) for the DiagnosticsPanel control
 */
const mapDispatchToProps = (
  dispatch: Dispatch<IRootEntitlementsState>
): IDiagnosticsPanelActions => {
  return {
    getMember: createAction(EntitlementActions.getMember, dispatch)
  };
};

/**
 * A redux connected DiagnosticsPanel control.
 * @description Supports translation
 */
export const ConnectedDiagnosticsPanel = connect(
  mapStateToProps,
  mapDispatchToProps
  // tslint:disable-next-line:no-any
)(translate('MyAccess')(DiagnosticsPanel) as any);
