import {
  css,
  DefaultButton,
  DocumentCard,
  DocumentCardImage,
  DocumentCardTitle,
  DocumentCardType,
  Icon,
  ImageFit,
  Link,
  ResponsiveMode} from '@fluentui/react';
import { useResponsiveMode } from '@uifabric/portal-ux/lib/styling/useResponsiveMode';
import * as React from 'react';
const overviewCardStyle = require('../../../css/overview.scoped.scss');
const myAccessStyles = require('../../../css/myAccess.scoped.scss');

export interface IOverviewLearningCardProps {
  title: string;
  cardImageUrl: string;
  cardDescription: string;
  defaultButtonLabel?: string;
  linkLabel?: string;
  linkUrl?: string;
  defaultButtonAction?: () => void;
  handleLinkClick? : () => void;
}

export function OverviewLearningCard(props: IOverviewLearningCardProps) {
  const responsiveMode = useResponsiveMode();
  const isMobile = responsiveMode < ResponsiveMode.large;

  return (
    <DocumentCard
      className={overviewCardStyle.learningCardNormal}
      type={isMobile ? DocumentCardType.normal : DocumentCardType.compact}
      aria-label={props.title}
    >
      <div className={overviewCardStyle.learningCardImage}>
        <DocumentCardImage
          imageFit={ImageFit.cover}
          imageSrc={props.cardImageUrl}
        />
      </div>
      <div className={overviewCardStyle.learningCardContent}>
        <div>
          <DocumentCardTitle
            className={overviewCardStyle.cardTitle}
            title={props.title}
          />
          <div className={css(myAccessStyles.marginLeftSmall, myAccessStyles.marginRightSmall, myAccessStyles.marginBottomSmall)}>
            {props.cardDescription}
          </div>
        </div>
        <div className={overviewCardStyle.learningCardBottom}>
          {props.defaultButtonLabel &&
          <DefaultButton
            className={css(overviewCardStyle.boldText, myAccessStyles.marginRightSmall)}
            key="open"
            onClick={props.defaultButtonAction}
          >
            {props.defaultButtonLabel}
          </DefaultButton>
          }
          {props.linkLabel &&
          <Link key="learn-more" href={props.linkUrl} aria-labelledby="openInNewTab" target="_blank" onClick={props.handleLinkClick}>
            {props.linkLabel}
          <Icon
            id="openInNewTab"
            iconName="OpenInNewTab"
            aria-label="External link, opens in new tab"
            className={myAccessStyles.marginLeftXSmall}
          />
          </Link>
          }
        </div>
      </div>
    </DocumentCard>
  );
}
