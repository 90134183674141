import { translate } from 'react-i18next';
import { connect, Dispatch } from 'react-redux';

import { createAction } from '@microsoft/portal-app/lib/redux/createAction';

import { EntitlementActions } from '../../../models';
import { IRootEntitlementsState } from '../../../models/IEntitlementState';
import { CatalogsPage } from './CatalogsPage';
import {
  ICatalogsPageActions,
  ICatalogsPageStateProps,
  IConnectedCatalogsPageProps,
} from './CatalogsPage.types';

const mapStateToProps = (
  state: IRootEntitlementsState
): ICatalogsPageStateProps => {
  const { catalogs, searchTerm } = state.app;

  return {
    catalogs,
    searchTerm,
    features: state.app.features.isEnabled
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<IRootEntitlementsState>
): ICatalogsPageActions => {
  return {
    setSearchContext: createAction<string | null>(
      EntitlementActions.setSearchContext,
      dispatch
    ),
    showSearch: createAction<boolean>(
      EntitlementActions.showSearchBox,
      dispatch
    ),
  };
};

/**
 * The connected component.
 */
export const ConnectedCatalogsPage = connect<
  ICatalogsPageStateProps,
  ICatalogsPageActions,
  IConnectedCatalogsPageProps,
  IRootEntitlementsState
>(
  mapStateToProps,
  mapDispatchToProps
)(translate('MyAccess')(CatalogsPage));
