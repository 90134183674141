import { getUserFromAuth } from './authHelper';

export const getOneLayerUp = (): string => {
  const currentHash = location.hash.substr(1);
  const splits = currentHash.split('/');
  let url = '';
  if (splits && splits.length > 1) {
    splits.pop();
    splits.forEach((split: string) => {
      url += `${split}/`;
    });
  }
  return url;
};

export const getTenantInfo = (first): string => {
  const user = getUserFromAuth();
  const aid = first ? '?' : '&';
  const tid = user.tenantId;
  return tid ? `${aid}x-tenantid=${tid}` : '';
};
