export enum DecisionType {
  Approve = 'Approve',
  Deny = 'Deny',
  DontKnow = 'DontKnow',
  NotReviewed = 'NotReviewed',
  AcceptRecommendation = 'AcceptRecommendation',
  Revoke = 'Revoke',
  AccessRemoved = 'AccessRemoved'
}

export enum DecisionFilterType {
  Approve = 'approved',
  Deny = 'denied',
  DontKnow = 'dontKnow',
  NotReviewed = 'notReviewed'
}

export enum DecisionResourceType {
  User = 'user',
  Group = 'group',
  ServicePrincipal = 'servicePrincipal'
}

export enum ReviewDecisionSubjectType {
  User = 'User',
  Group = 'Group',
  ServicePrincipal = 'ServicePrincipal',
  UserAndGroup = 'User,Group'
}

export enum DecisionCriteriaTypeId {
  RBACScope = 'deb8e8e3-9575-4bcc-b9ff-8f7e98635e4b', // PIMARMAllRoleAssignmentCriteria
  UserCentric = 'e0a240cd-752c-422b-8068-bdd370a272b8',// PimGovernanceAllRoleAssignmentCriteria
}

export enum ReviewScopeResourceType {
  Subscription = 'subscription',
  ResourceGroup = 'resourceGroup',
  ManagementGroup = 'managementGroup'
}