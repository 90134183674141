import { ColorClassNames } from '@fluentui/react';
import { css } from '@fluentui/react';
import {
  IWithResponsiveModeState,
  withResponsiveMode
} from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import * as React from 'react';
import { InjectedTranslateProps, TranslationFunction } from 'react-i18next';
import { IGrantRequest } from '../../../models';
import * as ApprovalHelper from '../../../shared/approvalPersonaHelper';
import { FormatDateTime } from '../../../shared/FormatDateTime';
import { LocaleKeys } from '../../../shared/LocaleKeys';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');

export interface IGrantRequestDueDateInfoProps
  extends IWithResponsiveModeState,
    InjectedTranslateProps {
  grantRequest: IGrantRequest | undefined;
  t: TranslationFunction;
}

@withResponsiveMode
export class GrantRequestDueDateInfo extends React.Component<IGrantRequestDueDateInfoProps> {
  constructor(nextProps: IGrantRequestDueDateInfoProps) {
    super(nextProps);
  }

  public render(): JSX.Element {
    const { t, grantRequest } = this.props;

    if (!grantRequest) {
      return <div />;
    }

    const dueDate = ApprovalHelper.getDueDate(grantRequest);
    if (dueDate) {
      return (
        <div
          className={css(
            'ms-pii',
            ColorClassNames.red,
            myAccessStyles.marginBottomXSmall
          )}
        >
          {t(LocaleKeys.due)} {FormatDateTime(dueDate)}
        </div>
      );
    }
    return <div />;
  }
}
