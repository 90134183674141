import { Observable } from 'rxjs/Observable';
import { filter, mergeMap } from 'rxjs/operators';

import { IGrantRequest } from '../../../models/ELM/IGrantRequest';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { registry } from '../myAccessRegistry';
import { createTelemetryAction, ofType } from './';

export const addGrantRequestTelemetryEpic = registry.addEpic(
  'addGrantRequestTelemetry',
  (action$, _store) =>
    action$.pipe(
      ofType(EntitlementActions.addGrantRequest),
      filter(
        (action) =>
          action.payload &&
          action.payload.newGrantRequest &&
          action.payload.newGrantRequest.requestType
      ),
      mergeMap((action) => {
        const {
          newGrantRequest,
        }: { newGrantRequest: IGrantRequest } = action.payload;
        const isValidation = newGrantRequest.isValidationOnly
          ? 'validate'
          : 'submit';

        const numQuestionsAnswered = newGrantRequest.answers
          ? newGrantRequest.answers.length
          : 0;
        const metadata = { numQuestionsAnswered };

        return Observable.of(
          createTelemetryAction(
            `${action.type}/${newGrantRequest.requestType}/${isValidation}`,
            metadata
          )
        );
      })
    )
);
