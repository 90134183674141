export enum LocaleKeys {
  aadRole = 'aadRole',
  accept = 'accept',
  acceptAllRecommendations = 'acceptAllRecommendations',
  acceptAllRecommendationsRedirect = 'acceptAllRecommendationsRedirect',
  acceptOurRecommendations = 'acceptOurRecommendations',
  acceptRecommendations = 'acceptRecommendations',
  acceptRecommendationsForApprovalSelected = 'acceptRecommendationsForApprovalSelected',
  acceptRecommendationsMessage = 'acceptRecommendationsMessage',
  acceptRecommendationsUnavailable = 'acceptRecommendationsUnavailable',
  accessDates = 'accessDates',
  accessDenied = 'accessDenied',
  accessExtension = 'accessExtension',
  accessPackage = 'accessPackage',
  accessPackageAssignment = 'accessPackageAssignment',
  accessPackageCount = 'accessPackageCount',
  accessPackageGuideString = 'accessPackageGuideString',
  accessPackageRecommendationGuideString = 'accessPackageRecommendationGuideString',
  accessPackagePivot = 'accessPackagePivot',
  accessPackageReviewsGridAriaLabel = 'accessPackageReviewsGridAriaLabel',
  accessRequest = 'accessRequest',
  accessReview = 'accessReview',
  accessReviewApproval = 'accessReviewApproval',
  accessReviewsAvailabilityBannerMessage = 'accessReviewsAvailabilityBannerMessage',
  accessReviewDecisionSelectAll = 'accessReviewDecisionSelectAll',
  accessReviewDecisionSelectAllTitle = 'accessReviewDecisionSelectAllTitle',
  accessReviewDecisionWarning = 'accessReviewDecisionWarning',
  accessReviewDenial = 'accessReviewDenial',
  accessReviewDontKnows = 'accessReviewDontKnows',
  accessReviewRecommendationWarning = 'accessReviewRecommendationWarning',
  accessReviews = 'accessReviews',
  accessReviewsGridAriaLabel = 'accessReviewsGridAriaLabel',
  accessReviewsPermission = 'accessReviewsPermission',
  accessReviewsPrincipalType = 'accessReviewsPrincipalType',
  accessReviewsProgress = 'accessReviewsProgress',
  accessReviewsRecurring = 'accessReviewsRecurring',
  accessReviewsType = 'accessReviewsType',
  accessReviewsResourceType = 'accessReviewsResourceType',
  accessReviewsResourceTypeCount = 'accessReviewsResourceTypeCount',
  accessReviewsResourceName = 'accessReviewsResourceName',
  accessReviewsResourceNameCount = 'accessReviewsResourceNameCount',
  accessReviewsResourceAssignmentType = 'accessReviewsResourceAssignmentType',
  accessReviewsResourceScope = 'accessReviewsResourceScope',
  accessReviewSubmitDecisionFailed = 'accessReviewSubmitDecisionFailed',
  accessReviewSubmitDecisionSucceeded = 'accessReviewSubmitDecisionSucceeded',
  accessReviewSubmitDecisionSucceededMessage = 'accessReviewSubmitDecisionSucceededMessage',
  accessReviewWalkthrough = 'accessReviewWalkthrough',
  accessRemoved = 'accessRemoved',
  accessTo = 'accessTo',
  action = 'action',
  actions = 'actions',
  activeReviews = 'activeReviews',
  actorApprovedThis = 'actorApprovedThis',
  actorDeniedThis = 'actorDeniedThis',
  actorRemovedThis = 'actorRemovedThis',
  actorMarkedDontKnow = 'actorMarkedDontKnow',
  additionalInformation = 'additionalInformation',
  additionalQuestions = 'additionalQuestions',
  additionalReviewers = 'additionalReviewers',
  all = 'all',
  allApprovalSelectedOverride = 'allApprovalSelectedOverride',
  allResources = 'allResources',
  app = 'app',
  application = 'application',
  approval = 'approval',
  approvalDetails = 'approvalDetails',
  approvalHistory = 'approvalHistory',
  approvalSelected = 'approvalSelected',
  approvalsWithCount = 'approvalsWithCount',
  approvalUpper = 'approvalUpper',
  approve = 'approve',
  approveAccess = 'approveAccess',
  approved = 'approved',
  approveOrDenyNow = 'approveOrDenyNow',
  approver = 'approver',
  approvers = 'approvers',
  approverGroup = 'approverGroup',
  authorization = 'authorization',
  available = 'available',
  awaitingRequest = 'awaitingRequest',
  azureAd = 'azureAd',
  azureRbac = 'azureRbac',
  back = 'back',
  backToAccessPackages = 'backToAccessPackages',
  beforeReviewLess = 'beforeReviewLess',
  beforeReviewMore = 'beforeReviewMore',
  browseLibrary = 'browseLibrary',
  bulkApproveMessage = 'bulkApproveMessage',
  bulkDenyMessage = 'bulkApproveMessage',
  businessJustification = 'businessJustification',
  button = 'button',
  byod = 'byod',
  cancel = 'cancel',
  cancelMyRequest = 'cancelRequest',
  cancelMyRequestConfirmMessage = 'cancelMyRequestConfirmMessage',
  cancelRequestFailed = 'cancelRequestFailed',
  cancelRequestSucceeded = 'cancelRequestSucceeded',
  cancelRequestSucceededMessage = 'cancelRequestSucceededMessage',
  cantScanImage = 'cantScanImage',
  cantScanInstruction = 'cantScanInstruction',
  cantScanQRCode = 'cantScanQRCode',
  catalog = 'catalog',
  catalogNameAriaLabel = 'catalogNameAriaLabel',
  catalogUpper = 'catalogUpper',
  changeDecision = 'changeDecision',
  changeOrUpdateDecision = 'changeOrUpdateDecision',
  chooseAPolicy = 'chooseAPolicy',
  clickHere = 'clickHere',
  close = 'close',
  collapse = 'collapse',
  collapsedResources = 'collapsedResources',
  complete = 'complete',
  completed = 'completed',
  completedApproval = 'completedApproval',
  completedReview = 'completedReview',
  completeWithinTimeLimit = 'completeWithinTimeLimit',
  consentForm = 'consentForm',
  consentSentence = 'consentSentence',
  continue = 'continue',
  continueAccess = 'continueAccess',
  continueAccessByod = 'continueAccessByod',
  continueStep = 'continueStep',
  copied = 'copied',
  copy = 'copy',
  copyLink = 'copyLink',
  copyLinkTip = 'copyLinkTip',
  ctaMoreAriaDescription = 'ctaMoreAriaDescription',
  currentDecision = 'currentDecision',
  dataProvider = 'dataProvider',
  dateRequested = 'dateRequested',
  decision = 'decision',
  decisionApproved = 'decisionApproved',
  decisionBy = 'decisionBy',
  decisionDenied = 'decisionDenied',
  decisionDontKnow = 'decisionDontKnow',
  decisionHistory = 'decisionHistory',
  decisionOverrideConfirmation = 'decisionOverrideConfirmation',
  decisionRecommended = 'decisionRecommended',
  decisionStatus = 'decisionStatus',
  decisionType = 'decisionType',
  decisionWillOverride = 'decisionWillOverride',
  deliveringFailedMessage = 'deliveringFailedMessage',
  deliveringPackages = 'deliveringPackages',
  denialsWithCount = 'denialsWithCount',
  denied = 'denied',
  deny = 'deny',
  denyAccess = 'denyAccess',
  description = 'description',
  details = 'details',
  detailsNotAvailable = 'detailsNotAvailable',
  diagnostics = 'diagnostics',
  directoryRole = 'directoryRole',
  directReports = 'directReports',
  discard = 'discard',
  discardChanges = 'discardChanges',
  dontKnow = 'dontKnow',
  downloadAuthenticator = 'downloadAuthenticator',
  downloadMicrosoftAuthenticator = 'downloadMicrosoftAuthenticator',
  due = 'due',
  dueBy = 'dueBy',
  dueSoon = 'dueSoon',
  dynamics365 = 'dynamics365',
  editAnswers = 'editAnswers',
  empty = '',
  endAccess = 'endAccess',
  entityNameLabel = 'entityNameLabel',
  entityNameWithCount = 'entityNameWithCount',
  entityNameWithCountRev = 'entityNameWithCountRev',
  entityNameWithCountSelected = 'entityNameWithCountSelected',
  entityNameWithReviewedCount = 'entityNameWithReviewedCount',
  errorMessage = 'errorMessage',
  errorTemplate = 'errorTemplate',
  escalationMessageAfterDue = 'escalationMessageAfterDue',
  escalationMessageAlternateAdded = 'escalationMessageAlternateAdded',
  escalationMessageBeforeDue = 'escalationMessageBeforeDue',
  escalationMessageForwarded = 'escalationMessageForwarded',
  expand = 'expand',
  expanded = 'expanded',
  expiredResourcesMessage = 'expiredResourcesMessage',
  expired = 'expired',
  expiresOn = 'expiresOn',
  expiringPackage = 'expiringPackage',
  extend = 'extend',
  extendAccess = 'extendAccess',
  extendAccessPackageButtonAria = 'extendAccessPackageButtonAria',
  extendDisabledMessage = 'extendDisabledMessage',
  filter = 'filter',
  filteringSubtitle = 'filteringSubtitle',
  filterUpper = 'filterUpper',
  findPackages = 'findPackages',
  finishEditing = 'finishEditing',
  firstApproverWithoutStageCount = 'firstApproverWithoutStageCount',
  firstApproverWithStageCount = 'firstApproverWithStageCount',
  generalErrorMessage = 'generalErrorMessage',
  generateNewCode = 'generateNewCode',
  getReviewerDisplayNamesFailed = 'getReviewerDisplayNamesFailed',
  grantExistsMessage = 'grantExistsMessage',
  group = 'group',
  groupsAndTeam = 'groupsAndTeam',
  groupsApps = 'groupsApps',
  groupAndAppMembership = 'groupAndAppMembership',
  history = 'history',
  identity = 'identity',
  includes = 'includes',
  incompatibleGroupMemberships = 'incompatibleGroupMemberships',
  informationProvidedByUser = 'informationProvidedByUser',
  inProgressText = 'inProgressText',
  inProductFeedback = 'inProductFeedback',
  insight = 'insight',
  insightActive = 'insightActive',
  insightCardInactive = 'insightCardInactive',
  insightCardOutlier = 'insightCardOutlier',
  insightInactive = 'insightInactive',
  insightNotOutlier = 'insightNotOutlier',
  insightOutlier = 'insightOutlier',
  invalidField = 'invalidField',
  invalidSODCheck = 'invalidSODCheck',
  isThisUseful = 'isThisUseful',
  justificationNotEmpty = 'justificationNotEmpty',
  keepexisting = 'keepexisting',
  lastSignedIn = 'lastSignedIn',
  lastSignIn = 'lastSignIn',
  lastSignInUnknown = 'lastSignInUnknown',
  learningCardAccessReviewDescription = 'learningCardAccessReviewDescription',
  learningCardAccessReviewTitle = 'learningCardAccessReviewTitle',
  learningCardApprovalRequestDescription = 'learningCardApprovalRequestDescription',
  learningCardApprovalRequestTitle = 'learningCardApprovalRequestTitle',
  learningCardRequestPackageDescription = 'learningCardRequestPackageDescription',
  learningCardRequestPackageTitle = 'learningCardRequestPackageTitle',
  learnMore = 'learnMore',
  learnMoreWithOur = 'learnMoreWithOur',
  loading = 'loading',
  loadingPage = 'loadingPage',
  loadMore = 'loadMore',
  makeDecision = 'makeDecision',
  managementGroup = 'managementGroup',
  manualRefresh = 'manualRefresh',
  microsoftVerifiedIDs = 'microsoftVerifiedIDs',
  missPolicyRequirements = 'missPolicyRequirements',
  more = 'more',
  multiple = 'multiple',
  myAccess = 'myAccess',
  myAccessOverview = 'myAccessOverview',
  myself = 'myself',
  name = 'name',
  new = 'new',
  next = 'next',
  no = 'no',
  noAccessReviewsMessage = 'noAccessReviewsMessage',
  noCompletedApprovalsMessage = 'noCompletedApprovalsMessage',
  noDirectReports = 'noDirectReports',
  noEndDate = 'noEndDate',
  noEntitlementsMessage = 'noEntitlementsMessage',
  noExpiredGrantsMessage = 'noExpiredGrantsMessage',
  noFilteredResultsMessage = 'noFilteredResultsMessage',
  noHistoryInApprovalRequest = 'noHistoryInApprovalRequest',
  noInfo = 'noInfo',
  noPendingAction = 'noPendingAction',
  noPendingApprovalsMessage = 'noPendingApprovalsMessage',
  noPermissionsInAccessPackage = 'noPermissionsInAccessPackage',
  noPermissionToViewAccessPackage = 'noPermissionToViewAccessPackage',
  noRecommendedAccessPackageMessage = 'noRecommendedAccessPackageMessage',
  noRecommendedAccessPackageSubMessage = 'noRecommendedAccessPackageSubMessage',
  noRequestSelected = 'noRequestSelected',
  noRequestsMessage = 'noRequestsMessage',
  noResourcesHere = 'noResourcesHere',
  normalPendingApprovalMessage = 'normalPendingApprovalMessage',
  notAvailable = 'notAvailable',
  notReviewed = 'notReviewed',
  notReviewedApprovalSelected = 'notReviewedApprovalSelected',
  notSpecified = 'notSpecified',
  noUsers = 'noUsers',
  noValidGrantsMessage = 'noValidGrantsMessage',
  of = 'of',
  open = 'open',
  openResourceAria = 'openResourceAria',
  other = 'other',
  override = 'override',
  overviewActionTitle = 'overviewActionTitle',
  overviewExpiringGrantDetail = 'overviewExpiringGrantDetail',
  overviewLearningTitle = 'overviewLearningTitle',
  overviewUpper = 'overviewUpper',
  overviewTryAgain = 'overviewTryAgain',
  package = 'package',
  packageDetails = 'packageDetails',
  packageRequest = 'packageRequest',
  partnerNotFoundMessage = 'partnerNotFoundMessage',
  peerAccess = 'peerAccess',
  pending = 'pending',
  pendingAccessReview = 'pendingAccessReview',
  pendingApproval = 'pendingApproval',
  pendingApprovalsExcludingSelf = 'pendingApprovalsExcludingSelf',
  pendingRemoval = 'pendingRemoval',
  peopleCount = 'peopleCount',
  peopleSingular = 'peopleSingular',
  permissionsNotVisibleMessage = 'permissionsNotVisibleMessage',
  pimArBannerMessage = 'pimArBannerMessage',
  pleaseReview = 'pleaseReview',
  pleaseReviewMultiEntities = 'pleaseReviewMultiEntities',
  pleaseReviewNoEntity = 'pleaseReviewNoEntity',
  pleaseReviewPrincipleAzureRole = 'pleaseReviewPrincipleAzureRole',
  policyExpiredMessage = 'policyExpiredMessage',
  policyTooltip = 'policyTooltip',
  preparingGrantMessage = 'preparingGrantMessage',
  presentVerifiedId = 'presentVerifiedId',
  previousDecisionsChanged = 'previousDecisionsChanged',
  previousStageDecision = 'previousStageDecision',
  previous = 'previous',
  principal = 'principal',
  privacyStatement = 'privacyStatement',
  provideJustification = 'provideJustification',
  provideReason = 'provideReason',
  readHowToGuide = 'readHowToGuide',
  reason = 'reason',
  reasonNotEmpty = 'reasonNotEmpty',
  recommendApprove = 'recommendApprove',
  recommended = 'recommended',
  recommendedAccessPackages = 'recommendedAccessPackages',
  recommendation = 'recommendation',
  recommendationBaseOn = 'recommendationBaseOn',
  recommendationsSortBy = 'recommendationsSortBy',
  recommendationsInfo = 'recommendationsInfo',
  recommendationsInfoExpanded = 'recommendationsInfoExpanded',
  recommendationTag = 'recommendationTag',
  recommendationTooltip = 'recommendationTooltip',
  recommendDeny = 'recommendDeny',
  recommendSecondary = 'recommendSecondary',
  refresh = 'refresh',
  remove = 'remove',
  removed = 'removed',
  removeAccess = 'removeAccess',
  removeAccessConfirmation = 'removeAccessConfirmation',
  removeAccessApproverConfirmation = 'removeAccessApproverConfirmation',
  removeFilterAction = 'removeFilterAction',
  removeRequest = 'removeRequest',
  removingAccessMessage = 'removingAccessMessage',
  renew = 'renew',
  renewAccess = 'renewAccess',
  renewAccessPackageButtonAria = 'renewAccessPackageButtonAria',
  request = 'request',
  requestAccess = 'requestAccess',
  requestAccessLabel = 'requestAccessLabel',
  requestAccessPackageButtonAria = 'requestAccessPackageButtonAria',
  requestActivity = 'requestActivity',
  requestDetails = 'requestDetails',
  requestDetailsAriaLabel = 'requestDetailsAriaLabel',
  requestDetailsSynopsis = 'requestDetailsSynopsis',
  requestDetailsSynopsisWithTarget = 'requestDetailsSynopsisWithTarget',
  requestDueBy = 'requestDueBy',
  requestDurationOutsidePolicyRange = 'requestDurationOutsidePolicyRange',
  requestedBy = 'requestedBy',
  requestedFor = 'requestedFor',
  requestedOn = 'requestedOn',
  requestedPackage = 'requestedPackage',
  requestEndDate = 'requestEndDate',
  requestExistsMessage = 'requestExistsMessage',
  requestForSpecificPeriod = 'requestForSpecificPeriod',
  requestHistory = 'requestHistory',
  requestingFor = 'requestingFor',
  requestingOBOMessage = 'requestingOBOMessage',
  requestStartDate = 'requestStartDate',
  requestState = 'status',
  requestSubmitted = 'requestSubmitted',
  requestSubmittedOn = 'requestSubmittedOn',
  required = 'required',
  reset = 'reset',
  resetAllDecisions = 'resetAllDecisions',
  resetDecisions = 'resetDecisions',
  resource = 'resource',
  resourceCount = 'resourceCount',
  resourceGroup = 'resourceGroup',
  resourceNoDescription = 'resourceNoDescription',
  resources = 'resources',
  resourceScope = 'resourceScope',
  resourceScopeNameTypeMessage = 'resourceScopeNameTypeMessage',
  resourcesNotVisible = 'resourcesNotVisible',
  resourceType = 'resourceType',
  resourceTypeSpace = 'resourceTypeSpace',
  resourceView = 'resourceView',
  resubmit = 'resubmit',
  retry = 'retry',
  review = 'review',
  reviewAndModify = 'reviewAndModify',
  reviewDeleted = 'reviewDeleted',
  reviewedBy = 'reviewedBy',
  reviewError = 'reviewError',
  reviewers = 'reviewers',
  reviewNow = 'reviewNow',
  reviewOnly = 'reviewOnly',
  reviewRequest = 'reviewRequest',
  reviews = 'reviews',
  reviewSubtitle = 'reviewSubtitle',
  reviewTypeAnnually = 'reviewTypeAnnually',
  reviewTypeBiannually = 'reviewTypeBiannually',
  reviewTypeMonthly = 'reviewTypeMonthly',
  reviewTypeOneTime = 'reviewTypeOneTime',
  reviewTypeQuarterly = 'reviewTypeQuarterly',
  reviewTypeWeekly = 'reviewTypeWeekly',
  revoke = 'revoke',
  revokeSubtitle = 'revokeSubtitle',
  role = 'role',
  roleAssigned = 'roleAssigned',
  roleAssignedWithRole = 'roleAssignedWithRole',
  roles = 'roles',
  rolesReviewed = 'rolesReviewed',
  scope = 'scope',
  scopeType = 'scopeType',
  search = 'search',
  searchApprovals = 'searchApprovals',
  searchCatalogs = 'searchCatalogs',
  searchingSubtitle = 'searchingSubtitle',
  searchPackages = 'searchPackages',
  searchPackagesByAll = 'searchPackagesByAll',
  searchPackagesByName = 'searchPackagesByName',
  searchPackagesByNameOrDescription = 'searchPackagesByNameOrDescription',
  searchRequests = 'searchRequests',
  searchReviews = 'searchReviews',
  searchUsers = 'searchUsers',
  secondApproverWithoutStageCount = 'secondApproverWithoutStageCount',
  secondApproverWithStageCount = 'secondApproverWithStageCount',
  seeDetails = 'seeDetails',
  selectAll = 'selectAll',
  selectAnOption = 'selectAnOption',
  selectAPolicy = 'selectAPolicy',
  selectPolicy = 'selectPolicy',
  selectOne = 'selectOne',
  selfReviewTitle = 'selfReviewTitle',
  servicePrincipal = 'servicePrincipal',
  share = 'share',
  shareLink = 'shareLink',
  sharepointOnlineSite = 'sharepointOnlineSite',
  shouldContinueAccess = 'shouldContinueAccess',
  shouldContinueAccessByod = 'shouldContinueAccessByod',
  signInActivityAtTenant = 'signInActivityAtTenant',
  site = 'site',
  someoneElse = 'someoneElse',
  somethingWentWrong = 'somethingWentWrong',
  stageTitle = 'stageTitle',
  startsOn = 'startsOn',
  submit = 'submit',
  submitRequest = 'submitRequest',
  submittedBy = 'submittedBy',
  submittedThisRequest = 'submittedThisRequest',
  submitting = 'submitting',
  submittingYourAccessRequest = 'submittingYourAccessRequest',
  subscription = 'subscription',
  successfulRequestMessage = 'successfulRequestMessage',
  tapVCButton = 'tapVCButton',
  tenant = 'tenant',
  tenantNotWhitelistedMessage = 'tenantNotWhitelistedMessage',
  thirdApproverWithoutStageCount = 'thirdApproverWithoutStageCount',
  thirdApproverWithStageCount = 'thirdApproverWithStageCount',
  thanks = 'thanks',
  throttleError = 'throttleError',
  toggleCollapseOriginSystemAria = 'toggleCollapseOriginSystemAria',
  type = 'type',
  url = 'url',
  urlCopied = 'urlCopied',
  urlCreated = 'urlCreated',
  user = 'user',
  userAccess = 'userAccess',
  userAccessAvailabilityBannerMessage = 'userAccessAvailabilityBannerMessage',
  userAccessReviewSummary = 'userAccessReviewSummary',
  userAccessReviewSummaryTitle = 'userAccessReviewSummaryTitle',
  userAccessReviewPendingTitle = 'userAccessReviewPendingTitle',
  userAccessReviewScopeTitle = 'userAccessReviewScopeTitle',
  userAccessReviewPendingAssignments = 'userAccessReviewPendingAssignments',
  userAndGroup = 'userAndGroup',
  userNotInScope = 'userNotInScope',
  userAlreadyHasAccess = 'userAlreadyHasAccess',
  userHasOpenedRequest = 'userHasOpenedRequest',
  verifiableCredentialExplainer = 'verifiableCredentialExplainer',
  verifiableCredentialPolicyDisclosure = 'verifiableCredentialPolicyDisclosure',
  verifiableCredentialPolicyRequest = 'verifiableCredentialPolicyRequest',
  verifiableCredentialPrompt = 'verifiableCredentialPrompt',
  verifiableCredentialRequest = 'verifiableCredentialRequest',
  verifiableCredentialShared = 'verifiableCredentialShared',
  verified = 'verified',
  verifiedCredentialAuthenticator = 'verifiedCredentialAuthenticator',
  verifiedCredentialsProvidedByUser = 'verifiedCredentialsProvidedByUser',
  verifiedCredentialTimer = 'verifiedCredentialTimer',
  verifiedLearnMore = 'verifiedLearnMore',
  view = 'view',
  viewAccessPackage = 'viewAccessPackage',
  viewAllAvailable = 'viewAllAvailable',
  viewBy = 'viewBy',
  viewOtherAvailablePackages = 'viewOtherAvailablePackages',
  viewPackage = 'viewPackage',
  waitingForAuthenticator = 'waitingForAuthenticator',
  ramTenantUnavailableWarningMessage = 'ramTenantUnavailableWarningMessage',
  ramAdminRoleTextOnLandingPage = 'ramAdminRoleTextOnLandingPage',
  ramUserRoleTextOnLandingPage = 'ramUserRoleTextOnLandingPage',
  ramQuickActivationDialogTitle = 'ramQuickActivationDialogTitle',
  ramQuickActivationDialogNormalSubText = 'ramQuickActivationDialogNormalSubText',
  ramQuickActivationDialogBoldSubText = 'ramQuickActivationDialogBoldSubText',
  ramQuickActivationDialogRoleSectionRoleName = 'ramQuickActivationDialogRoleSectionRoleName',
  ramQuickActivationDialogRoleSectionStartTime = 'ramQuickActivationDialogRoleSectionStartTime',
  ramQuickActivationDialogRoleSectionImmediatelyDisplayText = 'ramQuickActivationDialogRoleSectionImmediatelyDisplayText',
  ramQuickActivationDialogRoleSectionDurationTitle = 'ramQuickActivationDialogRoleSectionDurationTitle',
  ramQuickActivationDialogRoleSectionDurationHours = 'ramQuickActivationDialogRoleSectionDurationHours',
  ramQuickActivationDialogRoleSectionPorts = 'ramQuickActivationDialogRoleSectionPorts',
  ramQuickActivationDialogRoleSectionIpAddresses = 'ramQuickActivationDialogRoleSectionIpAddresses',
  ramQuickActivationDialogJustificationSectionTitle = 'ramQuickActivationDialogJustificationSectionTitle',
  ramQuickActivationDialogJustificationSectionTip = 'ramQuickActivationDialogJustificationSectionTip',
  ramQuickActivationDialogErrorMessage = 'ramQuickActivationDialogErrorMessage',
  ramQuickActivationDialogFooterContinueButtonText = 'ramQuickActivationDialogFooterContinueButtonText',
  ramQuickActivationDialogFooterChangeSettingsButtonText = 'ramQuickActivationDialogFooterChangeSettingsButtonText',
  ramQuickActivationDialogTicketingSectionSystemTitle = 'ramQuickActivationDialogTicketingSectionSystemTitle',
  ramQuickActivationDialogTicketingSectionSystemAria = 'ramQuickActivationDialogTicketingSectionSystemAria',
  ramQuickActivationDialogTicketingSectionNumberTitle = 'ramQuickActivationDialogTicketingSectionNumberTitle',
  ramQuickActivationDialogTicketingSectionNumberAria = 'ramQuickActivationDialogTicketingSectionNumberAria',
  ramQuickActivationDialogTicketingSectionNumberError = 'ramQuickActivationDialogTicketingSectionNumberError',
  ramQuickActivationDialogMfaMessageText = 'ramQuickActivationDialogMfaMessageText',
  ramQuickActivationDialogRoleAtResourceScopeMessageText = 'ramQuickActivationDialogRoleAtResourceScopeMessageText',
  ramQuickActivationDialogRoleActivationScopeMessageText = 'ramQuickActivationDialogRoleActivationScopeMessageText',
  ramQuickActivationDialogRoleSectionScope = 'ramQuickActivationDialogRoleSectionScope',
  ramQuickActivationDialogRoleSectionResource = 'ramQuickActivationDialogRoleSectionResource',
  ramQuickActivationDialogRoleSectionResourceGroup = 'ramQuickActivationDialogRoleSectionResourceGroup',
  ramManualApproverMessageDialogTitle = 'ramManualApproverMessageDialogTitle',
  ramManualApproverMessageDialogBoldText = 'ramManualApproverMessageDialogBoldText',
  ramManualApproverMessageDialogMessageText = 'ramManualApproverMessageDialogMessageText',
  ramManualApproverMessageDialogCloseButtonText = 'ramManualApproverMessageDialogCloseButtonText',
  ramErrorTryAgain = 'ramErrorTryAgain',
  ramErrorContinue = 'ramErrorContinue',
  ramErrorClose = 'ramErrorClose',
  ramErrorRoleDoesNotExistTitle = 'ramErrorRoleDoesNotExistTitle',
  ramErrorRoleDoesNotExistMessage = 'ramErrorRoleDoesNotExistMessage',
  ramErrorRoleAlreadyExistsTitle = 'ramErrorRoleAlreadyExistsTitle',
  ramErrorRoleAlreadyExistsMessage = 'ramErrorRoleAlreadyExistsMessage',
  ramErrorRoleValidationFailTitle = 'ramErrorRoleValidationFailTitle',
  ramErrorRoleValidationFailMessage = 'ramErrorRoleValidationFailMessage',
  ramSearchBoxPlaceholder = 'ramSearchBoxPlaceholder',
  ramMoreMenuLabel = 'ramMoreMenuLabel',
  ramErrorRoleAssignmentDoesNotExistMessage = 'ramErrorRoleAssignmentDoesNotExistMessage',
  ramErrorActiveDurationTooShortMessage = 'ramErrorActiveDurationTooShortMessage',
  ramErrorDeactivateRoleFailed = 'ramErrorDeactivateRoleFailed',
  ramCustomActivationTitle = 'ramCustomActivationTitle',
  ramCustomActivationSubtitle = 'ramCustomActivationSubtitle',
  ramCustomActivationRole = 'ramCustomActivationRole',
  ramCustomActivationDuration = 'ramCustomActivationDuration',
  ramCustomActivationStartingTime = 'ramCustomActivationStartingTime',
  ramCustomActivationImmediately = 'ramCustomActivationImmediately',
  ramCustomActivationPickAStartingTime = 'ramCustomActivationPickAStartingTime',
  ramCustomActivationStartDate = 'ramCustomActivationStartDate',
  ramCustomActivationTime = 'ramCustomActivationTime',
  ramCustomActivationTimeZone = 'ramCustomActivationTimeZone',
  ramCustomActivationActivate = 'ramCustomActivationActivate',
  ramCustomActivationChangeSettings = 'ramCustomActivationChangeSettings',
  ramCustomActivationUtc = 'ramCustomActivationUtc',
  environment = 'environment',
  distributionType = 'distributionType',
  assignment = 'assignment',
  assignmentName = 'assignmentName',
  active = 'active',
  eligible = 'eligible',
  refreshList = 'refreshList',
  ramResourceGroup = 'ramResourceGroup',
  virtualMachine = 'virtualMachine',
  favorites = 'favorites',
  cloudInfrastructure = 'cloudInfrastructure',
  cloudInfrastructureGuide = 'cloudInfrastructureGuide',
  activate = 'activate',
  yes = 'yes',
  you = 'you',
  you_lowercase = 'you_lowercase',
  yourself = 'yourself',
  unknownErrorTitle = 'unknownErrorTitle',
  refreshPage = 'refreshPage',
  time = 'time',
  errorCode = 'errorCode',
  correlationId = 'correlationId',
  sessionId = 'sessionId',
  userId = 'userId',
  viewMore = 'viewMore',
  viewLess = 'viewLess',
  downloadError = 'downloadError',
  skipToMainContent = 'skipToMainContent',
  closeButtonAriaLabel = 'closeButtonAriaLabel',
  unexpectedError = 'unexpectedError',
  license = 'license',
  shareLinkNoOr = 'shareLinkNoOr',
  readMore = 'readMore',
  copiedCopyButton = 'copiedCopyButton',
  copyToClipboardCopyButton = 'copyToClipboardCopyButton',
  failedToCopyCopyButton = 'failedToCopyCopyButton',
  ramActivationStatusProcessingYourRequest = 'ramActivationStatusProcessingYourRequest',
  ramActivationStatusActivatingYourRole = 'ramActivationStatusActivatingYourRole',
  ramActivationStatusValidating = 'ramActivationStatusValidating',
  ramActivationStatusActivationSuccessful = 'ramActivationStatusActivationSuccessful',
  ramActivationMenuActivateAsAdmin = 'ramActivationMenuActivateAsAdmin',
  ramActivationMenuActivateAsUser = 'ramActivationMenuActivateAsUser',
  ramActivationMenuRolesAvailableTitle = 'ramActivationMenuRolesAvailableTitle',
  ramActivationMenuSwitchRoleTitle = 'ramActivationMenuSwitchRoleTitle',
  ramActivationMenuConnectionDetailsTitle = 'ramActivationMenuConnectionDetailsTitle',
  ramConnectionProvideKeyPath = 'ramConnectionProvideKeyPath',
  ramConnectionProvideLocalUsername = 'ramConnectionProvideLocalUsername',
  ramConnectionSshOption1 = 'ramConnectionSshOption1',
  ramConnectionSshOption2 = 'ramConnectionSshOption2',
  ramConnectionSshInstallationStep = 'ramConnectionSshInstallationStep',
  ramConnectionSshLoginStep = 'ramConnectionSshLoginStep',
  ramConnectionArcSshLoginStepMessage = 'ramConnectionArcSshLoginStepMessage',
  ramConnectionSshOpenClientStep = 'ramConnectionSshOpenClientStep',
  ramConnectionSshReadOnlyAccessStep = 'ramConnectionSshReadOnlyAccessStep',
  ramConnectionSshNote = 'ramConnectionSshNote',
  ramConnectionSshChmodMessage = 'ramConnectionSshChmodMessage',
  ramConnectionSshConnectionStep = 'ramConnectionSshConnectionStep',
  ramConnectionSshPrivateKeyPath = 'ramConnectionSshPrivateKeyPath',
  ramConnectionSshWindowsLinkMessage = 'ramConnectionSshWindowsLinkMessage',
  ramConnectionSshWindowsDescription = 'ramConnectionSshWindowsDescription',
  ramConnectionArcSshUsername = 'ramConnectionArcSshUsername',
  ramConnectionRdpPublicIp = 'ramConnectionRdpPublicIp',
  ramConnectionRdpGuide = 'ramConnectionRdpGuide',
  ramConnectionRdpDropdownLabel = 'ramConnectionRdpDropdownLabel',
  ramConnectionRdpUsernameLabel = 'ramConnectionRdpUsernameLabel',
  ramConnectionRdpDownloadRdpFile = 'ramConnectionRdpDownloadRdpFile',
  ramConnectionDialogCantConnect = 'ramConnectionDialogCantConnect',
  ramConnectionDialogRemoteDesktopLink = 'ramConnectionDialogRemoteDesktopLink',
  ramConnectionDialogTroubleshootLink = 'ramConnectionDialogTroubleshootLink',
  ramConnectionDialogMove = 'ramConnectionDialogMove',
  ramConnectionDialogClose = 'ramConnectionDialogClose',
  ramConnectionDialogRoleDuration = 'ramConnectionDialogRoleDuration',
  ramConnectionDialogRoleIndefinteDuration = 'ramConnectionDialogRoleIndefinteDuration',
  ramConnectionCopyCommandEntraId = 'ramConnectionCopyCommandEntraId',
  ramConnectionCopyCommandSshClient = 'ramConnectionCopyCommandSshClient',
  ramConnectionCopyCommandAzureCli = 'ramConnectionCopyCommandAzureCli',
  ramConnectionCopyCommandPassword = 'ramConnectionCopyCommandPassword',
  ramConnectionIpAddress = 'ramConnectionIpAddress',
  ramVmListNoItems = 'ramVmListNoItems',
  ramVmListError = 'ramVmListError',
  ramConnectionAlertVmDeallocated = 'ramConnectionAlertVmDeallocated',
  ramConnectionAlertNotSystemAssigned = 'ramConnectionAlertNotSystemAssigned',
  ramConnectionAlertExtensionNotInstalled = 'ramConnectionAlertExtensionNotInstalled',
  ramConnectionAlertRoleScopedAtResource = 'ramConnectionAlertRoleScopedAtResource',
  ramVirtualMachinesListAriaLabel = 'ramVirtualMachinesListAriaLabel',
  ramFilterPanelClose = 'ramFilterPanelClose',
  ramFilterPanelResetFilters = 'ramFilterPanelResetFilters',
  ramFilterPanelSubscriptionsLabel = 'ramFilterPanelSubscriptionsLabel',
  ramFilterPanelFiltersTitle = 'ramFilterPanelFiltersTitle',
  ramClearFilters = 'ramClearFilters',
  ramFilterCountMessage = 'ramFilterCountMessage',
  ramDistributionTypeNotAvailable = 'ramDistributionTypeNotAvailable',
  learnMoreText = 'learnMoreText',
  requestedForOthers = 'requestedForOthers',
  requestedForYou = 'requestedForYou',
  insightsForRelatedPeopleAndHistory = 'insightsForRelatedPeopleAndHistory',
  insightsForRelatedPeople = 'insightsForRelatedPeople',
  insightsForHistory = 'insightsForHistory',
  insightsForRelatedPersonAndHistory = 'insightsForRelatedPersonAndHistory',
  insightsForRelatedPerson = 'insightsForRelatedPerson',
  lookForMore = 'lookForMore',
  viewAndSearchAll = 'viewAndSearchAll'
}
