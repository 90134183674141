/* Ported from https://outlookweb.visualstudio.com/Outlook%20Web */

import { BaseComponent, IBaseProps } from '@fluentui/react';
import * as React from 'react';

let styles = require('./SearchBox.scoped.scss');

export interface ISearchInputProps extends IBaseProps {
  disabled?: boolean;
  canShowPlaceholder?: boolean;
  searchPlaceholderText?: string;
  searchText?: string;
  shouldFocus?: boolean;
  onKeyDown?(evt: React.KeyboardEvent<EventTarget>): void;
  onBlur?(evt: React.FocusEvent<EventTarget>): void;
  onFocus?(): void;

  setIsFocusInSearchInput?(isFocusInSearchInput: boolean): void;
  onSearchChange?(value: string): void;
  setSearchTextForSuggestion?(value: string): void;
}

export class SearchInput extends BaseComponent<ISearchInputProps, {}> {
  private searchInput: HTMLInputElement;

  /**
   * Sets/puts focus on the search input
   */
  public setFocus(): void {
    this.searchInput.focus();
  }

  public blur(): void {
    this.searchInput.blur();
  }

  public render(): React.ReactNode {
    const {
      searchText,
      canShowPlaceholder,
      searchPlaceholderText
    } = this.props;
    // We want to show place holder text only when there is not search text and no persona pill ids
    let shouldShowPlaceholder =
      (searchText === '' || searchText === undefined) &&
      canShowPlaceholder !== false;
    let placeHolderToShow = shouldShowPlaceholder ? searchPlaceholderText : '';

    return (
      <input
        ref={this._resolveRef('searchInput')}
        className={styles.searchBoxInput}
        disabled={this.props.disabled}
        aria-label={searchPlaceholderText}
        placeholder={placeHolderToShow}
        value={searchText}
        onChange={this._onSearchTextChange}
        onClick={this._onClick}
        onFocus={this._onFocus}
        onBlur={this._onBlur}
        onKeyDown={this.props.onKeyDown}
        autoFocus={this.props.shouldFocus}
      />
    );
  }

  private _onClick = (evt: React.MouseEvent<EventTarget>): void => {
    evt.stopPropagation();

    if (this.props.setIsFocusInSearchInput !== undefined) {
      this.props.setIsFocusInSearchInput(true /* isFocusInSearchInput */);
    }
  }

  private _onFocus = (_evt: React.FocusEvent<EventTarget>): void => {
    if (this.props.onFocus !== undefined) {
      this.props.onFocus();
    }

    if (this.props.setIsFocusInSearchInput !== undefined) {
      this.props.setIsFocusInSearchInput(true /* isFocusInSearchInput */);
    }
  }

  private _onBlur = (evt: React.FocusEvent<EventTarget>): void => {
    if (this.props.setIsFocusInSearchInput !== undefined) {
      this.props.setIsFocusInSearchInput(false /* isFocusInSearchInput */);
    }

    if (this.props.onBlur !== undefined) {
      this.props.onBlur(evt);
    }
  }

  private _onSearchTextChange = (): void => {
    // Set the store value synchronously to prevent rendering issues
    if (this.props.onSearchChange !== undefined) {
      this.props.onSearchChange(this.searchInput.value);
    }

    if (this.props.setSearchTextForSuggestion !== undefined) {
      this.props.setSearchTextForSuggestion(this.searchInput.value);
    }
  }
}
