import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';
import { IEntityFilterable } from '../../../models/IEntityFilterable';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { EntityType } from '../../../models/EntityType';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IRootEntitlementsState, IEntitlementState } from '../../../models/IEntitlementState';
import { getAudience } from '../../../shared/AttachAudience';
import { IAjaxRequest } from '@microsoft/portal-app/lib/auth/withAuth';
import { getDeleteVerifiableCredentialPresentationStatusApiUrl } from '../../../shared/getApiUrl';
import { registry } from '../myAccessRegistry';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { TranslationOptions } from 'i18next';
import { notificationsMerge } from '@microsoft/portal-app/lib/Notifications/helpers/notificationsMerge';
import {
  INotification,
  NotificationSeverity,
  NotificationType
} from '@microsoft/portal-app/lib/Notifications/models/INotification';
import { v4 } from 'uuid';
import * as moment from 'moment';
import { IGrantPolicy } from '../../../models/ELM/IGrantPolicy';
import { IVerifiableCredentialStatusType } from '../../../models/ELM/IVerifiableCredentialStatus';

export const deleteStatusEpic: Epic<
  IEntitlementAction<AnyPayload>,
  IRootEntitlementsState
> = (
  action$: ActionsObservable<
    IEntitlementAction<{
      entityId: string;
    }>
  >,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod; }
): Observable<IEntitlementAction> => {
    return action$
      .ofType(EntitlementActions.deleteVerifiableCredentialPresentationStatus)
      .mergeMap((action: IEntitlementAction<IEntityFilterable>) => {
        const { entityId } = action.payload!;
        const ajaxRequest: IAjaxRequest = {
          url: getDeleteVerifiableCredentialPresentationStatusApiUrl(),
          audience: getAudience(EntityType.policies),
          method: 'POST',
          body: `{"grantPolicyId": "${entityId!}"}`,
        };
        return (
          ajax(ajaxRequest)
            .map(() => {
              return {
                type: EntitlementActions.deleteVerifiableCredentialPresentationStatusSuccess,
                payload: { entityId }
              };
            })
            .catch((error: any) => {
              return Observable.of({
                type: EntitlementActions.deleteVerifiableCredentialPresentationStatusFailed,
                payload: {
                  errorCode: error && error.status
                }
              });
            })
        );
      });
  };
registry.addEpic('deleteVerifiableCredentialPresentationStatusEpic', deleteStatusEpic);

export const deleteVerifiableCredentialPresentationStatus = (
  state: IEntitlementState,
  action: IEntitlementAction<{ entityId: string }>
): Readonly<IEntitlementState> => {
  return state;
};
registry.add(EntitlementActions.deleteVerifiableCredentialPresentationStatus, deleteVerifiableCredentialPresentationStatus);

export const deleteVerifiableCredentialPresentationStatusSucceeded = (
  state: IEntitlementState,
  action: IEntitlementAction<{ entityId: string }>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }
  const policyId = action.payload.entityId;
  const oldPolicy = state.policies.entitiesById.get(policyId);

  if (oldPolicy) {
    // force a policy refresh by setting the credential as required with a future expiration
    let futureTime = new Date(Date.now() + 60 * 60 * 1000);
    const policy = {
      ...oldPolicy,
      verifiableCredentialRequirementStatus: {
        '@odata.type': IVerifiableCredentialStatusType.required,
        expiryDateTime: futureTime,
      }
    };
    const newEntitiesById = new Map<string, IGrantPolicy>([
      ...(Array.from(state.policies.entitiesById) as any),
      [policy.id, policy],
    ]);

    return {
      ...state,
      policies: {
        ...state.policies,
        isLoading: false,
        entitiesById: newEntitiesById,
      },
    };
  } else {
    return state;
  }
};
registry.add(EntitlementActions.deleteVerifiableCredentialPresentationStatusSuccess, deleteVerifiableCredentialPresentationStatusSucceeded);

export const deleteVerifiableCredentialPresentationStatusFailed = (
  state: IEntitlementState,
  action: IEntitlementAction<Readonly<any>>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }
  const errorCode = action.payload.errorCode;

  let toastKey = LocaleKeys.generalErrorMessage;
  let toastOptions: TranslationOptions = {};

  let notifications: INotification[] = [
    {
      id: v4(),
      createdDateTime: moment(),
      localizableMessage: {
        key: toastKey,
        options: toastOptions
      },
      severity: NotificationSeverity.error,
      type: NotificationType.card
    }
  ];

  return {
    ...state,
    notifications: notificationsMerge(
      notifications,
      state.notifications,
      state.notificationsLimit
    ),
    errorHasOccurred: true,
    errorCode: errorCode
  };
};
registry.add(EntitlementActions.deleteVerifiableCredentialPresentationStatusFailed, deleteVerifiableCredentialPresentationStatusFailed);
