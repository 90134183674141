import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { useDispatch } from 'react-redux';

import { EntitlementActions, IEligibleAccessPackageDetail, IPageData } from '../models';
import { useSelector } from '.';
import { GetEligibleAccessPackageActionPayload } from '../redux';

interface IUseGetEligibleAccessPackage {
  getEligibleAccessPackage: (payload: GetEligibleAccessPackageActionPayload) => void;
  clearValidationErrors: () => void;
  eligibleAccessPackage: IEligibleAccessPackageDetail;
}

export const useGetEligibleAccessPackage = (): IUseGetEligibleAccessPackage => {
  const dispatch = useDispatch();
  const {eligibleAccessPackage} = useSelector((state) => ({
    eligibleAccessPackage: state?.app?.eligibleAccessPackageDetail
  }));

  return {
    getEligibleAccessPackage: createAction<GetEligibleAccessPackageActionPayload>(EntitlementActions.getEligibleAccessPackageDetail, dispatch),
    clearValidationErrors: createAction(EntitlementActions.clearValidationErrors, dispatch),
    eligibleAccessPackage
  };
};