import React from 'react';
import { useTranslation } from '../../../hooks';
import { LocaleKeys } from '../../../shared';
const overviewStyles = require('../../../css/overview.scoped.scss');

export const OverviewEmptyAction = () => {
  const t = useTranslation();
  return(
  <div className={overviewStyles.emptyStatus}>
    <img src="/imgs/hot_air_ballon.svg" alt=""/>
    <p role="presentation">{t(LocaleKeys.noPendingAction)}</p>
  </div>
  );
};