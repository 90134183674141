import { IErrorBoundaryComponentProps } from '@uifabric/portal-ux/lib/ErrorBoundary';
import {
  FullSizeError,
  IFullSizeErrorComponentLabels,
  IFullSizeErrorProps
} from '@uifabric/portal-ux/lib/FullsizeError';
import { useCallback, useEffect } from 'react';
import * as React from 'react';

import { useTelemetryWithMetadata, useTranslation } from './hooks';
import { LocaleKeys } from './shared';
import { Omit } from './shared/helpers.types';

export type LocalizedFullSizeErrorProps = Omit<IFullSizeErrorProps, 'labels'>;

export const LoginError: React.FunctionComponent<IErrorBoundaryComponentProps> = ({ error, errorInfo }) => {
  const t = useTranslation();
  const errorDetails = `${error.message} ${JSON.stringify(errorInfo, null, 2)}`;
  const { reportErrorWithMetadata } = useTelemetryWithMetadata();

  useEffect(() => {
    reportErrorWithMetadata('Login error found in My Access', errorDetails);
  }, [errorDetails, reportErrorWithMetadata]);

  const actionCallback = useCallback(() => {
    location.reload();
  }, []);

  const props: LocalizedFullSizeErrorProps = {
    errorTitle: t(LocaleKeys.unknownErrorTitle),
    subtitle: t(LocaleKeys.refreshPage),
    actionButtonProps: {
      actionLabel: t(LocaleKeys.refreshPage),
      onActionPressed: actionCallback
    },
    message: errorDetails
  };

  const constants: IFullSizeErrorComponentLabels = {
    timeLabel: t(LocaleKeys.time),
    errorCodeLabel: t(LocaleKeys.errorCode),
    correlationIdLabel: t(LocaleKeys.correlationId),
    sessionIdLabel: t(LocaleKeys.sessionId),
    userIdLabel: t(LocaleKeys.userId),
    detailsLabel: t(LocaleKeys.details),
    unknownErrorTitle: t(LocaleKeys.unknownErrorTitle),
    viewMoreLabel: t(LocaleKeys.viewMore),
    viewLessLabel: t(LocaleKeys.viewLess),
    defaultErrorButtonActionLabel: t(LocaleKeys.accept),
    downloadErrorTooltipLabel: t(LocaleKeys.downloadError)
  };

  return <FullSizeError labels={constants} {...props} />;
};
