import { OriginSystem } from '../../../../src/models';
import { LocaleKeys } from '../../../shared';

export const OriginSystemToSingularNameMap = new Map<OriginSystem, LocaleKeys>([
  [OriginSystem.AadApplication, LocaleKeys.application],
  [OriginSystem.AadAuthorization, LocaleKeys.authorization],
  [OriginSystem.AadGroup, LocaleKeys.group],
  [OriginSystem.DirectoryRole, LocaleKeys.directoryRole],
  [OriginSystem.Dynamics365, LocaleKeys.dynamics365],
  [OriginSystem.SharePointOnline, LocaleKeys.site],
  [OriginSystem.License, LocaleKeys.license]
]);

export const AllResourceTypeKey = 'all';
