import { TranslationFunction } from 'i18next';
import {
  Checkbox,
  CheckboxVisibility,
  css,
  DefaultButton,
  DetailsList,
  IDetailsListCheckboxProps,
  IDetailsRowProps,
  Label,
  Selection,
  Spinner,
  SpinnerSize
} from '@fluentui/react';
import * as React from 'react';
import { IKeyValuePair } from '../../../models/ELM/IKeyValuePair';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { ColumnValue } from '../ColumnValue/ColumnValue';
import { IRenderFunction } from '@fluentui/react/lib/Utilities';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');

export interface IFilterListProps {
  t: TranslationFunction;
  selection: Selection;
  options: IKeyValuePair[];
  localizedLabel: string;
  translateOptions: boolean;
  isLoadingOptions?: boolean;
  showLoadMoreOptions?: boolean;
  onLoadMoreOptions?: () => void;
}

export class FilterList extends React.Component<IFilterListProps, {}> {
  public render(): JSX.Element {
    const {
      t,
      selection,
      options,
      localizedLabel,
      isLoadingOptions,
      showLoadMoreOptions,
      translateOptions,
      onLoadMoreOptions
    } = this.props;
    return (
      <div className={css(myAccessStyles.marginBottomMedium)}>
        <Label
          className={css(
            myAccessStyles.marginBottomSmall,
            myAccessStyles.semiBoldText
          )}
        >
          {localizedLabel}
        </Label>
        {isLoadingOptions ? (
          <Spinner size={SpinnerSize.medium} label={t(LocaleKeys.loading)} />
        ) : (
          <DetailsList
            selection={selection}
            selectionPreservedOnEmptyClick={true}
            items={options}
            checkboxVisibility={CheckboxVisibility.always}
            // tslint:disable-next-line:jsx-no-lambda
            isHeaderVisible={false}
            onRenderRow={this._onRenderRow}
            columns={[
              {
                key: '',
                name: '',
                minWidth: 50,
                maxWidth: 50,
                onRender: (item: IKeyValuePair) => {
                  return (
                    <ColumnValue
                      columnValue={
                        translateOptions ? t(item.value) : item.value
                      }
                      isHighlightRequired={false}
                      isSearching={false}
                      searchTerm={''}
                    />
                  );
                }
              }
            ]}
            onRenderCheckbox={this.onRenderCheckbox}
          />
        )}
        {showLoadMoreOptions ? (
          <div className={css(myAccessStyles.alignCenter)}>
            <DefaultButton
              onClick={onLoadMoreOptions}
              text={t(LocaleKeys.loadMore)}
            />
          </div>
        ) : null}
      </div>
    );
  }

  private onRenderCheckbox = (props?: IDetailsListCheckboxProps) => {
    return (
      <div tabIndex={0} className={css(myAccessStyles.filterCheckboxDiv)}>
        <Checkbox
          checked={props!.checked}
          inputProps={
            {
              onClick: (ev: any) => ev.stopPropagation(),
              'data-selection-disabled': true,
            } as any}
        />
      </div>
    );
  };

  /* For the fluent ui version 8.9.2, Keyboard overrides is not working.
     This custom onRenderRow function works for the DetailList keyboard multi-selection
     https://stackoverflow.com/questions/56692224/how-to-toggle-office-ui-fabric-detailslist-row-selection-using-the-entire-row-as/56785728#56785728 */
  private _onRenderRow = (props: IDetailsRowProps, defaultRender?: IRenderFunction<IDetailsRowProps>): JSX.Element => {
    return (
      <div data-selection-toggle="true">
        {defaultRender && defaultRender(props)}
      </div>
    );
  };
}
