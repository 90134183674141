import { IAjaxCreationMethod, IAjaxRequest } from '@microsoft/portal-app/lib/auth';
import { determineEnvironment, EnvironmentType } from '@microsoft/portal-app/lib/environment';
import { IAction } from '@microsoft/portal-app/lib/redux';
import { addAjaxEpic, getSuccessActionType } from '@microsoft/portal-app/lib/redux/createAjaxEpic';
import { getOverrideSetting } from '@microsoft/portal-app/lib/shared/override-settings/overrideSettings';
import { NavBarData } from '@suiteux/suiteux-shell-applauncher';
import { v4 as uuidv4 } from 'uuid';
import { EntitlementActions, IEntitlementState } from '../../../models';
import { registry } from '../myAccessRegistry';

const SUITE_HEADER_AUDIENCE_URL_PPE = 'https://webshell.suite.officeppe.com';
const SUITE_HEADER_AUDIENCE_URL_PROD = 'https://webshell.suite.office.com';

export const getNavBarDataAjax = (ajax: IAjaxCreationMethod, action: IAction<string>) => {
  const token = action.payload;
  return ajax.getJSON(callShellApi(token));
};

export const getNarBarDataAjaxResponse = (state: IEntitlementState, action: IAction): IEntitlementState => {
  if (action.payload === undefined) {
    return state;
  }
  const response = action.payload;
  return {
    ...state,
    navBarData: response as NavBarData
  };
};

export const callShellApi = (token?: string): IAjaxRequest => {
  const environment = determineEnvironment(location);
  let audience = getOverrideSetting('officeSuiteHeaderAudience');

  if (!audience) {
    switch (environment) {
      case EnvironmentType.PPE:
        audience = SUITE_HEADER_AUDIENCE_URL_PPE;
        break;
      case EnvironmentType.PROD:
        audience = SUITE_HEADER_AUDIENCE_URL_PROD;
        break;
    }
  }

  return {
    url: `${audience}/api/shell/navbardata?CurrentMainLinkId=AADMyApps&TrackingGuid=${uuidv4()}`,
    headers: {
      Authorization: 'Bearer ' + token
    }
  };
};

addAjaxEpic(registry, EntitlementActions.getNavBarData, getNavBarDataAjax, {
  showErrorAsToast: false
});

registry.add(getSuccessActionType(EntitlementActions.getNavBarData), getNarBarDataAjaxResponse);
