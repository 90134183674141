import { IWithResponsiveModeState } from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import * as React from 'react';
import { InjectedTranslateProps } from 'react-i18next';

import { EntitlementActions } from '../../../models';
import { IGrantRequest } from '../../../models/ELM/IGrantRequest';
import { IPageData } from '../../../models/IPageData';
import { IWithFeaturesProp } from '../../../shared';

/**
 * The props for the GrantRequests control.
 */
export interface IGrantRequestsProps
  extends IGrantRequestsActions,
  IGrantRequestsMappedProps,
  React.Props<IGrantRequests>,
  InjectedTranslateProps,
  IConnectedGrantRequestsProps,
  IWithResponsiveModeState { }

/**
 * Used as a standalone interface by mapStateToProps, the IGrantRequestsMappedProps defines the non action properties.
 * for the GrantRequests control.
 */
export interface IGrantRequestsMappedProps extends IWithFeaturesProp {
  pendingApprovalGrantRequests: IPageData<IGrantRequest>;
  completedGrantRequests: IPageData<IGrantRequest>;
  searchTerm: string;
}

export interface IGrantRequestsState {
  selectedPivot: GrantRequestsPivots;
  approvalSubstitutionModalOpen: boolean;
}

export enum GrantRequestsPivots {
  Pending = 'Pending',
  Completed = 'Completed',
  Expired = 'Expired',
  History = 'History'
}

/**
 * Used as a standalone interface by mapDispatchToProps, the IGrantRequestsActions interface defines the actions
 * for the GrantRequests control
 */
export interface IGrantRequestsActions {
  setSearchContext: (context: EntitlementActions | null) => void;
  showFiltersIcon: (isVisible: boolean) => void;
}

/**
 * Used by the React.Props interface, IGrantRequests represents the interface for the GrantRequests when used as a ref.
 */
export interface IGrantRequests { }

/**
 * The props for the redux connected GrantRequests
 * @description Called "own" props in the redux function mapStateToProps.
 * The mapStateToProps can map the properties of this interface to the IGrantRequestsProps interface.
 */
export interface IConnectedGrantRequestsProps { }
