import { Observable } from 'rxjs/Observable';
import { debounceTime, mergeMap } from 'rxjs/operators';

import { EntitlementActions } from '../../../models/EntitlementActions';
import { registry } from '../myAccessRegistry';
import { createTelemetryAction, ofType } from './';

export const searchTelemetryEpic = registry.addEpic(
  'searchTelemetry',
  (action$, store) =>
    action$.pipe(
      ofType(
        EntitlementActions.searchValidGrantsOnServer,
        EntitlementActions.searchExpiredGrantsOnServer,
        EntitlementActions.searchGrantRequestsOnServer,
        EntitlementActions.searchPendingApprovalGrantRequestsOnServer,
        EntitlementActions.searchCompletedGrantRequestsOnServer,
        EntitlementActions.searchEntitlementsOnServer,
        EntitlementActions.searchPendingRaeRequestsOnServer,
        EntitlementActions.searchCompletedRaeRequestsOnServer,
        EntitlementActions.searchAccessReviewsOnServer,
        EntitlementActions.searchReviewDecisionsOnServer,
        EntitlementActions.searchCatalogsOnServer
      ),
      mergeMap((action) => {
        const state = store.getState();

        const searchType = state.search.selectedFilterKey
          ? `/${state.search.selectedFilterKey}`
          : '';

        return Observable.of(
          createTelemetryAction(`${action.type}${searchType}`)
        );
      })
    )
);

/**
 * Debounce telemetry events for the @app/search/changed action since it is fired for every
 * character typed into search bar which is unneccessary and slow.
 */
export const searchChangeTelemetryDebounceEpic = registry.addEpic(
  'searchChangeTelemetryDebounce',
  (action$) =>
    action$.pipe(
      ofType('@app/search/changed'),
      debounceTime(1000),
      mergeMap((action) => Observable.of(createTelemetryAction(action.type)))
    )
);
