import { IEntitlement } from '../models/ELM/IEntitlement';
import { IGrant } from '../models/ELM/IGrant';
import { IGrantRequest } from '../models/ELM/IGrantRequest';
import { getAuth } from './authHelper';

export const GetGrantFromEntitlement = (
  entitlement: IEntitlement | null | undefined,
  grantId?: string,
  grantPolicyId?: string
): Partial<IGrant> | undefined => {
  const auth = getAuth();

  if (!entitlement || !auth) {
    return undefined;
  }

  let grant: Partial<IGrant> = {
    id: grantId ? grantId : '',
    accessPackageId: entitlement.id,
    schedule: {
      startDateTime: null,
      stopDateTime: null,
    },
    assignmentPolicyId: grantPolicyId,
  };

  return grant;
};

export const GetGrantRequestFromGrant = (
  grant: Partial<IGrant>,
  requestType: string
): Partial<IGrantRequest> | undefined => {
  const grantRequest: Partial<IGrantRequest> = {
    accessPackageAssignment: grant,
    justification: '',
    id: '',
    requestStatus: '',
    requestType: requestType,
  };
  return grantRequest;
};
