export interface IAccessReviewInsight {
  readonly '@odata.type': string;
  readonly id: string;
}

export enum AccessReviewInsight {
  Outlier = '#microsoft.graph.membershipOutlierInsight',
  Inactive = '#microsoft.graph.userSignInInsight' // This insight does not come from odata
}

// Tracks the status of each insight category for a specific decision
export interface IAccessReviewDecisionInsights {
  readonly outlier: boolean;
  readonly inactive: boolean;
}