import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { EntitlementActions } from '../../../models';
import { IAccessReview } from '../../../models/AccessReviews/IAccessReview';
import { EntityType } from '../../../models/EntityType';
import { IRootEntitlementsState } from '../../../models/IEntitlementState';
import { IFilter } from '../../../models/IFilter';
import { IListColumn } from '../../../models/IListColumn';
import { LoadMode } from '../../../models/IPageData';
import { GetEntityList } from '../../../shared/GetEntityList';
import { getPageMetaDataFromEntities } from '../../../shared/getPageMetaDataFromEntities';
import { isEmptyOrUndefined } from '../../../shared/isEmptyOrUndefined';
import { AccessReviewsList } from './AccessReviewsList';
import {
  AccessReviewsPivot,
  IAccessReviewsListActions,
  IAccessReviewsListMappedProps,
  IConnectedAccessReviewsListProps
} from './AccessReviewsList.types';

const getIsFiltering = (filter: IFilter): boolean => {
  if (!filter) {
    return false;
  }
  if (filter.daysSinceSubmitted !== -1) {
    return true;
  }
  if (filter.selectedStates && filter.selectedStates.length > 0) {
    return true;
  }
  return false;
};

/**
 * Maps the external (i.e. own) props and the state to the properties of the AccessReviewsList control.
 * @param state The current redux state to map to props
 * @param ownProps The external properties to map to props
 * @returns The mapped properties that along with the actions
 * becomes the props (IAccessReviewsListProps) for the AccessReviewsList control
 */
const mapStateToProps = (
  state: IRootEntitlementsState,
  _ownProps: IConnectedAccessReviewsListProps
): IAccessReviewsListMappedProps => {
  const {
    accessReviews,
    accessPackageReviews,
    aadRolesReviews,
    rbacReviews,
    byodReviews,
    userAccessReviews,
    searchTerm,
    errorHasOccurred,
    errorCode,
    isTenantWhitelisted,
    showingConfirmDialog
  } = state.app;

  const filter = accessReviews.filterContext!;

  const isSearching: boolean = !isEmptyOrUndefined(searchTerm);
  const isFiltering: boolean = getIsFiltering(filter);
  const entitiesList = GetEntityList(accessReviews, isSearching || isFiltering);
  const elmEntitiesList = GetEntityList(accessPackageReviews, isSearching || isFiltering);
  const aadRolesEntitiesList = GetEntityList(aadRolesReviews, isSearching || isFiltering);
  const rbacEntitiesList = GetEntityList(rbacReviews, isSearching || isFiltering);
  const byodEntitiesList = GetEntityList(byodReviews, isSearching || isFiltering);
  const userAccessEntitiesList = GetEntityList(userAccessReviews, isSearching || isFiltering);

  const accessReviewsList: IAccessReview[] = entitiesList.filteredEntities.reduce(
    (reviewList: IAccessReview[], key: string) => {
      const item = accessReviews.entitiesById.get(key);
      if (item) {
        reviewList.push(item);
      }
      return reviewList;
    },
    []
  );

  const accessPackageReviewsList: IAccessReview[] = elmEntitiesList.filteredEntities.reduce(
    (reviewList: IAccessReview[], key: string) => {
      const item = accessPackageReviews.entitiesById.get(key);
      if (item) {
        reviewList.push(item);
      }
      return reviewList;
    },
    []
  );

  const aadRolesReviewsList: IAccessReview[] = aadRolesEntitiesList.filteredEntities.reduce(
    (reviewList: IAccessReview[], key: string) => {
      const item = aadRolesReviews.entitiesById.get(key);
      if (item) {
        reviewList.push(item);
      }
      return reviewList;
    },
    []
  );

  const rbacReviewsList: IAccessReview[] = rbacEntitiesList.filteredEntities.reduce(
    (reviewList: IAccessReview[], key: string) => {
      const item = rbacReviews.entitiesById.get(key);
      if (item) {
        reviewList.push(item);
      }
      return reviewList;
    },
    []
  );

  const byodReviewsList: IAccessReview[] = byodEntitiesList.filteredEntities.reduce(
    (reviewList: IAccessReview[], key: string) => {
      const item = byodReviews.entitiesById.get(key);
      if (item) {
        reviewList.push(item);
      }
      return reviewList;
    },
    []
  );

  const userAccessReviewsList: IAccessReview[] = userAccessEntitiesList.filteredEntities.reduce(
    (reviewList: IAccessReview[], key: string) => {
      const item = userAccessReviews.entitiesById.get(key);
      if (item) {
        reviewList.push(item);
      }
      return reviewList;
    },
    []
  );

  let pageMetaData = getPageMetaDataFromEntities(accessReviews);

  switch (state.app.reviewListPivot) {
    case AccessReviewsPivot.AccessPackages:
      pageMetaData = getPageMetaDataFromEntities(accessPackageReviews);
      break;
    case AccessReviewsPivot.AadRoles:
      pageMetaData = getPageMetaDataFromEntities(aadRolesReviews);
      break;
    case AccessReviewsPivot.Rbac:
      pageMetaData = getPageMetaDataFromEntities(rbacReviews);
      break;
    case AccessReviewsPivot.Byod:
      pageMetaData = getPageMetaDataFromEntities(byodReviews);
      break;
    default:
      break;
  }

  return {
    isSearching,
    isFiltering,
    errorHasOccurred,
    errorCode,
    isLoading: accessReviews.isLoading || accessPackageReviews.isLoading,
    isGroupsAppsRefreshing: accessReviews.loadMode! === LoadMode.Refresh,
    isAccessPackagesRefreshing: accessPackageReviews.loadMode! === LoadMode.Refresh,
    isAadRolesRefreshing: aadRolesReviews.loadMode! === LoadMode.Refresh,
    isRbacRefreshing: rbacReviews.loadMode! === LoadMode.Refresh,
    isByodRefreshing: byodReviews.loadMode! === LoadMode.Refresh,
    isUserAccessRefreshing: userAccessReviews.loadMode! === LoadMode.Refresh,
    isLoadingMoreGroupsApps: accessReviews.loadMode! === LoadMode.LoadMore,
    isLoadingMoreAccessPackages: accessPackageReviews.loadMode! === LoadMode.LoadMore,
    isLoadingMoreAadRoles: aadRolesReviews.loadMode! === LoadMode.LoadMore,
    isLoadingMoreRbac: rbacReviews.loadMode! === LoadMode.LoadMore,
    isLoadingMoreByod: byodReviews.loadMode! === LoadMode.LoadMore,
    isLoadingMoreUserAccess: userAccessReviews.loadMode! === LoadMode.LoadMore,
    accessReviewsList,
    accessPackageReviewsList,
    aadRolesReviewsList,
    rbacReviewsList,
    byodReviewsList,
    userAccessReviewsList,
    filter,
    pageMetaData,
    searchTerm,
    isTenantWhitelisted,
    showingConfirmDialog: showingConfirmDialog!,
    features: state.app.features.isEnabled,
    featureFlags: state.app.accessReviewsFeatureFlags
  };
};

/**
 * Maps the dispatch actions to the props for the AccessReviewsList control.
 * @param dispatch Dispatches actions to the List
 * @returns The mapped actions that along with the mapped properties
 * becomes the props (IAccessReviewsListProps) for the AccessReviewsList control
 */
const mapDispatchToProps = (dispatch: Dispatch<IRootEntitlementsState>): IAccessReviewsListActions => {
  const setSortedByColumnAction = createAction<{
    column: IListColumn<IAccessReview>;
    entityType: string;
  }>(EntitlementActions.setSortedByColumn, dispatch);
  const getSummary = createAction<{
    entityId: string;
  }>(EntitlementActions.getDecisionsSummary, dispatch);
  const getEntitiesAction = createAction<{ entityType: string }>(EntitlementActions.getEntities, dispatch);
  const setReviewListPivot = createAction<{
    pivot: AccessReviewsPivot;
  }>(EntitlementActions.setReviewListPivot, dispatch);
  const sortEntitiesAction = createAction<{
    entityType: string;
    orderby: string;
    isAscending: boolean;
  }>(EntitlementActions.sortEntities, dispatch);
  const sortFilteredEntitiesAction = createAction<{
    entityType: string;
    orderby: string;
    isAscending: boolean;
    searchTerm: string;
    filterContext: IFilter;
  }>(EntitlementActions.sortFilteredEntities, dispatch);
  const refreshEntitiesAction = createAction<{ entityType: string }>(EntitlementActions.refreshEntities, dispatch);
  const getAccessReviewsFeatureFlags = createAction(EntitlementActions.getAccessReviewsFeatureFlags, dispatch);
  const searchForMoreAction = createAction<{ entityType: string }>(EntitlementActions.searchForMore, dispatch);
  return {
    getEntities: () =>
      getEntitiesAction({
        entityType: EntityType.accessReviews
      }),
    getDecisionsSummary: (reviewId: string) =>
      getSummary({
        entityId: reviewId
      }),
    searchForMore: () => searchForMoreAction({ entityType: EntityType.accessReviews }),
    setReviewListPivot: (setPivot: AccessReviewsPivot) =>
      setReviewListPivot({
        pivot: setPivot
      }),
    openReview: () => true,
    setSearchContext: createAction<string>(EntitlementActions.setSearchContext, dispatch),
    setSortedByColumn: (column: IListColumn<IAccessReview>) =>
      setSortedByColumnAction({
        column,
        entityType: EntityType.accessReviews
      }),
    sortEntities: (orderby: string, isAscending: boolean, type: EntityType) =>
      sortEntitiesAction({
        entityType: type,
        orderby,
        isAscending
      }),
    sortFilteredEntities: (
      orderby: string,
      isAscending: boolean,
      searchTerm: string,
      filterContext: IFilter,
      type: EntityType
    ) =>
      sortFilteredEntitiesAction({
        entityType: type,
        orderby,
        isAscending,
        searchTerm,
        filterContext
      }),
    loadMoreEntities: (type: EntityType) =>
      getEntitiesAction({
        entityType: type
      }),
    refreshAccessReviews: () => refreshEntitiesAction({ entityType: EntityType.accessReviews }),
    refreshAccessPackageReviews: () => refreshEntitiesAction({ entityType: EntityType.accessPackageReviews }),
    refreshAadRolesReviews: () => refreshEntitiesAction({ entityType: EntityType.aadRoleReviews }),
    refreshRbacReviews: () => refreshEntitiesAction({ entityType: EntityType.rbacReviews }),
    refreshByodReviews: () => refreshEntitiesAction({ entityType: EntityType.byodReviews }),
    refreshUserAccessReviews: () => refreshEntitiesAction({ entityType: EntityType.userAccessReviews }),
    getAccessReviewsFeatureFlags: () => getAccessReviewsFeatureFlags()
  };
};

/**
 * A redux connected AccessReviewsList control.
 * @description Supports translation
 */
export const ConnectedAccessReviewsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(translate('MyAccess')(AccessReviewsList));
