import { ILocalizedText } from './ILocalizedText';
import i18n from '@microsoft/portal-app/lib/i18n';
import * as localeMap from '../../shared/locales/fallbacks.json';

export interface IEntitlementManagementLocalizedContent {
  defaultText: string;

  localizedTexts: ILocalizedText[];
}
export const getLocalizedText = (localizedContent: IEntitlementManagementLocalizedContent): string => {
  if (localizedContent.localizedTexts.find((t) => t.languageCode === i18n.language)) {
    return localizedContent.localizedTexts.find((t) => t.languageCode === i18n.language)!.text;
  }
  let fallbackMap = localeMap;
  let fallbacks = fallbackMap[i18n.language];
  if (fallbacks) {
    if (
      localizedContent.localizedTexts.find((t) => fallbacks.some((f: { code: string }) => t.languageCode === f.code))
    ) {
      return localizedContent.localizedTexts.find((t) =>
        fallbacks.some((f: { code: string }) => t.languageCode === f.code)
      )!.text;
    }
  }
  return localizedContent.defaultText;
};
