import { IAjaxRequest } from '@microsoft/portal-app/lib/auth/withAuth';
import {
  IODataValueResponse
} from '@microsoft/portal-app/lib/odata-utils';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';
import { IGrant } from '../../../models';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import {
  IEntitlementState,
  IRootEntitlementsState
} from '../../../models/IEntitlementState';
import { getAudience } from '../../../shared/AttachAudience';
import { getOverviewExpiringGrantsApiUrl } from '../../../shared/getApiUrl';
import { registry } from '../myAccessRegistry';
import { OverviewErrorEventName } from '../../../models/IOverviewData';
import { getResponseError } from '../../../shared/getResponseValue';

export const getOverviewExpiringGrantsEpic: Epic<
  IEntitlementAction<AnyPayload>,
  IRootEntitlementsState
> = (
  action$: ActionsObservable<IEntitlementAction>,
  store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
    return action$
      .ofType(EntitlementActions.getOverviewExpiringGrants)
      .switchMap((action: IEntitlementAction) => {

        const ajaxRequest: IAjaxRequest = {
          url: getOverviewExpiringGrantsApiUrl(),
          audience: getAudience(),
          method: 'GET'
        };

        return (
          ajax(ajaxRequest)
            .timeout(10000) // 10000ms
            .map((payload: IODataValueResponse<ReadonlyArray<IGrant>>) => {
              return {
                type: EntitlementActions.getOverviewExpiringGrantsSucceeded,
                payload: {
                  count: payload.response!['@odata.count'],
                }
              };
            })
            // tslint:disable-next-line:no-any
            .catch((error: any) =>
              Observable.of({
                type: EntitlementActions.getOverviewExpiringGrantsFailed,
                payload: {
                  error: error
                }
              })
            )
        );
      });
  };
registry.addEpic('getExpiringGrantsEpic', getOverviewExpiringGrantsEpic);

export const getOverviewExpiringGrants = (
  state: IEntitlementState
): Readonly<IEntitlementState> => {

  return {
    ...state,
    overviewExpiringGrantData: {
      isLoading: true,
      isSucceeded: false
    }
  };
};
registry.add(EntitlementActions.getOverviewExpiringGrants, getOverviewExpiringGrants);

export const getExpiringGrantsSucceeded = (
  state: IEntitlementState,
  action: IEntitlementAction
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  let newState = {
    ...state,
    overviewExpiringGrantData: {
      pendingActionEntityCount: action.payload.count!,
      isLoading: false,
      isSucceeded: true
    },
    errorHasOccurred: false
  };

  return newState;
};
registry.add(EntitlementActions.getOverviewExpiringGrantsSucceeded, getExpiringGrantsSucceeded);

export const getExpiringGrantsFailed = (
  state: IEntitlementState,
  // tslint:disable-next-line:no-any
  action: IEntitlementAction
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  return {
    ...state,
    overviewExpiringGrantData: {
      isLoading: false,
      isSucceeded: false,
      errorEventName: OverviewErrorEventName.expiringGrantsError,
      error: getResponseError(action.payload.error)
    }
  };
};
registry.add(EntitlementActions.getOverviewExpiringGrantsFailed, getExpiringGrantsFailed);
