import { createAction } from '@microsoft/portal-app/lib/redux/createAction';
import {
  ISearchAction,
  SearchActions
} from '@microsoft/portal-app/lib/Search/models';
import { IPersonaSuggestion } from '@uifabric/portal-ux/lib/components/Search/Suggestion';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IRootEntitlementsState } from '../../../models';
import {
  IConnectedCustomSearchBoxProps,
  ICustomSearchBoxActions,
  ICustomSearchBoxMappedProps
} from './ConnectedCustomSearchBox.types';
import { CustomSearchBox } from './CustomSearchBox';

export interface ISearchRequest {
  searchContext: string | undefined;
  searchTerm: string;
  actionSource: string;
  pills: IPersonaSuggestion[];
}

/**
 * Maps the external (i.e. own) props and the state to the properties of the CustomSearchBox control.
 * @param state The current redux state to map to props
 * @param ownProps The external properties to map to props
 * @returns The mapped properties that along with the actions
 * becomes the props (ICustomSearchBoxProps) for the CustomSearchBox control
 */
export const mapStateToProps = (
  state: IRootEntitlementsState,
  _ownProps: IConnectedCustomSearchBoxProps
): ICustomSearchBoxMappedProps => {
  const {
    searchPlaceholderText,
    showFiltersIcon,
    searchContext,
    filterItems,
    searchBoxVisible
  } = state.app;
  return {
    ...state.search,
    searchContext,
    searchTerm: state.search.searchTerm || '',
    showFiltersIcon: showFiltersIcon,
    searchPlaceholderText: searchPlaceholderText,
    filterItems: filterItems,
    searchVisible: searchBoxVisible
  };
};

let searchId = 1;

/**
 * Maps the dispatch actions to the props for the CustomSearchBox control.
 * @param dispatch Dispatches actions to the store
 * @returns The mapped actions that along with the mapped properties
 * becomes the props (ICustomSearchBoxProps) for the CustomSearchBox control
 */
export const mapDispatchToProps = (
  dispatch: Dispatch<ISearchAction>
): Partial<ICustomSearchBoxActions> => {
  const searchAction = (_searchContext: string, searchTerm: string) => {
    searchId++;
    createAction<string>(SearchActions.searchChanged, dispatch, {
      traceId: searchId
    })(searchTerm);
    // createAction<string>(searchContext, dispatch, { traceId: searchId })(
    //   searchTerm
    // );
  };

  const executeSearch = createAction<ISearchRequest>(
    SearchActions.executeSearch,
    dispatch
  );

  return {
    goBack: (_searchContext) => { createAction<string>(_searchContext, dispatch)(''); },
    onSearchChange: searchAction,
    onSearch: (
      searchContext: string | undefined,
      searchTerm: string,
      actionSource: string,
      pills: IPersonaSuggestion[]
    ) => {
      executeSearch({
        searchContext,
        searchTerm,
        actionSource,
        pills
      });
      if (searchContext && searchContext !== 'unknown') {
        createAction<string>(searchContext, dispatch)(searchTerm);
      }
    },
    onFilterClick: createAction(SearchActions.showFilter, dispatch),
    selectFilterItem: createAction<string>(
      SearchActions.selectFilterKey,
      dispatch
    ),
    hideFilter: createAction<string>(SearchActions.hideFilter, dispatch)
  };
};

/**
 * A redux connected CustomSearchBox control.
 */
export const ConnectedCustomSearchBox = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomSearchBox);
