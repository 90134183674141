import { IGrantRequest } from '../../../models/ELM/IGrantRequest';
import { IGrantRequestTargetType } from '../../../models/ELM/IGrantRequestTargetType';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState } from '../../../models/IEntitlementState';
import { registry } from '../myAccessRegistry';

export const setPartialGrantRequest = (
  state: IEntitlementState,
  action: IEntitlementAction<Partial<IGrantRequest>>
): IEntitlementState => {
  const nextState = {
    ...state,
    partialGrantRequest: action.payload
  };

  // Clear OBO switch state when the grant request is cleared
  if (!action.payload) {
    nextState.grantRequestTarget = {
      targetType: IGrantRequestTargetType.Myself
    };
  }

  return nextState;
};
registry.add(EntitlementActions.setPartialGrantRequest, setPartialGrantRequest);
