import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { Action, MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import {
  IEntitlementState,
  IRootEntitlementsState
} from '../../../models/IEntitlementState';
import { registry } from '../myAccessRegistry';
import { AdminOptInFeatureKey } from '../../../shared/features';
import { getCacheKey, getWithExpiry } from '../../../shared/storage/localStorageUtil';
import { isNullOrUndefined } from 'util';

export const getCachedFeaturesEpic: Epic<
  IEntitlementAction<AnyPayload>,
  IRootEntitlementsState
> = (
  action$: ActionsObservable<Action>,
  _store: MiddlewareAPI<IRootEntitlementsState>
): Observable<IEntitlementAction> => {
  return action$
    .ofType('@app/user/auth/success')
    .switchMap((_action: IEntitlementAction) => {
      const { tid } = _action.payload?.profile as { tid: string };
      const approverRevokeCacheKey = getCacheKey(AdminOptInFeatureKey.EnableApproverRevoke, tid);
      const enableRecommendationCacheKey = getCacheKey(AdminOptInFeatureKey.EnableRecommendationForEndUser, tid);

      const isApproverRevokeEnabledInCache = getWithExpiry(approverRevokeCacheKey);
      const isRecommendationEnabledInCache = getWithExpiry(enableRecommendationCacheKey);

        if (!isNullOrUndefined(isApproverRevokeEnabledInCache) && !isNullOrUndefined(isRecommendationEnabledInCache)) {
          return Observable.of({
            type: EntitlementActions.getCachedFeatures,
            payload: {
              isApproverRevokeEnabledInCache: isApproverRevokeEnabledInCache,
              isRecommendationEnabledInCache: isRecommendationEnabledInCache
            }
          });
        } else {
          return Observable.of({
            type: EntitlementActions.getAdminOptInFeatures,
          });
        }
      });
  };
registry.addEpic('getCachedFeaturesEpic', getCachedFeaturesEpic);

export const getCachedFeaturesSucceeded = (
  state: IEntitlementState,
  // tslint:disable-next-line:no-any
  action: IEntitlementAction
): Readonly<IEntitlementState> => {

  if (action.payload === undefined) {
    return state;
  }

  const features = { ...state.features.isEnabled };

  features[AdminOptInFeatureKey.EnableApproverRevoke] = action.payload.isApproverRevokeEnabledInCache;
  features[AdminOptInFeatureKey.EnableRecommendationForEndUser] = action.payload.isRecommendationEnabledInCache;


  return {
    ...state,
    features: {
      isLoaded: true,
      isEnabled: features,
    }
  };
};
registry.add(
  EntitlementActions.getCachedFeatures,
  getCachedFeaturesSucceeded
);