import { IAjaxRequest } from '@microsoft/portal-app/lib/auth/withAuth';
import { getResponseValue, IODataValueResponse } from '@microsoft/portal-app/lib/odata-utils';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';
import { IAccessReviewDecision } from '../../../models/AccessReviews/IAccessReviewDecision';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { EntityType } from '../../../models/EntityType';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState, IRootEntitlementsState } from '../../../models/IEntitlementState';
import { IEntityResult } from '../../../models/IEntityResult';
import { LoadMode } from '../../../models/IPageData';
import { getRequestWithAudience } from '../../../shared/AttachAudience';
import {
  getAccessReviewDecisionsApiUrl,
  getFilteredSupervisorCentricAccessReviewDecisionsApiUrl
} from '../../../shared/getAccessReviewsApiUrl';
import { registry } from '../myAccessRegistry';

export const searchReviewDecisionsOnServerEpic: Epic<IEntitlementAction<AnyPayload>, IRootEntitlementsState> = (
  action$: ActionsObservable<IEntitlementAction<string>>,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
  return action$
    .ofType(EntitlementActions.searchReviewDecisionsOnServer)
    .switchMap((action: IEntitlementAction<string>) => {
      let ajaxRequest: IAjaxRequest;

      let searchTerm = action.payload && action.payload.toLowerCase();
      const reviewDecisions = _store.getState().app.accessReviewDecisions;
      const nextLink = reviewDecisions.filtered.nextLink!;
      const orderby = reviewDecisions.sortedColumn && reviewDecisions.sortedColumn.key;
      const isAscending = reviewDecisions.sortedColumn && !reviewDecisions.sortedColumn.isSortedDescending;

      let url = getAccessReviewDecisionsApiUrl(undefined, undefined, searchTerm, orderby, isAscending);
      if (location.href.includes('user-access-reviews')) {
        url = getFilteredSupervisorCentricAccessReviewDecisionsApiUrl(searchTerm, undefined);
      }

      ajaxRequest = getRequestWithAudience(nextLink ? nextLink : url, EntityType.accessReviewDecisions);

      return ajax(ajaxRequest)
        .map((payload: IODataValueResponse<ReadonlyArray<IAccessReviewDecision>>) => {
          return {
            type: EntitlementActions.searchEntitiesOnServerSucceeded,
            payload: {
              entityType: EntityType.accessReviewDecisions,
              entities: getResponseValue(payload),
              searchTerm: searchTerm,
              count: payload.response!['@odata.count'],
              nextLink: payload.response!['@odata.nextLink']
            }
          } as IEntitlementAction<IEntityResult<IAccessReviewDecision>>;
        })
        .catch(() =>
          Observable.of({
            type: EntitlementActions.searchEntitiesOnServerFailed,
            payload: EntityType.accessReviewDecisions
          })
        );
    });
};
registry.addEpic('searchReviewDecisionsOnServerEpic', searchReviewDecisionsOnServerEpic);

export const searchReviewDecisionsOnServer = (
  state: IEntitlementState,
  action: IEntitlementAction<string>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }
  const searchTerm = action.payload;

  return {
    ...state,
    searchTerm: searchTerm!,
    accessReviewDecisions: {
      ...state.accessReviewDecisions,
      filtered: {
        ...state.accessReviewDecisions.filtered,
        entities: [],
        count: undefined,
        nextLink: undefined
      },
      filterContext: undefined,
      isLoading: true,
      loadMode: LoadMode.LoadMore
    }
  };
};
registry.add(EntitlementActions.searchReviewDecisionsOnServer, searchReviewDecisionsOnServer);
