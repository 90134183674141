import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/timeout';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { IAjaxRequest } from '@microsoft/portal-app/lib/auth/withAuth';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';
import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { AjaxCreationMethod, AjaxResponse } from 'rxjs/observable/dom/AjaxObservable';
import { of } from 'rxjs/observable/of';

import { EntityType } from '../../../models';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import { IEntitlementState, IRootEntitlementsState } from '../../../models/IEntitlementState';
import { getAudience } from '../../../shared';
import { getUserPhotoUrl } from '../../../shared/getUsersApiUrl';
import { registry } from '../myAccessRegistry';

export interface IGetPhotoActionPayload {
  userObjectId: string;
}

export type IGetPhotoAction = IEntitlementAction<IGetPhotoActionPayload>;
export type IGetPhotoSuccessAction = IEntitlementAction<IGetPhotoSuccessActionPayload>;
type IGetPhotoFailAction = IEntitlementAction<IGetPhotoFailActionPayload>;

interface IGetPhotoSuccessActionPayload {
  photo?: string;
  userObjectId: string;
}

interface IGetPhotoFailActionPayload {
  error?: Error;
  userObjectId?: string;
}

export const getPhotoEpic: Epic<IEntitlementAction<AnyPayload>, IRootEntitlementsState> = (
  action$: ActionsObservable<IGetPhotoAction>,
  store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IGetPhotoSuccessAction | IGetPhotoFailAction> => {
  return action$.ofType(EntitlementActions.getPhoto).switchMap((action: IGetPhotoAction) => {
    if (!action.payload) {
      return of<IGetPhotoFailAction>({
        type: EntitlementActions.getPhotoFailed,
        payload: {
          error: new Error('No payload')
        }
      });
    }

    const userObjectId = action.payload.userObjectId;
    // Disabling it until User.ReadBasic.All is available on our application
    if (!store.getState().app.features.isEnabled.enableGetPhoto) {
      return of<IGetPhotoSuccessAction>({
        type: EntitlementActions.getPhotoSucceeded,
        payload: {
          userObjectId
        }
      });
    }

    const getPhotoRequest: IAjaxRequest = {
      url: getUserPhotoUrl(userObjectId),
      method: 'GET',
      audience: getAudience(EntityType.graphObject),
      responseType: 'arraybuffer'
    };

    return ajax(getPhotoRequest)
      .timeout(5000) // 5000ms
      .map((payload: AjaxResponse): IGetPhotoSuccessAction => {
        const blob = new Blob([payload.response], { type: 'image/jpeg' });
        const url = URL.createObjectURL(blob);
        return {
          type: EntitlementActions.getPhotoSucceeded,
          payload: {
            photo: url,
            userObjectId
          }
        };
      })
      .catch((error: Error) => {
        return of<IGetPhotoFailAction>({
          type: EntitlementActions.getPhotoFailed,
          payload: {
            error,
            userObjectId
          }
        });
      });
  });
};
registry.addEpic('getPhotoEpic', getPhotoEpic);

export const getPhotoLoading = (state: IEntitlementState, action: IGetPhotoAction): Readonly<IEntitlementState> => {
  if (!action.payload) {
    return state;
  }
  const { usersPhoto } = state;
  usersPhoto.set(action.payload?.userObjectId, null);
  return state;
};
registry.add(EntitlementActions.getPhoto, getPhotoLoading);

export const getPhotoSucceeded = (
  state: IEntitlementState,
  action: IGetPhotoSuccessAction
): Readonly<IEntitlementState> => {
  if (!action.payload?.photo) {
    return state;
  }
  const { usersPhoto } = state;
  usersPhoto.set(action.payload?.userObjectId, action.payload?.photo);
  return state;
};
registry.add(EntitlementActions.getPhotoSucceeded, getPhotoSucceeded);

export const getPhotoFailed = (state: IEntitlementState): Readonly<IEntitlementState> => {
  return state;
};
registry.add(EntitlementActions.getPhotoFailed, getPhotoFailed);
