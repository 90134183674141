import { IGrantRequest } from '../models/ELM/IGrantRequest';
import { DecisionType } from '../models/RequestApprovals/DecisionType';
import { IDecision } from '../models/RequestApprovals/IDecision';
import { IRaeRequest } from '../models/RequestApprovals/IRaeRequest';

export const getDecisionFromRequest = (
  raeRequest: IRaeRequest,
  decisionType: DecisionType
): Partial<IDecision> | undefined => {
  if (!raeRequest) {
    return undefined;
  }

  const decision: Partial<IDecision> = {
    justification: '',
    requestId: raeRequest.id,
    reviewResult: decisionType,
    targetDisplayName: raeRequest.createdBy.displayName,
  };

  return decision;
};

export const getDecisionsFromRequests = (
  raeRequests: IRaeRequest[],
  decisionType: DecisionType
): Partial<IDecision>[] | undefined => {
  if (!raeRequests) {
    return undefined;
  }

  let decisions: Partial<IDecision>[] = [];

  for (let request of raeRequests) {
    const decision: IDecision = {
      requestId: request.id,
      reviewResult: decisionType,
      justification: '',
      targetDisplayName: request.createdBy.displayName,
    };

    decisions.push(decision);
  }

  return decisions;
};

export const getDecisionFromGrantRequest = (
  grantRequest: IGrantRequest,
  decisionType: DecisionType
): Partial<IDecision> | undefined => {
  if (!grantRequest) {
    return undefined;
  }

  const decision: Partial<IDecision> = {
    justification: '',
    requestId: grantRequest.referenceId,
    reviewResult: decisionType,
    targetDisplayName:
      grantRequest.accessPackageAssignment.target!.displayName || '',
    grantRequestId: grantRequest.id,
    answers: grantRequest.answers,
  };

  return decision;
};

export const getDecisionsFromGrantRequests = (
  grantRequests: IGrantRequest[],
  decisionType: DecisionType
): Partial<IDecision>[] | undefined => {
  if (!grantRequests) {
    return undefined;
  }

  let decisions: Partial<IDecision>[] = [];

  for (let request of grantRequests) {
    const decision: IDecision = {
      requestId: request.referenceId,
      reviewResult: decisionType,
      justification: '',
      targetDisplayName: request.accessPackageAssignment.target!.displayName,
      grantRequestId: request.id,
      answers: request.answers,
    };

    decisions.push(decision);
  }

  return decisions;
};
