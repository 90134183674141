/* Ported from https://outlookweb.visualstudio.com/Outlook%20Web */

import { BaseComponent, css, KeyCodes } from '@fluentui/react';
import { IconButton } from '@fluentui/react';
import {
  ContextualMenu,
  DirectionalHint,
  IContextualMenuItem
} from '@fluentui/react/lib/ContextualMenu';
import { Icon } from '@fluentui/react/lib/Icon';
import * as React from 'react';
import { LocaleKeys } from '../../../shared';
import {
  DEFAULT_COLLAPSED_WIDTH,
  DEFAULT_EXPANDED_WIDTH,
  ISearchBoxProps,
  SEARCH_BOX_SOURCE
} from './ExtendedSearchBox.types';
import { SearchInput } from './SearchInput';
const styles = require('./SearchBox.scoped.scss');

export class ExtendedSearchBox extends BaseComponent<ISearchBoxProps> {
  private folderFilterButton: HTMLElement;
  private searchBoxContainer: HTMLDivElement;
  private searchInput: SearchInput;

  constructor(props: ISearchBoxProps) {
    super(props);
  }

  /**
   * Returns a flag indicating whether the search box control is empty
   */

  public isEmpty(): boolean {
    const searchTermEmpty =
      this.props.searchText === undefined ||
      this.props.searchText.trim().length === 0;

    return searchTermEmpty;
  }

  /**
   * Returns flag indicating whether the document active element is contained within search box container OR
   * Filter dropdown is being shown, in which case the focus could be inside the filter drop down
   */

  public hasFocus(): boolean {
    const showFiltersMenu = this.props.showFiltersMenu;
    return (
      this.searchBoxContainer.contains(document.activeElement) ||
      showFiltersMenu!
    );
  }

  /**
   * Sets/puts focus on the search input
   */
  public setFocus(): void {
    this.searchInput.setFocus();
  }

  public render(): React.ReactNode {
    // We expand the search box when in search session
    const isSearchBoxExpanded = this.props.isSearchBoxExpanded;

    const shouldRenderPinIcon = this.props.shouldRenderPinIcon;

    let showPinIcon = shouldRenderPinIcon && this.props.shouldShowPinSearchIcon;

    let maxWidthValue;
    if (!isSearchBoxExpanded) {
      maxWidthValue = this.props.customCollapsedMaxWidth
        ? this.props.customCollapsedMaxWidth
        : DEFAULT_COLLAPSED_WIDTH;
    } else {
      maxWidthValue = DEFAULT_EXPANDED_WIDTH;
    }

    const searchBoxContainerClassNames = css(
      styles.searchBoxContainer,
      isSearchBoxExpanded && styles.inSearch
    );

    const searchBoxContainerStyle = { maxWidth: maxWidthValue + 'px' };

    return (
      <div
        role="search"
        className={css('ms-SearchBox', searchBoxContainerClassNames)}
        // tslint:disable-next-line:jsx-ban-props ~ Dynamic value
        style={searchBoxContainerStyle}
        ref={this._resolveRef('searchBoxContainer')}
      >
        <div className={styles.searchBox} ref={this._resolveRef('_searchBox')}>
          {/* Left side - Back Icon or Search Icon Button */
            isSearchBoxExpanded ? (
              <div
                className={styles.backIcon}
                // This custom property is required to not call props.blur inside onSearchBoxBlur
                data-click-source={SEARCH_BOX_SOURCE}
                onBlur={this._onSearchBoxBlur}
                onClick={this._onBackClick}
                onKeyDown={this._onBackKeyDown}
                tabIndex={0}
                title={this.props.backButtonTitle}
                role='button'
                aria-label={this.props.t(LocaleKeys.back)}
              >
                <Icon iconName={'Back'} />
              </div>
            ) : (
              <div
                onBlur={this._onSearchBoxBlur}
                className={styles.searchIcon}
                aria-hidden={true}
                onClick={this._onSearchBoxClick}
              >
                <Icon iconName={'Search'} />
              </div>
            )}
          <div
            onClick={this._onSearchBoxClick}
            className={styles.searchQueryContainer}
          >
            {
              /* Search Input control */
              <SearchInput
                ref={this._resolveRef('searchInput')}
                searchText={this.props.searchText}
                disabled={this.props.disabled}
                onSearchChange={this.props.onChange}
                onBlur={this._onSearchBoxBlur}
                onFocus={this._onSearchInputFocus}
                onKeyDown={this._onKeyDownOnInputBox}
                shouldFocus={this.props.isFocusInSearch}
                searchPlaceholderText={
                  (this.isEmpty() && this.props.searchPlaceholderText) || ''
                }
              />
            }
          </div>
          {/* Save search Pin icon */
            isSearchBoxExpanded && showPinIcon && this._renderPinIcon()}
          {/* Search scope filter Button */
            // If no callback for the search click is passed, dont need to render the search filter
            this.props.showFiltersIcon &&
            isSearchBoxExpanded &&
            this.props.onFilterClick && (
              <div
                tabIndex={0}
                className={css(
                  styles.filterContainer,
                  showPinIcon && styles.filterWithPin
                )}
                ref={this._resolveRef('folderFilterButton')}
                data-click-source={SEARCH_BOX_SOURCE}
                onBlur={this._onSearchBoxBlur}
                onClick={this._onFilterClick}
                onKeyDown={this._onKeyDownOnFilter}
              >
                <span className={styles.filterText}>
                  {getSearchFilterDisplay(this.props)}
                </span>
                <Icon
                  iconName={this._getFilterIcon()}
                  className={styles.filterDropDownIcon}
                />
                {this.props.showFiltersMenu && this._renderRefiners()}
              </div>
            )}
          {/* Execute search Icon Button */
            isSearchBoxExpanded && (
              <IconButton
                tabIndex={0}
                data-click-source={SEARCH_BOX_SOURCE}
                onBlur={this._onSearchBoxBlur}
                onClick={this._onExecuteSearchClick}
                onKeyDown={this._onKeyDownOnExecuteSearch}
                className={styles.executeSearchIcon}
                title={this.props.executeSearchLabel}
                ariaLabel={this.props.t(LocaleKeys.search)}
              >
                <Icon iconName={'Search'} />
              </IconButton>
            )}
        </div>
      </div>
    );
  }

  private _renderRefiners = (): React.ReactNode => {
    return (
      <ContextualMenu
        target={this.folderFilterButton}
        items={
          (this.props.filterItems &&
            this.props.filterItems.map(
              (item: IContextualMenuItem) =>
              ({
                ...item,
                onClick: undefined
              } as IContextualMenuItem)
            )) ||
          []
        }
        onItemClick={this._onFilterItemClicked}
        onDismiss={this._filterDismissed}
        shouldFocusOnMount={true}
        directionalHintFixed={true} // ensure the position will not change sides in an attempt to fit the callout within bounds.
        gapSpace={2} // we need a 2 px gap between the suggestions menu and the box when search is in header
        directionalHint={DirectionalHint.bottomRightEdge}
      />
    );
  }

  private _renderPinIcon = (): React.ReactNode => {
    let icon;
    let tooltip;

    if (this.props.isTextAlreadyPinned) {
      icon = 'Pinned';
      tooltip = this.props.unpinSearchLabel;
    } else {
      icon = 'Pin';
      tooltip = this.props.pinSearchLabel;
    }

    return (
      <div className={styles.pinIconContainer}>
        <Icon
          className={styles.pinIcon}
          iconName={icon}
          tabIndex={0}
          title={tooltip}
          onClick={this._onPinSearchClick}
        />
      </div>
    );
  }

  private _onPinSearchClick = (evt: React.MouseEvent<EventTarget>): void => {
    evt.stopPropagation();
    this.searchInput.setFocus();
    this.props.toggleSaveSearch &&
      this.props.toggleSaveSearch(this.props.searchText ?? '');
  }

  private _onFilterItemClicked = (
    _ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    item?: IContextualMenuItem
  ): void => {
    if (
      this.props.selectFilterItem !== undefined &&
      item &&
      item.key !== this.props.selectedFilterKey
    ) {
      this.props.selectFilterItem(item.key);
      this._onFilterScopeChanged();
    }
  }

  private _filterDismissed = (): void => {
    if (this.props.hideFilter !== undefined && this.props.showFiltersMenu) {
      this.props.hideFilter();
    }
  }

  /**
   * Called when user clicks on the folder filter dropdown title in search box
   */

  private _onFilterClick = (evt: React.MouseEvent<EventTarget>): void => {
    evt.stopPropagation();
    this.props.onFilterClick && this.props.onFilterClick();
  }

  /**
   * Called when user changes the folder scope from folder filter drop down
   */

  private _onFilterScopeChanged = (): void => {
    // Start search only when search box is not empty
    if (!this.isEmpty()) {
      this._onSearch('SearchScopeRefiner');
    } else {
      // Else put focus back in search input
      this.setFocus();
    }
  }

  /**
   * Called when user presses keyboard on the filter dropdown title in search box
   */

  private _onKeyDownOnFilter = (ev: React.KeyboardEvent<HTMLElement>): void => {
    this._onExecuteByKeyboard(ev, this.props.onFilterClick);
  }

  /**
   * Called when user presses keyboard on the execute search button in search box
   */

  private _onKeyDownOnExecuteSearch = (
    ev: React.KeyboardEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement
    >
  ): void => {
    this._onExecuteByKeyboard(ev, this._onExecuteSearch, 'Keyboard');
  }

  /**
   * Called when user presses keyboard on back button on the search box.
   */

  private _onBackKeyDown = (ev: React.KeyboardEvent<HTMLElement>): void => {
    this._onExecuteByKeyboard(ev, this.props.onBackClick);
  }

  /**
   * Called when user presses keyboard on a button control
   */
  private _onExecuteByKeyboard = (
    ev: React.KeyboardEvent<EventTarget>,
    onClick?: (actionSource?: string) => void,
    actionSource?: string
  ): void => {
    switch (ev.keyCode) {
      case KeyCodes.enter:
      case KeyCodes.space:
        ev.stopPropagation();
        ev.preventDefault();
        onClick && onClick(actionSource);
        break;
      case KeyCodes.tab:
        if (ev.shiftKey) {
        ev.stopPropagation();
        ev.preventDefault();
        this.setFocus();
        }
        break;
    }
  }

  /**
   * Keyboard event handler for the input box
   */

  private _onKeyDownOnInputBox = (evt: React.KeyboardEvent<EventTarget>): void => {
    evt.stopPropagation();
    switch (evt.keyCode) {
      case KeyCodes.enter: {
        // Send out a search only if the search box is not empty
        // If it is, then it is a no op

        this._onSearch('Keyboard');
        this.searchInput.blur();

        break;
      }
      /**
       * For navigation key, we would like to have suggestion shown
       * with the current index and default behavior of input field for the key
       */
      case KeyCodes.pageUp:
      case KeyCodes.pageDown:
      case KeyCodes.end:
      case KeyCodes.home:
      case KeyCodes.left:
      case KeyCodes.right: {
        break;
      }
    }
  }

  /**
   * Event handler called when user clicks on the search icon on the search box control or
   * when they click on the editable part of the search box
   */

  private _onSearchBoxClick = (evt: React.MouseEvent<EventTarget>): void => {
    evt.stopPropagation();
    this.setFocus();
  }

  /**
   * Called when search input gains focus.
   * Call wrapper components onFocused function
   */

  private _onSearchInputFocus = (): void => {
    if (this.props.onFocused) {
      this.props.onFocused();
    }
  }

  /**
   * Search box can lose focus in multiple cases such as below:
   * a) user clicks out side of the search box,
   * b) tabs outside of the search box.
   *
   * We check if related target that will get the next focus is inside the search box container
   * If it is not then the search box control has no element that has a focus
   * so call wrapper components onBlurred function.
   */

  private _onSearchBoxBlur = (
    event: React.FocusEvent<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement
    >
  ): void => {
    let relatedTargetElement = event.relatedTarget as HTMLElement;
    let clickSourceProperty = 'clickSource';
    let isBlurSourceFromSearchBoxContainer = relatedTargetElement
      ? relatedTargetElement.dataset[clickSourceProperty] === SEARCH_BOX_SOURCE
      : false;

    if (!isBlurSourceFromSearchBoxContainer && this.props.onBlurred) {
      this.props.onBlurred();
    }
  }

  /**
   * Called when a search icon that appears on the right side of the search box is clicked
   */

  private _onExecuteSearchClick = (
    event: React.MouseEvent<
      HTMLDivElement | HTMLAnchorElement | HTMLButtonElement,
      MouseEvent
    >
  ): void => {
    event.stopPropagation();
    this._onExecuteSearch('SearchButton');
    if (!this.isEmpty()) {
      this.searchInput.blur();
      if (this.props.onBlurred !== undefined) {
        this.props.onBlurred();
      }
    }
  }

  /**
   * Called when a search icon is pressed or clicked
   */

  private _onExecuteSearch = (actionSource: string): void => {
    const isSearchBoxEmpty = this.isEmpty();
    if (!isSearchBoxEmpty) {
      // If we have something in the search box then start search
      this._onSearch(actionSource);
    } else {
      // Else put focus back in search input
      this.setFocus();
    }
  }

  /**
   * Called whenever user interaction happened and we want to start search
   */

  private _onSearch = (actionSource: string): void => {
    if (this.props.onSearch !== undefined) {
      this.props.onSearch(actionSource);
    }
  }

  /**
   * Called when back button on the search box is clicked.
   */

  private _onBackClick = (evt: React.MouseEvent<EventTarget>): void => {
    evt.stopPropagation();
    if (this.props.onBackClick !== undefined) {
      this.props.onBackClick();
    }
  }

  private _getFilterIcon = (): string => {
    return 'Filter';
  }
}

const getSearchFilterDisplay = (props: ISearchBoxProps) => {
  if (props.filterItems === undefined) {
    return '';
  }

  const selectedItem = props.filterItems.find(
    (item: IContextualMenuItem) => item.key === props.selectedFilterKey
  );
  return selectedItem !== undefined
    ? selectedItem.name
    : props.filterItems[0].name;
};
