import { IDropdownOption } from '@fluentui/react/lib/Dropdown';

import { IRoleScope, msGraphNameToOriginSystemMap, OriginSystem, TranslationFunctionKeys } from '../../../models';
import { LocaleKeys } from '../../../shared';
import { AllResourceTypeKey, OriginSystemToSingularNameMap } from './ResourceView.constants';
import { IResourceItem, IResourceType, IResourceTypeCounts } from './ResourceView.types';

export function convertRoleScopeToListItem(roleScope: IRoleScope): IResourceItem | null {
  const name = roleScope.accessPackageResourceScope?.accessPackageResource?.displayName;
  const originSystem = castToOriginSystem(roleScope.accessPackageResourceScope?.originSystem);
  const originSystemSingularName = originSystem && OriginSystemToSingularNameMap.get(originSystem);
  const role = roleScope.accessPackageResourceRole?.displayName;
  const description = roleScope.accessPackageResourceScope?.accessPackageResource?.description;
  if (!name || !originSystemSingularName) {
    return null;
  }
  return {
    name,
    originSystemLabel: originSystemSingularName,
    originSystem,
    role,
    description
  };
}

export function convertResourceTypeToDropdownOption(resourceType: IResourceType): IDropdownOption {
  return {
    key: resourceType.key,
    text: `${resourceType.displayName} (${resourceType.count})`
  };
}

function castToOriginSystem(originSystem: string): OriginSystem | undefined {
  return msGraphNameToOriginSystemMap.get(originSystem);
}

function countResourceTypes(entitlementResources: IRoleScope[], t: TranslationFunctionKeys): IResourceTypeCounts {
  const resourceTypeCounts: IResourceTypeCounts = {};
  for (const resource of entitlementResources) {
    const originSystem = castToOriginSystem(resource.accessPackageResourceScope?.originSystem);
    const originSystemCategoryName = originSystem && OriginSystemToSingularNameMap.get(originSystem);
    if (originSystem) {
      if (!resourceTypeCounts[originSystem]) {
        resourceTypeCounts[originSystem] = {
          key: originSystem,
          count: 0,
          displayName: originSystemCategoryName
            ? t(originSystemCategoryName, { context: 'pluralCapitalize' })
            : t(LocaleKeys.other)
        };
      }
      resourceTypeCounts[originSystem].count += 1;
    }
  }
  return resourceTypeCounts;
}

export function generateDropdownOptions(
  t: TranslationFunctionKeys,
  entitlementResources?: IRoleScope[]
): IDropdownOption[] {
  if (!entitlementResources || entitlementResources.length === 0) {
    return [];
  }
  const resourceTypeCounts: IResourceTypeCounts = countResourceTypes(entitlementResources, t);
  const dropdownOptions = Object.values(resourceTypeCounts)
    .map(convertResourceTypeToDropdownOption)
    .sort((a, b) => a.text.localeCompare(b.text));
  return dropdownOptions;
}

export function filterByResourceType(
  entitlementResources?: IRoleScope[],
  resourceType?: string | number
): IRoleScope[] {
  if (!entitlementResources || entitlementResources.length === 0) {
    return [];
  }
  let filteredElements = entitlementResources;
  if (resourceType !== AllResourceTypeKey) {
    filteredElements = entitlementResources?.filter((resource) => {
      return msGraphNameToOriginSystemMap.get(resource.accessPackageResourceScope?.originSystem) === resourceType;
    });
  }
  return filteredElements;
}
