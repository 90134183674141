import { css } from '@fluentui/react';
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';
import * as React from 'react';

import { useGetPhoto, useTranslation } from '../../../hooks';
import { LocaleKeys } from '../../../shared';
import { SELECT_ONE_KEY } from "./OnBehalfOfComboBox.constants";
import {
  IOnBehalfOfComboBoxOptionProps
} from './OnBehalfOfComboBox.types';
const styles = require('./OnBehalfOfComboBox.scoped.scss');


export function OnBehalfOfComboBoxOption({ option }: IOnBehalfOfComboBoxOptionProps): JSX.Element {
  const t = useTranslation();
  const { usersPhoto } = useGetPhoto();
  if (option.key === SELECT_ONE_KEY) {
    return (
      <div>
        <span>{t(option.text as LocaleKeys)}</span>
      </div>
    );
  }
  const photo = option.id && usersPhoto.get(option.id);
  return (
    <div className={styles.comboBoxOption}>
      <div>
        {<Persona hidePersonaDetails text={option.text} size={PersonaSize.size24} imageUrl={photo ?? undefined} />}
      </div>
      <div>
        <div>{option.text}</div>
        <div className={css(styles.secondaryText)}>{option.secondaryText}</div>
      </div>
    </div>
  );
}