import { css } from '@fluentui/react/lib/Utilities';
import * as React from 'react';
import { isNullOrUndefined } from 'util';

const myAccessStyles = require('../css/myAccess.scoped.scss');

export const highlightSearch = (
  searchTerm: string,
  text: string
): JSX.Element => {
  if (isNullOrUndefined(searchTerm) || isNullOrUndefined(text)) {
    return <div>{text}</div>;
  }

  let parts = text.split(new RegExp(`(${escapeRegExp(searchTerm)})`, 'gi'));
  return (
    <span>
      {parts.map((part: string, i: number) => (
        <span
          key={i}
          className={
            part.toLowerCase() === searchTerm.toLowerCase()
              ? css(myAccessStyles.highlightText)
              : ''
          }
        >
          {part}
        </span>
      ))}
    </span>
  );
};

export const escapeRegExp = (text: string): string => {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};
