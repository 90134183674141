import { css, DefaultButton, Pivot, PivotItem } from '@fluentui/react';
import { withResponsiveMode } from '@fluentui/react/lib/utilities/decorators/withResponsiveMode';
import * as React from 'react';
import { Route } from 'react-router-dom';

import { IEntity } from '../../../models/ELM/IEntity';
import { IPageData } from '../../../models/IPageData';
import { history } from '../../../redux/configureStore';
import { NAVIGATION_LINKS } from '../../../shared/constants';
import { getPageMetaDataFromEntities } from '../../../shared/getPageMetaDataFromEntities';
import { isEmptyOrUndefined } from '../../../shared/isEmptyOrUndefined';
import { LocaleKeys } from '../../../shared/LocaleKeys';
import { ApprovalSubstitution } from '../../RequestApprovals/ApprovalSubstitution';
import { Subtitle } from '../../Shared/Subtitle/Subtitle';
import { ConnectedCompletedGrantRequestList } from '../CompletedGrantRequestList';
import { ConnectedPendingApprovalGrantRequestList } from '../PendingApprovalGrantRequestList';
import { GrantRequestsPivots, IGrantRequestsProps, IGrantRequestsState } from './GrantRequests.types';
const detailsPageStyles = require('@microsoft/portal-app/lib/styling/patterns/DetailsPage.scoped.scss');
const myAccessStyles = require('../../../css/myAccess.scoped.scss');
const myAccessListStyles = require('../../../css/myAccessList.scoped.scss');
const globalStyles = require('../../../css/global.scss');
const grantRequestsStyle = require('./GrantRequests.scoped.scss');

@withResponsiveMode
export class GrantRequests extends React.Component<IGrantRequestsProps, IGrantRequestsState> {
  constructor(nextProps: IGrantRequestsProps) {
    super(nextProps);
    this.state = {
      selectedPivot: GrantRequestsPivots.Pending,
      approvalSubstitutionModalOpen: false
    };
    nextProps.setSearchContext(null);
    nextProps.showFiltersIcon(false);
  }

  public UNSAFE_componentWillReceiveProps(): void {
    this.setState({
      selectedPivot: this._getSelectedPivotFromUrl()
    });
  }

  public render(): React.ReactNode {
    const { t, searchTerm } = this.props;

    const activeEntities = this._getActiveEntities();

    return (
      <main data-automation-id="GrantRequestsPage" className={css(globalStyles.detailsPage)}>
        <div className={css(myAccessListStyles.listPage, myAccessStyles.detailPageContent)}>
          <div className={css(myAccessListStyles.padding, grantRequestsStyle.titleContainer)}>
            <div>
              <h1 className={css(myAccessListStyles.pageTitle, myAccessListStyles.pageTitleH1Margin)}>
                {t(LocaleKeys.approvalUpper, { context: 'plural' })}
              </h1>
              <div
                id="pageSubtitle"
                role="heading"
                aria-level={2}
                aria-labelledby="pageSubtitle"
                className={css(myAccessStyles.marginTopXSmall, myAccessListStyles.pageSubtitle)}
              >
                <Subtitle
                  isSearching={!isEmptyOrUndefined(searchTerm)}
                  isFiltering={false}
                  pageMetaData={getPageMetaDataFromEntities(activeEntities!)}
                  searchTerm={searchTerm}
                  entityName={this._getEntityName()}
                  t={this.props.t}
                />
              </div>
            </div>
            {this.props.features.enableApproverSubstitution && (
              <DefaultButton
                text='Schedule out of office'
                onClick={this.openApprovalSubstitutionModal}
              />
            )}
          </div>
          <div className={css('ms-DetailsPivot', detailsPageStyles.detailsPivot, myAccessStyles.detailsPivot)}>
            <Pivot selectedKey={this.state.selectedPivot} onLinkClick={this._handlePivotClick} headersOnly={true}>
              <PivotItem
                linkText={this.props.t(LocaleKeys.pending, {
                  context: 'capitalize'
                })}
                itemKey={GrantRequestsPivots.Pending}
              />
              <PivotItem
                linkText={this.props.t(LocaleKeys.history, {
                  context: 'capitalize'
                })}
                itemKey={GrantRequestsPivots.History}
              />
            </Pivot>
          </div>
          {this._renderGrantRequestsPivot()}
        </div>
        {this.props.features.enableApproverSubstitution && (
          <ApprovalSubstitution
            isOpen={this.state.approvalSubstitutionModalOpen}
            onDismiss={this.closeApprovalSubstitutionModal}
          />
        )}
      </main>
    );
  }

  private _getSelectedPivotFromUrl(): GrantRequestsPivots {
    if (location.hash.includes(NAVIGATION_LINKS.requestApprovals.subRoutePaths!.pendingRequestApprovals.link)) {
      return GrantRequestsPivots.Pending;
    }

    if (location.hash.includes(NAVIGATION_LINKS.requestApprovals.subRoutePaths!.completedRequestApprovals.link)) {
      return GrantRequestsPivots.History;
    }

    return GrantRequestsPivots.Pending;
  }

  private readonly _getUrlFromSelected = (selectedPivot: GrantRequestsPivots): string => {
    switch (selectedPivot) {
      case GrantRequestsPivots.Pending:
        return NAVIGATION_LINKS.requestApprovals.subRoutePaths!.pendingRequestApprovals.link;
      case GrantRequestsPivots.History:
        return NAVIGATION_LINKS.requestApprovals.subRoutePaths!.completedRequestApprovals.link;
      default:
        return NAVIGATION_LINKS.requestApprovals.subRoutePaths!.pendingRequestApprovals.link;
    }
  };

  private readonly _getEntityName = (): string => {
    switch (this.state.selectedPivot) {
      case GrantRequestsPivots.Pending:
        return LocaleKeys.pending;
      case GrantRequestsPivots.History:
        return LocaleKeys.completedApproval;
      default:
        return '';
    }
  };

  private readonly _getActiveEntities = (): IPageData<IEntity> | undefined => {
    const { pendingApprovalGrantRequests, completedGrantRequests } = this.props;
    switch (this.state.selectedPivot) {
      case GrantRequestsPivots.Pending:
        return pendingApprovalGrantRequests;
      case GrantRequestsPivots.History:
        return completedGrantRequests;
      default:
        return undefined;
    }
  };

  private readonly _renderGrantRequestsPivot = (): JSX.Element => {
    switch (this.state.selectedPivot) {
      case GrantRequestsPivots.Pending:
        return <Route component={ConnectedPendingApprovalGrantRequestList} />;
      case GrantRequestsPivots.History:
        return <Route component={ConnectedCompletedGrantRequestList} />;
      default:
        return <Route component={ConnectedPendingApprovalGrantRequestList} />;
    }
  };

  private readonly _handlePivotClick = (item: PivotItem | undefined): void => {
    if (!item?.props) {
      return;
    }

    if (this.state.selectedPivot === (item.props.itemKey as GrantRequestsPivots)) {
      return;
    }

    const selectedPivot = item.props.itemKey as GrantRequestsPivots;

    this.setState({
      selectedPivot
    });

    history.push(this._getUrlFromSelected(selectedPivot));
  };

  private readonly openApprovalSubstitutionModal = (): void => {
    this.setState({
      approvalSubstitutionModalOpen: true
    });
  };

  private readonly closeApprovalSubstitutionModal = (): void => {
    this.setState({
      approvalSubstitutionModalOpen: false
    });
  }
}
