// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../dev/node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../dev/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "li,div._3YhwKvopej6GgKbUKzjgNY{margin-top:16px;margin-bottom:16px}div._2MQ5C65iPy7xLojmIEIJki{padding:0px 24px 24px}hr{display:block;height:1px;border:0;border-top:1px solid #ccc;margin-top:16px;margin-bottom:16px;padding:0}h3._2feGMDGXH6Vlv8HtizWZyB{font-weight:600;font-size:1.2em}div._2Fj72poRd6POgmfpSfpdHo{margin-top:16px}\n", "",{"version":3,"sources":["webpack://./src/components/RAM/Connection/ram.connection.scss"],"names":[],"mappings":"AAAA,+BAEE,eAAgB,CAChB,kBAAmB,CACpB,4BAGC,qBAAsB,CACvB,GAGC,aAAc,CACd,UAAW,CACX,QAAS,CACT,yBAA0B,CAC1B,eAAgB,CAChB,kBAAmB,CACnB,SAAU,CACX,2BAGC,eAAgB,CAChB,eAAgB,CACjB,4BAGC,eAAgB","sourcesContent":["li,\ndiv.dialogSection {\n  margin-top: 16px;\n  margin-bottom: 16px;\n}\n\ndiv.dialogContent {\n  padding: 0px 24px 24px;\n}\n\nhr {\n  display: block;\n  height: 1px;\n  border: 0;\n  border-top: 1px solid #ccc;\n  margin-top: 16px;\n  margin-bottom: 16px;\n  padding: 0;\n}\n\nh3.connectionHeading {\n  font-weight: 600;\n  font-size: 1.2em;\n}\n\ndiv.connectionPivotContent {\n  margin-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogSection": "_3YhwKvopej6GgKbUKzjgNY",
	"dialogContent": "_2MQ5C65iPy7xLojmIEIJki",
	"connectionHeading": "_2feGMDGXH6Vlv8HtizWZyB",
	"connectionPivotContent": "_2Fj72poRd6POgmfpSfpdHo"
};
module.exports = ___CSS_LOADER_EXPORT___;
