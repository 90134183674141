import { IAjaxRequest } from '@microsoft/portal-app/lib/auth/withAuth';
import { checkFeatureAccess } from './';

import { EntityType } from '../models/EntityType';
import { settingsManager } from './settings';

export const getRequestWithAudience = (url: string, entityType?: EntityType): IAjaxRequest => {
  if (!url) {
    return {};
  }

  const request: IAjaxRequest = {
    url,
    audience: getAudience(entityType)
  };

  return request;
};

export const getAudience = (entityType?: EntityType): string => {
  switch (entityType) {
    case EntityType.cloudInfrastructure:
      return settingsManager.get('ramAudience')!;
    case EntityType.completedRaeRequests:
    case EntityType.pendingRaeRequests:
      return settingsManager.get('raeAudience')!;
    case EntityType.pdsMember:
      return settingsManager.get('pdsAudience')!;
    case EntityType.accessReviews:
    case EntityType.accessPackageReviews:
    case EntityType.aadRoleReviews:
    case EntityType.rbacReviews:
    case EntityType.byodReviews:
    case EntityType.userAccessReviews:
    case EntityType.supervisorCentricReviewDecisions:
    case EntityType.supervisorCentricReviewDecisionsResources:
    case EntityType.accessReviewDecisions:
    case EntityType.secondaryDecisions:
    case EntityType.decisionsCriteria:
    case EntityType.decisionsSummary:
    case EntityType.decisionHistory:
      return settingsManager.get('accessReviewsAudience')!;
    case EntityType.graphObject:
      return settingsManager.get('msGraphResourceName')!;
    case EntityType.virtualMachines:
      return settingsManager.get('azureArmAudience')!;
    case EntityType.policies:
    default:
      return settingsManager.get('elmAudience')!;
  }
};
