import { MiddlewareAPI } from 'redux';
import { ActionsObservable, Epic } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { AjaxCreationMethod } from 'rxjs/observable/dom/AjaxObservable';

import { IAjaxRequest } from '@microsoft/portal-app/lib/auth/withAuth';
import {
  getResponseValue,
  IODataValueResponse,
} from '@microsoft/portal-app/lib/odata-utils';
import { AnyPayload } from '@microsoft/portal-app/lib/redux/AnyPayload';

import { IEntitlement } from '../../../models/ELM/IEntitlement';
import { EntitlementActions } from '../../../models/EntitlementActions';
import { EntityType } from '../../../models/EntityType';
import { IEntitlementAction } from '../../../models/IEntitlementAction';
import {
  IEntitlementState,
  IRootEntitlementsState,
} from '../../../models/IEntitlementState';
import { IEntityResult } from '../../../models/IEntityResult';
import { LoadMode } from '../../../models/IPageData';
import { getRequestWithAudience } from '../../../shared/AttachAudience';
import { getEntitlementsApiUrl } from '../../../shared/getApiUrl';
import { registry } from '../myAccessRegistry';

export const searchEntitlementsOnServerEpic: Epic<
  IEntitlementAction<AnyPayload>,
  IRootEntitlementsState
> = (
  action$: ActionsObservable<IEntitlementAction<string>>,
  _store: MiddlewareAPI<IRootEntitlementsState>,
  { ajax }: { ajax: AjaxCreationMethod }
): Observable<IEntitlementAction> => {
  return action$
    .ofType(EntitlementActions.searchEntitlementsOnServer)
    .switchMap((action: IEntitlementAction<string>) => {
      let ajaxRequest: IAjaxRequest;
      let searchTerm = action.payload && action.payload.toLowerCase();
      const selectedFilterKey = _store.getState().search.selectedFilterKey;
      const entitlements = _store.getState().app.entitlements;
      const nextLink = entitlements.filtered.nextLink!;
      const orderby =
        entitlements.sortedColumn && entitlements.sortedColumn.key;
      const isAscending =
        entitlements.sortedColumn &&
        !entitlements.sortedColumn.isSortedDescending;
      const filterContext = entitlements.filterContext;

      ajaxRequest = getRequestWithAudience(
        nextLink
          ? nextLink
          : getEntitlementsApiUrl(
              searchTerm,
              orderby,
              isAscending,
              filterContext,
              selectedFilterKey
            )
      );

      return ajax(ajaxRequest)
        .map((payload: IODataValueResponse<ReadonlyArray<IEntitlement>>) => {
          return {
            type: EntitlementActions.searchEntitiesOnServerSucceeded,
            payload: {
              entityType: EntityType.entitlements,
              entities: getResponseValue(payload),
              searchTerm: searchTerm,
              selectedFilterKey: selectedFilterKey,
              count: payload.response!['@odata.count'],
              nextLink: payload.response!['@odata.nextLink'],
            },
          } as IEntitlementAction<IEntityResult<IEntitlement>>;
        })
        .catch(() =>
          Observable.of({
            type: EntitlementActions.searchEntitiesOnServerFailed,
            payload: EntityType.entitlements,
          })
        );
    });
};
registry.addEpic(
  'searchEntitlementsOnServerEpic',
  searchEntitlementsOnServerEpic
);

export const searchEntitlementsOnServer = (
  state: IEntitlementState,
  action: IEntitlementAction<string>
): Readonly<IEntitlementState> => {
  if (action.payload === undefined) {
    return state;
  }

  const searchTerm = action.payload;

  return {
    ...state,
    searchTerm: searchTerm!,
    entitlements: {
      ...state.entitlements,
      filtered: {
        ...state.entitlements.filtered,
        entities: [],
        count: undefined,
        nextLink: undefined,
      },
      isLoading: true,
      loadMode: LoadMode.LoadMore,
    },
  };
};
registry.add(
  EntitlementActions.searchEntitlementsOnServer,
  searchEntitlementsOnServer
);
