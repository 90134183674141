import '@microsoft/portal-app/lib/styling/patterns/Dialog.scss';

import {
  css,
  Dropdown,
  IDropdownOption,
  IDropdownStyles,
  ResponsiveMode,
  TextField,
} from '@fluentui/react';
import { TranslationFunction } from 'i18next';
import * as React from 'react';

import { IAnswerChoice } from '../../../models/ELM/AnswerChoice';
import { getLocalizedText } from '../../../models/ELM/IEntitlementManagementLocalizedContent';
import { QuestionAnswer as QuestionAnswerModel } from '../../../models/ELM/QuestionAnswer';
import { QuestionType } from '../../../models/ELM/QuestionType';
import { isEmptyOrUndefined } from '../../../shared';
import { LocaleKeys } from '../../../shared/LocaleKeys';

const myAccessStyles = require('../../../css/myAccess.scoped.scss');

export interface IQuestionAnswerProps {
  t: TranslationFunction;
  questionAnswer: QuestionAnswerModel;
  submitting: boolean;
  validating: boolean;
  onChange(
    questionId: string,
    answerValue: string,
    answerDiplayValue?: string,
    isValid?: boolean
  ): void;
}

export class QuestionAnswer extends React.Component<IQuestionAnswerProps, {}> {
  constructor(nextProps: IQuestionAnswerProps) {
    super(nextProps);
  }

  public render(): JSX.Element {
    const { questionAnswer } = this.props;

    return (
      <div
        className={css(
          myAccessStyles.marginBottomSmall,
          myAccessStyles.semiBoldText,
          myAccessStyles.questionSpacing
        )}
      >
        {this._getElement(questionAnswer)}
      </div>
    );
  }

  private _getElement = (questionAnswer: QuestionAnswerModel): JSX.Element => {
    const { t, submitting, validating } = this.props;
    const dropdownStyles: Partial<IDropdownStyles> = {
      dropdownOptionText: {overflow: 'visible', whiteSpace: 'normal'},
      dropdownItem: { height: 'auto' }
    };
    switch (questionAnswer.$type) {
      case QuestionType.MultipleChoiceQuestion:
        return (
          <div>
            <Dropdown
              label={getLocalizedText(questionAnswer.text)}
              options={questionAnswer.choices.map((option: IAnswerChoice) => {
                return {
                  key: option.actualValue,
                  text: getLocalizedText(option.displayValue),
                };
              })}
              styles = {dropdownStyles}
              placeholder={
                isEmptyOrUndefined(questionAnswer.answerValue)
                  ? t(LocaleKeys.selectAnOption)
                  : questionAnswer.answerValue
              }
              required={questionAnswer.isRequired}
              disabled={
                submitting ||
                validating ||
                questionAnswer.isAnswerEditable === false ||
                questionAnswer.isNonAnswerable
              }
              onChange={this._onDropdownChanged}
              responsiveMode={ResponsiveMode.large}
            />
          </div>
        );
      case QuestionType.TextInputQuestion:
      default:
        return (
          <div>
            <TextField
              label={getLocalizedText(questionAnswer.text)}
              placeholder={
                questionAnswer.isRequired ? t(LocaleKeys.required) : null
              }
              multiline={
                questionAnswer.$type === QuestionType.TextInputQuestion &&
                !questionAnswer.isSingleLineQuestion
              }
              rowSpan={
                questionAnswer.$type === QuestionType.TextInputQuestion
                  ? questionAnswer.numberOfLines
                  : undefined
              }
              onChange={this._onTextChanged}
              value={questionAnswer.answerValue}
              required={questionAnswer.isRequired}
              disabled={
                submitting ||
                validating ||
                questionAnswer.isAnswerEditable === false ||
                questionAnswer.isNonAnswerable
              }
              errorMessage={questionAnswer.isValid === false ? t(LocaleKeys.missPolicyRequirements) : undefined}
            />
          </div>
        );
    }
  }
  private _onTextChanged = (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    this.props.onChange(this.props.questionAnswer.id, newValue!);
  }
  private _onDropdownChanged = (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
    this.props.onChange(
      this.props.questionAnswer.id,
      String(option!.key),
      String(option!.text)
    );
  }
}
