import { IGrantRequest } from '../models/ELM/IGrantRequest';
import { IRaeRequest } from '../models/RequestApprovals/IRaeRequest';

export const isApproveOrDenyStatus = (request: IRaeRequest): boolean => {
  if (!request) {
    return false;
  }

  return request.status === 'Approve' || request.status === 'Deny';
};

export const isApproveOrDenyRequestStatus = (
  grantRequest: IGrantRequest
): boolean => {
  if (!grantRequest) {
    return false;
  }

  return (
    grantRequest.requestStatus === 'Approve' ||
    grantRequest.requestStatus === 'Deny'
  );
};
