import { IAppRootState } from '@microsoft/portal-app/lib/redux/modules/root';
import { IRootUserState, UserActions } from '@microsoft/portal-app/lib/User/models';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { EntitlementActions, IEntitlementState, IRootEntitlementsState } from '../../../models';
import { Header } from './Header';
import { IHeaderProps } from './Header.types';
import { isInternalUser } from '../../../shared';
import { IOrganization } from '@iamexperiences/suite-header/lib-commonjs/SuiteHeader';

type StateProps = Pick<
  IHeaderProps,
  | 'navBarData'
  | 'tenantBrandingUrl'
  | 'organizations'
  | 'userImageUrl'
  | 'hasP2License'
  | 'hasError'
  | 'currentOrganizationDisplayName'
  | 'enableClaimBasedTelemetry'
>;

type DispatchProps = Pick<
  IHeaderProps,
  | 'getNavBarData'
  | 'getCurrentOrganization'
  | 'getOrganizations'
  | 'getTenantBranding'
  | 'getUserImageUrl'
  | 'handleAppBrandingClick'
>;

const mapStateToProps = (state: IRootEntitlementsState & IAppRootState & IRootUserState): StateProps => {
  const { navBarData, organizations, hasP2License, hasError,
    currentOrgDisplayName, currentOrgId, tenantContext } = state.app;
  const userImageUrl = state.user.userImage;
  const guestOrganizations = {
    isLoading: false,
    value: [
      {
        tenantId: tenantContext?.tenantId || '',
        displayName: tenantContext?.tenant?.displayName || '',
        isCurrent: true,
        isHome: false
      }]
  };

  const isGuestUser = !isInternalUser();

  const organizationArray: IOrganization[] =
    !organizations.value || organizations.value.length === 0
      ? [{ tenantId: currentOrgId, displayName: currentOrgDisplayName, isCurrent: true, isHome: false}]
      : organizations.value;

  return {
    navBarData,
    tenantBrandingUrl: tenantContext?.tenant?.bannerLogoUrl?? '',
    currentOrganizationDisplayName: currentOrgDisplayName,
    organizations: isGuestUser ? guestOrganizations : { ...organizations, value: organizationArray },
    hasError,
    hasP2License,
    userImageUrl: (userImageUrl && userImageUrl.image) || '',
    enableClaimBasedTelemetry: state.app.features.isEnabled.enableClaimBasedTelemetry,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<IEntitlementState>): DispatchProps => {
  return {
    getNavBarData: (token: string) => dispatch({ type: EntitlementActions.getNavBarData, payload: token }),
    getCurrentOrganization: () => dispatch({ type: EntitlementActions.getCurrentOrganization }),
    getOrganizations: () => dispatch({ type: EntitlementActions.getOrganizations }),
    getTenantBranding: () => dispatch({ type: EntitlementActions.getTenantBranding}),
    getUserImageUrl: (userId: string) => dispatch({ type: UserActions.getMyImageRequest, payload: userId }),
    handleAppBrandingClick: () => dispatch(push('/'))
  };
};

export const ConnectedHeader = connect(mapStateToProps, mapDispatchToProps)(Header);
