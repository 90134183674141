export enum IPFFeatureArea {
  MyAccess = 'MyAccess',
}

export const DEFAULT_OCV_APP_ID = 2746;

export const defaultLastSurveyTimeInDays = 90;
export const defaultLastSurveyTimeInSeconds = 24 * 3600 * defaultLastSurveyTimeInDays;
export const defaultSamplingPercentageThreshold = 5;

export enum KnownIPFSurveyName {
  myaccesssysteminitiated = 'myaccesssysteminitiated',
  myaccessgenericuserinitiated = 'myaccessgenericuserinitiated',
  overview = 'overview',
  accesspackages = 'accesspackages',
  requesthistory = 'requesthistory',
  requestapproval = 'requestapproval',
  requestapprovalcompleted = 'requestapprovalcompleted',
  accessreviews = 'accessreviews',
}

export interface IFeedbackItem {
  surveyId: string;
  hasCustomLabel: boolean;
  ces: {
    enable: boolean;
    strings?: {
      label: string;
    };
  };
  cva: {
    enable: boolean;
    strings?: {
      label: string;
    };
  };
  csat: {
    enable: boolean;
    strings?: {
      label: string;
    };
  };
  lastSurveyTimeInSeconds: number;
  samplingPercentageThreshold: number;
}

export const feedbackItems: { [id: string]: IFeedbackItem } = {
  [KnownIPFSurveyName.myaccesssysteminitiated]: {
    surveyId: '0bdec821-4d3e-421b-af7e-1401fb5bb16f',
    hasCustomLabel: false,
    ces: { enable: false },
    cva: { enable: false },
    csat: { enable: true },
    lastSurveyTimeInSeconds: defaultLastSurveyTimeInSeconds,
    samplingPercentageThreshold: defaultSamplingPercentageThreshold,
  },
  [KnownIPFSurveyName.myaccessgenericuserinitiated]: {
    surveyId: 'B98B505D-5E2B-4EC0-8898-ADAE4EB89992',
    hasCustomLabel: false,
    ces: { enable: true },
    cva: { enable: true },
    csat: { enable: false },
    lastSurveyTimeInSeconds: 0,
    samplingPercentageThreshold: 100,
  },
  [KnownIPFSurveyName.overview]: {
    surveyId: 'E403231A-0776-4B11-BA3B-54AB43454D33',
    hasCustomLabel: false,
    ces: { enable: true },
    cva: { enable: true },
    csat: { enable: false },
    lastSurveyTimeInSeconds: 0,
    samplingPercentageThreshold: 100,
  },
  [KnownIPFSurveyName.accesspackages]: {
    surveyId: '8CBFCE38-AE06-48C9-B996-402A798331EF',
    hasCustomLabel: false,
    ces: { enable: true },
    cva: { enable: true },
    csat: { enable: false },
    lastSurveyTimeInSeconds: 0,
    samplingPercentageThreshold: 100,
  },
  [KnownIPFSurveyName.requesthistory]: {
    surveyId: '108689C2-1D71-4DE3-AA3C-6958261CF516',
    hasCustomLabel: false,
    ces: { enable: true },
    cva: { enable: true },
    csat: { enable: false },
    lastSurveyTimeInSeconds: 0,
    samplingPercentageThreshold: 100,
  },
  [KnownIPFSurveyName.requestapproval]: {
    surveyId: '612F7DED-CE5D-4FC3-AD8C-9AEB9AD4FF55',
    hasCustomLabel: false,
    ces: { enable: true },
    cva: { enable: true },
    csat: { enable: false },
    lastSurveyTimeInSeconds: 0,
    samplingPercentageThreshold: 100,
  },
  [KnownIPFSurveyName.requestapprovalcompleted]: {
    surveyId: '5153C7CF-E3C6-4F5C-A8CB-063D523B9468',
    hasCustomLabel: false,
    ces: { enable: true },
    cva: { enable: true },
    csat: { enable: false },
    lastSurveyTimeInSeconds: 0,
    samplingPercentageThreshold: 100,
  },
  [KnownIPFSurveyName.accessreviews]: {
    surveyId: '59776E8E-A89A-4BED-859C-EDF40CD88D9C',
    hasCustomLabel: false,
    ces: { enable: true },
    cva: { enable: true },
    csat: { enable: false },
    lastSurveyTimeInSeconds: 0,
    samplingPercentageThreshold: 100,
  },
};

export const IPFBrowserStorageOverrideKey = {
  // The main purpose of this key is to override whether System Initiated IPF should be enabled or not
  // This is helpful as it allows us to disable this feature to avoid interference with e2e tests in general by default and to enable it only for a specifc e2e test
  // This will be useful in the future once MyAccess starts having e2e test setup
  SystemInitiatedIPF: {
    key: 'SystemInitiatedIPFOverrideVisibilityState',
  },
};
